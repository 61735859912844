<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{
          $t("components.meetings.material.template_list.table.select_template")
        }}
      </h5>
    </div>

    <div class="card-body">
      <div v-if="templates.length > 0" class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>
                {{
                  $t(
                    "components.meetings.material.template_list.table.template"
                  )
                }}
              </th>

              <th class="col-shrink"></th>

              <th>
                {{
                  $t(
                    "components.meetings.material.template_list.table.updated_at"
                  )
                }}
              </th>

              <th class="col-shrink"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="template in templates" :key="`template-${template.id}`">
              <td>
                {{ template.title }}
              </td>

              <td class="col-shrink">
                <i
                  v-if="template.owner_type === 'AdminPanel'"
                  v-be-tooltip="
                    $t('models.agenda_template.from_client_manager')
                  "
                  class="fal fa-info-circle"
                />
              </td>

              <td class="col-shrink">
                {{ $d(new Date(template.updated_at), "dateTime") }}
              </td>

              <td class="col-shrink">
                <be-button
                  v-be-modal="`preview-${template.id}`"
                  variant="outline-secondary"
                  size="sm"
                  inline
                >
                  {{ $t("buttons.titles.preview") }}
                </be-button>

                <be-button
                  variant="outline-primary"
                  size="sm"
                  inline
                  @click="confirmSelect(template)"
                >
                  {{ $t("buttons.titles.use") }}
                </be-button>

                <be-modal
                  :id="`preview-${template.id}`"
                  :title="template.title"
                  size="lg"
                  :ok-title="$t('buttons.titles.use')"
                  @ok="confirmSelect(template)"
                >
                  <div
                    v-for="item in template.items"
                    :key="`template-item-${item.id}`"
                  >
                    <div class="row justify-content-between">
                      <div class="col">
                        <h4 class="mb-0">
                          #{{ item.number }} - {{ item.title }}
                        </h4>
                      </div>

                      <div class="col-auto">
                        <div class="small text-nowrap">
                          <template v-if="item.subtitle">
                            {{ $t(`models.item.subtitles.${item.subtitle}`) }}
                            <span v-text="'•'" />
                          </template>

                          <span class="estimated-duration">
                            {{ formatItemDuration(item) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- eslint-disable-next-line vue/no-v-html -->
                    <p v-html="item.description"></p>

                    <div
                      v-if="item.recess_in_seconds > 0"
                      class="d-flex justify-content-center align-items-center lined-heading mw-100 py-2"
                    >
                      <h5 class="text-black-50 mx-2">
                        {{
                          $t("models.item.recess_w_time", {
                            time: formatItemRecess(item),
                          })
                        }}
                      </h5>
                    </div>
                  </div>
                </be-modal>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <be-alert v-else variant="info">
        {{ $t("components.meetings.material.template_list.empty") }}
      </be-alert>
    </div>

    <div v-if="!hasMeetingItems" class="card-footer text-right">
      <be-button
        variant="outline-primary"
        @click="$emit('update-page', 'editor')"
      >
        {{ $t("buttons.titles.skip") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatTimeDuration } from "@/utils/time";
import materialType from "./materialState";

export default {
  mixins: [materialType],

  props: {
    templates: {
      type: Array,
      required: true,
    },

    hasMeetingItems: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["template-selected", "update-page"],

  computed: {
    ...mapGetters("material", ["material"]),
  },

  methods: {
    async confirmSelect(template) {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("buttons.titles.use"),

        title: this.$t("nav.confirm_use_w_title", {
          title: template.title,
        }),

        description:
          this.material.id && this.hasMeetingItems
            ? this.$t(
                "components.meetings.material.template_list.confirm_template_load"
              )
            : "",
      });

      if (isConfirmed) {
        this.$emit("template-selected", template);
      }
    },

    formatItemDuration(item) {
      return formatTimeDuration(item.duration_in_seconds, this.company.locale);
    },

    formatItemRecess(item) {
      return formatTimeDuration(item.recess_in_seconds, this.company.locale);
    },
  },
};
</script>
