<template>
  <div>
    <be-alert v-if="!contracts.length" variant="info">
      {{ $t("components.contracts.contract_tabs.no_contracts") }}
    </be-alert>

    <template v-else>
      <filter-form :categories="categories" :custom-fields="customFields" />

      <be-table
        :items="filteredContracts"
        :fields="fields"
        :tbody-tr-class="rowStatus"
        sort-by="title"
        hide-filters
      >
        <template #title="{ item }">
          <a :href="url(`/contracts/${item.id}`)">
            <template v-if="item.title_and_number.length <= MAX_TITLE_WIDTH">
              {{ item.title_and_number }}
            </template>

            <span v-else v-be-tooltip="item.title_and_number">
              {{ truncateText(item.title_and_number, MAX_TITLE_WIDTH) }}
            </span>
          </a>

          <p class="text-muted small">
            <span>
              {{ $t(`models.contract.statuses.${item.status}`) }}
            </span>

            <span v-if="subcategory(item)">
              <span class="mx-1">•</span>
              {{ subcategory(item) }}
            </span>
          </p>
        </template>

        <template #total_value="{ item }">
          <p v-if="item.total_value_cents">
            <money-format
              :currency="item.currency"
              :value="item.total_value_cents / 100.0"
            />
          </p>

          <p v-else>-</p>
        </template>

        <template #value_per_month="{ item }">
          <p v-if="item.monthly_value_cents">
            <money-format
              :currency="item.currency"
              :value="item.monthly_value_cents / 100.0"
            />
          </p>

          <p v-else>-</p>
        </template>

        <template #start_date="{ item }">
          <p v-if="item.start_date">
            {{ $d(new Date(item.start_date), "date") }}
          </p>

          <p v-else>-</p>
        </template>

        <template #end_date="{ item }">
          <p v-if="item.end_date">
            {{ $d(new Date(item.end_date), "date") }}
          </p>

          <p v-else>-</p>
        </template>

        <template #termination_date="{ item }">
          <p v-if="item.termination_date">
            {{ $d(new Date(item.termination_date), "date") }}
          </p>

          <p v-else>-</p>
        </template>

        <template #responsible_user="{ item }">
          <user-avatar
            v-if="item.responsible_user_id"
            :user="item.responsible_user_id"
          />
        </template>

        <template #actions="{ item }">
          <contract-dropdown
            v-if="item.policy.edit"
            :contract="item"
            @contract-remove="removeContract(item)"
          />
        </template>
      </be-table>
    </template>

    <div v-if="activeFilters.length > 0" class="row mb-3">
      <div class="col-12 col-md-6">
        <p class="text-muted font-italic">
          <template v-if="activeFilters.length == 1">
            {{
              $t("components.shared.filters.active_filters.one_w_count", {
                filterCount: activeFilters.length,
                filteredCount: filteredContracts.length,
                count: contracts.length,
              })
            }}
          </template>

          <template v-else>
            {{
              $t("components.shared.filters.active_filters.other_w_count", {
                filterCount: activeFilters.length,
                filteredCount: filteredContracts.length,
                count: contracts.length,
              })
            }}
          </template>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ContractDropdown from "./ContractDropdown.vue";
import FilterForm from "./FilterForm.vue";
import TextUtilities from "@/mixins/textUtilities";
import { mapGetters } from "vuex";

const MAX_TITLE_WIDTH = 45;

export default {
  components: {
    ContractDropdown,
    FilterForm,
  },

  mixins: [TextUtilities],

  props: {
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },

    customFields: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      MAX_TITLE_WIDTH: MAX_TITLE_WIDTH,
    };
  },

  computed: {
    ...mapGetters({
      contracts: "contracts/getContracts",
      filteredContracts: "contracts/getFilteredContracts",
      activeFilters: "contracts/getFilters",
    }),

    fields() {
      let fields = [
        {
          key: "title",
          label: this.$t("activerecord.attributes.contract.title"),
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.contract_number,
        },
        {
          key: "total_value",
          label: this.$t("activerecord.attributes.contract.total"),
          class: "col-shrink",
        },
        {
          key: "value_per_month",

          label: this.$t(
            "components.contracts.contract_list.value_per_month_short"
          ),

          class: "col-shrink",
        },
        {
          key: "start_date",
          label: this.$t("activerecord.attributes.contract.start_date"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "end_date",
          label: this.$t("activerecord.attributes.contract.end_date"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "termination_date",
          label: this.$t("activerecord.attributes.contract.termination_date"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "responsible_user",

          label: this.$t(
            "activerecord.attributes.contract.responsible_user_id"
          ),

          class: "col-shrink text-center",
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) =>
            this.getUser(item.responsible_user_id)?.name,
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink",
        },
      ];

      return fields;
    },
  },

  methods: {
    rowStatus(item) {
      if (!item) return "";

      return `status-contracts-${item.status}`;
    },

    subcategory(contract) {
      const subcategory = contract.category_owner?.sub_category;

      if (!subcategory) {
        return undefined;
      }

      if (subcategory.title.length <= this.MAX_TITLE_WIDTH) {
        return subcategory.title;
      }

      return this.truncateText(subcategory.title, this.MAX_TITLE_WIDTH);
    },

    async removeContract(contract) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: contract.title,
        })
      );

      if (isConfirmed) {
        this.$store.dispatch("contracts/removeContract", contract);
      }
    },
  },
};
</script>
