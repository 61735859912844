<template>
  <be-alert :variant="variant">
    {{ $t(`components.annual_reports.notices.events.${translationKey}`) }}
    <div>
      <small>
        {{
          $t(
            "components.annual_reports.annual_report_state_transitions.event_info_format",
            {
              date: formatDate(transition.created_at),
              name: transition.formatted_event_user_name,
            }
          )
        }}
      </small>
    </div>
  </be-alert>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    translationKey: {
      type: String,
      required: true,
    },

    transition: {
      type: Object,
      required: true,
    },

    variant: {
      type: String,
      default: "success",
    },
  },

  methods: {
    formatDate(date) {
      return format(new Date(date), "yyy-MM-dd");
    },
  },
};
</script>
