<template>
  <table class="table">
    <thead>
      <tr>
        <th>
          {{ `${$t("models.evaluation_question.possible_answer")}*` }}
        </th>

        <th class="col-shrink"></th>
      </tr>
    </thead>

    <tbody>
      <possible-answer-row
        v-for="(answer, index) in question.possible_answers"
        :key="`possible-answer-${index}`"
        :component-indexes="componentIndexes.concat([index])"
        :loaded-possible-answer="answer"
        :possible-answers="question.possible_answers"
        :question="question"
        :section="section"
        @possible-answer-removed="updatePositions"
      />
    </tbody>

    <tfoot>
      <tr>
        <td colspan="2">
          <be-button
            variant="outline-secondary"
            size="sm"
            inline
            @click="addPossibleAnswer()"
          >
            {{ $t("models.evaluation_question.add_possible_answer") }}
          </be-button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import PossibleAnswerRow from "./../PossibleAnswerRow.vue";

export default {
  components: {
    PossibleAnswerRow,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },

    section: {
      type: Object,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    addPossibleAnswer() {
      this.$store.dispatch("evaluation_forms/addPossibleAnswer", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,

        possibleAnswer: {
          uuid: this.generateUuid(),
          title: "",

          position:
            this.question.possible_answers.filter(
              (possibleAnswer) => possibleAnswer._destroy != 1
            ).length + 1,

          _destroy: 0,
        },
      });
    },

    updatePositions() {
      this.$store.dispatch("evaluation_forms/updatePossibleAnswersPositions", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,
      });
    },
  },
};
</script>
