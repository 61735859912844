<template>
  <div class="card">
    <div class="card-body">
      <h3 class="mb-0">
        {{ $t("components.onboarding.we_are_here_for_you") }}
      </h3>
    </div>

    <be-list-group flush>
      <be-list-group-item class="py-4">
        <div class="d-md-flex justify-content-betweeen">
          <div>
            <h6>
              {{ $t("components.onboarding.contact.support_center") }}
            </h6>

            <div class="mb-2 mb-md-0">
              {{
                $t(
                  "components.onboarding.introduction.support_section.support_description"
                )
              }}
            </div>
          </div>

          <be-link
            :href="$platform.theme.support_url"
            class="text-nowrap ml-md-3"
          >
            {{ $t("components.onboarding.introduction.visit_support") }}
          </be-link>
        </div>
      </be-list-group-item>
    </be-list-group>
  </div>
</template>

<script>
export default {
  props: {
    isFortnox: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
