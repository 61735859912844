<template>
  <div>
    <div class="card mb-2">
      <div class="card-header">
        <div class="d-md-flex justify-content-end">
          <be-button
            href="/demo/group_ownership_import_template.xlsx"
            variant="outline-primary"
            icon="fa-arrow-down-to-bracket"
          >
            {{
              $t(
                "components.admin_panel.import_group_ownerships.download_template"
              )
            }}
          </be-button>
        </div>
      </div>

      <div class="card-body">
        <p>{{ $t("components.admin_panel.import_group_ownerships.intro") }}</p>

        <be-form-group
          v-show="!importing"
          :label="$t('components.admin_panel.import_companies.upload_template')"
          label-for="document-uploader"
        >
          <document-uploader
            id="document-uploader"
            :max-files="1"
            :documents="documents"
            :accepted-types="['xlsx']"
            @document-added="onDocumentAdded"
            @document-removed="onDocumentRemoved"
            @uploading="(value) => (uploading = value)"
          />
        </be-form-group>

        <div class="d-flex justify-content-end">
          <be-button
            :disabled="documents.length === 0 || importing"
            variant="primary"
            @click="importOwnerships"
          >
            {{ $t("components.admin_panel.import_group_ownerships.submit") }}
          </be-button>
        </div>

        <be-alert v-if="uploadError" variant="warning" class="mt-3">
          {{
            $t("components.admin_panel.import_group_ownerships.upload_error")
          }}
        </be-alert>

        <job-callback
          v-if="jobCallback"
          :callback="jobCallback"
          @update="onJobCallbackUpdate"
        >
          <template #in_progress>
            <be-alert variant="info" loading class="mt-3">
              {{
                $t("components.admin_panel.import_group_ownerships.in_progress")
              }}
            </be-alert>
          </template>

          <template #failed>
            <be-alert variant="warning" class="mt-3">
              {{ $t("components.admin_panel.import_group_ownerships.failed") }}
            </be-alert>
          </template>

          <template #timeout>
            <be-alert variant="warning" class="mt-3">
              {{ $t("components.admin_panel.import_group_ownerships.timeout") }}
            </be-alert>
          </template>

          <template #completed>
            <be-alert variant="success" class="mt-3">
              {{ $t("components.admin_panel.import_group_ownerships.success") }}
            </be-alert>
          </template>
        </job-callback>
      </div>
    </div>

    <div v-if="resultsSummary.length > 0" class="card mb-2">
      <div class="card-header">
        <template v-if="importing">
          {{ $t("components.admin_panel.import_group_ownerships.progress") }}
        </template>

        <template v-else>
          {{ $t("components.admin_panel.import_group_ownerships.results") }}
        </template>
      </div>

      <div class="card-body">
        <be-table :items="resultsSummary" class="mb-0" thead-class="d-none" />
      </div>
    </div>

    <div v-if="feedback.length > 0" class="card mb-2">
      <div class="card-header">
        {{ $t("components.admin_panel.import_group_ownerships.feedback") }}
      </div>

      <div class="card-body">
        <be-table :items="feedback" class="mb-0" thead-class="d-none" />
      </div>
    </div>

    <div v-if="ownerships.length > 0" class="card">
      <div class="card-header">
        {{
          $t("components.admin_panel.import_group_ownerships.new_ownerships")
        }}
      </div>

      <div class="card-body">
        <group-ownerships-table :ownerships="ownerships" />
      </div>
    </div>
  </div>
</template>

<script>
import GroupOwnershipsTable from "./parts/GroupOwnershipsTable.vue";

export default {
  components: { GroupOwnershipsTable },

  props: {
    adminPanel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      documents: [],
      removedDocuments: [],
      uploading: false,
      uploadError: null,
      loading: false,
      importing: false,
      ownerships: [],
      jobCallback: null,
    };
  },

  computed: {
    results() {
      return this.jobCallback?.body;
    },

    resultsSummary() {
      const summary = this.results?.summary;
      if (!summary) {
        return [];
      }
      return Object.entries(summary)
        .filter(([, value]) => value > 0)
        .map(([key, value]) => {
          return [
            this.$t(`components.admin_panel.import_group_ownerships.${key}`),
            value,
          ];
        });
    },

    feedback() {
      const feedback = this.results?.feedback;
      if (!feedback) {
        return [];
      }
      return Object.entries(feedback).map(([row, value]) => [
        this.$t("components.admin_panel.import_group_ownerships.row", {
          row: row,
        }),
        value.errors
          ? value.errors.join(", ")
          : this.$t(
              `components.admin_panel.import_group_ownerships.${value.status}`
            ),
      ]);
    },
  },

  watch: {
    // Watch for new created ownerships ids and load them so they show up in the list.
    "results.created_ids": {
      handler(newValue, oldValue) {
        if (!newValue || newValue.length === 0) {
          return;
        }

        if (!oldValue || oldValue.length === 0) {
          this.loadOwnerships(newValue);
          return;
        }

        const newIds = newValue.filter((id) => !oldValue.includes(id));
        if (newIds.length > 0) {
          this.loadOwnerships(newIds);
        }
      },

      deep: true,
    },
  },

  methods: {
    async importOwnerships() {
      try {
        this.importing = true;
        this.jobCallback = null;
        this.uploadError = null;

        const document = this.documents[0];
        const { data } = await axios.post(
          `/admin_panel/${this.adminPanel.id}/companies/import_ownerships`,
          { document }
        );
        this.jobCallback = data;
      } catch (error) {
        this.uploadError = {
          status: "failed",
          error: error.response.data,
        };
        this.importing = false;
        this.documents = [];
        this.handleError(error);
      }
    },

    async loadOwnerships(merged_ids) {
      this.loading = true;

      // The ownership ids here are merged with the state ids, to make sure we fetch them again if there are new
      // states added. But here we only need the ownership ids.
      const ids = merged_ids.map((id) => id.split(",")[0]);

      try {
        const response = await axios.get(
          `/admin_panel/${this.adminPanel.id}/group_ownerships`,
          { params: { ownership_ids: ids } }
        );

        this.ownerships = response.data.concat(this.ownerships);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    onDocumentAdded(document) {
      this.documents.push(document);
      this.jobCallback = null;
    },

    onDocumentRemoved(document) {
      this.removedDocuments.push(document);
      this.documents = this.documents.filter(
        (item) => item.file_uuid !== document.file_uuid
      );
    },

    onJobCallbackUpdate(jobCallback) {
      this.jobCallback = jobCallback;
      if (jobCallback.status !== "in_progress") {
        this.importing = false;
        this.documents = [];
      }
    },
  },
};
</script>
