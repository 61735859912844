<template>
  <div class="card">
    <div class="card-body">
      <h3>
        {{
          $t("components.template_packages.nav.tabs.user_published_templates")
        }}
      </h3>

      <div class="row mb-5">
        <div class="col-12 col-md-10 mb-3 mb-md-0">
          {{
            $t(
              "components.template_packages.user_published_templates.description"
            )
          }}
        </div>
      </div>

      <be-table
        v-if="publishedTemplates.length > 0"
        :items="publishedTemplates"
        :fields="fields"
        sort-by="created_at"
        sort-desc
      >
        <template #password="{ item }">
          <i
            v-if="item.password_required"
            v-be-tooltip="$t('models.template_package.password_required')"
            class="fal fa-lock fa-fw text-muted"
          />
        </template>

        <template #title="{ item }">
          <a :href="url(`/template_packages/${item.id}/edit`)">
            {{ item.title }}
          </a>
        </template>

        <template #template_type="{ item }">
          <template v-if="item.template_package_items.length > 1">
            {{
              $t(
                `models.template_package.types.multiple_templates.${item.template_type}`
              )
            }}
          </template>

          <template v-else>
            {{ $t(`models.template_package.types.${item.template_type}`) }}
          </template>
        </template>

        <template #created_at="{ item }">
          {{ formattedCreatedAt(item) }}
        </template>

        <template #visibility="{ item }">
          {{
            $t(`models.template_package.privacy_levels.${item.privacy_level}`)
          }}
        </template>

        <template #downloads="{ item }">
          {{ item.downloads }}
        </template>

        <template #options="{ item }">
          <template v-if="item.policy.unpublish">
            <be-button
              :href="url(`/template_packages/${item.id}/invitations`)"
              variant="outline-secondary"
              size="sm"
              inline
            >
              {{ $t("buttons.titles.share") }}
            </be-button>

            <be-button
              variant="danger"
              size="sm"
              inline
              @click="unpublish(item)"
            >
              {{ $t("buttons.titles.unpublish") }}
            </be-button>
          </template>
        </template>
      </be-table>

      <be-alert v-else variant="info">
        {{ $t("components.template_packages.empty") }}
      </be-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "password",
          label: "",
          class: "col-shrink text-center",
          sortable: false,
        },
        {
          key: "title",
          label: this.translateAttribute("template_package", "title"),
          sortable: true,
        },
        {
          key: "template_type",
          label: this.translateAttribute("template_package", "type"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "created_at",
          label: this.translateAttribute("template_package", "published_at"),
          class: "col-shrink d-none d-md-table-cell",
          sortable: true,
        },
        {
          key: "visibility",
          label: this.translateAttribute("template_package", "visibility"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "downloads",
          label: this.translateAttribute("template_package", "downloads"),
          class: "col-shrink d-none d-md-table-cell text-right",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      publishedTemplates: "template_packages/publishedTemplates",
    }),
  },

  methods: {
    async unpublish(templatePackage) {
      const description =
        templatePackage.template_package_items?.length > 1
          ? this.$t(
              "components.template_packages.confirm_unpublish_package_description",
              {
                title: templatePackage.title,
              }
            )
          : this.$t(
              "components.template_packages.confirm_unpublish_description",
              { title: templatePackage.title }
            );

      const isConfirmed = await this.promptRemovalConfirm({
        confirmButtonText: this.$t("buttons.titles.unpublish"),

        title: this.$t("components.template_packages.confirm_unpublish", {
          title: templatePackage.title,
        }),

        description: description,
      });

      if (isConfirmed) {
        this.$store.dispatch("template_packages/unpublish", templatePackage);
      }
    },

    formattedCreatedAt(templatePackage) {
      return this.$i18n.d(new Date(templatePackage.created_at), "date");
    },
  },
};
</script>
