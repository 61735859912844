<template>
  <div>
    <be-form-checkbox
      :id="`compliance-role-${compliance.id}-${role}`"
      :checked="checked"
      :disabled="disabled"
      @change="toggleDestroy"
    />

    <input
      v-if="!destroy"
      type="hidden"
      :name="`company[compliance_attributes][${rowIndex}][compliance_permissions_attributes][${index}][role]`"
      :value="role"
    />

    <input
      type="hidden"
      :name="`company[compliance_attributes][${rowIndex}][compliance_permissions_attributes][${index}][_destroy]`"
      :value="destroy"
    />

    <input
      v-if="compliancePermission"
      type="hidden"
      :name="`company[compliance_attributes][${rowIndex}][compliance_permissions_attributes][${index}][id]`"
      :value="compliancePermission.id"
    />
  </div>
</template>

<script>
export default {
  props: {
    compliance: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    rowIndex: {
      type: Number,
      required: true,
    },

    role: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      destroy: false,
    };
  },

  computed: {
    checked() {
      return !!this.compliancePermission || this.compliance.role == this.role;
    },

    disabled() {
      return this.compliance.role == this.role;
    },

    compliancePermission() {
      return this.compliance.compliance_permissions.find((permission) => {
        return (
          permission.role == this.role &&
          this.compliance.id == permission.compliance_id
        );
      });
    },
  },

  mounted() {
    this.destroy = !this.checked;
  },

  methods: {
    toggleDestroy() {
      this.destroy = !this.destroy;
    },
  },
};
</script>
