<template>
  <form
    novalidate="novalidate"
    accept-charset="UTF-8"
    method="POST"
    :action="formAction()"
    @submit="loading = true"
  >
    <input name="utf8" type="hidden" value="✓" />

    <input type="hidden" name="authenticity_token" :value="$csrfToken" />

    <input
      v-if="contract.id"
      id="_method"
      type="hidden"
      name="_method"
      value="PUT"
    />

    <div class="card mb-2">
      <div class="card-body">
        <div class="mb-3">
          <be-form-group
            :label="$t('attributes.title')"
            label-for="title"
            :error="getErrors(contract, ['title'])"
          >
            <be-form-input
              id="title"
              name="contract[title]"
              :model-value="contract.title"
              :disabled="contract.editing_disabled"
              required
              @change="clearErrors(contract, ['title'])"
            />
          </be-form-group>

          <be-form-group
            v-if="!contract.editing_disabled"
            :label="$t('activerecord.models.contract.one')"
            label-for="contract-document"
            :error="getErrors(contract, ['documents'])"
            required
          >
            <document-uploader
              name="contract[documents_attributes][_index_]"
              :documents="contract.documents"
              :html-form="true"
              :state="validationState(contract, ['documents'])"
              @document-added="documentAdded"
              @document-removed="documentRemoved"
              @uploading="(value) => (loading = value)"
            />
          </be-form-group>

          <table v-else-if="contract.documents" class="table">
            <tbody>
              <tr
                v-for="contractDocument in contract.documents"
                :key="`contract-document-${contractDocument.id}`"
                class="fields"
              >
                <td class="col-shrink">
                  <i :class="contractDocument.fontawesome_icon"></i>
                </td>

                <td>
                  {{ contractDocument.filename }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <be-button
          variant="outline-secondary"
          :class="{ 'd-none': contract.id }"
          @click="toggleMetadata()"
        >
          {{
            showMetadata
              ? $t("buttons.titles.show_less")
              : $t("buttons.titles.show_more")
          }}
        </be-button>

        <div v-show="showMetadata">
          <hr />

          <div class="row">
            <div class="col-12 col-md-4">
              <be-form-group label-for="subscription-date">
                <template #label>
                  {{ $t("components.contracts.editor.subscription_date") }}
                  <i
                    v-be-tooltip="
                      $t(
                        'components.contracts.editor.subscription_date_description'
                      )
                    "
                    class="fal fa-question-circle ml-1"
                  />
                </template>

                <be-form-datepicker
                  id="subscription-date"
                  v-model="contract.subscription_date"
                  name="contract[subscription_date]"
                  :disabled="contract.editing_disabled"
                />
              </be-form-group>
            </div>
          </div>

          <hr />

          <!-- Contract type -->
          <div class="row justify-content-between">
            <div class="col-12 col-md-4">
              <be-form-group
                label-for="main-type"
                :label="$t('components.contracts.editor.main_type')"
              >
                <be-form-select
                  id="main-type"
                  v-model="contract.contract_type.main_type"
                  name="contract[contract_type_attributes][main_type]"
                  :options="formattedContractTypes"
                  include-blank-option
                />
              </be-form-group>
            </div>
          </div>

          <contract-date-period
            :contract="contract"
            :initial-end-date="endDate"
            :initial-start-date="startDate"
            :show-end-date="
              !contract.contract_type.main_type ||
              contract.contract_type.main_type == 'limited'
            "
            @end-date="updateEndDate"
            @start-date="updateStartDate"
          />

          <no-main-type
            v-if="!contract.contract_type.main_type"
            :contract="contract"
          />

          <ongoing
            v-if="contract.contract_type.main_type == 'ongoing'"
            :contract="contract"
            :start-date="startDate"
          />

          <limited
            v-if="contract.contract_type.main_type == 'limited'"
            :end-date="endDate"
            :contract="contract"
          />

          <hr />

          <!-- Values -->
          <div class="row">
            <div class="col-12 col-md-4">
              <be-form-group label-for="value">
                <template #label>
                  {{ $t("components.contracts.editor.value") }}
                  <i
                    v-be-tooltip="
                      $t('components.contracts.editor.value_description')
                    "
                    class="fal fa-question-circle ml-1"
                  />
                </template>

                <be-form-input
                  id="value"
                  v-model="contractValue"
                  name="contract[value]"
                  type="number"
                  number
                />
              </be-form-group>
            </div>

            <div class="col-12 col-md-4">
              <be-form-group
                label-for="value-type"
                :label="$t('components.contracts.editor.value_type')"
              >
                <be-form-select
                  id="value-type"
                  v-model="selectedValueType"
                  name="contract[value_type]"
                  :options="formattedValueTypes"
                  :disabled="contract.editing_disabled"
                />
              </be-form-group>
            </div>

            <div class="col-12 col-md-4">
              <be-form-group
                label-for="currency"
                :label="$t('components.contracts.editor.currency')"
              >
                <be-form-select
                  id="currency"
                  v-model="selectedCurrency"
                  name="contract[currency]"
                  :options="formattedCurrencies"
                  :disabled="contract.editing_disabled"
                />
              </be-form-group>
            </div>
          </div>

          <hr />

          <!-- Categories -->
          <div class="row">
            <div class="col-12 col-md-4">
              <be-form-group
                label-for="sub-category-id"
                :label="$t('components.shared.categories.category')"
              >
                <be-form-select
                  id="sub-category-id"
                  v-model="selectedSubCategory"
                  name="contract[sub_category][id]"
                  :options="formattedSubCategories"
                  :disabled="contract.editing_disabled"
                  include-blank-option
                />
              </be-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-4 align-self-end">
              <be-button
                v-be-modal.add-sub-category
                variant="light"
                size="sm"
                icon="fa-plus"
                :disabled="!categoriesExisting"
              >
                {{ $t("models.sub_category.new") }}
              </be-button>

              <div>
                <small v-if="!categoriesExisting" class="text-muted">
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span v-html="categoryDescription"></span>
                </small>
              </div>

              <add-sub-category-modal
                :object="contract"
                object-type="Contract"
                @sub-category-added="addSubCategory"
              />
            </div>
          </div>

          <hr />

          <!-- Custom fields -->
          <label>
            {{ $t("components.contracts.editor.custom_fields") }}
            <i
              v-be-tooltip="
                $t('components.contracts.editor.custom_fields_description')
              "
              class="fal fa-question-circle ml-1"
            />
          </label>

          <div class="row">
            <template v-if="customFields && customFields.length > 0">
              <div class="col-12 col-md-6 col-lg-4">
                <be-form-group>
                  <be-form-select
                    v-model="selectedCustomFieldId"
                    name="custom_fields"
                    :options="formattedCompanyCustomFields"
                    include-blank-option
                  />
                </be-form-group>
              </div>

              <div class="col-12 col-md-6 pl-md-0">
                <be-button
                  v-be-tooltip="{
                    title: $t('components.custom_fields.already_added'),
                    disabled:
                      !selectedCustomFieldId ||
                      !disableCustomField(selectedCustomFieldId),
                  }"
                  variant="outline-primary"
                  :disabled="
                    disableCustomField(selectedCustomFieldId) ||
                    contract.editing_disabled
                  "
                  @click="renderCustomField"
                >
                  {{ $t("buttons.titles.use") }}
                </be-button>
              </div>
            </template>
          </div>

          <div class="row">
            <div class="col-12 col-md-4 align-self-end">
              <add-custom-field-modal
                :object="contract"
                object-type="Contract"
                @custom-field-added="addCustomField"
              />

              <be-button
                v-be-modal.add-custom-fields
                variant="light"
                size="sm"
                class="mb-3"
                icon="fa-plus"
                :disabled="contract.editing_disabled"
              >
                {{ $t("models.custom_field.new") }}
              </be-button>
            </div>
          </div>

          <div class="row">
            <custom-field-owner-row
              v-for="(customFieldOwner, index) in contract.custom_field_owners"
              :key="'custom-field-owner-' + index"
              name-prefix="contract"
              :custom-field-owner="customFieldOwner"
              :index="index"
              @custom-field-owner-removed="removeCustomFieldOwner"
            />
          </div>

          <hr />

          <!-- Contract responsibility -->
          <div class="row">
            <div class="col-12 col-md-4">
              <be-form-group label-for="responsible-user">
                <template #label>
                  {{ $t("components.contracts.editor.responsible_user") }}
                  <i
                    v-be-tooltip="
                      $t(
                        'components.contracts.editor.responsible_user_description'
                      )
                    "
                    class="fal fa-question-circle ml-1"
                  />
                </template>

                <be-form-select
                  id="responsible-user"
                  v-model="selectedResponsibleUser"
                  name="contract[responsible_user_id]"
                  :options="formattedCompanyUsers"
                  required
                />
              </be-form-group>
            </div>

            <div class="col-12 col-md-4">
              <be-form-group label-for="administrator-id">
                <template #label>
                  {{ $t("components.contracts.editor.administrator") }}
                  <i
                    v-be-tooltip="
                      $t(
                        'components.contracts.editor.administrator_description'
                      )
                    "
                    class="fal fa-question-circle ml-1"
                  />
                </template>

                <be-form-select
                  id="administrator-id"
                  v-model="selectedAdministrator"
                  name="contract[administrator_id]"
                  :options="formattedCompanyUsers"
                  required
                />
              </be-form-group>
            </div>
          </div>

          <hr />

          <!-- Contacts -->
          <div class="row">
            <div class="col-12 col-md-6 col-lg-4">
              <be-form-group label-for="contacts">
                <template #label>
                  {{ $t("components.contracts.editor.parties") }}
                  <i
                    v-be-tooltip="
                      $t('components.contracts.editor.parties_description')
                    "
                    class="fal fa-question-circle ml-1"
                  />
                </template>

                <be-form-select
                  v-if="localCompanyContacts.length > 0"
                  id="contacts"
                  v-model="selectedContacts"
                  :options="formattedAvailableContacts"
                  option-label="name"
                  option-value="id"
                  template="user"
                  multiple
                />

                <input
                  v-for="contactId in selectedContacts"
                  :key="contactId"
                  type="hidden"
                  name="contract[contacts][]"
                  :value="contactId"
                />
              </be-form-group>
            </div>

            <div class="col-12">
              <p v-if="localCompanyContacts.length === 0" class="mb-2">
                {{ $t("components.contracts.editor.no_contacts") }}
              </p>

              <div class="row">
                <div class="col-12 col-md-4 align-self-end">
                  <be-button
                    v-be-modal.add-contact
                    variant="light"
                    size="sm"
                    icon="fa-plus"
                  >
                    {{ $t("models.contact.new") }}
                  </be-button>

                  <add-contact-modal
                    :object="contract"
                    object-type="Contract"
                    @contact-added="addContact"
                  />
                </div>
              </div>
            </div>
          </div>

          <hr />

          <!-- Notes -->
          <be-form-group
            label-for="note"
            :label="$t('components.contracts.editor.notes')"
          >
            <be-form-textarea
              id="note"
              v-model="contract.note"
              name="contract[note]"
              rows="3"
              max-rows="20"
            />
          </be-form-group>
        </div>
      </div>

      <div class="card-footer text-right">
        <be-button
          type="submit"
          :variant="saveButtonVariant"
          :loading="loading"
          @click="updateActionType('save')"
        >
          {{ $t("documents.save") }}
        </be-button>

        <template v-if="!contract.monitored && !contract.archived">
          <template v-if="isContractAdmin">
            <be-button
              type="submit"
              variant="primary"
              :loading="loading"
              @click="updateActionType('verify')"
            >
              {{ $t("components.contracts.editor.mark_as_verified_and_save") }}
            </be-button>
          </template>

          <template v-else-if="!contract.in_verification">
            <be-button
              type="submit"
              variant="primary"
              :loading="loading"
              @click="updateActionType('start_verification')"
            >
              {{
                $t("components.contracts.editor.save_and_send_for_verification")
              }}
            </be-button>
          </template>

          <input v-model="actionType" type="hidden" name="submit_button" />
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import uuid from "@/mixins/uuid/index";
import AddContactModal from "../shared/AddContactModal.vue";
import AddCustomFieldModal from "../shared/AddCustomFieldModal.vue";
import AddSubCategoryModal from "../shared/AddSubCategoryModal.vue";
import CustomFieldOwnerRow from "../shared/custom_fields/CustomFieldOwnerRow.vue";
import ContractDatePeriod from "./contract_types/dates/ContractDatePeriod.vue";
import Limited from "./contract_types/LimitedContract.vue";
import NoMainType from "./contract_types/NoMainType.vue";
import Ongoing from "./contract_types/OngoingContract.vue";

export default {
  components: {
    AddContactModal,
    AddCustomFieldModal,
    AddSubCategoryModal,
    NoMainType,
    Ongoing,
    Limited,
    CustomFieldOwnerRow,
    ContractDatePeriod,
  },

  props: {
    initialContract: {
      type: Object,
      required: true,
    },

    companyContacts: {
      type: Array,
      required: true,
    },

    companyCustomFields: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      actionType: "save",
      contract: this.initialContract,
      customFields: this.companyCustomFields,
      contractValue: null,
      endDate: Date.parse(this.initialContract.end_date),
      loading: false,
      localCompanyContacts: this.cloneDeep(this.companyContacts),
      startDate: Date.parse(this.initialContract.start_date),
      showMetadata: !!this.initialContract.id,
      selectedCurrency: null,
      selectedValueType: null,
      selectedSubCategory: null,
      selectedResponsibleUser: null,
      selectedAdministrator: null,
      selectedCustomFieldId: null,

      selectedContacts:
        this.initialContract.contacts.map((contact) => contact.id) || [],
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
    }),

    categoryDescription() {
      return this.$i18n.t("components.contracts.editor.no_categories", {
        link: `<a href="${this.url(
          "/contracts/categories"
        )}" target="_blank" rel="noopener">${this.$i18n.t(
          "components.contracts.editor.add"
        )}</a>`,
      });
    },

    categoriesExisting() {
      return (
        this.contract.available_categories &&
        this.contract.available_categories.length > 0
      );
    },

    formattedContractTypes() {
      return this.$config.CONTRACT.MAIN_TYPES.map((key) => ({
        text: this.$i18n.t(`models.contract.types.${key}`),
        value: key,
      }));
    },

    formattedValueTypes() {
      return this.$config.CONTRACT.VALUE_TYPES.map((key) => ({
        text: this.$i18n.t(`activerecord.attributes.contract.${key}`),
        value: key,
      }));
    },

    formattedCurrencies() {
      return this.$config.CONTRACT.CURRENCIES;
    },

    formattedSubCategories() {
      let formattedCategories = [];

      for (let category of this.contract.available_categories) {
        formattedCategories.push({
          label: category.title,

          options: category.sub_categories.map((sub_category) => {
            return {
              label: sub_category.title,
              value: sub_category.id,
            };
          }),
        });
      }

      return formattedCategories;
    },

    formattedCompanyUsers() {
      return this.companyUsers
        .map((user) => ({ text: user.name, value: user.id }))
        .sort((a, b) => b.id - a.id);
    },

    formattedAvailableContacts() {
      return this.localCompanyContacts
        .map((user) => ({ name: user.name, id: user.id }))
        .sort((a, b) => b.id - a.id);
    },

    formattedCompanyCustomFields() {
      return this.customFields
        .map((customField) => ({
          text: customField.title,
          value: customField.id,
          disabled: this.disableCustomField(customField.id),
        }))
        .sort((a, b) => b.id - a.id);
    },

    isContractAdmin() {
      return (
        this.contract.administrator_id &&
        this.contract.administrator_id == this.$currentUser.id
      );
    },

    saveButtonVariant() {
      if (!this.contract.monitored && !this.contract.archived) {
        return "outline-primary";
      }
      return "primary";
    },
  },

  watch: {
    "contract.contract_type.main_type"(oldValue, newValue) {
      if (newValue == "ongoing" || newValue == null) {
        this.contract.contract_type.renewal_type = null;
      }
    },
  },

  mounted() {
    if (
      this.initialContract.errors &&
      Object.keys(this.initialContract.errors).length > 0
    ) {
      this.showMetadata = true;
    }

    this.selectedCurrency =
      this.initialContract.currency || this.$config.CONTRACT.CURRENCIES[0];

    this.selectedValueType =
      this.initialContract.value_type || this.$config.CONTRACT.VALUE_TYPES[0];

    this.selectedSubCategory = this.initialContract.category_owner
      ? this.initialContract.category_owner.sub_category.id
      : null;

    this.selectedResponsibleUser =
      this.contract.responsible_user_id || this.$currentUser.id;

    this.selectedAdministrator =
      this.contract.administrator_id || this.$currentUser.id;

    // Format value from cents
    if (this.contract.total_value_cents) {
      this.contractValue = this.contract.total_value_cents / 100.0;
    } else if (this.contract.monthly_value_cents) {
      this.contractValue = this.contract.monthly_value_cents / 100.0;
    }

    // Set UUIDS too custom fields that has no ids
    this.contract.custom_field_owners.forEach((customFieldOwner) => {
      if (!customFieldOwner.id) {
        customFieldOwner.uuid = uuid.methods.generateUuid();
      }
    });
  },

  methods: {
    formAction() {
      if (this.contract.id) {
        return this.url(`/contracts/${this.contract.id}`);
      }
      return this.url("/contracts");
    },

    documentAdded(file) {
      this.contract.documents.push(file);
    },

    documentRemoved(file) {
      const index = this.contract.documents.findIndex(
        (existingDocument) => existingDocument === file
      );

      if (index > -1) {
        this.contract.documents.splice(index, 1);
      }
    },

    renderCustomField() {
      this.customFields.forEach((customField) => {
        if (customField.id == this.selectedCustomFieldId) {
          this.contract.custom_field_owners.push({
            uuid: uuid.methods.generateUuid(),
            custom_field: customField,
            input_value: customField.default_value,
          });
        }
      });
    },

    disableCustomField(customFieldId) {
      if (!customFieldId) {
        return true;
      }

      const usedIds = this.contract.custom_field_owners
        .filter((owner) => !owner.destroyed)
        .map((owner) => owner.custom_field?.id);

      return usedIds.includes(customFieldId);
    },

    addContact(contact) {
      this.localCompanyContacts.push(contact);

      // We need to wait for the next tick to ensure the contact is added to the options
      // in the select before we set it as selected.
      this.$nextTick(() => {
        this.selectedContacts.push(contact.id);
      });
    },

    addSubCategory(subCategory) {
      this.contract.available_categories.forEach((category) => {
        if (category.id == subCategory.category_id) {
          category.sub_categories.push(subCategory);
        }
      });

      this.$nextTick(() => {
        this.selectedSubCategory = subCategory.id;
      });
    },

    addCustomField(customField) {
      this.customFields.push(customField);
      this.selectedCustomFieldId = customField.id;
    },

    removeCustomFieldOwner(removedOwner) {
      let idx = this.contract.custom_field_owners.findIndex(
        (existingCustomFieldOwner) => {
          return (
            (existingCustomFieldOwner.id &&
              existingCustomFieldOwner.id == removedOwner.id) ||
            (existingCustomFieldOwner.uuid &&
              existingCustomFieldOwner.uuid == removedOwner.uuid)
          );
        }
      );

      this.contract.custom_field_owners[idx]["destroyed"] = true;
    },

    toggleMetadata() {
      this.showMetadata = !this.showMetadata;
    },

    updateEndDate(endDate) {
      this.endDate = Date.parse(endDate);
    },

    updateStartDate(startDate) {
      this.startDate = Date.parse(startDate);
    },

    updateActionType(actionType) {
      this.actionType = actionType;
    },
  },
};
</script>
