import { createNamespacedHelpers } from "vuex";

const { mapState: mapCompanyState } = createNamespacedHelpers("company");

const {
  mapActions: mapSynaActions,
  mapMutations: mapSynaMutations,
  mapGetters: mapSynaGetters,
} = createNamespacedHelpers("syna");

const blankCompanySuggestion = {
  title: "",
  type_of_organization: "limited_company",
  financial_year_start: "",
  billing_address: "",
  billing_address_two: "",
  postcode: "",
  city: "",
  country: "",
  id: "",
};

export default {
  data() {
    return {
      selectedSuggestion: { ...blankCompanySuggestion },
    };
  },

  computed: {
    ...mapCompanyState(["companies"]),

    ...mapSynaGetters([
      "getPersonalCompanySuggestions",
      "getSharedCompanySuggestions",
    ]),

    personalSuggestions() {
      return this.filterAndFormatSuggestions(
        this.getPersonalCompanySuggestions
      );
    },

    sharedSuggestions() {
      return this.filterAndFormatSuggestions(this.getSharedCompanySuggestions);
    },

    suggestionOptions() {
      const optionGroups = [];

      if (this.personalSuggestions.length > 0) {
        optionGroups.push({
          label: this.$t(
            "components.new_company_form.suggestions_source_identification_number"
          ),

          options: this.personalSuggestions,
        });
      }

      if (this.sharedSuggestions.length > 0) {
        optionGroups.push({
          label: this.$t(
            "components.new_company_form.suggestions_source_companies"
          ),

          options: this.sharedSuggestions,
        });
      }

      return optionGroups;
    },
  },

  mounted() {
    if (this.platformEnabled("syna_suggestions")) {
      this.loadCompanySuggestions();
    }
  },

  methods: {
    ...mapSynaActions(["loadCompanySuggestions"]),
    ...mapSynaMutations(["setSelectedCompanySuggestion"]),

    clearSuggestion() {
      this.selectedSuggestion = { ...blankCompanySuggestion };
    },

    formatOrgNumber(value) {
      if (!value) return "";
      return value.replace(/(\d{6})(\d{4})/, "$1-$2");
    },

    filterAndFormatSuggestions(suggestions) {
      // Filter out suggestions that are already in the list of companies:
      return suggestions
        .filter(
          (s) =>
            !this.companies.some((c) => this.suggestionMatchesCompany(s, c))
        )
        .map((suggestion) => suggestion);
    },

    suggestionMatchesCompany(suggestion, company) {
      if (company.title == suggestion.title) {
        return true;
      }
      if (!company.organization_number) {
        return false;
      }
      return (
        this.formatOrgNumber(company.organization_number) ==
        this.formatOrgNumber(suggestion.id)
      );
    },
  },
};
