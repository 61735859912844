<template>
  <div :class="containerClasses">
    <nav v-if="!onlyCode && !onlyPreview && !sideBySide" :class="navClasses">
      <div class="nav nav-tabs" role="tablist">
        <button
          v-bind="tabButtonAttrs('preview')"
          :class="tabButtonClasses('preview')"
          @click="setActiveTab('preview')"
        >
          <i class="fal fa-eye fa-fw mr-1" />
          Preview
        </button>

        <button
          v-bind="tabButtonAttrs('code')"
          :class="tabButtonClasses('code')"
          @click="setActiveTab('code')"
        >
          <i class="fal fa-code fa-fw mr-1" />
          Code
        </button>
      </div>
    </nav>

    <div class="tab-content">
      <div
        v-bind="tabContentAttrs('preview')"
        :class="tabContentClasses('preview')"
      >
        <slot name="preview" />
      </div>

      <div v-bind="tabContentAttrs('code')" :class="tabContentClasses('code')">
        <div
          class="position-absolute d-flex justify-content-end w-100 p-2"
          style="z-index: 2"
        >
          <be-button
            :variant="copyButtonVariant"
            size="sm"
            class="position-absolute"
            @click="copyCode"
          >
            {{ copyButtonText }}
          </be-button>
        </div>

        <slot name="code" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onlyCode: {
      type: Boolean,
      required: false,
      default: false,
    },

    onlyPreview: {
      type: Boolean,
      required: false,
      default: false,
    },

    sideBySide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      activeTab: this.onlyCode ? "code" : "preview",
      copyButtonText: "Copy",
      copyButtonVariant: "light",
    };
  },

  computed: {
    containerClasses() {
      return [
        "border",
        "border-top-1",
        "border-right-1",
        "border-bottom-1",
        "border-left-1",
        "rounded",
      ];
    },

    navClasses() {
      return [
        "border",
        "border-top-0",
        "border-right-0",
        "border-bottom-1",
        "border-left-0",
        "bg-light",
      ];
    },
  },

  methods: {
    setActiveTab(tab) {
      this.activeTab = tab;
    },

    async copyCode() {
      const code = this.$slots.code()[0].children[0].children[0].text;

      await navigator.clipboard.writeText(code);

      this.copyButtonText = "Copied";
      this.copyButtonVariant = "success";

      setTimeout(() => {
        this.copyButtonText = "Copy";
        this.copyButtonVariant = "light";
      }, 3000);
    },

    tabButtonAttrs(tab) {
      return {
        id: `${tab}-tab`,
        type: "button",
        role: "tab",
        "aria-controls": tab,
        "aria-selected": this.activeTab === tab,
      };
    },

    tabButtonClasses(tab) {
      return [
        "nav-link",
        "rounded-0",
        "border",
        "border-top-0",
        "border-right-1",
        "border-bottom-0",
        "border-left-0",
        {
          "bg-white": this.activeTab === tab,
        },
      ];
    },

    tabContentAttrs(tab) {
      return {
        role: "tabpanel",
        "aria-labelledby": `${tab}-tab`,
      };
    },

    tabContentClasses(tab) {
      return [
        "tab-pane",
        "fade",
        "bg-white",
        "position-relative",
        "rounded-bottom",
        {
          show: this.activeTab === tab || this.sideBySide,
          active: this.activeTab === tab || this.sideBySide,
          "p-3": tab === "preview",
        },
      ];
    },
  },
};
</script>
