<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <h3 class="mb-0">
            {{
              $t(
                "views.companies.evaluation_forms.evaluations.header.evaluation_header",
                {
                  id: evaluationForm.id,
                  title: evaluationTitle,
                }
              )
            }}
            <br />

            <template v-if="evaluationForm.evaluation_report">
              <small
                v-if="evaluationForm.evaluation_report.file_generated_at"
                class="text-muted"
              >
                {{
                  $t("models.evaluation_form.inactived_at", {
                    inactived_at: $d(
                      new Date(
                        evaluationForm.evaluation_report.file_generated_at
                      ),
                      { format: "date" }
                    ),
                  })
                }}
              </small>

              <small v-else class="text-muted">
                {{ $t("activerecord.attributes.evaluation_form.due_at") }}:
                {{ $d(new Date(evaluationForm.due_at), "date") }}
              </small>
            </template>

            <small v-else class="text-muted">
              {{ $t("activerecord.attributes.evaluation_form.due_at") }}:
              {{ $d(new Date(evaluationForm.due_at), "date") }}
            </small>
          </h3>
        </div>

        <div class="col-auto">
          <be-dropdown v-if="platformAdmin" ellipsis>
            <be-dropdown-item
              variant="danger"
              data-method="delete"
              :href="url(`/evaluation_forms/${evaluationForm.id}`)"
              :data-confirm="
                $t('nav.confirm_delete_w_title', {
                  title: truncateText(evaluationForm.title, 64),
                })
              "
            >
              {{ $t("buttons.titles.remove") }}
            </be-dropdown-item>
          </be-dropdown>
        </div>
      </div>
    </div>

    <div class="card-body pb-0">
      <!-- Navigation -->
      <be-nav wizard>
        <be-nav-item
          :active="activeState === 'edit'"
          :disabled="!evaluationForm.ready_to_edit"
          @click="changeState('edit')"
        >
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.tabs.edit_evaluation"
            )
          }}
        </be-nav-item>

        <be-nav-item
          :active="activeState === 'collect'"
          :disabled="!evaluationForm.ready_to_invite"
          @click="changeState('collect')"
        >
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.tabs.collect"
            )
          }}
        </be-nav-item>

        <be-nav-item
          :active="activeState === 'report'"
          :disabled="!evaluationForm.ready_to_create_report"
          @click="changeState('report')"
        >
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.tabs.create_report"
            )
          }}
        </be-nav-item>

        <be-nav-item
          :active="activeState === 'distribute_report'"
          :disabled="!evaluationForm.ready_to_send_report"
          @click="changeState('distribute_report')"
        >
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.tabs.distribute_report"
            )
          }}
        </be-nav-item>
      </be-nav>
    </div>

    <!-- Content -->
    <div class="card-body">
      <!-- Edit -->
      <div v-if="evaluationForm && activeState == 'edit'">
        <div class="my-2 mt-lg-0">
          {{ $t("activerecord.attributes.evaluation_form.title") }}:
          {{ evaluationTitle }}
        </div>

        <div class="mb-3">
          {{ $t("activerecord.attributes.evaluation_form.due_at") }}:
          {{ dueAt }}
        </div>

        <template v-if="platformAdmin">
          <be-button
            v-be-tooltip="{
              title: $t('models.evaluation_form.closed'),
              disabled: evaluationForm.status === 'active',
            }"
            :href="url(`/evaluation_forms/${evaluationForm.id}/edit`)"
            :disabled="evaluationForm.evaluation_closed"
            variant="outline-primary"
          >
            {{ $t("buttons.titles.edit") }}
          </be-button>
        </template>
      </div>

      <!-- Collect answers -->
      <div v-if="evaluationForm && activeState == 'collect'">
        <div class="d-lg-flex justify-content-lg-between">
          <div v-if="formInvitations.length > 0 && evaluationForm.due_at">
            <p class="my-2 mt-lg-0">
              {{ $t("activerecord.attributes.evaluation_form.due_at") }}:
              {{ dueAt }}
            </p>

            <p class="mb-3">
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.answer_frequency",
                  {
                    amount: evaluationForm.answer_frequency,
                    total: formInvitations.length,
                  }
                )
              }}
            </p>
          </div>

          <p v-else class="mb-3">
            {{
              $t(
                "components.evaluation_forms.evaluation_form_viewer.evaluation_row.no_invitations_sent"
              )
            }}
          </p>

          <div v-if="formInvitations.length > 0 && platformAdmin">
            <be-button
              :href="handleStatusUrl"
              data-method="POST"
              variant="outline-secondary"
              class="mb-2"
            >
              <template v-if="evaluationForm.status == 'active'">
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.close_evaluation"
                  )
                }}
              </template>

              <template v-else-if="evaluationForm.evaluation_closed">
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.open_evaluation"
                  )
                }}
              </template>
            </be-button>
          </div>
        </div>

        <be-alert v-if="evaluationForm.evaluation_closed" variant="info">
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.closed_evaluation"
            )
          }}
        </be-alert>

        <be-button
          v-if="platformAdmin && evaluationForm.status == 'active'"
          :href="url(`/evaluation_forms/${evaluationForm.id}/invitations`)"
          variant="outline-primary"
        >
          {{
            $t(
              "components.evaluation_forms.evaluation_form_viewer.evaluation_row.show_invitations"
            )
          }}
        </be-button>

        <!-- Invitation table -->
        <table v-if="formInvitations.length > 0" class="table table-hover">
          <thead>
            <tr>
              <th class="col-shrink"></th>

              <th>
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.name"
                  )
                }}
              </th>

              <th class="col-shrink"></th>
            </tr>
          </thead>

          <tbody>
            <!-- Current user invitation row -->
            <tr>
              <td class="col-shrink">
                <user-avatar :user="$currentUser" />
              </td>

              <td>{{ $currentUser.name }}</td>

              <td v-if="hasAnswered($currentUser)" class="col-shrink">
                <i class="fas fa-check-circle mr-1 text-success" />
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.has_answered"
                  )
                }}
              </td>

              <td
                v-else-if="currentUserCanAnswer"
                class="col-shrink text-right"
              >
                <be-button
                  v-be-tooltip="{
                    title: $t(
                      'components.evaluation_forms.evaluation_form_viewer.evaluation_row.closed_evaluation'
                    ),
                    disabled: !evaluationForm.evaluation_closed,
                  }"
                  :href="evaluationFormUrl"
                  variant="primary"
                  size="sm"
                  inline
                  :disabled="evaluationForm.evaluation_closed"
                >
                  <template v-if="evaluationForm.current_user_answered">
                    {{
                      $t(
                        "components.evaluation_forms.evaluation_form_viewer.evaluation_row.continue_to_answer"
                      )
                    }}
                  </template>

                  <template v-else>
                    {{
                      $t(
                        "components.evaluation_forms.evaluation_form_viewer.evaluation_row.answer"
                      )
                    }}
                  </template>
                </be-button>
              </td>

              <td
                v-else-if="!invitedToForm($currentUser) && platformAdmin"
                class="col-shrink text-right"
              >
                <be-button
                  variant="outline-primary"
                  size="sm"
                  :disabled="evaluationForm.evaluation_closed"
                  icon="fa-envelope"
                  inline
                  @click="invite($currentUser, 'form')"
                >
                  {{ $t("buttons.titles.invite") }}
                </be-button>
              </td>

              <td v-else class="col-shrink"></td>
            </tr>

            <!-- Other users invitation rows -->
            <tr v-for="user in invitableUsers" :key="`user-${user.id}`">
              <td class="col-shrink">
                <user-avatar :user="user" />
              </td>

              <td>{{ user.name }}</td>

              <td v-if="hasAnswered(user)" class="col-shrink">
                <i class="fas fa-check-circle mr-1 text-success" />
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.has_answered"
                  )
                }}
              </td>

              <td
                v-else-if="platformAdmin && invitedToForm(user)"
                class="col-shrink text-right"
              >
                <be-button
                  variant="outline-secondary"
                  size="sm"
                  :disabled="evaluationForm.evaluation_closed"
                  icon="fa-envelope"
                  inline
                  @click="reinvite(user)"
                >
                  {{ $t("buttons.titles.remind") }}
                </be-button>
              </td>

              <td
                v-else-if="platformAdmin && !invitedToForm(user)"
                class="col-shrink text-right"
              >
                <be-button
                  variant="outline-primary"
                  size="sm"
                  :disabled="evaluationForm.evaluation_closed"
                  icon="fa-envelope"
                  inline
                  @click="invite(user, 'form')"
                >
                  {{ $t("buttons.titles.invite") }}
                </be-button>
              </td>

              <td v-else class="col-shrink"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Create report -->
      <div v-if="evaluationForm && activeState == 'report'">
        <template v-if="!evaluationForm.evaluation_report">
          <p class="mb-3 mt-2 mt-lg-0">
            {{
              $t(
                "components.evaluation_forms.evaluation_form_viewer.evaluation_row.no_report_created"
              )
            }}
          </p>

          <be-button
            v-if="platformAdmin"
            :href="url(`/evaluation_forms/${evaluationForm.id}/report/new`)"
            variant="outline-primary"
          >
            {{
              $t(
                "components.evaluation_forms.evaluation_form_viewer.evaluation_row.create_report"
              )
            }}
          </be-button>
        </template>

        <template v-else>
          <p class="mb-3 mt-2 mt-lg-0">
            {{
              $t(
                "components.evaluation_forms.evaluation_form_viewer.evaluation_row.report_created"
              )
            }}:
            {{ $d(new Date(evaluationForm.report_created_at), "date") }}
          </p>

          <be-button
            v-if="platformAdmin"
            :href="editReportUrl"
            variant="outline-primary"
          >
            {{ $t("buttons.titles.edit") }}
          </be-button>
        </template>
      </div>

      <!-- Send report -->
      <div v-if="evaluationForm && activeState == 'distribute_report'">
        <div v-if="showReportActions" class="d-md-flex justify-content-between">
          <div class="mb-2 mb-md-3">
            <p v-if="reportSent" class="mb-3 mt-2 mt-lg-0">
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.report_sent"
                )
              }}:
              {{
                $d(new Date(evaluationForm.evaluation_report.sent_at), "date")
              }}
            </p>

            <p v-else class="mt-2 mt-lg-0">
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.no_report_sent"
                )
              }}
            </p>

            <be-button
              v-if="platformAdmin && evaluationForm.evaluation_report"
              :href="reportInvitationsUrl"
              variant="outline-primary"
            >
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.send_report"
                )
              }}
            </be-button>
          </div>

          <div>
            <be-button :href="reportUrl" variant="outline-secondary">
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.view_report"
                )
              }}
            </be-button>

            <be-button
              v-if="reportDocument"
              :href="reportDocument.pdf_url"
              variant="outline-secondary"
              icon="fa-arrow-down-to-bracket"
            >
              {{
                $t(
                  "components.evaluation_forms.evaluation_form_viewer.evaluation_row.download_report"
                )
              }}
            </be-button>
          </div>
        </div>

        <table class="table table-hover">
          <thead>
            <tr>
              <th class="col-shrink"></th>

              <th>
                {{
                  $t(
                    "components.evaluation_forms.evaluation_form_viewer.evaluation_row.name"
                  )
                }}
              </th>

              <th class="col-shrink"></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in invitableUsers" :key="`user-${user.id}`">
              <td class="col-shrink">
                <user-avatar :user="user" />
              </td>

              <td>
                {{ user.name }}
                <div v-if="invitedToReport(user)">
                  <em class="small">
                    {{
                      $d(
                        new Date(reportInvitation(user).created_at),
                        "dateTime"
                      )
                    }}
                  </em>
                </div>
              </td>

              <td
                v-if="platformAdmin && !invitedToReport(user)"
                class="col-shrink text-right"
              >
                <be-button
                  variant="outline-primary"
                  size="sm"
                  icon="fa-envelope"
                  inline
                  @click="invite(user, 'report')"
                >
                  {{ $t("buttons.titles.invite") }}
                </be-button>
              </td>

              <td v-else></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    initialEvaluationForm: {
      type: Object,
      required: true,
    },
  },

  data() {
    const { invitations, ...evaluationForm } = this.cloneDeep(
      this.initialEvaluationForm
    );
    return {
      activeState: this.initialEvaluationForm.active_state,
      evaluationForm: evaluationForm,
      formInvitations: invitations,
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
    }),

    invitableUsers() {
      let companyUsers = this.cloneDeep(this.companyUsers);

      // If no report present, return all company users excecpt
      // current user that is handled separately
      if (!this.evaluationForm.evaluation_report) {
        const currentUserIndex = companyUsers.findIndex(
          (user) => user.id === this.$currentUser.id
        );

        if (currentUserIndex != -1) {
          companyUsers.splice(currentUserIndex, 1);
        }

        return companyUsers;
      }

      // If report present, return all company users that were invited
      // to answer the form
      return this.companyUsers.filter((user) => this.formInvitation(user));
    },

    dueAt() {
      return this.evaluationForm.due_at
        ? this.$i18n.d(new Date(this.evaluationForm.due_at), "date")
        : this.$i18n.t(
            "components.evaluation_forms.evaluation_form_viewer.evaluation_row.no_date_set"
          );
    },

    editReportUrl() {
      return this.url(
        `/evaluation_forms/${this.evaluationForm.id}/report/edit`
      );
    },

    evaluationTitle() {
      return this.evaluationForm.number
        ? `${this.evaluationForm.title} ${this.evaluationForm.number}`
        : this.evaluationForm.title;
    },

    evaluationFormUrl() {
      return this.url(`/evaluation_forms/${this.evaluationForm.id}`);
    },

    handleStatusUrl() {
      return this.url(
        `/evaluation_forms/${this.evaluationForm.id}/handle_evaluation_status`
      );
    },

    formInvitationsUrl() {
      return this.url(
        `/evaluation_forms/${this.evaluationForm.id}/invitations`
      );
    },

    reportUrl() {
      return this.url(`/evaluation_forms/${this.evaluationForm.id}/report`);
    },

    reportInvitationsUrl() {
      return this.url(
        `/evaluation_forms/${this.evaluationForm.id}/report_invitations/`
      );
    },

    reportSent() {
      return (
        this.evaluationForm.evaluation_report &&
        this.evaluationForm.evaluation_report.sent_at
      );
    },

    reportDocument() {
      return (this.evaluationForm.evaluation_report?.documents || [])[0];
    },

    showReportActions() {
      const membership = this.company.memberships.find(
        (membership) => membership.user.id == this.$currentUser.id
      );

      if (membership.policy_level != "limited") {
        return true;
      }

      return !!this.invitedToReport(this.$currentUser);
    },

    finishedFormInvitations() {
      return this.formInvitations.filter((invitation) => invitation.attending);
    },

    currentUserCanAnswer() {
      const invitation = this.formInvitation(this.$currentUser);

      // If the user invitation is marked as attending, user is done.
      if (invitation) {
        return !invitation.attending;
      }

      return false;
    },
  },

  methods: {
    changeState(state) {
      switch (state) {
        case "edit":
          if (this.evaluationForm.ready_to_edit) {
            this.activeState = state;
          }
          break;
        case "collect":
          if (this.evaluationForm.ready_to_invite) {
            this.activeState = state;
          }
          break;
        case "report":
          if (this.evaluationForm.ready_to_create_report) {
            this.activeState = state;
          }
          break;
        case "distribute_report":
          if (this.evaluationForm.ready_to_send_report) {
            this.activeState = state;
          }
      }
    },

    hasAnswered(user) {
      return this.finishedFormInvitations.some(
        (invitation) => invitation.user_id == user.id
      );
    },

    async invite(user, invitationType) {
      let url = "";

      if (invitationType == "form") {
        url = this.formInvitationsUrl;
      } else {
        url = this.reportInvitationsUrl;
      }

      try {
        const response = await axios.post(url, {
          invitation: {
            user_id: user.id,
          },
        });

        if (invitationType == "form") {
          this.formInvitations.push(response.data);
        } else {
          this.evaluationForm.evaluation_report.invitations.push(response.data);
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    formInvitation(user) {
      return this.formInvitations.find(
        (invitation) => invitation.user_id === user.id
      );
    },

    reportInvitation(user) {
      return this.evaluationForm.evaluation_report?.invitations?.find(
        (invitation) => invitation.user_id == user.id
      );
    },

    invitedToForm(user) {
      return !!this.formInvitation(user);
    },

    invitedToReport(user) {
      if (this.evaluationForm.evaluation_report) {
        return !!this.reportInvitation(user);
      }
    },

    async reinvite(user) {
      try {
        const { data } = await axios.patch(this.reinviteUrl(user), {
          invitation: {
            user_id: user.id,
          },
        });

        const index = this.formInvitations.findIndex(
          (invitation) => invitation.id === data.id
        );

        if (index > -1) {
          this.formInvitations[index] = data;
        } else {
          this.formInvitations.push(data);
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    reinviteUrl(user) {
      const invitation = this.formInvitation(user);

      if (invitation) {
        return this.url(
          `/evaluation_forms/${this.evaluationForm.id}/invitations/${invitation.id}/reinvite`
        );
      }

      return "#";
    },
  },
};
</script>
