<template>
  <div v-if="reviews.length > 0" class="card-footer">
    <div class="d-flex justify-content-between align-items-center">
      <h3>{{ reviewsTitle }}</h3>

      <be-button
        v-if="reviews.length > currentReviews.length || showAllReviews"
        variant="outline-secondary"
        @click="showAllReviews = !showAllReviews"
      >
        {{
          showAllReviews
            ? $t("components.meetings.material_show.show_latest")
            : $t("buttons.titles.show_all")
        }}
      </be-button>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th class="col-shrink"></th>

            <th class="col-shrink">
              {{ $t("activerecord.models.user.one") }}
            </th>

            <th></th>

            <th class="col-shrink">
              {{ $t("attributes.status") }}
            </th>

            <th v-if="anyReviewHaveItemComments" class="col-shrink">
              {{
                $t(
                  "components.meetings.material.minutes_review_table.item_comments"
                )
              }}
            </th>

            <th>
              {{ $t("activerecord.models.comment.one") }}
            </th>

            <th class="col-shrink" />
          </tr>
        </thead>

        <tbody>
          <tr v-for="review in currentReviews" :key="`review-${review.id}`">
            <td class="col-shrink">
              <user-avatar :user="avatarUser(review)" />
            </td>

            <td class="col-shrink">
              {{ reviewName(review) }}
              <div class="small">{{ attendanceRole(review.attendance) }}</div>
            </td>

            <td class="col-shrink pr-0">
              <template v-if="review.status !== 'comments_request'">
                <i
                  v-if="reviewLevelClasses(review.status)"
                  class="mr-1 fa-fw"
                  :class="reviewLevelClasses(review.status)"
                />
              </template>
            </td>

            <td class="col-shrink">
              {{ translatedStatus(review.status) }}
              <div class="small">
                {{ $d(new Date(review.created_at), "dateTime") }}
              </div>
            </td>

            <td v-if="anyReviewHaveItemComments" class="col-shrink text-center">
              {{ review.comments.length }}
            </td>

            <td>
              <div
                v-if="review.comment"
                v-dompurify-html="displayReviewComment(review)"
                class="text-pre-wrap d-inline"
              />
              <div v-if="review.comment.length > COMMENT_TRUNCATION_THRESHOLD">
                <be-link @click="toggleExpandedComment(review)">
                  {{
                    reviewWithExpandedCommentId === review.id
                      ? $t("buttons.titles.show_less")
                      : $t("buttons.titles.show_more")
                  }}
                </be-link>
              </div>
            </td>

            <td class="col-shrink">
              <i
                v-if="
                  !showAllReviews && hasMultipleReviews(review.attendance_id)
                "
                v-be-tooltip="
                  $t('components.meetings.material_show.has_multiple_reviews')
                "
                class="fal fa-comments"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";
import { truncateText } from "@/utils/text-utils";

const COMMENT_TRUNCATION_THRESHOLD = 100;
export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    reviews: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showAllReviews: false,
      reviewWithExpandedCommentId: null,
      COMMENT_TRUNCATION_THRESHOLD,
    };
  },

  computed: {
    reviewsPerAttendance() {
      const reviews = {};

      this.reviews.forEach((review) => {
        if (!reviews[review.attendance_id]) {
          reviews[review.attendance_id] = [];
        }

        reviews[review.attendance_id].push(review);
      });

      return reviews;
    },

    currentReviews() {
      if (this.showAllReviews) {
        return this.reviews;
      }

      const lastReviews = [];

      Object.values(this.reviewsPerAttendance).forEach((reviews) => {
        lastReviews.push(reviews[reviews.length - 1]);
      });

      return lastReviews.sort((a, b) => {
        return new Date(a.created_at) - new Date(b.created_at);
      });
    },

    reviewsTitle() {
      if (
        ["review", "approve", "sign", "archived"].includes(
          this.meeting.process.active_state
        )
      ) {
        return this.$t("components.meetings.material_show.reviews");
      } else {
        return this.$t("components.meetings.material_show.comments");
      }
    },

    anyReviewHaveItemComments() {
      return this.reviews.some((review) => review.comments.length > 0);
    },
  },

  methods: {
    truncateText,

    avatarUser(review) {
      return review.attendance?.user_id || review.attendance?.name;
    },

    reviewName(review) {
      return review.attendance?.name;
    },

    translatedStatus(status) {
      if (status === "comments_request") {
        return this.$t("models.material_review.statuses.comments_request");
      } else {
        return this.$t(`models.attendance.review_levels.${status}`);
      }
    },

    hasMultipleReviews(attendanceId) {
      return this.reviewsPerAttendance[attendanceId]?.length > 1;
    },

    toggleExpandedComment(review) {
      this.reviewWithExpandedCommentId =
        this.reviewWithExpandedCommentId === review.id ? null : review.id;
    },

    displayReviewComment(review) {
      if (this.reviewWithExpandedCommentId === review.id) {
        return review.comment;
      } else {
        return truncateText(review.comment, COMMENT_TRUNCATION_THRESHOLD);
      }
    },
  },
};
</script>
