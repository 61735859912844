<template>
  <be-list-group flush>
    <be-list-group-item v-if="platformEnabled('onboarding_bankid')" class="p-4">
      <task-container
        action-key="bank_id_registered"
        section-type="user_info"
        :onboarding-object="$currentUser"
        :hide-button-on-completion="true"
        :skipped="isSkipped('user_info', 'bank_id_registered')"
        :title="$t('bankid.activate_bank_id')"
      >
        <template #text>
          <div class="mb-2">
            {{
              $t(
                "components.onboarding.introduction.user_section.activate_bankid_description"
              )
            }}
          </div>

          <ul>
            <li>
              {{
                $t(
                  "components.onboarding.introduction.user_section.activate_bankid_list_item_one"
                )
              }}
            </li>

            <li>
              {{
                $t(
                  "components.onboarding.introduction.user_section.activate_bankid_list_item_two"
                )
              }}
            </li>
          </ul>
        </template>

        <template #link>
          <div>
            <div class="d-none d-md-inline-block">
              <be-link v-be-modal.bankid class="p-1">
                {{ $t("buttons.titles.activate") }}
              </be-link>

              <p>
                <be-link
                  v-if="
                    !$currentUser.identification_number_verified &&
                    !isSkipped('user_info', 'bank_id_registered')
                  "
                  class="p-1"
                  @click="$emit('bank-id-skipped')"
                >
                  <span class="text-muted small">
                    {{ $t("buttons.titles.skip") }}
                  </span>
                </be-link>
              </p>
            </div>

            <be-button
              v-be-modal.bankid
              class="mt-3 order-1 order-md-2 d-md-none"
              variant="outline-secondary"
              block
            >
              {{ $t("buttons.titles.activate") }}
            </be-button>

            <be-button
              v-if="
                !$currentUser.identification_number_verified &&
                !isSkipped('user_info', 'bank_id_registered')
              "
              variant="outline-secondary"
              class="d-md-none"
              block
              @click="$emit('bank-id-skipped')"
            >
              {{ $t("buttons.titles.skip") }}
            </be-button>
          </div>

          <be-modal
            id="bankid"
            :title="$t('bankid.activate_bank_id')"
            no-footer
          >
            <bank-id-authentication
              begin-authentication-on-mount
              :redirect-on-success="false"
              collect-url="/bank_id/collect/activation"
              @cancel="$beModal.hide('bankid')"
              @bankid-collect-success="activateBankID()"
            />
          </be-modal>
        </template>
      </task-container>
    </be-list-group-item>

    <profile-picture-upload
      section-type="user_info"
      :support-user-settings-url="supportUserSettingsUrl"
    />
  </be-list-group>
</template>

<script>
import TaskContainer from "@/components/onboarding/TaskContainer.vue";
import ProfilePictureUpload from "@/components/onboarding/user/ProfilePictureUpload.vue";

export default {
  components: {
    TaskContainer,
    ProfilePictureUpload,
  },

  emits: ["bank-id-activated", "bank-id-skipped"],

  computed: {
    supportUserSettingsUrl() {
      if (this.$currentUser.locale === "sv") {
        return "https://support.boardeaser.com/hc/sv/articles/360021233440-Hur-%C3%A4ndrar-jag-mina-personliga-inst%C3%A4llningar-";
      }

      return "https://support.boardeaser.com/hc/en-gb/articles/360021233440-How-do-I-change-my-personal-settings-";
    },
  },

  methods: {
    activateBankID() {
      this.$emit("bank-id-activated");

      this.$beModal.hide("bankid");
    },

    isSkipped(sectionKey, actionKey) {
      return this.$currentUser.onboarding_skipped_steps?.includes(
        `${sectionKey}.${actionKey}`
      );
    },
  },
};
</script>
