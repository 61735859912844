<template>
  <div>
    <div class="card mb-2">
      <div class="card-header d-md-flex justify-content-end">
        <be-button v-be-modal.add-category variant="outline-secondary">
          {{ $t("models.category.new") }}
        </be-button>

        <!-- Add category -->
        <be-modal
          id="add-category"
          :title="$t('models.category.new')"
          :ok-title="$t('models.category.create')"
          @ok="handleCategoryOK"
        >
          <be-form-group
            label-for="category-title"
            :label="$t('attributes.title')"
            :error="getErrors(newCategory, ['title'])"
          >
            <be-form-input
              id="category-title"
              v-model="newCategory.title"
              required
              @change="clearErrors(newCategory, ['title'])"
            />
          </be-form-group>
        </be-modal>

        <!-- Add sub category -->
        <be-button
          v-be-modal.add-sub-category
          v-be-tooltip="{
            title: $t('components.contracts.categories.no_categories'),
            disabled: categoriesExists,
          }"
          variant="outline-primary"
          :disabled="!categoriesExists"
        >
          {{ $t("models.sub_category.new") }}
        </be-button>

        <be-modal
          id="add-sub-category"
          :title="$t('models.sub_category.new')"
          :ok-title="$t('models.sub_category.create')"
          @ok="handleSubCategoryOK"
        >
          <be-form-group
            :label="$t('activerecord.models.category.one')"
            label-for="category-id"
          >
            <be-form-select
              id="category-id"
              v-model="newSubCategory.category_id"
              :options="formattedCategories"
            />
          </be-form-group>

          <be-form-group
            label-for="sub-category-title"
            :label="$t('attributes.title')"
            :error="getErrors(newSubCategory, ['title'])"
          >
            <be-form-input
              id="sub-category-title"
              v-model="newSubCategory.title"
              required
              @change="clearErrors(newSubCategory, ['title'])"
            />
          </be-form-group>
        </be-modal>
      </div>

      <div class="card-body">
        <table v-if="categoriesExists" class="table table-hover">
          <thead>
            <tr>
              <th>{{ $t("activerecord.attributes.category.name") }}</th>

              <th class="col-shrink"></th>
            </tr>
          </thead>

          <tbody v-for="category in sortedCategories" :key="category.id">
            <category-row
              :key="`category-${category.id}`"
              :category="category"
              @removed-category="removeCategory"
            />

            <sub-category-row
              v-for="subCategory in sortedSubCategories(
                category.sub_categories
              )"
              :key="`sub-category-${subCategory.id}`"
              :category="category"
              :sub-category="subCategory"
              @removed-sub-category="removeSubCategory"
            />
          </tbody>
        </table>

        <be-alert v-else variant="warning">
          {{ $t("components.contracts.categories.no_categories") }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryRow from "./CategoryRow.vue";
import SubCategoryRow from "./SubCategoryRow.vue";
import { compareText } from "@/utils/text-utils";

export default {
  components: {
    CategoryRow,
    SubCategoryRow,
  },

  props: {
    initialCategories: {
      type: Array,
      required: true,
    },

    categoryType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      categories: this.cloneDeep(this.initialCategories),

      newCategory: {
        title: null,
        category_type: this.categoryType || null,
      },

      newSubCategory: {
        title: null,
        category_id: null,
        type: this.categoryType || null,
      },
    };
  },

  computed: {
    sortedCategories() {
      return this.categories
        .slice()
        .sort((a, b) => compareText(a, b, "title", this.$i18n.locale));
    },

    sortedSubCategories() {
      return (subCategories) => {
        return subCategories
          .slice()
          .sort((a, b) => compareText(a, b, "title", this.$i18n.locale));
      };
    },

    categoriesExists() {
      return this.categories && this.categories.length > 0;
    },

    formattedCategories() {
      return this.categories.map((category) => ({
        text: category.title,
        value: category.id,
      }));
    },
  },

  watch: {
    formattedCategories: {
      handler() {
        this.setCategoryId();
      },

      deep: true,
    },
  },

  mounted() {
    this.setCategoryId();
  },

  methods: {
    /**
     * Categories
     */
    async addCategory() {
      try {
        const response = await axios.post(this.url("/categories"), {
          category: this.newCategory,
        });

        this.$beModal.hide("add-category");

        this.categories.push(response.data);
        this.newCategory.title = null;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.newCategory = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    handleCategoryOK(beModalEvt) {
      beModalEvt.preventDefault();
      this.addCategory();
    },

    removeCategory(removedCategory) {
      let idx = this.categories.findIndex(
        (existingCategory) => existingCategory.id == removedCategory.id
      );
      this.categories.splice(idx, 1);

      // Remove sub categories for removed category
      this.categories.forEach((category) => {
        if (category.id == removedCategory.id) {
          idx = category.sub_categories.findIndex(
            (existingSubCategory) => existingSubCategory.id == category.id
          );
          category.sub_categories.splice(idx, 1);
        }
      });
    },

    /**
     * Subcategories
     */
    async addSubCategory() {
      try {
        const response = await axios.post(this.url("/sub_categories"), {
          sub_category: this.newSubCategory,
        });

        this.categories.forEach((category) => {
          if (category.id == response.data.category_id) {
            category.sub_categories.push(response.data);
          }
        });

        this.$beModal.hide("add-sub-category");
        this.newSubCategory.title = null;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.newSubCategory = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    handleSubCategoryOK(beModalEvt) {
      beModalEvt.preventDefault();
      this.addSubCategory();
    },

    removeSubCategory(subCategory) {
      this.categories.forEach((category) => {
        if (subCategory.category_id == category.id) {
          let idx = category.sub_categories.findIndex(
            (existingSubCategory) => existingSubCategory.id == subCategory.id
          );
          category.sub_categories.splice(idx, 1);
        }
      });
    },

    setCategoryId() {
      this.newSubCategory.category_id =
        this.formattedCategories.length > 0
          ? this.formattedCategories[0].value
          : null;
    },
  },
};
</script>
