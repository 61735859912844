<template>
  <tr>
    <td class="col-shrink">
      <user-avatar :user="user" />
    </td>

    <td class="align-middle">
      {{ userName }}
    </td>

    <td class="text-right align-middle">
      <a
        v-if="!sent"
        href="#"
        class="btn btn-outline-primary btn-sm mb-0"
        @click.prevent="sendLink(invitation.user_id)"
      >
        {{ $t("components.companies.message_threads.send_link") }}
      </a>

      <a
        v-else
        href="#"
        class="btn btn-outline-secondary btn-sm mb-0"
        @click.prevent="sendLink(invitation.user_id)"
      >
        {{ $t("components.companies.message_threads.link_sent") }}
      </a>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    thread: {
      type: Object,
      required: true,
    },

    message: {
      type: Object,
      required: true,
    },

    invitation: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      sent: false,
    };
  },

  computed: {
    user() {
      return this.getUser(this.invitation.user_id);
    },

    userName() {
      return this.user?.name;
    },
  },

  methods: {
    async sendLink(user_id) {
      try {
        await axios.post(
          this.url(
            `/message_threads/${this.thread.id}/messages/${this.message.id}/send_link`
          ),
          { user_id: user_id }
        );

        this.sent = true;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
