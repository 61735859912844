<template>
  <span>
    <be-alert v-if="!modal" v-dompurify-html="bankIdMessage" variant="info" />

    <be-modal
      v-else
      id="bank-id-collect-modal"
      centered
      no-footer
      no-header
      visible
    >
      <be-alert v-dompurify-html="bankIdMessage" variant="info" />
    </be-modal>
  </span>
</template>
<script>
import BankIdMixin from "@/mixins/BankId";
import { EventBus } from "@/event-bus";

export default {
  mixins: [BankIdMixin],

  props: {
    orderRefParam: {
      type: String,
      required: false,
      default: null,
    },

    collectUrl: {
      type: String,
      required: false,
      default: null,
    },

    modal: {
      type: Boolean,
      required: false,
      default: true,
    },

    redirectOnSuccess: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  emits: ["bankid-collect-success"],

  mounted() {
    this.bankIdCollectUrl = this.collectUrl || window.location.pathname;
    this.bankIdRedirectOnSuccess = this.redirectOnSuccess;
    if (/#bank-id-return/.test(window.location.hash)) {
      this.orderRef = localStorage.getItem("lastBankIdOrderRef");
      localStorage.removeItem("lastBankIdOrderRef");
      window.location.hash = "";
    } else {
      this.orderRef = this.orderRefParam;
    }

    if (this.orderRef) {
      this.collect();
      this.collectInterval = setInterval(this.collect, 2000);
    }

    EventBus.on("bankid-collect-success", () => {
      this.$beModal.hide("bank-id-collect-modal");
      this.$emit("bankid-collect-success");
    });
  },
};
</script>
