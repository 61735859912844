<template>
  <div class="mb-4">
    <be-alert v-if="loading" variant="info" loading>
      {{
        $t("components.annual_reports.visualby_annual_report_fetcher.loading")
      }}
    </be-alert>

    <be-alert v-else-if="failed" variant="danger">
      {{
        $t("components.annual_reports.visualby_annual_report_fetcher.failed")
      }}
    </be-alert>

    <div v-else-if="documents.length > 0" class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th class="d-none d-sm-table-cell" />

            <th class="col-shrink">
              {{
                $t(
                  "components.annual_reports.visualby_annual_report_fetcher.headers.created_date"
                )
              }}
            </th>

            <th class="col-shrink">
              {{
                $t(
                  "components.annual_reports.visualby_annual_report_fetcher.headers.locked_date"
                )
              }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="document in documents" :key="document.uuid">
            <td class="d-none d-sm-table-cell">
              {{ formatType(document.type) }}
            </td>

            <td class="col-shrink">{{ formatDate(document.createTime) }}</td>

            <td class="col-shrink">{{ formatDate(document.lockedTime) }}</td>

            <td class="text-right col-shrink">
              <be-button
                v-if="document.lockedTime"
                :loading="fetching"
                variant="outline-primary"
                inline
                @click="fetchDocument(document)"
              >
                {{
                  previousUuid
                    ? $t(
                        "components.annual_reports.visualby_annual_report_fetcher.refresh"
                      )
                    : $t(
                        "components.annual_reports.visualby_annual_report_fetcher.fetch"
                      )
                }}
              </be-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <be-alert v-else variant="info">
      {{
        $t(
          "components.annual_reports.visualby_annual_report_fetcher.no_document"
        )
      }}
    </be-alert>

    <be-alert v-if="documents.length > 0 && !hasLockedDocuments" variant="info">
      {{
        $t(
          "components.annual_reports.visualby_annual_report_fetcher.no_locked_document"
        )
      }}
    </be-alert>

    <div
      v-if="!loading && !hasLockedDocuments"
      class="d-flex justify-content-end mt-2"
    >
      <be-button
        :href="url('/corporate_groups/consolidated_annual_reports')"
        variant="outline-primary"
        class="ml-auto"
      >
        {{
          $t(
            "components.annual_reports.visualby_annual_report_fetcher.go_to_annual_report_tool"
          )
        }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    annualReport: {
      type: Object,
      required: true,
    },

    documentKey: {
      type: String,
      default: "annual_report",
    },
  },

  data() {
    return { failed: false, loading: true, fetching: false, documents: [] };
  },

  computed: {
    hasLockedDocuments() {
      return this.documents.some((doc) => doc.lockedTime);
    },

    previousUuid() {
      return this.annualReport.annual_report_ixbrl_source_uuid;
    },
  },

  async mounted() {
    try {
      const { data } = await axios.get(
        this.url(`/annual_reports/${this.annualReport.id}/visualby_document`)
      );

      if (this.previousUuid) {
        this.documents = data.items.filter(
          ({ uuid }) => uuid == this.previousUuid
        );
      } else {
        this.documents = data.items;
      }
    } catch {
      this.failed = true;
    } finally {
      this.loading = false;
    }
  },

  methods: {
    ...mapMutations({ setAnnualReport: "annual_reports/setAnnualReport" }),

    async fetchDocument(document) {
      const type = this.formatType(document.type);
      const time = this.formatDate(document.lockedTime);
      const includeCertification =
        this.documentKey == "annual_report_w_certification";
      let filename;
      this.fetching = true;
      if (includeCertification) {
        filename = `${type}_${time}_w_certification.ixbrl`;
      } else {
        filename = `${type}_${time}.ixbrl`;
      }

      try {
        const { data } = await axios.post(
          this.url(`/annual_reports/${this.annualReport.id}/visualby_document`),
          {
            key: this.documentKey,
            uuid: document.uuid,
            filename,
          }
        );
        this.setAnnualReport(data.annual_report);
        this.fetching = false;
      } catch {
        // There will be a flash message, and we don't need to do anything else
        this.fetching = false;
      }
    },

    formatDate(dateString) {
      if (dateString) {
        return this.$d(this.parseDate(dateString), "dateTime");
      } else {
        return "-";
      }
    },

    formatType(typeString) {
      if (typeString.startsWith("K3_GROUP")) {
        return this.$t(
          "components.annual_reports.visualby_annual_report_fetcher.k3_group"
        );
      }
      return this.$t(
        "components.annual_reports.visualby_annual_report_fetcher.unknown_type"
      );
    },
  },
};
</script>
