<template>
  <div v-if="!!$currentCompany" :class="{ 'h-100': gridLayout }">
    <div v-if="gridLayout" class="card h-100">
      <div
        class="card-img-top feature-img-container"
        :style="{
          backgroundColor: featureBackgroundColor(feature.name),
        }"
      >
        <be-img
          :src="imageSrc(`icons/features/${feature.name}.svg`)"
          class="feature-img"
          crossorigin="anonymous"
          @error.prevent
        />
      </div>

      <div class="card-body">
        <div class="row align-content-center mb-2">
          <div v-if="hasFeature(feature.name)" class="col-auto text-muted">
            {{ renewalText(feature) }}
          </div>

          <div v-if="addBetaBadge" class="col">
            <div
              class="badge badge-secondary badge-pill"
              :class="{
                'invisible d-none d-md-inline-block': !feature.beta_status,
              }"
            >
              {{ $t("models.feature.beta_status") }}
            </div>
          </div>
        </div>

        <h3>{{ $t(`models.feature.features.${feature.name}.title`) }}</h3>

        <p>
          {{ $t(`models.feature.features.${feature.name}.description`) }}
        </p>

        <p
          v-if="$config.PRODUCTS_REQUIRING_FINANCIALS.includes(feature.name)"
          class="mt-3 text-muted"
        >
          {{ $t("models.feature.requires_financials") }}
        </p>
      </div>

      <div class="card-footer border-top-0 pt-0">
        <h4 v-if="trial" class="text-primary">
          {{ $t("components.onboarding.free_during_trial") }}
        </h4>

        <div v-if="!!feature.price && allowed">
          <h4
            v-if="!!feature.price.value"
            class="text-primary mb-0"
            :class="{ 'mb-1': showPriceVariationDescription }"
          >
            <template v-if="trial">
              <s class="text-black-50 font-weight-normal">
                {{ productPrice(feature) }}
              </s>
            </template>

            <template v-else>
              {{ productPrice(feature) }}
            </template>
          </h4>

          <i18n-t
            v-if="showPriceVariationDescription"
            keypath="components.onboarding.feature_shop.price_variation_description_short_html"
            tag="div"
            class="text-muted small font-italic"
          >
            <template #link>
              <be-link v-be-modal="'price_variations'">
                <span class="small font-italic">
                  {{
                    $t(
                      "components.onboarding.quotation.price_link"
                    ).toLowerCase()
                  }}
                </span>
              </be-link>
            </template>
          </i18n-t>

          <small v-else class="d-none d-md-inline-block invisible">
            &nbsp;
          </small>
        </div>

        <div v-else class="text-muted">
          {{ $t("models.feature.contact_for_price") }}
        </div>
      </div>

      <div class="card-footer row no-gutters">
        <div class="col">
          <template v-if="hasActiveFeature(feature.name)">
            <be-button
              v-if="!hasCancelledFeature(feature)"
              v-be-tooltip="{
                title: $t(
                  'views.companies.shop.index.inactivate_disabled_trial'
                ),
                disabled: !trial,
              }"
              variant="outline-secondary"
              block
              :disabled="loading || trial"
              @click="deactivateFeature"
            >
              {{ $t("buttons.titles.inactivate") }}
            </be-button>

            <be-button
              v-else
              v-be-tooltip="{
                title: $t('components.shop.features.trial_renewal_tooltip'),
                disabled: !trial,
              }"
              :disabled="trial"
              variant="outline-primary"
              block
              @click="renewFeature"
            >
              {{ $t("buttons.titles.reactivate") }}
            </be-button>
          </template>

          <be-button
            v-else
            variant="primary"
            block
            :disabled="loading"
            @click="activateFeatureConfirm"
          >
            {{ $t("buttons.titles.activate") }}
          </be-button>
        </div>
      </div>
    </div>

    <div v-else class="card">
      <div class="row no-gutters">
        <div
          class="feature-img-container-list col-auto"
          :style="{
            backgroundColor: featureBackgroundColor(feature.name),
          }"
        >
          <be-img
            :src="imageSrc(`icons/features/${feature.name}.svg`)"
            class="feature-img"
            crossorigin="anonymous"
            @error.prevent
          />
        </div>

        <div class="col p-3 d-flex flex-column justify-content-between">
          <div
            v-if="feature.beta_status || hasFeature(feature.name)"
            class="d-flex mb-2"
          >
            <div
              v-if="feature.beta_status"
              class="badge badge-secondary badge-pill mr-2"
            >
              {{ $t("models.feature.beta_status") }}
            </div>

            <div v-if="hasFeature(feature.name)" class="small text-muted">
              {{ renewalText(feature) }}
            </div>
          </div>

          <div class="row no-gutters">
            <h3 class="col-12">
              {{ $t(`models.feature.features.${feature.name}.title`) }}
            </h3>

            <p
              class="col-12 col-lg-7"
              :class="{
                'mb-1': $config.PRODUCTS_REQUIRING_FINANCIALS.includes(
                  feature.name
                ),
              }"
            >
              {{ $t(`models.feature.features.${feature.name}.description`) }}
            </p>

            <p
              v-if="
                $config.PRODUCTS_REQUIRING_FINANCIALS.includes(feature.name)
              "
              class="col-12 text-muted"
            >
              {{ $t("models.feature.requires_financials") }}
            </p>
          </div>

          <div class="d-flex align-items-end justify-content-between">
            <div>
              <h4 v-if="trial" class="text-primary">
                {{ $t("components.onboarding.free_during_trial") }}
              </h4>

              <div v-if="!!feature.price && allowed">
                <h4
                  v-if="!!feature.price.value"
                  class="text-primary mb-0"
                  :class="{ 'mb-2': showPriceVariationDescription }"
                >
                  <template v-if="trial">
                    <s class="text-black-50 font-weight-normal">
                      {{ productPrice(feature) }}
                    </s>
                  </template>

                  <template v-else>
                    {{ productPrice(feature) }}
                  </template>
                </h4>

                <i18n-t
                  v-if="showPriceVariationDescription"
                  keypath="components.onboarding.feature_shop.price_variation_description_short_html"
                  tag="div"
                  class="text-muted small font-italic"
                >
                  <template #link>
                    <be-link v-be-modal="'price_variations'">
                      <span class="small font-italic">
                        {{
                          $t(
                            "components.onboarding.quotation.price_link"
                          ).toLowerCase()
                        }}
                      </span>
                    </be-link>
                  </template>
                </i18n-t>
              </div>

              <div v-else class="text-muted">
                {{ $t("models.feature.contact_for_price") }}
              </div>
            </div>

            <div class="d-flex">
              <template v-if="hasActiveFeature(feature.name)">
                <template v-if="!hasCancelledFeature(feature)">
                  <be-button
                    v-be-tooltip="{
                      title: $t(
                        'views.companies.shop.index.inactivate_disabled_trial'
                      ),
                      disabled: !trial,
                    }"
                    variant="outline-secondary"
                    block
                    :disabled="loading || trial"
                    @click="deactivateFeature"
                  >
                    {{ $t("buttons.titles.inactivate") }}
                  </be-button>
                </template>

                <be-button
                  v-else
                  v-be-tooltip="{
                    title: $t('components.shop.features.trial_renewal_tooltip'),
                    disabled: !trial,
                  }"
                  :disabled="trial"
                  variant="outline-primary"
                  block
                  @click="renewFeature"
                >
                  {{ $t("buttons.titles.reactivate") }}
                </be-button>
              </template>

              <be-button
                v-else
                variant="primary"
                block
                :disabled="loading"
                @click="activateFeatureConfirm"
              >
                {{ $t("buttons.titles.activate") }}
              </be-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { featureBackgroundColor } from "@/utils/color";

export default {
  props: {
    feature: {
      type: Object,
      required: true,
    },

    layout: {
      type: String,
      required: true,
    },

    trial: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("company", [
      "hasFeature",
      "subscriptionFor",
      "hasActiveFeature",
      "hasCancelledFeature",
    ]),

    addBetaBadge() {
      if (this.hasFeature(this.feature.name) && !this.feature.beta_status) {
        return false;
      }

      return true;
    },

    featureUrl() {
      let translatedUrl = this.$i18n.t(
        `models.feature.url.${this.feature.name}`
      );

      if (translatedUrl && translatedUrl.startsWith("https://")) {
        return translatedUrl;
      }

      return this.$config.WEBSITE_URLS.PRICE_PAGE_URL;
    },

    allowed() {
      return !["economy"].includes(this.feature.name);
    },

    showPriceVariationDescription() {
      return this.feature.has_price_variations;
    },

    gridLayout() {
      return this.layout === "grid";
    },
  },

  methods: {
    featureBackgroundColor,

    async activateFeature() {
      await axios
        .post(
          this.url(
            `/shop/feature_request?feature=${this.feature.name}&type=activate`
          )
        )
        .then((result) => {
          this.$store.commit("company/setCompany", result.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async activateFeatureConfirm() {
      this.loading = true;

      if (this.trial) {
        await this.activateFeature();
      } else {
        const isConfirmed = await this.promptConfirm({
          title: this.$t(
            "views.companies.shop.index.activate_confirmation_title_w_feature",
            {
              feature: this.$t(
                `models.feature.features.${this.feature.name}.title`
              ),
            }
          ),

          description: this.$t(
            "views.companies.shop.index.activate_confirmation_description"
          ),

          confirmButtonText: this.$t("buttons.titles.activate"),
        });

        if (isConfirmed) {
          await this.activateFeature();
        }
      }

      this.loading = false;
    },

    async deactivateFeature() {
      this.loading = true;

      const isConfirmed = await this.promptConfirm({
        title: this.$t(
          "views.companies.shop.index.inactivate_confirmation_title_w_feature",
          {
            feature: this.$t(
              `models.feature.features.${this.feature.name}.title`
            ),
          }
        ),

        description: this.$t(
          "views.companies.shop.index.inactivate_confirmation_description"
        ),

        confirmButtonText: this.$t("buttons.titles.inactivate"),
      });

      if (isConfirmed) {
        try {
          const { data } = await axios.post(
            this.url(
              `/shop/feature_request?feature=${this.feature.name}&type=inactivate`
            )
          );

          this.$store.commit("company/setCompany", data);
        } catch (error) {
          this.handleError(error);
        }
      }

      this.loading = false;
    },

    async renewFeature() {
      this.loading = true;

      const isConfirmed = await this.promptConfirm({
        title: this.$t(
          "views.companies.shop.index.renew_confirmation_title_w_feature",
          {
            feature: this.$t(
              `models.feature.features.${this.feature.name}.title`
            ),
          }
        ),

        text: this.$t(
          "views.companies.shop.index.renew_confirmation_description"
        ),

        confirmButtonText: this.$t("buttons.titles.reactivate"),
      });

      if (isConfirmed) {
        try {
          const { data } = await axios.post(
            this.url(
              `/shop/feature_request?feature=${this.feature.name}&type=renew`
            )
          );

          this.$store.commit("company/setCompany", data);
        } catch (error) {
          this.handleError(error);
        }
      }

      this.loading = false;
    },

    renewalText(feature) {
      const subscription = this.subscriptionFor(feature.id, "Feature");
      if (!subscription) return;

      let translationKey;

      if (this.trial) {
        translationKey = "models.feature.trial_ends_at_w_end_date";
      } else if (subscription.auto_renew) {
        translationKey = "models.feature.renews_at_w_end_date";
      } else {
        translationKey = "models.feature.active_until_w_end_date";
      }

      return this.$t(translationKey, {
        end_date: this.$d(new Date(subscription.ends_at), "date"),
      });
    },

    productPrice(feature) {
      return this.$t("models.feature.determined_price", {
        value: Math.round(feature.price.value / 12),
        currency: feature.price.currency,
      });
    },
  },
};
</script>
