<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t("activerecord.models.compliance.one") }}</th>

          <th
            v-for="role in roles"
            :key="`row-role-${role}`"
            class="rotate-45deg"
          >
            <div>
              <span>
                {{ $t(`models.document.compliances.permission_roles.${role}`) }}
              </span>
            </div>
          </th>
        </tr>
      </thead>

      <tbody>
        <compliance-permissions-row
          v-for="(compliance, index) in persistedCompliances"
          :key="`compliance-${compliance.id}`"
          :compliance="compliance"
          :row-index="index"
          :roles="roles"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import CompliancePermissionsRow from "./CompliancePermissionsRow.vue";

export default {
  components: {
    CompliancePermissionsRow,
  },

  props: {
    compliances: {
      type: Array,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },
  },

  computed: {
    persistedCompliances() {
      return this.compliances.filter((compliance) => compliance.created_at);
    },
  },
};
</script>
