<template>
  <div v-if="!!externalId" class="alert alert-warning">
    {{
      $t("views.users.riksbyggen.index.loading_w_external_id", {
        externalId: externalId,
      })
    }}
  </div>
</template>

<script>
const POLLING_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds
const TIMEOUT = 5 * 1000; // 5 seconds in milliseconds
const timeout = async () => {
  return new Promise((resolve) => {
    setTimeout(resolve, TIMEOUT);
  });
};

export default {
  props: {
    externalId: {
      type: String,
      default: "",
      required: false,
    },
  },

  data() {
    return {
      startedPolling: null,
    };
  },

  computed: {
    queryUrl() {
      return `/users/riksbyggen/${this.externalId}`;
    },
  },

  created() {
    this.polling();
  },

  methods: {
    async polling() {
      if (!this.externalId) {
        return;
      }
      if (!this.startedPolling) {
        this.startedPolling = Date.now();
      }

      if (Date.now() - this.startedPolling > POLLING_DURATION) {
        this.timedOut = true;
      }

      try {
        const { data } = await axios.get(
          `/users/riksbyggen/${this.externalId}`
        );

        if (data.path) {
          window.location.href = data.path;
        } else {
          await timeout();
          await this.polling();
        }
      } catch (error) {
        if (error?.response?.status != 404) {
          this.handleError(error);
        } else {
          await timeout();
          await this.polling();
        }
      }
    },
  },
};
</script>
