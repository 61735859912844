<template>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <h3 v-text="tc('form.description.title')" />

        <p v-text="tc('form.description.body')" />

        <div class="row align-items-end">
          <div class="col-12 col-md-4">
            <be-form-group :label="tc('form.turnover')" label-for="turnover">
              <be-form-select
                id="turnover"
                v-model="companyData.turnover"
                name="company[turnover]"
                :options="companySelectionOptions.turnover"
                @change="onCompanyChange"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-md-4">
            <be-form-group :label="tc('form.employees')" label-for="employees">
              <be-form-select
                id="employees"
                v-model="companyData.employees"
                name="company[employees]"
                :options="companySelectionOptions.employees"
                @change="onCompanyChange"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-md-4">
            <be-form-group>
              <be-form-checkbox
                v-model="companyData.externalOwners"
                name="company[external_owners]"
                @change="onCompanyChange"
              >
                {{ tc("form.external_owners") }}
              </be-form-checkbox>

              <be-form-checkbox
                v-model="companyData.externalBoardMembers"
                name="company[external_board_members]"
                @change="onCompanyChange"
              >
                {{ tc("form.external_board_members") }}
              </be-form-checkbox>
            </be-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <form
        novalidate="novalidate"
        accept-charset="UTF-8"
        method="post"
        :action="url('/documents/compliances/configuration')"
      >
        <input name="utf8" type="hidden" value="✓" />

        <input type="hidden" name="authenticity_token" :value="$csrfToken" />

        <input id="locale" type="hidden" name="locale" value="sv" />

        <input id="_method" type="hidden" name="_method" value="patch" />

        <input
          type="hidden"
          name="company[external_board_members]"
          :value="companyData.externalBoardMembers"
        />

        <input
          type="hidden"
          name="company[external_owners]"
          :value="companyData.externalOwners"
        />

        <input
          type="hidden"
          name="company[employees]"
          :value="companyData.employees"
        />

        <input
          type="hidden"
          name="company[turnover]"
          :value="companyData.turnover"
        />

        <div class="card-body">
          <be-table-simple v-if="currentCompliances.length > 0" class="mb-md-0">
            <thead>
              <tr>
                <th class="col-shrink"></th>

                <th class="col-shrink"></th>

                <th>
                  {{
                    $t(
                      "views.companies.documents.compliances.shared.table.document"
                    )
                  }}
                </th>

                <th class="w-25">
                  {{
                    $t(
                      "views.companies.documents.compliances.shared.table.responsible"
                    )
                  }}
                </th>
              </tr>
            </thead>

            <tbody>
              <ComplianceConfigurationRow
                v-for="(compliance, index) in currentCompliances"
                :key="`compliance-${compliance.index}`"
                v-model:checked="currentCompliances[index].checked"
                :compliance="compliance"
                :law-level="lawLevel"
                :company-level="companyLevel"
                :role-options="responsibleRolesOptions"
              />

              <ComplianceConfigurationEditRow
                v-for="(compliance, index) in newCompliances"
                :key="`compliance-edit-${compliance.index}`"
                v-model:checked="newCompliances[index].checked"
                :compliance="compliance"
                :law-level="lawLevel"
                :company-level="companyLevel"
                :role-options="responsibleRolesOptions"
                @remove-compliance="removeCompliance(compliance)"
              />
            </tbody>

            <tfoot>
              <tr>
                <td colspan="5">
                  <be-button
                    size="sm"
                    variant="outline-secondary"
                    @click="onSelectAllBtn"
                  >
                    {{ selectAllButtonTitle }}
                  </be-button>

                  <be-button
                    size="sm"
                    variant="outline-secondary"
                    @click="onSelectRecommendedBtn"
                  >
                    {{ selectRecommendedButtonTitle }}
                  </be-button>

                  <be-button
                    size="sm"
                    class="float-md-right"
                    variant="outline-secondary"
                    @click="onAddComplianceBtn"
                  >
                    {{
                      $t(
                        "components.compliances.configurations.add_custom_compliance"
                      )
                    }}
                  </be-button>
                </td>
              </tr>
            </tfoot>
          </be-table-simple>

          <be-alert v-else variant="danger">
            {{ $t("documents.no_documents") }}
          </be-alert>
        </div>

        <div class="card-footer d-flex justify-content-end">
          <be-button type="submit" variant="primary">
            {{ $t("buttons.titles.save") }}
          </be-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ComplianceConfigurationRow from "./ConfigurationRow.vue";
import ComplianceConfigurationEditRow from "./ConfigurationEditRow.vue";

// TODO: move constants
const defaultData = {
  defaultRole: "chairman",
  defaultLawLevel: 2,
  defaultCompanyLevel: 2,

  defaultRecommendationLevels: [
    { level: 1, turnover: 3, employees: 3 },
    { level: 2, turnover: 10, employees: 10 },
    { level: 3, turnover: 40, employees: 25 },
    { level: 4, turnover: 100, employees: 80 },
    { level: 5, turnover: 500, employees: 250 }, // type: concern
    { level: 6, turnover: 500, employees: 250 }, // type: publ
    { level: 7, turnover: 501, employees: 251 }, // type: public
  ],
};

const companySelectionOptions = {
  turnover: [
    { value: 3, text: "< 3 MSEK" },
    { value: 10, text: "< 10 MSEK" },
    { value: 40, text: "< 40 MSEK" },
    { value: 100, text: "< 100 MSEK" },
    { value: 500, text: "< 500 MSEK" },
    { value: 501, text: "> 500 MSEK" },
  ],

  employees: [
    { value: 3, text: "< 3" },
    { value: 10, text: "< 10" },
    { value: 25, text: "< 25" },
    { value: 80, text: "< 80" },
    { value: 250, text: "< 250" },
    { value: 251, text: "> 250" },
  ],
};

const castBoolean = (value) =>
  value === true || value === "true" || value === "1" || value === 1;

export default {
  name: "ComplianceConfiguration",

  components: {
    ComplianceConfigurationRow,
    ComplianceConfigurationEditRow,
  },

  props: {
    compliances: {
      type: Array,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      lawLevel: defaultData.defaultLawLevel,
      companyLevel: defaultData.defaultCompanyLevel,
      companySelectionOptions,

      companyData: {
        employees: null,
        turnover: null,
        externalOwners: false,
        externalBoardMembers: false,
      },

      currentCompliances: [],
      newCompliances: [],

      state: {
        allSelected: false,
        recommended: false,
      },
    };
  },

  computed: {
    isNew() {
      return !this.compliances.find((compliance) => compliance.id);
    },

    selectAllButtonTitle() {
      return this.state.allSelected
        ? this.$t("buttons.toggle_all_selection.deselect_all")
        : this.$t("buttons.toggle_all_selection.select_all");
    },

    selectRecommendedButtonTitle() {
      return this.state.recommended
        ? this.tc("form.toggle.deselect")
        : this.tc("form.toggle.select");
    },

    isAllSelected() {
      return (
        this.currentCompliances.filter((c) => c.checked).length ===
          this.compliances.length &&
        this.newCompliances.filter((c) => c.checked).length ===
          this.newCompliances.length
      );
    },

    responsibleRolesOptions() {
      // Format role array into be-form-select options format
      const selectOptions = [];

      this.roles.forEach((group) => {
        const label = group[0];
        const options = [];
        group[1].forEach((role) => {
          options.push({ text: role[0], value: role[1] });
        });
        selectOptions.push({ label, options });
      });

      return selectOptions;
    },
  },

  mounted() {
    this.currentCompliances = this.getCurrentCompliances();
    this.companyData = this.getCompanyData();

    this.updateRecommendationLevels();

    // set recommendations according to company settings
    if (this.isNew) {
      this.state.recommended = true;
      this.setRecommendations();
    }

    if (this.isAllSelected) {
      this.state.allSelected = true;
      this.state.recommended = true;
    }
  },

  methods: {
    tc(key) {
      // translation helper
      return this.$t(
        `views.companies.documents.compliances.configurations.${key}`
      );
    },

    onCompanyChange() {
      // v-model sync issue with checkboxes
      this.$nextTick(() => {
        this.updateRecommendationLevels();
      });
    },

    onSelectAllBtn() {
      // (de)select all items. respect initial data state
      this.state.allSelected = !this.state.allSelected;

      if (this.state.allSelected) {
        this.state.recommended = true;
        this.setSelectCompliances(true);
      } else {
        this.state.recommended = false;
        this.setSelectCompliances(false);
      }
    },

    onSelectRecommendedBtn() {
      this.state.recommended = !this.state.recommended;
      this.setRecommendations();
    },

    onAddComplianceBtn() {
      this.newCompliances.push({
        index: Date.now(),
        checked: true,
        title: "",
        role: defaultData.defaultRole,
      });
    },

    setRecommendations() {
      this.currentCompliances
        .filter((c) => {
          return (
            c.law_level <= this.lawLevel || c.company_level <= this.companyLevel
          );
        })
        .map((compliance) => {
          if (compliance.id && this.state.recommended == true) {
            compliance.checked = true;
          } else if (!compliance.id) {
            compliance.checked = this.state.recommended;
          }
        });

      this.state.allSelected = this.isAllSelected;
    },

    getCurrentCompliances() {
      let index = 0;
      return this.cloneDeep(this.compliances).map((compliance) => {
        compliance.index = index++;
        compliance.checked = compliance.id !== null;
        return compliance;
      });
    },

    getCompanyData() {
      const { preferences } = this.$currentCompany;
      return {
        employees: Number.parseInt(preferences.employees),
        turnover: Number.parseInt(preferences.turnover),
        externalOwners: castBoolean(preferences.external_owners),
        externalBoardMembers: castBoolean(preferences.external_board_members),
      };
    },

    /**
     * Set select state for all compliances
     * @param state {Boolean} Set marked state
     * @param keepInitial {Boolean} keep initial compliances marked
     */
    setSelectCompliances(state) {
      this.currentCompliances.forEach((compliance) => {
        if (compliance.id && state == true) {
          compliance.checked = true;
        } else if (!compliance.id) {
          compliance.checked = state;
        }
      });

      this.newCompliances.forEach((compliance) => {
        if (compliance.id && state == true) {
          compliance.checked = true;
        } else if (!compliance.id) {
          compliance.checked = state;
        }
      });
    },

    updateRecommendationLevels() {
      const { employees, turnover, externalOwners, externalBoardMembers } =
        this.companyData;

      if (this.isNew && this.lawLevel <= defaultData.defaultLawLevel) {
        this.lawLevel = defaultData.defaultLawLevel;
      }

      this.lawLevel = Math.max(
        1,
        [externalBoardMembers, externalOwners].filter((f) => f).length
      );

      if (this.isNew && this.companyLevel <= defaultData.defaultCompanyLevel) {
        this.companyLevel = defaultData.defaultCompanyLevel;
      }

      const employeeLevel = defaultData.defaultRecommendationLevels.find(
        (l) => l.employees === employees
      );
      const turnoverLevel = defaultData.defaultRecommendationLevels.find(
        (l) => l.turnover === turnover
      );
      if (employeeLevel && turnoverLevel) {
        this.companyLevel = Math.max(employeeLevel.level, turnoverLevel.level);
      }
    },

    removeCompliance(compliance) {
      const index = this.newCompliances.indexOf(compliance);

      if (index > -1) {
        this.newCompliances.splice(index, 1);
      }
    },
  },
};
</script>
