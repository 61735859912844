<template>
  <be-modal
    :id="modalId"
    :title="title"
    size="xl"
    no-footer
    @cancel="close"
    @close="close"
  >
    <membership-form
      :membership="localMembership"
      @cancel="close"
      @submit="submit"
    />
  </be-modal>
</template>

<script>
import MembershipForm from "./MembershipForm.vue";

export default {
  components: {
    MembershipForm,
  },

  props: {
    membership: {
      type: Object,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },

    originOptions: {
      type: Object,
      required: true,
    },
  },

  emits: ["close", "submit"],

  data() {
    return {
      localMembership: this.cloneDeep(this.membership),
    };
  },

  computed: {
    title() {
      const beginning = this.$t("components.memberships.edit.title");
      if (this.localMembership.user) {
        if (this.localMembership.user.name) {
          return `${beginning} - ${this.localMembership.user.name}`;
        } else if (this.localMembership.user.email) {
          return `${beginning} - ${this.localMembership.user.email}`;
        }
      }

      return beginning;
    },
  },

  watch: {
    membership(new_value) {
      this.localMembership = this.cloneDeep(new_value);
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },

    submit(membership) {
      this.$emit("submit", membership);
    },
  },
};
</script>
