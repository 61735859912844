<template>
  <div class="card-body">
    <label> {{ $t("activerecord.models.report.other") }} </label>

    <be-table
      v-if="reportReferences.length > 0"
      :items="reportReferences"
      :fields="reportFields"
    >
      <template #title="{ item }">
        <be-link :href="url(`/reports/${item.reference_id}`)">
          {{ item.report.title }}
        </be-link>
      </template>

      <template #period_end="{ item }">
        <template v-if="item.report.period_end">
          {{ $d(new Date(item.report.period_end), { format: "year_month" }) }}
        </template>
      </template>

      <template #report_type="{ item }">
        <template v-if="item.report.report_type != 'general'">
          {{ $t(`models.report.types.${item.report.report_type}`) }}
        </template>
      </template>

      <template #published_at="{ item }">
        {{ $d(new Date(item.report.published_at), { format: "year_month" }) }}
      </template>

      <template #options="{ item }">
        <be-button
          variant="danger"
          size="sm"
          class="ml-2"
          :loading="loading.includes(item.reference_id)"
          icon="fa-times"
          inline
          @click="removeReport(item.reference_id)"
        />
      </template>
    </be-table>

    <be-alert v-else variant="info" class="mt-2">
      {{ $t("components.meetings.material.item_reports.no_reports") }}
    </be-alert>

    <be-button
      v-be-modal="`add-report-${itemId}`"
      variant="primary"
      class="mt-2"
    >
      {{ $t("components.meetings.material.item_reports.add_reports") }}
    </be-button>

    <be-modal
      :id="`add-report-${itemId}`"
      :title="$t('components.meetings.material.item_reports.add_reports')"
      :ok-title="$t('buttons.titles.close')"
      ok-only
      ok-variant="light"
      size="lg"
      @show="fetchReports"
    >
      <div class="d-flex flex-wrap align-items-start justify-content-between">
        <div class="mb-3">
          <be-form-group
            :label="$t('activerecord.attributes.report.report_type')"
            label-for="search-report-type"
          >
            <be-form-select
              v-model="reportType"
              :options="reportTypeOptions"
              :include-blank-option="$t('components.shared.be_table.show_all')"
            />
          </be-form-group>

          <be-form-group
            label-for="dp-input-period-end-start"
            :label="$t('activerecord.attributes.report.period_end')"
          >
            <be-form-datepicker
              id="period-end"
              :model-value="[
                periodEndFrom || undefined,
                periodEndTo || undefined,
              ]"
              type="range"
              @update:start-date="periodEndFrom = $event"
              @update:end-date="periodEndTo = $event"
            />
          </be-form-group>
        </div>
      </div>

      <paginated-table
        :items="publishedReports"
        :fields="reportFields"
        :pagination="pagination"
        :loading="searchLoading"
        @page-changed="paginationChanged"
      >
        <template #title="{ item }">
          <be-link :href="url(`/reports/${item.id}`)">
            {{ item.title }}
          </be-link>
        </template>

        <template #period_end="{ item }">
          <template v-if="item.period_end">
            {{ $d(new Date(item.period_end), { format: "year_month" }) }}
          </template>
        </template>

        <template #report_type="{ item }">
          {{
            item.report_type !== "general"
              ? $t(`models.report.types.${item.report_type}`)
              : ""
          }}
        </template>

        <template #published_at="{ item }">
          {{ $d(new Date(item.published_at), { format: "year_month" }) }}
        </template>

        <template #options="{ item }">
          <be-button
            v-if="itemReferenceFor(item.id)"
            :loading="loading.includes(item.id)"
            variant="danger"
            size="sm"
            icon="fa-times"
            inline
            @click="removeReport(item.id)"
          />

          <be-button
            v-else
            :loading="loading.includes(item.id)"
            size="sm"
            @click="addReport(item)"
          >
            {{ $t("buttons.titles.add_more") }}
          </be-button>
        </template>
      </paginated-table>
    </be-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

import ReportMixin from "@/mixins/reports";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";

export default {
  components: {
    PaginatedTable,
  },

  mixins: [ReportMixin],

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      publishedReports: [],
      loading: [],
      pagination: this.parsePagination({}),
      reportType: null,
      periodEndFrom: null,
      periodEndTo: null,
      searchLoading: true,
    };
  },

  computed: {
    ...mapGetters({
      followUpReferences: "material/followUpReferences",
      hasCorporateGroup: "company/hasCorporateGroup",
    }),

    reportFields() {
      return [
        {
          key: "title",
          label: this.$t("activerecord.attributes.report.title"),
          sortable: false,
        },
        {
          key: "period_end",
          label: this.$t("activerecord.attributes.report.period_end"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "report_type",
          label: this.$t("activerecord.attributes.report.report_type"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "published_at",
          label: this.$t("activerecord.attributes.report.published_at"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "options",
          label: "",
          sortable: false,
          class: "col-shrink text-center",
        },
      ];
    },

    reportReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Report"
      );
    },
  },

  watch: {
    reportType: {
      handler: "fetchReports",
      immediate: true,
    },

    periodEndFrom: {
      handler: "fetchReports",
      immediate: true,
    },

    periodEndTo: {
      handler: "fetchReports",
      immediate: true,
    },
  },

  methods: {
    ...materialHelpers.mapActions([
      "addFollowUpReference",
      "removeFollowUpReference",
    ]),

    async fetchReports(resetPage) {
      const page = resetPage ? 1 : this.pagination.currentPage;
      try {
        const { data, headers } = await axios.get(
          this.url("/reports/published"),
          {
            params: {
              page: page,
              report_type: this.reportType,
              period_end_from: this.periodEndFrom,
              period_end_to: this.periodEndTo,
            },
          }
        );
        this.publishedReports = data;
        this.pagination = this.parsePagination(headers);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.searchLoading = false;
      }
    },

    itemReferenceFor(report_id) {
      return this.reportReferences.find((reference) => {
        return (
          reference.reference_type == "Report" &&
          reference.reference_id == report_id
        );
      });
    },

    async addReport(report) {
      this.loading.push(report.id);
      await this.addFollowUpReference({
        itemId: this.itemId,
        reference_type: "Report",
        reference_id: report.id,
      });
      this.loading = this.loading.filter((id) => id != report.id);
    },

    async removeReport(report_id) {
      this.loading.push(report_id);
      const reference = this.itemReferenceFor(report_id);
      await this.removeFollowUpReference(reference);
      this.loading = this.loading.filter((id) => id != report_id);
    },

    parsePagination(headers) {
      return {
        currentPage: parseInt(headers["current-page"]) || 1,
        pageItems: parseInt(headers["page-items"]) || 20,
        totalCount: parseInt(headers["total-count"]) || 0,
        totalPages: parseInt(headers["total-pages"]) || 0,
      };
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.fetchReports();
    },
  },
};
</script>
