<template>
  <div class="card">
    <div v-if="canEdit" class="card-header d-md-flex justify-content-end">
      <be-dropdown ellipsis>
        <be-dropdown-item variant="danger" @click="deleteContact">
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </div>

    <form @submit.prevent="saveContact">
      <div class="card-body">
        <be-alert v-if="showUpdateAlert" :variant="updateAlertVariant">
          {{ updateAlertText }}
        </be-alert>

        <div class="row">
          <div class="col-12 col-md-7">
            <div class="row">
              <div class="col-12 col-lg-6">
                <!-- Name -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.name')"
                  label-for="name"
                  :error="getErrors(localContact, 'name')"
                  required
                >
                  <be-form-input
                    id="name"
                    v-model="localContact.name"
                    @change="clearErrors(localContact, 'name')"
                  />
                </be-form-group>
              </div>

              <div class="col-12 col-lg-6">
                <!-- Function -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.function')"
                  label-for="function"
                  :error="getErrors(localContact, 'function')"
                >
                  <be-form-input
                    id="function"
                    v-model="localContact.function"
                    @change="clearErrors(localContact, 'function')"
                  />
                </be-form-group>
              </div>

              <div class="col-12 col-lg">
                <!-- Image -->
                <!-- The ID on the form group is purely for system tests -->
                <be-form-group
                  id="contact-image"
                  :label="$t('activerecord.attributes.contact.image')"
                  label-for="image"
                  :error="getErrors(localContact, 'image')"
                >
                  <single-file-uploader
                    v-model:remove="localContact.remove_image"
                    :file="localContact.image || {}"
                    :accepted-types="acceptedFileTypes"
                    @file-updated="(file) => (localContact.image = file)"
                    @uploading="(value) => (uploadingFiles = value)"
                  />
                </be-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6">
                <!-- Email -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.email')"
                  label-for="email"
                  :error="getErrors(localContact, 'email')"
                >
                  <be-form-input
                    id="email"
                    v-model="localContact.email"
                    type="email"
                    @change="clearErrors(localContact, 'email')"
                  />
                </be-form-group>

                <!-- Telephone -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.telephone')"
                  label-for="telephone"
                  :error="getErrors(localContact, 'telephone')"
                >
                  <be-tel-input
                    id="telephone"
                    v-model="localContact.telephone"
                    @change="clearErrors(localContact, 'telephone')"
                  />
                </be-form-group>

                <!-- Locale -->
                <be-form-group
                  :label="translateAttribute('contact', 'locale')"
                  label-for="locale"
                  :error="getErrors(localContact, 'locale')"
                >
                  <be-form-select
                    id="locale"
                    v-model="localContact.locale"
                    preset-options="locales"
                    @change="clearErrors(localContact, 'locale')"
                  />
                </be-form-group>
              </div>

              <div class="col-12 col-lg-6">
                <!-- Company name -->
                <be-form-group
                  :label="translateAttribute('contact', 'company_name')"
                  label-for="company_name"
                  :error="getErrors(localContact, 'company_name')"
                >
                  <be-form-input
                    id="company_name"
                    v-model="localContact.company_name"
                    @change="clearErrors(localContact, 'company_name')"
                  />
                </be-form-group>

                <!-- Address -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.address')"
                  label-for="address"
                  :error="getErrors(localContact, 'address')"
                >
                  <be-form-input
                    id="address"
                    v-model="localContact.address"
                    @change="clearErrors(localContact, 'address')"
                  />
                </be-form-group>

                <div class="row">
                  <div class="col-12 col-lg-5">
                    <!-- Zip Code -->
                    <be-form-group
                      :label="$t('activerecord.attributes.contact.zipcode')"
                      label-for="zipcode"
                      :error="getErrors(localContact, 'zipcode')"
                    >
                      <be-form-input
                        id="zipcode"
                        v-model="localContact.zipcode"
                        @change="clearErrors(localContact, 'zipcode')"
                      />
                    </be-form-group>
                  </div>

                  <div class="col-12 col-lg-7">
                    <!-- City -->
                    <be-form-group
                      :label="$t('activerecord.attributes.contact.city')"
                      label-for="city"
                      :error="getErrors(localContact, 'city')"
                    >
                      <be-form-input
                        id="city"
                        v-model="localContact.city"
                        @change="clearErrors(localContact, 'city')"
                      />
                    </be-form-group>
                  </div>
                </div>

                <!-- Country -->
                <be-form-group
                  :label="$t('activerecord.attributes.contact.country')"
                  label-for="country"
                  :error="getErrors(localContact, 'country')"
                >
                  <be-form-select
                    id="country"
                    v-model="localContact.country"
                    preset-options="countries"
                    @change="clearErrors(localContact, 'country')"
                  />
                </be-form-group>
              </div>
            </div>

            <be-form-group
              :label="$t('activerecord.models.document.other')"
              label-for="document-uploader"
            >
              <document-uploader
                id="document-uploader"
                :documents="localDocuments"
                @document-added="documentAdded"
                @document-removed="documentRemoved"
                @uploading="(value) => (uploadingFiles = value)"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-md-5">
            <!-- Notes -->
            <be-form-group
              :label="translateAttribute('contact', 'notes')"
              label-for="notes"
              :error="getErrors(localContact, 'notes')"
            >
              <be-form-textarea
                id="notes"
                v-model="localContact.notes"
                rows="3"
                @change="clearErrors(localContact, 'notes')"
              />
            </be-form-group>

            <div class="d-flex align-items-center mb-2">
              <h3 class="mr-2 mb-0">
                {{ $t("views.companies.contacts.contact_form.social_media") }}
              </h3>

              <i
                v-be-tooltip="
                  $t(
                    'views.companies.contacts.contact_form.social_media_format'
                  )
                "
                class="fal fa-question-circle"
              />
            </div>

            <be-form-group
              v-for="socialMedia in Object.keys(
                localContact.social_media || {}
              )"
              :key="socialMedia"
              :label="capitalize(socialMedia)"
              :label-for="socialMedia"
            >
              <be-form-input
                :id="socialMedia"
                v-model="localContact.metadata.social_media[socialMedia]"
              />
            </be-form-group>
          </div>
        </div>
      </div>

      <div class="card-footer text-right">
        <be-button
          variant="primary"
          type="submit"
          :disabled="uploadingFiles"
          :loading="saving"
        >
          {{
            localContact.id
              ? $t("buttons.titles.save")
              : $t("models.contact.create")
          }}
        </be-button>
      </div>
    </form>
  </div>
</template>

<script>
import { IMAGE_TYPES } from "@/constants/file-types";
import { capitalize } from "@/utils/text-utils";

export default {
  props: {
    contact: {
      type: Object,
      required: true,
    },

    documents: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      localContact: this.cloneDeep(this.contact),
      localDocuments: this.cloneDeep(this.documents),
      saving: false,
      uploadingFiles: false,
    };
  },

  computed: {
    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    canEdit() {
      return this.localContact.id && this.localContact.policy?.edit;
    },

    showUpdateAlert() {
      return this.localContact.needs_update || this.localContact.suggest_update;
    },

    updateAlertText() {
      return this.localContact.needs_update
        ? this.$t("views.companies.contacts.contact_form.needs_update")
        : this.$t("views.companies.contacts.contact_form.suggest_update");
    },

    updateAlertVariant() {
      return this.localContact.needs_update ? "warning" : "info";
    },
  },

  watch: {
    contact: {
      handler(contact) {
        this.localContact = this.cloneDeep(contact);
      },

      deep: true,
    },

    documents: {
      handler(documents) {
        this.localDocuments = this.cloneDeep(documents);
      },

      deep: true,
    },
  },

  methods: {
    capitalize,

    async saveContact() {
      this.saving = true;

      // Prepare the contact object
      const contact = this.prepareContact();

      // Define URL and method
      const url = contact.id ? `/contacts/${contact.id}` : "/contacts";
      const method = contact.id ? "put" : "post";

      try {
        const { data } = await axios[method](this.url(url), {
          contact,
        });

        // Redirect to contact page
        window.location.href = this.url(`/contacts/${data.id}`);
      } catch (error) {
        if (error.response?.status === 422) {
          // Handle validation errors
          this.localContact = {
            ...this.localContact,
            ...error.response.data,
          };
        } else {
          this.handleError(error);
        }

        this.saving = false;
      }
    },

    async deleteContact() {
      const isConfirmed = await this.promptRemovalConfirm({
        stayOpenAfterConfirm: true,

        title: this.$t("nav.confirm_delete_w_title", {
          title: this.localContact.name,
        }),
      });

      if (!isConfirmed) {
        return;
      }

      try {
        await axios.delete(this.url(`/contacts/${this.localContact.id}`));
        window.location.href = this.url("/contacts");
      } catch (error) {
        this.handleError(error);
      }
    },

    documentAdded(document) {
      this.localDocuments.push(document);
    },

    documentRemoved(document) {
      const index = this.localDocuments.findIndex(
        (doc) =>
          (doc.id && doc.id === document.id) ||
          (doc.file_uuid && doc.file_uuid === document.file_uuid)
      );

      if (index !== -1) {
        if (document.id) {
          this.localDocuments[index]._destroy = true;
        } else {
          this.localDocuments.splice(index, 1);
        }
      }
    },

    prepareContact() {
      let contact = this.cloneDeep(this.localContact);
      let documents = this.cloneDeep(this.localDocuments);

      // Remove image if it already exists or if it's pending removal
      if (contact.image?.upload_state !== "added" || contact.remove_image) {
        contact.image = null;
      }

      // Remove documents that already exist or is pending removal
      documents = documents.filter(
        (doc) => doc.uploaded_file?.upload_state === "added" || doc._destroy
      );

      // Reformat documents pending removal
      documents = documents.map((doc) => {
        if (doc._destroy) {
          return {
            id: doc.id,
            _destroy: true,
          };
        }

        return doc;
      });

      return {
        ...contact,
        documents_attributes: documents,
      };
    },
  },
};
</script>
