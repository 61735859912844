<template>
  <div>
    <phrase-form
      :phrase="currentPhrase"
      @create="createPhrase"
      @update="updatePhrase"
      @close="closeForm"
    />

    <div class="card">
      <div
        v-if="newPhrase.policy.create"
        class="card-header d-md-flex justify-content-end"
      >
        <be-button variant="outline-primary" @click="addPhrase">
          {{ $t("models.phrase.new") }}
        </be-button>
      </div>

      <div class="card-body">
        <be-table
          v-if="phrases.length > 0"
          :items="phrases"
          :fields="tableFields"
          sort-by="content"
        >
          <template #content="{ item }">
            {{ item.content }}
          </template>

          <template #updated_at="{ item }">
            {{ $d(new Date(item.updated_at), { format: "mini" }) }}
          </template>

          <template #options="{ item }">
            <be-dropdown :id="`phrase-dropdown-${item.id}`" size="sm" ellipsis>
              <be-dropdown-item @click="editPhrase(item)">
                {{ $t("buttons.titles.edit") }}
              </be-dropdown-item>

              <be-dropdown-divider />

              <be-dropdown-item variant="danger" @click="destroyPhrase(item)">
                {{ $t("buttons.titles.destroy") }}
              </be-dropdown-item>
            </be-dropdown>
          </template>
        </be-table>

        <be-alert v-else variant="info">
          {{ $t("views.companies.meetings.phrases.index.no_phrases") }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PhraseForm from "./PhraseForm.vue";

export default {
  components: {
    PhraseForm,
  },

  props: {
    newPhrase: {
      type: Object,
      required: true,
    },

    initialPhrases: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      phrases: "phrases/getPhrases",
      currentPhrase: "phrases/getCurrentPhrase",
    }),

    tableFields() {
      return [
        {
          key: "content",
          label: this.translateAttribute("phrase", "content"),
          sortable: true,
        },
        {
          key: "created_by",
          label: this.translateAttribute("phrase", "created_by"),
          class: "d-none d-md-table-cell col-shrink",
          sortable: true,
        },
        {
          key: "updated_at",
          label: this.translateAttribute("phrase", "updated_at"),
          class: "d-none d-md-table-cell col-shrink text-right",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
        },
      ];
    },
  },

  mounted() {
    this.$store.commit("phrases/addPhrases", this.initialPhrases);
  },

  methods: {
    editPhrase(phrase) {
      this.$store.commit("phrases/editPhrase", phrase);
    },

    addPhrase() {
      this.$store.commit("phrases/editPhrase", this.newPhrase);
    },

    createPhrase(phrase) {
      this.$store.dispatch("phrases/createPhrase", phrase);
    },

    updatePhrase(phrase) {
      this.$store.dispatch("phrases/updatePhrase", phrase);
    },

    async destroyPhrase(phrase) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: phrase.content,
        })
      );

      if (isConfirmed) {
        this.$store.dispatch("phrases/destroyPhrase", phrase);
      }
    },

    closeForm() {
      this.editPhrase({});
    },
  },
};
</script>
