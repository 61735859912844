<template>
  <div class="card mb-2">
    <div class="card-header">
      {{ $t("admin_panels.users.show.id_documents") }}
    </div>

    <div
      v-if="identifications.filter((i) => i._destroy != 1).length > 0"
      class="card-body table-responsive"
    >
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t("admin_panels.users.show.id_document_type") }}</th>

            <th>{{ $t("admin_panels.users.show.expiration_date") }}</th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(identification, index) in identifications"
            v-show="
              !identification.hasOwnProperty('_destroy') ||
              identification._destroy == 0
            "
            :key="index"
          >
            <td>
              <be-form-input
                v-model="identification.identification_type"
                :name="
                  'user[identifications_attributes][' +
                  index +
                  '][identification_type]'
                "
              />

              <input
                v-model="identification.id"
                type="hidden"
                :name="'user[identifications_attributes][' + index + '][id]'"
              />

              <input
                v-model="identification._destroy"
                type="hidden"
                :name="
                  'user[identifications_attributes][' + index + '][_destroy]'
                "
              />
            </td>

            <td>
              <be-form-datepicker
                :name="`user[identifications_attributes][${index}][expires_at]`"
                :model-value="getDate(identification)"
              ></be-form-datepicker>
            </td>

            <td class="col-shrink">
              <be-button
                v-be-tooltip="$t('buttons.titles.remove')"
                variant="danger"
                size="sm"
                icon="fa-times"
                inline
                @click.prevent="removeIdentification(identification)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else class="card-body">
      <be-alert variant="warning">
        {{ $t("admin_panels.users.show.no_id_documents") }}
      </be-alert>
    </div>

    <div class="card-footer">
      <be-button
        variant="outline-secondary"
        size="sm"
        @click="addIdentification"
      >
        {{ $t("admin_panels.users.show.add_identification") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    loadedIdentifications: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      identifications: this.loadedIdentifications,
    };
  },

  mounted() {
    if (!this.identifications.length) {
      this.addIdentification();
    }
  },

  methods: {
    addIdentification() {
      this.identifications.push({
        identification_type: "",
        expires_at: new Date(),
        _destroy: 0,
      });
    },

    removeIdentification(identification) {
      identification._destroy = 1;
    },

    getDate(identification) {
      return format(new Date(identification.expires_at), "yyyy-MM-dd");
    },
  },
};
</script>
