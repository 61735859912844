<template>
  <table class="table mb-0 table-borderless nested-table">
    <thead>
      <div ref="pindetector" />

      <tr
        :class="headerClasses"
        :style="headerStyle"
        @click="expanded = canExpand ? !expanded : expanded"
      >
        <td :class="expandCellClasses">
          <be-button
            v-if="canExpand"
            class="p-0 pl-md-2"
            size="sm"
            variant="link"
          >
            <i :class="expandIconClasses" />
          </be-button>
        </td>

        <slot name="header" />
      </tr>
    </thead>

    <tbody>
      <tr v-if="$slots.body">
        <td :class="expanded && !lastBranch ? 'branch' : ''" />

        <td colspan="4" class="p-0 px-sm-2">
          <slot name="body" />
        </td>
      </tr>

      <slot v-if="expanded" name="children" />
    </tbody>
  </table>
</template>

<script>
// A table section with a sticky first row that doesn't break dropdowns.
// If we just add a sticky-top class to the row it would overlap dropdown
// content. So we watch for intersection changes and add the sticky-top class
// when the row is about to leave the viewport.

/* Helpers */

const onStickyChange = (el, callback) => {
  const observer = new IntersectionObserver(
    ([entry]) => {
      const targetInfo = entry.boundingClientRect;
      const rootBoundsInfo = entry.rootBounds;

      if (!rootBoundsInfo) {
        return;
      }

      // Started sticking.
      if (targetInfo.bottom < rootBoundsInfo.top) {
        callback(true);
      }

      // Stopped sticking.
      if (
        targetInfo.bottom >= rootBoundsInfo.top &&
        targetInfo.bottom < rootBoundsInfo.bottom
      ) {
        callback(false);
      }
    },
    { threshold: [0] }
  );

  observer.observe(el);
  return observer;
};

export default {
  props: {
    lastBranch: {
      type: Boolean,
      default: false,
      required: false,
    },

    level: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    expanded: true,
    hovering: false,
    pinned: false,
    observer: null,
  }),

  computed: {
    canExpand() {
      return this.level > 0 && !this.lastBranch;
    },

    headerStyle() {
      return {
        top: `${this.level * 3}rem`,
        zIndex: this.pinned ? `${100 - this.level}` : 0,

        boxShadow: this.pinned
          ? "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px"
          : "none",
      };
    },

    headerClasses() {
      return [
        "bg-gray",
        {
          "sticky-top": this.pinned,
        },
      ];
    },

    expandIconClasses() {
      return [
        "fal",
        "fa-chevron-right",
        "fa-fw",
        "expand-icon",
        {
          expanded: this.expanded,
        },
      ];
    },

    expandCellClasses() {
      return [
        "expander-cell",
        "col-shrink",
        "p-0",
        "p-sm-1",
        {
          leaf: this.level > 0,
        },
      ];
    },
  },

  watch: {
    lastBranch(value) {
      this.updateStickyWatching(!value);
    },
  },

  mounted() {
    this.updateStickyWatching(!this.lastBranch);
  },

  methods: {
    updateStickyWatching(isOn) {
      if (isOn) {
        this.observer = onStickyChange(this.$refs.pindetector, (pinned) => {
          this.pinned = pinned;
        });
      } else {
        this.pinned = false;
        this.observer?.disconnect();
      }
    },
  },
};
</script>
