<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <h3 v-if="noSupervision">
          {{
            $t(
              "components.annual_reports.email_delivery_tab.archive_annual_report"
            )
          }}
        </h3>

        <h3 v-else>
          {{
            $t(
              "components.annual_reports.email_delivery_tab.send_to_authorities"
            )
          }}
        </h3>

        <template v-if="canSendToAuthorities">
          <p>
            <strong>{{ annualReport.county_name }}</strong>

            <br />
            {{ annualReport.county_email }}
          </p>

          <p>
            {{
              $t("components.annual_reports.email_delivery_tab.switch_county")
            }}
          </p>

          <p>
            {{
              $t(
                "components.annual_reports.email_delivery_tab.send_to_authorities_text"
              )
            }}
          </p>

          <be-alert v-if="sendToAuthoritiesTestEmailText" variant="warning">
            {{ sendToAuthoritiesTestEmailText }}
          </be-alert>

          <be-button
            variant="primary"
            :loading="loading"
            icon="fa-envelope"
            @click="
              confirmTriggerEvent('send_to_authorities', 'email_delivery')
            "
          >
            {{
              $t(
                "components.annual_reports.email_delivery_tab.send_to_authorities"
              )
            }}
          </be-button>
        </template>

        <template v-else-if="canArchive">
          <p>
            {{
              $t(
                "components.annual_reports.email_delivery_tab.selected_no_supervision"
              )
            }}
          </p>

          <p>
            {{
              $t("components.annual_reports.email_delivery_tab.switch_county")
            }}
          </p>

          <be-button
            variant="primary"
            :loading="loading"
            @click="
              confirmTriggerEvent(
                'archive_without_supervision',
                'email_delivery'
              )
            "
          >
            {{
              $t(
                "components.annual_reports.email_delivery_tab.archive_annual_report"
              )
            }}
          </be-button>
        </template>

        <template v-else-if="!annualReport.county_code">
          <p>
            {{
              $t(
                "components.annual_reports.email_delivery_tab.no_county_selected"
              )
            }}
          </p>
        </template>

        <be-alert v-if="sentToAuthoritiesDate" variant="success">
          {{
            $t(
              "components.annual_reports.email_delivery_tab.sent_to_authorities_at",
              {
                date: sentToAuthoritiesDate,
              }
            )
          }}
        </be-alert>

        <be-alert v-if="archivedDate" variant="success">
          {{
            $t("components.annual_reports.email_delivery_tab.archived_at", {
              date: archivedDate,
            })
          }}
        </be-alert>
      </div>

      <div class="col-12 col-xl-6">
        <table
          v-for="uploadedDocument in uploadedDocuments"
          :key="`uploaded-document-${uploadedDocument.id}`"
          class="table table-hover"
        >
          <thead>
            <tr>
              <th colspan="3">
                {{
                  $t(`models.annual_report.documents.${uploadedDocument.key}`)
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="2">
                <document-link
                  :document-id="uploadedDocument.id"
                  :filename="filenameWithSize(uploadedDocument)"
                />
              </td>

              <td class="text-right col-shrink">
                <be-button
                  v-if="canRemoveDocument(uploadedDocument)"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  inline
                  @click="confirmRemoveDocument(uploadedDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";

export default {
  mixins: [AnnualReportTabBase],

  data() {
    return {
      documentForAuthoritiesButton: false,
      loading: false,
    };
  },

  computed: {
    noSupervision() {
      return (
        this.annualReport.county_code == "no_supervision" ||
        !this.annualReport.county_code
      );
    },

    sendToAuthoritiesTestEmailText() {
      let authoritiesTestEmail =
        this.annualReport.send_to_authorities_test_email;
      if (authoritiesTestEmail) {
        return this.$t(
          "components.annual_reports.email_delivery_tab.send_to_authorities_test_email",
          {
            authorities_email_to: authoritiesTestEmail,
            selected_authorities_email: this.annualReport.county_email,
          }
        );
      }
      return false;
    },

    canSendToAuthorities() {
      return this.can("send_to_authorities", "email_delivery", "update");
    },

    canArchive() {
      return this.can(
        "archive_without_supervision",
        "email_delivery",
        "update"
      );
    },

    sentToAuthoritiesDate() {
      return this.datesForTransitions([
        {
          event: "send_to_authorities",
          namespace: "email_delivery",
        },
        {
          event: "send_all_documents_to_authorities",
        },
      ]).pop();
    },

    archivedDate() {
      return this.datesForTransitions([
        {
          event: "archive_without_supervision",
          namespace: "email_delivery",
        },
        {
          event: "archive_without_supervision",
        },
      ]).pop();
    },
  },
};
</script>
