<template>
  <div>
    <be-alert v-if="activated" variant="success">
      {{
        $t(
          "components.user.settings.mfa.email_mfa_activation.activated_since",
          {
            activated_at: $d(activatedSince, "date"),
          }
        )
      }}
    </be-alert>

    <be-button v-else v-be-modal.email-mfa-activation variant="primary">
      {{ $t("buttons.titles.activate") }}
    </be-button>

    <be-modal
      id="email-mfa-activation"
      :title="$t('components.user.settings.mfa.email_mfa_activation.title')"
      header-class="d-print-none"
      footer-class="d-print-none"
      centered
      @shown="onShown"
      @hidden="onModalHidden"
    >
      <!-- If there's no recovery codes, show message that they must create them -->
      <template v-if="step === 'noRecoveryCodes'">
        {{
          $t(
            "components.user.settings.mfa.email_mfa_activation.must_create_recovery_codes"
          )
        }}
      </template>

      <!-- If there are recovery codes, show them -->
      <template v-if="step === 'displayRecoveryCodes'">
        <div class="d-print-none mb-3">
          {{
            $t(
              "components.user.settings.mfa.email_mfa_activation.recovery_codes_created"
            )
          }}
        </div>

        <recovery-codes-table :recovery-codes="recoveryCodes" class="mb-2" />

        <div class="d-md-flex flex-wrap justify-content-center d-print-none">
          <be-button
            class="mr-md-2"
            variant="outline-primary"
            target="_blank"
            :href="url('/users/settings/mfa/recovery_codes.txt')"
            icon="fa-arrow-down-to-bracket"
          >
            {{ $t("buttons.titles.download") }}
          </be-button>

          <be-copy-to-clipboard-button
            class="mr-md-2"
            variant="outline-primary"
            :value="recoveryCodes?.join('\n')"
          />

          <be-button variant="outline-primary" icon="fa-print" @click="print">
            {{ $t("buttons.titles.print") }}
          </be-button>
        </div>
      </template>

      <!-- The view with the code input fields -->
      <template v-if="step === 'emailConfirmation'">
        <be-spinner v-if="loading">
          {{ $t("buttons.states.loading") }}
        </be-spinner>

        <template v-else>
          <p>
            {{
              $t(
                "components.user.settings.mfa.email_mfa_activation.an_activation_code_has_been_sent_enter_it_below"
              )
            }}
          </p>

          <be-code-input
            :error="
              unauthorized
                ? $t(
                    'components.user.settings.mfa.email_mfa_activation.wrong_code'
                  )
                : null
            "
            length="6"
            size="lg"
            justify
            autofocus
            @done="handleDone"
            @input="onCodeInput"
          />

          <be-timer
            v-if="retryActivationCodeRequestAt"
            v-slot="{ timerFinished, formattedDurationRemaining }"
            :finish-at="retryActivationCodeRequestAt"
          >
            <p class="mt-3">
              <template v-if="timerFinished">
                {{
                  $t(
                    "components.user.settings.mfa.email_mfa_activation.did_you_not_receive_code"
                  )
                }}
              </template>

              <template v-else>
                {{
                  $t(
                    "components.user.settings.mfa.email_mfa_activation.did_you_not_receive_code_w_time",
                    { time: formattedDurationRemaining }
                  )
                }}
              </template>
            </p>

            <be-button
              variant="primary"
              :disabled="!timerFinished"
              @click="requestActivationCode"
            >
              {{
                $t(
                  "components.user.settings.mfa.email_mfa_activation.request_new_activation_code"
                )
              }}
            </be-button>
          </be-timer>
        </template>
      </template>

      <template #footer="{ cancel }">
        <be-button variant="light" @click="cancel">
          {{ $t("buttons.titles.cancel") }}
        </be-button>

        <!-- If there are no recovery codes, show button to create them -->
        <be-button
          v-if="step === 'noRecoveryCodes'"
          variant="primary"
          @click="createRecoveryCodes"
        >
          {{ $t("views.users.settings.mfa.recovery_codes.show.create_codes") }}
        </be-button>

        <!-- If there are recovery codes, show button to continue to activation step -->
        <be-button
          v-if="step === 'displayRecoveryCodes'"
          @click="continueToEmailConfirmation"
        >
          {{ $t("buttons.titles.continue") }}
        </be-button>

        <!-- Show button to confirm the code input (if it is not automatically confirmed when inputted) -->
        <be-button
          v-if="step === 'emailConfirmation'"
          variant="primary"
          :disabled="code.length !== 6"
          :loading="confirming"
          @click="handleConfirmEmail"
        >
          {{ $t("buttons.titles.activate") }}
        </be-button>
      </template>
    </be-modal>
  </div>
</template>
<script>
import BeTimer from "@/components/shared/BeTimer.vue";

import RecoveryCodesTable from "./RecoveryCodesTable.vue";

export default {
  components: {
    BeTimer,
    RecoveryCodesTable,
  },

  props: {
    initialRecoveryCodesActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      recoveryCodesActive: this.initialRecoveryCodesActive,
      recoveryCodes: null,
      activatedSince: null,
      code: "",
      step: null,
      retryActivationCodeRequestAt: null,
      unauthorized: false,
      loading: true,
      confirming: false,
    };
  },

  computed: {
    activated() {
      return this.activatedSince !== null;
    },
  },

  mounted() {
    if (this.$currentUser.email_mfa_enabled_at) {
      this.activatedSince = this.parseDate(
        this.$currentUser.email_mfa_enabled_at
      );
    }
  },

  methods: {
    print() {
      window.print();
    },

    async tryConfirm() {
      this.confirming = true;

      try {
        await axios.post("/users/settings/mfa/email", {
          code: this.code,
        });
        this.activatedSince = Date.now();
        this.$beModal.hide("email-mfa-activation");
      } catch (error) {
        if (error.response.status === 401) {
          this.unauthorized = true;
        } else {
          this.handleError(error);
        }
      } finally {
        this.confirming = false;
      }
    },

    async createRecoveryCodes() {
      try {
        const { data } = await axios.post("/users/settings/mfa/recovery_codes");
        this.recoveryCodes = data.recovery_codes;
        this.recoveryCodesActive = true;
        this.step = "displayRecoveryCodes";
      } catch (error) {
        this.handleError(error);
      }
    },

    async continueToEmailConfirmation() {
      await this.requestActivationCode();
      this.step = "emailConfirmation";
    },

    async onShown() {
      this.setDefaultStep();

      if (this.step === "emailConfirmation") {
        await this.requestActivationCode();
      }
    },

    async requestActivationCode() {
      try {
        const { data } = await axios.post(
          "/users/settings/mfa/email/activation_codes"
        );
        this.retryActivationCodeRequestAt = data.retry_at;
      } catch (error) {
        if (error.response.status === 429) {
          this.retryActivationCodeRequestAt = error.response.data.retry_at;
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    async handleConfirmEmail(beModalEvt) {
      beModalEvt.preventDefault();
      await this.tryConfirm();
    },

    handleDone(code) {
      this.code = code;
      this.tryConfirm();
    },

    onCodeInput(code) {
      this.unauthorized = false;
      this.code = code;
    },

    onModalHidden() {
      this.step = null;
    },

    setDefaultStep() {
      if (this.step === null) {
        if (this.recoveryCodesActive) {
          this.step = "emailConfirmation";
        } else {
          this.step = "noRecoveryCodes";
        }
      }
    },
  },
};
</script>
