<template>
  <div v-show="!section._destroy" class="card mb-2">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <be-link
                class="text-decoration-none mr-3"
                @click="toggleExpanded"
              >
                <span class="text-primary">
                  <i
                    class="fal fa-grip-vertical fa-lg mr-2 handle d-none d-md-inline section-draggable-handle"
                    style="cursor: move"
                  />

                  <i
                    class="fal"
                    :class="{
                      'fa-chevron-down': expanded,
                      'fa-chevron-right': !expanded,
                    }"
                  />
                </span>
              </be-link>
            </div>

            <div class="col">
              <div class="row align-items-center no-gutters">
                <h5 class="my-auto">{{ section.position }}</h5>

                <span class="my-auto mx-3">-</span>

                <be-form-group
                  :error="getErrors(section, ['title'])"
                  class="mb-0 col"
                >
                  <be-form-input
                    :model-value="section.title"
                    :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][title]`"
                    required
                    @change="clearErrors(section, ['title'])"
                    @input="update('title', $event)"
                  />
                </be-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="d-none d-lg-flex col-auto align-items-center">
          <be-button
            v-be-tooltip="$t('buttons.titles.remove')"
            size="sm"
            variant="danger"
            icon="fa-times"
            @click="onRemoveSection"
          />
        </div>
      </div>
    </div>

    <div class="card-body">
      <drop-list
        :items="questions"
        class="accordion"
        :accepts-data="(data) => data.id === section.id"
        accepts-type="question"
        @reorder="updatePositions"
      >
        <template #item="{ item: question, index }">
          <drag
            :key="`question-${question.id || question.uuid}`"
            handle=".question-draggable-handle"
            :data="section"
            type="question"
            @dragstart="handleOnDragStart"
            @dragend="handleOnDragStop"
          >
            <evaluation-question
              :expanded="isQuestionExpanded(question.id || question.uuid)"
              :component-indexes="componentIndexes.concat([index])"
              :section="section"
              :loaded-question="question"
              :question-types="questionTypes"
              :dragging="dragging || draggingQuestion"
              class="mb-1"
              @question-removed="updatePositions"
              @toggle-expanded="
                toggleQuestionIdExpanded(question.id || question.uuid)
              "
            />
          </drag>
        </template>

        <!-- This slot must be defined, even if it is empty -->
        <template #feedback></template>
      </drop-list>
    </div>

    <div class="card-footer d-flex justify-content-between align-items-center">
      <div class="custom-control custom-checkbox">
        <input
          :id="`section-save-to-library-${section.id || section.uuid}`"
          :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][added_to_library]`"
          :value="section.added_to_library"
          :checked="section.added_to_library ? true : false"
          type="checkbox"
          class="custom-control-input"
          @input="toggleSaveToLibrary"
        />

        <label
          :for="`section-save-to-library-${section.id || section.uuid}`"
          class="custom-control-label"
        >
          {{
            $t(
              "components.evaluation_forms.editor.library.sections.save_description"
            )
          }}
        </label>
      </div>

      <be-dropdown split right variant="light" size="sm" @click="addQuestion">
        <template #button-content>
          <i class="fas fa-plus mr-1" />
          {{
            $t(
              "views.companies.evaluation_forms.template_section_fields.add_question"
            )
          }}
        </template>

        <be-dropdown-item
          v-be-modal="`question-library-${componentIndexes[0]}`"
        >
          {{
            $t("components.evaluation_forms.editor.library.questions.choose")
          }}
        </be-dropdown-item>
      </be-dropdown>

      <!-- Section library modal -->
      <be-modal
        :id="`question-library-${componentIndexes[0]}`"
        :title="
          $t('components.evaluation_forms.editor.library.questions.title')
        "
        :ok-title="$t('buttons.titles.select')"
        size="lg"
        :ok-disabled="evaluationForm.library_questions.length === 0"
        @ok="addQuestionsFromLibrary"
      >
        <evaluation-library
          v-if="evaluationForm.library_questions.length > 0"
          :items="evaluationForm.library_questions"
          item-type="question"
          @library-item-chosen="selectLibraryQuestion"
        />

        <be-alert v-else variant="info" class="mb-0">
          {{ $t("components.evaluation_forms.editor.library.no_questions") }}
        </be-alert>
      </be-modal>
    </div>

    <input
      v-if="section.id"
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][id]`"
      :value="section.id"
    />

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][position]`"
      :value="section.position"
    />

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][_destroy]`"
      :value="section._destroy"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import omit from "lodash/omit";
import EvaluationLibrary from "./EvaluationLibrary.vue";
import EvaluationQuestion from "./EvaluationQuestion.vue";
import { Drag, DropList } from "vue-easy-dnd";

export default {
  components: {
    Drag,
    DropList,
    EvaluationLibrary,
    EvaluationQuestion,
  },

  props: {
    loadedSection: {
      type: Object,
      required: true,
    },

    questionTypes: {
      type: Array,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },

    dragging: {
      type: Boolean,
      required: true,
    },

    expanded: {
      type: Boolean,
      required: true,
    },

    expandedQuestionIds: {
      type: Array,
      required: true,
    },
  },

  emits: ["set-question-ids-expanded", "section-removed", "toggle-expanded"],

  data() {
    return {
      newQuestions: [],
      draggingQuestion: false,
    };
  },

  computed: {
    ...mapGetters({
      evaluationForm: "evaluation_forms/getEvaluationForm",
    }),

    titleInputName() {
      return `evaluation_form[evaluation_sections_attributes][${this.componentIndexes[0]}][title]`;
    },

    questionIds: {
      get() {
        return this.questions.map((question) => question.id || question.uuid);
      },
    },

    section() {
      return this.$store.getters["evaluation_forms/getSectionById"](
        this.loadedSection.id || this.loadedSection.uuid
      );
    },

    questions: {
      get() {
        return this.$store.getters["evaluation_forms/getQuestionsForSection"](
          this.section
        );
      },

      set(questions) {
        this.$store.dispatch("evaluation_forms/updateQuestions", {
          sectionId: this.section.id || this.section.uuid,
          questions: questions,
        });
      },
    },
  },

  methods: {
    handleOnDragStart(e) {
      this.draggingQuestion = true;

      this.$nextTick(() => {
        e.native.target.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    },

    handleOnDragStop(e) {
      this.draggingQuestion = false;

      this.$nextTick(() => {
        e.native.target.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      });
    },

    toggleQuestionIdExpanded(questionId) {
      if (this.isQuestionExpanded(questionId)) {
        this.setQuestionIdsExpanded([questionId], false);
      } else {
        this.setQuestionIdsExpanded([questionId], true);
      }
    },

    setQuestionIdsExpanded(questionIds, expanded) {
      this.$emit("set-question-ids-expanded", { questionIds, expanded });
    },

    isQuestionExpanded(questionId) {
      return this.expandedQuestionIds.includes(questionId);
    },

    addQuestion(questionData = {}) {
      const uuid = this.generateUuid();
      const question = {
        ...{
          uuid: uuid,
          question_type: "open",
          _destroy: 0,

          section_position:
            this.questions.filter((question) => question._destroy != "1")
              .length + 1,

          possible_answers: [],
        },

        ...questionData,
      };

      this.$store.dispatch("evaluation_forms/addQuestion", {
        sectionId: this.section.id || this.section.uuid,
        question: omit(question, "id"),
      });
      this.setQuestionIdsExpanded([uuid], true);
    },

    addQuestionsFromLibrary() {
      this.newQuestions.forEach((newQuestion) => {
        let possibleAnswers = [];

        newQuestion.possible_answers.forEach((answer) => {
          possibleAnswers.push({
            title: answer.title,
            uuid: this.generateUuid(),
            position: answer.position,
            _destroy: 0,
          });
        });

        this.addQuestion({
          question_title: newQuestion.question_title,
          question_type: newQuestion.question_type,
          possible_answers: possibleAnswers,
        });
      });

      this.newQuestions = [];
    },

    async onRemoveSection() {
      let title = "";

      if (this.section.title) {
        title = this.$t("nav.confirm_delete_w_title", {
          title: this.section.title,
        });
      } else {
        title = this.$t(
          "components.evaluation_forms.editor.section.confirm_delete"
        );
      }

      const isConfirmed = await this.promptRemovalConfirm({
        title: title,

        description: this.$t(
          "components.evaluation_forms.editor.section.confirm_delete_description"
        ),
      });

      if (isConfirmed) {
        await this.$store.dispatch(
          "evaluation_forms/removeSection",
          this.section.id || this.section.uuid
        );
        this.$emit("section-removed", this.section);
      }
    },

    selectLibraryQuestion(newQuestion) {
      let idx = this.newQuestions.findIndex(
        (existingQuestion) => existingQuestion.id == newQuestion.id
      );

      if (idx == -1) {
        this.newQuestions.push({
          id: newQuestion.id,
          question_title: newQuestion.question_title,
          possible_answers: newQuestion.possible_answers,
          question_type: newQuestion.question_type,
        });
      } else {
        this.newQuestions.splice(idx, 1);
      }
    },

    toggleSaveToLibrary() {
      if (this.section.added_to_library) {
        this.update("added_to_library", null);
      } else {
        this.update("added_to_library", new Date().toISOString());
      }
    },

    toggleExpanded() {
      this.$emit("toggle-expanded");
    },

    update(key, value) {
      this.$store.dispatch("evaluation_forms/updateSection", {
        sectionId: this.section.id || this.section.uuid,
        data: { [key]: value },
      });
    },

    updatePositions(event) {
      // Update the DropList data
      if (event.apply) {
        event.apply(this.questions);
      }

      this.$store.dispatch("evaluation_forms/updateQuestionPositions", {
        sectionId: this.section.id || this.section.uuid,
      });
    },
  },
};
</script>
