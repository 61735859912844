<template>
  <be-table
    v-if="decisions.length > 0"
    table-class="vertical-align-top"
    :fields="tableFields"
    :items="decisions"
    :per-page="5"
    :tbody-tr-class="rowClasses"
    sort-by="updated_at"
    sort-desc
  >
    <template #description="{ item }">
      <a
        v-dompurify-html="truncateText(item.description)"
        :href="url(`decisions/${item.id}`)"
        class="d-print-none"
      />

      <span
        v-dompurify-html="truncateText(item.description)"
        class="d-none d-print-inline"
      />
    </template>

    <template #meeting_title="{ item }">
      <meeting-information :owner="item" />

      <div v-if="item.number" class="mb-2">
        <small>
          {{
            $t(
              "views.companies.decisions.decision.decision_reference_w_reference",
              {
                reference: item.human_reference,
              }
            )
          }}
        </small>
      </div>

      <p v-if="displayProjectTitle(item)" class="small text-wrap">
        {{ item.project.display_title }}
      </p>

      <template v-else-if="displayProjectLink(item)">
        <i18n-t
          keypath="views.companies.decisions.decision.project_html"
          tag="small"
          class="d-print-none"
        >
          <template #project>
            <a :href="projectLink(item)">
              {{ item.project.display_title }}
            </a>
          </template>
        </i18n-t>

        <small class="d-none d-print-inline">
          {{ item.project.display_title }}
        </small>
      </template>
    </template>

    <template #user_id="{ item }">
      <user-avatar v-if="item.user_id" :user="item.user_id" />
    </template>

    <template #status="{ item }">
      <template v-if="item.policy && item.policy.edit">
        <be-form-select
          :id="`decision-status-${item.id}`"
          :key="`decision-status-${item.id}`"
          :ref="`decision-${item.id}`"
          v-model="item.status"
          class="custom-select-fixed-width d-print-none"
          :class="{ 'mb-1': loading[item.id] || false }"
          :disabled="loading[item.id]"
          :options="formattedDecisionStatuses"
          @change="updateDecisionStatus(item, $event)"
        />

        <span class="d-none d-print-inline">
          {{ $t(`models.decision.statuses.${item.status}`) }}
        </span>
      </template>

      <span v-else class="decision-status">
        <template v-if="item.done">
          {{ $t("models.decision.statuses.done") }}
        </template>

        <template v-else>
          {{
            $t(
              `models.decision.statuses.${
                item.status || decisionStatuses.active[0]
              }`
            )
          }}
        </template>
      </span>

      <be-spinner v-if="loading[item.id] || false" class="mt-2">
        {{ $t("buttons.states.loading") }}
      </be-spinner>
    </template>

    <template #due_at="{ item }">
      <div v-if="item.due_at">
        {{ $d(new Date(item.due_at), "date") }}
      </div>
    </template>

    <template #updated_at="{ item }">
      {{ $d(new Date(item.updated_at), "date") }}
    </template>
  </be-table>

  <be-alert v-else variant="info">
    {{ $t("components.decisions.no_decisions") }}
  </be-alert>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";
import MeetingInformation from "@/components/shared/meetings/MeetingInformation.vue";
import DecisionStatusMixin from "@/mixins/decisions/";

import Config from "@/config.js";

export default {
  components: { MeetingInformation },

  mixins: [TextUtilities, DecisionStatusMixin],

  props: {
    initialDecisions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ["decision-status-updated"],

  data() {
    return {
      decisions: this.cloneDeep(this.initialDecisions),
      loading: this.mapLoadingFalse(this.initialDecisions),

      decisionStatuses: {
        active: Config.DECISION.STATUSES.ACTIVE,
        inactive: Config.DECISION.STATUSES.INACTIVE,
      },
    };
  },

  computed: {
    tableFields() {
      let fields = [
        {
          key: "description",
          label: this.$i18n.t("activerecord.models.decision.one"),
          sortable: true,
        },
        {
          key: "meeting_title",
          label: this.$i18n.t("activerecord.attributes.decision.meeting_title"),
          sortable: true,
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
        },
      ];

      return fields.concat([
        {
          key: "status",
          label: this.$i18n.t("activerecord.attributes.decision.status_title"),
          sortable: false,
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
        },
        {
          key: "due_at",
          label: this.$i18n.t("activerecord.attributes.decision.due_at_title"),
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
          sortable: true,
        },
        {
          key: "updated_at",
          label: this.$i18n.t("activerecord.attributes.decision.updated_title"),
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
          sortable: true,
        },
        {
          key: "user_id",
          label: this.$i18n.t("activerecord.attributes.decision.assigned_to"),
          class: "d-none d-md-table-cell col-shrink text-center",
          sortable: true,
        },
        {
          key: "user_name",
          label: this.$i18n.t("activerecord.attributes.decision.assigned_to"),
          class: "d-none d-print-table-cell col-shrink",
        },
      ]);
    },

    formattedDecisionStatuses() {
      let formattedStatuses = [];
      let allDecisionStatuses = this.decisionStatuses["active"].concat(
        this.decisionStatuses["inactive"]
      );

      allDecisionStatuses.forEach((status) => {
        formattedStatuses.push({
          text: this.$i18n.t(`models.decision.statuses.${status}`),
          value: status,
        });
      });

      return formattedStatuses;
    },
  },

  watch: {
    // Needed for the tab communication to still work after
    // making a local copy of the decisions
    initialDecisions: {
      handler(newValue) {
        this.decisions = this.cloneDeep(newValue);
        this.loading = this.mapLoadingFalse(this.decisions);
      },

      deep: true,
    },
  },

  methods: {
    async updateDecisionStatus(decision, selectedValue) {
      const statusChange = this.getStatusChange(
        decision.status,
        selectedValue,
        this.decisionStatuses
      );

      if (["active", "inactive"].includes(statusChange)) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t(
            "components.decisions.status.change_status"
          ),

          title: this.$t(
            "components.decisions.status.confirm_status_change_w_title",
            { title: decision.description }
          ),

          description:
            statusChange === "active"
              ? this.$t(
                  "components.decisions.status.confirm_upgrade_description"
                )
              : this.$t(
                  "components.decisions.status.confirm_downgrade_description"
                ),
        });

        if (!isConfirmed) {
          const decisionStatusSelect =
            this.$refs[`decision-${decision.id}`].$el;
          decisionStatusSelect.value = decision.status;
          return false;
        }
      }

      this.loading[decision.id] = true;

      // Only triggers confirm when we're changing status group
      // [from active to inactive or vice versa]
      const response = await axios.put(this.url(`/decisions/${decision.id}`), {
        decision: { status: selectedValue },
      });

      if (response?.data?.decision) {
        this.$emit("decision-status-updated", decision, response.data.decision);
      }
    },

    rowClasses(item) {
      if (!item) return;

      if (item.overdue) {
        return `status-decisions-${item.status} table-danger`;
      }

      return `status-decisions-${item.status}`;
    },

    displayProjectTitle(decision) {
      return (
        decision.project &&
        decision.project.policy.show &&
        decision.project.status == "removed"
      );
    },

    displayProjectLink(decision) {
      return decision.project && decision.project.policy.show;
    },

    projectLink(decision) {
      return this.url(`/projects/${decision.project.id}`);
    },

    // Create mapping of each decision id to false, used for loading status
    mapLoadingFalse(decisions) {
      return decisions.reduce(
        (obj, decision) => ({ ...obj, [decision.id]: false }),
        {}
      );
    },
  },
};
</script>
