<template>
  <be-modal
    :id="modalId"
    :title="modalTitle"
    size="xl"
    body-class="bg-light"
    @show="fetchTask"
  >
    <single-task
      v-if="task"
      :initial-task="task"
      without-header
      @updated="$emit('updated')"
    />

    <template #footer>
      <be-button variant="outline-secondary" :href="url(`tasks/${taskId}`)">
        {{ $t("components.tasks.single_task_modal.go_to_task") }}
      </be-button>

      <be-button variant="light" @click="onClose">
        {{ $t("buttons.titles.close") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import SingleTask from "./SingleTask.vue";

export default {
  components: {
    SingleTask,
  },

  props: {
    taskId: {
      type: Number,
      required: true,
    },

    modalId: {
      type: String,
      required: true,
    },
  },

  emits: ["updated"],

  data() {
    return {
      task: null,
    };
  },

  computed: {
    modalTitle() {
      if (!this.task) {
        return this.$t("activerecord.models.task.one");
      } else {
        return this.task?.description;
      }
    },
  },

  methods: {
    async fetchTask() {
      try {
        const { data } = await axios.get(this.url(`/tasks/${this.taskId}`));

        this.task = data;
      } catch (error) {
        this.handleError(error);
      }
    },

    onClose() {
      this.task = null;

      this.$beModal.hide(this.modalId);
    },
  },
};
</script>
