<template>
  <div class="card">
    <div class="card-body">
      <h2>{{ $t("annual_cycles.new_configuration.title") }}</h2>

      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            label-for="meetings-per-year"
            :label="$t('models.company.labels.meetings_per_year')"
            :description="$t('models.company.hints.meetings_per_year')"
          >
            <be-form-select
              v-model="companySettings.meetings_per_year"
              :options="MEETINGS_PER_YEAR_OPTIONS"
            />
          </be-form-group>

          <be-form-group
            label-for="financial-year-start"
            :label="$t('models.company.labels.financial_year_start')"
            :description="$t('models.company.hints.financial_year_start')"
          >
            <be-form-select
              v-model="companySettings.financial_year_start"
              :options="getAllMonths"
            />
          </be-form-group>

          <be-form-group
            label-for="board-year-start"
            :label="$t('models.company.labels.board_year_start')"
            :description="$t('models.company.hints.board_year_start')"
          >
            <be-form-select
              v-model="companySettings.board_year_start"
              :options="getAllMonths"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            label-for="remind-cfo"
            :label="$t('models.company.labels.remind_cfo')"
            :description="$t('models.company.hints.remind_cfo')"
          >
            <be-form-select
              v-model="companySettings.remind_cfo"
              :options="DAYS_OF_LARGEST_MONTH"
              :include-blank-option="
                $t('models.company.labels.send_no_cfo_reminder')
              "
            />
          </be-form-group>

          <be-form-group
            label-for="days-to-report-after-economy"
            :label="$t('models.company.labels.days_to_report_after_economy')"
            :description="
              $t('models.company.hints.days_to_report_after_economy')
            "
          >
            <be-form-select
              v-model="companySettings.days_to_report_after_economy"
              :options="REPORT_DAYS"
            />
          </be-form-group>

          <be-form-group
            label-for="days-to-hold-meeting-after-report"
            :label="
              $t('models.company.labels.days_to_hold_meeting_after_report')
            "
            :description="
              $t('models.company.hints.days_to_hold_meeting_after_report')
            "
          >
            <be-form-select
              v-model="companySettings.days_to_hold_meeting_after_report"
              :options="REPORT_DAYS"
            />
          </be-form-group>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <be-button variant="primary" @click="saveConfiguration">
          {{ $t("buttons.titles.save_and_continue") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
const DAYS_OF_LARGEST_MONTH = Array(31)
  .fill(1)
  .map((x, y) => x + y);
const MEETINGS_PER_YEAR_OPTIONS = [5, 6];
const REPORT_DAYS = Array(10)
  .fill(1)
  .map((x, y) => x + y);

import { mapGetters } from "vuex";

export default {
  emits: ["configuration-saved"],

  data() {
    return {
      companySettings: {
        board_year_start: null,
        days_to_hold_meeting_after_report: null,
        days_to_report_after_economy: null,
        financial_year_start: null,
        meetings_per_year: null,
        remind_cfo: null,
      },

      DAYS_OF_LARGEST_MONTH: DAYS_OF_LARGEST_MONTH,
      MEETINGS_PER_YEAR_OPTIONS: MEETINGS_PER_YEAR_OPTIONS,
      REPORT_DAYS: REPORT_DAYS,
    };
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),

    getAllMonths() {
      const monthsTranslations = JSON.parse(
        this.$t("date.month_names").replace("nil", "null")
      );

      return Array(12)
        .fill(1)
        .map((x, y) => x + y)
        .map((i) => {
          return { value: i, label: monthsTranslations[i] };
        });
    },
  },

  mounted() {
    this.companySettings.meetings_per_year =
      this.company.preferences["meetings_per_year"] ||
      MEETINGS_PER_YEAR_OPTIONS[0];
    this.companySettings.remind_cfo =
      this.company.preferences["remind_cfo"] || DAYS_OF_LARGEST_MONTH[0];
    this.companySettings.board_year_start =
      this.company.preferences["board_year_start"] || DAYS_OF_LARGEST_MONTH[0];
    this.companySettings.financial_year_start =
      this.company.preferences["financial_year_start"] ||
      DAYS_OF_LARGEST_MONTH[0];
    this.companySettings.days_to_report_after_economy =
      this.company.preferences["days_to_report_after_economy"] ||
      REPORT_DAYS[0];
    this.companySettings.days_to_hold_meeting_after_report =
      this.company.preferences["days_to_hold_meeting_after_report"] ||
      REPORT_DAYS[0];
  },

  methods: {
    async saveConfiguration() {
      try {
        let response = await this.$store.dispatch(
          "company/updateCompanySettings",
          {
            company: this.company,
            companySettings: this.companySettings,
          }
        );

        if (response) {
          this.$emit("configuration-saved");
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
