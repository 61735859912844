<template>
  <div>
    <meeting-display
      v-for="meeting in meetings"
      :key="`meeting-${meeting.id}`"
      :initial-meeting="meeting"
    />
  </div>
</template>

<script>
export default {
  props: {
    initialMeetings: {
      type: Array,
      required: true,
    },

    meetingState: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    meetings() {
      if (this.meetingState == "archived") {
        return this.$store.getters["meetings/getArchivedMeetings"]();
      }

      return this.$store.getters["meetings/getActiveMeetings"];
    },
  },

  mounted() {
    this.$store.dispatch("meetings/addMeetings", this.initialMeetings);
  },
};
</script>
