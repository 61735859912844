<template>
  <be-alert variant="info">
    <!-- Agenda -->
    <template v-if="meeting.process.active_state === 'agenda'">
      <template v-if="!agenda.id">
        {{ $t("components.meetings.tabs.agenda.no_agenda") }}
      </template>

      <template v-else-if="agenda.secretary_done_at == null">
        {{ $t("components.meetings.tabs.agenda.secretary_not_done") }}
      </template>
    </template>

    <!-- Invitations -->
    <template v-if="meeting.process.active_state === 'invitations'">
      <template v-if="meeting.process.invitations_sent">
        {{ $t("components.meetings.invitations.invitations_sent") }}
      </template>

      <template v-else>
        {{ $t("components.meetings.invitations.no_invitations_sent") }}
      </template>
    </template>

    <!-- Minutes -->
    <template v-else-if="meeting.process.active_state === 'minutes'">
      <template
        v-if="
          meeting.process.pre_review_comments_sent ||
          meeting.process.ready_for_comments
        "
      >
        {{ $t("views.companies.meetings.minutes_status.being_commented") }}
      </template>

      <template v-else-if="meeting.process.minutes_started">
        {{ $t("views.companies.meetings.minutes_status.writing") }}
      </template>

      <template v-else-if="meeting.process.minutes_not_started">
        {{ $t("views.companies.meetings.minutes_status.not_started") }}
      </template>
    </template>

    <!-- Review -->
    <template v-else-if="meeting.process.active_state === 'review'">
      {{ $t("views.companies.meetings.minutes_status.in_review") }}
    </template>

    <!-- Approve -->
    <template v-else-if="meeting.process.active_state === 'approve'">
      <template v-if="meeting.process.minutes_review_complete">
        {{ $t("views.companies.meetings.minutes_status.waiting_for_approval") }}
      </template>
    </template>

    <!-- Signature -->
    <template v-else-if="meeting.process.active_state === 'sign'">
      <template v-if="!meeting.is_archived">
        {{ $t("components.meetings.tabs.sign.document_is_being_signed") }}
      </template>
    </template>

    <!-- Archive -->
    <template v-else-if="meeting.process.active_state === 'archived'">
      {{ $t("components.meetings.tabs.archived.description") }}
    </template>
  </be-alert>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },

    agenda: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
};
</script>
