<template>
  <tr v-if="!participant.destroyed">
    <td>{{ participant.user.name }}</td>

    <td>{{ participant.user.email }}</td>

    <td class="col-shrink">
      <be-button
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeParticipant"
      />

      <input
        v-if="participant.id"
        type="hidden"
        :value="participant.id"
        :name="`education_group[education_group_participants_attributes][${index}][id]`"
      />

      <input
        v-if="educationGroup.id"
        type="hidden"
        :value="educationGroup.id"
        :name="`education_group[education_group_participants_attributes][${index}][education_group_id]`"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    educationGroup: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    initialParticipant: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      participant: this.cloneDeep(this.initialParticipant),
    };
  },

  methods: {
    async removeParticipant() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t(
          "components.educations.education_group.education_group_participant.remove_confirm"
        )
      );

      if (isConfirmed) {
        try {
          await axios.delete(
            this.url(
              `/educations/groups/${this.educationGroup.id}/participants/${this.participant.id}`
            )
          );

          this.participant.destroyed = 1;
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
