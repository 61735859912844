<template>
  <div
    class="card"
    :class="{
      show: question.open,
      'border border-light': !question.open,
    }"
  >
    <div class="card-header bg-light">
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <be-link class="mr-3" @click="toggleVisibility">
            <span class="text-primary">
              <i
                class="fal"
                :class="{
                  'fa-chevron-down': question.open,
                  'fa-chevron-right': !question.open,
                }"
              />
            </span>
          </be-link>
        </div>

        <div class="col">
          <h5 class="mb-0">
            {{ question.question_title }}
          </h5>
        </div>

        <div class="col-auto">
          <div class="custom-control custom-checkbox">
            <input
              :id="`include-question-${question.id}-in-library`"
              type="checkbox"
              class="custom-control-input"
              :checked="question.included_in_report"
              @change="toggleSaveToReport()"
            />

            <label
              class="custom-control-label"
              :for="`include-question-${question.id}-in-library`"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-show="question.open" class="card-body border-bottom-0">
      <template v-if="answersPresent">
        <template v-if="question.question_type != 'open'">
          <evaluation-question-graph
            v-if="question.question_type != 'open'"
            :type="graphType"
            :question-data="questionChartData"
            class="mb-3"
          />

          <div v-if="questionChartData.possible_answers" class="mb-5">
            <p>
              {{
                question.question_type == "ranged"
                  ? `1 = ${questionChartData.possible_answers["1"]}`
                  : ""
              }}
            </p>

            <p>
              {{
                question.question_type == "ranged"
                  ? `5 = ${questionChartData.possible_answers["2"]}`
                  : ""
              }}
            </p>
          </div>

          <table
            v-if="question.question_type != 'ranged'"
            class="table table-comfortable table-footable"
          >
            <tbody>
              <tr>
                <th class="hidden-phone col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.typical_value"
                    )
                  }}
                </th>

                <td class="text-right">
                  <p
                    v-for="(
                      amount, answer, index_typical
                    ) in questionChartData.typical_value"
                    :key="index_typical"
                  >
                    {{ amount }} ({{ answer }})
                  </p>
                </td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.number_of_answers"
                    )
                  }}
                </th>

                <td class="text-right">
                  {{ Object.keys(questionChartData.answers).length }}
                </td>
              </tr>
            </tbody>
          </table>

          <table v-else class="table table-comfortable">
            <tbody>
              <tr>
                <th class="col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.average"
                    )
                  }}
                </th>

                <td class="text-right">
                  {{
                    questionChartData.average ? questionChartData.average : "-"
                  }}
                </td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.standard_deviation"
                    )
                  }}
                </th>

                <td class="text-right">
                  {{
                    questionChartData.standard_deviation
                      ? questionChartData.standard_deviation
                      : "-"
                  }}
                </td>
              </tr>

              <tr>
                <th class="hidden-phone col-shrink">
                  {{
                    $t("views.companies.evaluation_forms.reports.results.mean")
                  }}
                </th>

                <td class="text-right">
                  {{ questionChartData.mean ? questionChartData.mean : "-" }}
                </td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.number_of_answers"
                    )
                  }}
                </th>

                <td class="text-right">
                  {{ Object.keys(questionChartData.answers).length }}
                </td>
              </tr>

              <tr>
                <th class="hidden-phone col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.results.typical_value"
                    )
                  }}
                </th>

                <td class="text-right">
                  <p
                    v-for="(
                      amount, answer, index_typical
                    ) in questionChartData.typical_value"
                    :key="index_typical"
                  >
                    {{ amount }} ({{ answer }})
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <template v-else>
          <table
            v-if="Object.keys(questionChartData.attachments).length"
            class="table table-comfortable table-footable"
          >
            <thead>
              <tr>
                <th class="hidden-phone col-shrink">
                  {{
                    $t(
                      "views.companies.evaluation_forms.reports.open_question_results.open_question_table.answer"
                    )
                  }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(attachment, attachment_index) in Object.values(
                  questionChartData.attachments
                )"
                :key="`attachment-${question.id}-${attachment_index}`"
              >
                <td>
                  {{ attachment }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </template>

      <template v-else>
        {{
          $t(
            "views.companies.evaluation_forms.reports.open_question_results.open_question_table.no_answers_present"
          )
        }}
      </template>
    </div>
  </div>
</template>

<script>
import EvaluationQuestionGraph from "./QuestionGraph.vue";

export default {
  components: {
    EvaluationQuestionGraph,
  },

  props: {
    index: {
      type: Number,
      required: true,
    },

    question: {
      type: Object,
      required: true,
    },

    questionChartData: {
      type: Object,
      required: true,
    },
  },

  emits: ["question-visibility-toggled", "question-inclusion-toggled"],

  computed: {
    answersPresent() {
      const { type, answers, attachments } = this.questionChartData;

      if (type === "open") {
        return Object.values(attachments).length > 0;
      } else {
        return Object.keys(answers).length > 0;
      }
    },

    graphType() {
      if (
        (this.question.question_type == "multiple_choices" ||
          this.question.question_type == "one_choice") &&
        Object.keys(this.questionChartData.answers).length <= 5
      ) {
        return "pie";
      } else {
        return "bar";
      }
    },
  },

  methods: {
    toggleVisibility() {
      this.$emit("question-visibility-toggled", this.question);
    },

    toggleSaveToReport() {
      this.$emit("question-inclusion-toggled", this.question);
    },
  },
};
</script>
