<template>
  <fieldset class="mb-0">
    <div
      class="card"
      :class="{
        show: expanded,
      }"
    >
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <span class="text-primary d-inline-flex align-items-center">
              <i
                class="fal fa-grip-vertical mr-3 handle d-none d-md-inline"
                style="cursor: move"
              />

              <be-button
                variant="link"
                class="p-0 mr-3 text-decoration-none"
                @click="toggleVisibility"
              >
                <i
                  :class="[
                    'fas d-none d-lg-inline',
                    {
                      'fa-chevron-down': expanded,
                      'fa-chevron-right': !expanded,
                    },
                  ]"
                />
              </be-button>
            </span>

            <h5 class="d-inline">
              {{ `#${localMeeting.number}` }}
              -
              {{ localMeeting.title }}
            </h5>
          </div>

          <template v-if="localMeeting.start_at">
            <div class="col-12 col-md-auto">
              <i class="fal fa-calendar fa-fw text-primary mr-1" />

              <small>
                {{ $d(new Date(localMeeting.start_at), "short") }}
              </small>
            </div>

            <div class="mb-2 mb-md-0 col-12 col-md-auto">
              <i class="fal fa-clock fa-fw text-primary mr-1" />

              <small>
                {{ $d(new Date(localMeeting.start_at), "time") }}
                -
                {{ $d(new Date(localMeeting.end_at), "time") }}
              </small>
            </div>
          </template>

          <div class="col-12 col-md-auto d-md-flex align-items-center">
            <be-button
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              @click="removeMeeting"
            />
          </div>
        </div>
      </div>

      <div v-show="expanded" class="card-body">
        <be-form-group
          :label="$t('components.annual_cycles.meeting_container.title')"
          label-for="meeting-title"
          :error="getErrors(localMeeting, ['title'])"
        >
          <be-form-input
            id="meeting-title"
            v-model="localMeeting.title"
            :placeholder="$t('agendas.enter_title')"
            required
            @change="updated('title')"
          />
        </be-form-group>

        <be-form-group
          :label="$t('components.annual_cycles.meeting_container.time')"
          class="mb-0"
        >
          <meeting-date-picker :meeting="localMeeting" @update="datesUpdated" />
        </be-form-group>

        <div class="row">
          <div class="col-12 col-md-6">
            <be-form-group
              :label="$t('components.annual_cycles.meeting_container.location')"
              label-for="meeting-location"
              :error="getErrors(localMeeting, ['location'])"
            >
              <be-form-input
                id="meeting-location"
                v-model="localMeeting.location"
                :placeholder="$t('agendas.enter_location')"
                @change="updated('location')"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-md-6">
            <be-form-group
              :label="$t('components.annual_cycles.meeting_container.theme')"
              label-for="meeting-theme"
              :error="getErrors(localMeeting, ['theme'])"
            >
              <be-form-input
                id="meeting-theme"
                v-model="localMeeting.theme"
                :placeholder="$t('agendas.enter_theme')"
                @change="updated('theme')"
              />
            </be-form-group>
          </div>
        </div>

        <be-form-group
          :label="$t('components.annual_cycles.meeting_container.reason')"
          label-for="meeting-reason"
          :error="getErrors(localMeeting, ['reason'])"
        >
          <be-form-textarea
            id="meeting-reason"
            v-model="localMeeting.reason"
            rows="3"
            :state="validationState(localMeeting, ['reason'])"
            @change="updated('reason')"
          />
        </be-form-group>

        <be-form-group
          :label="$t('components.annual_cycles.meeting_container.preparations')"
          label-for="meeting-preparations"
          :invalid-feedback="getErrors(localMeeting, ['preparations'])"
        >
          <be-form-textarea
            id="meeting-preparations"
            v-model="localMeeting.preparations"
            rows="3"
            :state="validationState(localMeeting, ['preparations'])"
            @change="updated('preparations')"
          />
        </be-form-group>
      </div>
    </div>
  </fieldset>
</template>

<script>
import { mapGetters } from "vuex";

import MeetingDatePicker from "@/components/meetings/components/DatePicker.vue";

export default {
  components: {
    MeetingDatePicker,
  },

  props: {
    disabledDates: {
      type: Object,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },
  },

  emits: ["meeting-removed"],

  data() {
    return {
      localMeeting: this.cloneDeep(this.meeting),
      destroyed: null,

      labels: {
        sv: {
          labelCloseButton: this.$t("buttons.titles.close"),

          labelNoDateSelected: this.$t(
            "components.annual_cycles.meeting_container.no_date_selected"
          ),

          labelNoTimeSelected: this.$t(
            "components.annual_cycles.meeting_container.no_time_selected"
          ),
        },

        en: {
          labelCloseButton: this.$t("buttons.titles.close"),

          labelNoDateSelected: this.$t(
            "components.annual_cycles.meeting_container.no_date_selected"
          ),

          labelNoTimeSelected: this.$t(
            "components.annual_cycles.meeting_container.no_time_selected"
          ),
        },
      },

      locale: null,
    };
  },

  computed: {
    ...mapGetters({
      expandedMeetingUuids: "annual_cycle/expandedMeetingUuids",
    }),

    expanded() {
      return this.expandedMeetingUuids.includes(this.localMeeting.uuid);
    },
  },

  watch: {
    meeting(value) {
      this.localMeeting = this.cloneDeep(value);
    },
  },

  mounted() {
    this.locale = this.$currentUser.locale;
  },

  methods: {
    async removeMeeting() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: `#${this.localMeeting.number} - ${this.localMeeting.title}`,
        })
      );

      if (isConfirmed) {
        this.$store.dispatch("annual_cycle/removeMeeting", this.localMeeting);
        this.$emit("meeting-removed");
      }
    },

    toggleVisibility() {
      this.$store.commit("annual_cycle/toggleMeeting", this.localMeeting.uuid);
    },

    updated(attribute) {
      if (attribute) {
        this.clearErrors(this.localMeeting, attribute);
      }
      this.$store.commit("annual_cycle/updateMeeting", this.localMeeting);
    },

    datesUpdated(value) {
      this.clearErrors(this.localMeeting, ["end_at", "start_at"]);
      this.localMeeting.start_at = value.startAt;
      this.localMeeting.end_at = value.endAt;
    },
  },
};
</script>
