<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">{{ $t("users.u2f.authenticate.title") }}</h5>
    </div>

    <div v-if="unableToInitialize" class="card-body">
      <be-alert variant="danger">
        {{ $t("users.u2f.authenticate.unable_to_initialize") }}
      </be-alert>
    </div>

    <div v-else class="card-body">
      <p class="lead">
        {{ $t("users.u2f.authenticate.insert_key") }}
      </p>

      <div v-if="waiting">
        <be-alert v-if="!submitted" loading>
          {{ $t("users.u2f.authenticate.waiting") }}
        </be-alert>

        <be-alert v-else variant="success">
          {{ $t("users.u2f.authenticate.signing_in") }}
        </be-alert>
      </div>

      <be-alert v-if="error" variant="danger">
        {{ error }}
      </be-alert>

      <slot />
    </div>
  </div>
</template>

<script>
import { isSupported, sign } from "u2f-api";

export default {
  props: {
    otpEnabled: {
      type: Boolean,
      required: true,
    },

    signRequests: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      initialized: false,
      unableToInitialize: false,
      submitted: false,
      waiting: true,
      error: null,
    };
  },

  mounted() {
    if (isSupported()) {
      this.initializeU2F();
      this.initialized = true;
    }

    if (this.otpEnabled) {
      this.initializeOTP();
      this.initialized = true;
    }

    if (!this.initialized) {
      this.unableToInitialize = true;
    }
  },

  methods: {
    async initializeU2F() {
      const signResponse = await sign([this.signRequests], 15);

      if (signResponse.errorCode) {
        return this.otpEnabled ? false : this.setError(signResponse.errorCode);
      }

      let response = document.querySelector("[name=response]");
      response.value = JSON.stringify(signResponse);

      this.submitted = true;

      const $form = document.getElementsByName("otp")[0].closest("form");
      $form.submit();
    },

    initializeOTP() {
      const $target = document.getElementsByName("otp")[0];
      const $form = $target.closest("form");

      document.addEventListener("keydown", (e) => {
        if (this.submitted) {
          return;
        }

        if (e.which == 13 || e.keyCode == 13) {
          this.submitted = true;
          $form.submit();
        } else {
          $target.value = $target.value + e.key;
        }
      });
    },

    setError(errorCode) {
      let errorMap = {
        1: this.$t("users.u2f.errors.unknown"),
        2: this.$t("users.u2f.errors.bad_request"),
        3: this.$t("users.u2f.errors.not_supported"),
        4: this.$t("users.u2f.errors.already_registered"),
        5: this.$t("users.u2f.errors.timeout"),
      };

      this.waiting = false;
      this.error = errorMap[errorCode];
    },
  },
};
</script>
