<template>
  <div :class="{ 'd-inline-block': inline }">
    <div>
      <template v-if="userPolicyLevel != 'limited'">
        <a :href="minutesUrl" class="text-wrap d-print-none">
          {{
            $t("models.meeting.title_and_number", {
              title: meetingTitle,
              number: meetingNumber,
            })
          }}
        </a>

        <span class="d-none d-print-inline text-wrap">
          {{
            $t("models.meeting.title_and_number", {
              title: meetingTitle,
              number: meetingNumber,
            })
          }}
        </span>
      </template>

      <span v-else>
        {{
          $t("models.meeting.title_and_number", {
            title: meetingTitle,
            number: meetingNumber,
          })
        }}
      </span>

      <i
        v-if="meetingNotReviewed && inline"
        v-be-tooltip="$t('models.material.minutes.not_reviewed')"
        class="d-print-none fa-duotone fa-triangle-exclamation ml-1 text-warning"
      />
    </div>

    <small v-if="meetingStartAt && !inline">
      {{
        $t("models.meeting.meeting_date", {
          date: $d(new Date(meetingStartAt), "date"),
        })
      }}
    </small>

    <be-alert
      v-if="meetingNotReviewed && !inline"
      variant="warning"
      class="mt-2 p-2"
    >
      <small>
        {{ $t("models.material.minutes.not_reviewed") }}
      </small>
    </be-alert>
  </div>
</template>

<script>
export default {
  props: {
    owner: {
      type: Object,
      required: true,
    },

    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    meetingNotReviewed() {
      if (this.owner.meeting_sealed != undefined) {
        return !this.owner.meeting_sealed;
      } else if (this.owner.meeting_data.review_complete != undefined) {
        return !this.owner.meeting_data.review_complete;
      }

      return false;
    },

    meetingNumber() {
      if (this.owner.meeting_data) {
        return this.owner.meeting_data.meeting_number;
      }

      return this.owner.meeting_number;
    },

    meetingStartAt() {
      if (this.owner.meeting_data) {
        return this.owner.meeting_data.meeting_start_at;
      }

      return this.owner.meeting_start_at;
    },

    meetingTitle() {
      if (this.owner.meeting_data) {
        return this.owner.meeting_data.meeting_title;
      }

      return this.owner.meeting_title;
    },

    minutesUrl() {
      if (this.owner.meeting_data) {
        return this.url(
          `/meetings/${this.owner.meeting_data.meeting_id}/minutes`
        );
      } else {
        return this.url(`/meetings/${this.owner.meeting_id}/minutes`);
      }
    },

    userPolicyLevel() {
      const membership = this.$currentCompany.memberships.find(
        (membership) => membership.user.id === this.$currentUser.id
      );
      return membership?.policy_level || "limited";
    },
  },
};
</script>
