<template>
  <tr>
    <td />

    <td>
      {{ title }}
    </td>

    <td />

    <td />

    <td class="col-shrink">
      {{ $d(new Date(createdAt), { format: "mini" }) }}
    </td>

    <td class="col-shrink">
      {{ $d(new Date(expiresAt), { format: "mini" }) }}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    supportManagementConnection: {
      type: Object,
      required: true,
    },
  },

  computed: {
    createdAt() {
      return this.supportManagementConnection.created_at;
    },

    expiresAt() {
      return this.supportManagementConnection.expires_at;
    },

    title() {
      return this.supportManagementConnection.title;
    },
  },
};
</script>
