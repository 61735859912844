<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="card mb-2">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("components.decisions.single_decision.information") }}
          </h5>
        </div>

        <div class="card-body">
          <table class="table vertical-align-top">
            <tbody>
              <tr>
                <th class="col-shrink">
                  {{ $t("activerecord.models.meeting.one") }}
                </th>

                <td>
                  <meeting-information :owner="decision" :inline="false" />
                </td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{ $t("activerecord.attributes.decision.assigned_to") }}
                </th>

                <td v-if="decision.user_name">
                  {{ decision.user_name }}
                </td>

                <td v-else>-</td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{ $t("activerecord.attributes.decision.due_at_title") }}
                </th>

                <td v-if="decision.due_at">
                  {{ $d(new Date(decision.due_at), "date") }}
                </td>

                <td v-else>-</td>
              </tr>

              <tr v-if="decision.updated_at">
                <th class="col-shrink">
                  {{ $t("activerecord.attributes.decision.updated_title") }}
                </th>

                <td>{{ $d(new Date(decision.updated_at), "date") }}</td>
              </tr>

              <tr v-if="decision.project_id && decision.project.policy.show">
                <th class="col-shrink">
                  {{ $t("activerecord.attributes.decision.project_id") }}
                </th>

                <td>
                  <a :href="url(`/projects/${decision.project_id}`)">
                    {{ decision.project.display_title }}
                  </a>
                </td>
              </tr>

              <tr>
                <th class="col-shrink">
                  {{ $t("activerecord.attributes.decision.status_title") }}
                </th>

                <td>
                  <be-form-group
                    v-if="decision.policy && decision.policy.edit"
                    class="mb-1"
                  >
                    <be-form-select
                      id="decision-status"
                      :key="`decision-status-${decision.id}`"
                      :ref="`decision-${decision.id}`"
                      v-model="decision.status"
                      :class="{ 'mb-1': decision.loading }"
                      :disabled="decision.loading"
                      :options="formattedDecisionStatuses"
                    />
                  </be-form-group>

                  <p v-else>
                    {{
                      $t(
                        `models.decision.statuses.${
                          decision.status || decisionStatuses.active[0]
                        }`
                      )
                    }}
                  </p>

                  <be-spinner v-if="decision.loading" class="mt-2">
                    {{ $t("buttons.states.loading") }}
                  </be-spinner>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-7">
      <div class="card mb-2">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("activerecord.models.decision.one") }}
          </h5>
        </div>

        <div class="card-body">
          <div v-dompurify-html="decision.description" class="text-pre-line" />
        </div>
      </div>

      <div v-if="decision.item_notes" class="card mb-2">
        <div class="card-header">
          <h5 class="card-title">
            {{ $t("decisions.notes_title") }}
          </h5>
        </div>

        <div class="card-body">
          <div
            v-dompurify-html="decision.item_notes"
            class="p-0 ql-editor ql-viewer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MeetingInformation from "@/components/shared/meetings/MeetingInformation.vue";
import DecisionStatusMixin from "@/mixins/decisions/";

import Config from "@/config.js";

export default {
  components: { MeetingInformation },
  mixins: [DecisionStatusMixin],

  props: {
    initialDecision: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ["updated"],

  data() {
    return {
      decision: this.cloneDeep(this.initialDecision),
      skipNextStatusChange: false,

      decisionStatuses: {
        active: Config.DECISION.STATUSES.ACTIVE,
        inactive: Config.DECISION.STATUSES.INACTIVE,
      },
    };
  },

  computed: {
    formattedDecisionStatuses() {
      let formattedStatuses = [];
      let allDecisionStatuses = this.decisionStatuses["active"].concat(
        this.decisionStatuses["inactive"]
      );

      allDecisionStatuses.forEach((status) => {
        formattedStatuses.push({
          text: this.$i18n.t(`models.decision.statuses.${status}`),
          value: status,
        });
      });

      return formattedStatuses;
    },
  },

  watch: {
    initialDecision(newDecision) {
      this.decision = this.cloneDeep(newDecision);
    },

    async "decision.status"(newValue, oldValue) {
      if (this.skipNextStatusChange) {
        this.skipNextStatusChange = false;
        return;
      }

      const statusChange = this.getStatusChange(
        oldValue,
        newValue,
        this.decisionStatuses
      );

      if (["active", "inactive"].includes(statusChange)) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t(
            "components.decisions.status.change_status"
          ),

          title: this.$t(
            "components.decisions.status.confirm_status_change_w_title",
            { title: this.decision.description }
          ),

          description:
            statusChange === "active"
              ? this.$t(
                  "components.decisions.status.confirm_upgrade_description"
                )
              : this.$t(
                  "components.decisions.status.confirm_downgrade_description"
                ),
        });

        if (isConfirmed) {
          this.updateDecisionStatus(newValue);
        } else {
          this.decision.status = oldValue;
          this.skipNextStatusChange = true;
        }
      } else {
        this.updateDecisionStatus(newValue);
      }
    },
  },

  methods: {
    async updateDecisionStatus(value) {
      this.decision.loading = true;

      // Only triggers confirm when we're changing status group
      // [from active to inactive or vice versa]
      const response = await axios.put(
        this.url(`/decisions/${this.decision.id}`),
        {
          decision: { status: value },
        }
      );

      if (response) {
        // Make sure activities in comments sections gets updated
        this.$store.dispatch("activities/addActivity", response.data.activity);
        this.decision = response.data.decision;
        this.$emit("updated");
      }
    },

    minutesUrl(decision) {
      return this.url(`/meetings/${decision.meeting_id}/minutes`);
    },
  },
};
</script>
