<template>
  <div class="card-body">
    <label>{{ $t("activerecord.models.decision.other") }}</label>

    <be-alert v-if="itemReferencesWithOldStatus" variant="info">
      {{
        $t(
          "components.meetings.material.item_decisions.references_with_old_status"
        )
      }}
    </be-alert>

    <be-table
      v-if="decisionReferences.length > 0"
      v-be-visible="observeVisibilityOptions"
      :items="decisionReferences"
      :fields="referenceFields"
      :tbody-tr-class="rowClasses"
    >
      <template #meeting="{ item }">
        {{ `#${item.decision.human_reference}` }}
      </template>

      <template #description="{ item }">
        <be-link
          v-if="item.decision.description"
          v-be-modal="`decision-${item.reference_id}`"
        >
          {{ truncateText(item.decision.description) }}
        </be-link>

        <single-decision-modal
          :decision-id="item.reference_id"
          :modal-id="`decision-${item.reference_id}`"
          @updated="refreshItemReference(item)"
        />
      </template>

      <template #due_at="{ item }">
        <div v-if="item.decision.due_at">
          {{ $d(new Date(item.decision.due_at), "date") }}
        </div>
      </template>

      <template #user_id="{ item }">
        <user-avatar
          v-if="item.decision.user_id"
          :user="item.decision.user_id"
        />
      </template>

      <template #status="{ item }">
        <div>
          {{ $t(`models.decision.statuses.${item.decision.status}`) }}
        </div>

        <be-link
          v-if="
            item.decision.current_status &&
            item.decision.current_status !== item.decision.status
          "
          @click="refreshItemReference(item)"
        >
          {{ $t("components.meetings.material.item_decisions.refresh_status") }}
        </be-link>
      </template>

      <template #options="{ item }">
        <be-button
          variant="danger"
          size="sm"
          class="ml-2"
          :loading="loadingIds.includes(item.reference_id)"
          icon="fa-times"
          inline
          @click="removeDecisionReference(item)"
        />
      </template>
    </be-table>

    <be-alert v-else variant="info" class="mt-2">
      {{ $t("components.meetings.material.item_decisions.no_decisions") }}
    </be-alert>

    <div class="mt-2">
      <be-button v-be-modal="`add-decisions-${itemId}`" variant="primary">
        {{ $t("components.meetings.material.item_decisions.add_decisions") }}
      </be-button>
    </div>

    <be-modal
      :id="`add-decisions-${itemId}`"
      :title="$t('components.meetings.material.item_decisions.add_decisions')"
      :ok-title="$t('buttons.titles.close')"
      ok-only
      ok-variant="light"
      size="xl"
    >
      <decisions-search
        :references="decisionReferences"
        :loading-ids="loadingIds"
        :exclude-meeting-id="meeting.id"
        :date-start-suggestion="{
          dateString: meeting.previous_meeting_date,
          text: $t('models.meeting.since_previous'),
        }"
        @add-reference="addDecisionReference"
        @remove-reference="removeDecisionReference"
      />
    </be-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const materialHelpers = createNamespacedHelpers("material");

import TextUtilities from "@/mixins/textUtilities";
import UserAvatar from "@/components/user/UserAvatar.vue";
import DecisionsSearch from "@/components/shared/DecisionsSearch.vue";
import SingleDecisionModal from "@/components/decisions/SingleDecisionModal.vue";

export default {
  components: {
    UserAvatar,
    DecisionsSearch,
    SingleDecisionModal,
  },

  mixins: [TextUtilities],

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      decisions: [],
      loadingIds: [],
      activeTab: "active",
    };
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences", "meeting"]),

    referenceFields() {
      return [
        {
          key: "meeting",
          label: "",
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "description",
          label: this.$i18n.t("activerecord.models.decision.one"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("activerecord.attributes.decision.status_title"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "due_at",
          label: this.$t("activerecord.attributes.decision.due_at_title"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "user_id",
          label: this.$t("activerecord.attributes.decision.assigned_to"),
          sortable: false,
          class: "col-shrink text-center",
        },
        {
          key: "options",
          label: "",
          sortable: false,
          class: "col-shrink text-center",
        },
      ];
    },

    decisionReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Decision"
      );
    },

    observeVisibilityOptions() {
      return {
        callback: this.visibilityChanged,
        once: false,
      };
    },

    itemReferencesWithOldStatus() {
      return this.decisionReferences.some(
        (reference) =>
          reference.decision.current_status &&
          reference.decision.current_status !== reference.decision.status
      );
    },
  },

  methods: {
    ...materialHelpers.mapActions([
      "addFollowUpReference",
      "removeFollowUpReference",
      "updateFollowUpReference",
      "fetchItemFollowUpReferences",
    ]),

    async addDecisionReference(decisionId) {
      this.loadingIds.push(decisionId);
      await this.addFollowUpReference({
        itemId: this.itemId,
        reference_type: "Decision",
        reference_id: decisionId,
      });
      this.loadingIds = this.loadingIds.filter((id) => id != decisionId);
    },

    async removeDecisionReference(reference) {
      this.loadingIds.push(reference.reference_id);
      await this.removeFollowUpReference(reference);
      this.loadingIds = this.loadingIds.filter(
        (id) => id != reference.reference_id
      );
    },

    rowClasses(item) {
      if (!item) return;

      const decision = item.decision || item;

      return `status-decisions-${decision.status}`;
    },

    async fetchWithCurrentDecisionStatus() {
      this.fetchItemFollowUpReferences(this.itemId);
    },

    async refreshItemReference(reference) {
      this.loadingIds.push(reference.reference_id);
      await this.updateFollowUpReference(reference);
      this.loadingIds = this.loadingIds.filter(
        (id) => id != reference.reference_id
      );
    },

    visibilityChanged(isVisible) {
      if (isVisible) {
        this.fetchWithCurrentDecisionStatus();
      }
    },
  },
};
</script>
