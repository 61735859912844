<template>
  <div>
    <be-tabs
      v-model="activeTab"
      :options="tabsData"
      translation-scope="views.companies.tokens.index"
    >
      <template #content-right>
        <be-button v-be-modal.new-token variant="outline-primary">
          {{ $t("views.companies.tokens.index.new_token") }}
        </be-button>
      </template>
    </be-tabs>

    <div class="card">
      <div class="card-body">
        <tokens-table
          v-if="activeTab === tabsData.activeTokens.value"
          :tokens="activeTokens"
          :token-count="tabsData.activeTokens.count"
          :tab-state="activeTab"
          @token-inactivate="inactivate"
          @token-remove="remove($event, 'active')"
        />

        <tokens-table
          v-if="activeTab === tabsData.inactiveTokens.value"
          :tokens="inactiveTokens"
          :token-count="tabsData.inactiveTokens.count"
          :tab-state="activeTab"
          @token-remove="remove($event, 'inactive')"
        />
      </div>
    </div>

    <new-token-modal :token-types="tokenTypes" @token-create="save" />
  </div>
</template>

<script>
import TokensTable from "@/components/tokens/TokensTable.vue";
import NewTokenModal from "@/components/tokens/NewTokenModal.vue";

export default {
  components: {
    TokensTable,
    NewTokenModal,
  },

  props: {
    initialTokens: {
      type: Array,
      required: false,
      default: () => [],
    },

    tokenTypes: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "active-tokens",
      tokens: this.cloneDeep(this.initialTokens),
    };
  },

  computed: {
    activeTokens() {
      return this.tokens.filter((token) => !token.inactivated_at);
    },

    inactiveTokens() {
      return this.tokens.filter((token) => token.inactivated_at);
    },

    fields() {
      return [
        {
          key: "template_type",

          label: this.$i18n.t(
            "activerecord.attributes.company_token.token_type"
          ),

          class: "col-shrink",
          sortable: false,
        },
        {
          key: "value",
          label: this.$i18n.t("models.company_token.email"),
          sortable: true,
        },
        {
          key: "created_at",

          label: this.$i18n.t(
            "activerecord.attributes.company_token.created_at"
          ),

          class: "col-shrink",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ];
    },

    tabsData() {
      return {
        activeTokens: {
          value: "active-tokens",
          count: this.activeTokens.length,
        },

        inactiveTokens: {
          value: "inactive-tokens",
          count: this.inactiveTokens.length,
        },
      };
    },
  },

  methods: {
    async inactivate(token) {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$i18n.t("buttons.titles.inactivate"),

        title: this.$t(
          "views.companies.tokens.tokens_table.confirm_token_inactivation_title_w_token",
          {
            token: token.email_as_string,
          }
        ),

        description: this.$t(
          "views.companies.tokens.tokens_table.remove_or_inactivate_description"
        ),
      });

      if (isConfirmed) {
        try {
          await axios.patch(this.url(`/tokens/${token.id}/inactivate`));

          const index = this.tokens.findIndex(
            (existingToken) => existingToken.id === token.id
          );

          if (index != -1) {
            this.tokens[index]["inactivated_at"] = new Date();
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async remove(token) {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t(
          this.$t("nav.confirm_delete_w_title", {
            title: token.email_as_string,
          })
        ),

        description: !token.inactivated_at
          ? this.$t(
              "views.companies.tokens.tokens_table.remove_or_inactivate_description"
            )
          : null,
      });

      if (isConfirmed) {
        try {
          await axios.delete(this.url(`/tokens/${token.id}`));

          const index = this.tokens.findIndex(
            (existingToken) => existingToken.id === token.id
          );

          if (index != -1) {
            this.tokens.splice(index, 1);
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async save(tokenType) {
      try {
        const { data } = await axios.post(this.url("/tokens"), {
          company_token: {
            token_type: tokenType,
          },
        });

        this.tokens.push(data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
