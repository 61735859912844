<template>
  <be-modal
    id="remove-ownership-modal"
    :title="$t('components.remove_ownership_form.title')"
  >
    <h4 class="text-muted">
      {{ ownership.parent_company_title }}
      <i class="far fa-arrow-right fa-sm mx-1"></i>
      {{ ownership.child_company_title }}
    </h4>

    <p>{{ $t("components.remove_ownership_form.description") }}</p>

    <be-form-radio-group v-model="action">
      <be-form-radio
        checked-value="end"
        :description="$t('components.remove_ownership_form.end_description')"
        class="mb-2"
      >
        {{ $t("components.remove_ownership_form.end_title") }}
      </be-form-radio>

      <be-form-radio
        checked-value="delete"
        :description="$t('components.remove_ownership_form.delete_description')"
        class="mb-2"
      >
        {{ $t("components.remove_ownership_form.delete_title") }}
      </be-form-radio>
    </be-form-radio-group>

    <div class="mt-2">
      <div v-if="action === 'end' && !hasStates">
        <be-alert variant="info">
          {{ $t("components.remove_ownership_form.no_states") }}
        </be-alert>
      </div>

      <div v-else-if="action === 'end' && currentEndDate">
        <be-alert variant="info">
          {{
            $t("components.remove_ownership_form.already_ending_date", {
              date: $d(currentEndDate, "date"),
            })
          }}
        </be-alert>
      </div>

      <be-form-group
        v-else-if="action === 'end'"
        label-for="end"
        :label="
          $t('activerecord.attributes.company_group_ownership_state.end_at')
        "
        :description="$t('components.remove_ownership_form.blocked_dates')"
        required
      >
        <be-form-datepicker
          id="end"
          v-model="end_at"
          name="end_at"
          :disabled-dates="dateDisabled"
          class="mt-2"
          :clearable="false"
          required
        />
      </be-form-group>

      <template v-if="action === 'delete'">
        <be-alert
          v-if="deleteStatusOk"
          variant="danger"
          class="d-flex align-items-center"
        >
          {{ $t("components.remove_ownership_form.delete_warning") }}
        </be-alert>

        <be-spinner v-else-if="deleteStatusLoading">
          {{ $t("components.remove_ownership_form.delete_status_loading") }}
        </be-spinner>

        <div v-else-if="deleteStatusWarning" class="mb-3">
          <be-alert variant="warning" class="d-flex align-items-center">
            {{
              $t(
                `components.remove_ownership_form.refusereason.${deleteStatus.reason}`
              )
            }}
          </be-alert>

          <label :for="`${id}-input`" class="font-weight-normal">
            <i18n-t keypath="components.shared.confirm_modal.input_label">
              <template #validInput>
                <code>{{ validInputText }}</code>
              </template>
            </i18n-t>
          </label>

          <be-form-input
            :id="`${id}-input`"
            v-model="confirmText"
            autofocus
            trim
            @keyup.enter="onEnterClick"
          />
        </div>

        <be-alert
          v-else-if="deleteStatusForbidden"
          variant="info"
          class="d-flex align-items-center"
        >
          {{
            $t(
              `components.remove_ownership_form.refusereason.${deleteStatus.reason}`
            )
          }}
        </be-alert>
      </template>
    </div>

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        :variant="action === 'delete' ? 'danger' : 'primary'"
        :disabled="submitButtonDisabled"
        :loading="saving"
        @click="handleOk"
      >
        {{ submitLabel }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { EventBus } from "@/event-bus";

const STATUS_LOADING = 0;
const STATUS_OK = 1;
const STATUS_FORBIDDEN = 2;
const STATUS_WARNING = 3;

export default {
  props: {
    ownership: {
      type: Object,
      default: () => ({}),
      required: false,
    },

    resource: {
      type: String,
      default: "",
      required: false,
    },
  },

  emits: ["ownership-updated", "ownership-deleted"],

  data: () => ({
    saving: false,
    confirmText: "",

    deleteStatus: {
      status: STATUS_LOADING,
      reason: "loading",
    },

    action: "end",
    end_at: new Date(),
  }),

  computed: {
    validInputText() {
      return this.ownership.child_company_title.toLowerCase();
    },

    hasStates() {
      return this.ownership.states?.length > 0;
    },

    currentEndDate() {
      if (!this.ownership.end_at) {
        return null;
      }
      return new Date(this.ownership.end_at);
    },

    submitLabel() {
      if (this.action === "end") {
        return this.$t("buttons.titles.save");
      } else {
        return this.$t("buttons.titles.remove");
      }
    },

    submitButtonDisabled() {
      return (
        this.saving ||
        (this.action === "end" && (!this.hasStates || this.currentEndDate)) ||
        (this.action === "delete" && !this.readyToDelete)
      );
    },

    deleteStatusWarning() {
      return this.deleteStatus.status === STATUS_WARNING;
    },

    deleteStatusForbidden() {
      return this.deleteStatus.status === STATUS_FORBIDDEN;
    },

    deleteStatusOk() {
      return this.deleteStatus.status === STATUS_OK;
    },

    deleteStatusLoading() {
      return this.deleteStatus.status === STATUS_LOADING;
    },

    readyToDelete() {
      return (
        this.deleteStatusOk ||
        (this.deleteStatusWarning && this.confirmTextValid)
      );
    },

    confirmTextValid() {
      return this.confirmText === this.validInputText;
    },

    lastStatesStartDate() {
      if (!this.ownership.states.length) {
        return true;
      }
      return Math.max(
        ...this.ownership.states.map((s) => new Date(s.start_at))
      );
    },

    notifyFailureMessage() {
      if (this.action === "delete") {
        return this.$t("components.remove_ownership_form.could_not_delete");
      }
      if (this.action === "end") {
        return this.$t("components.remove_ownership_form.could_not_end");
      }
      return "Unknown error";
    },
  },

  watch: {
    resource() {
      this.checkDeleteStatus();
    },
  },

  mounted() {
    this.checkDeleteStatus();
  },

  methods: {
    dateDisabled(date) {
      return date <= this.lastStatesStartDate;
    },

    onEnterClick() {
      if (this.confirmTextValid) {
        this.handleOk();
        this.$refs.modal.hide();
      }
    },

    async checkDeleteStatus() {
      this.deleteStatus = {
        status: STATUS_LOADING,
        reason: "loading",
      };
      try {
        const { data } = await axios.get(`${this.resource}/status`);
        const hasAcquisitionAnalysis = data.acquisitionAnalysis?.length > 0;
        const hasMultipleStates = data.organisationChildStates?.length > 0;

        if (hasAcquisitionAnalysis && hasMultipleStates) {
          this.deleteStatus = { status: STATUS_FORBIDDEN, reason: "both" };
        } else if (hasAcquisitionAnalysis) {
          this.deleteStatus = {
            status: STATUS_FORBIDDEN,
            reason: "acquisition_analysis",
          };
        } else if (hasMultipleStates) {
          this.deleteStatus = {
            status: STATUS_WARNING,
            reason: "organisation_child_states",
          };
        } else {
          this.deleteStatus = { status: STATUS_OK };
        }
      } catch {
        this.deleteStatus = { status: STATUS_WARNING, reason: "request_error" };
      }
    },

    async handleOk() {
      this.saving = true;
      try {
        if (this.action === "end" && !this.hasStates) {
          window.location = this.resource;
        } else if (this.action === "end") {
          const { data } = await axios.patch(this.resource, {
            end_at: this.end_at.toISOString(),
          });
          this.$emit("ownership-updated", data.ownership);
        }
        if (this.action === "delete") {
          await axios.delete(this.resource);
          this.$emit("ownership-deleted");
        }
      } catch (error) {
        this.notifyFailure(error);
        this.handleError(error);
      }
      this.saving = false;
      this.$beModal.hide("remove-ownership-modal");
    },

    notifyFailure(error) {
      let message = this.notifyFailureMessage;

      if (error.response?.data?.errors) {
        message += ": " + error.response.data.errors.join(",");
      }

      EventBus.emit("new-notification", {
        message,
        status: "danger",
      });
    },
  },
};
</script>
