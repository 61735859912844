<template>
  <div>
    <div class="card mb-2">
      <div class="card-body d-md-flex">
        <be-form-datepicker
          v-model="localYear"
          type="year"
          :min-date="startOfYear(new Date())"
          :clearable="false"
          inline
          @input="changeYear"
        />
      </div>
    </div>

    <div v-if="!skipIntroduction" class="card mb-2">
      <div class="card-body">
        <be-alert variant="info">
          {{
            $t("components.annual_cycles.year_plan.no_more_to_plan", {
              year: year,
            })
          }}
        </be-alert>

        <be-button variant="outline-primary" @click="increaseYearByOne">
          {{ $t("components.annual_cycles.year_plan.load_next_year") }}
        </be-button>
      </div>
    </div>

    <div v-else>
      <div v-if="loading" class="card">
        <div class="card-body">
          <be-skeleton-table
            :rows="4"
            :columns="2"
            :table-props="{ bordered: true, striped: true }"
          ></be-skeleton-table>
        </div>
      </div>

      <template v-else>
        <configuration-form
          v-if="currentStep == 'configuration'"
          @configuration-saved="showMeetingsArea"
        />

        <new-meetings
          v-if="currentStep == 'new-meetings'"
          :existing-disabled-dates="existingDisabledDates"
          @meeting-editing-canceled="setStep('configuration')"
          @meeting-removed="handleMeetingRemoved"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { startOfYear } from "date-fns/startOfYear";

import { mapGetters } from "vuex";

import ConfigurationForm from "./ConfigurationForm.vue";
import NewMeetings from "./NewMeetings.vue";

export default {
  components: {
    ConfigurationForm,
    NewMeetings,
  },

  props: {
    initialCurrentStep: {
      type: String,
      required: false,
      default: "configuration",
    },

    initialNewMeetings: {
      type: Array,
      required: false,
      default: () => [],
    },

    initialYearAsDate: {
      type: String,
      required: false,
      default: () => new Date().toString(),
    },

    firstAvailableMeetingNumber: {
      type: Number,
      required: false,
      default: 1,
    },

    existingDisabledDates: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      currentStep: this.initialCurrentStep,
      showMeetings: true,
      localYear: new Date(this.initialYearAsDate).getFullYear(),
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      meetings: "annual_cycle/meetings",
      year: "annual_cycle/year",
      initialMeetingCount: "annual_cycle/initialMeetingCount",
      meetingCount: "annual_cycle/meetingCount",
    }),

    skipIntroduction() {
      return (
        (this.meetings && this.meetings.length > 0) ||
        this.initialMeetingCount > 0
      );
    },
  },

  mounted() {
    this.$store.commit("annual_cycle/setYear", this.localYear);
    this.$store.dispatch("annual_cycle/setMeetings", {
      meetings: this.initialNewMeetings,
      initialNumber: this.firstAvailableMeetingNumber,
    });
  },

  methods: {
    getDateTime(dateAsString) {
      return this.$d(new Date(dateAsString), "time");
    },

    setStep(step) {
      this.currentStep = step;
    },

    handleMeetingRemoved() {
      if (this.meetingCount == 0 && this.initialMeetingCount > 0) {
        this.setStep("configuration");
      }
    },

    showMeetingsArea() {
      this.changeYear(false);
      this.setStep("new-meetings");
    },

    async changeYear(changeStep = true) {
      if (this.localYear) {
        const year = this.localYear;
        this.loading = true;
        try {
          const response = await axios.get(this.url("/annual_cycle/new"), {
            params: { year: year },
          });
          this.$store.commit("annual_cycle/setYear", year);
          this.$store.dispatch("annual_cycle/setMeetings", {
            meetings: response.data,
          });

          if (changeStep && this.currentStep == "new-meetings") {
            this.setStep("configuration");
          }
        } catch (error) {
          this.handleError(error);
        } finally {
          this.loading = false;
        }
      }
    },

    increaseYearByOne() {
      this.localYear++;
      this.changeYear();
    },

    startOfYear,
  },
};
</script>
