<template>
  <slot
    :selected-companies-warning="selectedCompaniesWarning"
    :selected-companies="selectedCompanies"
    :add="add"
    :e-sign="eSign"
    :set-e-sign="(event) => (eSign = event.target.checked)"
  />
</template>
<script>
export default {
  data() {
    return {
      warningThreshold: 20,
      selectedCompanies: [],
      eSign: false,
    };
  },

  computed: {
    selectedCompaniesWarning() {
      if (this.selectedCompanies.length >= this.warningThreshold) {
        return this.$t(
          "admin_panels.documents.distributions.new.organization_size_warning_w_count",
          { count: this.selectedCompanies.length }
        );
      } else {
        return null;
      }
    },
  },

  methods: {
    add(selectedItems) {
      this.selectedCompanies = selectedItems;
    },
  },
};
</script>
