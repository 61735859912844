<template>
  <div
    :ref="`message-${message.id}`"
    v-be-visible="observeVisibilityOptions"
    :class="`${borderClasses}`"
  >
    <div v-if="showMessage" class="row px-3 py-4 no-gutters">
      <div class="col-auto">
        <user-avatar
          :user="getUser(message.user_id)"
          class="d-none d-md-inline-block mr-md-2"
        />
      </div>

      <div class="col">
        <div class="d-flex justify-content-between">
          <div>
            <span class="mr-1 mb-1">{{ formattedMessageName }}</span>

            <i
              v-if="message.status === 'urgent'"
              v-be-tooltip="$t('models.message_thread.urgent')"
              class="fal fa-comment-alt-exclamation mr-1"
            />

            <i
              v-if="message.documents && message.documents.length"
              v-be-tooltip="$t('models.message_thread.documents_present')"
              class="fal fa-paperclip"
            />
          </div>

          <div class="d-md-none">
            <message-dropdown
              :message="message"
              :allowed-to-edit="allowedToEdit"
              @toggle-edit-area="toggleEditArea"
              @delete-message="deleteMessage"
            />
          </div>
        </div>

        <small>
          {{ $d(new Date(message.created_at), "dateTime") }}

          <template v-if="showActivities">
            -
            <be-link v-be-modal="`message-history-${message.id}`">
              {{ translateAttribute("message", "updated_at") }}
              {{ $d(new Date(message.updated_at), "dateTime") }}
            </be-link>
          </template>
        </small>

        <message-comment
          ref="messageComment"
          :message="message"
          :loading="loading"
          class="mt-2"
          @edit-cancelled="closeEditArea"
          @update="updateMessage"
          @preview-toggled="toggleOpen"
        />

        <table
          v-if="message.documents && message.documents.length"
          class="table mt-3"
        >
          <tbody>
            <tr
              v-for="messageDocument in message.documents"
              :key="`document-${messageDocument.id}`"
            >
              <td class="col-shrink py-1">
                <document-link
                  :document-id="messageDocument.id"
                  :filename="messageDocument.filename"
                />
              </td>

              <td class="text-right py-1">
                <be-button
                  v-if="message.show_edit_area"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  inline
                  @click="deleteDocument(messageDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="!messageDeleted" class="col-auto d-none d-md-block">
        <message-dropdown
          :message="message"
          :allowed-to-edit="allowedToEdit"
          @toggle-edit-area="toggleEditArea"
          @delete-message="deleteMessage"
        />
      </div>
    </div>

    <div v-else class="card-body px-3 py-4">
      <small>
        {{
          $t(
            "components.companies.message_threads.message_deleted_with_names",
            {
              name: getUserName(message.user_id),
              date: messageDeletedAt,
            }
          )
        }}
      </small>
    </div>

    <message-history-modal
      v-if="validActivities.length > 0"
      :activities="validActivities"
      :message="message"
      :thread="thread"
    />

    <share-link-modal
      :key="`share-message-${message.id}`"
      :thread="thread"
      :message="message"
      :invitations="persistedUserInvitations"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MessageComment from "./MessageComment.vue";
import ShareLinkModal from "./ShareLinkModal.vue";
import MessageDropdown from "./MessageDropdown.vue";
import MessageHistoryModal from "./MessageHistoryModal.vue";

export default {
  components: {
    MessageComment,
    ShareLinkModal,
    MessageDropdown,
    MessageHistoryModal,
  },

  props: {
    initialMessage: {
      type: Object,
      required: true,
    },

    newMessage: {
      type: Boolean,
      default: false,
    },

    read: {
      type: Boolean,
      default: false,
    },

    persistedUserInvitations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ["show-document-fields"],

  data() {
    return {
      showDocumentFields: false,
      initialRead: this.read,
      markedAsRead: this.read,
      sharedMessage: false,
      hover: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      thread: "message_threads/getThread",
      invitations: "message_threads/getInvitations",
    }),

    allowedToEdit() {
      return this.message.policy.edit;
    },

    formattedMessageName() {
      const name = this.getUserName(this.message.user_id);

      if (name) {
        return `#${this.message.number} - ${name}`;
      }

      // If the user has been removed from the organization
      return `#${this.message.number} - ${this.$t(
        "components.companies.invitations.removed_user"
      )}`;
    },

    message() {
      return this.$store.getters["message_threads/getMessage"](
        this.initialMessage
      );
    },

    messageDeleted() {
      return !!this.message.deleted_at;
    },

    messageDeletedAt() {
      return this.$i18n.d(new Date(this.message.deleted_at), "dateTime");
    },

    borderClasses() {
      /* Don't add borders for deleted messages or own
         messages unless they have been shared
      */

      if (this.messageDeleted) {
        return "";
      }

      const ownedByCurrentUser = this.message.user_id == this.$currentUser.id;

      if (this.sharedMessage) {
        return this.borderTypeClasses("warning");
      } else if (!this.initialRead && !ownedByCurrentUser) {
        return this.borderTypeClasses("danger");
      }

      return "";
    },

    showMessage() {
      // Show the message if it hasn't been removed or
      // if its thread has been removed
      return !this.message.deleted_at || this.thread.deleted_at;
    },

    showActivities() {
      return this.validActivities.length > 0;
    },

    validActivities() {
      if (this.messageDeleted || !this.message.activities) {
        return [];
      }

      return this.message.activities.filter(
        (activity) =>
          activity.changes_body &&
          activity.changes_body.length > 0 &&
          activity.changes_updated_at &&
          activity.changes_updated_at.length > 0
      );
    },

    observeVisibilityOptions() {
      return {
        callback: this.visibilityChanged,
        once: true,
        throttle: this.$config?.MESSAGE_THREADS.VISIBILITY_RENDER_DELAY,
      };
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.scrollToMessage();
    });

    if (!this.initialRead) {
      this.toggleOpen();
    }
  },

  methods: {
    borderTypeClasses(borderType) {
      return `border-left border-left-4 border-left-${borderType}`;
    },

    toggleDocumentFields() {
      this.$emit("show-document-fields", this.showDocumentFields);
      this.showDocumentFields = !this.showDocumentFields;
    },

    toggleEditArea() {
      this.updateAttribute("show_edit_area", !this.message.show_edit_area);
    },

    toggleOpen() {
      this.updateAttribute("open", !this.message.open);
    },

    closeEditArea() {
      this.updateAttribute("show_edit_area", false);
    },

    scrollToMessage() {
      var splitUrl = document.location.toString().split("#");

      if (splitUrl[1] && "message_" + this.message.id == splitUrl[1]) {
        this.sharedMessage = true;

        // TODO SCROLL
        setTimeout(() => {
          var messageDisplay = this.$refs[`message-${this.message.id}`];

          window.scrollTo(0, messageDisplay.offsetTop);
        }, 1000); // TODO: Remove this in production
      }
    },

    updateAttribute(key, value) {
      this.$store.commit("message_threads/updateMessageAttributes", {
        message: this.message,
        data: { [key]: value },
      });
    },

    async updateMessage(message) {
      try {
        this.loading = true;
        await this.$store.dispatch("message_threads/updateMessage", message);
      } catch (error) {
        if (error.response && error.response.status == 422) {
          this.$store.commit(
            "message_threads/updateMessage",
            error.response.data
          );
          this.updateAttribute("show_edit_area", true);
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    async deleteMessage() {
      if (!this.allowedToEdit) {
        return false;
      }

      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: `#${this.message.number}`,
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(
            this.url(
              `/message_threads/${this.thread.id}/messages/${this.message.id}`
            ),
            { message: this.message }
          );
          this.$store.commit("message_threads/setMessage", response.data);
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async deleteDocument(documentToRemove) {
      // TODO: The document policy does not exist here
      if (
        !this.message.policy.update ||
        (documentToRemove.policy && !documentToRemove.policy.destroy)
      ) {
        return false;
      }

      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: documentToRemove.filename,
        })
      );

      if (isConfirmed) {
        try {
          await axios.delete(this.url(`/documents/${documentToRemove.id}`));

          this.$store.commit("message_threads/removeDocument", {
            message: this.message,
            document: documentToRemove,
          });
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    async visibilityChanged(isVisible) {
      if (!this.markedAsRead && !this.message.deleted_at && isVisible) {
        try {
          await axios.post(
            this.url(
              `/message_threads/${this.thread.id}/messages/${this.message.id}/read`
            )
          );

          this.markedAsRead = true;
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
