<template>
  <div>
    <h3 class="mb-3" v-text="$t('components.meetings.tabs.minutes.title')" />

    <div class="row">
      <div class="col-12 col-md-6 mb-4">
        <meeting-status
          v-if="['minutes', 'review'].includes(meeting.process.active_state)"
          :meeting="meeting"
        />

        <tab-activity
          :owner="minutes"
          :accepted-keys="['material.sealed', 'material.declined']"
        />

        <template v-if="minutesStatus === 'loading'">
          <be-skeleton-table :rows="3" :columns="2" />
        </template>

        <template v-else-if="minutesStatus === 'error'">
          <be-alert variant="danger">
            {{ $t("components.meetings.tabs.failed_to_load") }}
          </be-alert>
        </template>

        <template v-else-if="minutesStatus === 'loaded'">
          <template v-if="canEdit">
            <be-button
              v-if="minutes.id"
              :href="`${meeting.paths.base}/minutes/edit`"
              variant="outline-primary"
            >
              {{ $t("models.material.minutes.edit") }}
            </be-button>

            <template v-else>
              <be-button variant="outline-primary" @click="createMinutes">
                {{ $t("components.meetings.tabs.minutes.write_minutes") }}
              </be-button>
            </template>
          </template>

          <be-button
            v-if="canShow"
            :href="`${meeting.paths.base}/minutes`"
            variant="outline-secondary"
          >
            {{ $t("components.meetings.tabs.minutes.show_minutes") }}
          </be-button>
        </template>
      </div>

      <div
        v-if="folderStatus === 'loaded' && folder.id"
        class="col-12 col-md-6"
      >
        <meeting-documents :folder="folder" :meeting="meeting" />
      </div>

      <div class="col-12">
        <meeting-comments-table
          v-if="
            meeting.process.active_state == 'minutes' &&
            preReviewCommentsRequests.length > 0
          "
          :meeting="meeting"
          :minutes="minutes"
          :attendances="attendances"
          :comments-requests="preReviewCommentsRequests"
          mode="minutes-tab"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";
import materialRequests from "@/requests/material";

import MeetingCommentsTable from "./components/MeetingCommentsTable.vue";
import MeetingDocuments from "./components/MeetingDocuments.vue";
import TabActivity from "./TabActivity.vue";

export default {
  components: {
    MeetingCommentsTable,
    MeetingDocuments,
    TabActivity,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  computed: {
    minutes() {
      return this.getMinutes(this.meeting);
    },

    minutesStatus() {
      return this.getMinutesStatus(this.meeting);
    },

    attendances() {
      return this.getAttendances(this.meeting);
    },

    commentsRequests() {
      return this.getCommentsRequests(this.meeting);
    },

    preReviewCommentsRequests() {
      return this.commentsRequests.filter((request) => request.pre_review);
    },

    commentsRequestStatus() {
      return this.getCommentsRequestStatus(this.meeting);
    },

    canEdit() {
      return (
        (!this.minutes && this.meeting.policy.create) ||
        (this.minutes?.policy?.edit && !this.minutes?.sealed)
      );
    },

    canShow() {
      return this.minutes?.id && this.minutes?.policy?.show;
    },

    folder() {
      return this.getFolder(this.meeting);
    },

    folderStatus() {
      return this.getFolderStatus(this.meeting);
    },
  },

  mounted() {
    this.fetchAttendancesForMeeting(this.meeting);
    this.fetchFolderForMeeting(this.meeting);
    this.fetchMinutesForMeeting(this.meeting);
    this.fetchCommentsRequestsForMeeting(this.meeting);
    this.fetchAgendaForMeeting(this.meeting);
  },

  methods: {
    async createMinutes() {
      const isConfirmed = await this.promptConfirm({
        title: this.$i18n.t("models.material.minutes.confirm_create"),
        confirmButtonText: this.$i18n.t("buttons.titles.create"),

        description: this.$i18n.t(
          "models.material.minutes.confirm_create_description"
        ),
      });

      if (isConfirmed) {
        materialRequests.createMinutes(this.meeting);
      }
    },
  },
};
</script>
