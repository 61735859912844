<template>
  <be-nav wizard class="flex-nowrap">
    <be-nav-item
      v-for="state in states"
      :key="`state-${state}`"
      :active="state === currentTab"
      :disabled="isDisabled(state)"
      @click="changeTab(state)"
    >
      {{ $t(`models.meeting.navigation.${state}`) }}
    </be-nav-item>
  </be-nav>
</template>

<script>
export default {
  props: {
    states: {
      type: Array,
      required: true,
    },

    meetingState: {
      type: String,
      required: true,
    },

    currentTab: {
      type: String,
      required: true,
    },
  },

  emits: ["update:current-tab"],

  methods: {
    changeTab(tab) {
      if (this.isDisabled(tab)) {
        return;
      }

      this.$emit("update:current-tab", tab);
    },

    isDisabled(tab) {
      let index = this.states.indexOf(tab);

      return this.states.indexOf(this.meetingState) < index;
    },
  },
};
</script>
