<template>
  <tr>
    <td>
      <template v-if="evaluationForm.number">
        {{ `${evaluationForm.title} ${evaluationForm.number}` }}
      </template>

      <template v-else>
        {{ evaluationForm.title }}
      </template>
    </td>

    <td class="hidden-phone col-shrink">
      {{ $d(new Date(evaluationForm.created_at), "date") }}
    </td>

    <td class="col-shrink">
      <be-button
        :href="evaluationFormUrl"
        size="sm"
        variant="outline-secondary"
        inline
      >
        {{ $t("buttons.titles.preview") }}
      </be-button>

      <be-button
        :href="url(`/evaluation_forms/?template_id=${evaluationForm.id}`)"
        size="sm"
        variant="outline-primary"
        data-method="post"
        inline
      >
        {{ $t("buttons.titles.use") }}
      </be-button>

      <be-dropdown
        v-if="showMenu && isAllowedToDestroyForm"
        size="sm"
        inline
        ellipsis
      >
        <be-dropdown-item variant="danger" @click.prevent="removeTemplate">
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    evaluationForm: {
      type: Object,
      required: true,
    },
  },

  emits: ["template-removed"],

  computed: {
    showMenu() {
      return (
        this.evaluationForm.policy.edit ||
        this.evaluationForm.policy.destroy ||
        this.evaluationForm.policy.show
      );
    },

    evaluationFormUrl() {
      return this.url(`/evaluation_forms/${this.evaluationForm.id}`);
    },

    useTemplateUrl() {
      return this.url("/evaluation_forms/");
    },
  },

  methods: {
    isAllowedToDestroyForm() {
      return (
        this.evaluationForm.policy.destroy ||
        (this.evaluationForm.creator_id &&
          this.evaluationForm.creator_id == this.$currentUser.id)
      );
    },

    async removeTemplate() {
      try {
        const isConfirmed = await this.promptRemovalConfirm({
          title: this.$t("nav.confirm_delete_w_title", {
            title: this.evaluationForm.title,
          }),

          description: this.evaluationForm.published
            ? this.$t(
                "components.evaluation_forms.templates_table.confirm_delete_published_template_description"
              )
            : "",
        });

        if (isConfirmed) {
          await axios.delete(this.evaluationFormUrl);
          this.$emit("template-removed", this.evaluationForm);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
