<template>
  <be-dropdown size="sm" ellipsis>
    <be-dropdown-item :href="url(`/contracts/${contract.id}/edit`)">
      {{ $t("buttons.titles.edit") }}
    </be-dropdown-item>

    <template v-if="contract.policy.destroy">
      <be-dropdown-divider />

      <be-dropdown-item variant="danger" @click="removeContract">
        {{ $t("buttons.titles.remove") }}
      </be-dropdown-item>
    </template>
  </be-dropdown>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },

  emits: ["contract-remove"],

  methods: {
    removeContract() {
      this.$emit("contract-remove");
    },
  },
};
</script>
