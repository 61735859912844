<template>
  <be-accordion-item :open="open">
    <!-- Header with actions -->
    <template #title>
      <div v-if="batchEnabled" @click="selectInquiry">
        <be-form-checkbox
          v-if="!batchSaving"
          :id="`inquiry-${inquiry.id}-checkbox`"
          :checked="selected"
          class="mr-1"
          @click.stop
          @change="selectInquiry"
        />

        <be-spinner v-else>
          {{ $t("buttons.states.loading") }}
        </be-spinner>
      </div>

      <h6 class="mb-0">{{ title }}</h6>
    </template>

    <template #header-right>
      <span v-if="inquiryItem.status === 'approved'" class="text-muted small">
        {{ $t("views.companies.inquiries.inquiry.granted_value") }}:
        <money-format
          :currency="inquiry.currency"
          :value="Number(inquiryItem.granted_value) || 0"
          class="mr-3"
        />
      </span>

      <inquiry-status-badge
        v-if="inquiryItem.status"
        class="mr-3"
        :inquiry="inquiryItem"
      />
    </template>

    <!-- Description -->
    <div v-if="inquiry.description" class="row mb-3">
      <div class="col">
        <p class="font-weight-bold mb-1">
          {{ $t("activerecord.attributes.inquiry.description") }}
        </p>

        <p>{{ inquiry.description }}</p>
      </div>
    </div>

    <div class="row mb-2 flex-column flex-md-row">
      <!-- External ID -->
      <div class="col">
        <p class="font-weight-bold mb-1">
          {{ $t("activerecord.attributes.inquiry.external_id") }}
        </p>

        <p>{{ inquiry.external_id }}</p>
      </div>

      <!-- Receiver -->
      <div class="col mt-3 mt-md-0">
        <p class="font-weight-bold mb-1">
          {{ $t("activerecord.attributes.inquiry.receiver") }}
        </p>

        <p>
          <template v-if="inquiry.inquiry_receiver?.name">
            {{ inquiry.inquiry_receiver.name }}
          </template>

          <template v-else> - </template>
        </p>
      </div>

      <!-- Link -->
      <div v-if="inquiry.link" class="col mt-3 mt-md-0">
        <p class="font-weight-bold mb-1">
          {{ $t("activerecord.attributes.inquiry.link") }}
        </p>

        <p>
          <be-link :href="inquiry.link">
            {{ $t("views.companies.inquiries.table.go_to_inquiry") }}
          </be-link>
        </p>
      </div>

      <!-- Granted Value -->
      <div class="col mt-3 mt-md-0">
        <p class="font-weight-bold mb-1">
          {{ valueTitle }}
        </p>

        <div v-if="!status && !batchSaving">
          <be-form-input
            v-model="inquiryItem.granted_value"
            type="number"
            size="sm"
          />

          <p class="text-muted small">
            {{ $t("activerecord.attributes.inquiry_item.requested_value") }}:
            <money-format
              :currency="inquiry.currency"
              :value="Number(inquiry.requested_value)"
            />
          </p>
        </div>

        <div v-else>
          <money-format
            :currency="inquiry.currency"
            :value="
              Number(inquiryItem.granted_value) ||
              Number(inquiry.requested_value)
            "
          />
        </div>
      </div>
    </div>

    <!-- Comments -->
    <div class="row mb-2 mt-3 mt-md-0">
      <div class="col">
        <p class="font-weight-bold mb-1">
          {{ $t("activerecord.attributes.inquiry_item.comment") }}
        </p>

        <be-form-textarea
          v-if="!status && !batchSaving"
          v-model="inquiryItem.comment"
        />

        <p
          v-else
          v-dompurify-html="inquiryItem.comment || '-'"
          class="ql-viewer"
        />
      </div>
    </div>

    <div
      v-if="
        localInquiry.dimensions.length > 0 &&
        localInquiry.dimension_entry_owners.length > 0
      "
      class="row mb-2 mt-4"
    >
      <div class="col-12 col-xl-12 mb-4">
        <div class="card border-left border-bottom shadow-none">
          <div
            class="card-header d-flex justify-content-between align-items-baseline"
          >
            <div>
              {{ $t("activerecord.attributes.dimension.dimension_entries") }}
            </div>

            <div>
              <be-button variant="primary" size="sm" @click="refreshInquiry()">
                {{
                  $t("views.companies.inquiries.minutes.refresh_category_data")
                }}
              </be-button>

              <be-button
                :href="url(`/inquiries/${inquiry.id}/edit`)"
                target="_blank"
                size="sm"
              >
                {{ $t("views.companies.inquiries.minutes.edit_categories") }}
              </be-button>
            </div>
          </div>

          <div class="card-body">
            <dimension-entry-owners-table
              :inquiry-id="inquiry.id"
              :dimension-entry-owners="localInquiry.dimension_entry_owners"
              :dimensions="localInquiry.dimensions"
              :granted-value="inquiryItem.granted_value"
              :currency="inquiry.currency"
              table-mode
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else-if="$currentCompany.pe_accounting_payments_enabled"
      class="mt-3 text-right"
    >
      <be-button variant="primary" size="sm" @click="refreshInquiry()">
        {{ $t("views.companies.inquiries.minutes.refresh_category_data") }}
      </be-button>

      <be-button
        :href="url(`/inquiries/${inquiry.id}/edit`)"
        target="_blank"
        size="sm"
      >
        {{ $t("views.companies.inquiries.minutes.edit_categories") }}
      </be-button>
    </div>

    <template v-if="!batchSaving" #footer>
      <div class="row no-gutters">
        <div v-if="!status" class="col-12 col-md-auto ml-auto">
          <be-button
            variant="outline-danger"
            size="sm"
            @click="handleUpdate('rejected')"
          >
            {{ $t("views.companies.inquiries.inquiry.reject") }}
          </be-button>

          <be-button
            variant="outline-secondary"
            size="sm"
            @click="handleUpdate('postponed')"
          >
            {{ $t("views.companies.inquiries.inquiry.postpone") }}
          </be-button>

          <be-button
            variant="outline-success"
            size="sm"
            @click="handleUpdate('approved')"
          >
            {{ $t("views.companies.inquiries.inquiry.approve") }}
          </be-button>
        </div>

        <div v-else class="col-12 col-md-auto ml-auto">
          <be-button
            variant="outline-secondary"
            size="sm"
            icon="fa-rotate-left"
            @click="handleDelete"
          >
            {{ $t("buttons.titles.undo") }}
          </be-button>
        </div>
      </div>
    </template>
  </be-accordion-item>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import InquiryStatusBadge from "@/components/inquiries/components/StatusBadge.vue";
import DimensionEntryOwnersTable from "@/components/inquiries/form/components/FormFieldsDimensionEntries.vue";

export default {
  components: {
    InquiryStatusBadge,
    DimensionEntryOwnersTable,
  },

  props: {
    inquiry: {
      type: Object,
      required: true,
    },

    selectedInquiriesIds: {
      type: Array,
      required: false,
      default: () => [],
    },

    savingBatch: {
      type: Array,
      default: () => [],
    },

    itemId: {
      type: Number,
      required: true,
    },

    meetingId: {
      type: Number,
      required: true,
    },

    materialId: {
      type: Number,
      required: true,
    },

    batchEnabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["select"],

  data() {
    return {
      open: false,
      localInquiry: this.inquiry,
    };
  },

  computed: {
    inquiryItem() {
      return this.inquiry.latest_inquiry_item || {};
    },

    selected() {
      return this.selectedInquiriesIds.includes(this.inquiry.id);
    },

    batchSaving() {
      return this.savingBatch.find((inquiry) => inquiry.id === this.inquiry.id);
    },

    valueTitle() {
      switch (this.status) {
        case "approved":
          return this.$t("activerecord.attributes.inquiry_item.granted_value");
        case "rejected":
          return this.$t("views.companies.inquiries.inquiry.rejected_value");
        case "postponed":
          return this.$t("views.companies.inquiries.inquiry.requested_value");
        default:
          return this.$t("activerecord.attributes.inquiry_item.granted_value");
      }
    },

    status() {
      return this.inquiryItem.status;
    },

    title() {
      return this.inquiry.title || this.inquiry.external_id;
    },
  },

  methods: {
    ...mapActions({
      updateInquiryItem: "inquiries/updateInquiryItem",
      deleteInquiryItem: "inquiries/removeInquiryItem",
      loadInquiry: "inquiries/loadInquiry",
    }),

    ...mapMutations({
      setInquiryItem: "inquiries/setInquiryItem",
    }),

    async refreshInquiry() {
      const response = await this.loadInquiry(this.inquiry.id);

      this.localInquiry.dimensions = response.dimensions;
      this.localInquiry.dimension_entry_owners =
        response.dimension_entry_owners;
    },

    async handleUpdate(status) {
      this.open = true;

      const inquiryItem = {
        ...this.inquiryItem,

        granted_value:
          status === "approved" ? Number(this.inquiryItem.granted_value) : null,

        status: status,
      };

      await this.updateInquiryItem({
        url: this.url(
          `/meetings/${this.meetingId}/materials/${this.materialId}/inquiry_items`
        ),

        inquiryItem: inquiryItem,
      });

      this.open = false;
    },

    async handleDelete() {
      await this.deleteInquiryItem({
        url: this.url(
          `/meetings/${this.meetingId}/materials/${this.materialId}/inquiry_items`
        ),

        inquiryItem: this.inquiryItem,
      });
    },

    selectInquiry() {
      this.$emit("select", this.inquiry.id);
    },
  },
};
</script>
