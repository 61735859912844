<template>
  <tr>
    <th>{{ category.title }}</th>

    <td class="col-shrink">
      <be-button
        v-if="category.policy.destroy && !category.active"
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeCategory()"
      />

      <be-button
        v-if="category.policy.destroy && category.active"
        v-be-tooltip="$t('buttons.titles.remove')"
        :href="editCategoryUrl"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },
  },

  emits: ["removed-category"],

  computed: {
    editCategoryUrl() {
      return this.url(`/categories/${this.category.id}/edit_categories`);
    },

    categoryUrl() {
      return this.url(`/categories/${this.category.id}`);
    },
  },

  methods: {
    async removeCategory() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_title", {
          title: this.category.title,
        }),

        description: this.$t(
          "views.companies.categories.edit_categories.confirm_delete_description"
        ),
      });

      if (isConfirmed) {
        try {
          await axios.delete(this.categoryUrl);

          this.$emit("removed-category", this.category);
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
