import Config from "@/config.js";

export default {
  computed: {
    formattedShareTypes() {
      return Config.SHAREHOLDER_REGISTER.SHARE_TYPES.map((shareType) => ({
        value: shareType,

        text: this.$i18n.t(
          `models.shareholder_transaction.share_types.${shareType}`
        ),
      }));
    },
  },

  methods: {
    formatShareholder(shareholder) {
      if (shareholder.company_name) {
        return {
          value: shareholder.id,
          text: `${shareholder.company_name} - ${shareholder.identification_number}`,
        };
      } else {
        return {
          value: shareholder.id,
          text: `${shareholder.name} - ${shareholder.identification_number}`,
        };
      }
    },

    shareCountMismatch(shareholder) {
      return (
        shareholder.a_shares +
          shareholder.b_shares +
          shareholder.c_shares +
          shareholder.pref_shares +
          shareholder.common_shares !=
        shareholder.share_count
      );
    },
  },
};
