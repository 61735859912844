<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <div class="row">
        <div class="col-lg-7">
          <be-form-group
            label-for="added_capital"
            :label="$t('activerecord.attributes.shareholder_register.capital')"
            :error="getErrors(action, ['added_capital'])"
          >
            <be-form-input
              id="added_capital"
              v-model="addedCapital"
              type="number"
              name="shareholder_register_action[added_capital]"
              required
              @change="clearErrors(action, ['added_capital'])"
            />
          </be-form-group>

          <be-form-group
            label-for="number_of_shares"
            :label="
              $t(
                'activerecord.attributes.shareholder_register_action.number_of_shares'
              )
            "
            :error="getErrors(action, ['number_of_shares'])"
          >
            <be-form-input
              id="number_of_shares"
              v-model="numberOfShares"
              type="number"
              :min="0"
              name="shareholder_register_action[number_of_shares]"
              @change="clearErrors(action, ['number_of_shares'])"
            />
          </be-form-group>
        </div>
      </div>
    </div>

    <div v-if="!inEdit" class="col-12 col-lg-7">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="text-right">
              {{
                `${$t("models.shareholder_register.quota")} (${
                  shareholderRegister.currency
                })`
              }}
            </th>

            <th class="text-right">
              {{ $t("models.shareholder_register.value_above_quota") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td class="text-right">
              {{ quota }}
            </td>

            <td class="text-right">-</td>
          </tr>
        </tbody>
      </table>

      <be-alert variant="info">
        {{
          $t("components.shareholder_registers.company_formation.quota_as_help")
        }}
      </be-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shareholderRegister: {
      type: Object,
      required: true,
    },

    action: {
      type: Object,
      required: true,
    },

    inEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      addedCapital: this.action.added_capital
        ? this.action.added_capital
        : null,

      numberOfShares: this.action.number_of_shares
        ? this.action.number_of_shares
        : null,

      roundDecimals: null,
    };
  },

  computed: {
    quota() {
      let unformatedQuota =
        BigNumber(this.addedCapital) / BigNumber(this.numberOfShares);

      if (unformatedQuota > 0) {
        return `${BigNumber(unformatedQuota).decimalPlaces(
          this.roundDecimals
        )} ${this.shareholderRegister.currency.toUpperCase()}`;
      } else {
        return "-";
      }
    },
  },

  mounted() {
    this.roundDecimals = this.$config.SHAREHOLDER_REGISTER.ROUND_DECIMALS;
  },
};
</script>
