<template>
  <be-modal
    :id="`link-modal-${message.id}`"
    :title="
      $t('components.companies.message_threads.share', {
        title: `#${message.number}`,
      })
    "
    :cancel-title="$t('buttons.titles.cancel')"
    :ok-title="$t('buttons.titles.close')"
    ok-variant="light"
    ok-only
  >
    <table class="table">
      <tbody>
        <share-link-row
          v-for="invitation in invitations"
          :key="invitation.user_id"
          :thread="thread"
          :message="message"
          :invitation="invitation"
        />
      </tbody>
    </table>
  </be-modal>
</template>

<script>
import ShareLinkRow from "./ShareLinkRow.vue";

export default {
  components: {
    ShareLinkRow,
  },

  props: {
    thread: {
      type: Object,
      required: true,
    },

    message: {
      type: Object,
      required: true,
    },

    invitations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
};
</script>
