const FEATURE_TABLE = [
  {
    name: "Boardeaser Financials 10",
    price: "199 SEK",
  },
  {
    name: "Boardeaser Financials 20",
    price: "499 SEK",
  },
  {
    name: "Boardeaser Financials 50",
    price: "799 SEK",
  },
  {
    name: "Boardeaser Financials 100",
    price: "1 499 SEK",
  },
  {
    name: "Boardeaser Financials 250",
    price: "1 999 SEK",
  },
  {
    name: "Boardeaser Financials 500",
    price: "2 999 SEK",
  },
  {
    name: "Boardeaser Financials 1000",
    price: "3 999 SEK",
  },
  {
    name: "Boardeaser Financials 2500",
    price: "799 SEK",
  },
  {
    name: "Boardeaser Financials+ 2500",
    price: "5 999 SEK",
  },
  {
    name: "Boardeaser Corporate Group Consolidation",
    price: "199 SEK",
  },
  {
    name: "Boardeaser Business units 10",
    price: "599 SEK",
  },
  {
    name: "Boardeaser Contracts 50",
    price: "99 SEK",
  },
  {
    name: "Boardeaser Contracts 200",
    price: "199 SEK",
  },
  {
    name: "Boardeaser Contracts 1000",
    price: "499 SEK",
  },
  {
    name: "Boardeaser iPad och iPhone App 5 användare",
    price: "199 SEK",
  },
  {
    name: "Boardeaser iPad och iPhone App 12 användare",
    price: "399 SEK",
  },
  {
    name: "Boardeaser iPad och iPhone App 24 användare",
    price: "599 SEK",
  },
  {
    name: "Boardeaser Project 25",
    price: "49 SEK",
  },
  {
    name: "Boardeaser Project 250",
    price: "399 SEK",
  },
  {
    name: "Boardeaser Video Meetings 10",
    price: "199 SEK",
  },
  {
    name: "Boardeaser Video Meetings 24",
    price: "399 SEK",
  },
  {
    name: "Boardeaser Video Meetings 60",
    price: "599 SEK",
  },
];

export { FEATURE_TABLE };
