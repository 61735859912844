<template>
  <tr>
    <td class="d-print-none">
      <a :href="url(`/shareholder_register/actions/${action.id}`)">
        {{ actionTypeTitle }}
      </a>

      <be-spinner v-if="loading" class="ml-1" />
    </td>

    <td class="d-none d-print-table-cell">
      {{ actionTypeTitle }}
    </td>

    <td class="col-shrink text-muted d-print-none">
      <i
        v-if="isMissingTransactions(action, actionTransactions)"
        v-be-tooltip="
          $t('components.shareholder_registers.action_row.transactions_missing')
        "
        class="fa-duotone fa-triangle-exclamation fa-fw text-warning"
        :data-original-title="
          $t('components.shareholder_registers.action_row.transactions_missing')
        "
      />

      <i
        v-if="showLockIcon"
        v-be-tooltip="
          $t('models.shareholder_register_action.tooltips.approved')
        "
        class="fal fa-lock fa-fw"
      />
    </td>

    <td class="col-shrink">{{ $d(new Date(action.action_date), "date") }}</td>

    <td class="col-shrink d-print-none">
      <be-button
        v-if="action.policy.approve"
        variant="primary"
        size="sm"
        :disabled="loading"
        inline
        @click="approveAction"
      >
        {{ $t("models.shareholder_register_action.approve") }}
      </be-button>

      <be-button
        v-if="action.policy.unapprove"
        variant="outline-secondary"
        size="sm"
        :disabled="loading"
        inline
        @click="unapproveAction"
      >
        {{ $t("models.shareholder_register_action.unapprove") }}
      </be-button>

      <be-dropdown
        v-if="actionsAvailable && lastAction && !isApproved"
        size="sm"
        inline
        ellipsis
      >
        <be-dropdown-item
          v-if="allowedToAddTransaction"
          :href="
            url(
              `/shareholder_register/actions/${action.id}/transactions/new?type=${action.action_type}`
            )
          "
        >
          {{
            $t("components.shareholder_registers.action_row.new_transaction")
          }}
        </be-dropdown-item>

        <be-dropdown-item
          v-else-if="allowedToAddOwner"
          :href="url('/shareholder_register/edit')"
        >
          {{ $t("models.shareholder.add") }}
        </be-dropdown-item>

        <be-dropdown-item
          v-if="allowedToEdit"
          :href="url(`/shareholder_register/actions/${action.id}/edit`)"
          :disabled="loading"
        >
          {{ $t("buttons.titles.edit") }}
        </be-dropdown-item>

        <be-dropdown-divider v-if="showDivider" />

        <be-dropdown-item
          v-if="allowedToDestroy"
          variant="danger"
          :disabled="loading"
          @click.prevent="removeAction()"
        >
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from "@/event-bus";
import { isMissingTransactions } from "@/utils/shareholder_register_helpers";

export default {
  props: {
    action: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      destroyed: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      lastAction: "shareholder_register/lastAction",
      transactions: "shareholder_register/transactions",
    }),

    actionTypeTitle() {
      return this.$i18n.t(
        `models.shareholder_register_action.action_types.${this.action.action_type}`
      );
    },

    actionTitleAndDate() {
      return `${this.actionTypeTitle} - ${this.$d(
        new Date(this.action.action_date),
        "date"
      )}`;
    },

    actionTransactions() {
      return this.transactions.filter(
        (transaction) =>
          transaction.shareholder_register_action_id === this.action.id
      );
    },

    allowedToAddTransaction() {
      return (
        this.action.policy.create_transaction &&
        [
          "company_formation",
          "equity_issuance",
          "sale",
          "share_transfer",
        ].includes(this.action.action_type)
      );
    },

    allowedToApprove() {
      return !this.isApproved && this.action.policy.approve;
    },

    allowedToUnapprove() {
      return this.isApproved && this.action.policy.unapprove;
    },

    allowedToAddOwner() {
      return this.action.policy.edit && this.action.policy.add_owner;
    },

    allowedToEdit() {
      return this.action.policy.edit;
    },

    allowedToDestroy() {
      return this.action.policy.destroy;
    },

    actionsAvailable() {
      return (
        this.allowedToApprove ||
        this.allowedToUnapprove ||
        this.allowedToAddTransaction ||
        this.allowedToAddOwner ||
        this.allowedToEdit ||
        this.allowedToDestroy
      );
    },

    isApproved() {
      return this.action.approved_at;
    },

    lastAction() {
      return this.shareholderRegister.last_action_id == this.action.id;
    },

    showDivider() {
      return (
        this.allowedToAddTransaction ||
        this.allowedToAddOwner ||
        this.allowedToEdit
      );
    },

    showLockIcon() {
      return this.action.approved_at;
    },
  },

  methods: {
    isMissingTransactions,

    async approveAction() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t(
          "components.shareholder_registers.action_row.approve"
        ),

        title: this.$i18n.t(
          "components.shareholder_registers.action_row.confirm_approve_w_title",
          {
            title: this.actionTitleAndDate,
          }
        ),

        description: this.$i18n.t(
          "components.shareholder_registers.action_row.confirm_approve_description"
        ),
      });

      if (isConfirmed) {
        this.approve();
      }
    },

    async unapproveAction() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t(
          "components.shareholder_registers.action_row.unapprove"
        ),

        title: this.$i18n.t(
          "components.shareholder_registers.action_row.confirm_unapprove_w_title",
          {
            title: this.actionTitleAndDate,
          }
        ),

        description: this.$i18n.t(
          "components.shareholder_registers.action_row.confirm_unapprove_description"
        ),
      });

      if (isConfirmed) {
        this.unapprove();
      }
    },

    async removeAction() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_title", {
          title: this.actionTitleAndDate,
        }),

        description: this.$i18n.t(
          "components.shareholder_registers.action_row.remove_action_description"
        ),
      });

      if (isConfirmed) {
        this.remove();
        EventBus.emit("shareholder-register-changed");
      }
    },

    async approve() {
      this.loading = true;

      await this.$store.dispatch(
        "shareholder_register/approveAction",
        this.action
      );

      this.loading = false;
    },

    async unapprove() {
      this.loading = true;

      await this.$store.dispatch(
        "shareholder_register/unapproveAction",
        this.action
      );

      this.loading = false;
    },

    async remove() {
      this.loading = true;

      await this.$store.dispatch(
        "shareholder_register/destroyAction",
        this.action
      );

      this.loading = false;
    },
  },
};
</script>
