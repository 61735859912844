<template>
  <div v-show="!hidden">
    <label class="mr-2">{{ `${position == 1 ? "1*" : "5*"}:` }}</label>

    <be-form-group :error="getErrors(possibleAnswer, ['title'])">
      <be-form-input
        :model-value="possibleAnswer.title"
        maxlength="255"
        :class="{ hidden: hidden }"
        :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][title]`"
        @change="clearErrors(possibleAnswer, ['title'])"
        @input="update('title', $event)"
      />
    </be-form-group>

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][position]`"
      :value="position"
    />

    <input
      v-if="question.id"
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][question_id]`"
      :value="question.id"
    />

    <input
      v-if="possibleAnswer.created_at"
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][id]`"
      :value="possibleAnswer.id"
    />
  </div>
</template>

<script>
export default {
  props: {
    hidden: {
      type: Boolean,
      default: false,
    },

    possibleAnswer: {
      type: Object,
      required: true,
    },

    question: {
      type: Object,
      required: true,
    },

    section: {
      type: Object,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },

    position: {
      type: Number,
      required: true,
    },
  },

  methods: {
    update(key, value) {
      this.$store.dispatch("evaluation_forms/updatePossibleAnswer", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,
        possibleAnswerId: this.possibleAnswer.id || this.possibleAnswer.uuid,
        data: { [key]: value },
      });
    },
  },
};
</script>
