<template>
  <div class="card mb-2">
    <div
      class="card-header d-md-flex justify-content-between align-items-center sticky-top bg-white"
    >
      <save-status
        class="mb-3 mb-md-0 small"
        :loading="loading"
        :failed-to-save="failedToSaveSomeItem"
        :failed-to-save-error="failedToSaveError"
        :validation-failed="formInvalid"
        :validation-failed-text="
          $t('components.meetings.material.editor.form_invalid')
        "
        :unsaved-changes="isDirty"
        :last-saved-at="lastSavedAt"
        :last-saved-at-prefix="lastSavedAtPrefix"
      />

      <div v-if="!material.external_uploaded_at && items.length > 0">
        <be-button variant="outline-secondary" @click="toggleAllItems">
          {{ toggleButtonText }}
        </be-button>
      </div>
    </div>

    <external-material-item
      v-if="material.external_uploaded_at"
      :material="material"
    />

    <div v-else class="card-body p-2 p-md-4">
      <div class="accordion">
        <drop-list
          v-if="items.length > 0"
          :items="items"
          no-animations
          class="list-group list-group-flush"
          @reorder="reorderItems"
        >
          <template #item="{ item }">
            <drag :key="`item-${item.id}`" handle=".handle">
              <item
                :key="`item-${item.id}`"
                :item="item"
                :open="openItems.includes(item.id)"
                @open="openItem"
                @close="closeItem"
              />
            </drag>
          </template>

          <!-- This slot must be defined, even if it is empty -->
          <template #feedback></template>
        </drop-list>

        <be-alert v-else-if="isAgenda" variant="info">
          <i18n-t
            keypath="components.meetings.material.editor.no_items_w_add_an_item_and_import_from_a_template"
            tag="span"
          >
            <template #add_an_item>
              <be-link v-be-modal.add-item>
                {{ $t("components.meetings.material.editor.add_an_item") }}
              </be-link>
            </template>

            <template #import_from_a_template>
              <be-link @click="$emit('switch-to-template-list')">
                {{
                  $t(
                    "components.meetings.material.editor.import_from_a_template"
                  )
                }}
              </be-link>
            </template>
          </i18n-t>
        </be-alert>

        <be-alert v-else-if="isMinutes" variant="info">
          <i18n-t
            keypath="components.meetings.material.editor.no_items_w_add_an_item"
            tag="span"
          >
            <template #add_an_item>
              <be-link v-be-modal.add-item>
                {{ $t("components.meetings.material.editor.add_an_item") }}
              </be-link>
            </template>
          </i18n-t>
        </be-alert>
      </div>

      <div class="d-md-flex justify-content-end mt-2">
        <be-button v-be-modal.add-item variant="light" icon="fa-plus">
          {{ $t("components.meetings.material.editor.add_item") }}
        </be-button>
      </div>
    </div>

    <material-editor-footer current-page="editor" />

    <!-- Add new Item modal -->
    <be-modal
      id="add-item"
      :title="$t('components.meetings.material.editor.add_item')"
      :ok-title="$t('buttons.titles.add_more')"
      @ok="addNewItemClick"
    >
      <be-form-group
        :label="$t('components.meetings.material.editor.item_title')"
        label-for="title"
      >
        <be-form-input
          id="title"
          v-model="newItemTitle"
          autocomplete="off"
          @keyup.enter="addNewItem"
        />
      </be-form-group>
    </be-modal>

    <!-- Change item start number modal -->
    <be-modal
      v-if="$currentCompany.continuous_numbering"
      id="change-item-start-number"
      :title="
        $t('components.meetings.material.editor.change_item_start_number')
      "
      :ok-title="$t('buttons.titles.save')"
      :ok-disabled="!!invalidContinuousItemNumberStart"
      @ok="changeItemStartNumberClick"
    >
      <be-form-group
        label-for="item-number-start"
        :label="
          $t('activerecord.attributes.meeting.continuous_item_number_start')
        "
        :error="invalidContinuousItemNumberStart?.message"
      >
        <be-form-input
          id="item-number-start"
          v-model="continuousItemNumberStart"
          type="number"
          number
          min="0"
          :state="invalidContinuousItemNumberStart?.state"
        />
      </be-form-group>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { Drag, DropList } from "vue-easy-dnd";

import materialType from "./materialState";
import Item from "./MaterialItem.vue";
import SaveStatus from "@/components/shared/SaveStatus.vue";
import MaterialEditorFooter from "./MaterialEditorFooter.vue";
import ExternalMaterialItem from "./ExternalMaterialItem.vue";

export default {
  components: {
    Drag,
    DropList,
    Item,
    SaveStatus,
    MaterialEditorFooter,
    ExternalMaterialItem,
  },

  mixins: [materialType],

  emits: ["switch-to-template-list"],

  data() {
    return {
      openItems: [],
      newItemTitle: "",
      continuousItemNumberStart: 1,
    };
  },

  computed: {
    ...mapGetters("material", [
      "failedToSaveSomeItem",
      "formInvalid",
      "items",
      "loading",
      "material",
      "meeting",
      "lastItemUpdatedAt",
      "isDirty",
    ]),

    toggleButtonText() {
      const openItemsCount = this.openItems.length;

      if (openItemsCount > 0) {
        return this.$i18n.t("buttons.toggle_all_selection.minimize_all");
      }
      return this.$i18n.t("buttons.toggle_all_selection.expand_all");
    },

    secretaryDoneAt() {
      if (this.material.secretary_done_at) {
        return new Date(this.material.secretary_done_at);
      }

      return null;
    },

    failedToSaveError() {
      if (this.formInvalid) {
        return this.$t("components.meetings.material.editor.form_invalid");
      } else {
        return null;
      }
    },

    lastSavedAt() {
      if (this.isAgenda && this.secretaryDoneAt) {
        return this.$t(
          "components.meetings.material.editor.save_state.secretary_done_at_w_time",
          {
            time: this.$d(
              this.secretaryDoneAt,
              this.dateFormatIfSameDay(this.secretaryDoneAt)
            ),
          }
        );
      } else {
        return this.lastItemUpdatedAt;
      }
    },

    lastSavedAtPrefix() {
      if (this.isAgenda && !this.secretaryDoneAt) {
        return this.$t(
          "components.meetings.material.editor.save_state.last_saved_at_prefix_agenda"
        );
      }

      if (this.isMinutes) {
        return this.$t(
          "components.meetings.material.editor.save_state.last_saved_at_prefix_minutes"
        );
      }

      return null;
    },

    invalidContinuousItemNumberStart() {
      return this.continuousItemNumberStart <= 0
        ? {
            message: this.$t("errors.messages.greater_than_or_equal_to", {
              count: 0,
            }),

            state: false,
          }
        : null;
    },
  },

  watch: {
    "meeting.continuous_item_number_start": {
      handler(value) {
        if (this.$currentCompany.continuous_numbering) {
          this.continuousItemNumberStart = value || 1;
        } else {
          this.continuousItemNumberStart = 1;
        }
      },
    },
  },

  mounted() {
    if (this.$currentCompany.continuous_numbering) {
      this.continuousItemNumberStart =
        this.meeting?.continuous_item_number_start || 1;
    }
  },

  methods: {
    ...mapActions("material", [
      "changeItemStartNumber",
      "createItem",
      "removeItem",
      "updateItemNumber",
    ]),

    // Call preventDefault in a non async method, to avoid closing.
    addNewItemClick(event) {
      event.preventDefault();
      this.addNewItem();
    },

    async addNewItem() {
      const result = await this.createItem({
        item: { title: this.newItemTitle },
      });
      if (result) {
        const newItem = this.items[this.items.length - 1];
        this.openItems.push(newItem.id);
        this.newItemTitle = "";
        this.$beModal.hide("add-item");
      }
    },

    // Call preventDefault in a non async method, to avoid closing.
    changeItemStartNumberClick(event) {
      event.preventDefault();
      this.handleItemStartNumber();
    },

    async handleItemStartNumber() {
      await this.changeItemStartNumber({
        materialId: this.material.id,
        itemNumberStart: this.continuousItemNumberStart,
      });
      this.$beModal.hide("change-item-start-number");
    },

    closeItem(itemId) {
      const index = this.openItems.findIndex(
        (existingId) => existingId == itemId
      );

      if (index > -1) {
        this.openItems.splice(index, 1);
      }
    },

    openItem(itemId) {
      const index = this.openItems.findIndex(
        (existingId) => existingId == itemId
      );

      if (index == -1) {
        this.openItems.push(itemId);
      }
    },

    toggleAllItems() {
      if (this.openItems.length > 0) {
        this.openItems = [];
      } else {
        this.openItems = this.items.map((item) => item.id);
      }
    },

    reorderItems(event) {
      const item = this.items[event.from];
      event.apply(this.items);
      if (item) {
        // Need to add one because numbers are 1-indexed!
        this.updateItemNumber({
          item: item,
          to: event.to + this.continuousItemNumberStart,
        });
      }
    },
  },
};
</script>
