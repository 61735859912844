<template>
  <div>
    <div
      class="card mb-1"
      :class="{
        show: expanded,
        'border-left-danger': invalid,
      }"
    >
      <div
        class="card-header p-2 px-md-4"
        :class="{ 'bg-light': bgVariant == 'light' }"
        @click="toggleItemExpanded"
      >
        <div class="row align-items-center">
          <div class="col">
            <div class="row no-gutters">
              <div class="col-auto my-auto">
                <div class="text-decoration-none mr-3">
                  <span class="text-primary">
                    <i
                      v-if="draggable"
                      class="fal fa-grip-vertical mr-2 handle d-none d-md-inline"
                      :class="[handleClass]"
                      style="cursor: move"
                    />

                    <i
                      class="fal fa-fw"
                      :class="{
                        'fa-chevron-down': expanded,
                        'fa-chevron-right': !expanded,
                      }"
                    />
                  </span>
                </div>
              </div>

              <div class="col">
                <slot name="title" />
              </div>
            </div>
          </div>

          <slot name="right" />
        </div>
      </div>

      <div v-show="expanded">
        <slot />
      </div>
    </div>

    <slot name="after" />
  </div>
</template>
<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      required: true,
    },

    invalid: {
      type: Boolean,
      default: false,
    },

    handleClass: {
      type: String,
      default: null,
    },

    bgVariant: {
      type: String,
      default: null,
    },

    draggable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["toggle-item-expanded"],

  methods: {
    toggleItemExpanded() {
      this.$emit("toggle-item-expanded");
    },
  },
};
</script>
