<template>
  <div class="row">
    <div class="col">
      <be-form-group
        :label="$t('activerecord.attributes.compliance_version.title')"
        label-for="compliance-version-title"
        :error="getErrors(localComplianceVersion, 'title')"
      >
        <be-form-input
          id="compliance-version-title"
          v-model="localComplianceVersion.title"
          maxlength="255"
          required
          @change="clearErrors(localComplianceVersion, 'title')"
        />
      </be-form-group>

      <be-form-group
        label-for="compliance-version-start_at"
        :label="translateAttribute('compliance_document_version', 'start_at')"
        :error="getErrors(localComplianceVersion, 'start_at')"
      >
        <be-form-datepicker
          id="compliance-version-start_at"
          v-model="localComplianceVersion.start_at"
          :max-date="localComplianceVersion.end_at"
          :state="validationState(localComplianceVersion, 'start_at')"
          @change="clearErrors(localComplianceVersion, 'start_at')"
        />
      </be-form-group>

      <be-form-group
        label-for="compliance-version-end_at"
        :label="translateAttribute('compliance_document_version', 'end_at')"
        :error="getErrors(localComplianceVersion, 'end_at')"
      >
        <be-form-datepicker
          id="compliance-version-end_at"
          v-model="localComplianceVersion.end_at"
          :min-date="localComplianceVersion.start_at"
          :state="validationState(localComplianceVersion, 'end_at')"
          @change="clearErrors(localComplianceVersion, 'end_at')"
        />

        <template v-if="'reminders' === 'implemented'" #description>
          {{ $t("models.compliance.hints.current_end_at") }}
        </template>
      </be-form-group>

      <be-form-group
        v-if="localComplianceVersion.id"
        :label="$t('activerecord.models.document.other')"
        label-for="document-uploader"
        :description="
          $t(
            'components.compliances.version_form.documents_saved_automatically'
          )
        "
      >
        <document-uploader
          id="document-uploader"
          :documents="uploaderDocuments"
          support-select-from-archive
          @document-added="saveDocument"
          @document-removed="removeDocument"
        />
      </be-form-group>

      <div
        class="d-flex flex-wrap justify-content-between align-items-center gap-2"
      >
        <save-status
          class="mr-auto"
          :validation-failed="
            localComplianceVersion.errors &&
            Object.keys(localComplianceVersion.errors).length > 0
          "
          :unsaved-changes="unsavedChanges"
          :last-saved-at="lastSavedAt"
        />

        <be-button
          v-if="localComplianceVersion.current"
          variant="outline-primary"
          @click="$emit('archive-version', localComplianceVersion)"
        >
          {{ $t("buttons.titles.archive") }}
        </be-button>

        <be-button
          variant="primary"
          @click="$emit('save-version', localComplianceVersion)"
        >
          {{ $t("buttons.titles.save") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import SaveStatus from "@/components/shared/SaveStatus.vue";
import isEqual from "lodash/isEqual";

export default {
  components: {
    SaveStatus,
  },

  props: {
    compliance: {
      type: Object,
      required: true,
    },

    complianceVersion: {
      type: Object,
      required: true,
    },

    complianceDocuments: {
      type: Array,
      required: true,
    },
  },

  emits: [
    "archive-version",
    "save-version",
    "compliance-document-added",
    "compliance-document-removed",
  ],

  data() {
    return {
      localComplianceVersion: this.cloneDeep(this.complianceVersion),
      loading: false,
      pendingDocuments: [],
    };
  },

  computed: {
    documents() {
      return this.complianceDocuments.map(
        (complianceDocument) => complianceDocument.document
      );
    },

    uploaderDocuments() {
      return this.documents.concat(this.pendingDocuments);
    },

    unsavedChanges() {
      return !isEqual(this.localComplianceVersion, this.complianceVersion);
    },

    lastSavedAt() {
      if (this.localComplianceVersion.updated_at) {
        return new Date(this.localComplianceVersion.updated_at);
      } else {
        return null;
      }
    },
  },

  watch: {
    complianceVersion() {
      this.localComplianceVersion = this.cloneDeep(this.complianceVersion);
    },
  },

  methods: {
    async saveDocument(doc) {
      try {
        this.pendingDocuments.push(doc);
        const { data } = await axios.post(
          this.url(
            `/documents/compliances/${this.compliance.id}/versions/${this.complianceVersion.id}/documents`
          ),
          {
            document: doc,
          }
        );

        this.$emit("compliance-document-added", data);

        this.pendingDocuments = this.pendingDocuments.filter(
          (pending) => pending.file_uuid !== doc.file_uuid
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    async removeDocument(document) {
      const index = this.compliance.compliance_documents.findIndex(
        (complianceDocument) => complianceDocument.document_id === document.id
      );

      if (index === -1) {
        return;
      }

      const complianceDocument = this.compliance.compliance_documents[index];

      try {
        const isConfirmed = await this.promptRemovalConfirm(
          this.$t("nav.confirm_delete_w_title", {
            title: complianceDocument.document.title,
          })
        );

        if (!isConfirmed) {
          return;
        }

        await axios.delete(
          this.url(
            `/documents/compliances/${this.compliance.id}/versions/${this.complianceVersion.id}/documents/${complianceDocument.id}`
          )
        );

        this.$emit("compliance-document-removed", complianceDocument);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
