<template>
  <div v-if="features.length" class="row">
    <div
      v-for="feature in features"
      :key="`feature-${feature.id}`"
      class="col-12 col-md-6 col-lg-4 mb-4"
    >
      <onboarding-feature
        :feature="feature"
        :selected="featureIsSelected(feature)"
        @feature-selected="selectFeature"
        @feature-deselected="deselectFeature"
      />
    </div>
  </div>

  <be-alert v-else variant="info" class="mb-2">
    {{ $t("components.onboarding.no_features") }}
  </be-alert>
</template>

<script>
import OnboardingFeature from "./OnboardingFeature.vue";

export default {
  components: {
    OnboardingFeature,
  },

  props: {
    features: {
      type: Array,
      required: true,
    },

    selectedFeatures: {
      type: Array,
      required: true,
    },
  },

  emits: ["feature-selected", "feature-deselected"],

  methods: {
    selectFeature(feature) {
      this.$emit("feature-selected", feature);
    },

    deselectFeature(feature) {
      this.$emit("feature-deselected", feature);
    },

    featureIsSelected(feature) {
      // Return if no features selected
      if (!this.selectedFeatures.length) {
        return false;
      }

      return !!this.selectedFeatures.find(
        (selectedFeature) => selectedFeature.id == feature.id
      );
    },
  },
};
</script>
