<template>
  <div>
    <div class="card-body">
      <invitation
        v-if="evaluationForm"
        v-model:checked-user-ids="checkedUserIds"
        class="mb-4"
        :invite-policy="evaluationForm.policy.invite"
        :uninvite-policy="false"
        :checkbox-invites="true"
        :invitations="invitations"
        :hide-membership-invitations-link="hideMembershipInvitationsLink"
        @invite="invite"
        @uninvite="uninvite"
        @memberships-updated="membershipsUpdated"
      />

      <be-form-group
        label-for="invitation-message"
        :label="$t('activerecord.attributes.membership.invitation_message')"
        :description="$t('models.membership.hints.invitation_message')"
      >
        <be-form-textarea
          id="invitation-message"
          v-model="invitationMessage"
          rows="3"
          max-rows="20"
        />
      </be-form-group>
    </div>

    <div class="card-footer d-md-flex justify-content-end">
      <be-button
        v-if="evaluationForm && evaluationForm.evaluation_report"
        :href="url(`/evaluation_forms/${evaluationForm.id}/report`)"
        variant="outline-primary"
      >
        {{
          $t(
            "components.evaluation_forms.evaluation_form_viewer.evaluation_row.view_report"
          )
        }}
      </be-button>

      <be-button
        :disabled="sending || usersToInvite.length == 0"
        variant="primary"
        icon="fa-envelope"
        @click="confirmSendInvites"
      >
        {{ sendInvitesButtonText }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Invitation from "@/components/companies/UserInvitations.vue";

export default {
  components: {
    Invitation,
  },

  props: {
    initialEvaluationForm: {
      type: Object,
      required: true,
    },

    initialInvitations: {
      type: Array,
      required: true,
    },

    initialMemberships: {
      type: Array,
      required: true,
    },

    hideMembershipInvitationsLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      invitationMessage: "",
      sending: false,
      publishing: false,
      checkedUserIds: [],
    };
  },

  computed: {
    sendInvitesButtonText() {
      return this.sending
        ? this.$i18n.t("buttons.states.sending")
        : this.$i18n.t("components.evaluation_forms.participants.send_invites");
    },

    usersToInvite() {
      return this.invitations.filter(
        (invitation) =>
          this.checkedUserIds.includes(invitation.user_id) &&
          (invitation.id == null || invitation.deleted_at)
      );
    },

    ...mapGetters({
      users: "company/users",
      evaluationForm: "evaluation_forms/getEvaluationForm",
      invitations: "evaluation_forms/getInvitations",
    }),
  },

  mounted() {
    this.$store.dispatch(
      "evaluation_forms/setEvaluationForm",
      this.initialEvaluationForm
    );

    this.$store.dispatch(
      "evaluation_forms/setInvitations",
      this.initialInvitations
    );

    this.$store.commit("memberships/setMemberships", this.initialMemberships);
  },

  methods: {
    invite(user) {
      this.$store.dispatch("evaluation_forms/inviteUser", user);
    },

    uninvite(invitation) {
      this.$store.dispatch("evaluation_forms/uninviteUser", invitation);
    },

    membershipsUpdated() {
      this.$store.dispatch("evaluation_forms/loadInvitations");
    },

    async sendInvites() {
      this.sending = true;

      let invitations = [];
      this.usersToInvite.forEach((invitation) => {
        let invite = this.$store.dispatch("evaluation_forms/inviteUser", {
          user_id: invitation.user_id,
          message: this.invitationMessage,
        });

        invitations.push(invite);
      });

      await Promise.all(invitations);

      this.invitationMessage = "";
      this.sending = false;

      if (!this.evaluationForm.evaluation_report) {
        this.$store.dispatch("evaluation_forms/loadEvaluationForm");
      }
    },

    async confirmSendInvites() {
      if (
        !this.evaluationForm.evaluation_report &&
        !this.evaluationForm.invitations_sent_at
      ) {
        const isConfirmed = await this.promptConfirm({
          title: this.$t(
            "components.evaluation_forms.participants.confirm_send_invitations"
          ),

          description: this.$t(
            "components.evaluation_forms.participants.confirm_send_invitations_description"
          ),

          confirmButtonText: this.$i18n.t("buttons.titles.send"),
        });

        if (isConfirmed) {
          this.sendInvites();
        }
      } else {
        this.sendInvites();
      }
    },
  },
};
</script>
