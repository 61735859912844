<template>
  <div class="card">
    <div class="card-header">
      <div class="row no-gutters align-items-center">
        <be-link class="text-decoration-none mr-3" @click="toggleVisibility">
          <span class="text-primary">
            <i
              class="fal"
              :class="{
                'fa-chevron-down': section.open,
                'fa-chevron-right': !section.open,
              }"
            />
          </span>
        </be-link>

        <div class="col">
          <h5 class="mb-0">
            {{ section.title }}
          </h5>
        </div>

        <div class="d-flex col-auto">
          <div class="custom-control custom-checkbox">
            <input
              :id="`include-section-${section.id}-in-library`"
              type="checkbox"
              class="custom-control-input"
              :checked="sectionIncluded"
              @change="toggleSaveToReport()"
            />

            <label
              class="custom-control-label"
              :for="`include-section-${section.id}-in-library`"
            >
              <small>
                {{
                  $t(
                    "views.companies.evaluation_forms.reports.open_question_results.include"
                  )
                }}
              </small>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="accordion">
        <form-question
          v-for="(question, index) in section.evaluation_questions"
          :key="`question-${index}`"
          :index="index"
          :question="question"
          :question-chart-data="sectionChartData.questions[question.id]"
          :section="section"
          class="mb-1"
          @question-inclusion-toggled="
            $emit('question-inclusion-toggled', {
              sectionId: section.id,
              question: question,
            })
          "
          @question-visibility-toggled="
            $emit('question-visibility-toggled', {
              sectionId: section.id,
              question: question,
            })
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormQuestion from "./FormQuestion.vue";

export default {
  components: {
    FormQuestion,
  },

  props: {
    section: {
      type: Object,
      required: true,
    },

    showGraphs: {
      type: Boolean,
      required: true,
    },

    sectionChartData: {
      type: Object,
      required: true,
    },
  },

  emits: [
    "question-inclusion-toggled",
    "question-visibility-toggled",
    "section-inclusion-toggled",
    "section-visibility-toggled",
  ],

  data() {
    return {
      sectionIncluded: this.section.evaluation_questions.every(
        (question) => question.included_in_report
      ),
    };
  },

  computed: {
    allQuestionsIncluded() {
      return this.section.evaluation_questions.every(
        (question) => question.included_in_report
      );
    },
  },

  watch: {
    allQuestionsIncluded(value) {
      this.sectionIncluded = value;
    },
  },

  mounted() {
    if (
      !this.section.evaluation_questions.find(
        (question) => !question.included_in_report
      )
    ) {
      this.$emit("section-inclusion-toggled", {
        sectionId: this.section.id,
        includedInReport: true,
      });
    }
  },

  methods: {
    toggleVisibility() {
      this.$emit("section-visibility-toggled", {
        sectionId: this.section.id,
        open: !this.section.open,
      });
    },

    toggleSaveToReport() {
      this.$emit("section-inclusion-toggled", {
        sectionId: this.section.id,
        includedInReport: !this.sectionIncluded,
      });
    },
  },
};
</script>
