<template>
  <div>
    <div class="card mb-2">
      <div class="card-header text-right">
        <be-button
          :href="url('/message_threads/new')"
          variant="outline-primary"
        >
          {{ $t("models.message_thread.new") }}
        </be-button>
      </div>

      <div class="card-body">
        <table class="table table-hover">
          <thead>
            <tr>
              <th class="d-none d-lg-table-cell"></th>

              <th>{{ translateAttribute("message_thread", "title") }}</th>

              <th></th>

              <th class="col-shrink d-none d-lg-table-cell">
                {{ $t("components.companies.message_threads.last_post") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <message-thread-row
              v-for="thread in threads"
              :key="`${thread.id}-${loadedAt}`"
              :thread="thread"
              :checked="selected[thread.id] || false"
              @marked="select"
            />
          </tbody>
        </table>
      </div>

      <div class="card-footer d-none d-lg-block">
        <be-button variant="outline-secondary" @click="toggleAll">
          {{ toggleButtonText }}
        </be-button>

        <be-button
          variant="outline-secondary"
          :disabled="markAsReadDisabled"
          @click="markAsRead"
        >
          {{ $t("components.companies.message_threads.mark_as_read") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import MessageThreadRow from "./MessageThreadRow.vue";

export default {
  components: {
    MessageThreadRow,
  },

  props: {
    initialThreads: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      threads: this.initialThreads,
      selected: {},
      loadedAt: Date.now(),
    };
  },

  computed: {
    allSelected: {
      get() {
        return !this.threads.some((thread) => !this.selected[thread.id]);
      },
    },

    markAsReadDisabled() {
      return !Object.entries(this.selected).some((item) => item[1] == true);
    },

    toggleButtonText() {
      if (this.allSelected) {
        return this.$i18n.t("buttons.toggle_all_selection.deselect_all");
      }
      return this.$i18n.t("buttons.toggle_all_selection.select_all");
    },
  },

  mounted() {
    this.selected = this.setupSelection();
  },

  methods: {
    toggleAll() {
      const willBeSelected = !this.allSelected;

      for (var thread of this.threads) {
        this.selected[thread.id] = willBeSelected;
      }
    },

    select(thread, value) {
      this.selected[thread.id] = value;
    },

    async markAsRead() {
      let threadIds = [];
      Object.entries(this.selected).forEach((item) => {
        if (item[1]) {
          threadIds.push(item[0]);
        }
      });

      const response = await axios.post(
        this.url(`/message_threads/mark_as_read`),
        {
          mark_as_read: threadIds,
        }
      );

      this.threads = response.data;
      this.loadedAt = Date.now();
      this.selected = this.setupSelection();
    },

    setupSelection() {
      let selected = {};
      this.threads.forEach((thread) => {
        selected[thread.id] = false;
      });
      return selected;
    },
  },
};
</script>
