<template>
  <div class="row align-items-end">
    <div class="col-12 mb-2">
      <h3>
        {{ $t("components.annual_reports.ixbrl_signature_settings.title") }}
      </h3>

      <be-alert v-if="!currentUserIsAuditor" variant="warning">
        {{
          $t(
            "components.annual_reports.ixbrl_signature_settings.not_auditor_warning"
          )
        }}
      </be-alert>
    </div>

    <be-form-group
      :label="
        $t(
          'components.annual_reports.ixbrl_signature_settings.audit_company.title'
        )
      "
      label-for="audit_company"
      :error="getErrors(annualReport, ['audit_company'])"
      class="col-6"
    >
      <be-form-input
        id="audit_company"
        v-model="auditCompany"
        name="annual_report[audit_company]"
        :disabled="saving"
        :state="validationState(annualReport, ['audit_company'])"
        @change="(event) => handleSettingsChange('audit_company', event || '')"
      />
    </be-form-group>

    <be-form-group
      :label="
        $t(
          'components.annual_reports.ixbrl_signature_settings.audit_place.title'
        )
      "
      label-for="audit_place"
      :error="getErrors(annualReport, ['audit_place'])"
      class="col-6"
    >
      <be-form-input
        id="audit_place"
        v-model="auditPlace"
        name="annual_report[audit_place]"
        required
        :disabled="saving"
        :state="validationState(annualReport, ['audit_place'])"
        @change="(event) => handleSettingsChange('audit_place', event || '')"
      />
    </be-form-group>

    <be-form-group
      :label="
        $t(
          'components.annual_reports.ixbrl_signature_settings.audit_opinion.title'
        )
      "
      label-for="audit_opinion"
      :error="getErrors(annualReport, ['audit_opinion'])"
      class="col-12"
    >
      <be-form-select
        id="audit_opinion"
        v-model="auditOpinion"
        name="annual_report[audit_opinion]"
        :options="annualReport.audit_opinion_options"
        :include-blank-option="
          $t(
            'components.annual_reports.ixbrl_signature_settings.audit_opinion.empty_value'
          )
        "
        :disabled="saving"
        :state="validationState(annualReport, ['audit_opinion'])"
        @change="(event) => handleSettingsChange('audit_opinion', event || 0)"
      />
    </be-form-group>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";

export default {
  mixins: [AnnualReportTabBase],

  data() {
    return {
      saving: false,
      auditPlace: "",
      auditCompany: "",
      auditOpinion: "",
    };
  },

  watch: {
    "annualReport.audit_place": function (newValue) {
      this.auditPlace = newValue;
    },

    "annualReport.audit_company": function (newValue) {
      this.auditCompany = newValue;
    },

    "annualReport.audit_opinion": function (newValue) {
      this.auditOpinion = newValue;
    },
  },

  created() {
    this.auditPlace = this.annualReport.audit_place;
    this.auditCompany = this.annualReport.audit_company;
    this.auditOpinion = this.annualReport.audit_opinion;
  },

  methods: {
    async handleSettingsChange(attribute, value) {
      this.saving = true;
      const changedAnnualReport = { ...this.annualReport };
      changedAnnualReport[attribute] = value;
      await this.updateAnnualReport(changedAnnualReport);
      this.clearErrors(this.annualReport, [attribute]);
      this.saving = false;
    },
  },
};
</script>
