<template>
  <li class="mt-2" :class="{ 'mb-3': !lastRow }">
    <div class="small text-uppercase">
      {{ $t("models.shareholder_transaction.transaction_date") }}
    </div>

    <div class="mb-1 mb-md-0">
      {{ $d(new Date(seriesHistory.action_date), "date") }}
    </div>

    <span v-if="buyer && buyer.company_name">
      <span class="d-none d-md-inline">
        {{ formattedCompanySeries() }}
      </span>

      <span class="d-md-none">
        <div>{{ buyer.company_name }}</div>

        <div>{{ buyer.identification_number }}</div>

        <div>
          {{ $t("models.shareholder_transaction_series.share_number") }}
          : {{ seriesHistory.start }} - {{ seriesHistory.end }}
        </div>
      </span>
    </span>

    <span v-else>
      <span class="d-none d-md-inline">
        {{ formattedUserSeries() }}
      </span>

      <span class="d-md-none">
        <div>{{ buyer.name }} - ({{ buyer.identification_number }})</div>

        <div>
          {{ $t("models.shareholder_transaction_series.share_number") }}
          : {{ seriesHistory.start }} - {{ seriesHistory.end }}
        </div>
      </span>
    </span>

    <div
      v-if="seriesHistory.transaction.note"
      class="small"
      :class="{ 'mb-2': !lastRow }"
    >
      <i>
        <span class="text-wrap">
          {{
            $t("companies.shareholder_registers.previous_notes", {
              note: seriesHistory.transaction.note,
            })
          }}
        </span>
      </i>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    seriesHistories: {
      type: Array,
      required: true,
    },

    seriesHistory: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      shareholders: "shareholder_register/shareholders",
    }),

    buyer() {
      return this.shareholders.find(
        (shareholder) =>
          shareholder.id === this.seriesHistory.transaction.buyer_id
      );
    },

    lastRow() {
      return this.index === this.seriesHistories.length - 1;
    },
  },

  methods: {
    formattedUserSeries() {
      return `${this.buyer.name} (${
        this.buyer.identification_number
      }) - ${this.$i18n.t(
        "models.shareholder_transaction_series.share_number"
      )} : ${this.seriesHistory.start} - ${this.seriesHistory.end}`;
    },

    formattedCompanySeries() {
      return `${this.buyer.company_name} - ${this.$i18n.t(
        "models.shareholder_transaction_series.share_number"
      )} : ${this.seriesHistory.start} - ${this.seriesHistory.end}`;
    },
  },
};
</script>
