<template>
  <div class="card">
    <div class="card-body">
      <be-form-group
        label-for="group_id"
        :label="$t('components.educations.invite_friends.select_group')"
      >
        <be-form-select
          id="group_id"
          v-model="selectedGroup"
          :options="allFormattedGroups"
          :include-blank-option="
            $t('components.educations.invite_friends.no_group')
          "
          @change="populateForm"
        />
      </be-form-group>

      <table v-if="educationGroup" class="table">
        <thead>
          <tr>
            <th>{{ $t("activerecord.attributes.user.name") }}</th>

            <th class="col-shrink">
              {{
                $t("activerecord.attributes.education_group_participant.email")
              }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="participant in participants"
            :key="`participant-${participant.id}`"
            class="persisted"
          >
            <td>
              {{ participant.user.name }}
            </td>

            <td class="col-shrink">
              {{ participant.user.email }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="card-footer">
      <a :href="url('/educations/groups/new')" class="btn btn-outline-primary">
        {{ $t("models.education_group.new") }}
      </a>

      <a
        :href="createGroupUrl"
        class="btn btn-primary"
        :class="{ disabled: !educationGroup }"
        :disabled="!educationGroup"
      >
        {{ $t("components.educations.invite_friends.choose") }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      createGroupUrl: "",
      educationGroup: null,
      selectedGroup: null,
      participants: null,
    };
  },

  computed: {
    allFormattedGroups() {
      return this.groups.map((group) => ({
        value: group["id"],
        text: group.title,
      }));
    },
  },

  methods: {
    populateForm() {
      this.educationGroup = this.groups.find(
        (group) => group.id == this.selectedGroup
      );

      if (this.educationGroup) {
        this.participants = this.educationGroup.education_group_participants;
        this.createGroupUrl = this.url(
          `/educations/groups/${this.educationGroup.id}/edit`
        );
      }
    },
  },
};
</script>
