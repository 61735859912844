<template>
  <be-modal
    :id="`save-template-modal-${item.id}`"
    :title="$t('components.template_packages.public_templates.enter_code')"
    size="sm"
    @ok="save(item)"
  >
    <be-form-group class="mb-0" :error="error" required>
      <be-form-input
        v-model="password"
        @input="clearError"
        @keydown.enter="save(item)"
      />
    </be-form-group>

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        :disabled="!password"
        :loading="loading"
        variant="primary"
        @click="save(item)"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      password: "",
    };
  },

  methods: {
    ...mapMutations("template_packages", ["saveTemplate"]),

    clearError() {
      this.error = null;
    },

    async save(template) {
      this.loading = true;

      try {
        const { data } = await axios.post(
          this.url(`/template_packages/${template.id}/save_template`),
          {
            public_template: {
              password: this.password,
            },
          }
        );

        this.saveTemplate(data);
        this.$beModal.hide(`save-template-modal-${template.id}`);
      } catch (error) {
        if (error.response?.status === 403) {
          this.error = this.$t(
            "models.template_package.password_error_message"
          );
        } else {
          this.handleError(error);
        }

        this.loading = false;
      }
    },
  },
};
</script>
