<template>
  <be-form-group label-for="reminder" :label="$t(label)">
    <be-form-select
      id="reminder"
      v-model="selectedReminder"
      name="contract[reminder]"
      class="mb-1"
      :options="reminders"
      :disabled="contract.editing_disabled"
    />
  </be-form-group>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },

    reminders: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedReminder: this.contract.reminder || this.reminders[0].value,
    };
  },
};
</script>
