<template>
  <div class="col">
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-html="
        $t('views.auth.mfa.emails.new.description_html', {
          email: userEmail,
        })
      "
    />

    <p>
      {{ $t("views.auth.mfa.emails.new.lost_access_to_email") }}
      <be-link href="/auth/mfa/recovery_code/new">
        {{ $t("views.auth.mfa.emails.new.use_recovery_code") }}
      </be-link>
    </p>

    <!-- eslint-enable vue/no-v-html -->

    <form @submit.prevent="onSubmit">
      <be-code-input
        ref="codeInput"
        :error="error"
        size="lg"
        class="mb-3"
        justify
        @input="onCodeInput"
        @done="onSubmit"
      />

      <p>
        <request-email-login-code />
      </p>

      <div class="d-flex flex-column flex-md-row justify-content-end mt-4">
        <be-button :href="cancelUrl" variant="light" data-method="DELETE">
          {{ $t("buttons.titles.cancel") }}
        </be-button>

        <be-button type="submit" variant="primary" :loading="verifying">
          {{ $t("buttons.titles.verify") }}
        </be-button>
      </div>
    </form>
  </div>
</template>

<script>
import RequestEmailLoginCode from "@/components/authentication/RequestEmailLoginCode.vue";

export default {
  components: {
    RequestEmailLoginCode,
  },

  props: {
    userEmail: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      cancelUrl: this.url("/users/sign_out"),
      code: "",
      error: null,
      verifying: false,
    };
  },

  methods: {
    onCodeInput(code) {
      this.code = code;
    },

    async onSubmit(code) {
      if (code) {
        this.code = code;
      }

      this.verifying = true;

      try {
        const { data } = await axios.post("/auth/mfa/email", {
          one_time_password: this.code,
        });

        window.location.href = data.redirect_path;
      } catch (error) {
        this.error = error.response.data.error;
        this.$refs.codeInput.reset();
      } finally {
        this.verifying = false;
      }
    },
  },
};
</script>
