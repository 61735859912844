<template>
  <be-table
    :items="items"
    :fields="[
      {
        key: 'type',
        label: $t('components.annual_reports.digital_delivery_tab.check_type'),
        class: 'col-shrink',
      },
      {
        key: 'text',
        label: $t(
          'components.annual_reports.digital_delivery_tab.check_message'
        ),
      },
    ]"
  >
    <template #type="{ item }">
      <i
        v-if="item.type === 'info'"
        v-be-tooltip="$t('components.annual_reports.digital_delivery_tab.info')"
        class="fas fa-info-circle text-info"
      />

      <i
        v-if="item.type === 'warn'"
        v-be-tooltip="
          $t('components.annual_reports.digital_delivery_tab.warning')
        "
        class="fa-duotone fa-triangle-exclamation text-warning"
      />

      <i
        v-if="item.type === 'error'"
        v-be-tooltip="
          $t('components.annual_reports.digital_delivery_tab.error')
        "
        class="fas fa-hexagon-exclamation text-danger"
      />
    </template>
  </be-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
