<template>
  <div>
    <!-- Choose package -->
    <div class="card mb-2">
      <div class="card-body p-5">
        <!-- Introduction for organizations that can qet a quotation -->
        <quotation-introduction
          v-if="inQuotationState"
          class="mb-5"
          :packages="packages"
        />

        <template v-if="showPackages">
          <div class="row mb-md-5">
            <div class="col-12 col-md-7 mb-3">
              <h2>
                {{
                  $t(
                    "components.onboarding.feature_shop.choose_package_title_w_organization_type",
                    {
                      organization_type: $t(
                        `models.company.type_of_organizations.${organizationType}`
                      ),
                    }
                  )
                }}
              </h2>

              <p>
                {{
                  $t(
                    "components.onboarding.feature_shop.choose_package_description"
                  )
                }}
              </p>

              <h4>
                {{
                  $t("components.onboarding.feature_shop.account_renewal_title")
                }}
              </h4>

              <p>
                {{
                  $t(
                    "components.onboarding.feature_shop.price_variation_description"
                  )
                }}
              </p>
            </div>

            <div v-if="showTip" class="col-12 col-md-5 mb-3 mb-md-0">
              <info-card>
                <template #text>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <p v-html="formattedTipText" />
                </template>

                <template v-if="showTipButton" #action-button>
                  <i18n
                    tag="p"
                    path="components.onboarding.quotation.contact_description_w_link"
                  >
                    <template #link>
                      <be-link href="mailto:sales@boardeaser.com">
                        sales@boardeaser.com
                      </be-link>
                    </template>
                  </i18n>
                </template>
              </info-card>
            </div>
          </div>

          <div class="row mb-3 mb-md-5">
            <feature-package-container
              v-for="(featurePackage, index) in selectablePackages"
              :key="`featurePackage-${featurePackage.id}`"
              class="col-12 col-md-4"
              :discounted-price="
                monthlyPackagePrice(featurePackage.title, true, true)
              "
              :feature-package="featurePackage"
              :regular-price="monthlyPackagePrice(featurePackage.title, false)"
              :package-selected="selectedPackageType == featurePackage.title"
              :side-borders="featurePackage.title == 'large'"
              :show-popular-badge="featurePackage.title == 'large'"
              :last-package="index == selectablePackages.length - 1"
              @package-selected="selectPackage"
              @package-deselected="deselectPackage"
            >
              <template #package-description>
                <p class="mb-5">
                  {{
                    $t(
                      `components.onboarding.feature_packages.package_description.${featurePackage.title}`
                    )
                  }}
                </p>
              </template>
            </feature-package-container>
          </div>
        </template>
      </div>

      <div class="card-footer d-md-flex justify-content-between">
        <be-button
          variant="outline-primary"
          size="lg"
          @click="showPreviousSlide"
        >
          {{ $t("components.onboarding.navigation.previous") }}
        </be-button>

        <be-button variant="outline-primary" size="lg" @click="saveFeatures">
          {{ $t("components.onboarding.navigation.next") }}
        </be-button>
      </div>
    </div>

    <!-- Manage features -->
    <div class="card">
      <div class="card-body">
        <div class="row mb-3 mb-md-5">
          <div class="col-12 col-md-6">
            <h2>
              {{
                $t("components.onboarding.feature_shop.choose_features_title")
              }}
            </h2>

            <p>
              {{
                $t(
                  "components.onboarding.feature_shop.choose_features_description"
                )
              }}
            </p>

            <i18n
              tag="p"
              path="components.onboarding.feature_shop.read_more_about_products_w_link"
            >
              <template #link>
                <be-link
                  :href="
                    $i18n.locale === 'sv'
                      ? 'https://boardeaser.com'
                      : 'https://boardeaser.com/en'
                  "
                >
                  boardeaser.com
                </be-link>
              </template>
            </i18n>
          </div>
        </div>

        <div class="d-flex justify-content-md-end">
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li v-for="tabType in TAB_TYPES" :key="`tab-type-${tabType}`">
              <a
                :href="`#${tabType}`"
                :aria-controls="`${tabType}`"
                :class="{ active: tabType == 'all_features' }"
                class="nav-link pl-0"
                role="tab"
                data-toggle="tab"
              >
                {{ $t(`views.companies.shop.index.${tabType}`) }}
                <be-badge variant="light" pill>
                  {{ getFeaturesByTabType(tabType).length }}
                </be-badge>
              </a>
            </li>
          </ul>
        </div>

        <div class="tab-content">
          <div
            v-for="tabType in TAB_TYPES"
            :id="`${tabType}`"
            :key="`tab-type-content-${tabType}`"
            class="tab-pane"
            :class="{ active: tabType == 'all_features' }"
          >
            <feature-shop-tab-content
              :features="getFeaturesByTabType(tabType)"
              :selected-features="selectedFeatures"
              @feature-selected="selectFeature"
              @feature-deselected="deselectFeature"
            />

            <p class="font-italic text-muted">
              {{ $t("components.onboarding.feature_shop.excluding_vat") }}
            </p>
          </div>
        </div>
      </div>

      <div class="card-footer d-md-flex justify-content-between">
        <be-button
          variant="outline-primary"
          size="lg"
          @click="showPreviousSlide"
        >
          {{ $t("components.onboarding.navigation.previous") }}
        </be-button>

        <be-button variant="outline-primary" size="lg" @click="saveFeatures">
          {{ $t("components.onboarding.navigation.next") }}
        </be-button>

        <feature-price-variations-modal />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FeaturePackageContainer from "../FeaturePackageContainer.vue";
import FeatureShopTabContent from "../FeatureShopTabContent.vue";
import QuotationIntroduction from "../QuotationIntroduction.vue";
import InfoCard from "@/components/shared/InfoCard.vue";

const NUMBER_OF_MISSING_FEATURES_REQUIRIED_FOR_WARNING = 1;
const TAB_TYPES = ["all_features", "addons", "integrations"];

const CORPORATE_GROUP = "corporate_group";
const ACCOUNTING_FIRM = "accounting_firm";
const INVESTMENT_COMPANY = "investment_company";
const NONPROFIT = "nonprofit";
const HOUSING_ASSOCIATION = "housing_association";
const SMALL = "small";
const MEDIUM = "medium";
const LARGE = "large";

export default {
  components: {
    FeaturePackageContainer,
    FeatureShopTabContent,
    InfoCard,
    QuotationIntroduction,
  },

  props: {
    allFeatures: {
      type: Array,
      required: true,
    },

    basicPlan: {
      type: Object,
      required: true,
    },

    packages: {
      type: Array,
      required: true,
    },
  },

  emits: ["set-slide", "total-price-changed"],

  data() {
    return {
      features: this.findFeaturesByType("feature"),
      integrations: this.findFeaturesByType("integration"),
      selectedPackageType: null,
      selectedFeatures: [],
      TAB_TYPES: TAB_TYPES, // Needs to be done since we want to use this in the template
    };
  },

  computed: {
    ...mapGetters({
      organizationType: "company/getOrganizationType",
    }),

    // Check if feature is missing to complete a package
    featureMissingForCompletePackage() {
      const packageTypes = [];
      const availablePackageTypes = ["medium", "large"];

      availablePackageTypes.forEach((packageType) => {
        // These warnings should not be shown for organization types
        // used in inQuotationState
        if (this.inQuotationState) {
          return false;
        }

        const packageFeatures = this.findPackageFeatures(packageType);

        // Check how many of the package features has been selected
        const selectedFeatureIds = this.selectedFeatures.map(
          (selectedFeature) => selectedFeature.id
        );

        const matchingFeatures = packageFeatures.reduce(
          (featureMatches, feature) => {
            if (selectedFeatureIds.includes(feature.id)) {
              return (featureMatches += 1);
            }
            return featureMatches;
          },
          0
        );

        if (
          matchingFeatures ==
          packageFeatures.length -
            NUMBER_OF_MISSING_FEATURES_REQUIRIED_FOR_WARNING
        ) {
          packageTypes.push(packageType);
        }
      });

      return packageTypes;
    },

    formattedTipText() {
      if (!this.showTip) {
        return;
      }

      const wordsInArray = this.$i18n
        .t(`components.onboarding.tip.${this.organizationType}_tip`)
        .split(" ");
      return (
        wordsInArray[0].bold() +
        " " +
        wordsInArray.slice(1, wordsInArray.length).join(" ")
      );
    },

    selectablePackages() {
      const smallPackage = this.packages.find(
        (featurePackage) => featurePackage.title == SMALL
      );
      const mediumPackage = this.packages.find(
        (featurePackage) => featurePackage.title == MEDIUM
      );
      const largePackage = this.packages.find(
        (featurePackage) => featurePackage.title == LARGE
      );

      return [smallPackage, largePackage, mediumPackage];
    },

    showPackages() {
      return !this.inQuotationState;
    },

    showTip() {
      return [NONPROFIT, INVESTMENT_COMPANY, HOUSING_ASSOCIATION].includes(
        this.organizationType
      );
    },

    showTipButton() {
      return [INVESTMENT_COMPANY].includes(this.organizationType);
    },

    totalPrice() {
      const packageFeatures = this.findPackageFeatures(
        this.selectedPackageType
      );

      // Locate any extra features in addition to those already added by the package
      const extraFeatures = this.selectedFeatures.filter((feature) => {
        return !packageFeatures
          .map((packageFeature) => packageFeature.id)
          .includes(feature.id);
      });

      // Calculate price for extra features
      let monthlyPriceForExtraFeatures = 0;

      const useDiscount = [NONPROFIT, HOUSING_ASSOCIATION].includes(
        this.organizationType
      );

      for (const feature of extraFeatures) {
        monthlyPriceForExtraFeatures += this.montlyFeaturePrice(
          feature,
          this.selectedPackageType,
          useDiscount
        );
      }

      const monthlyPackagePrice = this.monthlyPackagePrice(
        this.selectedPackageType,
        true,
        true
      );

      this.$emit(
        "total-price-changed",
        monthlyPackagePrice + monthlyPriceForExtraFeatures
      );

      return monthlyPackagePrice + monthlyPriceForExtraFeatures;
    },

    priceOutputAfterTrial() {
      if (this.inQuotationState) {
        return this.$i18n.t(
          "components.onboarding.quotation.price_after_trial_by_quotation"
        );
      } else {
        return this.$i18n.t(
          "components.onboarding.quotation.price_after_trial",
          { price: Math.floor(this.totalPrice) }
        );
      }
    },

    inQuotationState() {
      return [CORPORATE_GROUP, ACCOUNTING_FIRM].includes(this.organizationType);
    },
  },

  watch: {
    organizationType() {
      this.setPackageTypeByQuotationState();
    },
  },

  mounted() {
    this.setPackageTypeByQuotationState();
  },

  methods: {
    async saveFeatures() {
      // First save selected features then go to the next step

      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t(
          "components.onboarding.confirm_selected_features_ok_title"
        ),

        title: this.$t("components.onboarding.confirm_selected_features_title"),

        description: this.$t(
          "components.onboarding.confirm_selected_features_description"
        ),
      });

      if (isConfirmed) {
        const companyFeatureSelection = {
          company: { features: this.selectedFeatures },
        };

        try {
          const response = await axios.post(
            this.url("/onboarding"),
            companyFeatureSelection
          );

          if (response.status == 200) {
            this.showNextSlide();
          }
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    showNextSlide() {
      this.$emit("set-slide", "complete-onboarding");
    },

    showPreviousSlide() {
      this.$emit("set-slide", "introduction");
    },

    setPackageType(packageType) {
      this.selectedPackageType = packageType;
    },

    selectFeature(feature) {
      this.selectedFeatures.push(feature);
      this.assignPackageTypeBasedOnSelectedFeatures();
    },

    deselectFeature(deselectedFeature) {
      // Remove feature from selected features if feature was deselected manually
      const idx = this.selectedFeatures.findIndex((selectedFeature) => {
        return selectedFeature.id == deselectedFeature.id;
      });

      if (idx > -1) {
        this.selectedFeatures.splice(idx, 1);
        this.assignPackageTypeBasedOnSelectedFeatures();
      }
    },

    selectPackage(featurePackage) {
      // Sets from_package flag to true for features that came from a
      // package and wasn't already selected manually
      featurePackage.features.forEach((feature) => {
        feature.from_package = true;
      });

      const extraFeatures = this.selectedFeatures.filter(
        (selectedFeature) => !selectedFeature.from_package
      );
      const manuallyAddedFeatureNames = extraFeatures.map(
        (feature) => feature.name
      );

      // Only add features from the package that wasn't already selected manually
      const featuresAddedFromPackage = featurePackage.features.filter(
        (feature) => {
          return !manuallyAddedFeatureNames.includes(feature.name);
        }
      );

      this.selectedFeatures = featuresAddedFromPackage.concat(extraFeatures);

      this.setPackageType(featurePackage.title);
    },

    deselectPackage() {
      this.setPackageType(null);

      // Remove all features from selectedFeatures except
      // the ones that were manually selected
      const extraFeatures = this.selectedFeatures.filter(
        (selectedFeature) => !selectedFeature.from_package
      );

      // Empty the array of seleted features before we rebuild it with
      // manually added features
      this.selectedFeatures = [];
      this.selectedFeatures = this.selectedFeatures.concat(extraFeatures);
    },

    setPackageTypeByQuotationState() {
      let packageType = "large";

      if (this.inQuotationState) {
        packageType = "quotation";
      }

      this.selectedPackageType = packageType;
      this.selectPackage(this.findFeaturePackage(packageType));
    },

    allPackageFeaturesSelected(packageType) {
      const packageFeatureIds = this.findPackageFeatures(packageType).map(
        (feature) => feature.id
      );
      const selectedFeatureIds = this.selectedFeatures.map(
        (selectedFeature) => selectedFeature.id
      );

      return packageFeatureIds.every((packageFeatureId) =>
        selectedFeatureIds.includes(packageFeatureId)
      );
    },

    assignPackageTypeBasedOnSelectedFeatures() {
      this.setPackageType(null);

      // Needs to be in this order, check from smallest to largest package
      const packagesSortedByNumberOfFeatures = this.selectablePackages.sort(
        (a, b) => a.features.length < b.features.length
      );

      packagesSortedByNumberOfFeatures.forEach((featurePackage) => {
        if (this.allPackageFeaturesSelected(featurePackage.title)) {
          this.setPackageType(featurePackage.title);
        }
      });
    },

    findFeaturePackage(packageType) {
      return this.packages.find(
        (featurePackage) => featurePackage.title == packageType
      );
    },

    findPackageFeatures(packageType) {
      return this.findFeaturePackage(packageType)?.features || [];
    },

    findFeaturesByType(featureType) {
      return this.allFeatures.filter(
        (feature) => feature.feature_type == featureType
      );
    },

    findDiscountByOrganizationType(discounts, organizationType) {
      return discounts.find((discount) => discount.kind === organizationType)
        ?.value;
    },

    getFeaturesByTabType(tabType) {
      if (tabType == "integrations") {
        return this.integrations;
      } else if (tabType == "addons") {
        return this.features;
      }

      return this.allFeatures;
    },

    discountPercentage(packageType, discounts) {
      if (this.organizationType == NONPROFIT) {
        return this.findDiscountByOrganizationType(discounts, NONPROFIT);
      } else if (this.organizationType == HOUSING_ASSOCIATION) {
        return this.findDiscountByOrganizationType(
          discounts,
          HOUSING_ASSOCIATION
        );
      } else {
        return this.findFeaturePackage(packageType)?.discount || 0;
      }
    },

    monthlyBasePrice(packageType, useDiscount = false) {
      let price = 0;

      if (useDiscount) {
        const discount = this.discountPercentage(
          packageType,
          this.basicPlan.price.discounts
        );

        price = (this.basicPlan.price.value / 100) * (100 - discount);
      } else {
        price = this.basicPlan.price.value;
      }

      return Math.floor(price / 12);
    },

    monthlyPackagePrice(packageType, useDiscount = false, ceilTotal = false) {
      const total =
        this.monthlyBasePrice(packageType, useDiscount) +
        this.monthlyPackageFeaturesPrice(packageType, useDiscount);

      if (
        !ceilTotal ||
        packageType == SMALL ||
        [NONPROFIT, HOUSING_ASSOCIATION].includes(this.organizationType)
      ) {
        return total;
      }

      // Ceils the total value to the closes 100 and removes 1
      return Math.ceil(total / 100) * 100 - 1;
    },

    monthlyPackageFeaturesPrice(packageType, useDiscount = false) {
      const packageFeatures = this.findPackageFeatures(packageType);

      return packageFeatures.reduce((sum, feature) => {
        return sum + this.montlyFeaturePrice(feature, packageType, useDiscount);
      }, 0);
    },

    montlyFeaturePrice(feature, packageType, useDiscount = true) {
      if (useDiscount) {
        const discount = this.discountPercentage(
          packageType,
          feature.price.discounts
        );

        return Math.floor(
          ((feature.price.value / 100) * (100 - discount)) / 12
        );
      }

      return Math.floor(feature.price.value / 12);
    },
  },
};
</script>
