<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 col-md-4">
        <be-alert variant="info">
          {{ $t("components.contracts.automatic_renewal.description") }}
        </be-alert>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <be-form-group
          :error="getErrors(contract, terminationStartErrorKeys)"
          label-for="termination-period-start"
          :label="
            $t('activerecord.attributes.contract.termination_period_start')
          "
        >
          <be-form-datepicker
            id="termination-period-start"
            v-model="terminationPeriodStart"
            name="contract[contract_type_attributes][termination_period_start]"
            :disabled="contract.editing_disabled"
            :state="validationState(contract, terminationStartErrorKeys)"
            @input="clearErrors(contract, terminationStartErrorKeys)"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-4">
        <be-form-group
          :error="getErrors(contract, terminationEndErrorKeys)"
          label-for="termination-period-end"
          :label="$t('activerecord.attributes.contract.termination_period_end')"
        >
          <be-form-datepicker
            id="termination-period-end"
            v-model="terminationPeriodEnd"
            name="contract[contract_type_attributes][termination_period_end]"
            :disabled="contract.editing_disabled"
            :state="validationState(contract, terminationEndErrorKeys)"
            @input="clearErrors(contract, terminationEndErrorKeys)"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-md-4">
        <contract-termination-date :contract="contract" />
      </div>
    </div>

    <div v-if="terminationPeriodEnd" class="row">
      <div class="col-12 col-md-4">
        <reminder-period
          :contract="contract"
          :reminders="reminders"
          label="views.companies.contracts.metadata.notify_before_last_termination_day"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContractTerminationDate from "./dates/ContractTerminationDate.vue";
import ReminderPeriod from "./ReminderPeriod.vue";

export default {
  components: {
    ContractTerminationDate,
    ReminderPeriod,
  },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    reminders: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      terminationPeriodStart:
        this.contract.contract_type.termination_period_start || null,

      terminationPeriodEnd:
        this.contract.contract_type.termination_period_end || null,
    };
  },

  computed: {
    terminationEndErrorKeys() {
      return [
        "termination_period_end_or_start_invalid",
        "termination_period_end_bigger_than_end_date",
        "termination_period_end_smaller_than_start_date",
      ];
    },

    terminationStartErrorKeys() {
      return [
        "termination_period_end_or_start_invalid",
        "termination_period_start_bigger_than_end",
        "termination_period_start_smaller_than_start_date",
        "termination_period_start_bigger_than_end_date",
      ];
    },
  },
};
</script>
