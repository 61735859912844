<template>
  <div v-if="!transaction.destroyed" class="col-12 mb-3 bg-light p-3 py-4">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <be-form-group>
        <be-form-radio-group
          id="buyer_type"
          v-model="shareholderBuyerType"
          :options="shareholderBuyerTypeOptions"
          :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][shareholder_buyer][shareholder_buyer_type]`"
        />
      </be-form-group>

      <be-button
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeNewShareholder"
      />
    </div>

    <div class="row">
      <div class="col-12 col-lg-6">
        <user-buyer-fields
          v-if="shareholderBuyerType == 'user'"
          :index="index"
          :name-prefix="`shareholder_register_action[shareholder_transactions_attributes][${index}]`"
          :shareholder="transaction.buyer || undefined"
          @shareholder-changed="updateTransactionBuyer"
        />

        <company-buyer-fields
          v-if="shareholderBuyerType == 'company'"
          :index="index"
          :name-prefix="`shareholder_register_action[shareholder_transactions_attributes][${index}]`"
          :shareholder="transaction.buyer || undefined"
          @shareholder-changed="updateTransactionBuyer"
        />
      </div>

      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-6">
            <be-form-group
              :label="
                $t(
                  'activerecord.attributes.shareholder_register_action.number_of_shares'
                )
              "
              :label-for="`transaction_number_of_shares_${index}`"
              :error="getErrors(transaction, ['number_of_shares'])"
            >
              <be-form-input
                :id="`transaction_number_of_shares_${index}`"
                v-model="transaction.number_of_shares"
                type="number"
                min="1"
                step="1"
                :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][number_of_shares]`"
                required
                @change="clearErrors(transaction, ['number_of_shares'])"
              />
            </be-form-group>

            <be-form-group
              v-if="action.action_type == 'company_formation'"
              :label="
                $t('activerecord.attributes.shareholder_transaction.share_type')
              "
              :label-for="`share_type_${index}`"
              required
            >
              <be-form-select
                :id="`share_type_${index}`"
                v-model="shareType"
                :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_type]`"
                :options="formattedShareTypes"
                required
              />
            </be-form-group>

            <input
              v-else
              type="hidden"
              :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_type]`"
              :value="shareType"
            />
          </div>

          <div class="col-6">
            <be-form-group
              :label="$t('models.shareholder_register_action.total_price')"
            >
              <be-form-input :model-value="totalPrice" readonly />
            </be-form-group>
          </div>
        </div>
      </div>

      <input
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_price]`"
        :value="sharePrice"
      />

      <input
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][action_type]`"
        :value="action.action_type"
      />

      <input
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][_destroy]`"
        :value="transaction.destroyed"
      />
    </div>
  </div>
</template>

<script>
import UserBuyerFields from "./UserBuyerFields.vue";
import CompanyBuyerFields from "./CompanyBuyerFields.vue";
import shareholderRegisterMixins from "@/mixins/shareholderRegisters";

export default {
  components: {
    UserBuyerFields,
    CompanyBuyerFields,
  },

  mixins: [shareholderRegisterMixins],

  props: {
    action: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    initialTransaction: {
      type: Object,
      required: true,
    },

    sharePrice: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["transaction-changed", "new-shareholder-removed"],

  data() {
    return {
      shareholderBuyerType: "user",

      shareholderBuyerTypeOptions: [
        { value: "user", text: this.$t("models.shareholder.person") },
        { value: "company", text: this.$t("models.shareholder.legal_person") },
      ],

      shareType:
        this.initialTransaction.share_type ||
        this.action.share_type ||
        "a_shares",

      transaction: this.cloneDeep(this.initialTransaction),
    };
  },

  computed: {
    totalPrice() {
      if (this.transaction.number_of_shares && this.sharePrice) {
        return BigNumber(this.transaction.number_of_shares).multipliedBy(
          BigNumber(this.sharePrice)
        );
      }

      return null;
    },
  },

  watch: {
    transaction: {
      handler() {
        this.$emit("transaction-changed", this.transaction);
      },

      deep: true,
    },
  },

  mounted() {
    this.setBuyerType();
  },

  methods: {
    updateTransactionBuyer(value) {
      this.transaction.buyer = value;
      this.$emit("transaction-changed", this.transaction);
    },

    removeNewShareholder() {
      this.$emit("new-shareholder-removed", this.transaction);
    },

    setBuyerType() {
      if (
        this.initialTransaction.buyer &&
        this.initialTransaction.buyer.shareholder_buyer_type
      ) {
        this.shareholderBuyerType =
          this.initialTransaction.buyer.shareholder_buyer_type;
      }
    },
  },
};
</script>
