<template>
  <div>
    <div v-if="customFields && customFields.length > 0">
      <header>
        <h1>{{ $t("application.custom_fields") }}</h1>
      </header>

      <div class="card mb-2">
        <div class="card-header d-md-flex justify-content-end">
          <be-button v-be-modal.add-custom-fields variant="outline-primary">
            {{ $t("models.custom_field.new") }}
          </be-button>

          <add-custom-field-modal
            :object="object"
            :object-type="objectType"
            @custom-field-added="addCustomField"
          />
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table class="table data-table table-hover">
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("components.custom_fields.title") }}
                  </th>

                  <th class="col-shrink"></th>

                  <th class="d-none d-md-table-cell col-shrink">
                    {{ $t("components.custom_fields.field_type") }}
                  </th>

                  <th class="d-none d-md-table-cell col-shrink">
                    {{ $t("components.custom_fields.marked_as_default") }}
                  </th>

                  <th class="d-none d-md-table-cell col-shrink">
                    {{ $t("components.custom_fields.default_value") }}
                  </th>

                  <th class="d-none d-md-table-cell col-shrink"></th>
                </tr>
              </thead>

              <tbody>
                <custom-field-row
                  v-for="customField in customFields"
                  :key="customField.id"
                  :initial-custom-field="customField"
                  @custom-field-removed="removeCustomField"
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <custom-fields-introduction v-else @custom-field-added="addCustomField" />
  </div>
</template>

<script>
import AddCustomFieldModal from "../AddCustomFieldModal.vue";
import CustomFieldRow from "./CustomFieldRow.vue";
import CustomFieldsIntroduction from "@/components/contracts/CustomFieldsIntroduction.vue";

export default {
  components: {
    AddCustomFieldModal,
    CustomFieldRow,
    CustomFieldsIntroduction,
  },

  props: {
    initialCustomFields: {
      type: Array,
      required: true,
    },

    object: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    objectType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      customFields: this.cloneDeep(this.initialCustomFields),
    };
  },

  methods: {
    addCustomField(addedField) {
      this.customFields.push(addedField);
    },

    removeCustomField(removedField) {
      let idx = this.customFields.findIndex(
        (existingField) => existingField.id == removedField.id
      );
      this.customFields.splice(idx, 1);
    },
  },
};
</script>
