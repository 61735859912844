<template>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <h3>
          {{ $t("components.template_packages.nav.tabs.public_archive") }}
        </h3>

        <div class="row">
          <div class="col-12 col-md-9 mb-3 mb-md-0">
            {{
              $t("components.template_packages.public_templates.description")
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header border-bottom-0 rounded py-lg-0">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              href="#public-templates"
              role="tab"
              data-toggle="tab"
              aria-controls="public-templates"
            >
              {{ $t("components.template_packages.nav.tabs.public_templates") }}

              <be-badge variant="light" pill>
                {{ publicTemplates.length }}
              </be-badge>
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              href="#private-templates"
              role="tab"
              data-toggle="tab"
              aria-controls="private-templates"
            >
              {{
                $t("components.template_packages.nav.tabs.private_templates")
              }}

              <be-badge variant="light" pill>
                {{ privateTemplates.length }}
              </be-badge>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="tab-content">
          <div id="public-templates" class="tab-pane active">
            <templates-table :templates="publicTemplates" />
          </div>

          <div id="private-templates" class="tab-pane">
            <templates-table :templates="privateTemplates" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TemplatesTable from "./TemplatesTable.vue";

export default {
  components: { TemplatesTable },

  computed: {
    ...mapGetters({
      publicTemplates: "template_packages/publicTemplates",
      privateTemplates: "template_packages/privateTemplates",
    }),
  },
};
</script>
