<template>
  <be-table :items="annualReports" :fields="fields" sort-by="year" sort-desc>
    <template #company="{ item }">
      <be-link :href="url(`/companies/${item.company.nanoid}`)">
        {{ item.company.title }}
      </be-link>

      <template v-if="item.company.organization_number">
        <br />

        <div class="small">{{ item.company.organization_number }}</div>
      </template>
    </template>

    <template #annual_report_document="{ item }">
      <template v-if="item.annual_report_state === 'completely_signed'">
        <template v-if="item.annual_report_document.signed_at">
          <div
            v-be-tooltip="
              $d(new Date(item.annual_report_document.signed_at), 'date_time')
            "
          >
            <span class="text-success mr-1">
              <i class="fa fa-check-circle" />
            </span>

            {{ $t("models.annual_report.statuses.signed") }}
          </div>
        </template>

        <template v-else>
          <div v-be-tooltip="$t('components.annual_reports.manually_signed')">
            <span class="text-success mr-1">
              <i class="fa fa-check-circle" />
            </span>

            {{ $t("models.annual_report.statuses.signed") }}
          </div>
        </template>
      </template>

      <template v-else>
        <span class="mr-1">
          <i class="fal fa-light fa-exclamation-circle" />
        </span>

        {{ $t("models.annual_report.statuses.pending") }}
      </template>
    </template>

    <template #board_statement_document="{ item }">
      <template v-if="hasStateTransition(item, 'board_statement', 'upload')">
        <template v-if="item.board_statement_state === 'completed'">
          <template v-if="item.board_statement_document.signed_at">
            <div
              v-be-tooltip="
                $d(
                  new Date(item.board_statement_document.signed_at),
                  'date_time'
                )
              "
            >
              <span class="text-success mr-1">
                <i class="fa fa-check-circle" />
              </span>

              {{ $t("models.annual_report.statuses.signed") }}
            </div>
          </template>

          <template v-else>
            <div v-be-tooltip="$t('components.annual_reports.manually_signed')">
              <span class="text-success mr-1">
                <i class="fa fa-check-circle" />
              </span>

              {{ $t("models.annual_report.statuses.signed") }}
            </div>
          </template>
        </template>

        <template v-else>
          <span class="mr-1">
            <i class="fal fa-light fa-exclamation-circle" />
          </span>

          {{ $t("models.annual_report.statuses.pending") }}
        </template>
      </template>
    </template>

    <template #audit_report_document="{ item }">
      <template v-if="hasStateTransition(item, 'audit_report', 'upload')">
        <template v-if="item.audit_report_state === 'signed'">
          <template v-if="item.audit_report_document.signed_at">
            <div
              v-be-tooltip="
                $d(new Date(item.audit_report_document.signed_at), 'date_time')
              "
            >
              <span class="text-success mr-1">
                <i class="fa fa-check-circle" />
              </span>

              {{ $t("models.annual_report.statuses.signed") }}
            </div>
          </template>

          <template v-else>
            <div v-be-tooltip="$t('components.annual_reports.manually_signed')">
              <span class="text-success mr-1">
                <i class="fa fa-check-circle" />
              </span>

              {{ $t("models.annual_report.statuses.signed") }}
            </div>
          </template>
        </template>

        <template v-else>
          <span class="mr-1">
            <i class="fal fa-light fa-exclamation-circle" />
          </span>

          {{ $t("models.annual_report.statuses.pending") }}
        </template>
      </template>
    </template>

    <template #submitted="{ item }">
      <template v-if="hasStateTransition(item, 'phase', 'complete_delivery')">
        <div
          v-be-tooltip="
            $d(
              new Date(
                hasStateTransition(
                  item,
                  'phase',
                  'complete_delivery'
                ).created_at
              ),
              'dateTime'
            )
          "
        >
          <span class="text-success mr-1">
            <i class="fa fa-check-circle" />
          </span>

          {{ $t("models.annual_report.statuses.sent_and_archived") }}
        </div>
      </template>

      <template
        v-else-if="hasStateTransition(item, 'phase', 'complete_annual_meeting')"
      >
        <span class="mr-1">
          <i class="fal fa-light fa-exclamation-circle" />
        </span>

        {{ $t("models.annual_report.statuses.ready_to_submit") }}
      </template>
    </template>

    <template #phase="{ item }">
      {{ $t(`models.annual_report.phases.${item.phase}`) }}
    </template>

    <template #actions="{ item }">
      <be-button
        :href="item.annual_report_path"
        variant="outline-secondary"
        size="sm"
        inline
      >
        {{ $t("buttons.titles.show") }}
      </be-button>
    </template>
  </be-table>
</template>

<script>
export default {
  props: {
    annualReports: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          key: "year",
          label: this.$t("activerecord.attributes.annual_report.year"),
          sortable: true,
          class: "col-shrink align-middle",
        },
        {
          key: "company",
          label: this.$t("activerecord.attributes.company.title"),
          sortable: true,
          class: "align-middle",
        },
        {
          key: "annual_report_document",
          label: this.$t("models.annual_report.documents.annual_report"),
          class: "col-shrink align-middle",
        },
        ...(this.includesFoundationCompany(this.annualReports)
          ? [
              {
                key: "board_statement_document",

                label: this.$t(
                  "models.annual_report.documents.board_statement"
                ),

                class: "col-shrink align-middle",
              },
            ]
          : []),
        {
          key: "audit_report_document",
          label: this.$t("models.annual_report.documents.audit_report"),
          class: "col-shrink align-middle",
        },
        {
          key: "submitted",

          label: this.$t(
            "views.admin_panels.dashboard.annual_reports.index.archive_status"
          ),

          class: "col-shrink align-middle",
        },
        {
          key: "phase",
          label: this.$t("activerecord.attributes.annual_report.phase"),
          class: "col-shrink align-middle",
        },
        { key: "actions", label: "", class: "col-shrink align-middle" },
      ],
    };
  },

  methods: {
    hasStateTransition(item, namespace, event) {
      return item.state_transitions.find(
        (transition) =>
          transition.namespace === namespace && transition.event === event
      );
    },

    includesFoundationCompany(annualReports) {
      return annualReports.some(
        (annualReport) =>
          annualReport.company.type_of_organization === "foundation"
      );
    },
  },
};
</script>
