<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      retries: 0,
      initialized: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "current_user/getUser",
      company: "company/getCompany",
    }),
  },

  mounted() {
    // Needs the Userback-component to be initialized
    this.$nextTick(() => {
      this.setUserInformation();
    });
  },

  methods: {
    setUserInformation() {
      if (this.retries >= 5) {
        // Stop retrying after 5 attempts
        window.Sentry?.captureMessage(
          "Userback initialization failed after 5 retries."
        );
        return;
      }

      try {
        // Sometimes $userback is not initialized yet, so catch and retry 5 times
        this.$userback.identify(this.user.id, {
          name: this.user.name,
          email: this.user.email,
          user_financials_uuid: this.user.financials_uuid,
        });

        this.$userback.setData({
          company: this.company?.nanoid,
          company_financials_uuid: this.company?.financials_uuid,
          company_corporate_group_uuid: this.company?.corporate_group_uuid,
        });

        this.$userback.show();
        this.initialized = true;
      } catch (error) {
        this.retries += 1;
        setTimeout(this.setUserInformation, 1000);
      }
    },
  },

  render() {
    // No template is needed since the component doesn't render anything
    return null;
  },
};
</script>
