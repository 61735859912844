<template>
  <div class="card-body">
    <label> {{ $t("activerecord.models.meeting.other") }} </label>

    <be-table
      v-if="minutesReferences.length > 0"
      :items="minutesReferences"
      :fields="minutesReferencesFields"
    >
      <template #start_at="{ item }">
        <template v-if="item.minutes.meeting_start_at">
          {{ $d(new Date(item.minutes.meeting_start_at), "short") }}
        </template>
      </template>

      <template #title="{ item }">
        <a :href="url(`/meetings/${item.minutes.meeting_id}/minutes`)">
          {{ `#${item.minutes.meeting_number} - ${item.minutes.title}` }}
        </a>
      </template>

      <template #status="{ item }">
        {{ minutesStatus(item.minutes) }}
      </template>

      <template #options="{ item }">
        <be-button
          v-be-tooltip="{
            title: $t('buttons.titles.remove'),
          }"
          variant="danger"
          size="sm"
          class="ml-2"
          :loading="loading.includes(item.reference_id)"
          icon="fa-times"
          inline
          @click="removeMinutes(item.reference_id)"
        />
      </template>
    </be-table>

    <be-alert v-else variant="info" class="mt-2">
      {{ $t("components.meetings.material.item_meetings.no_minutes") }}
    </be-alert>

    <div class="mt-2">
      <be-button v-be-modal="`add-minutes-${itemId}`" variant="primary">
        {{ $t("components.meetings.material.item_meetings.add_minutes") }}
      </be-button>
    </div>

    <be-modal
      :id="`add-minutes-${itemId}`"
      :title="$t('components.meetings.material.item_meetings.add_minutes')"
      :ok-title="$t('buttons.titles.close')"
      ok-only
      ok-variant="light"
      size="lg"
      @show="searchMinutes(true)"
    >
      <div class="d-flex flex-wrap align-items-start justify-content-between">
        <div class="mb-3">
          <label for="minutes-search">
            {{ $t("components.shared.be_table.search") }}
            <i
              v-be-tooltip="
                $t(
                  'components.meetings.material.follow_up_meetings.search_by_tooltip'
                )
              "
              class="fal fa-question-circle ml-1"
            />
          </label>

          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="bg-transparent pr-0">
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              id="minutes-search"
              v-model="searchQuery"
              type="search"
              class="border-left-0"
              :placeholder="$t('components.shared.be_table.type_to_search')"
            />
          </be-input-group>
        </div>
      </div>

      <paginated-table
        :items="minutes"
        :fields="minutesFields"
        :pagination="pagination"
        :sorting="{ sortBy, sortDirection }"
        :loading="searchLoading"
        @page-changed="paginationChanged"
        @sorting-changed="sortingChanged"
      >
        <template v-if="searchQuery" #empty>
          <be-alert variant="info">
            {{ $t("components.shared.be_table.no_results") }}
          </be-alert>
        </template>

        <template #start_at="{ item }">
          {{ $d(new Date(item.meeting.start_at), "short") }}
        </template>

        <template #number="{ item }">
          {{ `#${item.meeting.number}` }}
        </template>

        <template #title="{ item }">
          <a :href="url(`/meetings/${item.meeting_id}/minutes`)">
            {{ item.title }}
          </a>
        </template>

        <template #status="{ item }">
          {{ minutesStatus(item) }}
        </template>

        <template #options="{ item }">
          <be-button
            v-show="itemReferenceFor(item.id)"
            v-be-tooltip="{
              title: $t('buttons.titles.remove'),
            }"
            :loading="loading.includes(item.id)"
            variant="danger"
            size="sm"
            icon="fa-times"
            inline
            @click="removeMinutes(item.id)"
          />

          <be-button
            v-show="!itemReferenceFor(item.id)"
            :loading="loading.includes(item.id)"
            size="sm"
            @click="addMinutes(item)"
          >
            {{ $t("buttons.titles.add_more") }}
          </be-button>
        </template>
      </paginated-table>
    </be-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import debounce from "lodash/debounce";

import PaginatedTable from "@/components/shared/PaginatedTable.vue";

const materialHelpers = createNamespacedHelpers("material");

export default {
  components: {
    PaginatedTable,
  },

  props: {
    itemId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      searchQuery: "",
      searchLoading: true,
      sortBy: "start_at",
      sortDirection: "desc",
      pagination: this.parsePagination({}),
      minutes: [],
      loading: [],
    };
  },

  computed: {
    ...materialHelpers.mapGetters(["followUpReferences", "meeting"]),

    minutesFields() {
      return [
        {
          key: "start_at",
          label: this.$t("activerecord.attributes.meeting.date"),
          sortable: true,
          class: "col-shrink",
          sortByFormatted: true,

          formatter: (value, key, item) => {
            return new Date(item.meeting.start_at);
          },
        },
        {
          key: "number",
          sortable: true,
          class: "col-shrink",

          label: this.$t(
            "components.meetings.material.follow_up_meetings.number"
          ),
        },
        {
          key: "title",
          label: this.$t("activerecord.attributes.meeting.title"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("activerecord.attributes.meeting.status"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "options",
          label: "",
          sortable: false,
          class: "col-shrink text-center",
        },
      ];
    },

    minutesReferencesFields() {
      return [
        {
          key: "start_at",
          label: this.$t("activerecord.attributes.meeting.date"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "title",
          label: this.$t("activerecord.models.meeting.one"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("activerecord.attributes.meeting.status"),
          sortable: false,
          class: "col-shrink",
        },
        {
          key: "options",
          label: "",
          sortable: false,
          class: "col-shrink text-center",
        },
      ];
    },

    minutesReferences() {
      return this.followUpReferences.filter(
        (reference) =>
          reference.item_id === this.itemId &&
          reference.reference_type === "Material"
      );
    },
  },

  watch: {
    searchQuery: debounce(function () {
      this.searchMinutes(true);
    }, 500),
  },

  methods: {
    ...materialHelpers.mapActions([
      "addFollowUpReference",
      "removeFollowUpReference",
    ]),

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.searchMinutes();
    },

    sortingChanged({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.searchMinutes();
    },

    async searchMinutes(resetPage) {
      const page = resetPage ? 1 : this.pagination.currentPage;
      try {
        const { data, headers } = await axios.post(
          this.url("/minutes/search"),
          {
            page: page,
            exclude_meeting_ids: [this.meeting.id],

            search: {
              query: this.searchQuery,
              sort_by: this.sortBy,
              sort_direction: this.sortDirection,
            },
          }
        );

        this.pagination = this.parsePagination(headers);
        this.minutes = data;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.searchLoading = false;
      }
    },

    parsePagination(headers) {
      return {
        currentPage: parseInt(headers["current-page"]) || 1,
        pageItems: parseInt(headers["page-items"]) || 20,
        totalCount: parseInt(headers["total-count"]) || 0,
        totalPages: parseInt(headers["total-pages"]) || 0,
      };
    },

    itemReferenceFor(minutes_id) {
      return this.minutesReferences.find((reference) => {
        return (
          reference.reference_type == "Material" &&
          reference.reference_id == minutes_id
        );
      });
    },

    minutesStatus(minutes) {
      if (minutes.signed_at) {
        return this.$t(
          "components.meetings.material.item_meetings.status.signed"
        );
      } else if (minutes.approved_at) {
        return this.$t(
          "components.meetings.material.item_meetings.status.approved"
        );
      } else if (minutes.sealed) {
        return this.$t(
          "components.meetings.material.item_meetings.status.sealed"
        );
      } else {
        return this.$t(
          "components.meetings.material.item_meetings.status.not_sealed"
        );
      }
    },

    async addMinutes(minutes) {
      this.loading.push(minutes.id);
      await this.addFollowUpReference({
        itemId: this.itemId,
        reference_type: "Material",
        reference_id: minutes.id,
      });
      this.loading = this.loading.filter((id) => id != minutes.id);
    },

    async removeMinutes(minutes_id) {
      this.loading.push(minutes_id);
      const reference = this.itemReferenceFor(minutes_id);
      await this.removeFollowUpReference(reference);
      this.loading = this.loading.filter((id) => id != minutes_id);
    },
  },
};
</script>
