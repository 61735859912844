<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <h3>
          {{
            $t("components.annual_reports.annual_meeting_tab.information_title")
          }}
        </h3>

        <be-alert
          v-if="can('mark_invitations_as_sent', 'annual_meeting', 'update')"
          variant="info"
        >
          <strong>
            {{
              $t(
                "components.annual_reports.annual_meeting_tab.information_notice_title"
              )
            }}
          </strong>

          <br />

          {{
            $t(
              "components.annual_reports.annual_meeting_tab.information_notice"
            )
          }}
        </be-alert>

        <div
          v-if="can('mark_invitations_as_sent', 'annual_meeting')"
          v-text="
            $t(
              'components.annual_reports.annual_meeting_tab.document_preparations'
            )
          "
        />

        <table
          v-for="preparationDocument in preparationDocuments"
          :key="`preparation-document-${preparationDocument.id}`"
          class="table table-hover"
        >
          <thead>
            <tr>
              <th colspan="2">
                {{
                  $t(
                    `models.annual_report.documents.${preparationDocument.key}`
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="2">
                <document-link
                  :document-id="preparationDocument.id"
                  :filename="filenameWithSize(preparationDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <be-alert v-if="showInvitationStatus" variant="success">
          {{
            $t(
              "components.annual_reports.notices.events.mark_invitations_as_sent_annual_meeting"
            )
          }}
          <div>
            <small>
              {{ invitationStatusInfo }}
            </small>
          </div>
        </be-alert>

        <div class="d-flex justify-content-end">
          <be-button
            v-if="can('mark_invitations_as_sent', 'annual_meeting', 'update')"
            variant="primary"
            @click="annualMeetingEvent('mark_invitations_as_sent')"
          >
            {{
              $t(
                "components.annual_reports.annual_meeting_tab.mark_invitations_as_sent_annual_meeting"
              )
            }}
          </be-button>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <h3>
          {{ $t("models.annual_report.documents.annual_meeting_minutes") }}
        </h3>

        <be-alert
          v-if="can('mark_invitations_as_sent', 'annual_meeting')"
          variant="warning"
        >
          {{
            $t(
              "components.annual_reports.annual_meeting_tab.invitations_not_marked_as_sent"
            )
          }}
        </be-alert>

        <be-alert v-if="can('upload_minutes', 'annual_meeting')" variant="info">
          {{
            $t(
              "components.annual_reports.annual_meeting_tab.annual_meeting_minutes_information"
            )
          }}
        </be-alert>

        <table
          v-for="minutesDocument in meetingMinutesDocuments"
          :key="`minutes-document-${minutesDocument.id}`"
          class="table table-hover"
        >
          <thead>
            <tr>
              <th colspan="3">
                {{
                  $t(
                    "components.annual_reports.uploaded_annual_meeting_minutes"
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colspan="2">
                <document-link
                  :document-id="minutesDocument.id"
                  :filename="filenameWithSize(minutesDocument)"
                />
              </td>

              <td class="text-right">
                <be-button
                  v-if="canRemoveDocument(minutesDocument)"
                  v-be-tooltip="$t('buttons.titles.remove')"
                  variant="danger"
                  size="sm"
                  icon="fa-times"
                  @click="confirmRemoveDocument(minutesDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <be-alert
          v-if="
            !can('mark_invitations_as_sent', 'annual_meeting') &&
            !meetingMinutesDocuments
          "
          variant="warning"
        >
          {{
            $t(
              "components.annual_reports.annual_meeting_tab.annual_meeting_minutes_not_uploaded"
            )
          }}
        </be-alert>

        <annual-report-documents
          v-if="canUploadDocument('annual_meeting_minutes')"
          document-key="annual_meeting_minutes"
          @uploading="(value) => (loading = value)"
        />

        <div class="d-flex justify-content-end mt-3">
          <be-button
            v-if="can('mark_as_completed', 'annual_meeting', 'update')"
            variant="primary"
            :disabled="loading"
            @click="annualMeetingEvent('mark_as_completed')"
          >
            {{
              $t("components.annual_reports.annual_meeting_tab.move_to_archive")
            }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";

export default {
  mixins: [AnnualReportTabBase],

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    invitationStatusInfo() {
      const transition = this.findTransition([
        { event: "mark_annual_meeting_invitations_as_sent" },
        { event: "mark_invitations_as_sent_annual_meeting" },
      ]);

      return this.$i18n.t(
        "components.annual_reports.annual_report_state_transitions.event_info_format",
        {
          name: transition.formatted_event_user_name,
          date: format(new Date(transition.created_at), "yyyy-MM-dd"),
        }
      );
    },

    showDocumentPreparationsText() {
      return this.canMarkAnnualMeetingInvitationsAsSent;
    },

    showMinutesInformation() {
      return this.can("upload_minutes", "annual_meeting");
    },

    showDocumentUploader() {
      return this.can("upload_minutes", "annual_meeting", "upload");
    },

    showInformationNotice() {
      return this.canMarkAnnualMeetingInvitationsAsSent;
    },

    showInvitationsNotSentInformation() {
      return this.canMarkAnnualMeetingInvitationsAsSent;
    },

    showInvitationStatus() {
      return (
        this.findTransitions([
          { event: "mark_annual_meeting_invitations_as_sent" },
          { event: "mark_invitations_as_sent_annual_meeting" },
        ]).length > 0
      );
    },
  },

  methods: {
    async annualMeetingEvent(event) {
      this.fireAnnualReportEvent({
        event,
        id: this.annualReport.id,
        namespace: "annual_meeting",
      });
    },
  },
};
</script>
