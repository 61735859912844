export default {
  methods: {
    getStatusChange(previous, chosen, statuses) {
      let currentState = null;

      for (let [key, available_statuses] of Object.entries(statuses)) {
        if (available_statuses.includes(previous)) {
          currentState = key;
        }
      }

      const statusChanged =
        statuses[currentState].includes(previous) &&
        !statuses[currentState].includes(chosen);

      if (statusChanged && currentState === "active") {
        return "inactive";
      } else if (statusChanged && currentState === "inactive") {
        return "active";
      }

      return "unchanged";
    },
  },
};
