<template>
  <div class="form">
    <h3 class="mb-4">
      {{ $t("components.companies.settings.company_information") }}
    </h3>

    <div class="row">
      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="title"
          :label="$t('activerecord.attributes.company.title')"
          :error="getErrors(localCompany, ['title'])"
        >
          <be-form-input
            id="title"
            v-model="localCompany.title"
            :disabled="!platformAdmin"
            required
            @change="clearErrors(localCompany, ['title'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.company.organization_number')"
          label-for="organization_number"
        >
          <be-form-input
            id="organization_number"
            v-model="localCompany.organization_number"
            :disabled="!platformAdmin"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="invoice_email"
          :label="$t('activerecord.attributes.company.invoice_email')"
          :error="getErrors(localCompany, ['invoice_email'])"
          :description="$t('simple_form.hints.company.invoice_email')"
        >
          <be-form-input
            id="invoice_email"
            v-model="localCompany.invoice_email"
            type="email"
            :disabled="!platformAdmin"
            @change="clearErrors(localCompany, ['invoice_email'])"
          />
        </be-form-group>
      </div>
    </div>

    <hr class="my-5" />

    <h3 class="mb-4">
      {{ $t("components.companies.settings.address_information") }}
    </h3>

    <div class="row">
      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="billing_address"
          :label="$t('activerecord.attributes.company.billing_address')"
        >
          <be-form-input
            id="billing_address"
            v-model="localCompany.billing_address"
            :disabled="!platformAdmin"
          />
        </be-form-group>

        <be-form-group
          label-for="postcode"
          :label="$t('activerecord.attributes.company.postcode')"
        >
          <be-form-input
            id="postcode"
            v-model="localCompany.postcode"
            :disabled="!platformAdmin"
          />
        </be-form-group>

        <be-form-group
          label-for="country"
          :label="$t('activerecord.attributes.user.country')"
        >
          <be-form-select
            id="country"
            v-model="localCompany.country"
            name="country"
            :disabled="!platformAdmin"
            preset-options="countries"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <be-form-group
          label-for="billing_address_two"
          :label="$t('activerecord.attributes.company.billing_address_two')"
        >
          <be-form-input
            id="billing_address_two"
            v-model="localCompany.billing_address_two"
            :disabled="!platformAdmin"
          />
        </be-form-group>

        <be-form-group
          label-for="city"
          :label="$t('activerecord.attributes.company.city')"
        >
          <be-form-input
            id="city"
            v-model="localCompany.city"
            :disabled="!platformAdmin"
          />
        </be-form-group>
      </div>
    </div>

    <hr v-if="platformAdmin" class="my-5" />

    <be-form-group
      v-if="platformAdmin"
      label-for="logo"
      :label="$t('components.companies.settings.choose_logo')"
    >
      <single-file-uploader
        v-model:remove="localCompany.remove_logo"
        :file="localCompany.logo || {}"
        :accepted-types="acceptedFileTypes"
        @file-updated="fileUpdated"
        @uploading="(value) => (uploadingFiles = value)"
      />
    </be-form-group>

    <div v-if="flipperFlag('report_accent_color')" class="row">
      <div class="col-md-6 col-lg-4 col-xl-3">
        <be-form-group
          v-if="platformAdmin"
          label-for="brand-color"
          :label="$t('components.companies.settings.brand_color')"
          :description="
            $t('components.companies.settings.brand_color_description')
          "
        >
          <be-form-input
            id="brand-color"
            v-model="localCompany.brand_color"
            type="color"
            size="lg"
          />
        </be-form-group>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <be-button
        v-if="platformAdmin"
        :disabled="uploadingFiles"
        :loading="loading"
        variant="primary"
        @click="saveForm"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { IMAGE_TYPES } from "@/constants/file-types";

export default {
  data() {
    return {
      files: [],
      loading: false,
      uploadingFiles: false,
      localCompany: {},
    };
  },

  computed: {
    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },
  },

  created() {
    this.localCompany = this.cloneDeep(this.$currentCompany);
  },

  methods: {
    async saveForm() {
      try {
        this.loading = true;

        let { logo, ...rest } = this.localCompany;
        if (logo?.upload_state !== "added" || rest.remove_logo) {
          logo = null;
        }
        const response = await axios.patch(this.url("/"), {
          company: {
            logo: logo,
            ...rest,
          },
        });

        this.localCompany = response.data;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.localCompany = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    fileUpdated(file) {
      this.localCompany.logo = file;
    },
  },
};
</script>
