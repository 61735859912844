<template>
  <span>
    <be-spinner v-if="loading">
      {{ $t("buttons.states.loading") }}
    </be-spinner>

    <be-timer
      v-else-if="retryAt"
      v-slot="{ timerFinished, formattedDurationRemaining }"
      :finish-at="retryAt"
    >
      <template v-if="timerFinished">
        {{
          $t(
            "components.user.settings.mfa.email_mfa_activation.did_you_not_receive_code"
          )
        }}
        <be-link @click="requestLoginCode">
          {{
            $t(
              "components.user.settings.mfa.email_mfa_activation.request_new_activation_code"
            )
          }}
        </be-link>
      </template>

      <template v-else>
        {{
          $t(
            "components.user.settings.mfa.email_mfa_activation.did_you_not_receive_code_w_time",
            { time: formattedDurationRemaining }
          )
        }}
      </template>
    </be-timer>
  </span>
</template>
<script>
import BeTimer from "@/components/shared/BeTimer.vue";

export default {
  components: {
    BeTimer,
  },

  data() {
    return {
      loading: true,
      retryAt: null,
    };
  },

  mounted() {
    this.requestLoginCode();
  },

  methods: {
    async requestLoginCode() {
      this.loading = true;
      try {
        const { data } = await axios.post("/auth/mfa/email/login_codes");
        this.retryAt = data.retry_at;
      } catch (error) {
        if (error.response.status === 429) {
          this.retryAt = error.response.data.retry_at;
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
