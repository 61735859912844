<template>
  <div class="card">
    <div class="card-body">
      <h3>{{ $t("components.template_packages.nav.tabs.user_templates") }}</h3>

      <div class="row mb-3">
        <div class="col-12 col-md-9 mb-3 mb-md-0">
          {{ $t("components.template_packages.user_templates.description") }}
        </div>

        <div class="col-12 col-md-3 text-md-right">
          <be-button
            v-if="publishableUserTemplates.length > 1 && platformAdmin"
            :href="url('/template_packages/new?package=true')"
            variant="outline-secondary"
          >
            {{ $t("components.template_packages.publish_package") }}
          </be-button>
        </div>
      </div>

      <be-table
        v-if="publishableUserTemplates.length > 0"
        :items="publishableUserTemplates"
        :fields="fields"
        sort-by="created_at"
        sort-desc
      >
        <template #published="{ item }">
          <i
            v-if="item.published"
            v-be-tooltip="
              translateAttribute('template_package', 'published_at')
            "
            class="fal fa-globe fa-fw text-muted"
          />
        </template>

        <template #title="{ item }">
          <template v-if="item.title">
            {{ item.title }}
          </template>
        </template>

        <template #created_at="{ item }">
          {{ formattedCreatedAt(item) }}
        </template>

        <template #template_type="{ item }">
          {{ $t(`models.template_package.types.${item.template_type}`) }}
        </template>

        <template #options="{ item }">
          <be-button
            v-if="item.policy?.publish && !item.published"
            :href="publishUrl(item)"
            variant="primary"
            size="sm"
            inline
          >
            {{ $t("buttons.titles.publish") }}
          </be-button>
        </template>
      </be-table>

      <be-alert v-else variant="info">
        {{ $t("components.template_packages.empty") }}
      </be-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "published",
          label: "",
          class: "col-shrink text-center",
          sortable: false,
        },
        {
          key: "title",
          label: this.translateAttribute("template_package", "title"),
          sortable: true,
        },
        {
          key: "created_at",
          label: this.translateAttribute("template_package", "created_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "template_type",
          label: this.translateAttribute("template_package", "type"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      userTemplates: "template_packages/userTemplates",
    }),

    publishableUserTemplates() {
      return this.userTemplates.filter(
        (template) => template.template_type != "Phrase"
      );
    },
  },

  methods: {
    formattedCreatedAt(template) {
      return this.$i18n.d(new Date(template.created_at), "date");
    },

    publishUrl(template) {
      return this.url(
        `/template_packages/new?type=${template.template_type}&template_id=${template.id}`
      );
    },
  },
};
</script>
