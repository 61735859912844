<template>
  <div class="card">
    <div class="card-header">
      {{ $t("views.educations.groups.new.add_members") }}
    </div>

    <div class="card-body">
      <table class="table table-responsive table-hover">
        <thead>
          <tr>
            <th>
              {{
                $t("activerecord.attributes.education_group_participant.name")
              }}
            </th>

            <th>
              {{
                $t("activerecord.attributes.education_group_participant.email")
              }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>{{ currentUser.name }}</td>

            <td>{{ currentUser.email }}</td>

            <td class="col-shrink"></td>
          </tr>

          <persisted-participant-row
            v-for="(participant, index) in persistedParticipants"
            :key="'persisted-participant-' + index"
            :education-group="educationGroup"
            :initial-participant="participant"
            :index="index"
          />

          <new-participant-row
            v-for="(participant, index) in newParticipants"
            :key="'new-participant-' + index"
            :education-group="educationGroup"
            :participant="participant"
            :index="persistedParticipants.length + index"
            @new-participant-removed="removeNewParticipant"
          />
        </tbody>
      </table>
    </div>

    <div class="card-footer d-md-flex justify-content-between">
      <be-button variant="outline-secondary" @click="addNewParticipant()">
        {{ $t("views.educations.groups.new.add_members") }}
      </be-button>

      <be-button
        v-if="showSaveButton"
        type="submit"
        variant="primary"
        :data-confirm="$t('views.educations.groups.edit.invitation_warning')"
      >
        {{ saveButtonTitle }}
      </be-button>
    </div>
  </div>
</template>

<script>
import PersistedParticipantRow from "./PersistedParticipantRow.vue";
import NewParticipantRow from "./NewParticipantRow.vue";
import { mapGetters } from "vuex";
import extend from "lodash/extend";

export default {
  components: {
    PersistedParticipantRow,
    NewParticipantRow,
  },

  props: {
    educationGroup: {
      type: Object,
      required: true,
    },

    selectedCompanyUsers: {
      type: Array,
      required: false,
      default: null,
    },

    showSaveButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      persistedParticipants: [],
      newParticipants: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
    }),

    saveButtonTitle() {
      if (this.educationGroup.id) {
        return this.$t("buttons.titles.save");
      }

      return this.$t("models.education_group.create");
    },
  },

  watch: {
    selectedCompanyUsers: {
      handler() {
        this.populateForm();
      },

      deep: true,
    },
  },

  mounted() {
    if (this.educationGroup.education_group_participants) {
      let initialPersistedParticipants =
        this.educationGroup.education_group_participants.filter(
          (participant) =>
            participant.user.email != this.currentUser.email &&
            participant.created_at
        );

      let initialNewParticipants =
        this.educationGroup.education_group_participants.filter(
          (participant) =>
            participant.user.email != this.currentUser.email &&
            !participant.created_at &&
            participant._destroy != true
        );

      initialPersistedParticipants.forEach((participant) => {
        this.addParticipant(participant, this.persistedParticipants);
      });

      initialNewParticipants.forEach((participant) => {
        this.addNewParticipant(participant, this.newParticipants);
      });
    }
  },

  methods: {
    addNewParticipant(participant) {
      // Participant user exists
      if (participant && participant.user) {
        this.newParticipants.push(
          extend(participant, {
            id: +new Date() + Math.random(),
            destroyed: participant._destroy,
            persisted: false,
          })
        );

        // New participant without previous data
      } else {
        this.newParticipants.push({
          id: +new Date() + Math.random(),
          destroyed: 0,
          persisted: false,

          user: {
            name: "",
            email: "",
          },
        });
      }
    },

    addParticipant(participant) {
      this.persistedParticipants.push(
        extend(participant, {
          destroyed: participant._destroy,
          persisted: false,
        })
      );
    },

    removeNewParticipant(participant) {
      let idx = this.newParticipants.findIndex(
        (addedParticipant) => addedParticipant.id == participant.id
      );
      this.newParticipants[idx].destroyed = 1;
    },

    populateForm() {
      this.newParticipants = [];

      if (this.selectedCompanyUsers) {
        this.selectedCompanyUsers.forEach((user) => {
          if (user.id != this.currentUser.id) {
            this.newParticipants.push({
              id: +new Date() + Math.random(),
              destroyed: 0,
              persisted: false,

              user: {
                name: user.name,
                email: user.email,
              },
            });
          }
        });
      }
    },
  },
};
</script>
