<template>
  <save-status
    :class="textClass"
    :loading="anythingLoading"
    :last-saved-at="lastUpdatedAt"
    :last-saved-at-prefix="lastSavedAtPrefix"
  />
</template>

<script>
import { mapGetters } from "vuex";
import SaveStatus from "@/components/shared/SaveStatus.vue";

export default {
  components: {
    SaveStatus,
  },

  props: {
    textClass: {
      type: String,
      required: false,
      default: "",
    },
  },

  computed: {
    ...mapGetters("attendances", ["anythingLoading", "lastUpdatedAt"]),

    lastSavedAtPrefix() {
      return this.$t(
        "components.meetings.material.editor.save_state.last_saved_at_prefix_attendances"
      );
    },
  },
};
</script>
