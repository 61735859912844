<template>
  <div>
    <be-form-group>
      <be-form-checkbox
        v-model="allowChange"
        name="company[allow_change_of_signature_solutions]"
        :description="
          $t(
            'components.companies.settings.e_signing.hints.allow_change_of_signature_solutions'
          )
        "
      >
        {{
          $t(
            "components.companies.settings.e_signing.labels.allow_change_of_signature_solutions"
          )
        }}
      </be-form-checkbox>
    </be-form-group>

    <be-form-group>
      <be-form-checkbox
        v-model="computedAllowElectronicid"
        :disabled="!allowChange"
        :description="
          $t(
            'components.companies.settings.e_signing.hints.allow_signature_type_electronicid'
          )
        "
      >
        {{
          $t(
            "components.companies.settings.e_signing.labels.allow_signature_type_electronicid"
          )
        }}
      </be-form-checkbox>
    </be-form-group>

    <be-form-group>
      <be-form-checkbox
        v-model="computedAllowTouch"
        :disabled="!allowChange"
        :description="
          $t(
            'components.companies.settings.e_signing.hints.allow_signature_type_touch'
          )
        "
      >
        {{
          $t(
            "components.companies.settings.e_signing.labels.allow_signature_type_touch"
          )
        }}
      </be-form-checkbox>
    </be-form-group>

    <be-form-group>
      <be-form-checkbox
        v-model="computedAllowSms"
        :disabled="!allowChange"
        :description="
          $t(
            'components.companies.settings.e_signing.hints.allow_signature_type_sms'
          )
        "
      >
        {{
          $t(
            "components.companies.settings.e_signing.labels.allow_signature_type_sms"
          )
        }}
      </be-form-checkbox>
    </be-form-group>

    <input
      type="hidden"
      name="company[allow_change_of_signature_solutions]"
      :value="allowChange"
    />

    <template v-if="allowChange">
      <input
        type="hidden"
        name="company[allow_signature_type_electronicid]"
        :value="allowElectronicid"
      />

      <input
        type="hidden"
        name="company[allow_signature_type_touch]"
        :value="allowSignatureTypeTouch"
      />

      <input
        type="hidden"
        name="company[allow_signature_type_sms]"
        :value="allowSignatureTypeSms"
      />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    allowChangeInitiallyEnabled: {
      type: Boolean,
      required: true,
    },

    allowElectronicidInitiallyEnabled: {
      type: Boolean,
      required: true,
    },

    allowTouchInitiallyEnabled: {
      type: Boolean,
      required: true,
    },

    allowSmsInitiallyEnabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      enabledMethods: [],
      allowChange: this.allowChangeInitiallyEnabled,
      allowElectronicid: this.allowElectronicidInitiallyEnabled,
      allowSignatureTypeTouch: this.allowTouchInitiallyEnabled,
      allowSignatureTypeSms: this.allowSmsInitiallyEnabled,
    };
  },

  computed: {
    computedAllowElectronicid: {
      get() {
        return this.allowChange ? this.allowElectronicid : true;
      },

      set(v) {
        this.allowChange ? (this.allowElectronicid = v) : undefined;
      },
    },

    computedAllowTouch: {
      get() {
        return this.allowChange ? this.allowSignatureTypeTouch : false;
      },

      set(v) {
        this.allowChange ? (this.allowSignatureTypeTouch = v) : undefined;
      },
    },

    computedAllowSms: {
      get() {
        return this.allowChange ? this.allowSignatureTypeSms : false;
      },

      set(v) {
        this.allowChange ? (this.allowSignatureTypeSms = v) : undefined;
      },
    },
  },
};
</script>
