<template>
  <tr v-if="shareholder.share_count > 0">
    <td v-if="shareholder.company_name">
      {{ shareholder.company_name }}
    </td>

    <td v-else>
      {{ shareholder.name }}
    </td>

    <td class="text-right">
      {{ shareholder.share_count }}
    </td>

    <td>
      <be-form-group
        :error="getErrors(transaction, ['number_of_shares'])"
        class="mb-0"
      >
        <be-form-input
          v-model="shareholder.addedShares"
          type="number"
          min="1"
          step="1"
          :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][number_of_shares]`"
          required
          @change="clearErrors(transaction, ['number_of_shares'])"
        />
      </be-form-group>
    </td>

    <td class="text-right">{{ totalShares }}</td>

    <td class="text-right">
      {{ totalPrice }}

      <input
        v-if="transaction"
        id="id"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][id]`"
        :value="transaction.id"
      />

      <input
        id="buyer_id"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][buyer_id]`"
        :value="shareholder.id"
      />

      <input
        id="share_price"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_price]`"
        :value="action.price"
      />

      <input
        id="share_type"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_type]`"
        :value="shareType"
      />

      <input
        id="action_type"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][action_type]`"
        :value="action.action_type"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },

    initialShareholder: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    sharePrice: {
      type: String,
      required: false,
      default: null,
    },

    shareType: {
      type: String,
      required: true,
    },
  },

  emits: ["shareholder-updated"],

  data() {
    return {
      share_setting: "new_shares",
      transaction: this.initialShareholder.transaction,

      shareholder: {
        ...this.cloneDeep(this.initialShareholder),
        addedShares: this.initialShareholder.addedShares || null,
      },
    };
  },

  computed: {
    totalPrice() {
      if (this.shareholder.addedShares && this.sharePrice) {
        return BigNumber(parseInt(this.shareholder.addedShares)).multipliedBy(
          BigNumber(this.sharePrice)
        );
      }

      return null;
    },

    totalShares() {
      let oldShareCount = this.shareholder.share_count;
      let updatedShareCount = null;

      if (this.valueNumeric(this.shareholder.addedShares)) {
        updatedShareCount =
          oldShareCount + parseInt(this.shareholder.addedShares);
      } else if (!this.shareholder.addedShares) {
        updatedShareCount = oldShareCount;
      }

      return updatedShareCount;
    },
  },

  watch: {
    shareholder: {
      handler() {
        this.$emit("shareholder-updated", this.shareholder);
      },

      deep: true,
    },
  },

  methods: {
    valueNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
  },
};
</script>
