<template>
  <div id="client-managers-table" class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th colspan="2"></th>

          <th>{{ translateAttribute("user", "name") }}</th>

          <th>{{ translateAttribute("user", "email") }}</th>

          <th class="col-shrink">
            {{ translateAttribute("membership", "function") }}
          </th>

          <th class="col-shrink">
            {{ translateAttribute("membership", "policy_level") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template
          v-for="(adminCompany, adminCompanyIndex) in adminCompanies"
          :key="`admin-company-${adminCompanyIndex}`"
        >
          <tr>
            <td colspan="3">
              <strong>{{ adminCompany.title }}</strong>
            </td>

            <td>{{ adminCompany.email }}</td>

            <td>{{ $t("memberships.functions.admin") }}</td>

            <td>{{ $t("memberships.policy_levels.admin") }}</td>
          </tr>

          <tr
            v-for="(membership, membershipIndex) in adminCompany.memberships"
            :key="`membership-${membershipIndex}`"
          >
            <td class="col-shrink text-center">
              <i
                v-if="membership.primary_contact"
                id="primary_contact"
                v-be-tooltip="
                  $t('activerecord.attributes.membership.primary_contact_for', {
                    title: adminCompany.title,
                  })
                "
                class="fal fa-star"
              />
            </td>

            <td class="col-shrink">
              <user-avatar :user="membership.user" />
            </td>

            <td>{{ membership.user.name }}</td>

            <td>{{ membership.user.email }}</td>

            <td class="col-shrink">{{ membership.title }}</td>

            <td class="col-shrink">
              {{ $t("memberships.policy_levels.admin") }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    adminCompanies: {
      type: Array,
      required: true,
    },
  },
};
</script>
