<template>
  <div class="card mb-2">
    <div class="card-body">
      <be-alert v-if="hasMissingMeetings" variant="warning">
        {{ $t("components.annual_cycles.new_meetings.has_missing_meetings") }}
      </be-alert>

      <div class="d-md-flex justify-content-between mb-3">
        <div>
          <h2>
            {{
              $t("components.annual_cycles.new_meetings.meetings", {
                year: year,
              })
            }}
          </h2>
        </div>

        <div>
          <be-button variant="outline-primary" @click="toggleAllMeetings">
            <template v-if="showMeetings">
              {{ $t("buttons.toggle_all_selection.minimize_all") }}
            </template>

            <template v-else>
              {{ $t("buttons.toggle_all_selection.expand_all") }}
            </template>
          </be-button>
        </div>
      </div>

      <div class="accordion">
        <drop-list
          :items="localMeetings"
          :no-animations="true"
          class="list-group list-group-flush"
          @reorder="reorderItems"
        >
          <template #item="{ item }">
            <drag :key="item.number" class="item mb-2" handle=".handle">
              <meeting-container
                :disabled-dates="disabledDates(item)"
                :meeting="item"
                @meeting-removed="$emit('meeting-removed')"
              />
            </drag>
          </template>

          <!-- This slot must be defined, even if it is empty -->
          <template #feedback></template>
        </drop-list>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-md-flex justify-content-between">
        <be-button variant="light" icon="fa-plus" @click="addMeeting">
          {{ $t("components.annual_cycles.new_meetings.add_meeting") }}
        </be-button>

        <div class="mt-2 mt-md-0">
          <be-button variant="outline-secondary" @click="cancelEditing">
            {{ $t("buttons.titles.cancel") }}
          </be-button>

          <be-button variant="primary" @click="submit">
            {{ $t("buttons.titles.save") }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { add } from "date-fns/add";
import { startOfDay } from "date-fns/startOfDay";
import { mapGetters } from "vuex";

import { Drag, DropList } from "vue-easy-dnd";
import MeetingContainer from "./MeetingContainer.vue";

export default {
  components: {
    Drag,
    DropList,
    MeetingContainer,
  },

  props: {
    existingDisabledDates: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ["meeting-removed", "meeting-editing-canceled"],

  data() {
    return {
      showMeetings: false,
      localMeetings: [],
    };
  },

  computed: {
    ...mapGetters({
      meetings: "annual_cycle/meetings",
      year: "annual_cycle/year",
      initialNumber: "annual_cycle/initialNumber",
    }),

    availableMeetingNumber() {
      return (
        Math.max(...this.localMeetings.map((meeting) => meeting.number)) + 1
      );
    },

    lastStartAt() {
      return this.localMeetings[this.localMeetings.length - 1]?.start_at;
    },

    lastEndAt() {
      return this.localMeetings[this.localMeetings.length - 1]?.end_at;
    },

    hasMissingMeetings() {
      return (
        this.localMeetings.length <
        Number(this.$currentCompany.preferences["meetings_per_year"]) + 1
      );
    },
  },

  watch: {
    meetings: {
      handler(value) {
        this.localMeetings = this.cloneDeep(value);
      },

      deep: true,
    },
  },

  mounted() {
    this.localMeetings = this.cloneDeep(this.meetings);
  },

  methods: {
    async submit() {
      const response = await this.$store.dispatch(
        "annual_cycle/submitMeetings"
      );

      if (response) {
        window.location.href = this.url(`/annual_cycle?year=${this.year}`);
      }
    },

    addMeeting() {
      this.$store.commit("annual_cycle/updateMeeting", {
        uuid: this.generateUuid(),
        title: "",
        start_at: this.lastStartAt,
        end_at: this.lastEndAt,
        number: this.availableMeetingNumber,
      });
    },

    reorderItems(event) {
      this.$store.commit("annual_cycle/reorderMeetings", event);
      this.$store.commit("annual_cycle/reorderDates");
    },

    toggleAllMeetings() {
      this.showMeetings = !this.showMeetings;

      this.localMeetings.forEach((meeting) => {
        if (this.showMeetings) {
          this.$store.commit("annual_cycle/expandMeeting", meeting.uuid);
        } else {
          this.$store.commit("annual_cycle/collapseMeeting", meeting.uuid);
        }
      });
    },

    async cancelEditing() {
      const isConfirmed = await this.promptConfirm(
        this.$t("components.annual_cycles.new_meetings.confirm_cancel")
      );

      if (isConfirmed) {
        this.$emit("meeting-editing-canceled");
      }
    },

    disabledDates(meeting) {
      let idx = this.localMeetings.findIndex(
        (existingMeeting) => existingMeeting.id == meeting.id
      );
      let from = undefined;
      let to = undefined;

      if (idx > -1) {
        // Sets disabled dates starting from the next meetings start date
        let nextMeeting = this.localMeetings[idx + 1];

        if (nextMeeting) {
          from = startOfDay(
            add(new Date(nextMeeting.start_at), { days: 1 })
          ).toString();
        }

        // Sets disabled dates ending with the previous meetings end date
        if (idx == 0 && this.existingDisabledDates) {
          to = this.startOfDayAsString(this.existingDisabledDates["to"]);
        } else {
          let previousMeeting = this.localMeetings[idx - 1];

          if (previousMeeting) {
            to = this.startOfDayAsString(previousMeeting.end_at);
          }
        }
      }

      return {
        to: to,
        from: from,
      };
    },

    startOfDayAsString(dateObject) {
      return startOfDay(new Date(dateObject)).toString();
    },
  },
};
</script>
