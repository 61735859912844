<template>
  <fieldset>
    <template v-if="suggestionOptions.length > 0">
      <be-form-group
        label-for="fetch"
        :error="getErrors(localCompany, ['title'])"
        :label="$t('components.new_company_form.fetch')"
      >
        <be-form-select
          id="fetch"
          v-model="selectedSuggestion"
          :options="suggestionOptions"
          option-label="title"
          option-value="title"
        />
      </be-form-group>

      <div class="lined-heading">
        {{ $t("components.new_company_form.separator") }}
      </div>
    </template>

    <be-form-group
      label-for="title"
      :error="getErrors(localCompany, ['title'])"
      :label="$t('components.new_company_form.title')"
    >
      <be-form-input
        id="title"
        v-model="localCompany.title"
        name="company[title]"
        required
        @change="clearSuggestion() && clearErrors(localCompany, 'title')"
      />
    </be-form-group>

    <be-form-group
      label-for="organization_number"
      :label="$t('components.new_company_form.organization_number')"
      :error="getErrors(localCompany, ['organization_number'])"
    >
      <be-form-input
        id="organization_number"
        v-model="localCompany.organization_number"
        name="company[organization_number]"
        @change="
          clearSuggestion() && clearErrors(localCompany, 'organization_number')
        "
      />
    </be-form-group>

    <be-form-group
      label-for="type_of_organization"
      :label="$t('components.new_company_form.type_of_organization')"
      :error="getErrors(localCompany, ['type_of_organization'])"
      :description="
        $t('components.new_company_form.hints.type_of_organization')
      "
    >
      <be-form-select
        id="type_of_organization"
        v-model="localCompany.type_of_organization"
        name="company[type_of_organization]"
        :options="organizationOptions"
        :state="validationState(localCompany, ['type_of_organization'])"
        @change="clearErrors(localCompany, 'type_of_organization')"
      />
    </be-form-group>

    <be-form-group
      label-for="promotional_code"
      :label="$t('components.new_company_form.promotional_code')"
      :error="getErrors(localCompany, ['promotional_code'])"
    >
      <be-form-input
        id="promotional_code"
        v-model="localCompany.promotional_code"
        name="company[promotional_code]"
        @change="clearErrors(localCompany, 'promotional_code')"
      />
    </be-form-group>

    <be-form-group
      v-if="showPartnerCodes"
      :description="
        $t('components.new_company_form.hints.admin_panel_partner_code')
      "
    >
      <template #label>
        {{ $t("components.new_company_form.admin_panel_partner_code") }}
        <span v-dompurify-html="$t('simple_form.required.html')" />
      </template>

      <be-form-radio-group
        v-model="localCompany.admin_panel_partner_code"
        :options="partnerCodes"
        name="company[admin_panel_partner_code]"
        stacked
      />
    </be-form-group>

    <be-form-group
      :invalid-feedback="getErrors(localCompany, 'accept_terms_of_service')"
    >
      <be-form-checkbox
        name="company[accept_terms_of_service]"
        checked-value="1"
        unchecked-value="0"
        :state="validationState(localCompany, 'accept_terms_of_service')"
        @change="clearErrors(localCompany, 'accept_terms_of_service')"
      >
        <span
          v-dompurify-html="
            $t('components.new_company_form.terms_of_service_html', {
              path: termsOfServicePath,
            })
          "
        />
      </be-form-checkbox>
    </be-form-group>

    <input
      v-model="localCompany.billing_address"
      type="hidden"
      name="company[billing_address]"
    />

    <input
      v-model="localCompany.billing_address_two"
      type="hidden"
      name="company[billing_address_two]"
    />

    <input
      v-model="localCompany.postcode"
      type="hidden"
      name="company[postcode]"
    />

    <input v-model="localCompany.city" type="hidden" name="company[city]" />

    <input
      v-model="localCompany.financial_year_start"
      type="hidden"
      name="company[financial_year_start]"
    />

    <input
      v-model="localCompany.board_year_start"
      type="hidden"
      name="company[board_year_start]"
    />
  </fieldset>
</template>
<script>
import SynaCompanySuggestions from "@/mixins/SynaCompanySuggestions";
import { createNamespacedHelpers } from "vuex";
import { flattenOptions } from "@/components/shared/be_form_select/utils";

const { mapState: mapCompanyState } = createNamespacedHelpers("company");

export default {
  mixins: [SynaCompanySuggestions],

  props: {
    newCompany: {
      type: Object,
      required: true,
    },

    partnerCodes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      localCompany: {
        ...this.newCompany,

        type_of_organization:
          this.newCompany.type_of_organization || "limited_company",
      },
    };
  },

  computed: {
    ...mapCompanyState(["companies"]),

    organizationOptions() {
      return this.$config.ORGANIZATION_TYPES.map((type) => ({
        value: type,
        text: this.$t(`models.company.type_of_organizations.${type}`),
      }));
    },

    showPartnerCodes() {
      return Object.entries(this.partnerCodes).length > 0;
    },

    termsOfServicePath() {
      const path = this.localCompany.paths.terms_of_service;
      const adminPanelPartnerCode = this.localCompany.admin_panel_partner_code;
      if (adminPanelPartnerCode === "no_code") {
        return path;
      }
      const url = new URL(path, document.location);
      const params = new URLSearchParams(url.search);
      params.set("admin_panel_partner_code", adminPanelPartnerCode);
      url.search = params;
      return url;
    },
  },

  watch: {
    selectedSuggestion(value) {
      const { localCompany } = this;

      const company = flattenOptions(this.suggestionOptions).find(
        (suggestion) => suggestion.title === value
      );

      if (!company) {
        return;
      }

      if (company.title) {
        // Visible fields, only change if a suggestion is selected, not when unsetting:
        localCompany.title = company.title;
        localCompany.organization_number = this.formatOrgNumber(company.id);
        localCompany.type_of_organization = company.type_of_organization;
      }

      localCompany.billing_address = company.billing_address;
      localCompany.billing_address_two = company.billing_address_two;
      localCompany.postcode = company.postcode;
      localCompany.city = company.city;

      const yearStart = parseInt(company.financial_year_start) || 1;

      localCompany.financial_year_start = yearStart;
      localCompany.board_year_start = (yearStart + 4) % 12;
    },
  },
};
</script>
