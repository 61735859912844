<template>
  <tr :class="rowClasses">
    <td>
      <be-link :href="url(`/documents/compliances/${compliance.id}`)">
        {{ compliance.title }}
      </be-link>

      <input
        type="hidden"
        :name="`company[compliance_attributes][${rowIndex}][title]`"
        :value="compliance.title"
      />

      <input
        type="hidden"
        :name="`company[compliance_attributes][${rowIndex}][id]`"
        :value="compliance.id"
      />
    </td>

    <td
      v-for="(role, index) in roles"
      :key="`column-role-${role}`"
      class="text-right"
    >
      <span
        v-if="compliance.required_by_law && role == 'law'"
        v-be-tooltip="$t('models.document.compliances.required_by_law')"
        class="text-muted mr-1"
      >
        <i class="fal fa-balance-scale" />
      </span>

      <compliance-permission-checkbox
        v-else
        :compliance="compliance"
        :index="index"
        :row-index="rowIndex"
        :role="role"
      />
    </td>
  </tr>
</template>

<script>
import CompliancePermissionCheckbox from "./CompliancePermissionCheckbox.vue";

export default {
  components: {
    CompliancePermissionCheckbox,
  },

  props: {
    compliance: {
      type: Object,
      required: true,
    },

    rowIndex: {
      type: Number,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },
  },

  computed: {
    rowClasses() {
      let addedClasses = [`status-compliance-${this.compliance.status}`];

      if (this.compliance.overdue) {
        addedClasses.push("table-danger");
      }

      return addedClasses;
    },
  },
};
</script>
