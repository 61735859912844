<template>
  <be-modal :id="modalId" size="lg">
    <template #header>
      <h5 class="mb-0">
        {{ modalTitle }}<br />

        <small>
          {{ localState.company_group_ownership.parent_company_title }}
          <i class="fas fa-arrow-right"></i>
          {{ localState.company_group_ownership.child_company_title }}
        </small>
      </h5>
    </template>

    <ownership-distribution-form
      v-model="localState"
      :states="computedStates"
    />

    <template #footer="{ cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        type="submit"
        :disabled="saving"
        :loading="saving"
        @click="handleOk"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import OwnershipDistributionForm from "./OwnershipDistributionForm.vue";
import { generateId } from "@/utils/id";

export default {
  components: { OwnershipDistributionForm },

  props: {
    state: {
      type: Object,
      default: () => ({}),
      required: false,
    },

    inClientManager: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["state-updated", "state-added"],

  data() {
    return {
      saving: false,
      localState: { ...this.state },
      ownership: this.state.company_group_ownership,
      modalId: generateId("ownership-modal"),
    };
  },

  computed: {
    baseUrl() {
      return this.inClientManager
        ? "companies/group/ownerships"
        : "group/ownerships";
    },

    action() {
      return this.state.id ? "update" : "add";
    },

    modalTitle() {
      return this.action === "add"
        ? this.$t("views.companies.group.ownership.new_owner_distribution")
        : this.$t("views.companies.group.ownership.edit_owner_distribution");
    },

    computedStates() {
      return this.ownership.states
        .map((state) => {
          return {
            id: state.id,
            start_at: new Date(state.start_at),
            end_at: new Date(state.end_at),
            ownership_id: this.ownership.id,
          };
        })
        .sort((a, b) => {
          return a.start_at - b.start_at;
        });
    },
  },

  watch: {
    state() {
      this.localState = { ...this.state };
      this.$beModal.show(this.modalId);
    },
  },

  mounted() {
    this.$beModal.show(this.modalId);
  },

  methods: {
    handleOk(e) {
      e.preventDefault();

      if (this.action === "add") {
        this.addOwnershipState();
      } else {
        this.updateOwnershipState();
      }
    },

    async addOwnershipState() {
      this.saving = true;
      const { ownership, localState } = this;

      try {
        const { data } = await axios.post(
          this.url(`${this.baseUrl}/${ownership.id}/states`),
          localState
        );
        if (typeof data === "object") {
          this.$emit("state-added", data);
        }
        this.resetModal();
      } catch (error) {
        if (error.response && error.response.status === 422) {
          localState.errors = error.response.data.errors;
        } else {
          this.handleError(error);
        }
      } finally {
        this.saving = false;
      }
    },

    async updateOwnershipState() {
      const { ownership, localState } = this;
      const url = this.url(
        `${this.baseUrl}/${ownership.id}/states/${localState.id}`
      );
      this.saving = true;
      try {
        const { data } = await axios.patch(url, localState);
        this.$emit("state-updated", data);
        this.resetModal();
      } catch (error) {
        if (error.response?.status === 422) {
          this.localState.errors = error.response.data.errors;
        }

        this.handleError(error);
      } finally {
        this.saving = false;
      }
    },

    resetModal() {
      this.$beModal.hide(this.modalId);
    },
  },
};
</script>
