<template>
  <form @submit.prevent="saveShareholder">
    <div class="card mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-6">
            <template v-if="shareholder.company_name">
              <!-- Company name -->
              <be-form-group
                :label="$t('companies.shareholder_registers.company_name')"
                label-for="company-name"
                :error="getErrors(localShareholder, 'company_name')"
                required
              >
                <be-form-input
                  id="company-name"
                  v-model="localShareholder.company_name"
                  @change="clearErrors(localShareholder, 'company_name')"
                />
              </be-form-group>

              <!-- Organization number -->
              <be-form-group
                :label="
                  $t('companies.shareholder_registers.organization_number')
                "
                label-for="organization-number"
                :error="getErrors(localShareholder, 'identification_number')"
                required
              >
                <be-form-input
                  id="organization-number"
                  v-model="localShareholder.identification_number"
                  @change="
                    clearErrors(localShareholder, 'identification_number')
                  "
                />
              </be-form-group>

              <!-- Contact person name -->
              <be-form-group
                :label="
                  $t('activerecord.attributes.shareholder.contact_person')
                "
                label-for="contact-person-name"
                :error="getErrors(localShareholder, 'name')"
                required
              >
                <be-form-input
                  id="contact-person-name"
                  v-model="localShareholder.name"
                  @change="clearErrors(localShareholder, 'name')"
                />
              </be-form-group>

              <!-- Contact person email -->
              <be-form-group
                :label="
                  $t('companies.shareholder_registers.contact_person_email')
                "
                label-for="contact-person-email"
                :error="getErrors(localShareholder, 'email')"
              >
                <be-form-input
                  id="contact-person-email"
                  v-model="localShareholder.email"
                  @change="clearErrors(localShareholder, 'email')"
                />
              </be-form-group>
            </template>

            <template v-else>
              <!-- Name -->
              <be-form-group
                :label="$t('activerecord.attributes.shareholder.name')"
                label-for="name"
                :error="getErrors(localShareholder, 'name')"
                required
              >
                <be-form-input
                  id="name"
                  v-model="localShareholder.name"
                  @change="clearErrors(localShareholder, 'name')"
                />
              </be-form-group>

              <!-- Identification number -->
              <be-form-group
                :label="
                  $t(
                    'companies.shareholder_registers.identification_number_user'
                  )
                "
                label-for="identification-number"
                :error="getErrors(localShareholder, 'identification_number')"
                required
              >
                <be-form-input
                  id="identification-number"
                  v-model="localShareholder.identification_number"
                  @change="
                    clearErrors(localShareholder, 'identification_number')
                  "
                />
              </be-form-group>

              <!-- Email -->
              <be-form-group
                :label="$t('activerecord.attributes.shareholder.email')"
                label-for="email"
                :error="getErrors(localShareholder, 'email')"
              >
                <be-form-input
                  id="email"
                  v-model="localShareholder.email"
                  type="email"
                  @change="clearErrors(localShareholder, 'email')"
                />
              </be-form-group>
            </template>

            <!-- Phone -->
            <be-form-group
              :label="$t('activerecord.attributes.shareholder.phone')"
              label-for="phone"
              :error="getErrors(localShareholder, 'phone')"
            >
              <be-tel-input
                id="phone"
                v-model="localShareholder.phone"
                @change="clearErrors(localShareholder, 'phone')"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-lg-6">
            <!-- Adress 1 -->
            <be-form-group
              :label="$t('activerecord.attributes.shareholder.address1')"
              label-for="address-1"
              :error="getErrors(localShareholder, 'address1')"
            >
              <be-form-input
                id="address-1"
                v-model="localShareholder.address1"
                @change="clearErrors(localShareholder, 'address1')"
              />
            </be-form-group>

            <!-- Adress 2 -->
            <be-form-group
              :label="$t('activerecord.attributes.shareholder.address2')"
              label-for="address-2"
              :error="getErrors(localShareholder, 'address2')"
            >
              <be-form-input
                id="address-2"
                v-model="localShareholder.address2"
                @change="clearErrors(localShareholder, 'address2')"
              />
            </be-form-group>

            <div class="row no-gutters">
              <div class="col-12 col-md-3 col-lg-5">
                <div class="mr-md-2">
                  <!-- Zipcode -->
                  <be-form-group
                    :label="$t('activerecord.attributes.shareholder.zipcode')"
                    label-for="zipcode"
                    :error="getErrors(localShareholder, 'zipcode')"
                  >
                    <be-form-input
                      id="zipcode"
                      v-model="localShareholder.zipcode"
                      @change="clearErrors(localShareholder, 'zipcode')"
                    />
                  </be-form-group>
                </div>
              </div>

              <div class="col-12 col-md">
                <!-- City -->
                <be-form-group
                  :label="$t('activerecord.attributes.shareholder.city')"
                  label-for="city"
                  :error="getErrors(localShareholder, 'city')"
                >
                  <be-form-input
                    id="city"
                    v-model="localShareholder.city"
                    @change="clearErrors(localShareholder, 'city')"
                  />
                </be-form-group>
              </div>
            </div>

            <!-- Country -->
            <be-form-group
              :label="$t('activerecord.attributes.shareholder.country')"
              label-for="country"
              :error="getErrors(localShareholder, 'country')"
            >
              <be-form-select
                id="country"
                v-model="localShareholder.country"
                preset-options="countries"
                @change="clearErrors(localShareholder, 'country')"
              />
            </be-form-group>
          </div>
        </div>

        <!-- Notes -->
        <be-form-group
          :label="
            $t('companies.shareholder_registers.other_shareholder_information')
          "
          label-for="notes"
          :error="getErrors(localShareholder, 'notes')"
        >
          <be-form-textarea
            id="notes"
            v-model="localShareholder.notes"
            rows="3"
            @change="clearErrors(localShareholder, 'notes')"
          />
        </be-form-group>
      </div>

      <div class="card-footer d-flex justify-content-end">
        <be-button type="submit" variant="primary" :loading="isSaving">
          {{ $t("buttons.titles.save") }}
        </be-button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    shareholder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      localShareholder: this.cloneDeep(this.shareholder),
    };
  },

  methods: {
    async saveShareholder() {
      this.isSaving = true;

      try {
        const { data } = await axios.patch(
          this.url(
            `/shareholder_register/shareholders/${this.localShareholder.id}`
          ),
          { shareholder: this.localShareholder }
        );

        this.localShareholder = data;
      } catch (error) {
        if (error.response.status === 422) {
          this.localShareholder = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
