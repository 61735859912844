<template>
  <div class="row">
    <div class="col-12">
      <h2>
        {{
          $t("components.onboarding.quotation.product_suggestion_w_org_type", {
            organization_type: $t(
              `models.company.type_of_organizations.${organizationType}`
            ),
          })
        }}
      </h2>
    </div>

    <div class="col-12 col-lg-5 mb-5 mr-lg-5 mb-lg-0">
      <p class="mb-4">
        {{
          $t("components.onboarding.quotation.product_suggestion_description", {
            package_title: lowerCasedPackageTitle,
          })
        }}
      </p>

      <h3 class="mb-2">
        {{ $t(`components.onboarding.quotation.${organizationType}_package`) }}
      </h3>

      <h4 class="text-primary mb-2">
        {{ $t("components.onboarding.free_30_days") }}
      </h4>

      <p>
        {{
          $t(
            `components.onboarding.quotation.${organizationType}_package_description`
          )
        }}
      </p>

      <ul class="list-unstyled">
        <li class="d-flex align-items-center mb-2">
          <span class="h3 mb-0">
            <i class="fas fa-check-circle text-success mr-2" />
          </span>
          {{ $t("components.onboarding.board_portal") }}
        </li>

        <li
          v-for="feature in quotationPackage.features"
          :key="`quotation-package-feature-${feature.id}`"
          class="d-flex align-items-center mb-2"
        >
          <span class="h3 mb-0">
            <i class="fas fa-check-circle text-success mr-2" />
          </span>
          {{ $t(`models.feature.features.${feature.name}.title`) }}
        </li>
      </ul>
    </div>

    <div class="col-12 col-lg-6 mb-5 mb-lg-0">
      <info-card>
        <template #title>
          {{
            $t("components.onboarding.quotation.prices_and_quotation_request")
          }}
        </template>

        <template #text>
          <p>
            <i18n-t
              keypath="components.onboarding.quotation.prices_request_description"
              tag="p"
              class="mt-3"
            >
              <template #short_organization_type>
                {{ lowerCasedShortOrgType }}
              </template>

              <template #link>
                <be-link :href="$config.WEBSITE_URLS.PRICE_PAGE_URL">
                  {{ $t("components.onboarding.quotation.price_link") }}
                </be-link>
              </template>
            </i18n-t>
          </p>

          <i18n
            tag="p"
            path="components.onboarding.quotation.contact_description_w_link"
          >
            <template #link>
              <be-link href="mailto:sales@boardeaser.com">
                sales@boardeaser.com
              </be-link>
            </template>
          </i18n>
        </template>
      </info-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InfoCard from "@/components/shared/InfoCard.vue";

export default {
  components: { InfoCard },

  props: {
    packages: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      organizationType: "company/getOrganizationType",
    }),

    lowerCasedPackageTitle() {
      return this.$i18n
        .t(`components.onboarding.quotation.${this.organizationType}_package`)
        .toLowerCase();
    },

    lowerCasedShortOrgType() {
      return this.$i18n
        .t(
          `components.onboarding.quotation.${this.organizationType}_short_organization_type`
        )
        .toLowerCase();
    },

    quotationPackage() {
      return this.packages.find(
        (featurePackage) => featurePackage.title == "quotation"
      );
    },
  },
};
</script>
