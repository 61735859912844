<template>
  <be-form-group :label="$t('components.meetings.suggestions.header')">
    <template
      v-for="suggestion in activeSuggestions"
      :key="getSuggestionKey(suggestion)"
    >
      <div class="p-0 pb-3 container-fluid">
        <date-time-range-picker
          v-model:start-at="suggestion.start_at"
          v-model:end-at="suggestion.end_at"
          :minutes-step="minutesStep"
          not-before-now
          :disabled="disabled"
        >
          <template #right>
            <div class="col-auto align-self-md-end align-self-lg-center">
              <be-button
                v-if="!disabled"
                size="sm"
                block
                variant="danger"
                @click="removeSuggestion(suggestion)"
              >
                <div class="text-center d-xs-block d-sm-block d-md-none">
                  {{ $t("components.meetings.suggestions.remove_suggestion") }}
                </div>

                <div
                  class="d-none d-xs-none d-sm-none d-md-block justify-content-center"
                >
                  <i class="fa fa-times" />
                </div>
              </be-button>
            </div>
          </template>
        </date-time-range-picker>
      </div>
    </template>
  </be-form-group>
</template>

<script>
export default {
  props: {
    suggestions: {
      type: Array,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },

    minutesStep: {
      type: Number,
      default: 5,
    },
  },

  emits: ["update:suggestions"],

  computed: {
    activeSuggestions() {
      return this.suggestions.filter((suggestion) => !suggestion._destroy);
    },
  },

  methods: {
    getSuggestionKey(suggestion) {
      if (suggestion.id) {
        return suggestion.id;
      } else {
        return (
          suggestion.start_at.toString() + "__" + suggestion.end_at.toString()
        );
      }
    },

    removeSuggestion(suggestion) {
      const suggestions = this.cloneDeep(this.suggestions);
      const index = suggestions.findIndex(
        (existing) =>
          (existing.id && existing.id === suggestion.id) ||
          (existing.uuid && existing.uuid === suggestion.uuid)
      );

      if (index !== -1) {
        if (suggestion.created_at) {
          suggestions[index]._destroy = true;
        } else {
          suggestions.splice(index, 1);
        }
        this.$emit("update:suggestions", suggestions);
      }
    },
  },
};
</script>
