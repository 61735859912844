<template>
  <be-form-group
    :error="getErrors(contract, terminationPeriodErrorKeys)"
    label-for="termination-date"
    :label="$t('activerecord.attributes.contract.termination_date')"
  >
    <be-form-datepicker
      id="termination-date"
      v-model="terminationDate"
      name="contract[termination_date]"
      :disabled="contract.editing_disabled"
      :state="validationState(contract, terminationPeriodErrorKeys)"
      @input="clearErrors(contract, terminationPeriodErrorKeys)"
    />
  </be-form-group>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      terminationDate: this.contract.termination_date || null,
    };
  },

  computed: {
    terminationPeriodErrorKeys() {
      return [
        "termination_date_out_of_range",
        "termination_date_too_small",
        "termination_date_too_big",
        "invalid_termination_date",
      ];
    },
  },
};
</script>
