<template>
  <div>
    <div class="card mb-2">
      <div class="card-header border-bottom-0 rounded py-lg-0">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              href="#public-archive"
              role="tab"
              data-toggle="tab"
              aria-controls="public_archive"
            >
              {{ $t("components.template_packages.nav.tabs.public_archive") }}

              <be-badge variant="light" pill>
                {{ publicTemplates.length + privateTemplates.length }}
              </be-badge>
            </a>
          </li>

          <li class="nav-item mr-md-auto">
            <a
              class="nav-link"
              href="#saved-templates"
              role="tab"
              data-toggle="tab"
              aria-controls="saved_templates"
              aria-selected="true"
            >
              {{ $t("components.template_packages.nav.tabs.saved_templates") }}

              <be-badge variant="light" pill>
                {{ savedTemplates.length }}
              </be-badge>
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              href="#user-templates"
              role="tab"
              data-toggle="tab"
              aria-controls="user_templates"
              aria-selected="true"
            >
              {{ $t("components.template_packages.nav.tabs.user_templates") }}

              <be-badge variant="light" pill>
                {{ userTemplates.length }}
              </be-badge>
            </a>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              href="#user-published-templates"
              role="tab"
              data-toggle="tab"
              aria-controls="user_published_templates"
              aria-selected="true"
            >
              {{
                $t(
                  "components.template_packages.nav.tabs.user_published_templates"
                )
              }}

              <be-badge variant="light" pill>
                {{ publishedTemplates.length }}
              </be-badge>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content">
      <div id="public-archive" class="tab-pane active">
        <public-template-container />
      </div>

      <div id="user-published-templates" class="tab-pane" role="tabpanel">
        <user-published-templates />
      </div>

      <div id="user-templates" class="tab-pane" role="tabpanel">
        <user-templates />
      </div>

      <div id="saved-templates" class="tab-pane" role="tabpanel">
        <user-saved-templates />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PublicTemplateContainer from "./PublicTemplateContainer.vue";
import UserPublishedTemplates from "./UserPublishedTemplates.vue";
import UserTemplates from "./UserTemplates.vue";
import UserSavedTemplates from "./UserSavedTemplates.vue";

export default {
  components: {
    PublicTemplateContainer,
    UserPublishedTemplates,
    UserTemplates,
    UserSavedTemplates,
  },

  props: {
    initialTemplates: {
      type: Array,
      required: true,
    },

    initialUserTemplates: {
      type: Array,
      required: true,
    },

    initialSavedTemplates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "public-archive",
    };
  },

  computed: {
    ...mapGetters({
      publicTemplates: "template_packages/publicTemplates",
      privateTemplates: "template_packages/privateTemplates",
      publishedTemplates: "template_packages/publishedTemplates",
      savedTemplates: "template_packages/savedTemplates",
      userTemplates: "template_packages/userTemplates",
    }),
  },

  mounted() {
    this.$store.commit("template_packages/load", this.initialTemplates);
    this.$store.commit(
      "template_packages/loadUserTemplates",
      this.initialUserTemplates
    );
    this.$store.commit(
      "template_packages/loadSavedTemplates",
      this.initialSavedTemplates
    );
  },

  methods: {
    changeTab(newTab) {
      this.activeTab = newTab;
    },
  },
};
</script>
