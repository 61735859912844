<template>
  <div class="mb-4">
    <be-alert v-if="loading" variant="info" loading>
      {{
        $t("components.annual_reports.visualby_annual_report_fetcher.loading")
      }}
    </be-alert>

    <be-button
      v-else-if="hasLockedDocuments"
      v-be-modal="`financial-statements-approval-modal`"
      variant="outline-primary"
      class="mt-3"
    >
      {{ $t("models.annual_report.documents.certification") }}
    </be-button>

    <be-modal
      id="financial-statements-approval-modal"
      size="xl"
      :title="$t('models.annual_report.documents.certification')"
      body-class="p-0"
      ok-only
      use-show
      @ok="fetchDocument"
    >
      <div id="financial-statements-approval"></div>
    </be-modal>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: {
    annualReport: {
      type: Object,
      required: true,
    },
  },

  data() {
    return { failed: false, loading: true, fetching: false, document: null };
  },

  computed: {
    hasLockedDocuments() {
      return !!this.document?.lockedTime;
    },

    previousUuid() {
      return this.annualReport.annual_report_ixbrl_source_uuid;
    },
  },

  async mounted() {
    try {
      const { data } = await axios.get(
        this.url(`/annual_reports/${this.annualReport.id}/visualby_document`)
      );

      this.document = data.items.find(({ uuid }) => uuid == this.previousUuid);
      if (!this.document) {
        this.failed = true;
      }
    } catch {
      this.failed = true;
    } finally {
      await this.loadFinancialStatementsApproval();
      this.loading = false;
    }
  },

  methods: {
    ...mapMutations({ setAnnualReport: "annual_reports/setAnnualReport" }),

    async loadFinancialStatementsApproval() {
      this.loading = true;
      const controller = await window.wzGetController();
      if (controller && controller.addFinancialStatementsApproval) {
        await controller.addFinancialStatementsApproval(
          "#financial-statements-approval",
          this.company.corporate_group_uuid,
          this.annualReport.annual_report_ixbrl_source_uuid
        );
      } else {
        this.failed = true;
      }
    },

    async fetchDocument() {
      const type = this.formatType(this.document.type);
      const time = this.formatDate(this.document.lockedTime);
      const filename = `${type}_${time}_w_certification.ixbrl`;

      this.loading = true;
      try {
        const { data } = await axios.post(
          this.url(`/annual_reports/${this.annualReport.id}/visualby_document`),
          {
            key: "annual_report_w_certification",
            uuid: this.annualReport.annual_report_ixbrl_source_uuid,
            filename,
          }
        );
        this.setAnnualReport(data.annual_report);
        this.loading = false;
      } catch {
        // There will be a flash message, and we don't need to do anything else
        this.loading = false;
      }
    },

    formatDate(dateString) {
      if (dateString) {
        return this.$d(this.parseDate(dateString), "dateTime");
      } else {
        return "-";
      }
    },

    formatType(typeString) {
      if (typeString.startsWith("K3_GROUP")) {
        return this.$t(
          "components.annual_reports.visualby_annual_report_fetcher.k3_group"
        );
      }
      return this.$t(
        "components.annual_reports.visualby_annual_report_fetcher.unknown_type"
      );
    },
  },
};
</script>
