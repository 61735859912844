<template>
  <div v-if="show" class="row">
    <div class="col">
      <be-alert variant="info">
        {{
          $t(
            "components.meetings.material.minutes.unlock_status.unlocking_in_progress",
            {
              user_names: userNames,
            }
          )
        }}

        <div v-if="isInvolved && userStatus">
          <em class="small" v-text="userStatus" />
        </div>
      </be-alert>
    </div>

    <div class="col-auto d-md-flex align-items-center">
      <template v-if="isInvolved">
        <template v-if="userApprovalState !== 'none'">
          <be-button
            :variant="approved ? 'danger' : 'success'"
            @click="makeResponse"
          >
            {{
              approved
                ? $t(
                    "components.meetings.material.minutes.unlock_status.reject_unlock"
                  )
                : $t(
                    "components.meetings.material.minutes.unlock_status.approve_unlock"
                  )
            }}
          </be-button>
        </template>

        <template v-else>
          <be-button variant="success" @click="makeResponse(null, true)">
            {{
              $t(
                "components.meetings.material.minutes.unlock_status.approve_unlock",
                { date: $d(new Date(responseAt), "mini") }
              )
            }}
          </be-button>

          <be-button variant="danger" @click="makeResponse(null, false)">
            {{
              $t(
                "components.meetings.material.minutes.unlock_status.reject_unlock",
                { date: $d(new Date(responseAt), "mini") }
              )
            }}
          </be-button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/mixins/RequestHandler";

export default {
  mixins: [RequestHandler],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    minutes: {
      type: Object,
      required: true,
    },

    redirectOnUnlock: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["unlocked"],

  data() {
    return {
      isInvolved: false,
      approvalState: "none",
      approved: false,
      responseAt: null,
      unlockResponseId: null,
      show: false,
    };
  },

  computed: {
    userApprovalState: {
      get() {
        return this.approvalState;
      },

      set(value) {
        this.approvalState = value;
        this.approved = value === "approved";
      },
    },

    userStatus() {
      if (this.userApprovalState === "none") {
        return "";
      }
      return this.approved
        ? this.$t(
            "components.meetings.material.minutes.unlock_status.approved_at",
            {
              date: this.$d(new Date(this.responseAt), "date"),
            }
          )
        : this.$t(
            "components.meetings.material.minutes.unlock_status.rejected_at",
            {
              date: this.$d(new Date(this.responseAt), "date"),
            }
          );
    },

    userNames() {
      let names =
        this.meeting.process.active_minutes_unlocking_response_receiver_names;
      if (this.isInvolved && this.userApprovalState === "approved") {
        names = names.filter((name) => name !== this.$currentUser.name);
      }
      return names.join(", ");
    },

    unlockResponseUrl() {
      return `${this.meeting.paths.base}/minutes/unlocking_responses/${this.unlockResponseId}`;
    },
  },

  async mounted() {
    return this.getUnlockingRequest();
  },

  methods: {
    async getUnlockingRequest() {
      try {
        const { data } = await axios.get(
          `${this.meeting.paths.base}/minutes/unlocking_responses`
        );
        const { id, approved_at, rejected_at } = data;

        this.show = true;

        if (id) {
          this.isInvolved = true;
          this.unlockResponseId = id;
        } else {
          return;
        }

        if (approved_at) {
          this.userApprovalState = "approved";
          this.responseAt = approved_at;
        } else if (rejected_at) {
          this.userApprovalState = "rejected";
          this.responseAt = rejected_at;
        } else {
          this.userApprovalState = "none";
          this.responseAt = null;
        }
      } catch (error) {
        if (error.response.status === 404) {
          // Why: the user might have unlocked the minutes already and switched tabs
          // Or someone else either completed/removed the unlocking or removed the user from unlocking request
          this.show = false;
        } else {
          this.handleError(error);
        }
      }
    },

    async makeResponse(event, state) {
      let approval = !this.approved;
      // if no response have been made, second param is response bool
      if (state !== undefined) {
        approval = state;
      }

      try {
        const { data } = await this.makeRequest(
          {
            method: "patch",
            url: this.unlockResponseUrl,

            data: {
              id: this.unlockResponseId,
              response: approval ? "approved" : "rejected",
            },
          },
          {
            confirm: true,
            confirmMessage: this.confirmMessage(approval),
            confirmButtonText: this.confirmButtonText(approval),
            description: this.confirmDescription(approval),
          }
        );

        // The sent response unlocked the minutes
        if (data.unlocked) {
          this.$emit("unlocked");
          this.show = false;

          if (this.redirectOnUnlock) {
            window.location = this.meeting.paths.base;
          }
        }

        this.userApprovalState = approval ? "approved" : "rejected";
        this.responseAt = new Date();
      } catch (error) {
        this.handleError(error);
      }
    },

    confirmMessage(approve) {
      return approve
        ? this.$t(
            "components.meetings.material.minutes.unlock_status.confirm_approval"
          )
        : this.$t(
            "components.meetings.material.minutes.unlock_status.confirm_reject"
          );
    },

    confirmButtonText(approve) {
      return approve
        ? this.$t(
            "components.meetings.material.minutes.unlock_status.approve_unlock_button_text"
          )
        : this.$t(
            "components.meetings.material.minutes.unlock_status.reject_unlock_button_text"
          );
    },

    confirmDescription(approve) {
      return approve
        ? this.$t(
            "components.meetings.material.minutes.unlock_status.approve_unlock_description"
          )
        : "";
    },
  },
};
</script>
