<template>
  <div>
    <h3 class="mb-3">
      {{ $t("views.companies.evaluation_forms.templates_table.title") }}
    </h3>

    <div v-if="evaluationForms.length > 0" class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("activerecord.attributes.evaluation_form.title") }}</th>

            <th class="hidden-phone text-nowrap">
              {{ $t("activerecord.attributes.evaluation_form.created_at") }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <template-row
            v-for="form in evaluationForms"
            :key="form.id"
            :evaluation-form="form"
            @template-removed="removeTemplate"
          />
        </tbody>
      </table>
    </div>

    <be-alert v-else variant="info" :show="true">
      {{
        $t(
          "views.companies.evaluation_forms.templates_table.no_templates_exists"
        )
      }}
    </be-alert>
  </div>
</template>

<script>
import TemplateRow from "./TemplateRow.vue";

export default {
  components: {
    TemplateRow,
  },

  props: {
    initialEvaluationForms: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      evaluationForms: this.initialEvaluationForms,
    };
  },

  methods: {
    removeTemplate(removedEvaluationFormTemplate) {
      let idx = this.evaluationForms.findIndex(
        (evaluationForm) =>
          evaluationForm.id == removedEvaluationFormTemplate.id
      );

      if (idx > -1) {
        this.evaluationForms.splice(idx, 1);
      }
    },
  },
};
</script>
