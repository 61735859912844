<template>
  <div class="card">
    <div class="card-body">
      <template v-if="activities && activities.length > 0">
        <be-table
          :items="activities"
          :fields="fields"
          sort-by="activity.created_at"
          sort-desc
        >
          <template #avatar="{ item }">
            <user-avatar :avatar="item.activity.avatar" />
          </template>

          <template #title="{ item }">
            <span v-if="item.contract.deleted_at">
              {{ `#${item.contract.contract_number} - ${item.contract.title}` }}
            </span>

            <span v-else>
              <a :href="url(`/contracts/${item.contract.id}`)">
                {{
                  `#${item.contract.contract_number} - ${item.contract.title}`
                }}
              </a>
            </span>
          </template>

          <template #icons="{ item }">
            <i
              v-if="item.contract.deleted_at"
              v-be-tooltip="
                $t('components.contracts.notifications.removed_contract')
              "
              class="far fa-ban fa-fw text-muted"
            />
          </template>

          <template #badge="{ item }">
            <be-badge v-if="showBadge(item.contract)" variant="danger" pill>
              1
            </be-badge>
          </template>

          <template #action_type="{ item }">
            {{ $t(`public_activity.limited.${item.activity.key}`) }}
          </template>

          <template #[`activity.created_at`]="{ item }">
            {{ $d(new Date(item.activity.created_at), "dateTime") }}
          </template>

          <template #expand="{ item }">
            <be-button
              v-if="item.activity.changes.length"
              v-be-modal="`expand-notifications-${item.activity.id}`"
              variant="outline-primary"
              size="sm"
              inline
            >
              {{ $t("buttons.titles.show_more") }}
            </be-button>

            <be-modal
              :id="`expand-notifications-${item.activity.id}`"
              size="md"
              :title="$t('components.contracts.notifications.title')"
              :ok-title="$t('buttons.titles.close')"
              ok-variant="light"
              ok-only
            >
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td></td>

                    <td>
                      <strong>
                        {{
                          $t(
                            "components.contracts.notifications.previous_value"
                          )
                        }}
                      </strong>
                    </td>

                    <td>
                      <strong>
                        {{ $t("components.contracts.notifications.new_value") }}
                      </strong>
                    </td>
                  </tr>

                  <tr
                    v-for="(changes, index) in item.activity.changes"
                    :key="index"
                  >
                    <th>{{ changes.heading }}</th>

                    <td>{{ changes.before }}</td>

                    <td>{{ changes.after }}</td>
                  </tr>
                </tbody>
              </table>
            </be-modal>
          </template>
        </be-table>
      </template>

      <be-alert v-else variant="info">
        {{ $t("components.contracts.notifications.no_notifications") }}
      </be-alert>
    </div>

    <div class="card-footer">
      <be-button
        variant="outline-secondary"
        :disabled="localUnreadActivities == 0"
        :loading="loading"
        @click="markAsRead"
      >
        {{ $t("components.contracts.notifications.mark_all_as_read") }}
      </be-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    initialContracts: {
      type: Array,
      required: false,
      default: () => [],
    },

    userUnreadActivities: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  data() {
    return {
      contracts: this.cloneDeep(this.initialContracts),
      loading: false,
      localUnreadActivities: this.userUnreadActivities,
    };
  },

  computed: {
    activities() {
      let activities = this.contracts.map((contract) => {
        return contract.activities.map((activity) => {
          return {
            activity: this.serializeActivity(activity),
            contract: this.serializeContract(contract),
          };
        });
      });

      return activities.flat();
    },

    fields() {
      return [
        {
          key: "avatar",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
        {
          key: "title",
          label: this.translateAttribute("contract", "title"),
          sortable: false,
        },
        {
          key: "icons",
          label: "",
          class: "col-shrink text-muted",
          sortable: false,
        },
        {
          key: "badge",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
        {
          key: "action_type",
          label: this.$i18n.t("components.contracts.notifications.action"),
          class: "col-shrink",
          sortable: false,
        },
        {
          key: "activity.created_at",
          label: this.translateAttribute("contract", "created_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "expand",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    async markAsRead() {
      this.loading = true;
      try {
        const response = await axios.post(this.url("/contracts/mark_as_read"));

        this.contracts = response.data;
        this.localUnreadActivities = 0;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    showBadge(contract) {
      return contract.has_not_read.includes(this.$currentUser.id);
    },

    // Serialize the activity to only use what we really need in the table
    serializeActivity(activity) {
      return {
        id: activity.id,
        avatar: activity.avatar,
        key: activity.key,
        created_at: activity.created_at,
        changes: activity.changes,
      };
    },

    // Serialize the contract to only use what we really need in the table
    serializeContract(contract) {
      return {
        id: contract.id,
        title: contract.title,
        contract_number: contract.contract_number,
        deleted_at: contract.deleted_at,

        has_not_read: contract.readings
          .filter((reading) => !reading.read)
          .map((reading) => reading.user_id)
          .flat(),
      };
    },
  },
};
</script>
