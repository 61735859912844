<template>
  <div>
    <be-table
      :items="userTemplates"
      :fields="fields"
      @row-clicked="check($event)"
    >
      <template #checkbox="{ item }">
        <be-form-checkbox
          :id="`template-${item.id}-${item.template_type}`"
          :checked="checked.includes(item.sgid)"
          @change="check(item)"
        />

        <input type="hidden" :value="item.selected ? item.sgid : null" />
      </template>

      <template #title="{ item }">
        <template v-if="item.title">
          {{ item.title }}
        </template>

        <template v-else-if="item.content">
          {{ item.content }}
        </template>
      </template>

      <template #template_type="{ item }">
        {{ $t(`models.template_package.types.${item.template_type}`) }}
      </template>
    </be-table>

    <input
      v-for="checkedSgid in checked"
      :key="checkedSgid"
      type="hidden"
      name="template_package[package_items][]"
      :value="checkedSgid"
    />
  </div>
</template>

<script>
export default {
  props: {
    userTemplates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      checked: [],

      fields: [
        {
          key: "checkbox",
          label: "",
          class: "col-shrink",
        },
        {
          key: "title",
          label: this.translateAttribute("template_package", "title"),
          sortable: true,
        },
        {
          key: "template_type",
          label: this.translateAttribute("template_package", "type"),
          class: "col-shrink",
          sortable: true,
        },
      ],

      loading: false,
    };
  },

  mounted() {
    this.userTemplates.forEach((template) => {
      if (template.selected) {
        this.checked.push(template.sgid);
      }
    });
  },

  methods: {
    check(template) {
      const idx = this.checked.findIndex((checkedSgid) => {
        return checkedSgid == template.sgid;
      });

      if (idx != -1) {
        this.checked.splice(idx, 1);
      } else {
        this.checked.push(template.sgid);
      }
    },
  },
};
</script>
