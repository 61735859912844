<template>
  <div class="card">
    <div class="card-body">
      <div class="row justify-content-between">
        <div class="col-12 col-lg-7">
          <section class="mb-6">
            <h1>{{ $t("components.onboarding.steps.welcome.title") }}!</h1>

            <div
              v-dompurify-html="
                $t('components.onboarding.introduction.welcome_text_html')
              "
            />
          </section>

          <h3 class="mb-3">
            {{
              $t("components.onboarding.introduction.customize_your_account")
            }}
          </h3>

          <be-list-group-item class="p-4">
            <div class="row no-gutters">
              <div class="col-auto d-none d-md-block h5">
                <i
                  class="fas fa-check-circle mr-3"
                  :class="{
                    'text-success': selectedOrganizationType != null,
                    invisible: selectedOrganizationType == null,
                  }"
                />
              </div>

              <div class="col">
                <h4>
                  {{
                    $t(
                      "components.onboarding.introduction.choose_organization_type"
                    )
                  }}
                </h4>

                <p>
                  {{
                    $t(
                      "components.onboarding.introduction.organization_type_description"
                    )
                  }}
                </p>

                <be-form-group
                  label-for="onboarding-organization-type"
                  :label="
                    $t('activerecord.attributes.company.type_of_organization')
                  "
                  required
                >
                  <be-form-select
                    id="onboarding-organization-type"
                    v-model="selectedOrganizationType"
                    :options="formattedOrganizationTypes"
                    required
                  />
                </be-form-group>
              </div>
            </div>
          </be-list-group-item>

          <be-list-group-item class="p-4">
            <task-container
              action-key="company_logo_uploaded"
              section-type="introduction"
              :title="
                $t('components.onboarding.introduction.upload_company_logo')
              "
              :onboarding-object="$currentCompany"
            >
              <template #text>
                <p class="mb-2">
                  {{
                    $t("components.onboarding.introduction.logo_description")
                  }}
                </p>

                <single-file-uploader
                  :key="`company-${$currentCompany.id}-logo-${$currentCompany.updated_at}`"
                  :aws-url="awsUrl"
                  :accepted-types="acceptedFileTypes"
                  :file="logo"
                  :remove="remove_logo"
                  @file-updated="fileUpdated"
                  @update:remove="fileRemoved"
                  @uploading="(value) => (uploadingFiles = value)"
                />
              </template>
            </task-container>
          </be-list-group-item>
        </div>

        <div
          class="d-none d-lg-block col-lg-5 d-lg-flex align-items-center justify-content-center"
        >
          <div class="w-75">
            <be-img :src="imageSrc('onboarding/laptop_round.png')" />
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-md-flex justify-content-end">
        <be-button
          v-be-tooltip="{
            title: $t('components.onboarding.introduction.next_step_tooltip'),
            disabled: !disableNextButton,
          }"
          variant="outline-primary"
          size="lg"
          :disabled="disableNextButton"
          @click="showNextSlide()"
        >
          {{ nextSlideButtonText }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

import { IMAGE_TYPES } from "@/constants/file-types";
import TaskContainer from "@/components/onboarding/TaskContainer.vue";

export default {
  components: {
    TaskContainer,
  },

  props: {
    awsUrl: {
      type: String,
      required: true,
    },
  },

  emits: ["set-slide"],

  data() {
    return {
      logo: {},
      remove_logo: false,
      savingLogo: false,
      selectedOrganizationType: null,
      slide: null,
    };
  },

  computed: {
    ...mapGetters({
      onboardingProgress: "company/getOnboardingProgress",
      organizationType: "company/getOrganizationType",
    }),

    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    formattedOrganizationTypes() {
      const organizationTypesExceptOther =
        this.$config.ORGANIZATION_TYPES.filter(
          (organizationType) => organizationType !== "other"
        );

      const organizationTypes = organizationTypesExceptOther
        .map((key) => {
          return {
            text: this.$i18n.t(`models.company.type_of_organizations.${key}`),
            value: key,
          };
        })
        .sort((a, b) => a.text.localeCompare(b.text));

      organizationTypes.push({
        text: this.$i18n.t("models.company.type_of_organizations.other"),
        value: "other",
      });

      return organizationTypes;
    },

    nextSlideButtonText() {
      return this.savingLogo
        ? this.$i18n.t("buttons.states.loading")
        : this.$i18n.t("buttons.titles.next_step");
    },

    disableNextButton() {
      return this.savingLogo || this.selectedOrganizationType == null;
    },
  },

  watch: {
    $currentCompany(value) {
      this.logo = this.cloneDeep(value?.logo || {});
      this.selectedOrganizationType = value?.type_of_organization;
    },
  },

  mounted() {
    this.selectedOrganizationType = this.organizationType;
    this.logo = this.cloneDeep(this.$currentCompany.logo) || {};
  },

  methods: {
    async showNextSlide() {
      try {
        const company = {
          type_of_organization: this.selectedOrganizationType,
          remove_logo: this.remove_logo,
        };
        if (this.logo?.upload_state && this.logo?.upload_state !== "saved") {
          company.logo = this.logo;
        }
        const { data } = await axios.put(this.url("/"), {
          company: company,
        });

        this.$store.commit("company/setCompany", data);
        this.$emit("set-slide", "feature-select");
      } catch (error) {
        this.handleError(error);
      }
    },

    async updateProgress(sectionType, actionKey, completed = true) {
      try {
        const { data } = await axios.post(this.url("/onboarding/progress"), {
          company: {
            section_type: sectionType,
            action_key: actionKey,
            completed: completed,
          },
        });

        this.$store.commit("company/setCompany", data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async fileUpdated(file) {
      this.logo = file;
      this.savingLogo = true;

      try {
        const { data } = await axios.put(this.url(""), {
          company: {
            logo: this.logo,
            type_of_organization: this.selectedOrganizationType,
          },
        });

        this.updateProgress("introduction", "company_logo_uploaded", true);

        this.logo = data.logo;
        this.$store.commit("company/setCompany", data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.savingLogo = false;
      }
    },

    async fileRemoved(remove) {
      this.remove_logo = remove;

      if (this.remove_logo) {
        debounce(this.removeLogo, 1000)();
      }
    },

    async removeLogo() {
      try {
        const { data } = await axios.put(this.url(""), {
          company: {
            remove_logo: true,
            type_of_organization: this.selectedOrganizationType,
          },
        });

        this.updateProgress("introduction", "company_logo_uploaded", false);

        this.logo = data.logo || {};
        this.remove_logo = data.remove_logo || false;
        this.$store.commit("company/setCompany", data);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.savingLogo = false;
      }
    },
  },
};
</script>
