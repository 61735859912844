<template>
  <div>
    <template v-if="canUploadDocument('annual_report_w_certification')">
      <template
        v-if="
          showVisualbyFinancialStatementsApproval &&
          !annualReportWCertificationDocument
        "
      >
        {{
          $t(
            "components.annual_reports.digital_delivery_tab.certification_description_visualby"
          )
        }}

        <visualby-financial-statements-approval
          :annual-report="annualReport"
          class="mt-3"
        />
      </template>

      <template v-if="showUploader && !annualReportWCertificationDocument">
        <template v-if="annualReport.no_separate_certificate">
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.certification_uploaded_before_meeting"
            )
          }}
        </template>

        <template v-else>
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.certification_description_upload"
            )
          }}
        </template>

        <annual-report-documents
          document-key="annual_report_w_certification"
          class="my-3"
        />
      </template>
    </template>

    <table
      v-if="annualReportWCertificationDocument"
      :key="`preparation-document-${annualReportWCertificationDocument.id}`"
      class="table table-hover mb-2"
    >
      <thead>
        <tr>
          <th colspan="3">
            {{
              $t(
                "models.annual_report.documents.annual_report_w_certification_ixbrl"
              )
            }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td colspan="2">
            <document-link
              :document-id="annualReportWCertificationDocument.id"
              :filename="filenameWithSize(annualReportWCertificationDocument)"
            />
          </td>

          <td class="text-right">
            <be-button
              v-if="canRemoveDocument(annualReportWCertificationDocument)"
              v-be-tooltip="$t('buttons.titles.remove')"
              variant="danger"
              size="sm"
              icon="fa-times"
              @click="confirmRemoveDocument(annualReportWCertificationDocument)"
            />
          </td>
        </tr>
      </tbody>
    </table>

    <be-alert v-else-if="!annualReportIxbrlDocument" variant="info">
      {{ $t("components.annual_reports.upload_tab.not_uploaded_info") }}
    </be-alert>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import VisualbyFinancialStatementsApproval from "./VisualbyFinancialStatementsApproval.vue";

export default {
  components: {
    VisualbyFinancialStatementsApproval,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      saving: false,
    };
  },

  computed: {
    showUploader() {
      return this.annualReport.annual_report_ixbrl_source_type === "upload";
    },

    showVisualbyFinancialStatementsApproval() {
      return this.annualReport.annual_report_ixbrl_source_type === "visualby";
    },
  },
};
</script>
