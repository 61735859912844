<template>
  <tr v-if="localShareholder.share_count > 0">
    <td v-if="localShareholder.company_name">
      {{ localShareholder.company_name }}
    </td>

    <td v-else>{{ localShareholder.name }}</td>

    <td class="text-right">{{ sharesBefore(shareDistribution) }}</td>

    <td>
      <be-form-group
        :error="getErrors(transaction, ['number_of_shares'])"
        class="mb-0"
      >
        <be-form-input
          v-model="localShareholder.addedSharesByType[shareTypeKey]"
          type="number"
          min="0"
          :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][number_of_shares]`"
          required
          @change="
            updateShareholder();
            clearErrors(transaction, ['number_of_shares']);
          "
        />
      </be-form-group>
    </td>

    <td class="text-right">{{ totalShares }}</td>

    <td>
      {{ $t(`models.shareholder_transaction.share_types.${shareTypeKey}`) }}
    </td>

    <td>
      <input
        id="id"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][id]`"
      />

      <input
        id="buyer_id"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][buyer_id]`"
        :value="localShareholder.id"
      />

      <input
        id="share_price"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_price]`"
        :value="action.price"
      />

      <input
        id="share_type"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][share_type]`"
        :value="shareTypeKey"
      />

      <input
        id="action_type"
        type="hidden"
        :name="`shareholder_register_action[shareholder_transactions_attributes][${index}][action_type]`"
        :value="action.action_type"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },

    shareholder: {
      type: Object,
      required: true,
    },

    shareDistribution: {
      type: Object,
      required: true,
    },

    newTransactions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: ["update-shareholder"],

  data() {
    return {
      index: Math.round(Math.random() * Math.pow(10, 10)),
      share_setting: "new_shares",
      previousShareCount: this.sharesBefore(this.shareDistribution),
      shareTypeKey: Object.keys(this.shareDistribution)[0],
      localShareholder: this.cloneDeep(this.shareholder),
      transaction: null,
    };
  },

  computed: {
    totalShares() {
      let updatedShareCount = 0;

      if (
        this.valueNumeric(
          this.localShareholder.addedSharesByType[this.shareTypeKey]
        )
      ) {
        updatedShareCount =
          parseInt(this.sharesBefore(this.shareDistribution)) +
          parseInt(this.localShareholder.addedSharesByType[this.shareTypeKey]);
      } else if (!this.localShareholder.addedSharesByType[this.shareTypeKey]) {
        updatedShareCount = parseInt(this.sharesBefore(this.shareDistribution));
      }

      return updatedShareCount;
    },
  },

  watch: {
    shareholder: {
      handler() {
        this.localShareholder = this.cloneDeep(this.shareholder);
      },

      deep: true,
    },
  },

  created() {
    this.transaction = this.getTransaction();
  },

  methods: {
    sharesBefore(shareDistribution) {
      return shareDistribution[Object.keys(shareDistribution)[0]];
    },

    getTransaction() {
      return this.newTransactions.find(
        (transaction) =>
          transaction.id == null &&
          transaction.buyer &&
          transaction.buyer.id == this.localShareholder.id &&
          transaction.share_type == this.shareTypeKey
      );
    },

    valueNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },

    updateShareholder() {
      this.$emit("update-shareholder", this.localShareholder);
    },
  },
};
</script>
