<template>
  <div>
    <div v-if="startDate" class="row">
      <div class="col-12 col-md-4">
        <be-form-group label-for="termination-period">
          <template #label>
            {{ $t("activerecord.attributes.contract.termination_period") }}
            <i
              v-be-tooltip="
                $t('components.contracts.editor.termination_period_tooltip')
              "
              class="fal fa-question-circle ml-1"
            />
          </template>

          <be-form-input
            id="termination-period"
            v-model="terminationPeriod"
            name="contract[contract_type_attributes][termination_period]"
            type="number"
            :min="1"
            :disabled="contract.editing_disabled"
          />
        </be-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-4">
        <contract-termination-date :contract="contract" />
      </div>
    </div>
  </div>
</template>

<script>
import ContractTerminationDate from "./dates/ContractTerminationDate.vue";

export default {
  components: { ContractTerminationDate },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    startDate: {
      type: Date,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      terminationPeriod: this.contract.contract_type.termination_period || null,
    };
  },
};
</script>
