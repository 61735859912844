<template>
  <tr>
    <td>
      <input
        v-if="localCompliance.id"
        type="hidden"
        :name="complianceFieldName('id')"
        :value="localCompliance.id"
      />

      <input
        v-if="localCompliance.template_id"
        type="hidden"
        :name="complianceFieldName('template_id')"
        :value="localCompliance.template_id"
      />

      <i
        v-if="localCompliance.required_by_law"
        v-be-tooltip="$t('models.document.compliances.required_by_law')"
        class="fal fa-balance-scale"
      />

      <i
        v-else-if="isRecommended"
        v-be-tooltip="$t('components.compliances.configurations.recommended')"
        class="fal fa-heart"
      />
    </td>

    <td>
      <input
        v-if="localCompliance.required_by_law"
        type="hidden"
        :name="complianceFieldName('active')"
        checked
        value="true"
      />

      <input
        v-else
        type="hidden"
        :name="complianceFieldName('active')"
        :value="localChecked"
      />

      <be-form-checkbox v-model="localChecked" />

      <!-- set _destroy if not checked to remove it from database -->
      <input
        v-if="!localChecked"
        type="hidden"
        :name="complianceFieldName('_destroy')"
        :value="true"
      />
    </td>

    <td>
      {{ localCompliance.title }}
      <i
        v-if="isCustom"
        v-be-tooltip="$t('components.compliances.configurations.custom')"
        style="float: right"
        class="fal fa-lightbulb"
      />
    </td>

    <td>
      <be-form-select
        v-model="localCompliance.role"
        :name="complianceFieldName('role')"
        :options="roleOptions"
      />
    </td>

    <td class="col-shrink" />
  </tr>
</template>

<script>
const CUSTOM_COMPLIANCE_PREFIX = "custom-";

export default {
  props: {
    compliance: {
      type: Object,
      required: true,
    },

    lawLevel: {
      type: Number,
      required: true,
    },

    companyLevel: {
      type: Number,
      required: true,
    },

    roleOptions: {
      type: Array,
      required: true,
    },
  },

  emits: ["update:checked"],

  data() {
    return {
      localCompliance: this.cloneDeep(this.compliance),
    };
  },

  computed: {
    localChecked: {
      get() {
        return this.compliance.checked;
      },

      set(newValue) {
        this.$emit("update:checked", newValue);
      },
    },

    isRecommended() {
      return (
        this.localCompliance.law_level <= this.lawLevel ||
        this.localCompliance.company_level <= this.companyLevel
      );
    },

    isCustom() {
      return this.localCompliance.key.startsWith(CUSTOM_COMPLIANCE_PREFIX);
    },
  },

  methods: {
    tc(key) {
      // translation helper
      return this.$t(`documents.compliance.configurations.${key}`);
    },

    complianceFieldName(field) {
      return `company[compliance_attributes][${this.localCompliance.index}][${field}]`;
    },
  },
};
</script>
