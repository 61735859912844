<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="col-shrink"></th>

          <th>
            {{ $t("components.annual_reports.signatures_table.headers.name") }}
          </th>

          <th class="col-shrink"></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="signature in signatures" :key="`signature-${signature.id}`">
          <td>
            <user-avatar :user="userName(signature)" />
          </td>

          <td>
            {{ userName(signature) }}
            <div v-if="signature.title" class="small text-muted">
              {{ signature.title }}
            </div>
          </td>

          <td class="text-center col-shrink">
            <div
              v-if="hasSigned(signature)"
              v-be-tooltip="$d(new Date(signature.signed_at), 'dateTime')"
              class="text-success"
            >
              <i class="fas fa-check-circle text-success mr-1" />
              {{ $t("views.companies.documents.signatures.sign_list.signed") }}
            </div>

            <template v-else>
              <be-button
                v-if="showSendSigningReminder(signature)"
                variant="outline-secondary"
                size="sm"
                icon="fa-envelope"
                inline
                :loading="!document.assently_ready"
                :disabled="!document.assently_ready"
                @click="remindAbout(signature.user_id, document.key)"
              >
                {{ $t("buttons.titles.remind") }}
              </be-button>

              <i
                v-if="!hasSignatureUser(signature)"
                v-be-tooltip="$t('models.user.external_user')"
                class="fal fa-info-circle text-muted"
              />
            </template>

            <be-button
              v-if="showSigningButton(signature)"
              :href="url(`/documents/signatures/${signature.document_id}`)"
              variant="primary"
              rel="noreferrer noopener"
              size="sm"
              :loading="!!timer || !document.assently_ready"
              :disabled="!!timer || !document.assently_ready"
              @click="signDocument(signature)"
            >
              {{ $t("buttons.titles.sign") }}
            </be-button>

            <template v-if="showRejectedStatus(signature)">
              {{ $t("components.annual_reports.signature_rejected") }}
            </template>

            <i
              v-if="postponedSigningInformation(signature)"
              key="postponed-signing"
              v-be-tooltip="postponedSigningInformation(signature)"
              class="fal fa-info-circle text-muted"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-if="canCancelSignatures(document)" class="mt-1 text-right">
    <be-button
      size="sm"
      variant="outline-danger"
      :loading="cancelling"
      :disabled="cancelling"
      @click="confirmCancelRequest"
    >
      {{ $t("components.documents.signature_case.cancel_request") }}
    </be-button>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";

export default {
  mixins: [AnnualReportTabBase],

  props: {
    document: {
      type: Object,
      required: true,
    },

    signatures: {
      type: Array,

      default: () => [],
    },
  },

  data() {
    return {
      localUsers: [],
      timer: null,
      cancelling: false,
    };
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("esign") == this.document.id) {
      if (this.signatures) {
        const mySignature = this.findMySignature(this.signatures);

        if (mySignature) {
          this.startSignPolling(mySignature);
        }
      }
    }

    this.localUsers = this.cloneDeep(this.companyUsers);
  },

  methods: {
    findMySignature(signatures) {
      return signatures.find(
        (signature) => signature.user_id == this.$currentUser.id
      );
    },

    hasSigned(signature) {
      return !!signature.signed_at;
    },

    async remindAbout(userId, key) {
      this.signatureRequestReminder({
        annualReport: this.annualReport,
        key: key,
        userId: userId,
      });
    },

    async pollForSignedDocument(signature) {
      try {
        const response = await axios.get(
          this.url(
            `/documents/${signature.document_id}/signatures/${signature.id}/status`
          )
        );

        if (response.data == "signed") {
          // Clear polling timer
          clearInterval(this.timer);

          signature.signed_at = new Date();
        }
      } catch (error) {
        this.handleError(error, { sentryLog: false });
      }
    },

    async confirmCancelRequest() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("components.documents.signature_case.confirm_cancel_w_title", {
          title: this.document.title,
        })
      );

      if (isConfirmed) {
        this.cancelling = true;
        await axios.delete(this.document.paths.sign);
        await this.reloadAnnualReport(this.annualReport.id);
        this.cancelling = false;
      }
    },

    postponedSigningInformation(signature) {
      if (
        !signature.postpone_notification ||
        !this.hasSignatureUser(signature)
      ) {
        return null;
      }

      if (this.document.key === "annual_report") {
        return this.$t(
          "components.annual_reports.audit_tab.postponed_until_board_signed"
        );
      } else if (this.document.key === "board_statement") {
        return this.$t(
          "components.annual_reports.audit_tab.board_statement_signatures_postponed"
        );
      }
    },

    showSigningButton(signature) {
      return (
        this.can("add_signature", this.document.key) &&
        !signature.postpone_notification &&
        !signature.signed_at &&
        signature.user_id == this.$currentUser.id &&
        !signature.rejected_at
      );
    },

    showRejectedStatus(signature) {
      return !!signature.rejected_at;
    },

    hasSignatureUser(signature) {
      return this.localUsers.map((user) => user.id).includes(signature.user_id);
    },

    showSendSigningReminder(signature) {
      return (
        !signature.postpone_notification &&
        !signature.signed_at &&
        signature.user_id != this.$currentUser.id &&
        this.hasSignatureUser(signature)
      );
    },

    async signDocument(signature) {
      this.startSignPolling(signature);
    },

    startSignPolling(signature) {
      this.timer = setInterval(() => {
        this.pollForSignedDocument(signature);
      }, 1000);
    },

    // TODO: Add formatting and escaping
    userName(signature) {
      if (signature.user_name) {
        return signature.user_name;
      }

      const user = this.getUser(signature.user_id);
      if (user) {
        return user.name;
      }
      return "";
    },
  },
};
</script>
