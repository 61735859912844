<template>
  <div v-if="showQuickLinks || showActions">
    <be-dropdown size="sm" ellipsis>
      <template v-if="showQuickLinks">
        <be-dropdown-item
          v-for="{ url, text } in quickLinks"
          :key="url"
          :href="url"
        >
          {{ text }}
        </be-dropdown-item>
      </template>

      <be-dropdown-divider v-if="showQuickLinks && showActions" />

      <template v-if="showActions">
        <template v-for="({ type, url, text, confirm_text }, key) in actions">
          <be-dropdown-divider
            v-if="type === 'divider'"
            :key="`${key}-divider`"
          />

          <be-dropdown-item
            v-else-if="type === 'confirm'"
            :key="`${key}-confirm`"
            variant="danger"
            @click="confirm(confirm_text, url)"
          >
            {{ text }}
          </be-dropdown-item>

          <be-dropdown-item
            v-else-if="type === 'remove-ownership-modal'"
            :key="`${key}-remove-ownership-modal`"
            variant="danger"
            @click="$emit('click-remove-ownership', { ownership, url })"
          >
            {{ text }}
          </be-dropdown-item>

          <be-dropdown-item v-else :key="`${key}-normal`" :href="url">
            {{ text }}
          </be-dropdown-item>
        </template>
      </template>
    </be-dropdown>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers(
  "corporate_group_tree"
);

export default {
  props: {
    groupMember: {
      type: [Object, Boolean],
      default: false,
      required: false,
    },

    ownership: {
      type: [Object, Boolean],
      default: false,
      required: false,
    },

    actions: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  emits: ["click-remove-ownership"],

  computed: {
    ...mapState(["quick_links"]),

    quickLinks() {
      if (!this.groupMember?.nanoid) {
        return [];
      }

      if (this.groupMember.corporate_group_uuid) {
        return this.quick_links[this.groupMember.corporate_group_uuid];
      }

      return this.quick_links[this.groupMember.nanoid] || [];
    },

    showQuickLinks() {
      return this.quickLinks.length > 0;
    },

    showActions() {
      return Object.keys(this.actions).length > 0;
    },
  },

  methods: {
    ...mapActions(["loadTree"]),

    async confirm(title, href) {
      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        try {
          await axios.delete(href);
          await this.loadTree();
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
