<template>
  <div>
    <div v-if="!newThread" class="card-header">
      <h5 class="card-title">
        {{ $t("components.companies.message_threads.new_post") }}
      </h5>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12" :class="{ 'col-xl-6': !newThread }">
          <be-form-group
            :label="$t('components.companies.message_threads.message')"
            label-for="new-message"
            :error="getErrors(localMessage, 'comment.body')"
          >
            <text-editor
              id="new-message"
              name="message[body]"
              :content="localMessage.body"
              :error="getErrors(localMessage, 'comment.body')"
              @update:content="updateBody"
            />
          </be-form-group>
        </div>

        <div v-if="!newThread" class="col-12 col-xl-6">
          <div class="row">
            <div class="mb-4 mb-xl-0">
              <be-form-checkbox
                id="message-status"
                v-model="localMessage.status"
                name="message-status"
                checked-value="urgent"
                unchecked-value="regular"
                :description="$t('models.message.hints.urgent')"
                @change="updateStatus"
              >
                {{ translateAttribute("message", "urgent") }}
              </be-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <be-form-group
        :label="$t('components.companies.message_threads.attach_file')"
        label-for="new-message-document-uploader"
      >
        <document-uploader
          id="new-message-document-uploader"
          :documents="localMessage.documents"
          @document-added="documentAdded"
          @document-removed="documentRemoved"
          @uploading="(value) => uploading(value)"
        />
      </be-form-group>
    </div>

    <div v-if="!hideButtons" class="card-footer d-flex justify-content-end">
      <be-button
        variant="primary"
        :loading="uploadingFiles || loading"
        @click="saveMessage()"
      >
        {{ $t("models.message.create") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },

    hideButtons: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    newThread: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["message-updated", "documents-updated", "submit", "uploading"],

  data() {
    return {
      showAll: false,
      showDocumentFields: false,
      localMessage: this.cloneDeep(this.message),
      uploadingFiles: false,
    };
  },

  computed: {
    files() {
      return this.localMessage.documents.map((document) => {
        return document.default_file;
      });
    },
  },

  watch: {
    message(new_value) {
      this.localMessage = this.cloneDeep(new_value);
    },
  },

  methods: {
    toggleDocumentFields() {
      this.showDocumentFields = !this.showDocumentFields;
    },

    updateBody(body) {
      this.clearErrors(this.localMessage, "body");
      this.localMessage.body = body;
      this.updateDebounce();
    },

    updateStatus(status) {
      this.clearErrors(this.localMessage, "status");
      this.localMessage.status = status;
      this.update();
    },

    update() {
      this.$emit("message-updated", this.localMessage);
    },

    updateDebounce: debounce(function () {
      this.update();
    }, 300),

    documentAdded(file) {
      this.localMessage.documents.push(file);

      this.uploadingFiles = false;
      this.$emit("documents-updated", this.localMessage.documents);
    },

    documentRemoved(file) {
      const index = this.localMessage.documents.findIndex(
        (existingDocument) => existingDocument.file_uuid == file.file_uuid
      );

      if (index > -1) {
        this.localMessage.documents.splice(index, 1);
      }

      this.uploadingFiles = false;
      this.$emit("documents-updated", this.localMessage.documents);
    },

    saveMessage() {
      this.$emit("submit", this.localMessage);
    },

    uploading(value) {
      this.uploadingFiles = value;
      this.$emit("uploading", value);
    },
  },
};
</script>
