<template>
  <div class="pb-2">
    <group-tree-filters v-if="withFilters" :reverse="inClientManager" />

    <be-skeleton-table v-if="tree.loading" />

    <table v-else class="table mb-0 table-borderless">
      <group-member
        :group-member="tree"
        first-branch
        @click-remove-ownership="$emit('click-remove-ownership', $event)"
        @open-edit-state-modal="$emit('open-edit-state-modal', $event)"
        @confirm-remove-state="$emit('confirm-remove-state', $event)"
      />
    </table>
  </div>
</template>

<script>
import GroupTreeFilters from "./GroupTreeFilters.vue";
import GroupMember from "./GroupMember.vue";

import RequestQueryMixin from "@/mixins/RequestQuery";

import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("corporate_group_tree");

export default {
  components: {
    GroupTreeFilters,
    GroupMember,
  },

  mixins: [RequestQueryMixin],

  props: {
    withFilters: {
      type: Boolean,
      required: false,
      default: true,
    },

    inClientManager: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  emits: [
    "click-remove-ownership",
    "open-edit-state-modal",
    "confirm-remove-state",
  ],

  data: () => ({
    ownershipBeingRemoved: false,
    stateBeingEdited: false,
  }),

  computed: {
    ...mapState(["tree"]),
  },
};
</script>
