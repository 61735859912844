<template>
  <div>
    <!-- The transaction is not part of a sale -->
    <buyer-only-transaction
      v-if="!showSaleTransaction"
      class="mb-4"
      :action-type="actionType"
      :transaction="localTransaction"
    />

    <!-- The transaction is part of a sale -->
    <sale-transaction
      v-if="showSaleTransaction"
      class="mb-4"
      :action-type="actionType"
      :shareholder-register="shareholderRegister"
      :transaction="localTransaction"
      :formatted-available-sellers="formattedAvailableSellers"
      :can-edit-all-but-share-type="canEditAllButShareType"
      :share-type="shareType"
      @shareholder-series="updateSellerSeries"
      @seller-changed="resetNumberOfShares()"
      @update-errors="updateErrors"
    />

    <hr />

    <div class="row">
      <div class="col-12 col-lg-6">
        <!-- Transaction data -->
        <h3>
          {{
            $t("components.shareholder_registers.transaction.transaction_data")
          }}
        </h3>

        <div class="row">
          <div class="col-12 col-md-6">
            <be-form-group
              :label="
                $t('activerecord.attributes.shareholder_transaction.share_type')
              "
              label-for="share-type"
              :error="getErrors(localTransaction, 'share_type')"
              required
            >
              <be-form-select
                id="share-type"
                v-model="localTransaction.share_type"
                required
                :disabled="disableShareType"
                :options="formattedShareTypes"
              />
              <!-- Pass as hidden to always send even if disabled -->
              <input
                type="hidden"
                name="shareholder_transaction[share_type]"
                :value="shareType"
              />
            </be-form-group>

            <be-form-group
              :label="
                $t(
                  'activerecord.attributes.shareholder_transaction.number_of_shares'
                )
              "
              label-for="number-of-shares"
              :error="
                getErrors(localTransaction, [
                  'not_enough_shares',
                  'number_of_shares',
                ])
              "
            >
              <be-form-input
                id="number-of-shares"
                v-model="localTransaction.number_of_shares"
                type="number"
                number
                min="1"
                step="1"
                required
                :name="`shareholder_transaction[number_of_shares]`"
                :disabled="!numberOfSharesEnabled"
                :state="
                  validationState(localTransaction, [
                    'not_enough_shares',
                    'number_of_shares',
                  ])
                "
                @change="numberOfSharesChanged"
              />
            </be-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <be-form-group
              v-if="showPriceInput"
              :label="
                $t(
                  'activerecord.attributes.shareholder_transaction.share_price'
                )
              "
              label-for="share-price"
              :error="
                getErrors(localTransaction, [
                  'price_not_above_quota',
                  'share_price',
                ])
              "
            >
              <be-form-input
                id="share-price"
                v-model="localTransaction.share_price"
                number
                name="shareholder_transaction[share_price]"
                :disabled="!sharePriceEnabled"
                :state="
                  validationState(localTransaction, [
                    'price_not_above_quota',
                    'share_price',
                  ])
                "
                @change="sharePriceChanged"
              />
            </be-form-group>
          </div>

          <div v-if="showPriceInput" class="col-12 col-md-4">
            <be-form-group
              :label="$t('models.shareholder_register_action.total_price')"
              label-for="total-price"
            >
              <be-form-input id="total_price" v-model="totalPrice" readonly />
            </be-form-group>
          </div>
        </div>
      </div>

      <div class="col-12 col-lg-6">
        <h3>{{ $t("models.shareholder_transaction_series.series") }}</h3>

        <series-row
          v-for="(series, index) in transactionSeries"
          :key="`series-${series.id || series.uuid}`"
          :editable="canEditSeries"
          :index="index"
          :series="series"
          :overlap="seriesOverlaps[series.id || series.uuid] || []"
          @change="updateSeries"
        />

        <div class="d-md-flex justify-content-end mt-3">
          <be-button
            v-if="canEditSeries"
            variant="light"
            size="sm"
            icon="fa-plus"
            @click="addEmptyNewSeries"
          >
            {{ $t("components.shareholder_registers.transaction.add_series") }}
          </be-button>
        </div>

        <be-alert
          v-if="!!localTransaction.errors.wrong_series"
          variant="danger"
          class="mt-3"
        >
          {{
            $t(
              "views.companies.shareholder_registers.transactions.series_fields.series_doesnt_match"
            )
          }}
        </be-alert>

        <be-alert
          v-if="overlapWithinTransaction"
          variant="warning"
          class="mt-3"
        >
          {{
            $t(
              "components.shareholder_registers.transaction_form.overlap_within_transaction"
            )
          }}
        </be-alert>
      </div>
    </div>

    <div class="row mb-3">
      <!-- Register Series -->
      <div
        v-if="Object.keys(registerSeriesAsHash).length > 0"
        class="col-12 col-md-6 mt-4"
      >
        <h4>
          {{
            $t(
              "components.shareholder_registers.transaction.shareholder_register_shares"
            )
          }}
        </h4>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction.share_type"
                  )
                }}
              </th>

              <th class="text-right">
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.start"
                  )
                }}
              </th>

              <th class="text-right">
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.end"
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <template
              v-for="(series, localShareType) in registerSeriesAsHash[0] || []"
            >
              <tr
                v-for="(serie, serieIndex) in series['series']"
                :key="`register-series-${localShareType}-${serieIndex}`"
              >
                <td>
                  {{
                    $t(
                      `models.shareholder_transaction.share_types.${localShareType}`
                    )
                  }}
                </td>

                <td class="text-right">{{ serie["start"] }}</td>

                <td class="text-right">{{ serie["end"] }}</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>

      <!-- Shareholder Series -->
      <div
        v-if="sellerSeries.length > 0 && !transaction.id"
        class="col-12 col-md-6 mt-4"
      >
        <h4>
          {{
            $t(
              "components.shareholder_registers.transaction.owner_current_shares"
            )
          }}
        </h4>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction.share_type"
                  )
                }}
              </th>

              <th class="text-right">
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.start"
                  )
                }}
              </th>

              <th class="text-right">
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.end"
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(series, index) in sellerSeries"
              :key="'seller-series-' + index"
            >
              <td>
                {{
                  $t(
                    `models.shareholder_transaction.share_types.${series.transaction.share_type}`
                  )
                }}
              </td>

              <td class="text-right">{{ series["start"] }}</td>

              <td class="text-right">{{ series["end"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr />

    <!-- Notes -->
    <be-form-group
      :label="
        $t('companies.shareholder_registers.other_transaction_information')
      "
      label-for="notes"
      :error="getErrors(localTransaction, 'note')"
    >
      <be-form-textarea
        id="notes"
        v-model="localTransaction.note"
        name="shareholder_transaction[note]"
        rows="5"
        :state="validationState(localTransaction, 'note')"
        @change="clearErrors(localTransaction, 'note')"
      />
    </be-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BuyerOnlyTransaction from "./BuyerOnlyTransaction.vue";
import SaleTransaction from "./SaleTransaction.vue";
import SeriesRow from "./SeriesRow.vue";
import shareholderRegisterMixins from "@/mixins/shareholderRegisters";

export default {
  components: {
    BuyerOnlyTransaction,
    SaleTransaction,
    SeriesRow,
  },

  mixins: [shareholderRegisterMixins],

  props: {
    initialShareholderRegister: {
      type: Object,
      required: true,
    },

    transaction: {
      type: Object,
      required: true,
    },

    actionId: {
      type: Number,
      required: true,
    },

    availableSellers: {
      type: Array,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      localTransaction: this.cloneDeep(this.transaction),
      sellerSeries: [],
      transactionSeries: [],
    };
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      summaries: "shareholder_register/summaries",
      transactions: "shareholder_register/transactions",
    }),

    action() {
      return this.shareholderRegister?.shareholder_register_actions.find(
        (action) => action.id === this.actionId
      );
    },

    actionType() {
      return this.action?.action_type || "company_formation";
    },

    shareType() {
      return (
        this.action?.share_type ||
        this.localTransaction.share_type ||
        "a_shares"
      );
    },

    numberOfShares() {
      return this.localTransaction.number_of_shares;
    },

    sharePrice() {
      return this.localTransaction.share_price;
    },

    showSaleTransaction() {
      return ["sale", "share_transfer"].includes(this.actionType);
    },

    showPriceInput() {
      return !["share_transfer"].includes(this.actionType);
    },

    canEditAllButShareType() {
      return this.localTransaction.policy.edit_all_but_share_type;
    },

    canEditAllButShareTypeAndNumberOfShares() {
      return this.localTransaction.policy
        .edit_all_but_share_type_and_number_of_shares;
    },

    canEditSeries() {
      return (
        this.canEditAllButShareType ||
        this.canEditAllButShareTypeAndNumberOfShares
      );
    },

    disableShareType() {
      return !["company_formation", "upload"].includes(this.actionType);
    },

    sharePriceEnabled() {
      return (
        this.canEditAllButShareType ||
        this.canEditAllButShareTypeAndNumberOfShares
      );
    },

    numberOfSharesEnabled() {
      return (
        this.canEditAllButShareType &&
        !this.canEditAllButShareTypeAndNumberOfShares
      );
    },

    formattedAvailableSellers() {
      if (this.availableSellers) {
        return this.availableSellers.map((shareholder) => ({
          value: shareholder[1],
          text: shareholder[0],
        }));
      }

      return [];
    },

    registerSeriesAsHash() {
      return this.shareholderRegister?.register_series_as_hash || {};
    },

    totalPrice() {
      if (
        this.localTransaction.number_of_shares &&
        this.localTransaction.share_price &&
        this.valueNumeric(this.localTransaction.number_of_shares) &&
        this.valueNumeric(this.localTransaction.share_price)
      ) {
        return (
          this.localTransaction.number_of_shares *
          this.localTransaction.share_price
        );
      } else {
        return null;
      }
    },

    nbrSharesInSeries() {
      const series = this.transactionSeries.filter(
        (series) => !series.destroyed
      );

      let total = 0;

      series.forEach((series) => {
        if (Number.isInteger(series.end) && Number.isInteger(series.start)) {
          total += series.end - series.start + 1;
        }
      });
      return total;
    },

    seriesInAction() {
      const series = [];
      this.transactions
        .filter(
          (transaction) =>
            transaction.id !== this.localTransaction.id &&
            transaction.shareholder_register_action_id === this.actionId
        )
        .forEach((transaction) => {
          transaction.shareholder_transaction_series.forEach((serie) => {
            series.push({
              uuid: serie.uuid || serie.id,
              buyer: transaction.buyer.company_name || transaction.buyer.name,
              start: serie.start,
              end: serie.end,
            });
          });
        });

      return series;
    },

    seriesOverlaps() {
      const overlaps = {};
      this.transactionSeries.forEach((serie) => {
        let key = serie.id || serie.uuid;
        overlaps[key] = [];

        if (serie.destroyed) {
          return;
        }

        this.seriesInAction.forEach((other) => {
          if (
            !other.destroyed &&
            serie.uuid !== other.uuid &&
            serie.start <= other.end &&
            other.start <= serie.end
          ) {
            overlaps[key].push({
              name: other.buyer,
              start: other.start,
              end: other.end,
            });
          }
        });
      });

      return overlaps;
    },

    overlapWithinTransaction() {
      for (const serie of this.transactionSeries) {
        if (serie.destroyed) {
          continue;
        }

        for (const other of this.transactionSeries) {
          if (
            !other.destroyed &&
            serie.uuid !== other.uuid &&
            serie.start <= other.end &&
            other.start <= serie.end
          ) {
            return true;
          }
        }
      }

      return false;
    },
  },

  watch: {
    nbrSharesInSeries(value) {
      if (value != this.localTransaction.number_of_shares) {
        this.localTransaction.errors.number_of_shares = [
          this.$t(
            "components.shareholder_registers.transaction_form.mismatch_series_number_of_shares"
          ),
        ];
      } else {
        if (this.localTransaction.number_of_shares > 0) {
          delete this.localTransaction.errors.number_of_shares;
        }
      }
    },

    numberOfShares(value) {
      if (value < 1) {
        this.localTransaction.errors.number_of_shares = [
          this.$t("errors.messages.greater_than", {
            count: 0,
          }),
        ];
      } else {
        delete this.localTransaction.errors.number_of_shares;
      }
    },

    sharePrice(value) {
      if (value < 1) {
        this.localTransaction.errors.share_price = [
          this.$t("errors.messages.greater_than", {
            count: 0,
          }),
        ];
      } else {
        delete this.localTransaction.errors.share_price;
      }
    },
  },

  mounted() {
    this.$store.commit(
      "shareholder_register/loadRegister",
      this.initialShareholderRegister
    );

    if (this.localTransaction) {
      this.localTransaction.share_type = this.shareType;
    }

    this.localTransaction.shareholder_transaction_series.forEach((series) => {
      if (series.id) {
        this.addSeries(series);
      } else {
        this.addNewSeries(series);
      }
    });
  },

  methods: {
    addSeries(series) {
      this.transactionSeries.push({
        ...series,
        uuid: series.id,
        destroyed: false,
      });
    },

    addNewSeries(series) {
      this.transactionSeries.push({
        ...series,
        uuid: this.generateUuid(),
        destroyed: false,
      });
    },

    addEmptyNewSeries() {
      let series = {
        uuid: this.generateUuid(),
        destroyed: false,
      };

      this.transactionSeries.push(series);
    },

    updateSeries(series) {
      let idx = this.transactionSeries.findIndex(
        (existingSeries) => existingSeries.uuid == series.uuid
      );

      if (idx > -1) {
        this.transactionSeries[idx] = series;
      } else {
        this.transactionSeries.push(series);
      }
    },

    updateSellerSeries(series) {
      this.sellerSeries = series;
    },

    setSeriesProposal() {
      // Only suggest series for actions with types that the user can add new transactions to
      if (
        ![
          "sale",
          "share_transfer",
          "equity_issuance",
          "company_formation",
        ].includes(this.actionType)
      ) {
        return;
      }

      const currentSeries = this.transactionSeries.filter(
        (series) => !series.destroyed
      );

      // Only suggest series if exactly one series exists
      if (currentSeries.length === 0 || currentSeries.length > 1) {
        return;
      }

      // Only suggest series if it is a new transaction
      if (this.localTransaction.id) {
        return;
      }

      // Get the current and only transaction series
      let transactionSerie = currentSeries[currentSeries.length - 1];

      // Suggest series
      if (this.localTransaction.number_of_shares > 0) {
        if (["sale", "share_transfer"].includes(this.actionType)) {
          let sortedSellerSeries = this.cloneDeep(this.sellerSeries).sort(
            (a, b) => Number(b.start) - Number(a.start)
          );

          sortedSellerSeries.every((series) => {
            let start = series.start;
            let end = series.end;

            if (
              end + 1 - start >= this.localTransaction.number_of_shares &&
              this.shareType == series.transaction.share_type
            ) {
              transactionSerie.start =
                end + 1 - this.localTransaction.number_of_shares;
              transactionSerie.end = end;
              return false;
            }

            return true;
          });

          // Reset wrong series error if series have been suggested
          if (transactionSerie.start && transactionSerie.end) {
            delete this.localTransaction.errors.wrong_series;
          }
        } else if (this.actionType == "equity_issuance") {
          transactionSerie.start = this.shareholderRegister.last_series_end + 1;
          transactionSerie.end =
            this.shareholderRegister.last_series_end +
            parseInt(this.localTransaction.number_of_shares);
        } else if (this.actionType == "company_formation") {
          let start = 0;
          let end = 0;

          // If the shareholder register has had actions before
          if (this.shareholderRegister.total_share_count) {
            start = this.shareholderRegister.last_series_end + 1;
            end =
              this.shareholderRegister.last_series_end +
              parseInt(this.localTransaction.number_of_shares);
          } else {
            start = 1;
            end = this.localTransaction.number_of_shares;
          }

          transactionSerie.start = start;
          transactionSerie.end = end;
        }

        transactionSerie.errors = {};
      }
    },

    resetNumberOfShares() {
      this.localTransaction.number_of_shares = null;
    },

    valueNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },

    numberOfSharesChanged() {
      this.clearErrors(this.localTransaction, [
        "not_enough_shares",
        "number_of_shares",
      ]);
      this.setSeriesProposal();
    },

    sharePriceChanged(value) {
      if (value < 0) {
        this.clearErrors(this.localTransaction, ["price_not_above_quota"]);
      } else {
        this.clearErrors(this.localTransaction, [
          "price_not_above_quota",
          "share_price",
        ]);
      }

      this.setSeriesProposal();
    },

    updateErrors(key, value) {
      this.localTransaction.errors[key] = value;
    },
  },
};
</script>
