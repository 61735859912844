<template>
  <be-modal
    :id="`message-history-${message.id}`"
    :ok-title="$t('buttons.titles.close')"
    ok-only
    ok-variant="light"
    size="lg"
    header-class="align-items-start"
  >
    <template #header>
      <div>
        <h5 class="mb-1">
          {{ $t("components.companies.message_threads.message_history") }}
        </h5>

        <div class="text-muted">{{ title }}</div>
      </div>
    </template>

    <div class="media-list">
      <div
        v-for="(activity, index) in activities"
        :key="activity.id"
        class="media"
      >
        <div class="media-body">
          <h5>
            {{ $d(new Date(activity.changes_updated_at[0]), "dateTime") }}
          </h5>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="activity.changes_body[0]"></div>
          <!-- eslint-enable -->
          <hr v-if="index != message.activities.length - 1" />
        </div>
      </div>
    </div>
  </be-modal>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },

    thread: {
      type: Object,
      required: true,
    },

    activities: {
      type: Array,
      required: true,
    },
  },

  computed: {
    title() {
      const userName = this.getUserName(this.message.user_id);

      if (userName != undefined) {
        return `#${this.message.number} - ${this.getUserName(
          this.message.user_id
        )}`;
      } else {
        return `#${this.message.number} - ${this.$i18n.t(
          "models.user.removed_user"
        )}`;
      }
    },
  },
};
</script>
