<template>
  <div class="card">
    <div class="card-body">
      <h3 class="mb-0">
        {{
          $t("components.onboarding.introduction.support_section_fortnox.title")
        }}
      </h3>
    </div>

    <be-list-group flush>
      <be-list-group-item class="py-4">
        <div class="d-md-flex justify-content-between">
          <div>
            <h6>
              {{
                $t(
                  "components.onboarding.introduction.user_section_fortnox.education_title"
                )
              }}
            </h6>

            <div class="mb-2 mb-md-0">
              {{
                $t(
                  "components.onboarding.introduction.user_section_fortnox.education_description"
                )
              }}
            </div>
          </div>

          <be-link
            href="https://www.fortnox.se/inspelade-utbildningar"
            class="text-nowrap ml-md-3 mt-2 mt-md-0"
          >
            {{
              $t(
                "components.onboarding.introduction.user_section_fortnox.education_link"
              )
            }}
          </be-link>
        </div>
      </be-list-group-item>

      <be-list-group-item class="py-4">
        <div class="d-md-flex justify-content-between">
          <div>
            <h6>
              {{
                $t(
                  "components.onboarding.introduction.user_section_fortnox.user_support_title"
                )
              }}
            </h6>

            <div class="mb-2 mb-md-0">
              {{
                $t(
                  "components.onboarding.introduction.user_section_fortnox.user_support_description"
                )
              }}
            </div>
          </div>

          <be-link
            href="https://support.fortnox.se/produkthjalp"
            class="text-nowrap ml-md-3 mt-2 mt-md-0"
          >
            {{
              $t(
                "components.onboarding.introduction.user_section_fortnox.user_support_link"
              )
            }}
          </be-link>
        </div>
      </be-list-group-item>

      <be-list-group-item class="py-4">
        <div class="d-md-flex justify-content-betweeen">
          <div>
            <h6>
              {{
                $t(
                  "components.onboarding.introduction.support_section_fortnox.support_title"
                )
              }}
            </h6>

            <div class="mb-2 mb-md-0">
              {{
                $t(
                  "components.onboarding.introduction.support_section_fortnox.support_description"
                )
              }}
            </div>
          </div>
        </div>
      </be-list-group-item>
    </be-list-group>
  </div>
</template>

<script>
export default {};
</script>
