<template>
  <be-dropdown v-if="contentVersions.length > 0" right block>
    <template #button-content>
      {{ $t("models.material.previous_versions") }}
    </template>

    <be-dropdown-item :href="contentVersionUrl()" :active="version == 'none'">
      {{ $t("models.material.current_version") }}
    </be-dropdown-item>

    <be-dropdown-item
      v-for="contentVersion in contentVersions"
      :key="`content-version-${contentVersion.uuid}`"
      :active="version == contentVersion.uuid"
      :href="contentVersionUrl(contentVersion)"
    >
      {{
        $t("models.material.content_version_format_w_date_w_name", {
          date: $d(new Date(contentVersion.created_at), "dateTime"),
          name: contentVersion.user_name,
        })
      }}
    </be-dropdown-item>
  </be-dropdown>
</template>

<script>
export default {
  props: {
    contentVersions: {
      type: Array,
      required: true,
    },

    material: {
      type: Object,
      required: true,
    },

    version: {
      type: String,
      default: "none",
    },
  },

  methods: {
    contentVersionUrl(contentVersion = null) {
      let path = `meetings/${this.material.meeting_id}/${this.material.material_type}`;
      if (contentVersion?.uuid) {
        path += `?version=${contentVersion.uuid}`;
      }

      return this.url(path);
    },
  },
};
</script>
