<template>
  <div class="table-responsive mb-5">
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{ $t("models.shareholder_transaction_series.share_number") }}
            </label>

            <p>
              {{ series.start }}-{{ series.end }} ({{
                $t(
                  `models.shareholder_transaction.share_types.${transaction.share_type}`
                )
              }})
            </p>
          </td>

          <td class="mb-5 align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.insertion_date"
                )
              }}
            </label>

            <p>{{ $d(new Date(action.action_date), "date") }}</p>
          </td>

          <td class="mb-5 align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "activerecord.attributes.shareholder_register_action.action_type"
                )
              }}
            </label>

            <p>
              {{
                $t(
                  `models.shareholder_register_action.action_types.${action.action_type}`
                )
              }}
            </p>
          </td>
        </tr>

        <tr>
          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "activerecord.attributes.shareholder_register_action.number_of_shares"
                )
              }}
            </label>

            <div class="w-100"></div>

            {{ series.end - series.start + 1 }}
          </td>

          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{ $t("models.shareholder_register.quota") }}
            </label>

            <div class="w-100"></div>

            {{ formattedQuota }}
          </td>

          <td class="w-33"></td>
        </tr>

        <tr>
          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{ $t("models.shareholder_transaction.owner") }}
            </label>

            <div class="w-100"></div>

            <template v-if="!!shareholder.company_name">
              {{ shareholder.company_name }}
            </template>

            <template v-else>
              {{ shareholder.name }}
            </template>

            <div class="w-100"></div>

            <template v-if="shareholder.identification_number">
              {{ shareholder.identification_number }}
            </template>
          </td>

          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{ $t("models.shareholder.address") }}
            </label>

            <div class="w-100"></div>

            <div v-if="!validAddress" class="warning-container d-print-none">
              <i class="fa-duotone fa-triangle-exclamation text-warning" />

              <span class="help-block small">
                {{
                  $t(
                    "components.shareholder_registers.series_table.address_missing"
                  )
                }}

                <a
                  v-if="shareholderRegister.policy.edit"
                  :href="
                    url(
                      `/shareholder_register/shareholders/${transaction.buyer_id}/edit`
                    )
                  "
                >
                  {{ $t("buttons.titles.add_more") }}
                </a>
              </span>
            </div>

            <span v-if="shareholder.address1">{{ shareholder.address1 }}</span>

            <div class="w-100"></div>

            <span v-if="shareholder.address2">{{ shareholder.address2 }}</span>

            <div class="w-100"></div>

            <span v-if="shareholder.zipcode && shareholder.city">
              {{ shareholder.zipcode }} {{ shareholder.city }}
            </span>

            <div class="w-100"></div>

            <span v-if="validAddress && shareholder.country_name">
              {{ shareholder.country_name }}
            </span>
          </td>

          <td class="align-text-top w-33">
            <span v-if="shareholder.phone">
              <label class="small font-weight-bold text-uppercase mb-0">
                {{ $t("activerecord.attributes.shareholder.phone") }}
              </label>

              <div class="w-100"></div>

              {{ shareholder.phone }}
            </span>
          </td>
        </tr>

        <tr v-if="!!transaction.note && !!shareholder.notes">
          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.notes_transaction"
                )
              }}
            </label>

            <div class="w-100"></div>

            <span v-if="!!transaction.note">{{ transaction.note }}</span>

            <span v-else>{{ "-" }}</span>
          </td>

          <td class="align-text-top w-33">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.notes_shareholder"
                )
              }}
            </label>

            <div class="w-100"></div>

            {{ shareholder.notes }}
          </td>
        </tr>

        <tr v-else-if="!!shareholder.notes">
          <td colspan="3">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.notes_shareholder"
                )
              }}
            </label>

            <div class="w-100"></div>

            {{ shareholder.notes }}
          </td>
        </tr>

        <tr v-else-if="!!transaction.note">
          <td colspan="3">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.notes_transaction"
                )
              }}
            </label>

            <div class="w-100"></div>

            {{ transaction.note }}
          </td>
        </tr>

        <tr v-if="series.history && series.history.length > 0">
          <td colspan="3">
            <label class="small font-weight-bold text-uppercase mb-0">
              {{
                $t(
                  "components.shareholder_registers.series_table.previous_owners"
                )
              }}
            </label>

            <ul class="list-unstyled mb-0">
              <series-history
                v-for="(seriesHistory, index) in series.history"
                :key="`series-history-${index}`"
                :index="index"
                :series-history="seriesHistory"
                :series-histories="series.history"
              />
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SeriesHistory from "./SeriesHistory.vue";

export default {
  components: { SeriesHistory },

  props: {
    selectedDate: {
      type: String,
      required: true,
    },

    series: {
      type: Object,
      required: true,
    },

    quota: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      actions: "shareholder_register/actions",
      shareholderRegister: "shareholder_register/shareholderRegister",
      shareholders: "shareholder_register/shareholders",
      transactions: "shareholder_register/transactions",
    }),

    formattedQuota() {
      return (
        BigNumber(this.quota).decimalPlaces(
          this.$config.SHAREHOLDER_REGISTER.ROUND_DECIMALS
        ) +
        " " +
        this.shareholderRegister.currency.toUpperCase()
      );
    },

    validAddress() {
      return (
        this.shareholder.address1 ||
        this.shareholder.address2 ||
        this.shareholder.city ||
        this.shareholder.zipcode
      );
    },

    action() {
      return (
        this.actions.find((action) => action.id === this.series.action_id) || {}
      );
    },

    shareholder() {
      return (
        this.shareholders.find(
          (shareholder) => shareholder.id === this.series.shareholder_id
        ) || {}
      );
    },

    transaction() {
      return (
        this.transactions.find(
          (transaction) => transaction.id === this.series.transaction_id
        ) || {}
      );
    },
  },

  mounted() {
    this.$store.dispatch("shareholder_register/loadTransactionHistory", {
      series: this.series,
      selectedDate: this.selectedDate,
    });
  },
};
</script>
