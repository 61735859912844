<template>
  <div v-show="!destroyed" class="col-12 col-md-6">
    <div class="row no-gutters align-items-end mb-3">
      <div class="col">
        <be-form-group
          :label="customFieldOwner.custom_field.title"
          :label-for="`custom-field-title-${index}`"
          class="mb-0"
        >
          <be-form-input
            :id="`custom-field-title-${index}`"
            :name="`${namePrefix}[custom_field_owners_attributes][${index}][input_value]`"
            :model-value="customFieldOwner.input_value"
            required
          />

          <input
            :name="`${namePrefix}[custom_field_owners_attributes][${index}][_destroy]`"
            :value="destroyed"
            type="hidden"
          />

          <input
            :name="`${namePrefix}[custom_field_owners_attributes][${index}][custom_field_id]`"
            :value="customFieldOwner.custom_field.id"
            type="hidden"
          />

          <input
            v-if="customFieldOwner.id"
            :name="`${namePrefix}[custom_field_owners_attributes][${index}][id]`"
            :value="customFieldOwner.id"
            type="hidden"
          />
        </be-form-group>
      </div>

      <div class="col-auto ml-2">
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          class="mb-1"
          @click="removeCustomFieldOwner()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    namePrefix: {
      type: String,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    customFieldOwner: {
      type: Object,
      required: true,
    },
  },

  emits: ["custom-field-owner-removed"],

  data() {
    return {
      destroyed: false,
    };
  },

  methods: {
    removeCustomFieldOwner() {
      this.destroyed = true;
      this.$emit("custom-field-owner-removed", this.customFieldOwner);
    },
  },
};
</script>
