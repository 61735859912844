<template>
  <be-modal
    id="phrase-modal"
    :title="title"
    :ok-title="
      phrase.id ? $t('buttons.titles.save') : $t('models.phrase.create')
    "
    @ok.prevent="handleOk"
    @cancel.prevent="close"
    @hidden="close"
  >
    <be-form-group
      label-for="phrase-content"
      :label="translateAttribute('phrase', 'content')"
      :error="getErrors(localPhrase, 'content')"
      :description="
        $t('components.meetings.phrases.hints.max_length', { max: 255 })
      "
      required
    >
      <be-form-textarea
        id="phrase-content"
        v-model="localPhrase.content"
        rows="3"
        max-rows="20"
        maxlength="255"
        :state="validationState(localPhrase, 'content')"
        autofocus
        required
        @keydown.enter.prevent
        @change="clearErrors(localPhrase, 'content')"
      />
    </be-form-group>
  </be-modal>
</template>

<script>
export default {
  props: {
    phrase: {
      type: Object,
      required: true,
    },
  },

  emits: ["create", "update", "close"],

  data() {
    return {
      localPhrase: this.cloneDeep(this.phrase),
    };
  },

  computed: {
    title() {
      if (this.localPhrase.id) {
        return this.$t("models.phrase.edit");
      } else {
        return this.$t("models.phrase.new");
      }
    },
  },

  watch: {
    phrase(newPhrase) {
      if (Object.keys(newPhrase).length > 0) {
        this.localPhrase = this.cloneDeep(newPhrase);
        this.$beModal.show("phrase-modal");
      } else {
        this.$beModal.hide("phrase-modal");
      }
    },
  },

  methods: {
    handleOk() {
      if (this.localPhrase.id) {
        this.$emit("update", this.localPhrase);
      } else {
        this.$emit("create", this.localPhrase);
      }
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
