<template>
  <div class="integration">
    <div class="row">
      <div class="col-4 col-md-2 align-content-center">
        <be-img
          :src="imageSrc(`integrations/${integration}.png`)"
          class="media-image mr-md-4 mb-3 mb-md-0 align-self-center"
        />
      </div>

      <div class="col-8 col-md-10">
        <h5>
          {{
            $t(`models.available_integration.description.${integration}.title`)
          }}
          <be-badge v-if="enabled" variant="success">
            {{ $t("models.available_integration.status.active") }} -
            {{ $d(new Date(createdAt), "short") }}
          </be-badge>
        </h5>

        <p>
          {{
            $t(
              `models.available_integration.description.${integration}.description`
            )
          }}
        </p>

        <div class="mb-4">
          <template v-if="!enabled && !verificationStarted">
            <slot name="activate" />
          </template>

          <template v-if="enabled && !verificationStarted">
            <slot name="deactivate" />
          </template>

          <template v-else-if="verificationStarted">
            <job-callback
              reference-type="Company"
              :reference-id="companyId"
              :job-key="jobKey"
              @update="onJobCallbackUpdate"
            >
              <template #initial>
                <be-alert variant="info" loading>
                  {{ $t("views.payment_integrations.verification_started") }}
                </be-alert>
              </template>

              <template #in_progress>
                <be-alert variant="info" loading>
                  {{ $t("views.payment_integrations.verification_started") }}
                </be-alert>
              </template>

              <template #failed>
                <be-alert variant="warning">
                  <template v-if="jobCallbackData?.body.error">
                    {{ jobCallbackData.body.error }}
                  </template>

                  <template v-else>
                    {{ $t("views.payment_integrations.verification_failed") }}
                  </template>
                </be-alert>

                <slot name="activate" />
              </template>

              <template #timeout>
                <be-alert variant="warning">
                  {{ $t("views.payment_integrations.verification_timeout") }}
                </be-alert>

                <slot name="activate" />
              </template>

              <template #completed>
                <be-alert variant="success">
                  {{ $t("views.payment_integrations.verification_succeeded") }}
                </be-alert>

                <slot name="deactivate" />
              </template>
            </job-callback>
          </template>
        </div>

        <slot name="notifications" />
      </div>
    </div>

    <be-modal
      :id="`${integration}-modal`"
      :title="
        $t(`models.available_integration.description.${integration}.title`)
      "
    >
      <slot name="modal" />

      <template #footer="{ cancel }">
        <be-button variant="light" @click="cancel">
          {{ $t("buttons.titles.cancel") }}
        </be-button>

        <be-button variant="primary" @click="$emit('activate')">
          {{ $t("buttons.titles.save") }}
        </be-button>
      </template>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    integration: {
      type: String,
      required: true,
    },

    enabledPaymentIntegrations: {
      type: Array,
      required: true,
    },

    verificationStarted: {
      type: Boolean,
      default: false,
    },

    jobKey: {
      type: String,
      default: null,
    },
  },

  emits: ["activate", "deactivate"],

  data() {
    return {
      jobCallbackData: null,
    };
  },

  computed: {
    ...mapGetters({
      getCompany: "company/getCompany",
    }),

    companyId() {
      return this.getCompany.id;
    },

    enabled() {
      return this.enabledPaymentIntegrations
        .map((i) => i.integration)
        .includes(this.integration.vendor);
    },

    createdAt() {
      return this.enabledPaymentIntegrations
        .filter((i) => i.integration === this.integration.vendor)
        .map((i) => i.created_at)[0];
    },
  },

  methods: {
    onJobCallbackUpdate(data) {
      this.jobCallbackData = data;
    },
  },
};
</script>
