<template>
  <be-table
    v-if="templates.length > 0"
    :items="templates"
    :fields="fields"
    sort-by="created_at"
    sort-desc
  >
    <template #icon="{ item }">
      <i
        v-if="isDownloaded(item)"
        v-be-tooltip="$t('models.template_package.downloaded')"
        class="fal fa-check fa-fw"
      />

      <i v-else class="fal fa-check fa-fw invisible" />

      <i
        v-if="item.password_required"
        v-be-tooltip="$t('models.template_package.password_required')"
        class="fal fa-lock fa-fw"
      />

      <i v-else class="fal fa-lock fa-fw invisible" />

      <i
        v-if="item.privacy_level === 'secret_template'"
        v-be-tooltip="$t('models.template_package.hidden')"
        class="fal fa-user-lock"
      />
    </template>

    <template #title="{ item }">
      <template v-if="item.title">
        {{ item.title }}
      </template>

      <template v-else-if="item.content">
        {{ item.content }}
      </template>
    </template>

    <template #template_type="{ item }">
      <template v-if="item.template_package_items.length > 1">
        {{
          $t(
            `models.template_package.types.multiple_templates.${item.template_type}`
          )
        }}
      </template>

      <template v-else>
        {{ $t(`models.template_package.types.${item.template_type}`) }}
      </template>
    </template>

    <template #created_at="{ item }">
      <div>
        {{ $d(new Date(item.created_at), "date") }}
      </div>
    </template>

    <template #creators="{ item }">
      {{ item.creators }}
    </template>

    <template #downloads="{ item }">
      {{ item.downloads }}
    </template>

    <template #options="{ item }">
      <be-button
        v-be-modal="`read-more-modal-${item.id}`"
        variant="outline-secondary"
        size="sm"
        inline
      >
        {{ $t("buttons.titles.read_more") }}
      </be-button>

      <be-button
        v-if="item.policy.save_template && !isDownloaded(item)"
        variant="primary"
        size="sm"
        :disabled="loading"
        inline
        @click="save(item)"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>

      <be-modal
        :id="`read-more-modal-${item.id}`"
        :title="item.title"
        :ok-title="$t('buttons.titles.close')"
        ok-only
        ok-variant="light"
        size="lg"
      >
        <template v-if="item.description">
          <h4>
            {{ translateAttribute("template_package", "description") }}
          </h4>

          <p>{{ item.description }}</p>
        </template>

        <template v-if="item.template_package_items">
          <h4>{{ $t("models.template_package.content") }}</h4>

          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  {{ translateAttribute("template_package", "title") }}
                </th>

                <th>
                  {{ translateAttribute("template_package", "type") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="packageItem in item.template_package_items"
                :key="`package-item-${packageItem.id}`"
              >
                <td>
                  <template v-if="packageItem.reference_title">
                    {{ packageItem.reference_title }}
                  </template>

                  <template v-else-if="packageItem.reference_content">
                    {{ packageItem.reference_content }}
                  </template>
                </td>

                <td>
                  {{ translatedReferenceType(packageItem) }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>

        <p class="mb-0 text-muted">
          <em>
            {{
              $t("models.template_package.created_by", {
                creators: item.creators,
                created_at: $d(new Date(item.created_at), "date"),
              })
            }}
          </em>
        </p>
      </be-modal>

      <save-template-modal
        :id="`save-template-modal-${item.id}`"
        :item="item"
        @save="savePasswordRequiredTemplate"
      />
    </template>
  </be-table>

  <be-alert v-else variant="info">
    {{ $t("components.template_packages.empty") }}
  </be-alert>
</template>

<script>
import { mapGetters } from "vuex";
import SaveTemplateModal from "./SaveTemplateModal.vue";

export default {
  components: {
    SaveTemplateModal,
  },

  props: {
    templates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        {
          key: "icon",
          label: "",
          class: "col-shrink text-muted",
        },
        {
          key: "title",
          label: this.translateAttribute("template_package", "title"),
          sortable: true,
        },
        {
          key: "template_type",
          label: this.translateAttribute("template_package", "type"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "created_at",
          label: this.translateAttribute("template_package", "published_at"),
          class: "col-shrink text-right",
          sortable: true,
        },
        {
          key: "creators",
          label: this.translateAttribute("template_package", "creators"),
          sortable: true,
        },
        {
          key: "downloads",
          label: this.translateAttribute("template_package", "downloads"),
          class: "col-shrink d-none d-md-table-cell text-right",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ],

      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      savedTemplates: "template_packages/savedTemplates",
    }),

    isDownloaded() {
      return (template) => {
        let savedTemplateIds = this.savedTemplates.map(
          (template) => template.id
        );

        return (
          template.template_package_items.filter((item) => {
            let ids = item.template_activities.map(
              (activity) => activity.reference_id
            );

            return savedTemplateIds.find((id) => ids.includes(id));
          }).length == template.template_package_items.length
        );
      };
    },
  },

  methods: {
    translatedReferenceType(packageItem) {
      if (packageItem.translated_reference_type) {
        return packageItem.translated_reference_type;
      } else {
        return this.$t(
          `models.template_package.types.${packageItem.reference_type}`
        );
      }
    },

    async save(template) {
      try {
        if (template.password_required) {
          // If password required
          this.$beModal.show(`save-template-modal-${template.id}`);
        } else {
          // If no password required
          this.saveTemplate(template);
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async saveTemplate(template) {
      const response = await axios.post(
        this.url(`/template_packages/${template.id}/save_template`)
      );

      this.$store.commit("template_packages/saveTemplate", response.data);
    },
  },
};
</script>
