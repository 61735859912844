<template>
  <be-modal
    id="contract-request"
    ok-only
    :ok-title="okTitle"
    :title="$t('components.contracts.contract_request.title')"
    no-teleport
    @ok="submitForm"
  >
    <be-form-group
      label-for="request-receiver"
      :label="$t('components.contracts.contract_request.send_to')"
    >
      <be-form-select
        id="request-receiver"
        v-model="userId"
        name="user"
        :options="usersExceptCurrent"
      />
    </be-form-group>

    <be-form-group
      label-for="request-message"
      :label="$t('components.contracts.contract_request.message')"
    >
      <be-form-textarea
        id="request-message"
        v-model="message"
        class="form-control"
        rows="3"
        max-rows="20"
        name="message"
      />
    </be-form-group>
  </be-modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      message: null,
      userId: null,
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
    }),

    okTitle() {
      if (this.loading) {
        return this.$i18n.t("buttons.states.sending");
      }

      return this.$i18n.t("components.contracts.contract_request.send_request");
    },

    usersExceptCurrent() {
      let unformattedUsers = this.companyUsers.filter(
        (user) => user.id != this.$currentUser.id
      );

      return unformattedUsers
        .map((user) => ({ text: user.name, value: user.id }))
        .sort((a, b) => (b.text < a.text ? 1 : -1));
    },
  },

  mounted() {
    if (this.usersExceptCurrent) {
      this.userId = this.usersExceptCurrent[0].value;
    }
  },

  methods: {
    async submitForm(event) {
      event.preventDefault();
      this.loading = true;

      try {
        const response = await axios.post(
          this.url("/contracts/send_contract_request"),
          {
            user: this.userId,
            message: this.message,
          }
        );

        if (response.status == 200) {
          this.$beModal.hide("contract-request");
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
