<template>
  <form
    novalidate="novalidate"
    action="/users/sign_in"
    accept-charset="UTF-8"
    method="post"
    @submit="signingIn = true"
  >
    <input name="utf8" type="hidden" value="✓" />

    <input type="hidden" name="authenticity_token" :value="$csrfToken" />

    <input id="locale" type="hidden" name="locale" value="sv" />

    <be-form-group
      :label="$t('activerecord.attributes.user.email')"
      label-for="email"
    >
      <be-input-group>
        <be-form-input
          id="email"
          v-model="email"
          type="email"
          name="user[email]"
          autocomplete="username"
        />

        <be-input-group-append inline is-text>
          <i class="far fa-envelope text-muted" />
        </be-input-group-append>
      </be-input-group>
    </be-form-group>

    <be-form-group
      :label="$t('activerecord.attributes.user.password')"
      label-for="current-password"
    >
      <be-input-group>
        <be-form-input
          id="current-password"
          type="password"
          name="user[password]"
          autocomplete="current-password"
        />

        <be-input-group-append inline is-text>
          <i class="far fa-lock text-muted" />
        </be-input-group-append>
      </be-input-group>
    </be-form-group>

    <be-button
      variant="dark"
      size="lg"
      block
      type="submit"
      name="commit"
      class="mt-4"
      :disabled="!emailContainsAtSign || signingIn"
    >
      <be-spinner v-if="signingIn" variant="light">
        {{ $t("components.authentication.login_form.signing_in") }}
      </be-spinner>

      <template v-else>
        {{ $t("login.login") }}
      </template>
    </be-button>
  </form>
</template>

<script>
export default {
  props: {
    initialEmail: {
      type: String,
      required: false,
      default: "",
    },
  },

  data() {
    return {
      signingIn: false,
      email: this.initialEmail,
    };
  },

  computed: {
    emailContainsAtSign() {
      return this.email.match(/@/);
    },
  },
};
</script>
