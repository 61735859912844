<template>
  <div>
    <div class="d-flex justify-content-end">
      <be-button
        variant="outline-primary"
        class="mb-3"
        :href="url('/shareholder_register/register_series/edit')"
      >
        {{ $t("components.shareholder_registers.company_information.edit") }}
      </be-button>
    </div>

    <!-- Top section -->
    <div class="table-responsive mb-4">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>
              {{ $t("activerecord.attributes.shareholder_register.capital") }}
            </th>

            <th>
              {{ $t("models.shareholder_register.total_share_count_given") }}
            </th>

            <th>{{ $t("models.shareholder_register.quota") }}</th>

            <th>
              {{ $t("activerecord.attributes.shareholder_register.currency") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td v-if="shareholderRegister.capital">{{ formattedCapital }}</td>

            <td v-else>{{ "0" }}</td>

            <td v-if="shareholderRegister.total_share_count">
              {{ shareholderRegister.total_share_count }}
            </td>

            <td v-else>{{ "0" }}</td>

            <td v-if="shareholderRegister.quota">{{ formattedQuota }}</td>

            <td v-else>{{ "0" }}</td>

            <td>
              <template v-if="shareholderRegister.currency">{{
                $t(
                  `models.shareholder_register.currencies.${shareholderRegister.currency.toLowerCase()}`
                )
              }}</template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-responsive-sm mb-4">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <td>
              <label class="small font-weight-bold text-uppercase mb-0">
                {{
                  $t(
                    "activerecord.attributes.shareholder_register.provisions_on_pre_emptive_rights"
                  )
                }}
              </label>

              <p v-if="shareholderRegister.provisions_on_pre_emptive_rights">
                {{
                  $t(
                    "components.shareholder_registers.company_information.setting_exists"
                  )
                }}
              </p>

              <p v-else>{{ "-" }}</p>
            </td>

            <td>
              <label class="small font-weight-bold text-uppercase mb-0">
                {{
                  $t(
                    "activerecord.attributes.shareholder_register.offer_of_first_refusal"
                  )
                }}
              </label>

              <p v-if="shareholderRegister.offer_of_first_refusal">
                {{
                  $t(
                    "components.shareholder_registers.company_information.setting_exists"
                  )
                }}
              </p>

              <p v-else>{{ "-" }}</p>
            </td>
          </tr>

          <tr>
            <td>
              <label class="small font-weight-bold text-uppercase mb-0">
                {{
                  $t(
                    "activerecord.attributes.shareholder_register.physical_certificate_issued"
                  )
                }}
              </label>

              <p v-if="shareholderRegister.physical_certificate_issued">
                {{
                  $t(
                    "components.shareholder_registers.company_information.setting_exists"
                  )
                }}
              </p>

              <p v-else>{{ "-" }}</p>
            </td>

            <td>
              <label class="small font-weight-bold text-uppercase mb-0">
                {{ $t("activerecord.attributes.company.organization_number") }}
              </label>

              <p v-if="$currentCompany && $currentCompany.organization_number">
                {{
                  $t(
                    "components.shareholder_registers.company_information.setting_exists"
                  )
                }}
              </p>

              <p v-else>{{ "-" }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row">
      <div class="col-12 col-lg-6 mb-4">
        <h5 class="mb-0 mb-lg-2">
          {{
            $t(
              "components.shareholder_registers.company_information.vote_distribution"
            )
          }}
        </h5>

        <table class="table table-hover">
          <thead>
            <tr>
              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction.share_type"
                  )
                }}
              </th>

              <th>{{ $t("models.shareholder_register.votes_per_share") }}</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="series in sortedSeries"
              :key="`series-vote-${series.id}`"
            >
              <td>
                {{
                  $t(
                    `models.shareholder_transaction.share_types.${series.share_type}`
                  )
                }}
              </td>

              <td>
                <template v-if="series.votes_per_share">{{
                  series.votes_per_share
                }}</template>

                <template v-else>-</template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        v-if="
          shareholderRegister.register_series_as_hash && !registerSeriesEmpty
        "
        class="col-12 col-lg-6 mb-3"
      >
        <h5 class="mb-0 mb-lg-2">
          {{
            $t(
              "components.shareholder_registers.company_information.current_shareholder_register_series"
            )
          }}
        </h5>

        <table class="table table-hover table-responsive-sm">
          <thead>
            <tr>
              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction.share_type"
                  )
                }}
              </th>

              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.start"
                  )
                }}
              </th>

              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction_series.end"
                  )
                }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="serie in registerSeriesTotal"
              :key="`register-series-${serie.share_type}-${serie.start}-${serie.end}`"
            >
              <td>
                {{
                  $t(
                    `models.shareholder_transaction.share_types.${serie.share_type}`
                  )
                }}
              </td>

              <td>{{ serie.start }}</td>

              <td>{{ serie.end }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="col-12 col-lg-6">
        <be-alert variant="info">
          {{
            $t("components.shareholder_registers.company_information.no_series")
          }}
        </be-alert>
      </div>
    </div>

    <div
      v-if="registerDocuments && registerDocuments.length"
      class="col-12 px-0 d-print-none"
    >
      <table class="table table-bordered table-responsive-sm">
        <thead>
          <tr>
            <th class="small-table-heading">
              {{ $t("activerecord.models.document.other") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="registerDocument in registerDocuments"
            :key="`document-${registerDocument.id}`"
            class="fields document_fields"
          >
            <td class="col-shrink">
              <document-link
                v-if="registerDocument.policy?.show"
                :document-id="registerDocument.id"
                :filename="registerDocument.filename"
                :title="registerDocument.title"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";

export default {
  data() {
    return {
      roundDecimals: null,
    };
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
    }),

    sortedSeries() {
      // These steps are needed to avoid mutating the original array of actions
      let series = [...this.shareholderRegister.shareholder_register_series];

      return series.sort((a, b) => Number(a.share_type) - Number(b.share_type));
    },

    registerSeriesEmpty() {
      return isEmpty(this.shareholderRegister.register_series_as_hash);
    },

    registerDocuments() {
      return this.shareholderRegister.documents.filter(
        (registerDocument) => registerDocument.key == null
      );
    },

    registerSeriesTotal() {
      const hash = this.shareholderRegister.register_series_as_hash[0];
      const series = [];
      Object.entries(hash).forEach(([shareType, shares]) => {
        shares.series.forEach((serie) => {
          series.push({
            start: serie.start,
            end: serie.end,
            share_type: shareType,
          });
        });
      });
      return series;
    },

    formattedCapital() {
      let capital = this.shareholderRegister.capital;
      let currency = this.shareholderRegister.currency.toUpperCase();

      return `${BigNumber(capital).decimalPlaces(
        this.roundDecimals
      )} ${currency}`;
    },

    formattedQuota() {
      let quota = this.shareholderRegister.quota;
      let currency = this.shareholderRegister.currency.toUpperCase();

      return `${BigNumber(quota).decimalPlaces(
        this.roundDecimals
      )} ${currency}`;
    },
  },

  mounted() {
    this.roundDecimals = this.$config.SHAREHOLDER_REGISTER.ROUND_DECIMALS;
  },
};
</script>
