<template>
  <div>
    <div v-if="success">
      <lottie-animation
        class="py-3"
        :animation-data="imageSrc('lottie/confetti.json')"
        loop
        :speed="1.0"
      />

      <h2>{{ $t("components.user.registration_form.success_title") }}</h2>

      <p>{{ $t("components.user.registration_form.success_description") }}</p>

      <ul>
        <li>{{ $t("components.user.registration_form.success_item1") }}</li>

        <li>{{ $t("components.user.registration_form.success_item2") }}</li>

        <li>{{ $t("components.user.registration_form.success_item3") }}</li>
      </ul>
    </div>

    <div v-else-if="alreadyRegistered">
      <lottie-animation
        class="py-3"
        :animation-data="imageSrc('lottie/confetti.json')"
        loop
        :speed="1.0"
      />

      <p>{{ $t("components.user.registration_form.already_registered") }}</p>
    </div>

    <div v-else>
      <h2 v-if="registrationTitle">
        {{ registrationTitle }}
      </h2>

      <be-form-group
        label-for="name"
        :label="$t('activerecord.attributes.user.full_name')"
        :error="getErrors(user, 'name')"
      >
        <be-form-input
          id="name"
          v-model="user.name"
          autocomplete="name"
          required
          @change="clearErrors(user, 'name')"
        />
      </be-form-group>

      <be-form-group
        label-for="email"
        :label="$t('activerecord.attributes.user.email')"
        :error="getErrors(user, 'email')"
      >
        <be-form-input
          id="email"
          v-model="user.email"
          type="email"
          autocomplete="username"
          required
          :readonly="emailReadonly"
          @change="clearErrors(user, 'email')"
        />
      </be-form-group>

      <!-- PasswordInput includes a BeFormGroup -->
      <password-input
        id="user-password"
        v-model="user.password"
        name="user-password"
        required
        :error="getErrors(user, 'password')"
        :state="validationState(user, ['password'])"
        @change="clearErrors(user, ['password'])"
      />

      <be-form-group :error="getErrors(user, 'accept_terms')">
        <be-form-checkbox
          id="accept_terms"
          v-model="user.accept_terms"
          :state="validationState(user, 'accept_terms')"
          @change="clearErrors(user, 'accept_terms')"
        >
          {{ $t("components.user.registration_form.read_and_agree_to") }}
          <be-link :href="termsOfUseUrl">
            <u>{{ $t("application.terms_of_use") }}</u>
          </be-link>
        </be-form-checkbox>
      </be-form-group>

      <be-form-group
        :label-for="user.country"
        :label="$t('components.user.registration_form.do_not_fill')"
        :class="countryClass"
      >
        <be-form-input
          :id="user.country"
          v-model="country"
          autocomplete="off"
          tabindex="-1"
        />
      </be-form-group>

      <be-button
        variant="dark"
        size="lg"
        block
        class="mt-4"
        :loading="loading"
        @click="submitUser"
      >
        {{ buttonText }}
      </be-button>
    </div>

    <hr />

    <div>
      {{ $t("login.already_user") }}
      <be-link :href="`/users/sign_in?locale=${$i18n.locale}`">
        {{ $t("login.login") }}
      </be-link>
    </div>
  </div>
</template>

<script>
import { LottieAnimation } from "lottie-web-vue";
import Config from "@/config.js";

import PasswordInput from "@/components/authentication/PasswordInput.vue";

export default {
  components: {
    LottieAnimation,
    PasswordInput,
  },

  props: {
    initialUser: {
      type: Object,
      required: true,
    },

    countryClass: {
      type: String,
      required: true,
    },

    partnerCode: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      user: this.cloneDeep(this.initialUser),
      emailReadonly: !this.initialUser.editable_email,
      success: false,
      alreadyRegistered: false,
      minPasswordLength: Config.PASSWORD_MIN_LENGTH,
      country: "", // Fake field
    };
  },

  computed: {
    termsOfUseUrl() {
      if (this.$i18n.locale == "sv") {
        return "https://boardeaser.com/villkor/";
      } else {
        return "https://boardeaser.com/en/terms-and-conditions/";
      }
    },

    registrationTitle() {
      if (this.partnerCode) {
        return this.$t("components.user.registration_form.sign_up_w_code", {
          code_title: this.partnerCode.title,
        });
      } else if (this.user?.campaign) {
        // Hide the title for the trial campaign - requested by marketing
        if (
          this.user.campaign.title === this.$t("models.campaigns.titles.trial")
        ) {
          return null;
        }

        return this.user.campaign.title;
      } else {
        return this.$t("components.user.registration_form.sign_up");
      }
    },

    buttonText() {
      if (this.user?.campaign) {
        return this.user.campaign.button_text;
      } else {
        return this.$t("components.user.registration_form.sign_up");
      }
    },
  },

  methods: {
    async submitUser() {
      this.loading = true;

      try {
        const { data } = await axios.post("/users", {
          spinner: this.user.spinner,
          [this.user.country]: this.country, // Fake field

          user: {
            name: this.user.name,
            email: this.user.email,
            password: this.user.password,
            accept_terms: this.user.accept_terms,
            locale: this.$i18n.locale,
            campaign: this.user.campaign?.key,
          },
        });

        // Success and empty data is for real
        if (!data) {
          this.success = true;
          this.user = null;
        } else {
          // Success with data in body means invisible_captcha failed
          // Reload the window but keep email and name by adding to query string
          window.location.search = `?email=${this.user.email}&name=${this.user.name}&edit=true`;
        }
      } catch (error) {
        if (error.response?.status === 422) {
          this.user = {
            ...this.user,
            ...error.response.data,
          };
        } else if (error.response?.status === 409) {
          this.alreadyRegistered = true;
          setTimeout(() => {
            window.location = "/home/dashboard";
          }, 2500);
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
