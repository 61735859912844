<template>
  <tr v-if="!destroyed">
    <td>
      {{ question.title }}
    </td>

    <td class="col-shrink d-none d-md-table-cell">
      <template v-if="question.company_title">
        {{ question.company_title }}
      </template>
    </td>

    <td class="col-shrink d-none d-md-table-cell">
      {{ $d(new Date(question.created_at), "dateTime") }}
    </td>

    <td class="col-shrink">
      <be-dropdown size="sm" ellipsis>
        <template v-if="publishedQuestions">
          <be-dropdown-item
            :href="url(`/educations/questions/${question.id}/unpublish`)"
            :data-confirm="
              $t('views.educations.questions.table.unpublish_warning')
            "
            data-method="POST"
          >
            {{ $t("views.educations.questions.table.unpublish") }}
          </be-dropdown-item>

          <be-dropdown-divider />
        </template>

        <be-dropdown-item variant="danger" @click="removeRow()">
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </td>
  </tr>
</template>

<script>
import TextUtilities from "@/mixins/textUtilities";

export default {
  mixins: [TextUtilities],

  props: {
    question: {
      type: Object,
      required: true,
    },

    publishedQuestions: {
      type: Boolean,
    },
  },

  emits: ["row-removed"],

  data() {
    return {
      destroyed: false,
    };
  },

  methods: {
    async removeRow() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: this.truncateText(this.question.title, 64),
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(
            this.url(`/educations/questions/${this.question.id}`)
          );

          this.destroyed = true;
          this.$emit("row-removed", response.data);
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
