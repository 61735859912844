<template>
  <be-table
    v-if="tokenCount > 0"
    :fields="fields"
    :items="tokens"
    sort-by="created_at"
  >
    <template #template_type="{ item }">
      {{ $t(`models.company_token.types.${item.token_type.toLowerCase()}`) }}
    </template>

    <template #value="{ item }">
      {{ item.email_as_string }}
    </template>

    <template #created_at="{ item }">
      {{ $d(new Date(item.created_at), "dateTime") }}
    </template>

    <template #options="{ item }">
      <be-button
        variant="outline-secondary"
        size="sm"
        inline
        @click="copyEmail(item)"
      >
        {{ $t("buttons.titles.copy") }}
      </be-button>

      <be-dropdown v-if="item.policy.inactivate" size="sm" inline ellipsis>
        <template v-if="canInactivate(item)">
          <be-dropdown-item @click="inactivate(item)">
            {{ $t("buttons.titles.inactivate") }}
          </be-dropdown-item>

          <be-dropdown-divider />
        </template>

        <be-dropdown-item variant="danger" @click="remove(item)">
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </template>
  </be-table>

  <be-alert v-else variant="info">
    {{ noTokensText }}
  </be-alert>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    tokens: {
      type: Array,
      required: false,
      default: () => [],
    },

    tokenCount: {
      type: Number,
      required: false,
      default: 0,
    },

    tabState: {
      type: String,
      required: false,
      default: "active-tokens",
    },
  },

  emits: ["token-inactivate", "token-remove"],

  computed: {
    fields() {
      return [
        {
          key: "template_type",

          label: this.$i18n.t(
            "activerecord.attributes.company_token.token_type"
          ),

          class: "col-shrink",
          sortable: false,
        },
        {
          key: "value",
          label: this.$i18n.t("models.company_token.email"),
          sortable: true,
        },
        {
          key: "created_at",

          label: this.$i18n.t(
            "activerecord.attributes.company_token.created_at"
          ),

          class: "col-shrink",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ];
    },

    noTokensText() {
      if (this.tabState === "active-tokens") {
        return this.$t("views.companies.tokens.tokens_table.no_active_tokens");
      }

      return this.$t("views.companies.tokens.tokens_table.no_inactive_tokens");
    },
  },

  methods: {
    async copyEmail(token) {
      try {
        await navigator.clipboard.writeText(token.email_as_string);

        EventBus.emit("new-notification", {
          message: this.$i18n.t("components.company_tokens.email_copied"),
          status: "notice",
        });
      } catch (error) {
        EventBus.emit("new-notification", {
          message: this.$i18n.t("components.company_tokens.email_not_copied"),
          status: "danger",
        });
      }
    },

    inactivate(token) {
      this.$emit("token-inactivate", token);
    },

    remove(token) {
      this.$emit("token-remove", token);
    },

    canInactivate(token) {
      return token.policy.inactivate && this.tabState == "active-tokens";
    },
  },
};
</script>
