<template>
  <div class="card">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("application.custom_fields") }}
      </h5>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
          <p>
            {{
              $t(
                "components.contracts.custom_fields.introduction.description_1"
              )
            }}
          </p>

          <p>
            {{
              $t(
                "components.contracts.custom_fields.introduction.description_2"
              )
            }}
          </p>

          <be-button
            v-if="platformAdmin"
            v-be-modal.add-custom-fields
            variant="outline-primary"
          >
            {{ $t("models.custom_field.new") }}
          </be-button>

          <add-custom-field-modal
            :object="{}"
            object-type="Contract"
            @custom-field-added="$emit('custom-field-added', $event)"
          />
        </div>

        <div class="col-md-6 order-1 order-md-2 mb-3 mb-md-0">
          <be-img :src="imageSrc('onboarding/intro/custom_fields.png')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddCustomFieldModal from "@/components/shared/AddCustomFieldModal.vue";

export default {
  components: {
    AddCustomFieldModal,
  },

  emits: ["custom-field-added"],
};
</script>
