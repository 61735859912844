<template>
  <form @submit.prevent="saveSettings">
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="card mb-2">
          <div class="card-header">
            {{ $t("admin_panels.users.settings.edit.contact_information") }}
          </div>

          <div class="card-body">
            <!-- Name -->
            <be-form-group
              :label="$t('activerecord.attributes.user.full_name')"
              label-for="name"
              :error="getErrors(localUser, 'name')"
              required
            >
              <be-form-input
                id="name"
                v-model="localUser.name"
                :disabled="isSaving"
                @change="clearErrors(localUser, 'name')"
              />
            </be-form-group>

            <!-- Email -->
            <be-form-group
              :label="$t('activerecord.attributes.user.email')"
              label-for="email"
            >
              <be-form-input id="email" v-model="localUser.email" readonly />
            </be-form-group>

            <!-- Phone -->
            <be-form-group
              :label="$t('activerecord.attributes.user.phone')"
              label-for="phone"
              :error="getErrors(localUser, 'phone')"
            >
              <be-tel-input
                id="phone"
                v-model="localUser.phone"
                :disabled="isSaving"
                @change="clearErrors(localUser, 'phone')"
              />
            </be-form-group>
          </div>
        </div>

        <manage-identifications
          v-if="showManageIdentifications"
          :loaded-identifications="user.identifications"
        />
      </div>

      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">
            {{ $t("activerecord.models.company.other") }}
          </div>

          <div class="card-body">
            <table class="table table-hover">
              <tbody>
                <tr v-for="company in companies" :key="company.id">
                  <td>
                    <be-link :href="url(`/companies/${company.nanoid}`)">
                      {{ company.title }}
                    </be-link>
                  </td>

                  <td class="col-shrink">
                    <be-dropdown size="sm" ellipsis>
                      <be-dropdown-item
                        :href="url(`/companies/${company.nanoid}/edit`)"
                      >
                        {{ $t("buttons.titles.edit") }}
                      </be-dropdown-item>
                    </be-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="card-footer d-md-none justify-content-end">
            <be-button
              variant="primary"
              type="submit"
              :loading="isSaving"
              block
            >
              {{ $t("buttons.titles.save") }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <div class="d-none d-md-flex justify-content-end">
      <be-button variant="primary" type="submit" :loading="isSaving">
        {{ $t("buttons.titles.save") }}
      </be-button>
    </div>
  </form>
</template>

<script>
import ManageIdentifications from "@/components/admin_panel/users/ManageIdentifications.vue";

export default {
  components: {
    ManageIdentifications,
  },

  props: {
    companies: {
      type: Array,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      localUser: this.cloneDeep(this.user),
    };
  },

  computed: {
    showManageIdentifications() {
      return this.$theme.show_client_manager_manage_identifications;
    },
  },

  methods: {
    async saveSettings() {
      this.isSaving = true;

      try {
        const { data } = await axios.patch(
          this.url(`/users/${this.localUser.id}`),
          {
            user: this.localUser,
          }
        );

        // Redirect to the user page
        window.location.href = this.url(`/users/${data.id}`);
      } catch (error) {
        if (error.response.status === 422) {
          this.localUser = error.response.data;
        } else {
          this.handleError(error);
        }

        this.isSaving = false;
      }
    },
  },
};
</script>
