<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="row">
          <div class="col-12 col-lg-7">
            <be-form-group
              :label="
                $t('activerecord.attributes.shareholder_register.capital')
              "
              label-for="added_capital"
              :error="getErrors(action, ['added_capital'])"
            >
              <be-form-input
                id="added_capital"
                v-model="addedCapital"
                type="number"
                name="shareholder_register_action[added_capital]"
                required
                @input="updateQuota()"
                @change="clearErrors(action, ['added_capital'])"
              />
            </be-form-group>

            <be-form-group
              label-for="number_of_shares"
              :label="
                $t(
                  'activerecord.attributes.shareholder_register_action.number_of_shares'
                )
              "
              :error="getErrors(action, ['number_of_shares'])"
            >
              <be-form-input
                id="number_of_shares"
                v-model="numberOfShares"
                type="number"
                name="shareholder_register_action[number_of_shares]"
                @input="updateQuota()"
                @change="clearErrors(action, ['number_of_shares'])"
              />
            </be-form-group>

            <be-form-group
              v-if="!inEdit"
              label-for="share_price"
              :label="
                $t(
                  'activerecord.attributes.shareholder_transaction.share_price'
                )
              "
            >
              <be-form-input
                id="share_price"
                v-model="sharePrice"
                type="number"
                name="shareholder_register_action[price]"
              />
            </be-form-group>
          </div>
        </div>
      </div>

      <div v-if="!inEdit" class="col-12 col-lg-7">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-right">
                  {{
                    $t(
                      "models.shareholder_register_action.shares_left_to_assign"
                    )
                  }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register_action.shares_assigned") }}
                </th>

                <th class="text-right">
                  {{
                    `${$t("models.shareholder_register.quota")} (${
                      shareholderRegister.currency
                    })`
                  }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register.value_above_quota") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="text-right">
                  {{ sharesToDistribute }}
                </td>

                <td v-if="distributedShares > 0" class="text-right">
                  {{ distributedShares }}
                </td>

                <td v-else class="text-right">-</td>

                <td v-if="quota > 0" class="text-right">
                  {{ formattedQuota }}
                </td>

                <td v-else class="text-right">-</td>

                <td class="text-right">-</td>
              </tr>
            </tbody>
          </table>
        </div>

        <be-alert variant="info">
          {{
            $t(
              "components.shareholder_registers.company_formation.quota_as_help"
            )
          }}
        </be-alert>
      </div>
    </div>

    <template v-if="!inEdit">
      <hr />

      <h3 :class="newShareholders.length > 0 ? 'mb-3' : 'mb-1'">
        {{ $t("activerecord.models.shareholder.other") }}
      </h3>

      <new-shareholder-transaction
        v-for="(transaction, index) in newShareholderTransactions"
        :key="`new-shareholder-${transaction.id || generateUuid()}`"
        :index="index"
        :action="action"
        :initial-transaction="transaction"
        :share-price="sharePrice"
        name-prefix="shareholder_register_action[shareholder_transactions_attributes]"
        @new-shareholder-removed="removeNewShareholder"
        @transaction-changed="updateNewShareholder"
      />
    </template>

    <div class="mt-2">
      <be-button
        v-if="!action.id"
        variant="light"
        icon="fa-plus"
        @click="addShareholder"
      >
        {{ $t("models.shareholder.add") }}
      </be-button>
    </div>

    <duplicate-shareholders-warning
      v-if="!action.id"
      :shareholders="newShareholders"
    />
  </div>
</template>

<script>
import NewShareholderTransaction from "./NewShareholderTransaction.vue";
import shareholderRegisterMixins from "@/mixins/shareholderRegisters";
import DuplicateShareholdersWarning from "../../DuplicateShareholdersWarning.vue";

export default {
  components: {
    DuplicateShareholdersWarning,
    NewShareholderTransaction,
  },

  mixins: [shareholderRegisterMixins],

  props: {
    shareholderRegister: {
      type: Object,
      required: true,
    },

    action: {
      type: Object,
      required: true,
    },

    currentQuota: {
      type: String,
      required: true,
    },

    newTransactions: {
      type: Array,
      required: false,
      default: () => [],
    },

    inEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      addedCapital: this.action.added_capital
        ? this.action.added_capital
        : null,

      newShareholderTransactions: this.newTransactions,

      numberOfShares: this.action.number_of_shares
        ? this.action.number_of_shares
        : null,

      quota: 0,
      roundDecimals: null,
      sharePrice: this.action.price ? this.action.price.toString() : null,
      shareType: this.action.share_type ? this.action.share_type : "0",
    };
  },

  computed: {
    distributedShares() {
      return this.newShareholderTransactions
        .filter((newTransaction) => newTransaction.number_of_shares)
        .reduce(
          (acc, transaction) => acc + parseInt(transaction.number_of_shares),
          0
        );
    },

    sharesToDistribute() {
      if (this.numberOfShares) {
        return this.numberOfShares - this.distributedShares;
      }

      return "-";
    },

    formattedQuota() {
      let currency = this.shareholderRegister.currency.toUpperCase();
      let quota = BigNumber(this.quota).decimalPlaces(this.roundDecimals);

      return `${quota} ${currency}`;
    },

    newShareholders() {
      const activeNewShareholderTransactions =
        this.newShareholderTransactions.filter(
          (transaction) => !transaction.destroyed
        );

      return activeNewShareholderTransactions.map(
        (shareholderTransaction) => shareholderTransaction.buyer || {}
      );
    },
  },

  mounted() {
    this.roundDecimals = this.$config.SHAREHOLDER_REGISTER.ROUND_DECIMALS;

    if (this.newShareholderTransactions) {
      this.newShareholderTransactions.forEach((transaction) => {
        this.addTemporaryIdToTransaction(transaction);
      });
    }
  },

  methods: {
    updateQuota() {
      this.quota = parseFloat(
        this.addedCapital / parseFloat(this.numberOfShares).toFixed(13)
      );
    },

    addShareholder() {
      let transaction = {
        id: this.generateUuid(),
        destroyed: false,

        buyer: {
          id: this.generateUuid(),
        },
      };

      this.newShareholderTransactions.push(transaction);
    },

    addTemporaryIdToTransaction(transaction) {
      transaction.id = this.generateUuid();

      if (transaction.buyer) {
        transaction.buyer.id = this.generateUuid();
      }
    },

    removeNewShareholder(transaction) {
      const idx = this.newShareholderTransactions.findIndex(
        (existingTransaction) => existingTransaction.id == transaction.id
      );
      if (idx > -1) {
        const transaction = this.newShareholderTransactions[idx];
        transaction.destroyed = 1;
        this.newShareholderTransactions.splice(idx, 1);
      }
    },

    updateNewShareholder(transaction) {
      const idx = this.newShareholderTransactions.findIndex(
        (existingTransaction) => existingTransaction.id == transaction.id
      );
      this.newShareholderTransactions[idx] = transaction;
    },

    valueNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
  },
};
</script>
