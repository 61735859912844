<template>
  <be-alert
    v-if="duplicateShareholderIdentificationNumbers"
    variant="warning"
    class="mt-2"
  >
    {{
      $t(
        "components.shareholder_registers.shareholder_form.duplicate_identification_numbers"
      )
    }}
  </be-alert>
</template>

<script>
export default {
  props: {
    shareholders: {
      type: Array,
      required: true,
    },
  },

  computed: {
    duplicateShareholderIdentificationNumbers() {
      const activeShareholders = this.shareholders.filter(
        (shareholder) =>
          !shareholder.destroyed && shareholder.identification_number
      );
      const unique = [
        ...new Set(
          activeShareholders.map(
            (shareholder) => shareholder.identification_number
          )
        ),
      ];
      return unique.length != activeShareholders.length;
    },
  },
};
</script>
