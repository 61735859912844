<template>
  <be-modal
    :id="modalId"
    size="lg"
    :title="modalTitle"
    :ok-disabled="loading || uploadState !== 'added'"
    :ok-title="$t('buttons.titles.upload')"
    @hidden="modalClosed"
    @ok="modalOk"
  >
    <be-alert v-if="material.external_uploaded_at">
      {{
        $t(
          "components.meetings.material.upload_modal.already_uploaded_w_type",
          {
            type: $t(
              `activerecord.attributes.material.${material.material_type}`
            ).toLowerCase(),
          }
        )
      }}
    </be-alert>

    <be-alert v-else-if="hasItems" variant="warning">
      {{
        $t(
          "components.meetings.material.upload_modal.upload_external_info_w_type",
          {
            type: $t(
              `activerecord.attributes.material.${material.material_type}`
            ).toLowerCase(),
          }
        )
      }}
    </be-alert>

    <be-form-group
      :label="$t(`activerecord.attributes.material.${material.material_type}`)"
      label-for="material-uploader"
    >
      <document-uploader
        id="material-uploader"
        :accepted-types="['pdf']"
        :documents="[document]"
        :max-files="1"
        support-select-from-archive
        @document-added="documentAdded"
        @document-removed="documentRemoved"
        @uploading="(value) => (loading = value)"
      />
    </be-form-group>
  </be-modal>
</template>

<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },

    material: {
      type: Object,
      required: true,
    },

    hasItems: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["material-updated"],

  data: () => ({
    loading: false,
    document: {},
  }),

  computed: {
    modalTitle() {
      if (this.material.material_type === "agenda") {
        return this.$t(
          "components.meetings.material.upload_modal.upload_agenda"
        );
      } else {
        return this.$t(
          "components.meetings.material.upload_modal.upload_minutes"
        );
      }
    },

    // Document selected from archive have upload_state directly on document
    uploadState() {
      return (
        this.document.uploaded_file?.upload_state || this.document.upload_state
      );
    },
  },

  methods: {
    modalOk(beModalEvent) {
      beModalEvent.preventDefault();
      this.uploadDocument();
    },

    modalClosed() {
      this.document = {};
    },

    documentAdded(document) {
      this.document = document;
    },

    documentRemoved() {
      this.document = {};
    },

    async uploadDocument() {
      try {
        this.loading = true;
        const { data } = await axios.post(this.material.paths.upload, {
          document: this.document,
        });

        this.$emit("material-updated", data);
        this.$beModal.hide("material-upload");
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
