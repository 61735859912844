<template>
  <ul class="fa-ul ml-4">
    <template v-if="items.length < 4 || expanded">
      <li v-for="item in items" :key="item">
        <slot name="bullet" />

        <slot name="item" :item="item">
          {{ item }}
        </slot>
      </li>
    </template>

    <template v-else>
      <li v-for="item in items.slice(0, 2)" :key="item">
        <slot name="bullet" />

        <slot name="item" :item="item">
          {{ item }}
        </slot>
      </li>
    </template>

    <li v-if="items.length >= 4">
      <slot name="bullet" />

      <be-link @click="expanded = !expanded">
        <slot v-if="!expanded" name="more" :more-items-count="items.length - 2">
          {{
            $t(
              "components.user.merge_requests.merge_request_user.list_summary.display_more_w_item_type_and_count",
              { item_type: itemType, count: items.length - 2 }
            )
          }}
        </slot>

        <slot v-else name="fewer" :fewer-items-count="items.length - 2">
          {{
            $t(
              "components.user.merge_requests.merge_request_user.list_summary.display_fewer_w_item_type",
              { item_type: itemType }
            )
          }}
        </slot>

        <i
          :class="['fal ml-1', expanded ? 'fa-chevron-up' : 'fa-chevron-down']"
        />
      </be-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    itemType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      expanded: false,
    };
  },
};
</script>
