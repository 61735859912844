<template>
  <be-modal
    :id="id"
    :title="$t('components.meetings.material.minutes.unlock_modal.title')"
    size="lg"
    @show="onShow"
    @okclose="resetForm"
  >
    <be-alert show variant="info" class="mb-2">
      {{ unlockInfoText }}
    </be-alert>

    <be-form-group
      :label="
        $t('components.meetings.material.minutes.unlock_modal.message_label')
      "
      label-for="message"
    >
      <be-form-textarea id="message" v-model="message" rows="3" max-rows="20" />
    </be-form-group>

    <be-table
      class="mb-2"
      :items="usersAndSelections"
      :fields="fields"
      @row-clicked="(item) => checked(item)"
    >
      <template #select="{ item }">
        <be-form-group
          :label="
            $t(
              'components.meetings.material.minutes.unlock_modal.select_to_request'
            )
          "
          label-sr-only
          class="m-0"
        >
          <be-form-checkbox
            v-model="item.selected"
            @change="(value) => checked(item, value)"
          />
        </be-form-group>
      </template>

      <template #avatar="{ item }">
        <user-avatar
          v-if="item.user_id || item.id"
          :user="item.user_id || item.id"
        />
      </template>

      <template #name="{ item }">
        {{ item.name }}
      </template>

      <template #informed="{ item }">
        <template v-if="item.informedAt">
          <be-badge variant="success">
            {{ item.informedAt }}
          </be-badge>
        </template>
      </template>

      <template #function="{ item }">
        <template v-if="item.function">
          {{ $t(`models.attendance.functions.${item.function}`) }}
        </template>
      </template>
    </be-table>

    <be-button
      size="sm"
      variant="outline-primary"
      @click="checkAll(noneSelected)"
    >
      {{
        noneSelected
          ? $t("components.meetings.material.minutes.unlock_modal.mark_all")
          : $t("components.meetings.material.minutes.unlock_modal.mark_none")
      }}
    </be-button>

    <be-alert v-if="upcomingMeetingSigned" show variant="warning" class="mt-2">
      {{
        $t(
          "components.meetings.material.minutes.unlock_modal.alerts.upcoming_meeting_minutes_signed"
        )
      }}
    </be-alert>

    <be-alert v-if="isBeyondTimeframe" show variant="warning" class="mt-2">
      {{
        $t(
          "components.meetings.material.minutes.unlock_modal.alerts.beyond_recommended_time_frame"
        )
      }}
    </be-alert>

    <be-alert v-if="noneSelected" show variant="warning" class="mt-2">
      {{
        $t(
          "components.meetings.material.minutes.unlock_modal.no_receivers_warning"
        )
      }}
    </be-alert>

    <template #footer>
      <be-button variant="light" @click="onCancel">
        {{ $t("buttons.titles.close") }}
      </be-button>

      <be-button
        variant="primary"
        :disabled="noneSelected || !canSend"
        :loading="processing"
        @click="onSend"
      >
        {{ $t("buttons.titles.send") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { isBefore, subMonths } from "date-fns";
import RequestHandler from "@/mixins/RequestHandler";

const MEETING_UNLOCKING_TIME_FRAME = 6; // months
const MEETING_UNLOCKING_MIN_AMOUNT = 2;

export default {
  mixins: [RequestHandler],

  props: {
    id: {
      type: String,
      default: "unlock-minutes-request-modal",
      required: false,
    },

    meeting: {
      type: Object,
      required: true,
    },

    minutes: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      processing: false,
      haveFetched: false,
      message: "",
      selected: {},
      informedUsers: [],
      availableUserIds: [],
      unlocking: null,
      upcomingMeetingSigned: false,
    };
  },

  computed: {
    ...mapGetters({
      companyUsers: "company/users",
    }),

    users() {
      const result = [];
      for (const user of this.companyUsers) {
        if (this.availableUserIds.includes(user.id)) {
          result.push(user);
        }
      }
      return result;
    },

    usersAndSelections() {
      return this.users.map((user) => {
        return {
          ...user,
          selected: this.selected[user.id] == true,
          informedAt: this.getInformed(user),
        };
      });
    },

    selectedUsers() {
      return this.users.filter((user) => this.selected[user.id] !== undefined);
    },

    noneSelected() {
      return !Object.values(this.selected).some((value) => value);
    },

    fields() {
      return [
        {
          key: "select",

          label: this.$t(
            "components.meetings.material.minutes.unlock_modal.send_to"
          ),

          class: "col-shrink text-center",
        },
        { key: "avatar", label: "", class: "col-shrink text-center" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        { key: "informed", label: "", class: "text-right" },
        {
          key: "function",
          label: this.translateAttribute("attendance", "function"),
        },
      ];
    },

    isBoardmember() {
      return this.$currentMembership?.function !== "external";
    },

    minUnlockersNeeded() {
      return (
        Number.parseInt(this.$currentCompany.preferences.number_of_unlockers) ||
        MEETING_UNLOCKING_MIN_AMOUNT
      );
    },

    haveMultipleUnlockers() {
      return this.minUnlockersNeeded > 1;
    },

    isBeyondTimeframe() {
      const approvedAt = new Date(this.approvedAt);
      const timeFrameEnd = subMonths(new Date(), MEETING_UNLOCKING_TIME_FRAME);
      return isBefore(approvedAt, timeFrameEnd);
    },

    unlockInfoText() {
      let text = "";
      text += this.$t(
        this.haveMultipleUnlockers
          ? "components.meetings.material.minutes.unlock_modal.info.other"
          : "components.meetings.material.minutes.unlock_modal.info.one",
        { count: this.minUnlockersNeeded }
      );

      if (this.isBoardmember) {
        text += this.$t(
          this.haveMultipleUnlockers
            ? "components.meetings.material.minutes.unlock_modal.info_board_member.other"
            : "components.meetings.material.minutes.unlock_modal.info_board_member.one"
        );
      }

      return text;
    },

    canSend() {
      return this.selectedUsers.length >= this.minUnlockersNeeded;
    },
  },

  methods: {
    async onSend() {
      const usersAttributes = this.selectedUsers.map((user) => ({
        id: user.id,
        selected: this.selected[user.id],
      }));

      const data = {
        companies_unlocking_request_form: {
          message: this.message,
          users_attributes: usersAttributes,
        },
      };

      try {
        let url = `${this.meeting.paths.base}/minutes/unlocking_requests`;
        if (this.unlocking?.id) {
          url = `${url}/${this.unlocking.id}`;
        }

        await this.makeRequest(
          {
            method: this.unlocking?.id ? "patch" : "post",
            url,
            data,
          },
          {}
        );
        this.$beModal.hide(this.id);
        this.showSuccess();
      } catch (error) {
        this.handleError(error);
      }
    },

    onCancel() {
      this.$beModal.hide(this.id);
      if (!this.haveFetched) {
        this.resetForm();
      }
    },

    async onShow() {
      try {
        const response = await axios.get(
          `${this.meeting.paths.base}/minutes/unlocking_requests`
        );

        this.availableUserIds = response.data.available_user_ids;
        this.unlocking = response.data.unlocking;
        this.upcomingMeetingSigned = response.data.upcoming_meeting_signed;
        this.haveFetched = true;
        this.message = this.unlocking?.message || "";

        if (this.unlocking.users) {
          this.unlocking.users.forEach((fetchedUser) => {
            const user = this.users.find((user) => user.id === fetchedUser.id);

            if (user) {
              this.checked(user, true);
            }
          });
        }
      } catch (error) {
        this.haveFetched = false;
        this.handleError(error);
      }
    },

    resetForm() {
      this.message = "";
      this.processing = false;
      this.selected = {};
    },

    getInformed(user) {
      const foundUser = this.informedUsers.find(
        (informedUser) => informedUser.id === user.id
      );
      if (foundUser) {
        return this.$t(
          "components.meetings.material.minutes.unlock_modal.sent_at",
          { date: this.$d(new Date(foundUser.informed_at)) }
        );
      }
    },

    showSuccess() {
      window.location.reload(true);
    },

    checked(user, value) {
      let checkedValue = value;
      if (checkedValue == null || checkedValue == undefined) {
        checkedValue = !this.selected[user.id];
      }
      this.selected[user.id] = checkedValue;
    },

    checkAll(value) {
      this.users.forEach((user) => {
        this.selected[user.id] = value;
      });
    },
  },
};
</script>
