<template>
  <span>
    <slot v-bind="{ formattedDurationRemaining, timerFinished }">
      {{ formattedDurationRemaining }}
    </slot>
  </span>
</template>

<script>
import { intervalToDuration, formatDuration, compareDesc } from "date-fns";
import { sv, enUS } from "date-fns/locale";

export default {
  name: "BeTimer",

  props: {
    finishAt: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentTime: null,
      timer: null,
    };
  },

  computed: {
    finishAtAsDate() {
      return new Date(this.finishAt);
    },

    timerFinished() {
      return compareDesc(this.currentTime, this.finishAtAsDate) !== 1;
    },

    durationRemaining() {
      return intervalToDuration({
        start: this.currentTime,
        end: this.finishAtAsDate,
      });
    },

    formattedDurationRemaining() {
      if (!this.currentTime || this.timerFinished) {
        return null;
      }

      return formatDuration(this.durationRemaining, {
        locale: this.dateFnsLocale,
      });
    },

    dateFnsLocale() {
      const localeString = this.$i18n.locale;
      if (localeString === "sv") {
        return sv;
      } else {
        return enUS;
      }
    },
  },

  mounted() {
    this.updateCurrentTime();

    this.timer = setInterval(() => {
      this.updateCurrentTime();
    }, 1000);
  },

  beforeUnmount() {
    clearInterval(this.timer);
  },

  methods: {
    updateCurrentTime() {
      this.currentTime = new Date();
    },
  },
};
</script>
