<template>
  <be-dropdown variant="light" size="sm">
    <template #button-content>
      <span class="text-primary">{{ title }}</span>

      <be-badge variant="light" class="mx-1">
        {{ formattedDuration }}
      </be-badge>
    </template>

    <be-dropdown-form>
      <be-form-datepicker
        :model-value="time"
        type="time"
        :config="{ arrowLeft: '0px' }"
        time-inline
        auto-apply
        unstyled-wrapper
        is-24
        @input="valueUpdated"
      />
    </be-dropdown-form>
  </be-dropdown>
</template>
<script>
import { secondsToHMS, secondsToHM } from "@/utils/time";

export default {
  props: {
    modelValue: {
      type: Number,
      required: false,
      default: 0,
    },

    title: {
      type: String,
      required: true,
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      time: this.convertSecondsToTime(this.modelValue),
    };
  },

  computed: {
    formattedDuration() {
      return secondsToHM(this.modelValue);
    },

    valueAsString() {
      return this.modelValue ? secondsToHMS(this.modelValue) : "00:00";
    },
  },

  watch: {
    value(newValue) {
      this.time = this.convertSecondsToTime(newValue);
    },
  },

  methods: {
    valueUpdated(value) {
      this.$emit("update:modelValue", this.convertTimeToSeconds(value));
    },

    convertSecondsToTime(seconds) {
      return {
        hours: Math.floor(seconds / 3600),
        minutes: Math.floor((seconds % 3600) / 60),
      };
    },

    convertTimeToSeconds(time) {
      return time.hours * 3600 + time.minutes * 60;
    },
  },
};
</script>
