import formatIdentificationNumber from "@/utils/format-identification-number";
import isMobile from "is-mobile";
import { EventBus } from "@/event-bus";

export default {
  data() {
    return {
      collectInterval: null,
      qrCodeInterval: null,
      retries: 0,
      max_retries: 20,
      orderRef: null,
      autoStartToken: null,

      // Can be overridden in the component
      bankIdIdentificationNumber: "",
      bankIdCollectUrl: "/bank_id/collect/session",
      bankIdRedirectOnSuccess: true,
      bankIdPending: false,
      bankIdMessage: "",
      bankIdState: "",
      bankIdHintCode: null,
      qrData: null,
    };
  },

  computed: {
    autoStartUrl() {
      if (this.autoStartToken === null) {
        return null;
      }

      if (isMobile()) {
        // Based on https://github.com/BankID/SampleCode/blob/a021c3aabf22231f9c57cb24646099fca50fd063/client/src/pages/OpenMobileApp/OpenMobileApp.js
        const isChromeOnAppleDevice = !!navigator.userAgent.match(/CriOS/);
        const isFirefoxOnAppleDevice = !!navigator.userAgent.match(/FxiOS/);
        const isOperaTouchOnAppleDevice = !!navigator.userAgent.match(/OPT/);
        const isAppleDevice =
          !!navigator.userAgent.match(/iPad|iPhone|iPod/) && !window.MSStream;

        if (this.returningToOauth) {
          // If we are signing in and returning to the oauth flow after sign in, we most likely started the sign in from the iOS app
          return `https://app.bankid.com/?autostarttoken=${
            this.autoStartToken
          }&redirect=${encodeURIComponent("se.boardeaser.be-ios://")}`;
        } else if (isChromeOnAppleDevice) {
          return `https://app.bankid.com/?autostarttoken=${
            this.autoStartToken
          }&redirect=${encodeURIComponent("googlechrome://")}`;
        } else if (isFirefoxOnAppleDevice) {
          return `https://app.bankid.com/?autostarttoken=${
            this.autoStartToken
          }&redirect=${encodeURIComponent("firefox://")}`;
        } else if (isOperaTouchOnAppleDevice) {
          return `https://app.bankid.com/?autostarttoken=${
            this.autoStartToken
          }&redirect=${encodeURIComponent(
            this.collectReturnUrl.replace("http", "touch-http")
          )}`;
        } else if (isAppleDevice) {
          return `https://app.bankid.com/?autostarttoken=${
            this.autoStartToken
          }&redirect=${encodeURIComponent(
            `${window.location.href}#bank-id-return`
          )}`;
        } else {
          return `https://app.bankid.com/?autostarttoken=${this.autoStartToken}&redirect=null`;
        }
      } else {
        return `bankid:///?autostarttoken=${this.autoStartToken}&redirect=null`;
      }
    },

    collectReturnUrl() {
      return `${window.location.origin}${this.bankIdCollectUrl}?use_v6=true&collection[order_ref]=${this.orderRef}`;
    },

    canStartBankId() {
      return this.bankIdIdentificationNumber?.length == 12;
    },

    bankIdCollectUrlWithLocale() {
      const url = new URL(this.bankIdCollectUrl, document.location);
      if (this.locale) {
        url.searchParams.set("locale", this.locale);
      }
      return url.toString();
    },
  },

  methods: {
    async createOrder() {
      if (this.bankIdPending) {
        throw new Error("BankID order already exists");
      }

      this.bankIdPending = true;
      this.bankIdMessage = this.$t("bankid.user_messages.rfa1");
      this.retries = 0;

      try {
        const { data } = await axios.post("/bank_id/authentication", {
          api_version: "v6",
        });

        this.orderRef = data.order_ref;
        localStorage.setItem("lastBankIdOrderRef", this.orderRef);

        if (this.orderRef === undefined) {
          this.bankIdPending = false;
          this.bankIdMessage = this.$t("bankid.user_messages.rfa22");
          return;
        }

        await this.collect();
        this.collectInterval = setInterval(this.collect, 2000);
        this.autoStartToken = data.auto_start_token;

        if (this.mode === "qr") {
          this.qrData = data.qr_data;
          // Start polling for QR code
          await this.pollQrCode();
        }

        this.qrCodeInterval = setInterval(this.pollQrCode, 1000);
      } catch (error) {
        this.bankIdPending = false;
        this.bankIdMessage = this.$t("bankid.user_messages.rfa22");
        this.handleError(error);
      }
    },

    async startMobileBankId() {
      await this.createOrder({ qr: true });
    },

    async startBankIdOnCard() {
      this.bankIdIdentificationNumber = "";
      await this.createOrder();
      window.location.href = this.autoStartUrl;
    },

    async pollQrCode() {
      try {
        if (this.mode === "qr") {
          const { data } = await axios.get("/bank_id/authentication", {
            params: {
              order_ref: this.orderRef,
            },
          });
          this.qrData = data.qr_data;
        }
      } catch (error) {
        this.qrData = null;
        this.stopPollingQrCode();
        this.stopCollect();
        this.handleError(error);
      }
    },

    async collect() {
      try {
        const { data } = await axios.post(this.bankIdCollectUrlWithLocale, {
          api_version: "v6",

          collection: {
            order_ref: this.orderRef,
          },
        });

        this.bankIdState = data.state;
        this.bankIdMessage = data.message || "";

        if (data.hint_code) {
          this.bankIdHintCode = data.hint_code;
        }

        if (this.bankIdState === "successful") {
          this.$emit("bankid-collect-success");
          EventBus.emit("bankid-collect-success");

          if (this.didActivate !== undefined) {
            this.didActivate = true;
          }

          if (this.bankIdRedirectOnSuccess) {
            // Check that the path is not to some other domain by comparing the current location's
            // origin to the received path. The URL constructor will ignore the second argument if
            // the domain is already present in the first argument.

            const url = new URL(data.path, window.location.origin);
            if (data.path && url.origin == window.location.origin) {
              window.location = url;
            } else {
              window.location.reload();
            }
          }
          this.stopCollect();
        } else if (this.bankIdState === "pending") {
          // Status is pending, do nothing
        } else {
          // Failed somehow, stop collecting
          this.stopCollect();
        }
      } catch (error) {
        if (
          error.response?.status == 422 &&
          error.response?.data?.state === "abort"
        ) {
          // Received abort status from BankID
          this.stopCollect();
        }
        this.retries = this.retries + 1;
        if (this.retries > this.max_retries) {
          console.error("Failed to collect BankID status, stopping collect");
          this.stopCollect();
        }
        this.handleError(error);
      }
    },

    stopCollect() {
      clearInterval(this.collectInterval);
      this.bankIdPending = false;
    },

    stopPollingQrCode() {
      clearInterval(this.qrCodeInterval);
      this.bankIdPending = false;
      this.qrData = null;
    },

    async cancelBankId() {
      try {
        await axios.post("/bank_id/cancellation", {
          api_version: "v6",

          cancellation: {
            order_ref: this.orderRef,
          },
        });

        this.bankIdMessage = "";
      } catch (error) {
        this.handleError(error);
      } finally {
        this.stopCollect();
        this.stopPollingQrCode();
      }
    },

    formatIdentificationNumber,
  },
};
