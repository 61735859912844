<template>
  <be-dropdown :id="`messageMenuDropDown-${message.id}`" size="sm" ellipsis>
    <be-dropdown-item v-be-modal="`link-modal-${message.id}`">
      {{ $t("buttons.titles.share") }}
    </be-dropdown-item>

    <template v-if="allowedToEdit">
      <be-dropdown-item @click.prevent="toggleEditArea">
        {{ $t("components.companies.message_threads.edit") }}
      </be-dropdown-item>

      <be-dropdown-divider />

      <be-dropdown-item variant="danger" @click.prevent="deleteMessage">
        {{ $t("buttons.titles.remove") }}
      </be-dropdown-item>
    </template>
  </be-dropdown>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      required: true,
    },

    allowedToEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["delete-message", "toggle-edit-area"],

  methods: {
    deleteMessage() {
      this.$emit("delete-message");
    },

    toggleEditArea() {
      this.$emit("toggle-edit-area");
    },
  },
};
</script>
