<template>
  <div>
    <h3 class="mb-3" v-text="$t('components.meetings.tabs.agenda.title')" />

    <div class="row">
      <div class="col-12 col-md-6 mb-4">
        <be-skeleton-table
          v-if="agendaStatus === 'loading'"
          :rows="3"
          :columns="2"
        />

        <be-alert v-else-if="agendaStatus === 'error'" variant="danger">
          {{ $t("components.meetings.tabs.failed_to_load") }}
        </be-alert>

        <template v-else-if="agendaStatus === 'loaded'">
          <meeting-status
            v-if="meeting.process.active_state === 'agenda'"
            :meeting="meeting"
            :agenda="agenda"
          />

          <div class="mb-4">
            <be-button
              v-if="agenda && agenda.id && canEdit"
              variant="outline-primary"
              :href="`${meeting.paths.base}/agenda/edit`"
            >
              {{ $t("models.material.agenda.edit") }}
            </be-button>

            <template v-else-if="!agenda.id && meeting.policy.update">
              <be-button variant="outline-primary" @click="createAgenda">
                {{ $t("models.material.agenda.create") }}
              </be-button>
            </template>

            <template v-if="agenda && agenda.id && agenda.policy.show">
              <be-button
                variant="outline-secondary"
                :href="`${meeting.paths.base}/agenda`"
              >
                {{ $t("models.material.agenda.show") }}
              </be-button>
            </template>
          </div>
        </template>
      </div>

      <div
        v-if="
          folderStatus === 'loaded' && agendaStatus !== 'error' && folder.id
        "
        class="col-12 col-md-6"
      >
        <meeting-documents :folder="folder" :meeting="meeting" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import materialRequests from "@/requests/material";

import MeetingDocuments from "./components/MeetingDocuments.vue";
import MeetingStatus from "../material/MeetingStatus.vue";
import MaterialMixin from "@/mixins/meetings/material";

export default {
  components: {
    MeetingDocuments,
    MeetingStatus,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      meetings: "meetings/getMeetings",
    }),

    agenda() {
      return this.getAgenda(this.meeting);
    },

    agendaStatus() {
      return this.getAgendaStatus(this.meeting);
    },

    folder() {
      return this.getFolder(this.meeting);
    },

    folderStatus() {
      return this.getFolderStatus(this.meeting);
    },

    canEdit() {
      return this.agenda && !this.agenda.sealed && this.agenda.policy?.update;
    },

    previousMeeting() {
      // We rely on meetings to be sorted by number
      const previousMeetings = this.meetings.filter(
        (existing) => existing.number && existing.number < this.meeting.number
      );

      if (previousMeetings.length > 0) {
        return previousMeetings[previousMeetings.length - 1];
      } else {
        return null;
      }
    },

    previousMinutes() {
      return this.getMinutes(this.previousMeeting);
    },
  },

  mounted() {
    this.fetchAgendaForMeeting(this.meeting);
    this.fetchFolderForMeeting(this.meeting);
  },

  methods: {
    createAgenda() {
      materialRequests.createAgenda(this.meeting);
    },
  },
};
</script>
