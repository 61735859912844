<template>
  <div
    v-if="
      flipperFlag('meeting_series_flipper') && $platform.features.meeting_series
    "
    class="card mb-2"
  >
    <div
      class="card-header border-bottom-0 rounded d-lg-flex justify-content-end"
    >
      <be-form-group class="mb-0">
        <be-form-select
          id="meeting-series-type"
          v-model="selectedMeetingType"
          :options="[
            {
              text: $t(
                'components.meetings.meeting_series_type_filter.board_meetings'
              ),

              value: 'board',
            },
            {
              text: $t(
                'components.meetings.meeting_series_type_filter.annual_meetings'
              ),

              value: 'annual',
            },
          ]"
          :include-blank-option="
            $t('components.meetings.meeting_series_type_filter.all')
          "
        />
      </be-form-group>
    </div>
  </div>

  <div id="meeting-list">
    <template v-if="loading">
      <div v-for="index in 5" :key="index" class="card mb-4">
        <be-skeleton-table :rows="2" :columns="2" />
      </div>
    </template>

    <meeting-display
      v-for="meeting in meetings"
      v-else
      :key="`meeting-${meeting.id}`"
      :initial-meeting="meeting"
    />
  </div>

  <be-pagination
    v-if="pagination && pagination.totalPages > 1"
    class="mt-3"
    :model-value="pagination.currentPage"
    :total-rows="pagination.totalCount"
    :per-page="pagination.pageItems"
    aria-controls="meeting-list"
    @change="paginationChanged"
  />
</template>
<script>
export default {
  data() {
    return {
      pagination: this.parsePagination({}),
      meetings: [],
      selectedMeetingType: "",
    };
  },

  watch: {
    selectedMeetingType: {
      handler: "filterMeetings",
    },
  },

  async created() {
    await this.fetchMeetings();
  },

  methods: {
    parsePagination(headers) {
      return {
        currentPage: parseInt(headers["current-page"]) || 1,
        pageItems: parseInt(headers["page-items"]) || 10,
        totalCount: parseInt(headers["total-count"]) || 0,
        totalPages: parseInt(headers["total-pages"]) || 0,
      };
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.fetchMeetings();
    },

    async fetchMeetings(resetPage) {
      this.loading = true;
      window.scrollTo(0, 0);
      try {
        const page = resetPage ? 1 : this.pagination.currentPage;
        const { data, headers } = await axios.get(this.url("/meetings"), {
          params: {
            active_meetings_page: page,
            query: { meeting_series_type_in: this.selectedMeetingType },
          },
        });

        this.pagination = this.parsePagination(headers);
        this.meetings = data;
        this.$store.dispatch("meetings/addMeetings", data);
      } finally {
        this.loading = false;
      }
    },

    async filterMeetings() {
      await this.fetchMeetings(true);
    },
  },
};
</script>
