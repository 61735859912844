<template>
  <tr v-if="!participant.destroyed">
    <td>
      <be-form-group
        :error="getErrors(participant.user, ['name'])"
        class="mb-0"
      >
        <be-form-input
          :name="`education_group[education_group_participants_attributes][${index}][user_attributes][name]`"
          :model-value="participant.user.name"
          :placeholder="$t('views.educations.groups.new.user_name')"
          @change="clearErrors(participant.user, ['name'])"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group
        :error="getErrors(participant.user, ['email'])"
        class="mb-0"
      >
        <be-form-input
          :name="`education_group[education_group_participants_attributes][${index}][user_attributes][email]`"
          :model-value="participant.user.email"
          :placeholder="$t('views.educations.groups.new.email')"
          @change="clearErrors(participant.user, ['email'])"
        />
      </be-form-group>

      <input
        type="hidden"
        :value="participant.destroyed"
        :name="`education_group[education_group_participants_attributes][${index}][_destroy]`"
      />

      <input
        v-if="educationGroup.id"
        type="hidden"
        :value="educationGroup.id"
        :name="`education_group[education_group_participants_attributes][${index}][education_group_id]`"
      />
    </td>

    <td class="col-shrink">
      <be-button
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeParticipant(participant)"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    educationGroup: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    participant: {
      type: Object,
      required: true,
    },
  },

  emits: ["new-participant-removed"],

  methods: {
    removeParticipant(participant) {
      this.$emit("new-participant-removed", participant);
    },
  },
};
</script>
