<template>
  <div class="row justify-content-center">
    <div class="col-lg-9">
      <header class="mb-4">
        <h1>
          {{
            $t("views.companies.documents.compliances.edit.title_w_title", {
              title: compliance.title,
            })
          }}
        </h1>
      </header>

      <div class="d-flex flex-column">
        <be-tabs v-model="activeTab" :options="tabsData">
          <template #content-right>
            <be-dropdown ellipsis>
              <be-dropdown-item
                :href="url(`/documents/compliances/${localCompliance.id}`)"
              >
                {{ $t("buttons.titles.show") }}
              </be-dropdown-item>

              <be-dropdown-divider />

              <be-dropdown-item variant="danger" @click="deleteCompliance">
                {{ $t("buttons.titles.remove") }}
              </be-dropdown-item>
            </be-dropdown>
          </template>
        </be-tabs>

        <div class="card">
          <div class="card-body">
            <div v-if="activeTab === 'versions'">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        {{
                          $t(
                            "activerecord.models.compliance_document_version.other"
                          )
                        }}
                      </th>

                      <th
                        class="col-shrink"
                        v-text="
                          $t('components.compliances.editor.document_count')
                        "
                      />

                      <th
                        class="col-shrink"
                        v-text="
                          $t(
                            'components.compliances.editor.document_version_date'
                          )
                        "
                      />

                      <th class="col-shrink" />
                    </tr>
                  </thead>

                  <tbody>
                    <version-row
                      v-for="version in sortedVersions"
                      :key="`version-${version.id}`"
                      :compliance="localCompliance"
                      :version="version"
                      :compliance-documents="
                        complianceDocumentsByVersion[version.id] || []
                      "
                      @edit="toggleEditVersion"
                      @remove="removeVersion"
                      @archive="createAndArchive"
                    />
                  </tbody>
                </table>
              </div>

              <be-button
                variant="outline-secondary"
                @click="modalCreateArchivedVersion"
              >
                {{ $t("components.compliances.editor.new_archived_version") }}
              </be-button>

              <be-modal
                id="version-modal"
                :title="modalTitle"
                :ok-title="$t('buttons.titles.save')"
                size="lg"
                no-footer
              >
                <version-form
                  :compliance="localCompliance"
                  :compliance-version="complianceVersion"
                  :compliance-documents="
                    complianceDocumentsByVersion[complianceVersion.id] || []
                  "
                  @compliance-document-added="addComplianceDocument"
                  @compliance-document-removed="removeComplianceDocument"
                  @save-version="saveVersion"
                />
              </be-modal>
            </div>

            <div v-if="activeTab === 'current'">
              <version-form
                v-if="complianceVersion.id"
                :compliance="localCompliance"
                :compliance-version="complianceVersion"
                :compliance-documents="
                  complianceDocumentsByVersion[complianceVersion.id] || []
                "
                @compliance-document-added="addComplianceDocument"
                @compliance-document-removed="removeComplianceDocument"
                @archive-version="createAndArchive"
                @save-version="saveVersion"
              />

              <template v-else>
                <be-alert variant="info">
                  {{ $t("components.compliances.editor.no_current_version") }}
                </be-alert>

                <be-button variant="primary" @click="createAndArchive">
                  {{ $t("components.compliances.editor.new_version") }}
                </be-button>
              </template>
            </div>

            <div v-if="activeTab === 'settings'">
              <be-form-group
                label-for="compliance-title"
                :label="translateAttribute('compliance', 'title')"
                :error="getErrors(localCompliance, 'title')"
              >
                <be-form-input
                  id="compliance-title"
                  v-model="localCompliance.title"
                  :disabled="!localCompliance.custom"
                  required
                  @change="clearErrors(localCompliance, 'title')"
                />
              </be-form-group>

              <be-form-group
                label-for="compliance-role"
                :label="translateAttribute('compliance', 'role')"
                :description="$t('models.compliance.hints.role')"
                :error="getErrors(localCompliance, 'role')"
              >
                <be-form-select
                  id="compliance-role"
                  v-model="localCompliance.role"
                  :options="roleOptions"
                  :state="validationState(localCompliance, 'role')"
                  @change="clearErrors(localCompliance, 'role')"
                />
              </be-form-group>

              <template v-if="remindersWorking">
                <be-form-group
                  label-for="compliance-update_reminder_1"
                  :label="translateAttribute('compliance', 'update_reminder_1')"
                  :description="$t('models.compliance.hints.update_reminder_1')"
                  :error="getErrors(localCompliance, 'update_reminder_1')"
                >
                  <be-form-select
                    id="compliance-update_reminder_1"
                    v-model="localCompliance.update_reminder_1"
                    :options="reminderOptions"
                    :state="
                      validationState(localCompliance, 'update_reminder_1')
                    "
                    @change="clearErrors(localCompliance, 'update_reminder_1')"
                  />
                </be-form-group>

                <be-form-group
                  label-for="compliance-update_reminder_2"
                  :label="translateAttribute('compliance', 'update_reminder_2')"
                  :description="$t('models.compliance.hints.update_reminder_2')"
                  :error="getErrors(localCompliance, 'update_reminder_2')"
                >
                  <be-form-select
                    id="compliance-update_reminder_2"
                    v-model="localCompliance.update_reminder_2"
                    :options="reminderOptions"
                    :state="
                      validationState(localCompliance, 'update_reminder_2')
                    "
                    @change="clearErrors(localCompliance, 'update_reminder_2')"
                  />
                </be-form-group>

                <be-form-group
                  label-for="compliance-read_reminder"
                  :label="translateAttribute('compliance', 'read_reminder')"
                  :description="$t('models.compliance.hints.read_reminder')"
                  :error="getErrors(localCompliance, 'read_reminder')"
                >
                  <be-form-select
                    id="compliance-read_reminder"
                    v-model="localCompliance.read_reminder"
                    :options="reminderOptions"
                    :state="validationState(localCompliance, 'read_reminder')"
                    @change="clearErrors(localCompliance, 'read_reminder')"
                  />
                </be-form-group>
              </template>

              <div class="text-right">
                <be-button variant="primary" @click="saveCompliance">
                  {{ $t("buttons.titles.save") }}
                </be-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmNavigation from "@/mixins/ConfirmNavigation";
import RequestHandler from "@/mixins/RequestHandler";

import VersionForm from "./VersionForm.vue";
import VersionRow from "./VersionRow.vue";

export default {
  components: {
    VersionForm,
    VersionRow,
  },

  mixins: [ConfirmNavigation, RequestHandler],

  props: {
    compliance: {
      type: Object,
      required: true,
    },

    roles: {
      type: Array,
      required: true,
    },

    lifeSpan: {
      type: Array,
      required: true,
    },

    reminderValues: {
      type: Array,
      required: true,
    },
  },

  data() {
    const localCompliance = this.cloneDeep(this.compliance);
    const currentComplianceVersion =
      localCompliance.compliance_document_versions.find(
        (version) => version.current
      );

    return {
      localCompliance: this.cloneDeep(this.compliance),
      activeTab: "current",
      remindersWorking: false, // These are not implemented currently

      complianceVersion: currentComplianceVersion || {
        id: null,
        title: "",
        start_at: new Date(),
        end_at: null,
      },
    };
  },

  computed: {
    tabsData() {
      const data = {
        current: {
          value: "current",
          label: this.$t("components.compliances.editor.tabs.current"),
        },
      };

      if (this.sortedVersions.length > 0) {
        data.versions = {
          value: "versions",
          label: this.$t("components.compliances.editor.tabs.versions"),
        };
      }

      data.settings = {
        value: "settings",
        label: this.$t("components.compliances.editor.tabs.settings"),
      };

      return data;
    },

    modalTitle() {
      if (this.complianceVersion.id) {
        return this.$t("components.compliances.editor.update_version_w_title", {
          title: this.complianceVersion.title,
        });
      } else {
        return this.$t("components.compliances.editor.new_archived_version");
      }
    },

    complianceDocumentsByVersion() {
      const complianceDocumentsByVersion = {};

      this.localCompliance.compliance_documents.forEach(
        (complianceDocument) => {
          const versionId = complianceDocument.compliance_document_version_id;
          if (!complianceDocumentsByVersion[versionId]) {
            complianceDocumentsByVersion[versionId] = [];
          }
          complianceDocumentsByVersion[versionId].push(complianceDocument);
        }
      );

      return complianceDocumentsByVersion;
    },

    currentComplianceDocuments() {
      return this.complianceDocumentsByVersion[this.complianceVersion.id] || [];
    },

    currentDocuments() {
      return this.currentComplianceDocuments.map((doc) => doc.document);
    },

    currentVersion() {
      return this.localCompliance.compliance_document_versions.find(
        (version) => version.current
      );
    },

    roleOptions() {
      return this.roles.map((group) => {
        return {
          label: group[0],

          options: group[1].map((role) => {
            return {
              text: role[0],
              value: role[1],
            };
          }),
        };
      });
    },

    reminderOptions() {
      return [{ value: null, text: " " }].concat(
        this.reminderValues.map((reminder) => {
          return {
            text: reminder[0],
            value: reminder[1],
          };
        })
      );
    },

    lifeSpanOptions() {
      return [{ value: null, text: " " }].concat(
        this.lifeSpan.map((span) => {
          return {
            text: span[0],
            value: span[1],
          };
        })
      );
    },

    sortedVersions() {
      return [...this.localCompliance.compliance_document_versions].sort(
        (a, b) => new Date(b.start_at) - new Date(a.start_at)
      );
    },
  },

  watch: {
    activeTab(value) {
      if (value === "current") {
        this.complianceVersion = this.cloneDeep(this.currentVersion) || {
          id: null,
          title: "",
          start_at: new Date(),
          end_at: null,
        };
      }
    },
  },

  methods: {
    toggleEditVersion(version) {
      if (version.current) {
        this.activeTab = "current";
        return;
      } else {
        this.activeTab = "versions";
      }
      this.complianceVersion = this.cloneDeep(version);
      this.$beModal.show("version-modal");
    },

    modalCreateArchivedVersion() {
      this.complianceVersion = {
        id: null,
        title: "",
        start_at: null,
        end_at: null,
      };
      this.$beModal.show("version-modal");
    },

    addComplianceDocument(complianceDocument) {
      const index = this.localCompliance.compliance_documents.findIndex(
        (existing) => existing.id === complianceDocument.id
      );

      if (index === -1) {
        this.localCompliance.compliance_documents.push(complianceDocument);
      } else {
        this.localCompliance.compliance_documents[index] = complianceDocument;
      }
    },

    removeComplianceDocument(complianceDocument) {
      const index = this.localCompliance.compliance_documents.findIndex(
        (existing) => existing.id === complianceDocument.id
      );

      if (index > -1) {
        this.localCompliance.compliance_documents.splice(index, 1);
      }
    },

    complianceVersionSaved(version) {
      this.complianceVersion = version;
      const index = this.localCompliance.compliance_document_versions.findIndex(
        (existing) => existing.id === version.id
      );

      if (index > -1) {
        this.localCompliance.compliance_document_versions[index] = version;
      } else {
        this.localCompliance.compliance_document_versions.push(version);
      }

      if (version.current) {
        this.localCompliance.compliance_document_versions.forEach(
          (existing) => {
            if (existing.id !== version.id) {
              existing.current = false;
            }
          }
        );
      }
    },

    versionPeriod(version) {
      const value = [this.$d(new Date(version.start_at), "date")];

      if (version.end_at) {
        value.push("-");
        value.push(this.$d(new Date(version.end_at), "date"));
      }

      return value.join(" ");
    },

    async saveCompliance() {
      try {
        const response = await axios.patch(
          this.url(`/documents/compliances/${this.localCompliance.id}`),
          {
            compliance: {
              ...this.localCompliance,
            },
          }
        );
        this.localCompliance = response.data;
      } catch (error) {
        if (error?.response?.status === 422) {
          this.localCompliance = error.response.data;
        }

        this.handleError(error);
      }
    },

    async deleteCompliance() {
      try {
        const isConfirmed = await this.promptRemovalConfirm({
          title: this.$t("nav.confirm_delete_w_title", {
            title: this.localCompliance.title,
          }),

          description: this.$t(
            "components.compliances.editor.confirm_delete_compliance_text"
          ),

          stayOpenAfterConfirm: true,
        });

        if (!isConfirmed) {
          return;
        }

        await axios.delete(
          this.url(`/documents/compliances/${this.localCompliance.id}`)
        );

        window.location.href = this.url("/documents/compliances");
      } catch (error) {
        this.handleError(error);
      }
    },

    async createAndArchive() {
      try {
        if (this.currentVersion?.id) {
          const isConfirmed = await this.promptConfirm({
            confirmButtonText: this.$t(
              "components.compliances.editor.archive_current_version"
            ),

            title: this.$t(
              "components.compliances.editor.confirm_archive_current_title_w_period",
              {
                period: this.versionPeriod(this.currentVersion),
              }
            ),

            description: this.$t(
              "components.compliances.editor.confirm_archive_current_text"
            ),
          });

          if (!isConfirmed) {
            return;
          }
        }

        const { data } = await axios.post(
          this.url(
            `/documents/compliances/${this.localCompliance.id}/archive_version`
          )
        );

        this.complianceVersionSaved(data);

        if (data.current) {
          this.activeTab = "current";
          this.complianceVersion = data;
        } else {
          this.activeTab = "versions";
          this.toggleEditVersion(data);
        }
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.complianceVersion = error.response.data;
          if (error.response.data.current) {
            this.activeTab = "current";
          } else {
            this.activeTab = "versions";
            this.toggleEditVersion(this.complianceVersion);
          }
        } else {
          this.handleError(error);
        }
      }
    },

    async removeVersion(version) {
      try {
        await this.makeRequest(
          {
            method: "delete",

            url: this.url(
              `/documents/compliances/${this.localCompliance.id}/versions/${version.id}`
            ),
          },
          {
            confirmRemoval: true,

            confirmMessage: this.$t("nav.confirm_delete_w_title", {
              title: version.title,
            }),
          }
        );

        this.localCompliance.compliance_document_versions =
          this.localCompliance.compliance_document_versions.filter(
            (existing) => existing.id !== version.id
          );
      } catch (error) {
        this.handleError(error);
      }
    },

    saveVersion(version) {
      if (version.id) {
        this.updateVersion(version);
      } else {
        this.createVersion(version);
      }
    },

    async createVersion(version) {
      try {
        const { data } = await axios.post(
          this.url(
            `/documents/compliances/${this.localCompliance.id}/versions`
          ),
          {
            compliance_version: version,
          }
        );
        this.complianceVersionSaved(data);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.complianceVersion = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    async updateVersion(version) {
      try {
        const { data } = await axios.patch(
          this.url(
            `/documents/compliances/${this.localCompliance.id}/versions/${version.id}`
          ),
          {
            compliance_version: version,
          }
        );
        this.complianceVersionSaved(data);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.complianceVersion = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
