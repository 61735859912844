<template>
  <div class="card-body">
    <be-file-viewer
      :file-url="material.external_document.default_file.url"
      file-type="application/pdf"
    />

    <template v-if="externalAttachments.length > 0">
      <hr />

      <h3 class="mt-3">
        {{ $t("components.meetings.material.attachments.title") }}
      </h3>

      <ul>
        <li
          v-for="attachment in externalAttachments"
          :key="`attachment-${attachment.id}`"
        >
          <document-link
            :document-id="attachment.id"
            :filename="attachment.title"
          />
        </li>
      </ul>
    </template>

    <div
      v-for="(decision, index) in decisions"
      :key="`item-decision-${decision.id}`"
      class="mb-3"
    >
      <h4 class="d-flex align-items-center mb-1">
        {{ $t("activerecord.models.decision.one") }}
        <small class="text-muted ml-1">
          {{ `(${meeting.number}-${index + 1})` }}
        </small>
      </h4>

      <p
        v-if="decision.description"
        v-dompurify-html="decision.description"
        class="text-pre-wrap mb-0"
      />

      <small v-if="decision.user_id || decision.due_at">
        <template v-if="decision.user_name">
          <strong>
            {{ $t("activerecord.attributes.decision.assigned_to") }}:
          </strong>

          {{ decision.user_name }}

          <template v-if="decision.due_at">
            <span>•</span>
          </template>
        </template>

        <template v-if="decision.due_at">
          <strong>
            {{ $t("activerecord.attributes.decision.due_at") }}:
          </strong>
          {{ formatDateInStockholm(new Date(decision.due_at), "date") }}
        </template>
      </small>
    </div>

    <div v-for="task in tasks" :key="`item-task-${task.id}`" class="mb-3">
      <h4 class="mb-1">
        {{ $t("activerecord.models.task.one") }}
      </h4>

      <p v-dompurify-html="task.description" class="text-pre-wrap mb-0" />

      <small v-if="task.user_id || task.due_at">
        <template v-if="task.user_name">
          <strong>
            {{ $t("activerecord.attributes.task.assigned_to") }}:
          </strong>

          {{ task.user_name }}

          <template v-if="task.due_at">
            <span>•</span>
          </template>
        </template>

        <template v-if="task.due_at">
          <strong> {{ $t("activerecord.attributes.task.due_at") }}: </strong>
          {{ formatDateInStockholm(new Date(task.due_at), "date") }}
        </template>
      </small>
    </div>
  </div>
</template>

<script>
import { formatDateInStockholm } from "@/utils/time";

export default {
  props: {
    material: {
      type: Object,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    attachments: {
      type: Array,
      required: true,
    },

    meeting: {
      type: Object,
      required: true,
    },
  },

  computed: {
    decisions() {
      if (this.item) {
        return this.$store.getters["material/getItemDecisions"](this.item);
      }

      return [];
    },

    tasks() {
      if (this.item) {
        return this.$store.getters["material/getItemTasks"](this.item);
      }

      return [];
    },

    externalAttachments() {
      if (this.material.material_type === "minutes") {
        return this.attachments.filter((attachment) => {
          return attachment.selected;
        });
      } else {
        return this.item.documents;
      }
    },
  },

  methods: {
    formatDateInStockholm,
  },
};
</script>
