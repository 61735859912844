<template>
  <div>
    <be-form-group>
      <document-uploader
        :accepted-types="acceptedTypes"
        :documents="documents"
        :max-files="1"
        @document-added="handleDocumentAdded"
        @uploading="uploading"
      />
    </be-form-group>

    <be-alert
      v-for="doc in invalidUploads"
      :key="doc.filename"
      class="mt-2"
      variant="danger"
    >
      {{ failedUploadMessage(doc) }}
    </be-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";

export default {
  mixins: [AnnualReportTabBase],

  props: {
    documentKey: {
      type: String,
      required: true,
    },
  },

  emits: ["uploading"],

  computed: {
    ...mapGetters("annual_reports", ["invalidUploadedDocuments"]),

    documents() {
      const key = `${this.documentKey}_document`;
      if (key in this.annualReport) {
        const document = this.annualReport[key];
        if (document) {
          return [document];
        } else {
          return [];
        }
      }
      return [];
    },

    invalidUploads() {
      return this.invalidUploadedDocuments.filter(
        (doc) =>
          doc.key === this.documentKey && doc.owner_id === this.annualReport.id
      );
    },

    fields() {
      return [
        { key: "title", label: "", class: "shrink" },
        { key: "button", label: "", class: "shrink" },
      ];
    },

    acceptedTypes() {
      if (
        this.ixbrlAllowed &&
        [
          "annual_report",
          "audit_report",
          "annual_report_w_certification",
        ].includes(this.documentKey)
      ) {
        if (this.annualReport.delivery_method == "digital") {
          return ["xhtml"];
        } else {
          return ["pdf", "xhtml"];
        }
      }
      return ["pdf"];
    },
  },

  methods: {
    ...mapActions("annual_reports", ["uploadDocument"]),

    async handleDocumentAdded(document) {
      this.uploading(false);
      this.uploadDocument({
        annualReport: this.annualReport,
        key: this.documentKey,
        doc: document,
      });
    },

    uploading(value) {
      this.$emit("uploading", value);
    },

    failedUploadMessage(doc) {
      return this.$t(
        "components.annual_reports.documents.invalid_document_w_filename_w_error",
        {
          filename: doc.filename,

          error: [
            doc.errors?.uploaded_file || [
              this.$t("components.annual_reports.documents.unknown_error"),
            ],
          ].join(", "),
        }
      );
    },
  },
};
</script>
