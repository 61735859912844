<template>
  <div class="row">
    <div class="col-12 col-lg-auto col-xl-2">
      <be-form-group
        v-if="!inEdit"
        :label="
          $t('activerecord.attributes.shareholder_transaction.share_type')
        "
        label-for="share_type"
        required
      >
        <be-form-select
          id="share_type"
          v-model="localAction.share_type"
          name="shareholder_register_action[share_type]"
          :options="availableShareTypes"
          required
        />
      </be-form-group>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import shareholderRegisterMixins from "@/mixins/shareholderRegisters";

export default {
  mixins: [shareholderRegisterMixins],

  props: {
    action: {
      type: Object,
      required: true,
    },

    inEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      localAction: this.cloneDeep(this.action),
    };
  },

  computed: {
    ...mapGetters({ shareTypeKeys: "shareholder_register/saleShareTypeKeys" }),

    availableShareTypes() {
      const options = this.cloneDeep(this.formattedShareTypes);
      options.forEach((option) => {
        if (!this.shareTypeKeys.includes(option.value)) {
          option.disabled = true;
        } else {
          delete option["disabled"];
        }
      });
      return options;
    },
  },

  watch: {
    action(value) {
      this.localAction = this.cloneDeep(value);
      this.setShareType(this.shareTypeKeys);
    },

    shareTypeKeys: {
      handler(values) {
        this.setShareType(values);
      },

      deep: true,
    },
  },

  mounted() {
    this.setShareType(this.shareTypeKeys);
  },

  methods: {
    setShareType(values) {
      if (!values.includes(this.localAction.share_type)) {
        this.localAction.share_type = values[0];
      }
    },
  },
};
</script>
