<template>
  <div v-if="features.length > 0" class="row">
    <div
      v-for="feature in features"
      :key="`feature-${feature.feature_type}-${feature.id}`"
      :class="[layoutClasses]"
    >
      <companies-shop-feature
        :feature="feature"
        :layout="layout"
        :trial="trial"
      />
    </div>
  </div>

  <div v-else class="card">
    <div class="card-body">
      <be-alert variant="info">
        {{ $t(`components.shop.${featureType}.empty`) }}
      </be-alert>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    features: {
      type: Array,
      required: true,
    },

    featureType: {
      type: String,
      required: true,
    },

    layout: {
      type: String,
      required: true,
    },

    trial: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    layoutClasses() {
      if (this.layout === "grid") {
        return "col-12 col-md-6 col-lg-4 mb-4";
      } else {
        return "col-12 mb-3";
      }
    },
  },
};
</script>
