<template>
  <div>
    <template v-if="!processingStarted">
      <form
        id="shareholder_register_form"
        :action="getUrl"
        novalidate="novalidate"
        accept-charset="UTF-8"
        method="POST"
      >
        <input name="utf8" type="hidden" value="✓" />

        <input type="hidden" name="authenticity_token" :value="$csrfToken" />

        <input id="locale" type="hidden" name="locale" value="sv" />

        <input id="_method" type="hidden" name="_method" value="PUT" />

        <!-- If everything whent ok when adding/parsing shareholders -->
        <template v-if="hideForm">
          <div v-if="showCapitalFields" class="row">
            <div class="col-4 mb-2">
              <be-form-group
                :label="
                  $t('activerecord.attributes.shareholder_register.capital')
                "
                label-for="capital"
              >
                <be-form-input
                  id="capital"
                  v-model="capital"
                  name="shareholder_register[capital]"
                  required
                />
              </be-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <be-button variant="outline-primary" @click="showForm()">
                {{
                  $t(
                    "components.shareholder_registers.shareholder_form.add_more_members"
                  )
                }}
              </be-button>
            </div>
          </div>
        </template>

        <div v-else-if="showCapitalFields" class="row">
          <div class="col-4">
            <!-- If errors when adding/parsing shareholders -->
            <be-form-group
              :label="
                $t('activerecord.attributes.shareholder_register.capital')
              "
              label-for="capital"
            >
              <be-form-input
                id="capital"
                v-model="capital"
                name="shareholder_register[capital]"
                required
              />
            </be-form-group>
          </div>
        </div>

        <div v-if="!hideForm" class="table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  {{
                    $t(
                      "companies.shareholder_registers.transactions.company_fields.shareholder_type"
                    )
                  }}*
                </th>

                <th>{{ $t("activerecord.attributes.shareholder.name") }}*</th>

                <th>
                  {{ `${$t("activerecord.attributes.shareholder.email")}` }}
                </th>

                <th>
                  <span
                    v-be-tooltip="
                      $t(
                        'components.shareholder_registers.shareholder_form.identification_number_tooltip'
                      )
                    "
                  >
                    {{
                      $t(
                        "activerecord.attributes.shareholder.identification_number"
                      )
                    }}
                    *
                    <i class="fal fa-question-circle"></i>
                  </span>
                </th>

                <th>
                  <span
                    v-be-tooltip="
                      $t(
                        'components.shareholder_registers.shareholder_form.company_name_tooltip'
                      )
                    "
                  >
                    {{
                      $t(
                        "activerecord.attributes.shareholder.legal_person_name"
                      )
                    }}
                    (*)
                    <i class="fal fa-question-circle"></i>
                  </span>
                </th>

                <th>
                  {{ $t("activerecord.attributes.shareholder.share_count") }}*
                </th>

                <th class="col-shrink"></th>
              </tr>
            </thead>

            <tbody>
              <shareholder-row
                v-for="(shareholder, index) in newShareholders"
                :key="'new-shareholder-' + index"
                :index="shareholders.length + index"
                :shareholder="shareholder"
                @shareholder-remove="removeShareholder"
                @shareholder-change="changeShareholder"
              />
            </tbody>

            <tfoot>
              <tr>
                <td colspan="7">
                  <be-button
                    variant="outline-primary"
                    class="mt-2"
                    size="sm"
                    inline
                    @click="addNewShareholder()"
                  >
                    {{ $t("models.shareholder.add") }}
                  </be-button>
                </td>
              </tr>
            </tfoot>
          </table>

          <duplicate-shareholders-warning :shareholders="newShareholders" />
        </div>

        <div class="d-flex justify-content-end mt-2">
          <be-button
            type="submit"
            variant="primary"
            :disabled="!validShareholders"
          >
            {{ $t("buttons.titles.save") }}
          </be-button>
        </div>
      </form>
    </template>

    <be-alert v-else variant="info" loading>
      {{
        $t(
          "components.shareholder_registers.shareholder_form.processing_shareholders"
        )
      }}
    </be-alert>
  </div>
</template>

<script>
import ShareholderRow from "./ShareholderRow.vue";
import DuplicateShareholdersWarning from "../DuplicateShareholdersWarning.vue";

export default {
  components: { DuplicateShareholdersWarning, ShareholderRow },

  props: {
    shareholderRegister: {
      type: Object,
      required: true,
    },

    shareholders: {
      type: Array,
      required: false,
      default: () => [],
    },

    processingStarted: {
      type: Boolean,
      required: false,
      default: false,
    },

    initiallyHideForm: {
      type: Boolean,
      required: false,
      default: false,
    },

    firstAction: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      capital: this.shareholderRegister
        ? this.shareholderRegister.capital
        : null,

      newShareholders: [],
      hideForm: this.initiallyHideForm,
      timer: null,
    };
  },

  computed: {
    getUrl() {
      return this.url("/shareholder_register");
    },

    showCapitalFields() {
      return (
        !this.firstAction ||
        !this.firstAction.added_capital ||
        this.firstAction.added_capital <= 0
      );
    },

    validShareholders() {
      return this.newShareholders
        .filter((shareholder) => !shareholder.destroyed)
        .every((element) => {
          return Object.keys(element.errors).length == 0;
        });
    },
  },

  mounted() {
    if (this.processingStarted) {
      this.startPolling();
    }

    this.shareholders.forEach((shareholder) => {
      this.addShareholder(shareholder);
    });
  },

  methods: {
    addShareholder(shareholder) {
      this.newShareholders.push({
        ...shareholder,
        id: this.generateUuid(),
        destroyed: false,
      });
    },

    addNewShareholder() {
      let shareholder = {
        id: this.generateUuid(),
        destroyed: false,
        shareholder_type: "physical_person",
        errors: {},
      };

      this.newShareholders.push(shareholder);
    },

    removeShareholder(shareholder) {
      let idx = this.newShareholders.findIndex(
        (newShareholder) => newShareholder.id == shareholder.id
      );

      shareholder.destroyed = true;

      if (idx != -1) {
        this.newShareholders[idx] = shareholder;
      }
    },

    changeShareholder(shareholder) {
      let idx = this.newShareholders.findIndex(
        (newShareholder) => newShareholder.id == shareholder.id
      );

      if (idx != -1) {
        this.newShareholders[idx] = shareholder;
      }
    },

    startPolling() {
      this.pollForExport();
      this.timer = setInterval(this.pollForExport, 1000);
    },

    pollForExport() {
      axios
        .get(this.url("/shareholder_register/validator/statuses"))
        .then((response) => {
          if (response.data.download_path) {
            // Start download automatically
            window.location.href = response.data.download_path;

            // Clear polling timer
            clearInterval(self.timer);

            // Hide the spinner
            window.message.complete();
          }
          if (response.data.status == "failed") {
            window.location.reload();

            // Clear polling timer
            clearInterval(self.timer);

            // Hide the spinner
            window.message.complete();
          }
        })
        .catch(() => {});
    },

    showForm() {
      this.hideForm = false;
    },
  },
};
</script>
