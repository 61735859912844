<template>
  <div class="row">
    <div class="col-12">
      <label class="mr-2">
        {{ $t("models.evaluation_question.possible_answer") }}
      </label>
    </div>

    <div class="col-12 col-lg-6 mb-2 mb-lg-0">
      <ranged-row
        :position="1"
        :possible-answer="firstPossibleAnswer"
        :question="question"
        :section="section"
        :component-indexes="componentIndexes.concat([0])"
      />
    </div>

    <div class="col-12 col-lg-6 mb-5 mb-lg-0">
      <ranged-row
        :position="2"
        :possible-answer="lastPossibleAnswer"
        :question="question"
        :section="section"
        :component-indexes="componentIndexes.concat([1])"
      />

      <ranged-row
        :hidden="true"
        :position="3"
        :title-type="'hidden'"
        :possible-answer="unknownPossibleAnswer"
        :question="question"
        :section="section"
        :component-indexes="componentIndexes.concat([2])"
      />
    </div>
  </div>
</template>

<script>
import RangedRow from "./RangedRow.vue";

export default {
  components: {
    RangedRow,
  },

  props: {
    question: {
      type: Object,
      required: true,
    },

    section: {
      type: Object,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    firstPossibleAnswer() {
      return this.question.possible_answers.find(
        (possibleAnswer) => possibleAnswer.position == 1
      );
    },

    lastPossibleAnswer() {
      return this.question.possible_answers.find(
        (possibleAnswer) => possibleAnswer.position == 2
      );
    },

    unknownPossibleAnswer() {
      return this.question.possible_answers.find(
        (possibleAnswer) => possibleAnswer.position == 3
      );
    },
  },
};
</script>
