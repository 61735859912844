<template>
  <be-dropdown variant="light" class="mr-md-5" size="sm">
    <template #button-content>
      <span class="text-primary"> {{ dropdownLabel }} </span>
    </template>

    <be-dropdown-item @click="setRapporteurId(null)">
      {{ $t("components.meetings.material.item.no_rapporteur") }}
    </be-dropdown-item>

    <be-dropdown-item
      v-for="user in usersAscByName"
      :key="`rapporteur-${user.id}`"
      :active="user.id === rapporteurId"
      @click="setRapporteurId(user.id)"
    >
      {{ user.name }}
    </be-dropdown-item>
  </be-dropdown>
</template>
<script>
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },

    rapporteurId: {
      type: Number,
      required: false,
      default: null,
    },
  },

  emits: ["update:rapporteur-id"],

  computed: {
    usersAscByName() {
      return [...this.users].sort((a, b) => a.name.localeCompare(b.name));
    },

    selectedRapporteur() {
      return this.users.find((user) => user.id === this.rapporteurId);
    },

    dropdownLabel() {
      return (
        this.selectedRapporteur?.name ||
        this.$t("components.meetings.material.item.assign_rapporteur")
      );
    },
  },

  methods: {
    setRapporteurId(id) {
      this.$emit("update:rapporteur-id", id);
    },
  },
};
</script>
