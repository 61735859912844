<template>
  <div
    :class="{
      'border-md-right': sideBorders,
      'border-md-left': sideBorders,
      'px-md-3': discount,
      'border-bottom border-md-bottom-0 border-light': !lastPackage,
    }"
    class="pb-3 mb-3 mb-md-0"
  >
    <div
      class="mb-3 d-md-block"
      :class="{
        'd-none': lastPackage,
      }"
    >
      <be-badge
        v-if="showPopularBadge"
        variant="success"
        pill
        class="py-1 px-2"
      >
        {{ upperCasePopularText }}
      </be-badge>

      <be-badge v-else pill class="mr-1 py-1 px-2 invisible">
        <span> &nbsp; </span>
      </be-badge>
    </div>

    <h2>
      {{ $t(`components.onboarding.package_titles.${featurePackage.title}`) }}
    </h2>

    <h3 class="text-primary mb-3">
      {{ $t("components.onboarding.free_30_days") }}
    </h3>

    <slot name="package-description" />

    <be-button
      v-if="packageSelected"
      variant="primary"
      size="lg"
      block
      class="mb-5"
      @click="deselectPackage"
    >
      {{ $t("buttons.titles.chosen") }}
    </be-button>

    <be-button
      v-else
      variant="outline-primary"
      size="lg"
      block
      class="mb-5"
      @click="selectPackage"
    >
      {{ $t("buttons.titles.choose") }}
    </be-button>

    <h4 class="mb-3">
      {{ $t("components.onboarding.included_products_title") }}
    </h4>

    <ul class="list-unstyled mb-0">
      <li
        v-for="feature in featurePackage.features"
        :key="`package-feature-${feature.id}`"
        class="mb-2 d-flex align-items-center"
      >
        <span class="h3 mb-0">
          <i class="fas fa-check-circle text-success mr-2" />
        </span>

        {{ $t(`models.feature.features.${feature.name}.title`) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const NONPROFIT = "nonprofit";
const HOUSING_ASSOCIATION = "housing_association";

export default {
  props: {
    discountedPrice: {
      type: Number,
      required: true,
    },

    featurePackage: {
      type: Object,
      required: true,
    },

    regularPrice: {
      type: Number,
      required: true,
    },

    packageSelected: {
      type: Boolean,
      required: false,
      default: false,
    },

    sideBorders: {
      type: Boolean,
      required: false,
    },

    showPopularBadge: {
      type: Boolean,
      required: false,
      default: false,
    },

    lastPackage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["package-selected", "package-deselected"],

  computed: {
    ...mapGetters({
      organizationType: "company/getOrganizationType",
    }),

    alwaysDiscounted() {
      return [HOUSING_ASSOCIATION, NONPROFIT].includes(this.organizationType);
    },

    discount() {
      if (this.featurePackage && this.alwaysDiscounted) {
        return "50%";
      }

      if (this.featurePackage && this.featurePackage.discount) {
        return this.featurePackage.discount + "%";
      }

      return false;
    },

    upperCaseDiscountText() {
      return this.$i18n
        .t("components.onboarding.feature_packages.discount")
        .toUpperCase();
    },

    upperCasePopularText() {
      return this.$i18n
        .t("components.onboarding.feature_packages.popular")
        .toUpperCase();
    },
  },

  mounted() {
    if (this.packageSelected) {
      this.selectPackage();
    }
  },

  methods: {
    selectPackage() {
      this.$emit("package-selected", this.featurePackage);
    },

    deselectPackage() {
      this.$emit("package-deselected");
    },
  },
};
</script>
