<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <div>
          <be-form-group
            label-for="company-title"
            :label="$t('activerecord.attributes.company.title')"
          >
            <be-form-input
              id="company-title"
              v-model="newCompany.title"
              required
            />
          </be-form-group>

          <be-form-group
            label-for="company-organization-number"
            :label="$t('activerecord.attributes.company.organization_number')"
          >
            <be-form-input
              id="company-organization-number"
              v-model="newCompany.organization_number"
            />
          </be-form-group>

          <div class="mb-3">
            <be-link
              :href="`/admin_panel/${adminPanel.id}/admin/advanced_settings/edit`"
            >
              {{
                $t(
                  "components.admin_panel.setup_companies.check_default_features_w_count",
                  { count: adminPanel.manage_features.length }
                )
              }}
            </be-link>
          </div>
        </div>
      </div>

      <div class="card-footer d-md-flex justify-content-end">
        <be-button variant="primary" @click="createCompany">
          {{ $t("models.company.create") }}
        </be-button>
      </div>
    </div>

    <be-tabs v-model="activeTab" :options="tabsData" />

    <div v-if="activeTab === 'new-companies'" class="card">
      <div class="card-body">
        <light-table
          v-if="localCompanies.length > 0"
          :companies="localCompanies"
          :admin-panel="adminPanel"
        />

        <be-alert v-else variant="info" class="mb-0">
          {{
            $t("components.admin_panel.setup_companies.no_new_organizations")
          }}
        </be-alert>
      </div>
    </div>

    <div v-if="activeTab === 'existing-companies'" class="card">
      <div class="card-body">
        <be-skeleton-table
          v-if="loading"
          :rows="3"
          :columns="4"
          :table-props="{ striped: true }"
        />

        <light-table
          v-else-if="loadedCompanies"
          :companies="companies"
          :admin-panel="adminPanel"
        />

        <be-alert v-else variant="info" class="mb-0">
          {{ $t("components.admin_panel.setup_companies.no_organizations") }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import LightTable from "./LightTable.vue";

export default {
  components: {
    LightTable,
  },

  props: {
    adminPanel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "new-companies",
      newCompany: this.addCompany(),
      localCompanies: [],
      loadedCompanies: false,
      loading: false,
      companies: [],

      tabsData: {
        newCompanies: {
          value: "new-companies",

          label: this.$t(
            "components.admin_panel.setup_companies.new_companies"
          ),
        },

        existingCompanies: {
          value: "existing-companies",

          label: this.$t(
            "components.admin_panel.setup_companies.existing_companies"
          ),
        },
      },
    };
  },

  watch: {
    async activeTab(value) {
      if (value === "existing-companies") {
        await this.loadCompanies();
      }
    },
  },

  methods: {
    addCompany() {
      return {
        title: "",
        organization_number: "",
      };
    },

    async createCompany() {
      try {
        const response = await axios.post(
          `/admin_panel/${this.adminPanel.id}/companies.json`,
          {
            company: this.newCompany,
          }
        );

        this.localCompanies.push(response.data);
        this.newCompany = this.addCompany();
      } catch (error) {
        if (error?.response?.status === 422) {
          this.newCompany = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    async loadCompanies() {
      this.loading = true;

      if (this.loadedCompanies) {
        this.loading = false;
        return;
      }

      try {
        const response = await axios.get(
          `/admin_panel/${this.adminPanel.id}/companies.json`
        );

        this.companies = response.data;
        this.loadedCompanies = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
