<template>
  <div>
    <input
      v-if="localShareholder"
      type="hidden"
      :name="`${namePrefix}[shareholder_buyer][id]`"
    />

    <input
      type="hidden"
      :name="`${namePrefix}[shareholder_buyer][buyer]`"
      value="1"
    />

    <div class="row">
      <div class="col-12 col-lg-6">
        <be-form-group
          :label="$t('activerecord.attributes.shareholder.name')"
          :label-for="`name_${index}`"
          :error="getErrors(localShareholder, ['name'])"
        >
          <be-form-input
            :id="`name_${index}`"
            v-model="localShareholder.name"
            :name="`${namePrefix}[shareholder_buyer][name]`"
            required
            @change="clearErrors(localShareholder, ['name'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.email')"
          :label-for="`email_${index}`"
          :error="getErrors(localShareholder, ['email'])"
        >
          <be-form-input
            :id="`email_${index}`"
            v-model="localShareholder.email"
            :name="`${namePrefix}[shareholder_buyer][email]`"
            @change="clearErrors(localShareholder, ['email'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('models.shareholder.identification_number_user')"
          :label-for="`identification_number_${index}`"
          :error="getErrors(localShareholder, ['identification_number'])"
        >
          <be-form-input
            :id="`identification_number_${index}`"
            v-model="localShareholder.identification_number"
            :name="`${namePrefix}[shareholder_buyer][identification_number]`"
            required
            @change="clearErrors(localShareholder, ['identification_number'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.phone')"
          :label-for="`phone_${index}`"
        >
          <be-tel-input
            :id="`phone_${index}`"
            v-model="localShareholder.phone"
            :name="`${namePrefix}[shareholder_buyer][phone]`"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <be-form-group
          :label="$t('activerecord.attributes.shareholder.address1')"
          :label-for="`address1_${index}`"
        >
          <be-form-input
            :id="`address1_${index}`"
            v-model="localShareholder.address1"
            :name="`${namePrefix}[shareholder_buyer][address1]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.address2')"
          :label-for="`address2_${index}`"
        >
          <be-form-input
            :id="`address2_${index}`"
            v-model="localShareholder.address2"
            :name="`${namePrefix}[shareholder_buyer][address2]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.zipcode')"
          :label-for="`zipcode_${index}`"
        >
          <be-form-input
            :id="`zipcode_${index}`"
            v-model="localShareholder.zipcode"
            :name="`${namePrefix}[shareholder_buyer][zipcode]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.city')"
          :label-for="`city_${index}`"
        >
          <be-form-input
            :id="`city_${index}`"
            v-model="localShareholder.city"
            :name="`${namePrefix}[shareholder_buyer][city]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.country')"
          :label-for="`c_${index}`"
        >
          <be-form-select
            :id="`c_${index}`"
            v-model="localShareholder.country"
            :name="`${namePrefix}[shareholder_buyer][country]`"
            preset-options="countries"
            include-blank-option
          />
        </be-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: false,
      default: 0,
    },

    namePrefix: {
      type: String,
      required: true,
    },

    shareholder: {
      type: Object,
      required: false,

      default: () => {
        return {
          name: null,
          email: null,
          identification_number: null,
          phone: null,
          address1: null,
          address2: null,
          zipcode: null,
          city: null,
          country: null,
        };
      },
    },
  },

  emits: ["shareholder-changed"],

  data() {
    return {
      localShareholder: this.shareholder,
    };
  },

  watch: {
    shareholder: {
      handler() {
        this.localShareholder = this.shareholder;
      },

      deep: true,
    },

    localShareholder: {
      handler() {
        this.$emit("shareholder-changed", this.localShareholder);
      },

      deep: true,
    },
  },
};
</script>
