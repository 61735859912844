<template>
  <div>
    <h2 v-if="loginType === 'email' && !returningFromBankId">
      {{ $t("login.welcome") }}
    </h2>

    <be-alert v-if="banner?.id" variant="info">
      <h4 class="alert-heading">
        {{ $i18n.locale === "sv" ? banner.title_sv : banner.title_en }}
      </h4>

      <p
        v-dompurify-html="
          $i18n.locale === 'sv' ? banner.description_sv : banner.description_en
        "
      />
    </be-alert>

    <!-- Email/Password-->
    <template v-if="returningFromBankId()">
      <bank-id-collect collect-url="/bank_id/collect/session" :modal="false" />
    </template>

    <template v-else-if="loginType === 'email'">
      <email-login-form :initial-email="initialEmail" />

      <div class="lined-heading">{{ $t("login.or") }}</div>

      <be-button variant="dark" size="lg" block @click="setLoginType('bankid')">
        <be-img
          :src="imageSrc('icons/bank_id/BankID_logo_white.svg')"
          class="my-n2 mr-1 align-self-start"
          width="48"
          height="48"
        />

        {{ $t("login.login_via_bankid") }}
      </be-button>
    </template>

    <!-- BankId -->
    <template v-else>
      <bank-id-authentication
        :returning-to-oauth="returningToOauth"
        begin-authentication-on-mount
        collect-url="/bank_id/collect/session"
      />

      <template v-if="!disableEmail">
        <div class="lined-heading">{{ $t("login.or") }}</div>

        <be-button
          variant="outline-primary"
          size="lg"
          block
          @click="setLoginType('email')"
        >
          {{ $t("login.login_via_credentials") }}
        </be-button>
      </template>
    </template>
  </div>
</template>

<script>
import EmailLoginForm from "./EmailLoginForm.vue";
import BankIdAuthentication from "./BankIdAuthentication.vue";

export default {
  components: {
    EmailLoginForm,
    BankIdAuthentication,
  },

  props: {
    locale: {
      type: String,
      default: "sv",
    },

    initialEmail: {
      type: String,
      required: false,
      default: "",
    },

    disableEmail: {
      type: Boolean,
      required: false,
      default: false,
    },

    manage: {
      type: Boolean,
      required: false,
      default: false,
    },

    returningToOauth: {
      type: Boolean,
      required: false,
      default: false,
    },

    banner: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      loginType: this.disableEmail ? "bankid" : "email",
    };
  },

  methods: {
    setLoginType(type) {
      this.loginType = type;
    },

    returningFromBankId() {
      return /#bank-id-return/.test(window.location.hash);
    },
  },
};
</script>
