<template>
  <signature-form
    :doc="doc"
    :available-users="availableUsers"
    :preselected-user-ids="selectedUserIds"
    disable-change-of-signature-solutions
    use-custom-titles
    :default-titles="defaultTitles"
    :submit-url="annualReport.paths.signature_requests"
    @document-updated="documentUpdated"
    @reset="$emit('closed')"
  >
    <be-alert v-if="doc.key === 'annual_report'" variant="info">
      {{ signatureNotice }}
    </be-alert>
  </signature-form>
</template>

<script>
export default {
  props: {
    availableUsers: {
      required: true,
      type: Array,
    },

    annualReport: {
      required: true,
      type: Object,
    },

    doc: {
      required: true,
      type: Object,
    },

    selectedUserIds: {
      required: false,
      type: Array,
      default: () => [],
    },

    defaultTitles: {
      required: false,
      type: Object,
      default: () => {},
    },
  },

  emits: ["closed"],

  computed: {
    isFoundation() {
      return this.$currentCompany.type_of_organization == "foundation";
    },

    signatureNotice() {
      if (this.isFoundation) {
        return this.$t(
          "components.annual_reports.upload_tab.foundation.signatures_notice"
        );
      } else {
        return this.$t(
          "components.annual_reports.upload_tab.signatures_notice"
        );
      }
    },
  },

  methods: {
    // Will return the response from the call, which is infact the AnnualReport.
    documentUpdated(data) {
      this.$store.commit("annual_reports/setAnnualReport", data);
    },
  },
};
</script>
