<template>
  <be-table :items="items" :fields="fields" @row-clicked="toggleItem">
    <template #checkbox="{ item }">
      <be-form-checkbox
        :id="`library-${itemType}-${item.id}`"
        :checked="checked.includes(item.id)"
        @change="toggleItem(item)"
      />
    </template>

    <template v-if="itemType === 'section'" #title="{ item }">
      {{ item.title }}
    </template>

    <template v-if="itemType === 'question'" #question_title="{ item }">
      {{ item.question_title }}
    </template>

    <template v-if="itemType === 'question'" #question_type="{ item }">
      {{
        $t(`models.evaluation_question.question_types.${item.question_type}`)
      }}
    </template>
  </be-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },

    itemType: {
      type: String,
      required: true,
    },
  },

  emits: ["library-item-chosen"],

  data() {
    return {
      checked: [],
    };
  },

  computed: {
    fields() {
      let fields = [
        {
          key: "checkbox",
          label: "",
          class: "col-shrink pr-0",
        },
      ];

      if (this.itemType === "section") {
        fields.push({
          key: "title",
          label: this.$t("activerecord.models.evaluation_section.one"),
          sortable: true,
        });
      } else if (this.itemType === "question") {
        fields.push({
          key: "question_title",
          label: this.$t("activerecord.models.evaluation_question.one"),
          sortable: true,
        });

        fields.push({
          key: "question_type",

          label: this.$t(
            "activerecord.attributes.evaluation_question.question_type"
          ),

          sortable: true,
          class: "col-shrink",
        });
      }

      return fields;
    },
  },

  methods: {
    toggleItem(item) {
      const idx = this.checked.findIndex((checkedId) => {
        return checkedId == item.id;
      });

      if (idx != -1) {
        this.checked.splice(idx, 1);
      } else {
        this.checked.push(item.id);
      }

      this.$emit("library-item-chosen", item);
    },
  },
};
</script>
