<template>
  <div class="mt-4">
    <template v-for="(item, index) in form.payment_plan" :key="index">
      <div class="card bg-gray mb-3">
        <div
          class="card-header bg-gray d-flex align-items-center justify-content-between"
        >
          <h5 class="card-title">
            <template v-if="singlePayment">
              {{ $t("views.companies.inquiry_payments.index.payout") }}
            </template>

            <template v-else>
              {{ $t("views.companies.inquiries.create.partial_payment") }}
              #{{ index + 1 }}
            </template>
          </h5>

          <be-button
            v-if="!singlePayment"
            v-be-tooltip="$t('buttons.titles.remove')"
            variant="danger"
            icon="fa-times"
            inline
            @click="form.payment_plan.splice(index, 1)"
          />
        </div>

        <div class="card-body">
          <div class="row mb-xl-3">
            <div class="col-12 col-xl">
              <be-form-group
                :label="
                  $t(
                    'views.companies.inquiries.create.date_for_partial_payment'
                  )
                "
                class="mb-xl-0"
                required
                :error="getErrors(item, 'pay_at')"
              >
                <be-form-datepicker
                  v-model="item.pay_at"
                  required
                  :min-date="new Date()"
                  @input="debouncedValidation"
                />
              </be-form-group>
            </div>

            <div class="col-12 col-xl">
              <be-form-group
                :label="
                  $t(
                    'views.companies.inquiries.create.amount_for_partial_payment'
                  )
                "
                class="mb-xl-0"
                required
                :error="getErrors(item, 'amount')"
              >
                <be-input-group :append="inquiry?.currency">
                  <be-form-input
                    id="payout-amount"
                    v-model="item.amount"
                    @input="debouncedValidation"
                  />
                </be-input-group>
              </be-form-group>
            </div>
          </div>

          <div class="row no-gutters">
            <div class="col-12 col-xl-auto mr-0 mb-2 mb-xl-0 mr-xl-3">
              <be-form-radio-group
                v-model="item.reference_type"
                name="button-styled-radios"
                :options="referenceTypes"
                button-variant="outline-secondary"
                buttons
                class="w-100"
                @input="debouncedValidation"
              />
            </div>

            <div class="col-12 col-xl">
              <be-form-group
                class="mb-0"
                :description="
                  $t(
                    `views.companies.inquiries.create.fill_in_payment_reference`,
                    {
                      payment_reference: $t(
                        `views.companies.inquiries.create.reference_type_${item.reference_type}`
                      ),
                    }
                  )
                "
                :error="getErrors(item, 'reference')"
              >
                <be-form-input
                  id="payout-reference"
                  v-model="item.reference"
                  @input="debouncedValidation"
                />
              </be-form-group>
            </div>
          </div>
        </div>
      </div>
    </template>

    <be-button
      v-if="!singlePayment"
      variant="outline-primary"
      @click="addPartialPayment"
    >
      {{
        $t("views.companies.inquiries.create.create_another_partial_payment")
      }}
    </be-button>

    <template v-if="inquiry && form.payment_plan[0]?.amount > 0">
      <h6>
        <money-format
          :currency="inquiry.currency"
          :value="Number(partialPaymentsPlanned)"
        />
        /
        <money-format
          :currency="inquiry.currency"
          :value="inquiry.granted_value"
        />
      </h6>

      <be-progress height="2rem" class="mb-3">
        <be-progress-bar
          :value="Number(partialPaymentsPlanned)"
          :max="inquiry.granted_value"
          :variant="progressVariant"
        >
        </be-progress-bar>
      </be-progress>
    </template>
  </div>
</template>

<script>
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  mixins: [CreateInquiryMixin],

  props: {
    singlePayment: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      getInquiry: "inquiries/getInquiry",
    }),

    inquiry() {
      return this.getInquiry;
    },

    partialPaymentsPlanned() {
      const initial = this.inquiry.total_payments_planned || 0;
      let total = 0 + initial;
      if (!this.form.payment_plan[0]) return total;
      this.form.payment_plan.forEach((payment) => {
        total += parseInt(payment.amount) || 0;
      });

      // When updating payment, subtract the previous saved amount from the total
      if (this.form.id) {
        const paymentPlan = this.inquiry.payment_plan.find(
          (plan) => plan.id === this.form.id
        );
        total -= paymentPlan.amount;
      }

      return total;
    },

    progressVariant() {
      if (this.partialPaymentsPlanned > this.inquiry.granted_value) {
        return "danger";
      }

      return "success";
    },

    referenceTypes() {
      return [
        {
          text: this.$t("views.companies.inquiries.create.reference_type_ocr"),
          value: "ocr",
        },
        {
          text: this.$t(
            "views.companies.inquiries.create.reference_type_message"
          ),

          value: "message",
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      validateInquiryPayment: "inquiries/validateInquiryPayment",
    }),

    addPartialPayment() {
      this.form.payment_plan.push({
        pay_at: "",
        amount: 0,
        reference: "",
        reference_type: "ocr",
      });
    },

    async handleValidateInquiryPayment() {
      if (!this.form.inquiry.inquiry_id) {
        return;
      }

      const inquiryPayment = {
        inquiry_id: this.form.inquiry.inquiry_id,
        ...this.form.payment_plan[0],
        id: this.form.id,
      };

      this.clearStates();
      await this.validateInquiryPayment(inquiryPayment);
    },

    clearStates() {
      const fields = ["reference", "reference_type", "pay_at", "amount"];
      this.clearErrors(this.form.payment_plan[0], fields);
    },

    debouncedValidation: debounce(function () {
      this.handleValidateInquiryPayment();
    }, 1000),
  },
};
</script>
