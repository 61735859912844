<template>
  <be-modal
    id="new-token"
    :title="$t('views.companies.tokens.new_token.new_token')"
    :ok-title="$t('buttons.titles.create')"
    @ok="save"
  >
    <be-form-group
      label-for="token-type"
      :label="$t('views.companies.tokens.new_token.upload_type')"
      class="mb-0"
    >
      <be-form-select
        id="token-type"
        v-model="tokenType"
        :options="tokenTypeOptions"
      />
    </be-form-group>
  </be-modal>
</template>

<script>
export default {
  props: {
    tokenTypes: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  emits: ["token-create"],

  data() {
    return {
      tokenType: "Contract",
    };
  },

  computed: {
    tokenTypeOptions() {
      const options = [];
      Object.entries(this.tokenTypes).forEach((tokenType) => {
        options.push({
          value: tokenType[1],
          text: tokenType[0],
        });
      });

      return options;
    },
  },

  methods: {
    save() {
      this.$emit("token-create", this.tokenType);
    },
  },
};
</script>
