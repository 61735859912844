<template>
  <div
    v-show="!question._destroy"
    class="card"
    :class="{
      show: expanded,
    }"
  >
    <div class="card-header py-2">
      <div class="row align-items-center">
        <div class="col-12 col-md">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <be-link
                class="text-decoration-none mr-3"
                @click="toggleExpanded"
              >
                <span class="text-primary">
                  <i
                    class="fal fa-grip-vertical fa-lg mr-2 handle d-none d-md-inline question-draggable-handle"
                    style="cursor: move"
                  />

                  <i
                    class="fal"
                    :class="{
                      'fa-chevron-down': expanded,
                      'fa-chevron-right': !expanded,
                    }"
                  />
                </span>
              </be-link>
            </div>

            <div class="col">
              <div class="row align-items-center no-gutters">
                <h5 class="my-auto">
                  {{ section.position }}.{{ question.section_position }}
                </h5>

                <span class="my-auto mx-3">-</span>

                <be-form-group
                  :error="getErrors(question, ['question_title'])"
                  class="mb-0 col"
                >
                  <be-form-input
                    v-model="question.question_title"
                    :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][question_title]`"
                    required
                    @change="clearErrors(question, ['question_title'])"
                    @input="update('title', $event)"
                  />
                </be-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="d-none d-lg-flex col-auto align-items-center">
          <be-button
            v-be-tooltip="$t('buttons.titles.remove')"
            size="sm"
            variant="danger"
            icon="fa-times"
            inline
            @click="onRemoveQuestion"
          />
        </div>
      </div>
    </div>

    <div v-show="expanded && !dragging" class="card-body border-bottom-0">
      <div class="row justify-content-between">
        <div class="col-12 col-lg-6 order-2 order-lg-1">
          <!-- Render question component -->
          <component
            :is="componentType"
            v-if="question.question_type != 'open'"
            :component-indexes="componentIndexes"
            :question="question"
            :section="section"
          />
        </div>

        <!-- Question type selection dropdown -->
        <div class="col-12 col-lg-4 order-1 order-lg-2">
          <be-form-group
            class="mb-0"
            :description="
              $t(
                `views.companies.evaluation_forms.question_modules.${question.question_type}.description`
              )
            "
          >
            <be-form-select
              v-model="question.question_type"
              :options="translatedQuestionTypes"
            />
          </be-form-group>
        </div>
      </div>
    </div>

    <div v-show="expanded && !dragging" class="card-footer">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="custom-control custom-checkbox">
            <input
              :id="`question-save-to-library-${question.id || question.uuid}`"
              :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][added_to_library]`"
              :value="question.added_to_library"
              :checked="question.added_to_library ? true : false"
              type="checkbox"
              class="custom-control-input"
              @input="toggleSaveToLibrary"
            />

            <label
              :for="`question-save-to-library-${question.id || question.uuid}`"
              class="custom-control-label"
            >
              {{
                $t(
                  "components.evaluation_forms.editor.library.questions.save_description"
                )
              }}
            </label>
          </div>

          <small class="form-text text-muted">
            {{
              $t(
                "views.companies.evaluation_forms.template_question_fields.save_question_description"
              )
            }}
          </small>
        </div>

        <div class="col-12 col-md-4 mb-3 mb-md-0">
          <input
            type="hidden"
            :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][required]`"
            :value="[true, '1'].includes(question.required) ? '1' : '0'"
          />

          <div class="custom-control custom-checkbox">
            <input
              :id="`question-${
                question.id ? question.id : question.uuid
              }-required`"
              :checked="[true, '1'].includes(question.required)"
              type="checkbox"
              class="custom-control-input"
              @input="
                update('required', $event.target.checked == true ? '1' : '0')
              "
            />

            <label
              :for="`question-${
                question.id ? question.id : question.uuid
              }-required`"
              class="custom-control-label"
            >
              {{
                $t(
                  "views.companies.evaluation_forms.form_section_fields.required_question"
                )
              }}
            </label>

            <small class="form-text text-muted">
              {{
                $t(
                  "views.companies.evaluation_forms.template_question_fields.required_question_description"
                )
              }}
            </small>
          </div>
        </div>

        <div class="col-12 col-md-4">
          <input
            type="hidden"
            :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][confidential_answers]`"
            :value="
              [true, '1'].includes(question.confidential_answers) ? '1' : '0'
            "
          />

          <div class="custom-control custom-checkbox">
            <input
              :id="`question-${
                question.id ? question.id : question.uuid
              }-confidential`"
              :checked="[true, '1'].includes(question.confidential_answers)"
              type="checkbox"
              class="custom-control-input"
              @input="
                update(
                  'confidential_answers',
                  $event.target.checked == true ? '1' : '0'
                )
              "
            />

            <label
              :for="`question-${
                question.id ? question.id : question.uuid
              }-confidential`"
              class="custom-control-label"
            >
              {{
                $t(
                  "views.companies.evaluation_forms.form_section_fields.confidential_question"
                )
              }}
            </label>

            <small class="form-text text-muted">
              {{
                $t(
                  "views.companies.evaluation_forms.template_question_fields.confidential_answers_description"
                )
              }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][question_type]`"
      :value="question.question_type"
    />

    <input
      v-if="question.id"
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][id]`"
      :value="question.id"
    />

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][section_position]`"
      :value="currentPosition"
    />

    <input
      type="hidden"
      :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][_destroy]`"
      :value="question._destroy"
    />
  </div>
</template>

<script>
import MultiplePossibleAnswers from "./questions/MultiplePossibleAnswers.vue";
import Ranged from "./questions/RangedQuestion.vue";

export default {
  components: {
    MultiplePossibleAnswers,
    Ranged,
  },

  props: {
    section: {
      type: Object,
      required: true,
    },

    loadedQuestion: {
      type: Object,
      required: true,
    },

    questionTypes: {
      type: Array,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },

    dragging: {
      type: Boolean,
      required: true,
    },

    expanded: {
      type: Boolean,
      required: true,
    },
  },

  emits: ["question-removed", "toggle-expanded"],

  computed: {
    titleInputName() {
      return `evaluation_form[evaluation_sections_attributes][${this.componentIndexes[0]}][evaluation_questions_attributes][${this.componentIndexes[1]}][question_title]`;
    },

    question() {
      return this.$store.getters["evaluation_forms/getQuestionById"]({
        sectionId: this.section.id || this.section.uuid,
        questionId: this.loadedQuestion.id || this.loadedQuestion.uuid,
      });
    },

    translatedQuestionTypes() {
      return this.questionTypes.map((questionType) => ({
        value: questionType,

        text: this.$i18n.t(
          `models.evaluation_question.question_types.${questionType}`
        ),
      }));
    },

    componentType() {
      if (
        ["multiple_choices", "one_choice"].includes(this.question.question_type)
      ) {
        return "multiple-possible-answers";
      } else if (this.question.question_type == "ranged") {
        return "ranged";
      }

      return null;
    },

    currentPosition() {
      if (this.question.section_position) {
        return this.question.section_position;
      }

      return this.componentIndexes[1] + 1;
    },
  },

  watch: {
    "question.question_type"(newValue) {
      // Do not remove possible answers if question_type is undefined
      if (newValue) {
        this.setPossibleAnswersDestroy(1);
      }

      // Add one empty possible answer
      if (newValue == "multiple_choices" || newValue == "one_choice") {
        this.addPossibleAnswer({
          uuid: this.generateUuid(),
          title: "",

          position:
            this.question.possible_answers.filter(
              (possibleAnswer) => possibleAnswer._destroy != 1
            ).length + 1,

          _destroy: 0,
        });

        // Add 3 possible answers, 1, 5, and Not sure
      } else if (newValue == "ranged") {
        const answerTitles = [
          "1",
          "5",
          this.$i18n.t("models.possible_answer.unknown_answer"),
        ];

        answerTitles.forEach((title, index) => {
          this.addPossibleAnswer({
            uuid: this.generateUuid(),
            title: title,
            position: index + 1,
            _destroy: 0,
          });
        });
      }
    },
  },

  methods: {
    addPossibleAnswer(possibleAnswer) {
      this.$store.dispatch("evaluation_forms/addPossibleAnswer", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,
        possibleAnswer: possibleAnswer,
      });
    },

    async onRemoveQuestion() {
      let title = "";

      if (this.question.question_title) {
        title = this.$t("nav.confirm_delete_w_title", {
          title: this.question.question_title,
        });
      } else {
        title = this.$t(
          "components.evaluation_forms.editor.question.confirm_delete"
        );
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        await this.removeQuestion();
      }
    },

    removeQuestion() {
      this.setPossibleAnswersDestroy(1);
      this.update("_destroy", 1);
      this.$emit("question-removed", this.question);
    },

    setPossibleAnswersDestroy(value) {
      this.question.possible_answers.forEach((answer) => {
        this.$store.dispatch("evaluation_forms/updatePossibleAnswer", {
          sectionId: this.section.id || this.section.uuid,
          questionId: this.question.id || this.question.uuid,
          possibleAnswerId: answer.id || answer.uuid,
          data: { _destroy: value },
        });
      });
    },

    toggleSaveToLibrary() {
      if (this.question.added_to_library) {
        this.update("added_to_library", null);
      } else {
        this.update("added_to_library", new Date().toISOString());
      }
    },

    toggleExpanded() {
      this.$emit("toggle-expanded");
    },

    update(key, value) {
      this.$store.dispatch("evaluation_forms/updateQuestion", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,
        data: { [key]: value },
      });
    },
  },
};
</script>
