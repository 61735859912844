<template>
  <div class="row">
    <div class="col-12 col-lg-auto col-xl-3">
      <be-form-group
        :label="
          $t(
            'models.shareholder_register_action.bonus_issue.added_value_to_capital'
          )
        "
        label-for="added_capital"
        :error="getErrors(localAction, 'added_capital')"
      >
        <be-form-input
          id="added_capital"
          v-model="addedCapital"
          type="number"
          name="shareholder_register_action[added_capital]"
          required
          @change="clearErrors(localAction, 'added_capital')"
        />
      </be-form-group>

      <be-form-group v-if="!inEdit">
        <!-- TODO: Convert this to BeFormRadio -->
        <div class="form-check">
          <input
            id="new_shares"
            v-model="shareSetting"
            class="form-check-input"
            type="radio"
            value="new_shares"
            name="shareholder_register_action[new_shares]"
          />

          <label class="form-check-label" for="new_shares">
            {{
              $t("models.shareholder_register_action.bonus_issue.new_shares")
            }}
          </label>
        </div>

        <!-- TODO: Convert this to BeFormRadio -->
        <div class="form-check">
          <input
            id="no_new_shares"
            v-model="shareSetting"
            class="form-check-input"
            type="radio"
            value="no_new_shares"
            name="shareholder_register_action[no_new_shares]"
          />

          <label class="form-check-label" for="no_new_shares">{{
            $t("models.shareholder_register_action.bonus_issue.no_new_shares")
          }}</label>
        </div>
      </be-form-group>

      <div v-if="shareSetting == 'new_shares' && !inEdit">
        <be-form-group
          :label="$t('models.shareholder_register_action.bonus_issue.ratio')"
          label-for="split-ratio"
          :error="
            getErrors(localAction, ['split_ratio_first', 'split_ratio_last'])
          "
          :description="
            $t(
              'models.shareholder_register_action.bonus_issue.bonus_issue_quota_information'
            )
          "
          required
        >
          <be-input-group>
            <be-form-input
              id="split-ratio"
              v-model="splitRatioFirst"
              type="number"
              min="0"
              name="shareholder_register_action[split_ratio_first]"
              :state="validationState(localAction, ['split_ratio_first'])"
              @change="
                updateAssignedShares();
                clearErrors(localAction, ['split_ratio_first']);
              "
            />

            <be-input-group-separator is-text>:</be-input-group-separator>

            <be-form-input
              v-model="splitRatioLast"
              type="number"
              min="0"
              name="shareholder_register_action[split_ratio_last]"
              :state="validationState(localAction, ['split_ratio_last'])"
              @change="
                updateAssignedShares();
                clearErrors(localAction, ['split_ratio_last']);
              "
            />
          </be-input-group>
        </be-form-group>
      </div>
    </div>

    <div v-if="shareSetting == 'new_shares' && !inEdit" class="col-12 mt-3">
      <h3>
        {{ $t("models.shareholder_register.share_distribution") }}
      </h3>

      <div class="table-responsive">
        <table v-if="localExistingShareholders" class="table table-hover">
          <thead>
            <tr>
              <th>
                {{ $t("models.shareholder_register_action.owner") }}
              </th>

              <th class="text-right">
                {{ $t("models.shareholder_register_action.shares_before") }}
              </th>

              <th>
                {{ $t("models.shareholder_register_action.distribute") }}
              </th>

              <th class="text-right">
                {{ $t("models.shareholder_register_action.shares_after") }}
              </th>

              <th>
                {{
                  $t(
                    "activerecord.attributes.shareholder_transaction.share_type"
                  )
                }}
              </th>

              <th class="hidden"></th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(shareholder, index) in localExistingShareholders">
              <owner-row
                v-for="(
                  distribution, inner_index
                ) in shareholder.share_distribution"
                :key="'owner-row-' + index + '-' + inner_index"
                :action="localAction"
                :shareholder="shareholder"
                :share-distribution="distribution"
                :new-transactions="newTransactions"
                @update-shareholder="updateShareholder"
              />
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import OwnerRow from "../types/OwnerRow.vue";

export default {
  components: {
    OwnerRow,
  },

  props: {
    action: {
      type: Object,
      required: true,
    },

    existingShareholders: {
      type: Array,
      required: true,
    },

    inEdit: {
      type: Boolean,
      required: true,
    },

    newTransactions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      addedCapital: this.action.added_capital
        ? this.action.added_capital
        : null,

      shareSetting: "new_shares",

      splitRatioFirst: this.action.split_ratio_first
        ? this.action.split_ratio_first
        : null,

      splitRatioLast: this.action.split_ratio_last
        ? this.action.split_ratio_last
        : null,

      localAction: this.cloneDeep(this.action),
      localExistingShareholders: this.cloneDeep(this.existingShareholders),
    };
  },

  watch: {
    addedCapital(newValue) {
      if (newValue < 1) {
        this.localAction.errors.added_capital = [
          this.$t("errors.messages.greater_than", {
            count: 0,
          }),
        ];
      } else {
        delete this.localAction.errors.added_capital;
      }
    },
  },

  created() {
    // Populates the number of added shares based on the new transactions created
    // after validation was invalid in the backend
    if (this.localExistingShareholders) {
      if (this.newTransactions && !!this.newTransactions.length) {
        this.localExistingShareholders.forEach((shareholder) => {
          let shareDistribution = {};
          let transaction = [];

          shareholder.share_distribution.forEach((shares) => {
            let shareType = Object.keys(shares)[0];

            transaction = this.newTransactions.find(
              (transaction) =>
                transaction.buyer_id == shareholder.id &&
                shareType == transaction.share_type
            );

            if (transaction) {
              shareDistribution[shareType] = transaction.number_of_shares;
            }
          });

          shareholder.addedSharesByType = shareDistribution;
        });

        // Populates added shares attribute on shareholder since no transactions
        // have been created the first time entering this view
      } else {
        this.localExistingShareholders.forEach((shareholder) => {
          let shareDistribution = {};
          shareholder.share_distribution.forEach((shares) => {
            let shareType = Object.keys(shares)[0];
            shareDistribution[shareType] = null;
          });

          shareholder.addedSharesByType = shareDistribution;
        });
      }
    }
  },

  methods: {
    updateAssignedShares() {
      let updatable =
        this.splitRatioFirst &&
        this.splitRatioLast &&
        this.valueNumeric(this.splitRatioFirst) &&
        this.valueNumeric(this.splitRatioLast) &&
        this.splitRatioFirst <= this.splitRatioLast;

      // Loops through the shareholders and their shares of all different share types since split
      // ratio should effect all existing shareholders and their current shares. (A-, B-shares etc.)
      this.localExistingShareholders.forEach((shareholder) => {
        Object.keys(shareholder.addedSharesByType).forEach((shareType) => {
          let shareDistribution = shareholder.share_distribution.find(
            (type) => Object.keys(type) == shareType
          );
          let oldShareCount = shareDistribution[shareType];

          if (updatable) {
            let newShares =
              (oldShareCount / this.splitRatioLast) * this.splitRatioFirst;

            shareholder.addedSharesByType[shareType] = newShares;
          }
        });
      });
    },

    updateShareholder(shareholder) {
      const index = this.localExistingShareholders.findIndex(
        (item) => item.id == shareholder.id
      );

      if (index > -1) {
        this.localExistingShareholders[index] = shareholder;
      }
    },

    valueNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
  },
};
</script>
