<template>
  <div>
    <div v-if="groups.length > 0" class="table-responsive">
      <table class="table table-hover vertical-align-middle">
        <thead>
          <tr>
            <th>{{ $t("views.educations.groups.groups_table.name") }}</th>

            <th class="d-none d-md-table-cell col-shrink">
              {{ $t("views.educations.groups.groups_table.created_by") }}
            </th>

            <th class="col-shrink d-none d-md-table-cell">
              {{ $t("views.educations.groups.groups_table.created") }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <group-row
            v-for="group in groups"
            :key="`group-${group.id}`"
            :group="group"
            :groups-to-join="groupsToJoin"
            @row-removed="removeRowFromTable"
          />
        </tbody>
      </table>
    </div>

    <be-alert v-else variant="info">
      {{
        $t(
          "components.educations.education_group.groups_table.no_groups_present"
        )
      }}
    </be-alert>
  </div>
</template>

<script>
import GroupRow from "./groups/GroupRow.vue";

export default {
  components: {
    GroupRow,
  },

  props: {
    initialGroups: {
      type: Array,
      required: false,
      default: () => [],
    },

    groupsToJoin: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      groups: this.cloneDeep(this.initialGroups),
    };
  },

  methods: {
    removeRowFromTable(row) {
      let idx = this.groups.findIndex((group) => group.id == row.id);
      this.groups.splice(idx, 1);
    },
  },
};
</script>
