<template>
  <signature-case>
    <template #title>
      <h5 class="card-title">
        {{ meeting.number }} {{ meeting.title }}
        <div class="small">
          {{ $t("activerecord.attributes.material.minutes") }}
        </div>
      </h5>
    </template>

    <template #header-buttons>
      <be-dropdown ellipsis>
        <be-dropdown-item :href="meeting.paths.base">
          {{ $t("models.meeting.show_meeting") }}
        </be-dropdown-item>
      </be-dropdown>
    </template>

    <template #metadata>
      <div class="table-responsive">
        <table class="table table-bordered">
          <tbody>
            <tr>
              <th>
                {{ $t("activerecord.attributes.meeting.start_at") }}
              </th>

              <td>
                {{ $d(new Date(meeting.start_at), "dateTime") }}
              </td>
            </tr>

            <tr>
              <th>
                {{ $t("activerecord.attributes.material.approved_at") }}
              </th>

              <td>
                {{ $d(new Date(meeting.approved_at), "dateTime") }}
              </td>
            </tr>

            <tr>
              <th>
                {{ $t("models.document.signature_solutions") }}
              </th>

              <td>
                <be-badge
                  v-be-tooltip="
                    $t(
                      'models.document.signature_solution_list.electronicid.description'
                    )
                  "
                  variant="primary"
                  class="mr-1"
                >
                  {{
                    $t(
                      "models.document.signature_solution_list.electronicid.title"
                    )
                  }}
                </be-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template #table>
      <meeting-signatures-table
        :meeting="meeting"
        :attendances="meeting.attendances"
        :allowed-to-remind="meeting.minutes.policy.update"
      />
    </template>
  </signature-case>
</template>

<script>
import MeetingSignaturesTable from "./MeetingSignaturesTable.vue";
import SignatureCase from "@/components/shared/SignatureCase.vue";

export default {
  components: {
    MeetingSignaturesTable,
    SignatureCase,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
};
</script>
