<template>
  <div>
    <h3 class="mb-3">{{ $t("components.meetings.invitations.title") }}</h3>

    <div class="row">
      <div class="col-12 col-md-6 mb-4">
        <meeting-status
          v-if="meeting.process.active_state === 'invitations'"
          :meeting="meeting"
        />

        <tab-activity
          :owner="meeting"
          :accepted-keys="['meeting.invitation.sent']"
        />

        <template
          v-if="
            minutesStatus === 'loading' ||
            agendaStatus === 'loading' ||
            invitationsStatus === 'loading'
          "
        >
          <be-skeleton-table :rows="3" :columns="2" />
        </template>

        <be-alert v-else-if="invitationsStatus === 'error'" variant="danger">
          {{ $t("components.meetings.tabs.failed_to_load") }}
        </be-alert>

        <template v-else-if="sentInvitations.length > 0">
          <table class="table table-comfortable table-hover">
            <thead>
              <tr>
                <th class="col-shrink"></th>

                <th>
                  {{ $t("activerecord.attributes.user.name") }}
                </th>

                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="invitation in sentInvitations" :key="invitation.id">
                <td>
                  <user-avatar :user="invitation.user_id" />
                </td>

                <td>
                  {{ userName(invitation.user_id) }}
                  <div v-if="invitation.id">
                    <em class="small">
                      {{ $d(new Date(invitation.updated_at), "dateTime") }}
                    </em>
                  </div>
                </td>

                <td>
                  <template v-if="canReportAbsence(invitation)">
                    <be-button
                      v-if="invitation.reported_absence"
                      variant="outline-secondary"
                      size="sm"
                      icon="fa-envelope"
                      inline
                      @click="revertAbsenceReport(invitation)"
                    >
                      {{ $t("models.meeting.revert_absence_report") }}
                    </be-button>

                    <be-button
                      v-else
                      variant="outline-secondary"
                      size="sm"
                      icon="fa-envelope"
                      inline
                      @click="reportAbsence(invitation)"
                    >
                      {{ $t("models.meeting.report_absence") }}
                    </be-button>
                  </template>

                  <template v-else-if="invitation.reported_absence">
                    {{
                      $t("activerecord.attributes.attendance.reported_absence")
                    }}
                  </template>
                </td>
              </tr>
            </tbody>
          </table>

          <be-button
            v-if="canInvite"
            :href="`${meeting.paths.base}/invitations`"
            variant="outline-primary"
          >
            {{ $t("components.meetings.invitations.invite_more") }}
          </be-button>
        </template>

        <template v-else>
          <be-button
            v-if="showInvitationButton"
            :href="`${meeting.paths.base}/invitations`"
            variant="outline-primary"
          >
            {{ $t("components.meetings.invitations.handle_invitations") }}
          </be-button>

          <be-alert v-else variant="info">
            {{ $t("components.meetings.invitations.no_invitations_sent") }}
          </be-alert>
        </template>
      </div>

      <div class="col-12 col-md-6">
        <meeting-documents
          v-if="folderStatus === 'loaded' && folder.id"
          :folder="folder"
          :meeting="meeting"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MeetingDocuments from "./components/MeetingDocuments.vue";
import TabActivity from "./TabActivity.vue";
import RequestHandler from "@/mixins/RequestHandler";
import MaterialMixin from "@/mixins/meetings/material";

export default {
  components: {
    MeetingDocuments,
    TabActivity,
  },

  mixins: [MaterialMixin, RequestHandler],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      invitesResent: [],

      // Readings
      loadingReadings: true,
      readings: [],
    };
  },

  computed: {
    agenda() {
      return this.getAgenda(this.meeting);
    },

    agendaStatus() {
      return this.getAgendaStatus(this.meeting);
    },

    attendances() {
      return this.getAttendances(this.meeting);
    },

    canInvite() {
      return this.meeting.policy.invite && !this.minutes.id;
    },

    invitationsStatus() {
      return this.getInvitationStatus(this.meeting);
    },

    meetingDocuments() {
      if (!this.meeting.folder) {
        return [];
      }

      const documents = this.meeting.folder.documents;
      const attachments = this.meeting.folder.subtree_documents.filter(
        (document) => document.owner_type === "Item"
      );

      return [...documents, ...attachments];
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    minutesStatus() {
      return this.getMinutesStatus(this.meeting);
    },

    showInvitationButton() {
      return this.agenda.id && !this.minutes.id;
    },

    sentInvitations() {
      return this.$store.getters["meetings/getInvitationsForMeeting"](
        this.meeting.id
      ).filter((invitation) => invitation.id != null);
    },

    folder() {
      return this.getFolder(this.meeting);
    },

    folderStatus() {
      return this.getFolderStatus(this.meeting);
    },
  },

  mounted() {
    this.fetchMinutesForMeeting(this.meeting);
    this.fetchAgendaForMeeting(this.meeting);
    this.fetchFolderForMeeting(this.meeting);
    this.fetchInvitationsForMeeting(this.meeting);
    this.fetchAttendancesForMeeting(this.meeting);
  },

  methods: {
    readByUser(documentId, userId) {
      return this.readings.some(
        (reading) =>
          reading.reference_id == documentId && reading.user_id == userId
      );
    },

    userName(user_id) {
      return this.getUser(user_id)?.name;
    },

    canReportAbsence(invitation) {
      // Only report/recall absence for existing invitations where there is no attendance object
      // for that user
      return (
        invitation.id &&
        invitation.policy.decline &&
        !this.attendances.some(
          (attendance) => attendance.user_id === invitation.user_id
        )
      );
    },

    async reportAbsence() {
      try {
        const response = await axios.post(
          `${this.meeting.paths.base}/invitations/decline`
        );
        this.$store.commit("meetings/updateInvitation", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async revertAbsenceReport() {
      try {
        const response = await axios.delete(
          `${this.meeting.paths.base}/invitations/decline`
        );
        this.$store.commit("meetings/updateInvitation", response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
