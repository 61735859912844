<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <be-form-group
        :error="getErrors(contract, 'invalid_start_or_end_date')"
        label-for="start-date"
        :label="$t('activerecord.attributes.contract.start_date')"
      >
        <be-form-datepicker
          id="start-date"
          v-model="startDate"
          name="contract[start_date]"
          :disabled="contract.editing_disabled"
          :state="validationState(contract, 'invalid_start_or_end_date')"
          @input="updateStartDate"
        />
      </be-form-group>
    </div>

    <div v-if="showEndDate" class="col-12 col-md-4">
      <be-form-group
        :error="getErrors(contract, 'invalid_start_or_end_date')"
        label-for="end-date"
        :label="$t('activerecord.attributes.contract.end_date')"
      >
        <be-form-datepicker
          id="end-date"
          v-model="endDate"
          name="contract[end_date]"
          :disabled="contract.editing_disabled"
          :state="validationState(contract, 'invalid_start_or_end_date')"
          @input="updateEndDate"
        />
      </be-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contract: {
      type: Object,
      required: true,
    },

    initialEndDate: {
      type: Date,
      required: false,
      default: null,
    },

    initialStartDate: {
      type: Date,
      required: false,
      default: null,
    },

    showEndDate: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["end-date", "start-date"],

  data() {
    return {
      endDate: this.initialEndDate,
      startDate: this.initialStartDate,
    };
  },

  methods: {
    updateEndDate() {
      this.clearErrors(this.contract, "invalid_start_or_end_date");
      this.$emit("end-date", this.endDate);
    },

    updateStartDate() {
      this.clearErrors(this.contract, "invalid_start_or_end_date");
      this.$emit("start-date", this.startDate);
    },
  },
};
</script>
