<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="row">
            <div class="col-12 col-md-10">
              <h2>
                {{ $t("components.onboarding.contact.we_are_here_for_you") }}
              </h2>

              <p class="mb-5 pb-2">
                {{ $t("components.onboarding.contact.contact_description") }}
              </p>
            </div>
          </div>

          <be-list-group-item class="p-4">
            <h4>{{ $t("components.onboarding.contact.book_demo") }}</h4>

            <p>
              {{ $t("components.onboarding.contact.book_demo_description") }}
            </p>

            <div class="custom-control custom-checkbox">
              <input
                id="demo-request-check-box"
                v-model="demoRequested"
                type="checkbox"
                class="custom-control-input"
              />

              <label class="custom-control-label" for="demo-request-check-box">
                {{
                  $t("components.onboarding.contact.book_demo_checkbox_text")
                }}
              </label>
            </div>
          </be-list-group-item>

          <be-list-group-item class="p-4">
            <h4>
              {{ $t("components.onboarding.contact.support_center") }}
            </h4>

            <p>
              {{ $t("components.onboarding.contact.support_description") }}
            </p>

            <be-button
              :href="supportUrl"
              target="_blank"
              variant="outline-primary"
              icon="fa-external-link"
              icon-custom-class="small"
            >
              {{ $t("components.onboarding.contact.to_support") }}
            </be-button>
          </be-list-group-item>
        </div>

        <div class="col-5 d-none d-lg-block">
          <div class="d-flex align-items-center h-100">
            <be-img
              :src="imageSrc('onboarding/laptop_mobile.png')"
              class="mr-n8"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer">
      <div class="d-flex justify-content-end">
        <be-button variant="success" size="lg" @click="finishOnboarding">
          {{ $t("components.onboarding.contact.complete") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      demoRequested: false,
    };
  },

  computed: {
    supportUrl() {
      if (this.$currentUser.locale === "sv") {
        return "https://support.boardeaser.com/hc/sv";
      }

      return "https://support.boardeaser.com/hc/en-gb";
    },
  },

  methods: {
    async finishOnboarding() {
      if (this.demoRequested) {
        axios
          .post("/demo_requests/send_request/", {
            email: this.$currentUser.email,
          })
          .then(() => {
            window.location = this.url("/");
          })
          .catch(() => {});
      }

      if (this.$currentCompany.onboarded_at) {
        window.location = this.url("/");
      } else {
        try {
          await axios.post(this.url("/onboarding"), {
            company: {
              features: [],
            },
          });
          window.location = this.url("/");
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
