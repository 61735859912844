<template>
  <be-modal
    id="add-sub-category"
    :title="$t('models.sub_category.new')"
    :ok-title="$t('models.sub_category.create')"
    @ok="saveSubCategory"
  >
    <be-form-group
      :label="$t('activerecord.models.category.one')"
      label-for="category-type"
    >
      <be-form-select
        id="category-type"
        v-model="subCategory.category_id"
        name="sub_category[category_id]"
        :options="formattedCategories"
      />
    </be-form-group>

    <be-form-group
      label-for="title"
      :label="$t('attributes.title')"
      :error="getErrors(subCategory, ['title'])"
    >
      <be-form-input
        id="sub-category-title"
        v-model="subCategory.title"
        name="sub_category[title]"
        required
        @change="clearErrors(subCategory, ['title'])"
      />
    </be-form-group>
  </be-modal>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      required: true,
    },
  },

  emits: ["sub-category-added"],

  data() {
    return {
      subCategory: {
        category_id: null,
        title: null,
        object_id: this.object ? this.object.id : null,
      },
    };
  },

  computed: {
    formattedCategories() {
      return this.object.available_categories.map((category) => ({
        text: category.title,
        value: category.id,
      }));
    },
  },

  mounted() {
    if (this.formattedCategories.length > 0) {
      this.subCategory.category_id = this.formattedCategories[0].value;
    }
  },

  methods: {
    async saveSubCategory(beModalEvt) {
      beModalEvt.preventDefault();

      try {
        const response = await axios.post(this.url("/sub_categories"), {
          sub_category: this.subCategory,
        });

        this.$emit("sub-category-added", response.data);
        this.subCategory.title = null;
        this.$beModal.hide("add-sub-category");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.subCategory = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
