<template>
  <form
    novalidate="novalidate"
    :action="formUrl"
    accept-charset="UTF-8"
    method="post"
  >
    <input name="utf8" type="hidden" value="✓" />

    <input type="hidden" name="authenticity_token" :value="$csrfToken" />

    <input id="_method" type="hidden" name="_method" :value="formMethod" />

    <div class="row">
      <div class="col-12 col-md-4">
        <div class="card">
          <div class="card-body">
            <be-form-group
              :label="$t('views.educations.groups.new.title')"
              label-for="title"
              :error="getErrors(educationGroup, ['title'])"
            >
              <be-form-input
                id="title"
                v-model="title"
                name="education_group[title]"
                required
                @change="clearErrors(educationGroup, ['title'])"
              />
            </be-form-group>

            <be-form-group
              v-if="companies"
              :label="$t('views.educations.groups.new.invite_board_now')"
              label-for="action_type"
            >
              <be-form-select
                v-model="companyNanoId"
                name="company_nanoid"
                :options="allFormattedCompanies"
                :include-blank-option="
                  $t('views.educations.groups.new.no_board')
                "
              />
            </be-form-group>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 mb-3">
        <invitation-form
          :education-group="educationGroup"
          :selected-company-users="selectedCompanyUsers"
          :show-save-button="true"
        />
      </div>
    </div>
  </form>
</template>

<script>
import InvitationForm from "./InvitationForm.vue";

export default {
  components: {
    InvitationForm,
  },

  props: {
    companies: {
      type: Array,
      required: false,
      default: null,
    },

    educationGroup: {
      type: Object,
      required: true,
    },

    initialCompanyNanoid: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      companyNanoId: this.initialCompanyNanoid,
      title: this.educationGroup.title ? this.educationGroup.title : null,
    };
  },

  computed: {
    formMethod() {
      return this.educationGroup.id ? "patch" : "post";
    },

    formUrl() {
      return this.educationGroup.id
        ? this.url(`/educations/groups/${this.educationGroup.id}`)
        : this.url("/educations/groups");
    },

    allFormattedCompanies() {
      let companies = this.cloneDeep(this.companies);
      return companies
        .sort((a, b) => (a.title < b.title ? -1 : 1))
        .map((company) => ({
          value: company.nanoid,
          text: company.title,
        }));
    },

    selectedCompanyUsers() {
      if (this.companyNanoId && this.companies) {
        return this.companies
          .find((company) => company.nanoid == this.companyNanoId)
          .memberships.map((membership) => membership.user);
      }

      return null;
    },
  },
};
</script>
