<template>
  <div>
    <div v-if="features.length > 0 || integrations.length > 0">
      <be-tabs
        v-model="activeTab"
        :options="tabsData"
        translation-scope="components.shop.tabs"
      >
        <template #content-right>
          <div class="d-flex justify-content-end">
            <be-button
              v-if="canEditIntegrations"
              :href="url('/integrations')"
              variant="outline-primary"
              class="mt-2 mt-md-0 mr-md-2"
            >
              {{ $t("components.shop.handle_integrations") }}
            </be-button>

            <be-form-group class="w-auto m-0 d-none d-md-block">
              <be-form-radio-group
                id="layout-toggle"
                v-model="layout"
                :options="layoutOptions"
                button-variant="outline-secondary"
                buttons
              />
            </be-form-group>
          </div>
        </template>
      </be-tabs>

      <div
        v-if="activeTab == tabsData.features.value"
        id="features"
        class="tab-pane show active"
        role="tabpanel"
      >
        <feature-list
          :features="features"
          :layout="layout"
          :trial="trial"
          feature-type="features"
        />
      </div>

      <div
        v-if="activeTab == tabsData.integrations.value"
        id="integrations"
        class="tab-pane"
        role="tabpanel"
      >
        <feature-list
          :features="integrations"
          :layout="layout"
          :trial="trial"
          feature-type="integrations"
        />
      </div>

      <feature-price-variations-modal />
    </div>

    <div v-else class="card">
      <div class="card-body">
        <be-alert variant="info">
          {{ $t(`components.shop.empty`) }}
        </be-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

const TABS = {
  features: "features",
  integrations: "integrations",
};

export default {
  props: {
    features: {
      type: Array,
      required: true,
    },

    integrations: {
      type: Array,
      required: true,
    },

    initialTab: {
      type: String,
      required: true,
    },

    trial: {
      type: Boolean,
      required: true,
    },

    canUpdateCompany: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    const tabsData = {};
    Object.entries(TABS).map(([key, value]) => {
      tabsData[key] = { value };
      tabsData[key].count =
        key === "features" ? this.features.length : this.integrations.length;
    });

    return {
      activeTab: this.initialTab,
      tabsData,
      layout: "grid",

      layoutOptions: [
        {
          html: `<i class='fas fa-grid mr-1'></i>${this.$t(
            "components.shop.layout_toggle.grid"
          )}`,

          value: "grid",
        },
        {
          html: `<i class='fas fa-list mr-1'></i>${this.$t(
            "components.shop.layout_toggle.list"
          )}`,

          value: "list",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
    }),

    canEditIntegrations() {
      return (
        this.activeTab === "integrations" &&
        this.canUpdateCompany &&
        this.hasIntegrations
      );
    },

    hasIntegrations() {
      return this.integrations.some((integration) => {
        return this.hasActiveFeature(integration.name);
      });
    },
  },

  methods: {
    toggleLayout() {
      this.layout = this.layout === "grid" ? "list" : "grid";
    },
  },
};
</script>
