import { handleError } from "@/utils/error-handling";

export const requestSuggestions = (start_at, end_at, day_count, urlHelper) => {
  return axios.get(urlHelper("/meetings/generate_suggestions"), {
    params: {
      start_at,
      end_at,
      day_count,
    },
  });
};

export const requestMeetingNumberValidation = async (meeting, urlHelper) => {
  const { id, number, meeting_series_type } = meeting;

  try {
    const result = await axios.post(
      urlHelper("/meetings/validate_meeting_number"),
      {
        meeting_id: id,
        meeting_number: number,
        meeting_series_type: meeting_series_type,
      }
    );
    return result.data;
  } catch (error) {
    handleError(error, {
      message: "Could not request meeting number validation",
    });
    return { allowed: false, type: "error" };
  }
};

const formatMeetingRequestData = (meeting, numberOverride = false) => {
  const suggestions_attributes = meeting.suggestions.map((suggestion) => {
    return {
      ...suggestion,
      id: typeof suggestion.id == "number" ? suggestion.id : null,
    };
  });

  return {
    ...meeting,
    date_decided: true,
    suggestions_attributes,
    number_override: numberOverride,
  };
};

export const saveMeeting = async (meeting, numberOverride, saveAndContinue) => {
  const response = await axios.patch(meeting.paths.base, {
    meeting: formatMeetingRequestData(meeting, numberOverride),
    save_and_continue: saveAndContinue,
  });
  return response.data;
};

export const createMeeting = async (
  meeting,
  numberOverride,
  urlHelper,
  saveAndContinue
) => {
  const response = await axios.post(urlHelper("/meetings"), {
    meeting: formatMeetingRequestData(meeting, numberOverride),
    save_and_continue: saveAndContinue,
  });
  return response.data;
};
