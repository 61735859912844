import { url } from "@/url-helpers";

const removeItem = async ({ company, agendaTemplateId, itemId }) => {
  await axios.delete(
    url(`/meetings/agenda_templates/${agendaTemplateId}/items/${itemId}`, {
      company,
    })
  );
};

const addNewItem = async ({ company, agendaTemplateId, title }) => {
  const { data: item } = await axios.post(
    url(`/meetings/agenda_templates/${agendaTemplateId}/items`, {
      company,
    }),
    {
      item: {
        title,
      },
    }
  );
  return item;
};

const saveAgendaTemplate = async ({
  company,
  agendaTemplateId,
  agendaTemplate,
}) => {
  const { data: template } = await axios.patch(
    url(`/meetings/agenda_templates/${agendaTemplateId}`, { company }),
    {
      agenda_template: agendaTemplate,
    }
  );

  return template;
};

export default {
  removeItem,
  addNewItem,
  saveAgendaTemplate,
};
