<template>
  <div class="tab-pane active">
    <div class="row">
      <div class="col-12 col-xl-6 mb-4 mb-xl-0">
        <h3>
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.certification_header"
            )
          }}
        </h3>

        <annual-report-w-certification-upload />

        <be-alert v-if="showNoAnnualReportChecksumWarning" variant="warning">
          {{ $t("components.annual_reports.ixbrl_annual_report_no_checksum") }}
        </be-alert>

        <be-alert v-if="waitingForChecksum" loading>
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.waiting_for_checksum"
            )
          }}
        </be-alert>

        <be-alert v-else-if="checksumsMismatch" variant="danger">
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.checksum_mismatch"
            )
          }}
        </be-alert>

        <template v-else-if="certificationUploaded">
          <template v-if="!deliveredDate">
            <h3>
              {{ $t("components.annual_reports.digital_delivery_tab.upload") }}
            </h3>

            <p>
              {{
                $t("components.annual_reports.digital_delivery_tab.description")
              }}
            </p>

            <ol>
              <li>
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.step_terms"
                  )
                }}
                <i
                  v-if="hasAcceptedTerms && sameTermsAsLastTime"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_same_terms'
                    )
                  "
                  class="fas fa-check text-success ml-1"
                />

                <i
                  v-else-if="hasAcceptedTerms"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_done'
                    )
                  "
                  class="fas fa-check text-success ml-1"
                />

                <i
                  v-else-if="failed"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_failed'
                    )
                  "
                  class="fas fa-hexagon-exclamation text-danger ml-1"
                />
              </li>

              <li>
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.step_check"
                  )
                }}
                <i
                  v-if="annualReportCheckNotes"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_done'
                    )
                  "
                  class="fas fa-check text-success ml-1"
                />

                <i
                  v-else-if="hasAcceptedTerms && failed"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_failed'
                    )
                  "
                  class="fas fa-hexagon-exclamation text-danger ml-1"
                />
              </li>

              <li>
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.step_upload"
                  )
                }}
                <i
                  v-if="deliveredDate"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_done'
                    )
                  "
                  class="fas fa-check text-success ml-1"
                />

                <i
                  v-else-if="annualReportCheckNotes && failed"
                  v-be-tooltip="
                    $t(
                      'components.annual_reports.digital_delivery_tab.step_failed'
                    )
                  "
                  class="fas fa-hexagon-exclamation text-danger ml-1"
                />
              </li>
            </ol>

            <be-modal
              v-if="reviewingTerms && currentUserIsDelivering"
              id="review-terms"
              :title="
                $t(
                  'components.annual_reports.digital_delivery_tab.conditions_title'
                )
              "
              visible
              @ok="acceptTerms"
              @cancel="abortDelivery"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="jobCallback.body.terms" />
            </be-modal>

            <be-alert v-if="inProgress" variant="info" loading>
              {{
                $t("components.annual_reports.digital_delivery_tab.in_progress")
              }}
            </be-alert>

            <be-alert v-if="abortedByUser" variant="warning">
              {{ $t("components.annual_reports.digital_delivery_tab.aborted") }}
            </be-alert>

            <be-alert v-if="stale" variant="warning">
              {{ $t("components.annual_reports.digital_delivery_tab.stale") }}
            </be-alert>

            <be-alert v-if="failed" variant="danger">
              {{ $t("components.annual_reports.digital_delivery_tab.failed") }}
            </be-alert>

            <be-alert
              v-if="reviewingTerms && !currentUserIsDelivering"
              variant="info"
            >
              {{
                $t(
                  "components.annual_reports.digital_delivery_tab.awaiting_terms_acceptance"
                )
              }}
            </be-alert>

            <be-alert
              v-if="reviewingCheck && !currentUserIsDelivering"
              variant="info"
            >
              {{
                $t(
                  "components.annual_reports.digital_delivery_tab.awaiting_review_check_results"
                )
              }}
            </be-alert>

            <be-alert
              v-if="reviewingCheck && currentUserIsDelivering"
              variant="warning"
            >
              {{
                $t(
                  "components.annual_reports.digital_delivery_tab.review_check_results"
                )
              }}
            </be-alert>

            <div class="d-flex mt-2">
              <be-button
                v-if="!loading && can('start', 'digital_delivery') && stale"
                variant="primary"
                @click="startDelivery"
              >
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.start_when_stale"
                  )
                }}
              </be-button>

              <be-button
                v-else-if="!loading && can('start', 'digital_delivery')"
                variant="primary"
                @click="startDelivery"
              >
                {{ $t("components.annual_reports.digital_delivery_tab.start") }}
              </be-button>

              <be-button v-if="canAbort" variant="light" @click="abortDelivery">
                {{ $t("buttons.titles.cancel") }}
              </be-button>

              <be-button
                v-if="!loading && currentUserIsDelivering && reviewingCheck"
                variant="primary"
                class="ml-2"
                @click="acceptCheck"
              >
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.accept_check"
                  )
                }}
              </be-button>
            </div>
          </template>
        </template>

        <be-alert v-if="deliveredDate" variant="success">
          {{
            $t(
              "components.annual_reports.digital_delivery_tab.delivered_at_w_date",
              {
                date: deliveredDate,
              }
            )
          }}
        </be-alert>

        <div v-if="deliveredDate" class="mt-2">
          <h3>
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.sign_link_header"
              )
            }}
          </h3>

          <template v-if="!signedDate">
            <p>
              {{ signLinkDescription }}
            </p>

            <div v-if="deliveryResults" class="d-flex justify-content-end">
              <be-button
                v-if="currentUserIsCertificateSignee"
                variant="light"
                :href="deliveryResults.url"
                target="_blank"
                icon="fa-external-link"
                icon-custom-class="small"
              >
                {{
                  $t(
                    "components.annual_reports.digital_delivery_tab.sign_link_text"
                  )
                }}
              </be-button>

              <be-form-input-copy v-else :value="deliveryResults.url" />
            </div>
          </template>

          <be-alert v-else variant="success">
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.signed_at_w_date",
                {
                  date: signedDate,
                }
              )
            }}
          </be-alert>
        </div>

        <div v-if="signedDate" class="mt-2">
          <h3>
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.completion_header"
              )
            }}
          </h3>

          <be-alert v-if="registeredDate" variant="success">
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.completed_at_w_date",
                {
                  date: registeredDate,
                }
              )
            }}
          </be-alert>

          <be-alert v-else-if="rejectedDate" variant="danger">
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.rejected_at_w_date",
                {
                  date: rejectedDate,
                }
              )
            }}
          </be-alert>

          <be-alert v-else variant="warning">
            {{
              $t("components.annual_reports.digital_delivery_tab.not_completed")
            }}
          </be-alert>

          <div
            v-if="rejectedDate && annualReport.is_latest_version && !loading"
            class="d-flex justify-content-end"
          >
            <be-button
              variant="primary"
              class="ml-auto"
              @click="createNewVersion"
            >
              {{
                $t("components.annual_reports.digital_delivery_tab.new_version")
              }}
            </be-button>
          </div>
        </div>
      </div>

      <div class="col-12 col-xl-6">
        <h3>{{ $t("components.annual_reports.documents.title") }}</h3>

        <table v-if="ixbrlDocuments" class="table table-hover mb-1">
          <tbody>
            <tr v-for="ixbrlDocument in ixbrlDocuments" :key="ixbrlDocument.id">
              <td colspan="2">
                <document-link
                  :document-id="ixbrlDocument.id"
                  :filename="filenameWithSize(ixbrlDocument)"
                />
              </td>
            </tr>

            <tr v-if="certificationDocument">
              <td colspan="2">
                <document-link
                  :document-id="certificationDocument.id"
                  :filename="filenameWithSize(certificationDocument)"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <div v-if="annualReportCheckNotes">
          <h3>
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.annual_report_check_notes"
              )
            }}
          </h3>

          <digital-delivery-check-table :items="annualReportCheckNotes" />
        </div>

        <div v-if="auditReportCheckNotes">
          <h3>
            {{
              $t(
                "components.annual_reports.digital_delivery_tab.audit_report_check_notes"
              )
            }}
          </h3>

          <digital-delivery-check-table :items="auditReportCheckNotes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import DigitalDeliveryCheckTable from "./DigitalDeliveryCheckTable.vue";
import AnnualReportWCertificationUpload from "./AnnualReportWCertificationUpload.vue";

export default {
  components: { DigitalDeliveryCheckTable, AnnualReportWCertificationUpload },

  mixins: [AnnualReportTabBase],

  computed: {
    jobCallback() {
      return this.annualReport?.job_callbacks?.find(
        ({ key }) => key == "digital_delivery"
      );
    },

    deliveriesUrl() {
      return this.url(
        `/annual_reports/${this.annualReport.id}/digital_delivery`
      );
    },

    loading() {
      return (
        this.jobCallback?.status == "initial" ||
        this.jobCallback?.status == "in_progress"
      );
    },

    state() {
      return this.annualReport.digital_delivery_state;
    },

    abortedByUser() {
      return this.jobCallback?.body.progress == "aborted_by_user";
    },

    stale() {
      return this.jobCallback?.body.progress == "stale";
    },

    inProgress() {
      return ["in_progress", "initial"].includes(this.jobCallback?.status);
    },

    failed() {
      return this.jobCallback?.status == "failed" && !this.abortedByUser;
    },

    reviewingTerms() {
      return this.jobCallback?.body.progress == "review_terms";
    },

    reviewingCheck() {
      return (
        this.jobCallback?.body?.progress == "review_check_results" &&
        !this.jobCallback.body?.check_accepted
      );
    },

    completed() {
      return this.jobCallback?.body?.progress == "completed";
    },

    hasAcceptedTerms() {
      return this.jobCallback?.body?.terms_accepted;
    },

    sameTermsAsLastTime() {
      return this.jobCallback?.body?.terms_accepted == "same_as_last_time";
    },

    annualReportCheckNotes() {
      return this.jobCallback?.body?.annual_report_check_results;
    },

    auditReportCheckNotes() {
      return this.jobCallback?.body?.audit_report_check_results;
    },

    deliveryResults() {
      return this.jobCallback?.body?.annual_report_delivery_results;
    },

    auditReportDeliveryResults() {
      return this.jobCallback?.body?.audit_report_delivery_results;
    },

    currentUserIsDelivering() {
      return this.jobCallback?.user_id == this.$currentUser.id;
    },

    canAbort() {
      return (
        !this.loading &&
        this.currentUserIsDelivering &&
        this.can("abort", "digital_delivery")
      );
    },

    currentUserIsCertificateSignee() {
      return this.annualReport?.certificate_signee_id == this.$currentUser.id;
    },

    isCompleteFile() {
      return this.jobCallback?.body?.file_type == "arsredovisning_komplett";
    },

    allFilesAreSent() {
      return (
        this.isCompleteFile ||
        (this.deliveryResults && this.auditReportDeliveryResults)
      );
    },

    signLinkDescription() {
      if (this.currentUserIsCertificateSignee && this.allFilesAreSent) {
        return this.$t(
          "components.annual_reports.digital_delivery_tab.sign_link_description_for_signee"
        );
      } else if (this.currentUserIsCertificateSignee) {
        return this.$t(
          "components.annual_reports.digital_delivery_tab.sign_link_description_partial_for_signee"
        );
      } else if (this.allFilesAreSent) {
        return this.$t(
          "components.annual_reports.digital_delivery_tab.sign_link_description"
        );
      } else {
        return this.$t(
          "components.annual_reports.digital_delivery_tab.sign_link_description_partial"
        );
      }
    },

    // Get all non-nil ixbrl documents
    ixbrlDocuments() {
      return [
        this.annualReportWCertificationDocument ||
          this.annualReportIxbrlDocument,
        this.auditReportIxbrlDocument,
      ].filter((doc) => doc);
    },

    deliveredDate() {
      return this.eventDate("delivered");
    },

    signedDate() {
      return this.eventDate("signed");
    },

    registeredDate() {
      return this.eventDate("registered");
    },

    rejectedDate() {
      return this.eventDate("rejected");
    },
  },

  methods: {
    async startDelivery() {
      const { data } = await axios.post(this.deliveriesUrl);
      this.setData(data);
    },

    async abortDelivery() {
      this.loading = true;
      const { data } = await axios.delete(this.deliveriesUrl);
      this.setData(data);
    },

    async acceptTerms() {
      const terms_date = this.jobCallback.body.terms_date;
      const { data } = await axios.put(this.deliveriesUrl, {
        terms_date,
      });
      this.setData(data);
    },

    async acceptCheck() {
      const { data } = await axios.put(this.deliveriesUrl, {
        accept_check: true,
      });
      this.setData(data);
    },

    async setData(annualReport) {
      this.setAnnualReport(annualReport);
    },

    async createNewVersion() {
      const id = await this.addAnnualReport({
        year: this.annualReport.year,
        responsible_id: this.annualReport.responsible_id,
        ceo_id: this.annualReport.ceo_id,
        delivery_method: "digital",
        auditor_ids: this.annualReport.auditor_ids,
        certificate_signee_id: this.annualReport.certificate_signee_id,
      });
      window.location = this.url(`/annual_reports/${id}`);
    },

    eventDate(eventName) {
      const transitionDates = this.datesForTransitions([
        { event: eventName, namespace: "digital_delivery" },
      ]);

      if (transitionDates && transitionDates.length > 0) {
        return transitionDates[0];
      }

      return null;
    },
  },
};
</script>
