<template>
  <div class="card">
    <div class="card-header">
      {{ headerText }}
    </div>

    <div class="card-body">
      <bank-id-collect
        v-if="returningFromBankId()"
        :collect-url="bankIdCollectUrl"
        :modal="false"
      />

      <bank-id-authentication
        begin-authentication-on-mount
        collect-url="/bank_id/collect/elevation"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    organizationTitle: {
      type: String,
      required: true,
    },
  },

  computed: {
    headerText() {
      if (this.organizationTitle) {
        return this.$t(
          "components.authentication.bankid_elevate.elevate_for_w_title",
          { title: this.organizationTitle }
        );
      } else {
        return this.$t("components.authentication.bankid_elevate.elevate_for");
      }
    },
  },

  methods: {
    returningFromBankId() {
      return /#bank-id-return/.test(window.location.hash);
    },
  },
};
</script>
