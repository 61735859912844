<template>
  <div class="card mb-3">
    <div class="card-header">
      <h5 class="card-title">
        {{ $t("activerecord.models.inquiry.other") }}
      </h5>
    </div>

    <be-list-group flush>
      <be-list-group-item>
        <pe-accounting-payments
          :enabled-payment-integrations="enabledPaymentIntegrations"
        />
      </be-list-group-item>
    </be-list-group>
  </div>
</template>

<script>
import PeAccountingPayments from "../payment_integrations/PeAccountingPayments.vue";

export default {
  components: {
    PeAccountingPayments,
  },

  props: {
    enabledPaymentIntegrations: {
      type: Array,
      required: true,
    },
  },
};
</script>
