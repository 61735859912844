<template>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <be-form-group
          :label="$t('views.educations.questions.form.question')"
          label-for="title"
          :error="getErrors(question, ['title'])"
          required
        >
          <be-form-textarea
            id="title"
            v-model="title"
            name="education_question[title]"
            rows="3"
            max-rows="20"
            required
            :state="validationState(question, ['title'])"
            @change="clearErrors(question, ['title'])"
          />
        </be-form-group>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12 col-md-6">
        <div class="card mb-2">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th class="col-shrink"></th>

                    <th>
                      {{
                        `${$t(
                          "views.educations.questions.form.possible_answers"
                        )}*`
                      }}
                    </th>

                    <th class="col-shrink text-center">
                      {{
                        `${$t(
                          "views.educations.questions.form.correct_answer"
                        )}*`
                      }}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(possibleAnswer, key) in question.possible_answers"
                    :key="`possible-answer-${key}`"
                  >
                    <td class="col-shrink text-center">
                      <strong>
                        {{ key.toUpperCase() }}
                      </strong>
                    </td>

                    <td>
                      <be-form-group
                        :error="getErrors(question, key)"
                        class="mb-0"
                      >
                        <be-form-input
                          :model-value="possibleAnswer"
                          :name="`education_question[possible_answers][${key}]`"
                          @change="clearErrors(question, key)"
                        />
                      </be-form-group>
                    </td>

                    <td class="col-shrink text-center">
                      <be-form-group
                        :error="getErrors(question, ['correct_answer'])"
                        class="mb-0"
                      >
                        <be-form-radio
                          v-model="correctAnswer"
                          name="education_question[correct_answer]"
                          :checked-value="key"
                          :state="validationState(question, ['correct_answer'])"
                          @change="clearErrors(question, ['correct_answer'])"
                        />
                      </be-form-group>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <label>
              {{ $t("views.educations.questions.form.question_description") }}
            </label>

            <be-form-textarea
              v-model="description"
              name="education_question[description]"
              rows="3"
              max-rows="20"
            />
          </div>
        </div>

        <div class="card d-none d-md-block">
          <div class="card-body d-lg-flex">
            <be-button
              type="submit"
              variant="light"
              name="send_test"
              :data-confirm="confirmText"
            >
              {{ $t("views.educations.questions.form.send_test_question") }}
            </be-button>

            <div class="ml-auto">
              <be-button
                type="submit"
                :variant="question.published_at ? 'primary' : 'outline-primary'"
                :data-confirm="confirmText"
              >
                {{ $t("views.educations.questions.form.save") }}
              </be-button>

              <be-button
                v-if="!question.published_at"
                type="submit"
                variant="primary"
                name="save_and_publish"
                :data-confirm="
                  $t('views.educations.questions.form.publish_question')
                "
              >
                {{ $t("buttons.titles.publish") }}
              </be-button>
            </div>

            <input
              v-if="question.id"
              type="hidden"
              :value="question.id"
              :name="`education_question[id]`"
            />
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="card mb-2">
          <div class="card-header">
            <h5 class="card-title">
              {{ $t("views.educations.questions.form.references") }}
            </h5>
          </div>

          <div class="card-body">
            <question-reference
              v-for="(reference, index) in references"
              :key="index"
              :index="index"
              :reference="reference"
              :question="question"
              @reference-removed="removeReference()"
            />
          </div>

          <div class="card-footer">
            <be-button variant="outline-secondary" @click="addReference()">
              {{ $t("views.educations.questions.form.add_reference") }}
            </be-button>
          </div>
        </div>

        <div class="card mb-2">
          <div class="card-body">
            <label>{{ $t("views.educations.questions.form.image") }}</label>

            <single-file-uploader
              name="education_question"
              attachment-name="image"
              :file="question.image || {}"
              :html-form="true"
              :aws-url="awsUrl"
              @uploading="(value) => (uploadingFiles = value)"
            />
          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <div v-if="companies">
              <be-form-group
                :label="$t('views.educations.questions.form.company_name')"
                label-for="company_name"
                class="mb-0"
              >
                <be-form-select
                  id="company_name"
                  :model-value="companyNanoid"
                  name="company_nanoid"
                  :options="allFormattedCompanies"
                  :include-blank-option="
                    $t('views.educations.groups.new.no_board')
                  "
                />
              </be-form-group>
            </div>
          </div>

          <div class="card-footer d-md-none">
            <be-button
              type="submit"
              variant="light"
              name="send_test"
              :data-confirm="confirmText"
            >
              {{ $t("views.educations.questions.form.send_test_question") }}
            </be-button>

            <be-button
              type="submit"
              variant="outline-primary"
              :data-confirm="confirmText"
            >
              {{ $t("views.educations.questions.form.save") }}
            </be-button>

            <be-button
              v-if="!question.published_at"
              type="submit"
              variant="primary"
              name="save_and_publish"
              :data-confirm="
                $t('views.educations.questions.form.publish_question')
              "
            >
              {{ $t("views.educations.questions.form.save_and_publish") }}
            </be-button>

            <input
              v-if="question.id"
              type="hidden"
              :value="question.id"
              :name="`education_question[id]`"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionReference from "./question_references/QuestionReference.vue";

export default {
  components: {
    QuestionReference,
  },

  props: {
    initialCompanyNanoid: {
      type: String,
      required: false,
      default: null,
    },

    companies: {
      type: Array,
      required: false,
      default: () => [],
    },

    question: {
      type: Object,
      required: true,
    },

    awsUrl: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      correctAnswer: this.question.correct_answer
        ? this.question.correct_answer
        : null,

      description: this.question.description ? this.question.description : null,
      references: this.question.references ? this.question.references : [],
      companyNanoid: this.initialCompanyNanoid,
      title: this.question.title ? this.question.title : null,
      uploadingFiles: false,
    };
  },

  computed: {
    allFormattedCompanies() {
      return this.companies.map((company) => ({
        value: company.nanoid,
        text: company.title,
      }));
    },

    confirmText() {
      return this.question.published_at
        ? this.$i18n.t(
            "views.educations.questions.form.save_published_question"
          )
        : "";
    },
  },

  mounted() {
    if (this.references.length == 0) {
      this.addReference();
    }
  },

  methods: {
    addReference() {
      this.references.push({ title: null, url: null });
    },

    removeReference(removedReference) {
      let idx = this.references.findIndex(
        (reference) => reference.id == removedReference.id
      );
      this.references.splice(idx, 1);
    },

    fileUpdated(file) {
      this.question.image = file;
    },
  },
};
</script>
