<template>
  <div>
    <div class="card-body">
      <h5>
        {{
          materialType === "agenda"
            ? $t("models.material.external_agenda")
            : $t("models.material.external_minutes")
        }}
      </h5>

      <div
        class="bg-light rounded d-flex flex-wrap justify-content-between align-items-center gap-2 p-3 my-4"
      >
        <document-link
          class="mx-auto mx-md-0"
          :document-id="material.external_document.id"
          :filename="material.external_document.filename"
        />

        <div class="w-100 w-md-auto d-flex flex-wrap justify-content-end gap-2">
          <be-button v-be-modal.material-upload variant="outline-primary">
            {{ $t("components.meetings.material.upload_modal.change_file") }}
          </be-button>

          <be-button
            v-be-tooltip="$t('buttons.titles.remove')"
            variant="danger"
            icon="fa-times"
            @click="removeUploaded"
          />

          <material-upload-modal
            modal-id="material-upload-new"
            :material="material"
            has-items
            @material-updated="loadItemsAndMaterial"
          />
        </div>
      </div>

      <be-alert>
        {{
          materialType === "agenda"
            ? $t("components.meetings.material.editor.external_uploaded_agenda")
            : $t(
                "components.meetings.material.editor.external_uploaded_minutes"
              )
        }}
      </be-alert>

      <be-form-group
        v-if="item"
        :label="$t('activerecord.attributes.item.attachments')"
        label-for="document-uploader"
      >
        <document-uploader
          id="document-uploader"
          reactive
          :documents="documents"
          support-select-from-archive
          :manual-save="false"
          @document-added="addDocument"
          @document-removed="removeDocument"
          @uploading="(value) => (value ? setLoadingStart() : setLoadingStop())"
        />
      </be-form-group>
    </div>

    <template v-if="materialType == 'minutes'">
      <div v-if="decisions.length > 0" class="card-body">
        <decision-form
          v-for="(decision, index) in decisions"
          :key="`decision-${decision.id}`"
          :decision="decision"
          :index="index"
          :item="item"
        />
      </div>

      <hr
        v-if="decisions.length > 0 && tasks.length > 0"
        class="mx-0 border-dashed"
      />

      <div v-if="tasks.length > 0" class="card-body">
        <task-form
          v-for="(task, index) in tasks"
          :key="`task-${task.id}`"
          :task="task"
          :index="index"
          :item="item"
          hide-include-in-material
        />
      </div>

      <div class="card-footer border-top-0">
        <div class="row">
          <div class="col-12 col-md">
            <be-button variant="outline-primary" @click="addDecision">
              {{ $t("components.meetings.material.item.add_decision") }}
            </be-button>

            <be-button variant="outline-primary" @click="addTask">
              {{ $t("components.meetings.material.item.add_task") }}
            </be-button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

import materialType from "./materialState";

import MaterialUploadModal from "./MaterialUploadModal.vue";
import DecisionForm from "./DecisionForm.vue";
import TaskForm from "./TaskForm.vue";

export default {
  components: {
    MaterialUploadModal,
    DecisionForm,
    TaskForm,
  },

  mixins: [materialType],

  computed: {
    ...mapGetters("material", ["items"]),

    item() {
      return this.items[0];
    },

    decisions() {
      if (this.item) {
        return this.$store.getters["material/getItemDecisions"](this.item);
      }

      return [];
    },

    tasks() {
      if (this.item) {
        return this.$store.getters["material/getItemTasks"](this.item);
      }

      return [];
    },

    documents() {
      if (this.item) {
        return this.$store.getters["material/getItemDocuments"](this.item);
      }

      return [];
    },
  },

  watch: {
    items: {
      handler(items) {
        if (items?.length === 0) {
          this.addItem();
        }
      },

      deep: true,
    },
  },

  mounted() {
    if (this.items?.length === 0) {
      this.addItem();
    }
  },

  methods: {
    ...mapActions("material", [
      "createItem",
      "createDecision",
      "createTask",
      "createItemDocument",
      "removeItemDocument",
    ]),

    ...mapMutations("material", [
      "loadItemsAndMaterial",
      "setLoadingStart",
      "setLoadingStop",
    ]),

    async addItem() {
      await this.createItem({
        item: { title: "" },
        skip_flash: true,
      });
    },

    addDecision() {
      this.createDecision(this.item);
    },

    addTask() {
      this.createTask(this.item);
    },

    addDocument(document) {
      this.createItemDocument({ item: this.item, document: document });
    },

    removeDocument(document) {
      this.removeItemDocument({ item: this.item, document: document });
    },

    async removeUploaded() {
      const isConfirmed = await this.promptRemovalConfirm({
        title: this.$t("nav.confirm_delete_w_title", {
          title: this.material.external_document.filename,
        }),

        description:
          this.materialType === "agenda"
            ? this.$t(
                "components.meetings.material.editor.confirm_remove_uploaded_agenda"
              )
            : this.$t(
                "components.meetings.material.editor.confirm_remove_uploaded_minutes"
              ),
      });

      if (!isConfirmed) {
        return;
      }

      try {
        const { data } = await axios.delete(
          this.url(
            `meetings/${this.material.meeting_id}/materials/${this.material.id}/upload`
          )
        );
        this.loadItemsAndMaterial(data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
