<template>
  <div class="table-responsive">
    <table class="w-100 mb-4">
      <tbody>
        <template
          v-for="item in items"
          :key="`item-rows-${item.id || item.uuid}`"
        >
          <tr>
            <td class="col-shrink">
              <h3 class="mb-1 pl-0">{{ item.number }}.</h3>
            </td>

            <td>
              <h3 class="mb-1">{{ item.title }}</h3>
            </td>
          </tr>

          <tr class="mb-3">
            <td class="col-shrink mb-3" />

            <td>
              <div>
                <div class="small mb-3">
                  <template v-if="item.subtitle">
                    {{
                      $t(
                        `models.item.subtitles.${item.subtitle}`,
                        company.locale
                      )
                    }}
                    <span>•</span>
                  </template>

                  <template v-if="formatItemDuration(item)">
                    {{ formatItemDuration(item) }}
                  </template>
                </div>

                <div
                  v-if="item.description && item.description.length > 0"
                  class="mb-3"
                >
                  <p
                    v-dompurify-html="item.description"
                    class="ql-editor ql-viewer ql-viewer-raw p-0 overflow-hidden"
                  />
                </div>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import { formatTimeDuration } from "@/utils/time";
import { mapGetters } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      company: "company/getCompany",
    }),
  },

  methods: {
    formatItemDuration(item) {
      return formatTimeDuration(item.duration_in_seconds, this.company.locale);
    },
  },
};
</script>
