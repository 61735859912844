<template>
  <div>
    <div class="card mb-2">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <be-form-group
              :error="
                getErrors(evaluationForm.evaluation_report, ['description'])
              "
              :label="
                $t('activerecord.attributes.evaluation_report.description')
              "
              label-for="evaluation-report-description"
            >
              <be-form-textarea
                id="evaluation-report-description"
                v-model="evaluationForm.evaluation_report.description"
                rows="3"
                max-rows="20"
                required
                :state="
                  validationState(evaluationForm.evaluation_report, [
                    'description',
                  ])
                "
                @change="
                  clearErrors(evaluationForm.evaluation_report, ['description'])
                "
              />
            </be-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-2 sticky-top">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-auto ml-auto">
            <be-button
              variant="outline-primary"
              @click="toggleAllSectionsVisibility()"
            >
              {{ toggleButtonText }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <form-section
      v-for="(section, index) in evaluationForm.evaluation_sections"
      :key="`section-${index}`"
      :section="section"
      :section-chart-data="chartData[section.id]"
      :show-graphs="true"
      class="mb-2"
      @question-inclusion-toggled="toggleIncludeQuestion"
      @question-visibility-toggled="toggleQuestionVisibility"
      @section-inclusion-toggled="toggleIncludeSectionQuestions"
      @section-visibility-toggled="toggleSectionVisibility"
    />

    <div class="card">
      <div class="card-body">
        <div class="d-md-flex justify-content-md-end">
          <be-button
            v-be-tooltip="{
              title: $t(
                'components.evaluation_forms.report.preview_report_tooltip'
              ),
              disabled: !!evaluationForm.evaluation_report.id,
            }"
            data-name="preview_evaluation_report"
            variant="outline-secondary"
            :disabled="!evaluationForm.evaluation_report.id || loading"
            @click="save('preview_evaluation_report')"
          >
            {{
              $t(
                "components.evaluation_forms.evaluation_form_viewer.evaluation_row.view_report"
              )
            }}
          </be-button>

          <be-button
            data-name="save"
            variant="outline-primary"
            :loading="loading"
            @click="save()"
          >
            {{ saveButtonText }}
          </be-button>

          <be-button
            variant="primary"
            data-name="save_and_archive"
            :loading="loading"
            @click="save('save_and_archive')"
          >
            {{ saveAndArchiveButtonText }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";

export default {
  components: {
    FormSection,
  },

  props: {
    initialEvaluationForm: {
      type: Object,
      required: true,
    },

    chartData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      includedInReport: [],
      evaluationForm: this.initialEvaluationForm,
      loading: false,
      saveType: "save",
      open: false,

      sectionsWithOnlyGraphs:
        this.initialEvaluationForm.evaluation_sections.filter((section) =>
          section.evaluation_questions.find(
            (question) => question.question_type != "open"
          )
        ),

      sectionsWithoutGraphs:
        this.initialEvaluationForm.evaluation_sections.filter((section) =>
          section.evaluation_questions.find(
            (question) => question.question_type == "open"
          )
        ),
    };
  },

  computed: {
    saveConfirmTexts() {
      return {
        confirmButtonText: this.$t("buttons.titles.save"),

        title: this.$t(
          "views.companies.evaluation_forms.reports.new.confirm_save"
        ),

        text: this.$t(
          "views.companies.evaluation_forms.reports.new.confirm_save_description"
        ),
      };
    },

    archiveConfirmTexts() {
      return {
        confirmButtonText: this.$t("buttons.titles.archive"),

        title:
          this.includedInReport.length > 0
            ? this.$t(
                "views.companies.evaluation_forms.reports.new.confirm_archive"
              )
            : this.$t(
                "views.companies.evaluation_forms.reports.new.confirm_archive_empty"
              ),

        text: this.evaluationForm.evaluation_report.id
          ? this.$t(
              "views.companies.evaluation_forms.reports.new.confirm_archive_description"
            )
          : this.$t(
              "views.companies.evaluation_forms.reports.new.confirm_archive_and_close_description"
            ),
      };
    },

    saveButtonText() {
      return this.loading
        ? this.$i18n.t("buttons.states.saving")
        : this.$i18n.t("buttons.titles.save");
    },

    saveAndArchiveButtonText() {
      return this.loading
        ? this.$i18n.t("buttons.states.saving")
        : this.$i18n.t(
            "views.companies.evaluation_forms.reports.edit_form.archive_and_distribute"
          );
    },

    expandedQuestions() {
      return this.evaluationForm.evaluation_sections.reduce(
        (acc, section) =>
          acc +
          section.evaluation_questions.filter((question) => question.open)
            .length,
        0
      );
    },

    toggleButtonText() {
      return this.expandedQuestions > 0
        ? this.$t("buttons.toggle_all_selection.minimize_all")
        : this.$t("buttons.toggle_all_selection.expand_all");
    },
  },

  mounted() {
    this.includedInReport = this.populateWithIds();
  },

  methods: {
    findSection(sectionId) {
      return this.evaluationForm.evaluation_sections.find(
        (existingSection) => existingSection.id == sectionId
      );
    },

    async save(saveType) {
      let confirmTexts = this.saveConfirmTexts;

      if (saveType === "save_and_archive") {
        confirmTexts = this.archiveConfirmTexts;
      }

      if (
        !this.evaluationForm.evaluation_report.id ||
        saveType === "save_and_archive"
      ) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: confirmTexts.confirmButtonText,
          title: confirmTexts.title,
          description: confirmTexts.text,
        });

        if (isConfirmed) {
          this.sendSaveRequest(saveType);
        }
      } else {
        this.sendSaveRequest(saveType);
      }
    },

    async sendSaveRequest(saveType) {
      const data = {
        description: this.evaluationForm.evaluation_report.description,
        included_in_report: this.includedInReport,
        evaluation_report: this.evaluationForm.evaluation_report,
        save_type: saveType,
      };

      this.loading = true;

      try {
        const response = await axios.patch(
          this.url(`/evaluation_forms/${this.evaluationForm.id}/report`),
          data
        );

        if (saveType == "save_and_archive") {
          window.location.href = this.url(
            `/evaluation_forms/${this.evaluationForm.id}/report_invitations`
          );
        } else if (saveType == "preview_evaluation_report") {
          window.location.href = this.url(
            `/evaluation_forms/${this.evaluationForm.id}/report`
          );
        } else if (!this.evaluationForm.evaluation_report.id) {
          window.location.href = this.url(
            `/evaluation_forms/${this.evaluationForm.id}/report/edit`
          );
        }

        this.evaluationForm = response.data;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    populateWithIds() {
      let result = [];

      this.evaluationForm.evaluation_sections.forEach((section) => {
        result.push(
          section.evaluation_questions
            .filter((question) => question.included_in_report != null)
            .map((includedQuestion) => includedQuestion.id.toString())
        );
      });

      return result.flat();
    },

    toggleAllSectionsVisibility() {
      this.open = !this.open;

      this.evaluationForm.evaluation_sections.forEach((section) => {
        section.open = this.open;
        section.evaluation_questions.forEach((question) => {
          question.open = this.open;
        });
      });
    },

    toggleSectionVisibility({ sectionId, open }) {
      const originalSection = this.findSection(sectionId);

      originalSection.open = open;

      originalSection.evaluation_questions.forEach((question) => {
        question.open = originalSection.open;
      });
    },

    toggleIncludeSectionQuestions({ sectionId, includedInReport }) {
      // Find section within the nested objects we are looping over
      const originalSection = this.findSection(sectionId);

      originalSection.included_in_report = includedInReport;

      // Update the array of id:s that we want to send to the backend
      originalSection.evaluation_questions.forEach((question) => {
        const index = this.includedInReport.findIndex(
          (questionId) => questionId == question.id
        );

        if (includedInReport) {
          if (index == -1) {
            this.includedInReport.push(question.id.toString());
          }
        } else {
          if (index != -1) {
            this.includedInReport.splice(index, 1);
          }
        }

        // Update the actual evaluation form question data
        question.included_in_report = includedInReport;
      });
    },

    toggleIncludeQuestion({ sectionId, question }) {
      // Update the array of id:s that we want to send to the backend
      const index = this.includedInReport.findIndex(
        (questionId) => questionId == question.id
      );

      if (index == -1) {
        this.includedInReport.push(question.id.toString());
      } else {
        this.includedInReport.splice(index, 1);
      }

      // Update the actual evaluation form question data
      const originalSection = this.findSection(sectionId);

      const originalQuestion = originalSection.evaluation_questions.find(
        (existingQuestion) => existingQuestion.id == question.id
      );

      originalQuestion.included_in_report = !question.included_in_report;
    },

    toggleQuestionVisibility({ sectionId, question }) {
      // Find section within the nested objects we are looping over
      const originalSection = this.findSection(sectionId);

      const originalQuestion = originalSection.evaluation_questions.find(
        (existingQuestion) => existingQuestion.id == question.id
      );

      originalQuestion.open = !question.open;
    },
  },
};
</script>
