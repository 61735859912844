<template>
  <div>
    <states-table
      :states="states"
      table-class="table-sm"
      @click-edit-state="$emit('click-edit-state', $event)"
      @click-remove-state="$emit('click-remove-state', $event)"
    />

    <div v-if="ownership.can_edit" class="text-right mt-2">
      <be-button
        variant="outline-secondary"
        size="sm"
        class="mt-2"
        @click="$emit('click-add-state')"
      >
        {{ $t("views.companies.group.edit.add_new_owner_distribution") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import StatesTable from "./StatesTable.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("corporate_group_tree");

export default {
  components: { StatesTable },

  props: {
    ownership: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  emits: ["click-add-state", "click-edit-state", "click-remove-state"],

  computed: {
    states() {
      const company_group_ownership = { ...this.ownership };
      delete company_group_ownership.states;

      return [...this.ownership.states]
        .map((state) => ({
          ...state,
          company_group_ownership,
        }))
        .sort((a, b) => {
          return new Date(a.start_at) - new Date(b.start_at);
        });
    },
  },

  methods: {
    ...mapActions(["loadTree"]),
  },
};
</script>
