<template>
  <div>
    <div class="card mb-3">
      <div class="card-body">
        <be-form-group
          label-for="organization-numbers"
          :label="$t('activerecord.attributes.company.organization_number')"
          :description="
            $t(
              'components.admin_panel.fetch_from_syna.organization_numbers_description'
            )
          "
        >
          <be-form-textarea
            id="organization-numbers"
            v-model="organizationNumbers"
          />
        </be-form-group>
      </div>

      <div class="card-footer d-md-flex justify-content-end">
        <be-button :loading="loading" @click="loadSuggestions">
          {{ $t("components.admin_panel.fetch_from_syna.preview") }}
        </be-button>
      </div>
    </div>

    <div v-if="suggestions.length > 0" class="card mb-3">
      <div class="card-header">
        {{ $t("components.admin_panel.fetch_from_syna.preview") }}
      </div>

      <div class="card-body">
        <div class="mb-3">
          <be-link
            :href="`/admin_panel/${adminPanel.id}/admin/advanced_settings/edit`"
          >
            {{
              $t(
                "components.admin_panel.fetch_from_syna.check_default_features_w_count",
                { count: adminPanel.manage_features.length }
              )
            }}
          </be-link>
        </div>

        <be-table :items="suggestions" :fields="fields">
          <template #title="{ item }">
            <div>{{ item.title }}</div>

            <div>
              <small>{{ formatOrgNumber(item.id) }}</small>
            </div>
          </template>

          <template #address="{ item }">
            <div v-if="item.billing_address">
              <div>{{ item.billing_address }}</div>

              <div v-if="item.billing_address_two">
                {{ item.billing_address_two }}
              </div>

              <div>{{ item.postcode }} {{ item.city }}</div>
            </div>
          </template>

          <template #type_of_organization="{ item }">
            {{
              $t(
                `models.company.type_of_organizations.${item.type_of_organization}`
              )
            }}
          </template>

          <template #financial_year_start="{ item }">
            {{ formatFinancialYear(item.financial_year_start) }}
          </template>

          <template #actions="{ item }">
            <be-button
              variant="danger"
              size="sm"
              icon="fa-times"
              inline
              @click="removeSuggestion(item)"
            />
          </template>
        </be-table>

        <div class="d-flex justify-content-end mt-2">
          <be-button :loading="loading" @click="createCompanies">
            {{ $t("components.admin_panel.fetch_from_syna.create") }}
          </be-button>
        </div>
      </div>
    </div>

    <div v-if="createdCompanies.length > 0" class="card">
      <div class="card-header">
        {{ $t("components.admin_panel.fetch_from_syna.created_companies") }}
      </div>

      <div class="card-body">
        <light-table :companies="createdCompanies" :admin-panel="adminPanel" />
      </div>
    </div>
  </div>
</template>

<script>
import LightTable from "./LightTable.vue";

export default {
  components: {
    LightTable,
  },

  props: {
    adminPanel: {
      type: Object,
      required: true,
    },

    initialSuggestions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loading: false,
      suggestions: [...this.initialSuggestions],
      createdCompanies: [],
      organizationNumbers: "",

      fields: [
        {
          key: "title",
          label: this.$t("activerecord.attributes.company.title"),
          sortable: true,
        },
        {
          key: "address",
          label: this.$t("activerecord.attributes.company.address"),
        },
        {
          key: "type_of_organization",

          label: this.$t(
            "activerecord.attributes.company.type_of_organization"
          ),
        },
        {
          key: "financial_year_start",

          label: this.$t("activerecord.attributes.company.financial_year"),
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink",
        },
      ],
    };
  },

  watch: {
    async activeTab(value) {
      if (value === "existing-companies") {
        await this.loadCompanies();
      }
    },
  },

  methods: {
    removeSuggestion(suggestion) {
      this.suggestions = this.suggestions.filter(
        (item) => item.id !== suggestion.id
      );
    },

    async createCompanies() {
      this.loading = true;
      for (const suggestion of this.suggestions) {
        await this.createCompany(suggestion);
      }
      this.loading = false;
    },

    async createCompany(suggestion) {
      try {
        const response = await axios.post(
          `/admin_panel/${this.adminPanel.id}/companies.json`,
          {
            company: {
              ...suggestion,
              organization_number: this.formatOrgNumber(suggestion.id),
            },
          }
        );
        this.removeSuggestion(suggestion);
        this.createdCompanies.push(response.data);
      } catch (error) {
        if (error?.response?.status === 422) {
          this.newCompany = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    async loadSuggestions() {
      this.loading = true;

      const organizationNumbers = this.organizationNumbers
        .split(/\s|\n/)
        .map((number) => number.trim())
        .filter((number) => number.length > 0);

      try {
        const response = await axios.get(
          `/admin_panel/${this.adminPanel.id}/companies/from_register`,
          {
            params: {
              organization_numbers: organizationNumbers,
            },
          }
        );

        this.suggestions = [...this.suggestions, ...response.data.items];
        this.organizationNumbers = "";
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    formatOrgNumber(value) {
      if (!value) return "";
      return value.replace(/(\d{6})(\d{4})/, "$1-$2");
    },

    formatFinancialYear(startMonth) {
      if (!startMonth) return "";
      const from = this.$d(new Date(2022, startMonth - 1, 1), "month");
      const to = this.$d(new Date(2023, startMonth - 2, 1), "month");
      return `${from} - ${to}`;
    },
  },
};
</script>
