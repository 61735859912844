<template>
  <div
    v-show="!localSeries.destroyed"
    class="mb-3"
    :class="{ 'bg-light px-3 pt-3 pb-2': editable }"
  >
    <div class="row">
      <div :class="editable ? 'col-12 col-md' : 'col-4'">
        <input
          type="hidden"
          :name="`shareholder_transaction[shareholder_transaction_series_attributes][${index}][id]`"
          :value="localSeries.id"
        />

        <be-form-group
          :label="
            $t('activerecord.attributes.shareholder_transaction_series.start')
          "
          :label-for="`series-start-${index}`"
          :error="getErrors(localSeries, errorKeysStart)"
        >
          <be-form-input
            :id="`series-start-${index}`"
            :key="`series-start-${uniqueIdentifier}`"
            v-model="localSeries.start"
            number
            type="number"
            :name="`shareholder_transaction[shareholder_transaction_series_attributes][${index}][start]`"
            :disabled="!editable"
            required
            @change="handleChange(errorKeysStart)"
          />
        </be-form-group>
      </div>

      <div :class="editable ? 'col-12 col-md' : 'col-4'">
        <be-form-group
          :label-for="`series-end-${index}`"
          :label="
            $t('activerecord.attributes.shareholder_transaction_series.end')
          "
          :error="getErrors(localSeries, errorKeysEnd)"
        >
          <be-form-input
            :id="`series-end-${index}`"
            :key="`series-end-${uniqueIdentifier}`"
            v-model="localSeries.end"
            number
            type="number"
            :name="`shareholder_transaction[shareholder_transaction_series_attributes][${index}][end]`"
            :disabled="!editable"
            required
            @change="handleChange(errorKeysEnd)"
          />
        </be-form-group>
      </div>

      <div :class="editable ? 'col-12 col-md' : 'col-3'">
        <be-form-group
          :label-for="`series_total-${index}`"
          :label="
            $t(
              'activerecord.attributes.shareholder_register_action.number_of_shares'
            )
          "
        >
          <be-form-input
            :id="`series_total-${index}`"
            number
            type="number"
            disabled
            :model-value="seriesTotal"
          />
        </be-form-group>
      </div>

      <div
        v-if="editable"
        class="col col-md-auto d-md-flex align-items-center mt-md-2"
      >
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="removeSeries"
        />

        <input
          type="hidden"
          :value="localSeries.destroyed ? '1' : '0'"
          :name="`shareholder_transaction[shareholder_transaction_series_attributes][${index}][_destroy]`"
        />
      </div>
    </div>

    <be-alert
      v-if="editable && overlap.length > 0"
      variant="warning"
      class="mb-2"
    >
      {{
        $t(
          "components.shareholder_registers.transaction_form.overlap_within_action"
        )
      }}
      <ul>
        <li
          v-for="(row, idx) in overlap"
          :key="`series-row-${series.uuid}-${idx}`"
        >
          {{ row.name }} {{ row.start }} - {{ row.end }}
        </li>
      </ul>
    </be-alert>
  </div>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },

    index: {
      type: Number,
      required: true,
    },

    series: {
      type: Object,
      required: true,
    },

    overlap: {
      type: Array,
      require: false,
      default: () => [],
    },
  },

  emits: ["change"],

  data() {
    return {
      localSeries: this.cloneDeep(this.series),
      errorKeysStart: ["start", "end_smaller_than_start"],
      errorKeysEnd: ["end", "end_smaller_than_start"],
    };
  },

  computed: {
    seriesTotal() {
      if (this.localSeries.start && this.localSeries.end) {
        return this.localSeries.end - this.localSeries.start + 1;
      } else {
        return null;
      }
    },

    uniqueIdentifier() {
      return this.localSeries.id || this.localSeries.uuid;
    },
  },

  watch: {
    series: {
      handler(value) {
        this.localSeries = this.cloneDeep(value);
      },

      deep: true,
    },
  },

  methods: {
    handleChange(key) {
      this.clearErrors(this.localSeries, key);
      this.$emit("change", this.localSeries);
    },

    removeSeries() {
      this.localSeries.destroyed = true;
      this.$emit("change", this.localSeries);
    },
  },
};
</script>
