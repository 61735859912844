<template>
  <div class="card">
    <div class="card-body">
      <h3>{{ $t("components.template_packages.nav.tabs.saved_templates") }}</h3>

      <div class="row mb-3">
        <div class="col-12 col-md-9 mb-3 mb-md-0">
          {{ $t("components.template_packages.saved_templates.description") }}
        </div>
      </div>

      <be-table
        v-if="savedTemplates.length > 0"
        :items="savedTemplates"
        :fields="fields"
        sort-by="created_at"
        sort-desc
      >
        <template #title="{ item }">
          <template v-if="item.title">
            {{ item.title }}
          </template>

          <template v-else-if="item.content">
            {{ item.content }}
          </template>
        </template>

        <template #template_type="{ item }">
          {{ $t(`models.template_package.types.${item.template_type}`) }}
        </template>

        <template #template_package_title="{ item }">
          <template
            v-if="
              item.template_package &&
              item.template_package.original_package_items_size > 1
            "
          >
            {{ item.template_package.title }}
          </template>
        </template>

        <template #created_at="{ item }">
          {{ formattedCreatedAt(item) }}
        </template>

        <template #creators="{ item }">
          {{ item.template_package?.creators }}
        </template>

        <template #options="{ item }">
          <be-button
            v-if="item.policy?.destroy"
            v-be-tooltip="$t('buttons.titles.remove')"
            variant="danger"
            size="sm"
            icon="fa-times"
            inline
            @click="removeTemplate(item)"
          />
        </template>
      </be-table>

      <be-alert v-else variant="info">
        {{ $t("components.template_packages.empty") }}
      </be-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        {
          key: "title",
          label: this.translateAttribute("template_package", "title"),
          sortable: true,
        },
        {
          key: "template_type",
          label: this.translateAttribute("template_package", "type"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "template_package_title",

          label: this.translateAttribute(
            "template_package",
            "template_package_title"
          ),

          class: "d-none d-md-table-cell",
          sortable: true,
        },
        {
          key: "created_at",
          label: this.$i18n.t("models.template_package.saved"),
          class: "col-shrink d-none d-md-table-cell",
          sortable: true,
        },
        {
          key: "creators",
          label: this.translateAttribute("template_package", "creators"),
          class: "d-none d-md-table-cell",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      savedTemplates: "template_packages/savedTemplates",
    }),
  },

  methods: {
    async removeTemplate(template) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: template.title,
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(template.destroy_url);

          this.$store.commit("template_packages/removeTemplate", response.data);
        } catch (error) {
          this.handleError(error);
        }
      }
    },

    formattedCreatedAt(template) {
      return this.$i18n.d(new Date(template.created_at), "date");
    },
  },
};
</script>
