<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h3 class="mb-3">
          {{ $t("components.meetings.tabs.archived.title") }}
        </h3>

        <div class="d-md-flex justify-content-start">
          <be-button
            :href="`${meeting.paths.base}/minutes`"
            variant="outline-primary"
            class="mb-3"
          >
            {{ $t("components.meetings.tabs.archived.reviewed_minutes") }}
          </be-button>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <h3 class="mb-3">
          {{ $t("components.meetings.overview.meeting_information") }}
        </h3>

        <div class="media mb-4 mb-sm-3">
          <div
            class="media-icon align-self-center align-self-md-start mt-md-1 mr-3"
          >
            <div class="media-icon">
              <div class="h3">
                <i class="fal fa-fw fa-calendar text-primary" />
              </div>
            </div>
          </div>

          <div class="media-body">
            <h5 class="mb-0">
              {{ $t("components.meetings.info.date") }}
            </h5>

            <span v-if="meeting.start_at">
              {{ $d(new Date(meeting.start_at), "date") }}
            </span>

            <span v-else> - </span>
          </div>
        </div>

        <div class="media mb-4 mb-sm-3">
          <div class="align-self-center align-self-md-start mt-md-1 mr-3">
            <div class="media-icon">
              <div class="h3">
                <i class="fas fa-fw fa-map-marker-alt text-primary" />
              </div>
            </div>
          </div>

          <div class="media-body">
            <h5 class="mb-0">
              {{ $t("components.meetings.info.location") }}
            </h5>

            <span v-if="meeting.location">
              {{ meeting.location }}
            </span>

            <span v-else> - </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },
};
</script>
