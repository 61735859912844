<template>
  <template v-if="targetText">
    <ul class="fa-ul ml-4 mb-0">
      <li>
        <span
          v-be-tooltip="{
            title: $t(
              'components.user.merge_requests.merge_request_user.will_remain_in_this_account'
            ),
            placement: 'left',
          }"
          class="fa-li"
        >
          <i class="fas text-muted fa-circle-check"></i>
        </span>

        <i>
          {{ targetText }}
        </i>
      </li>
    </ul>
  </template>

  <list-summary
    :class="['fa-ul ml-4', { 'mb-0': transferredItems?.length > 0 }]"
    :items="keptItems"
    :item-type="itemType.toLowerCase()"
  >
    <template #bullet>
      <span
        v-be-tooltip="{
          title: keptItemsTooltip,
          placement: 'left',
        }"
        class="fa-li"
      >
        <i class="fas text-muted fa-circle-check"></i>
      </span>
    </template>
  </list-summary>

  <list-summary
    class="fa-ul ml-4"
    :items="transferredItems"
    :item-type="itemType.toLowerCase()"
  >
    <template #bullet>
      <span
        v-be-tooltip="{
          title: transferredItemsTooltip,
          placement: 'left',
        }"
        class="fa-li"
      >
        <i
          :class="[
            'fas',
            {
              'fa-circle-plus': mode === 'target',
              'text-success': mode === 'target',
              'fa-circle-arrow-right': mode === 'source',
              'text-muted': mode === 'source',
            },
          ]"
        ></i>
      </span>
    </template>
  </list-summary>
</template>

<script>
import ListSummary from "./ListSummary.vue";

export default {
  components: {
    ListSummary,
  },

  props: {
    sourceItems: {
      type: Array,
      required: false,
      default: () => [],
    },

    itemType: {
      type: String,
      required: true,
    },

    targetItems: {
      type: Array,
      required: false,
      default: () => [],
    },

    targetText: {
      type: String,
      required: true,
    },

    mode: {
      type: String,
      required: true,
      validates: (value) => ["source", "target"].includes(value),
    },
  },

  computed: {
    itemsNotInSource() {
      return this.targetItems.filter(
        (item) => !this.sourceItems.includes(item)
      );
    },

    overlappingItems() {
      return this.sourceItems.filter((item) => this.targetItems.includes(item));
    },

    itemsNotInTarget() {
      return this.sourceItems.filter(
        (item) => !this.targetItems.includes(item)
      );
    },

    keptItems() {
      if (this.mode === "target") {
        return this.itemsNotInSource.concat(this.overlappingItems);
      } else if (this.mode === "source") {
        return this.overlappingItems;
      }
      return null;
    },

    keptItemsTooltip() {
      if (this.mode === "target") {
        return this.$t(
          "components.user.merge_requests.merge_request_user.will_remain_in_this_account"
        );
      } else if (this.mode === "source") {
        return this.$t(
          "components.user.merge_requests.merge_request_user.already_exists_in_target_account"
        );
      }
      return null;
    },

    transferredItems() {
      return this.itemsNotInTarget;
    },

    transferredItemsTooltip() {
      if (this.mode === "target") {
        return this.$t(
          "components.user.merge_requests.merge_request_user.will_be_added_to_this_account"
        );
      } else if (this.mode === "source") {
        return this.$t(
          "components.user.merge_requests.merge_request_user.will_be_transferred_to_target_account"
        );
      }
      return null;
    },
  },
};
</script>
