<template>
  <div ref="root">
    <slot
      :step="step"
      :key-name="keyName"
      :set-key-name="(event) => (keyName = event.target.value)"
      :u2f-support="u2fSupport"
      :otp="otp"
      :set-otp="(event) => (otp = event.target.value)"
      :loading="loading"
      :error="error"
      :set-step="setStep"
      :fill-otp="fillOTP"
      :min-name-length="MIN_NAME_LENGTH"
      :otp-length="OTP_LENGTH"
    />
  </div>
</template>

<script>
import { isSupported, register } from "u2f-api";

const ERROR_MAP = Object.freeze({
  1: "users.u2f.errors.unknown",
  2: "users.u2f.errors.bad_request",
  3: "users.u2f.errors.not_supported",
  4: "users.u2f.errors.already_registered",
  5: "users.u2f.errors.timeout",
});

export default {
  props: {
    appId: {
      type: String,
      required: true,
    },

    registrationRequests: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      MIN_NAME_LENGTH: 3,
      OTP_LENGTH: 44,

      step: 1,
      loading: false,
      error: null,

      u2fSupport: false,
      keyName: "",
      otp: "",
      response: "",
    };
  },

  mounted() {
    isSupported().then((supported) => {
      this.u2fSupport = supported;
    });

    this.$refs.root.querySelector("#yubikey-registration-name").focus();
  },

  methods: {
    setStep(stepNumber) {
      this.step = stepNumber;

      if (this.step == 2) {
        this.$nextTick(() =>
          this.$refs.root.querySelector("#yubikey-registration-otp").focus()
        );
      } else if (this.step == 3) {
        this.setupU2FTab();
      }
    },

    fillOTP() {
      if (!this.u2fSupport) {
        this.$refs.root
          .querySelector("#yubikey-registration-otp")
          .closest("form")
          .submit();
      }

      this.setStep(3);
      return false;
    },

    async setupU2FTab() {
      const registerResponse = await register({
        version: this.registrationRequests[0].version,
        appId: this.appId,
        challenge: this.registrationRequests[0].challenge,
      });

      if (registerResponse.errorCode) {
        this.error = ERROR_MAP[registerResponse.errorCode];
        return;
      }

      this.response = JSON.stringify(registerResponse);
      this.$refs.root.querySelector(
        "#yubikey-registration-u2f-response"
      ).value = this.response;
      this.$refs.root
        .querySelector("#yubikey-registration-u2f-response")
        .closest("form")
        .submit();
      this.loading = true;

      return false;
    },
  },
};
</script>
