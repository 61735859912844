<template>
  <div class="row">
    <!-- Seller  -->
    <div class="cl-12 col-lg-6">
      <label for="seller">{{ sellerLabel }}*</label>

      <be-form-group
        v-if="formattedAvailableSellers && formattedAvailableSellers.length > 0"
        :error="getErrors(transaction, ['seller_and_buyer'])"
      >
        <be-form-select
          id="seller"
          v-model="selectedSeller"
          name="shareholder_transaction[seller_id]"
          required
          :disabled="!canEditAllButShareType"
          :options="sellers"
          :state="validationState(transaction, ['seller_and_buyer'])"
          @change="getSharesAndClearErrors()"
        />
      </be-form-group>

      <div v-else class="small form-text text-muted">
        {{
          $t(
            "components.shareholder_registers.sale_transaction.no_available_sellers",
            {
              share_type: $t(
                `models.shareholder_transaction.share_types.${shareType}`
              ),
            }
          )
        }}
      </div>
    </div>

    <!-- Buyer  -->
    <div class="col-12 col-lg-6">
      <label for="buyer-type-group">{{ buyerLabel }}*</label>

      <div v-if="canEditAllButShareType">
        <be-form-group>
          <be-form-radio-group
            id="buyer-type-group"
            v-model="selectedBuyerType"
            :options="selectedBuyerTypeOptions"
            name="shareholder_transaction[buyer_status]"
            @change="$event === 'existing' ? setBuyer() : null"
          />
        </be-form-group>

        <div v-if="selectedBuyerType == 'new_shareholder'">
          <be-form-group>
            <be-form-radio-group
              id="buyer_type"
              v-model="buyerType"
              :options="buyerTypeOptions"
              name="shareholder_transaction[shareholder_buyer][shareholder_buyer_type]"
            />
          </be-form-group>

          <user-buyer-fields
            v-if="buyerType == 'user'"
            name-prefix="shareholder_transaction"
            :shareholder="buyer"
          />

          <company-buyer-fields
            v-if="buyerType == 'company'"
            name-prefix="shareholder_transaction"
            :shareholder="buyer"
          />
        </div>
      </div>

      <be-form-select
        v-if="selectedBuyerType == 'existing'"
        v-model="selectedBuyer"
        name="shareholder_transaction[buyer_id]"
        required
        :disabled="!canEditAllButShareType"
        :options="formattedShareholders"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShareholderRegisterMixins from "@/mixins/shareholderRegisters";

import CompanyBuyerFields from "../actions/types/CompanyBuyerFields.vue";
import UserBuyerFields from "../actions/types/UserBuyerFields.vue";

export default {
  components: {
    CompanyBuyerFields,
    UserBuyerFields,
  },

  mixins: [ShareholderRegisterMixins],

  props: {
    transaction: {
      type: Object,
      required: true,
    },

    actionType: {
      type: String,
      required: true,
    },

    shareType: {
      type: String,
      required: true,
    },

    canEditAllButShareType: {
      type: Boolean,
      required: true,
    },

    formattedAvailableSellers: {
      type: Array,
      required: true,
    },
  },

  emits: ["seller-changed", "shareholder-series", "update-errors"],

  data() {
    return {
      buyerType: this.transaction.buyer
        ? this.transaction.buyer.shareholder_buyer_type
          ? this.transaction.buyer.shareholder_buyer_type
          : "user"
        : "user",

      buyerTypeOptions: [
        { text: this.$t("models.shareholder.person"), value: "user" },
        { text: this.$t("models.shareholder.legal_person"), value: "company" },
      ],

      selectedBuyer: null,
      selectedSeller: null,

      selectedBuyerType: this.transaction.buyer_status
        ? this.transaction.buyer_status
        : "existing",

      selectedBuyerTypeOptions: [
        { text: this.$t("models.shareholder.current"), value: "existing" },
        { text: this.$t("models.shareholder.new"), value: "new_shareholder" },
      ],

      buyer: this.transaction.buyer ? this.transaction.buyer : undefined,
    };
  },

  computed: {
    ...mapGetters({
      shareholders: "shareholder_register/persistedShareholders",
    }),

    buyerLabel() {
      if (this.actionType == "sale") {
        return this.$t(
          "components.shareholder_registers.sale_transaction.choose_buyer"
        );
      } else {
        return this.$t(
          "components.shareholder_registers.sale_transaction.choose_new_owner"
        );
      }
    },

    buyerPersisted() {
      return !!this.transaction.buyer && !!this.transaction.buyer.id;
    },

    sameBuyer() {
      return this.selectedSeller == this.selectedBuyer;
    },

    sellerPersisted() {
      return !!this.transaction.seller && !!this.transaction.seller.id;
    },

    sellerLabel() {
      if (this.actionType == "sale") {
        return this.$t(
          "components.shareholder_registers.sale_transaction.choose_seller"
        );
      } else {
        return this.$t(
          "components.shareholder_registers.sale_transaction.choose_owner"
        );
      }
    },

    sellers() {
      return this.sellerPersisted
        ? this.formattedShareholders
        : this.formattedAvailableSellers;
    },

    formattedShareholders() {
      return this.shareholders.map((shareholder) => {
        return this.formatShareholder(shareholder);
      });
    },
  },

  watch: {
    sameBuyer(value) {
      if (value == true && this.selectedBuyerType == "existing") {
        this.updateTransactionErrors(
          "seller_and_buyer",
          this.$t(
            "models.shareholder_register.errors.seller_and_buyer_same_owner"
          )
        );
      } else {
        this.clearErrors(this.transaction, ["seller_and_buyer"]);
      }
    },
  },

  created() {
    this.$nextTick(() => {
      if (this.selectedSeller) {
        this.getSellerShares();
      }
    });

    this.selectedBuyer = this.buyerPersisted
      ? this.transaction.buyer.id
      : this.formattedShareholders[0].value;
    this.selectedSeller = this.sellerPersisted
      ? this.transaction.seller.id
      : this.sellers[0].value;

    if (this.sameBuyer && this.selectedBuyerType == "existing") {
      this.updateTransactionErrors(
        "seller_and_buyer",
        this.$t(
          "models.shareholder_register.errors.seller_and_buyer_same_owner"
        )
      );
    }
  },

  methods: {
    getSellerShares() {
      axios
        .get(this.url("/shareholder_register/shareholders/find_series"), {
          params: { id: this.selectedSeller },
        })
        .then((response) => {
          this.$emit("shareholder-series", response.data);
        })
        .catch(() => {});
    },

    setBuyer() {
      this.selectedBuyer = this.buyerPersisted
        ? this.transaction.buyer.id
        : this.formattedShareholders[0].value;
    },

    getSharesAndClearErrors() {
      this.getSellerShares();
      this.clearErrors(this.transaction, ["seller_and_buyer"]);
      this.$emit("seller-changed", this.selectedSeller);
    },

    updateTransactionErrors(key, value) {
      this.$emit("update-errors", key, value);
    },
  },
};
</script>
