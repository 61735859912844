<template>
  <be-modal
    :title="$t('components.annual_reports.ixbrl_signature_modal.title')"
    size="lg"
    :ok-disabled="saving"
    @ok="submitManualSignatures"
  >
    <be-form-checkbox id="use-signed-at" v-model="externalESign">
      {{
        $t(
          "components.annual_reports.ixbrl_signature_modal.use_external_e_sign"
        )
      }}
    </be-form-checkbox>

    <be-table
      :items="signatureData"
      :fields="userFields"
      class="mb-3"
      hide-pagination
      hide-filters
      @row-clicked="(_, index) => toggleUserSelect(index)"
    >
      <template #selected="{ index, item }">
        <be-form-checkbox
          :id="`signature-${index}`"
          :checked="item.selected === true"
          @change="toggleUserSelect(index)"
        />
      </template>

      <template #avatar="{ item }">
        <user-avatar :user="item.user || item.name" />
      </template>

      <template #auditor="{ index, item }">
        <be-form-checkbox
          :id="`auditor-${index}`"
          :checked="item.auditor === true"
          @change="toggleAuditor(index)"
        />
      </template>

      <template #name="{ index, item }">
        <template v-if="item.user">
          <div>
            {{ item.user.name }}
          </div>

          <membership-subtitle :user-id="item.user.id" class="small" />
        </template>

        <template v-else>
          <be-form-group
            :label="translateAttribute('user', 'name')"
            :label-for="`custom-name-${item.id}`"
            class="mb-0"
            label-sr-only
          >
            <be-form-input
              :id="`custom-name-${item.id}`"
              v-model="item.name"
              @change="handleNameChange(index, $event)"
              @keydown.space.stop
            />
          </be-form-group>
        </template>
      </template>

      <template #title="{ index, item }">
        <be-form-group
          :label="translateAttribute('membership', 'role')"
          :label-for="`title-${index}`"
          class="mb-0"
          label-sr-only
        >
          <be-form-input
            :id="`title-${index}`"
            v-model="item.title"
            @change="handleTitleChange(index, $event)"
            @keydown.space.stop
          />
        </be-form-group>
      </template>

      <template #signed_at="{ index, item }">
        <be-form-group
          :label="translateAttribute('signature', 'signed_at')"
          :label-for="`title-${index}`"
          class="mb-0"
          label-sr-only
        >
          <be-form-datepicker
            :id="`signed-at-${index}`"
            v-model="item.signed_at"
            @change="handleSignedAtChange(index, $event)"
            @keydown.space.stop
          />
        </be-form-group>
      </template>
    </be-table>
  </be-modal>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import AnnualReportTabBase from "./mixins/AnnualReportTabBase.vue";
import MembershipSubtitle from "../shared/MembershipSubtitle.vue";

const { mapGetters: mapCompanyGetters } = createNamespacedHelpers("company");

export default {
  components: {
    MembershipSubtitle,
  },

  mixins: [AnnualReportTabBase],

  data() {
    return {
      saving: false,
      externalESign: false,
      signatureData: [],
    };
  },

  computed: {
    ...mapCompanyGetters(["memberships"]),

    userFields() {
      return [
        { key: "selected", label: "", class: "col-shrink" },
        { key: "avatar", label: "", class: "col-shrink" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "title",
          label: this.translateAttribute("membership", "title"),
        },
        {
          key: "auditor",
          label: this.$t("memberships.roles.auditor"),
          class: "text-center",
        },
        !this.externalESign
          ? {
              key: "signed_at",
              label: this.translateAttribute("signature", "signed_at"),
            }
          : null,
      ].filter(Boolean);
    },
  },

  watch: {
    signatureData: {
      handler() {
        const lastItem = this.signatureData[this.signatureData.length - 1];
        if (lastItem.name) {
          this.signatureData.push({
            name: "",
            title: "",
            selected: false,
          });
        }
      },

      deep: true,
    },
  },

  mounted() {
    const previousSignatures =
      this.annualReport.ixbrl_signatures?.annual_report_ixbrl?.signatures;

    if (previousSignatures) {
      this.signatureData = previousSignatures.map((data) => ({
        ...data,
        user: this.companyUsers.find((user) => user.id === data.user_id),
        selected: true,
      }));
    }

    this.memberships.forEach(({ user, role }) => {
      if (
        !this.signatureData.some(
          (data) => data.user && data.user.id === user.id
        )
      ) {
        this.signatureData.push({
          user: user,
          title: this.defaultTitles[user.id],
          selected: !previousSignatures,
          auditor: role === "auditor",
        });
      }
    });

    this.signatureData.push({
      name: "",
      title: "",
      selected: false,
    });
  },

  methods: {
    toggleUserSelect(index) {
      this.signatureData[index].selected = !this.signatureData[index].selected;
      if (!this.signatureData[index].selected) {
        this.signatureData[index].signed_at = null;
      }
    },

    toggleAuditor(index) {
      this.signatureData[index].auditor = !this.signatureData[index].auditor;
    },

    handleTitleChange(index, value) {
      this.signatureData[index].title = value;
    },

    handleSignedAtChange(index, value) {
      this.signatureData[index].signed_at = value;
      if (value) {
        this.signatureData[index].selected = true;
      }
    },

    handleNameChange(index, value) {
      this.signatureData[index].name = value;
      this.signatureData[index].selected = true;
    },

    async submitManualSignatures() {
      try {
        const { data } = await axios.post(
          this.annualReport.paths.ixbrl_signatures,
          {
            signature_data: {
              document_id: this.annualReportIxbrlDocument.id,

              external_e_sign: this.externalESign,

              ixbrl_signatures: this.signatureData
                .filter(
                  ({ selected, name, user }) => selected && (user || name)
                )
                .map(({ user, name, auditor, title, signed_at }) => ({
                  user_id: user ? user.id : null,
                  name: user ? user.name : name,
                  title: title,
                  auditor: auditor,
                  signed_at: this.externalESign ? null : signed_at,
                })),
            },
          }
        );

        this.setAnnualReport(data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
