<template>
  <tr>
    <td>
      <input
        v-if="localCompliance.id"
        type="hidden"
        :name="complianceFieldName('id')"
        :value="localCompliance.id"
      />
    </td>

    <td>
      <be-form-checkbox
        v-model="localChecked"
        :name="complianceFieldName('checked')"
      />
    </td>

    <td>
      <be-form-input
        v-model="localCompliance.title"
        v-autofocus
        :name="complianceFieldName('title')"
      />
    </td>

    <td>
      <be-form-select
        v-model="localCompliance.role"
        :name="complianceFieldName('role')"
        :options="roleOptions"
      />
    </td>

    <td class="col-shrink">
      <be-button
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="$emit('remove-compliance', compliance)"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    compliance: {
      type: Object,
      required: true,
    },

    roleOptions: {
      type: Array,
      required: true,
    },
  },

  emits: ["remove-compliance", "update:checked"],

  data() {
    return {
      localCompliance: this.cloneDeep(this.compliance),
    };
  },

  computed: {
    localChecked: {
      get() {
        return this.compliance.checked;
      },

      set(newValue) {
        this.$emit("update:checked", newValue);
      },
    },
  },

  methods: {
    tc(key) {
      return this.$t(`documents.compliance.configurations.${key}`);
    },

    complianceFieldName(field) {
      return `company[compliance_attributes][${this.compliance.index}][${field}]`;
    },
  },
};
</script>
