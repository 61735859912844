function isMissingTransactions(action, transactions) {
  if (transactions.length === 0) {
    // Bonus issues without a split ratio are allowed to have no transactions
    if (action.action_type === "bonus_issue" && !action.split_ratio_first) {
      return false;
    }

    return [
      "standard_split",
      "sale",
      "equity_issuance",
      "company_formation",
      "share_transfer",
      "bonus_issue",
      "upload",
    ].includes(action.action_type);
  }

  return false;
}

export { isMissingTransactions };
