<template>
  <div>
    <evaluation-row
      v-for="evaluation in evaluations"
      :key="evaluation.id"
      :initial-evaluation-form="evaluation"
    />
  </div>
</template>

<script>
import EvaluationRow from "./EvaluationRow.vue";

export default {
  components: {
    EvaluationRow,
  },

  props: {
    evaluations: {
      type: Array,
      required: true,
    },
  },
};
</script>
