<template>
  <div>
    <template v-if="tasks && tasks.length > 0">
      <header v-if="selectedProject == null" class="mb-4">
        <h1>{{ $t("activerecord.models.task.other") }}</h1>
      </header>

      <be-tabs v-model="activeTab" :options="tabsData">
        <template #content-right>
          <be-button
            v-if="canCreateTask"
            v-be-modal.new-task
            variant="primary"
            class="text-nowrap"
          >
            {{ $t("models.task.new") }}
          </be-button>

          <template v-if="canExportTasks">
            <be-dropdown ellipsis>
              <be-dropdown-item v-be-modal.export-tasks>
                {{ exportModalTitle }}
              </be-dropdown-item>
            </be-dropdown>

            <export-modal-with-filters
              id="export-tasks"
              :export-types="['tasks_pdf', 'tasks_excel']"
              :filter-types="[
                'tasks',
                'tasks_my_active',
                'tasks_active',
                'tasks_done',
                'tasks_archived',
              ]"
              :title="exportModalTitle"
              :preselected-options="exportPreselectedOptions"
              :export-params="exportParams"
            >
              <template #filters>
                <be-form-group
                  :label="$t('components.tasks.export_filter_due_at')"
                  label-for="dp-input-export-filter-start"
                >
                  <be-input-group>
                    <be-form-datepicker
                      id="export-filter-start"
                      v-model="exportParams.due_at_start"
                      :max-date="exportMaxFilter"
                      inline
                    />

                    <be-input-group-separator is-text>
                      -
                    </be-input-group-separator>

                    <be-form-datepicker
                      id="export-filter-end"
                      v-model="exportParams.due_at_end"
                      :min-date="exportMinFilter"
                      inline
                    />
                  </be-input-group>
                </be-form-group>

                <be-form-group
                  :label="$t('components.shared.tasks_search.source')"
                  label-for="export-filter-source"
                >
                  <be-form-select
                    id="export-filter-source"
                    v-model="exportParams.source"
                    :options="[
                      {
                        text: $t('models.task.sources.programmatic'),
                        value: 'programmatic',
                      },
                      {
                        text: $t('models.task.sources.manual'),
                        value: 'manual',
                      },
                    ]"
                    :include-blank-option="$t('models.task.sources.all')"
                  />
                </be-form-group>
              </template>
            </export-modal-with-filters>
          </template>
        </template>
      </be-tabs>

      <div class="card">
        <div class="card-body">
          <be-table
            key="tasks-table"
            :fields="columns"
            :items="computedTasks"
            :filters="[
              {
                field: 'due_at',
                label: $t('components.tasks.filter_due_at'),
                type: 'date-range',
              },
              {
                field: 'source',
                label: $t('components.shared.tasks_search.source'),
                type: 'select',
                options: [
                  {
                    text: $t('models.task.sources.programmatic'),
                    value: 'programmatic',
                  },
                  {
                    text: $t('models.task.sources.manual'),
                    value: 'manual',
                  },
                ],
              },
            ]"
            :tbody-tr-class="rowClasses"
            sort-by="due_at"
            @filter-changed="onTableFilterChange"
          >
            <template #description="{ item }">
              <a
                v-dompurify-html="
                  item.description || $t('models.task.description_missing')
                "
                :href="url(`tasks/${item.id}`)"
                :class="{
                  'font-italic': !item.description,
                }"
              />

              <div v-if="item.meeting_data" class="small">
                <template v-if="item.meeting_data.item_number">
                  {{ $t("components.tasks.from_meeting_item") }}:
                  {{ item.meeting_data.item_number }},
                </template>

                <a
                  v-if="userPolicyLevel !== 'limited'"
                  :href="item.meeting_data.path"
                >
                  {{
                    $t("models.meeting.title_and_number", {
                      title: item.meeting_data.meeting_title,
                      number: item.meeting_data.meeting_number,
                    })
                  }}
                </a>

                <span v-else>
                  {{
                    $t("models.meeting.title_and_number", {
                      title: item.meeting_data.meeting_title,
                      number: item.meeting_data.meeting_number,
                    })
                  }}
                </span>

                <i
                  v-if="!item.meeting_data.review_complete"
                  v-be-tooltip="$t('models.material.minutes.not_reviewed')"
                  class="fa-duotone fa-triangle-exclamation ml-1 text-warning"
                />
              </div>
            </template>

            <template #description_text="{ item }">
              <span v-dompurify-html="item.description" />

              <div v-if="item.meeting_data" class="small">
                {{ $t("components.tasks.from_meeting_item") }}:
                {{
                  $t("models.meeting.title_and_number", {
                    title: item.meeting_data.meeting_title,
                    number: item.meeting_data.meeting_number,
                  })
                }}
              </div>
            </template>

            <template #go_to_task="{ item }">
              <be-button
                v-if="showGoToButton(item)"
                v-be-tooltip="goToTooltip(item)"
                :href="item.link"
                :disabled="goToTaskDisabled(item)"
                variant="outline-secondary"
                size="sm"
                icon="fa-location-arrow"
                inline
              />
            </template>

            <template #toggle_done="{ item }">
              <be-button
                v-if="isTaskRestorable(item)"
                variant="outline-secondary"
                size="sm"
                :loading="item.loading"
                inline
                @click="restoreTask(item)"
              >
                {{ $t("buttons.titles.restore") }}
              </be-button>

              <be-button
                v-else-if="canMarkTaskAsDone(item)"
                variant="primary"
                :disabled="item.loading"
                size="sm"
                :loading="item.loading"
                inline
                @click="markTaskAsDone(item)"
              >
                {{ $t("buttons.titles.done") }}
              </be-button>

              <be-button
                v-else-if="isTaskRevertable(item)"
                variant="outline-secondary"
                :disabled="item.loading"
                size="sm"
                :loading="item.loading"
                inline
                @click="markTaskAsNotDone(item)"
              >
                {{ $t("models.task.revert_done") }}
              </be-button>

              <i
                v-if="!item.user_completable && !item.archived_at"
                v-be-tooltip="$t('tasks.task.disabled_by_user_completable')"
                class="text-muted fal fa-magic"
              />
            </template>

            <template #done_at="{ item }">
              <template v-if="!!item.done_at">
                {{ $d(new Date(item.done_at), "dateTime") }}
              </template>
            </template>

            <template #due_at="{ item }">
              <template v-if="!!item.due_at">
                {{ $d(new Date(item.due_at), "date") }}
              </template>
            </template>

            <template #assigned="{ item }">
              <user-avatar v-if="item.user_id" :user="item.user_id" />
            </template>

            <template #actions="{ item }">
              <be-dropdown v-if="isManageable(item)" size="sm" right ellipsis>
                <be-dropdown-item
                  v-if="isTaskEditable(item)"
                  v-be-modal="`edit-task-${item.id}-${activeTab}`"
                >
                  {{ $t("buttons.titles.edit") }}
                </be-dropdown-item>

                <be-dropdown-item
                  v-if="isTaskArchivable(item)"
                  @click="archiveTask(item)"
                >
                  {{ $t("buttons.titles.archive") }}
                </be-dropdown-item>

                <be-dropdown-divider
                  v-if="
                    (isTaskEditable(item) || isTaskArchivable(item)) &&
                    isTaskDestroyable(item)
                  "
                />

                <be-dropdown-item
                  v-if="isTaskDestroyable(item)"
                  variant="danger"
                  @click="destroy(item)"
                >
                  {{ $t("buttons.titles.remove") }}
                </be-dropdown-item>
              </be-dropdown>

              <task-form
                v-if="isTaskEditable(item)"
                :task="item"
                :modal-id="`edit-task-${item.id}-${activeTab}`"
                :projects="availableProjects"
                :selected-project="selectedProject"
              />
            </template>
          </be-table>
        </div>
      </div>
    </template>

    <task-introduction v-else :allowed-to-create="newTask.policy.create" />

    <task-form
      :task="newTask"
      :projects="availableProjects"
      :selected-project="selectedProject"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import TaskForm from "./TaskForm.vue";
import TaskIntroduction from "./TaskIntroduction.vue";

import TaskMixin from "@/mixins/tasks";

export default {
  components: {
    TaskForm,
    TaskIntroduction,
  },

  mixins: [TaskMixin],

  props: {
    initialTasks: {
      type: Array,
      required: true,
    },

    newTask: {
      type: Object,
      required: true,
    },

    projects: {
      type: Array,
      required: false,
      default: () => [],
    },

    selectedProject: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activeTab: this.selectedProject === null ? "my-tasks" : "all-tasks",

      exportParams: {
        due_at_end: null,
        due_at_start: null,
        source: null,
      },
    };
  },

  computed: {
    ...mapGetters("tasks", {
      tasks: "getTasks",
      filterTasks: "filterTasks",
    }),

    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
    }),

    tabsData() {
      return {
        ...(this.selectedProject === null
          ? {
              "my-tasks": {
                value: "my-tasks",
                label: this.$t("companies.tasks.index.my_active_tasks"),
                count: this.myTasks.length,
              },
            }
          : null),

        "all-tasks": {
          value: "all-tasks",
          label: this.$t("companies.tasks.index.all_active_tasks"),
          count: this.activeTasks.length,
        },

        "completed-tasks": {
          value: "completed-tasks",
          label: this.$t("companies.tasks.index.done_tasks"),
          count: this.completedTasks.length,
        },

        "archived-tasks": {
          value: "archived-tasks",
          label: this.$t("companies.tasks.index.archived_tasks"),
          count: this.archivedTasks.length,
        },
      };
    },

    myTasks() {
      return this.getFilteredTasks({
        userId: this.$currentUser.id,
        doneAt: false,
        archivedAt: false,
      });
    },

    activeTasks() {
      return this.getFilteredTasks({ doneAt: false, archivedAt: false });
    },

    completedTasks() {
      return this.getFilteredTasks({ doneAt: true, archivedAt: false });
    },

    archivedTasks() {
      return this.getFilteredTasks({ archivedAt: true });
    },

    computedTasks() {
      if (this.activeTab == "my-tasks") {
        return this.myTasks;
      }

      if (this.activeTab == "all-tasks") {
        return this.activeTasks;
      }

      if (this.activeTab == "completed-tasks") {
        return this.completedTasks;
      }

      if (this.activeTab == "archived-tasks") {
        return this.archivedTasks;
      }

      return [];
    },

    columns() {
      let columns = [
        {
          key: "description",
          label: this.$t("tasks.task_label"),
          class: "d-print-none",
        },
        {
          key: "description_text",
          label: this.$t("tasks.task_label"),
          class: "d-none d-print-table-cell",
        },
        {
          key: "go_to_task",
          label: "",
          class: "d-none d-md-table-cell d-print-none col-shrink text-center",
        },
        {
          key: "toggle_done",
          label: "",
          class: "col-shrink text-center align-middle d-print-none",
        },
        {
          key: "done_at",
          label: this.$t("activerecord.attributes.task.done_at"),
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
          sortable: true,
        },
        {
          key: "due_at",
          label: this.$t("activerecord.attributes.task.due_at"),
          class: "d-none d-md-table-cell d-print-table-cell col-shrink",
          sortable: true,
        },
        {
          key: "assigned",
          label: this.$t("activerecord.attributes.task.assigned"),
          class: "d-none d-md-table-cell col-shrink text-center d-print-none",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.user_name,
        },
        {
          key: "assigned_text",
          label: this.$t("activerecord.attributes.task.assigned"),
          class: "d-none d-print-table-cell col-shrink",
          sortable: true,
          sortByFormatted: true,
          formatter: (value, key, item) => item.user_name,
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink d-print-none",
        },
        {
          key: "source",
          label: "",
          class: "col-shrink d-none",
        },
      ];

      // Should only show done at in completed and archived tasks tabs
      // Should not show go to task in archived tasks tab
      return columns.filter((column) => {
        const hideDoneAt =
          column.key == "done_at" &&
          !["completed-tasks", "archived-tasks"].includes(this.activeTab);

        const hideGoToTask =
          "go_to_task" == column.key && this.activeTab == "archived-tasks";

        if (hideDoneAt || hideGoToTask) {
          return false;
        }

        return true;
      });
    },

    exportMaxFilter() {
      return this.exportParams.due_at_end
        ? new Date(this.exportParams.due_at_end)
        : null;
    },

    exportMinFilter() {
      return this.exportParams.due_at_start
        ? new Date(this.exportParams.due_at_start)
        : null;
    },

    availableProjects() {
      if (this.projects.length == 0 && this.selectedProject) {
        return [this.selectedProject];
      } else {
        return this.projects;
      }
    },

    canCreateTask() {
      return this.newTask.policy?.create;
    },

    canExportTasks() {
      return this.platformAdmin && this.selectedProject == null;
    },

    userPolicyLevel() {
      const membership = this.$currentCompany.memberships.find(
        (membership) => membership.user.id === this.$currentUser.id
      );
      return membership?.policy_level;
    },

    exportPreselectedOptions() {
      return {
        exportType: "tasks_pdf",
        filterType: this.getFilterTypeFromTab(),
      };
    },

    exportModalTitle() {
      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("activerecord.models.task.other").toLowerCase(),
      });
    },
  },

  mounted() {
    this.setTasks(this.initialTasks);
    this.updateMyTasks();
  },

  methods: {
    ...mapActions("tasks", {
      destroyTask: "destroy",
      updateMyTasks: "updateMyTasks",
    }),

    ...mapMutations("tasks", ["setTasks"]),

    changeTab(tab) {
      this.activeTab = tab;
    },

    async destroy(task) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: task.description,
        })
      );

      if (isConfirmed) {
        this.destroyTask(task);
      }
    },

    isManageable(task) {
      return (
        this.isTaskEditable(task) ||
        this.isTaskArchivable(task) ||
        this.isTaskDestroyable(task)
      );
    },

    isRestorable(task) {
      return task?.policy?.edit && !!task.archived_at;
    },

    rowClasses(task) {
      if (task && task.overdue) {
        return "table-danger";
      }
    },

    showGoToButton(task) {
      return (
        task.owner_type != null &&
        task.link &&
        this.activeTab != "completed-tasks"
      );
    },

    goToTaskDisabled(task) {
      const requiredFeature = this.taskRequiredFeature(task);
      return requiredFeature && !this.hasActiveFeature(requiredFeature);
    },

    goToTooltip(task) {
      if (this.goToTaskDisabled(task)) {
        const feature = this.taskRequiredFeature(task);
        return this.featureRequiredTitle(feature);
      } else {
        return this.$t("components.tasks.go_to_w_owner", {
          owner: this.taskOwnerTitle(task),
        });
      }
    },

    getFilteredTasks(options = null) {
      if (options == null) {
        options = {};
      }

      if (this.selectedProject) {
        options.projectId = this.selectedProject.id;
      }

      if (this.$currentCompany) {
        options.companyId = this.$currentCompany.id;
      }

      return this.filterTasks(options);
    },

    getFilterTypeFromTab() {
      switch (this.activeTab) {
        case "my-tasks":
          return "tasks_my_active";
        case "completed-tasks":
          return "tasks_done";
        case "archived-tasks":
          return "tasks_archived";
        case "all-tasks":
          return "tasks_active";
        default:
          return "tasks";
      }
    },

    onTableFilterChange(activeFilters) {
      activeFilters.forEach((activeFilter) => {
        const { filter, value } = activeFilter;

        if (filter.field === "due_at") {
          this.exportParams.due_at_end = value.end;
          this.exportParams.due_at_start = value.start;
        }

        if (filter.field === "source") {
          this.exportParams.source = value;
        }
      });
    },
  },
};
</script>
