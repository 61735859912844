<template>
  <div class="alert alert-warning">
    <p class="mb-0">
      <i class="fa-duotone fa-triangle-exclamation mr-2"></i>

      <strong>
        {{ $t("companies.meetings.messages.continuous_numbering_check") }}
      </strong>
    </p>

    <ul class="mb-0">
      <li v-for="message in messages" :key="`message-${message.id}`">
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
};
</script>
