<template>
  <div v-if="meeting" class="card mb-4">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-12 col-md mb-3 mb-md-0">
          <h5 class="card-title mb-1">
            <a
              :href="meeting.paths.base"
              class="text-decoration-none text-reset"
            >
              {{ meetingDisplayTitle }}
            </a>

            <span
              v-if="meeting.meeting_type === 'historic'"
              class="small text-muted"
            >
              {{ $t("models.meeting.historic_meeting.one") }}
            </span>
          </h5>

          <div class="d-flex flex-row flex-wrap">
            <template v-if="!isSameDay(meeting.start_at, meeting.end_at)">
              <div v-if="meeting.meeting_type != 'historic'" class="mr-3">
                <i class="fal fa-calendar-clock text-primary mr-1 small" />

                <small class="text-muted">
                  {{ $d(new Date(meeting.start_at), "date") }}
                  {{ $d(new Date(meeting.start_at), "time") }}
                  -
                  {{ $d(new Date(meeting.end_at), "date") }}
                  {{ $d(new Date(meeting.end_at), "time") }}
                </small>
              </div>

              <div v-else>
                <i class="fal fa-calendar text-primary mr-1 small" />

                <small class="text-muted">
                  {{ $d(new Date(meeting.start_at), "date") }} -
                  {{ $d(new Date(meeting.end_at), "date") }}
                </small>
              </div>
            </template>

            <template v-else-if="meeting.start_at">
              <div class="mr-3">
                <i class="fal fa-calendar text-primary mr-1 small" />

                <small class="text-muted">
                  {{ $d(new Date(meeting.start_at), "date") }}
                </small>
              </div>

              <div v-if="meeting.meeting_type !== 'historic'" class="mr-3">
                <i class="fal fa-clock text-primary mr-1 small" />

                <small class="text-muted">
                  {{ $d(new Date(meeting.start_at), "time") }}
                  -
                  {{ $d(new Date(meeting.end_at), "time") }}
                </small>
              </div>
            </template>

            <div
              v-if="
                flipperFlag('meeting_series_flipper') &&
                $platform.features.meeting_series
              "
            >
              <i class="fal fa-screen-users text-primary mr-1 small" />

              <small class="text-muted">
                {{ translatedMeetingSeriesTitle }}
              </small>
            </div>
          </div>
        </div>

        <div class="col col-md-auto d-flex flex-column flex-md-row gap-1">
          <be-button
            v-if="showMeetingOverview"
            :href="
              url(`/meetings/${meeting.id}/overview`, {
                company: company,
              })
            "
            variant="outline-primary"
          >
            {{ $t("views.companies.meetings.overviews.show.title") }}
          </be-button>

          <be-dropdown v-if="showMeetingDropdown" ellipsis>
            <be-dropdown-item v-if="!standalone" :href="meeting.paths.base">
              {{ $t("buttons.titles.show") }}
            </be-dropdown-item>

            <template v-if="meeting.policy.destroy">
              <be-dropdown-divider v-if="!standalone" />

              <be-dropdown-item
                variant="danger"
                @click="openRemoveMeetingModal"
              >
                {{ $t("buttons.titles.remove") }}
              </be-dropdown-item>
            </template>
          </be-dropdown>
        </div>
      </div>
    </div>

    <div class="card-body pb-0">
      <meeting-navigation
        v-if="meeting.meeting_type !== 'historic'"
        v-model:current-tab="currentTab"
        :states="STATES"
        :meeting-state="meeting.process.active_state"
      />
    </div>

    <div class="card-body">
      <component
        :is="meeting.meeting_type === 'historic' ? 'historic' : activeState"
        :meeting="meeting"
        :standalone="activeState === 'archived' ? standalone : null"
        @change-tab="changeTab"
      />
    </div>

    <confirm-modal
      :id="`confirm-remove-meeting-${meeting.id}`"
      :title="$t('components.meetings.delete_meeting_modal.remove_meeting')"
      :ok-title="$t('buttons.titles.remove')"
      ok-variant="danger"
      :valid-input-text="removalConfirmInputText"
      confirm-with-input
      stay-open-after-confirm
      @confirm="removeMeeting"
      @hidden="sendMeetingCancellationEmail = false"
    >
      <p
        v-dompurify-html="
          $t(
            'components.meetings.delete_meeting_modal.delete_meeting_w_title_html',
            { title: meetingDisplayTitle }
          )
        "
      />

      <p>
        {{
          $t(
            "components.meetings.delete_meeting_modal.delete_meeting_description"
          )
        }}
      </p>

      <template v-if="invitationsSent" #below-input>
        <be-form-group class="mb-0">
          <be-form-checkbox
            v-model="sendMeetingCancellationEmail"
            checked-value="1"
            unchecked-value="0"
          >
            {{
              $t(
                "components.meetings.delete_meeting_modal.notify_invited_meeting_cancelled"
              )
            }}
          </be-form-checkbox>
        </be-form-group>
      </template>
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Messages from "./components/MeetingMessages.vue";
import MeetingNavigation from "./components/MeetingNavigation.vue";

import TimeAndLocation from "./tabs/TimeAndLocationTab.vue";
import Agenda from "./tabs/AgendaTab.vue";
import Invitations from "./tabs/InvitationsTab.vue";
import Minutes from "./tabs/MinutesTab.vue";
import Review from "./tabs/ReviewTab.vue";
import Approve from "./tabs/ApproveTab.vue";
import Sign from "./tabs/SignTab.vue";
import Archived from "./tabs/ArchivedTab.vue";
import Historic from "./tabs/HistoricTab.vue";

import MaterialMixin from "@/mixins/meetings/material";
import RequestQueryMixin from "@/mixins/RequestQuery";
import { isSameDay } from "date-fns";

const STATES = [
  "time_and_location",
  "agenda",
  "invitations",
  "minutes",
  "review",
  "approve",
  "sign",
  "archived",
];

export default {
  components: {
    Messages,
    MeetingNavigation,

    // Activate all tabs
    TimeAndLocation,
    Agenda,
    Invitations,
    Minutes,
    Review,
    Approve,
    Sign,
    Archived,
    Historic,
  },

  mixins: [MaterialMixin, RequestQueryMixin],

  props: {
    initialMeeting: {
      type: Object,
      required: true,
    },

    standalone: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      STATES: STATES,
      sendMeetingCancellationEmail: false,
      currentTab: this.initialTab(),
    };
  },

  computed: {
    ...mapGetters({
      meetings: "meetings/getMeetings",
    }),

    translatedMeetingSeriesTitle() {
      return this.$t(
        `models.meeting.meeting_series_type.${this.meeting.meeting_series_type}.one`
      );
    },

    showMeetingOverview() {
      return (
        this.meeting.policy?.show_overview && this.getAgenda(this.meeting)?.id
      );
    },

    activeMeetings() {
      return this.meetings.filter((meeting) => !meeting.is_archived);
    },

    invitationsSent() {
      return (
        this.$store.getters["meetings/getInvitationsForMeeting"](
          this.initialMeeting.id
        ).filter(
          (invitation) => !!invitation.created_at && !invitation.deleted_at
        ).length > 0
      );
    },

    meeting: {
      get() {
        return this.$store.getters["meetings/getMeeting"](
          this.initialMeeting.id
        );
      },
    },

    meetingDisplayTitle() {
      return this.$t("companies.dashboard.header.meeting_header", {
        number: this.meeting.number,
        title: this.meeting.title,
      });
    },

    showMeetingDropdown() {
      return (
        (this.meeting.policy.show && !this.standalone) ||
        this.meeting.policy.destroy
      );
    },

    activeState() {
      return this.componentNameForState(this.currentTab);
    },

    removalConfirmInputText() {
      return `${this.$t("activerecord.models.meeting.one").toLowerCase()}-${
        this.meeting.number
      }`;
    },
  },

  watch: {
    currentTab() {
      if (this.standalone) {
        this.setQueryParam("tab", this.currentTab);
      }
    },
  },

  async created() {
    if (this.standalone) {
      this.setCurrentMeeting(this.initialMeeting);
    }
    this.$store.dispatch("meetings/fetchInvitationsForMeeting", this.meeting);
  },

  mounted() {
    if (this.meeting.policy?.show_overview) {
      this.fetchAgendaForMeeting(this.meeting);
    }
  },

  methods: {
    ...mapActions("meetings", ["updateMeeting", "setCurrentMeeting"]),

    changeTab(value) {
      this.currentTab = value;
    },

    initialTab() {
      const storedTab = this.getQueryParam("tab");
      if (this.standalone && this.validTab(storedTab)) {
        return storedTab;
      } else {
        if (this.standalone) {
          this.clearQueryParam("tab");
        }
        return this.initialMeeting.process.active_state;
      }
    },

    validTab(value) {
      const index = STATES.indexOf(value);

      if (index === -1) {
        return false;
      } else {
        const currentIndex = STATES.indexOf(
          this.initialMeeting.process.active_state
        );
        return index <= currentIndex;
      }
    },

    // Translates snake_case to PascalCase
    componentNameForState(state) {
      return state
        .split("_")
        .map((x) => x[0].toUpperCase() + x.substr(1))
        .join("");
    },

    openRemoveMeetingModal() {
      this.$beModal.show(`confirm-remove-meeting-${this.meeting.id}`);
    },

    async removeMeeting() {
      try {
        const redirectPath = this.meeting.paths.index;
        await this.$store.dispatch("meetings/removeMeeting", {
          meeting: this.meeting,
          sendMeetingCancellationEmail: this.sendMeetingCancellationEmail,
        });

        if (this.standalone) {
          window.location.href = redirectPath;
        } else if (this.activeMeetings.length === 0) {
          // Reload the page if there are no more meetings on the current page
          // to avoid showing an empty page. Pagy will redirect to the previous
          // page if there are no more meetings on the current page.
          window.location.reload();
        } else {
          this.updateCountBadge();
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    updateCountBadge() {
      const activeMeetingsCountBadge =
        this.$parent.$parent.$refs["active-meetings-count"];

      if (activeMeetingsCountBadge) {
        const currentCount = parseInt(activeMeetingsCountBadge.innerText);
        activeMeetingsCountBadge.innerText = currentCount - 1;
      }
    },

    setState(state) {
      this.activeState = this.componentNameForState(state);
    },

    isSameDay,
  },
};
</script>
