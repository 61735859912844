<template>
  <div>
    <header v-if="isNewThread" class="mb-4">
      <h1>
        {{ $t("models.message_thread.new") }}
      </h1>
    </header>

    <header v-else class="mb-3">
      <h1>{{ threadTitle }}</h1>

      <div v-if="!!latestMessage" class="text-muted">
        {{ formattedLastMessage }}
      </div>
    </header>

    <template v-if="!isNewThread">
      <be-tabs v-model="activeTab" class="d-print-none" :options="tabsData">
        <template #content-right>
          <be-button
            :href="url('/message_threads/new')"
            variant="outline-primary"
          >
            {{ $t("models.message_thread.new") }}
          </be-button>

          <template v-if="allowedToDeleteAndEdit">
            <be-dropdown id="thread-dropdown" ellipsis>
              <be-dropdown-item @click="openEditModal">
                {{ $t("models.message_thread.edit_title") }}
              </be-dropdown-item>

              <be-dropdown-divider />

              <be-dropdown-item
                :href="url(`/message_threads/${thread.id}`)"
                variant="danger"
                data-method="DELETE"
                :data-confirm="
                  $t('nav.confirm_delete_w_title', {
                    title: thread.title,
                  })
                "
              >
                {{ $t("buttons.titles.remove") }}
              </be-dropdown-item>

              <be-modal
                id="edit-message-thread"
                :title="$t('models.message_thread.edit_title')"
                :cancel-title="$t('buttons.titles.cancel')"
                :ok-title="$t('buttons.titles.save')"
                @ok="handleUpdateTitle"
              >
                <be-form-group
                  v-if="modalThread"
                  :label="translateAttribute('message_thread', 'title')"
                  label-for="message-thread-title"
                  :error="getErrors(modalThread, 'title')"
                >
                  <be-form-input
                    id="message-thread-title"
                    v-model="modalThread.title"
                    size="lg"
                    trim
                    required
                    @change="clearErrors(modalThread, 'title')"
                  />
                </be-form-group>
              </be-modal>
            </be-dropdown>
          </template>
        </template>
      </be-tabs>
    </template>

    <div v-show="activeTab == 'messages'">
      <div v-if="inviteAlert || noMessagesAlert" class="card mb-2">
        <div class="card-body">
          <be-alert :show="inviteAlert" variant="info">
            {{ $t("components.companies.message_threads.invite_users") }}
          </be-alert>

          <be-alert :show="noMessagesAlert" variant="warning">
            {{ $t("components.companies.message_threads.no_messages") }}
          </be-alert>
        </div>
      </div>

      <div class="card">
        <template v-if="!isNewThread">
          <div
            v-if="persistedMessages.length > 0"
            class="card-header d-flex justify-content-end"
          >
            <be-button variant="outline-secondary" @click="toggleAllMessages()">
              {{ toggleTitle }}
            </be-button>
          </div>

          <div
            v-for="message in persistedMessages"
            :key="`message-${message.id}`"
            class="card-body p-0 border-bottom"
          >
            <single-message
              :key="`message-${message.id}`"
              :initial-message="message"
              :new-message="newMessageIds.includes(message.id)"
              :read="isRead(message)"
              :persisted-user-invitations="invitedUserInvitations"
            />
          </div>
        </template>

        <div v-else class="card-body pb-0">
          <be-form-group
            :label="translateAttribute('message_thread', 'title')"
            label-for="message-thread-title"
            :error="getErrors(localThread, 'title')"
          >
            <be-form-input
              id="message-thread-title"
              v-model="localThread.title"
              size="lg"
              autofocus
              trim
              required
              @change="clearErrors(localThread, 'title')"
            />
          </be-form-group>
        </div>

        <new-message
          v-show="activeTab == 'messages' && allowedToAddMessage"
          :key="newMessageKey"
          :message="newMessage"
          :hide-buttons="isNewThread"
          :loading="loading"
          :new-thread="isNewThread"
          @submit="submitMessage"
          @message-updated="messageUpdated"
          @documents-updated="messageDocumentsUpdated"
          @uploading="(value) => (uploadingFiles = value)"
        />

        <div v-if="isNewThread" class="card-footer d-flex justify-content-end">
          <be-button
            variant="primary"
            :disabled="uploadingFiles || loading"
            :loading="loading"
            @click="submitNewThread"
          >
            {{ $t("models.message_thread.create") }}
          </be-button>
        </div>
      </div>
    </div>

    <div v-show="activeTab == 'participants'" class="card mb-2">
      <div class="card-body">
        <invitation
          v-model:checked-user-ids="checkedUserIds"
          :invite-policy="localThread.policy.invite"
          :uninvite-policy="localThread.policy.uninvite"
          :checkbox-invites="isNewThread"
          :invitations="invitations"
          :owners="invitationOwners"
          @invite="invite"
          @uninvite="uninvite"
          @memberships-updated="membershipsUpdated"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SingleMessage from "./message/SingleMessage.vue";
import NewMessage from "./message/NewMessage.vue";
import Invitation from "@/components/companies/UserInvitations.vue";
import { EventBus } from "@/event-bus";

const TABS = {
  messages: "messages",
  participants: "participants",
};

export default {
  components: {
    SingleMessage,
    NewMessage,
    Invitation,
  },

  props: {
    initialThread: {
      type: Object,
      required: true,
    },

    readings: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: "messages",
      inviteUsers: true,
      loading: true,
      localThread: this.cloneDeep(this.initialThread),
      newMessage: this.prepareNewMessage(),
      newMessageIds: [],
      newMessageKey: 0,
      showAll: false,
      showDocumentFields: false,
      showInviteAlert: false,
      uploadingFiles: false,
      checkedUserIds: [],
      modalThread: null,
    };
  },

  computed: {
    ...mapGetters({
      invitations: "message_threads/getInvitations",
      activeInvitations: "message_threads/getActiveInvitations",
      latestMessage: "message_threads/getLatestMessage",
      loadedInvitations: "message_threads/loadedInvitations",
      loadedMessages: "message_threads/loadedMessages",
      messages: "message_threads/getMessages",
      thread: "message_threads/getThread",
      companyUsers: "company/users",
    }),

    tabsData() {
      return {
        messages: {
          value: "messages",
          label: this.$t("components.companies.message_threads.tabs.messages"),
          count: this.persistedMessages.length,
        },

        participants: {
          value: "participants",

          label: this.$t(
            "components.companies.message_threads.tabs.participants"
          ),

          count: this.loadedInvitations
            ? this.invitedUserInvitations.length
            : null,
        },
      };
    },

    allowedToDeleteAndEdit() {
      if (!this.thread?.deleted_at) {
        return this.thread.policy.destroy && this.thread.policy.edit;
      }

      return false;
    },

    allowedToAddMessage() {
      if (this.isNewThread) {
        return true;
      }

      if (!this.thread?.deleted_at) {
        return this.thread.policy.create_message;
      }

      return false;
    },

    threadTitle() {
      return this.thread?.title;
    },

    formattedLastMessage() {
      if (!this.latestMessage) {
        return null;
      }

      const updatedAt = this.$i18n.d(
        new Date(this.latestMessage.updated_at),
        "dateTime"
      );

      return `${this.$t(
        "components.companies.message_threads.last_post"
      )}: ${updatedAt}`;
    },

    hasErrors() {
      return !!this.newMessage?.errors?.body;
    },

    invitedUserInvitations() {
      const userIds = this.companyUsers.map((user) => user.id);

      let invitations = this.activeInvitations.filter((invitation) =>
        userIds.includes(invitation.user_id)
      );

      return invitations;
    },

    isNewThread() {
      return this.localThread.id == null;
    },

    inviteAlert() {
      if (!this.loadedMessages || !this.loadedInvitations) {
        return false;
      }

      if (this.isNewThread) {
        return this.showInviteAlert;
      }

      return this.invitedUserInvitations.length < 2;
    },

    invitationOwners() {
      const user = this.getUser(this.thread.user_id);
      if (user) {
        return [user];
      }
      return [];
    },

    noMessagesAlert() {
      return (
        !this.loading &&
        this.invitedUserInvitations.length > 1 &&
        this.persistedMessages.length == 0
      );
    },

    persistedMessages() {
      return this.messages.filter((message) => !!message.id);
    },

    toggleTitle() {
      return this.showAll
        ? this.$i18n.t("buttons.toggle_all_selection.minimize_all")
        : this.$i18n.t("buttons.toggle_all_selection.expand_all");
    },

    usersToInvite() {
      return this.invitations.filter(
        (invitation) =>
          this.checkedUserIds.includes(invitation.user_id) &&
          (invitation.id == null || !!invitation.deleted_at)
      );
    },
  },

  watch: {
    thread(newValue) {
      this.localThread = newValue;
      this.loading = false;
    },

    activeTab(newValue) {
      let url = `${this.threadUrl()}?tab=${newValue}`;
      if (!this.isNewThread) {
        history.pushState({ tab: newValue }, null, url);
      }
    },
  },

  created() {
    this.$store.commit("message_threads/selectThread", this.initialThread);
    this.$store.commit(
      "message_threads/loadMessages",
      this.initialThread.messages
    );
    this.$store.dispatch("message_threads/fetchInvitations");
    this.inviteUsers = this.invitedUserInvitations.length <= 1;

    let tab = new URLSearchParams(window.location.search).get("tab");
    if (Object.keys(TABS).includes(tab)) {
      this.activeTab = tab;
    }
    this.loading = false;
  },

  methods: {
    toggleAllMessages() {
      this.showAll = !this.showAll;

      this.messages.map((message) => {
        this.$store.commit("message_threads/updateMessageAttributes", {
          message: message,
          data: { ["open"]: this.showAll },
        });
      });
    },

    toggleDocumentFields() {
      this.showDocumentFields = !this.showDocumentFields;
    },

    async submitMessage(message) {
      this.loading = true;

      let localMessage = message ? message : this.newMessage;

      try {
        let newMessage = await this.$store.dispatch(
          "message_threads/createMessage",
          {
            message: localMessage,
            documents: localMessage.documents,
          }
        );
        this.newMessage = this.prepareNewMessage();
        this.$store.commit("message_threads/loadThread", this.localThread);
        this.newMessageIds.push(newMessage.id);
        this.newMessageKey += 1;
        this.loading = false;
        return true;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.newMessage = error.response.data;
        } else {
          this.handleError(error);
        }

        this.loading = false;
        return false;
      }
    },

    invite(user) {
      this.$store.dispatch("message_threads/inviteUser", user);
    },

    uninvite(invitation) {
      this.$store.dispatch("message_threads/uninviteUser", invitation);
    },

    prepareNewMessage() {
      return {
        body: "",
        status: "regular",
        documents: [],
      };
    },

    async messageUpdated(message) {
      this.newMessage = message;
    },

    async messageDocumentsUpdated(documents) {
      this.newMessage.documents = documents;
    },

    async submitNewThread() {
      let status = false;
      this.loading = true;

      if (this.isNewThread) {
        status = await this.$store.dispatch("message_threads/createThread", {
          message_thread: this.localThread,
        });

        if (status == false) {
          this.loading = false;
          return;
        }
        history.pushState(null, null, this.threadUrl());
        this.updateBreadcrumbs(this.localThread);
      }

      if (this.newMessage.body !== "" || this.newMessage.documents.length > 0) {
        status = await this.submitMessage(this.newMessage);
        if (status == false) {
          this.loading = false;
          return;
        }
      }

      if (this.usersToInvite.length == 0 && !this.showInviteAlert) {
        this.showInviteAlert = true;
      }
      this.usersToInvite.forEach((invitation) => {
        this.$store.dispatch("message_threads/inviteUser", invitation.user_id);
      });
      this.$store.dispatch("message_threads/fetchInvitations", true);
      this.$store.dispatch("message_threads/fetchMessages", true);
      this.activeTab = "messages";
      this.loading = false;
    },

    handleUpdateTitle(beModalEvt) {
      // Prevent modal from closing
      beModalEvt.preventDefault();
      this.updateTitle();
    },

    async updateTitle() {
      try {
        let response = await axios.patch(
          this.url(`/message_threads/${this.thread.id}`),
          {
            message_thread: {
              title: this.modalThread.title,
            },
          }
        );

        this.$store.commit("message_threads/loadThread", response.data);
        this.$beModal.hide("edit-message-thread");
        this.updateBreadcrumbs(this.modalThread);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.modalThread = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    threadUrl() {
      return this.url(`/message_threads/${this.thread.id}`);
    },

    membershipsUpdated() {
      this.$store.dispatch("message_threads/fetchInvitations", {
        reload: true,
      });
    },

    isRead(message) {
      return this.readings.find(
        (reading) => reading.reference_id === message.id
      )?.read;
    },

    openEditModal() {
      this.modalThread = this.cloneDeep(this.localThread);
      this.$beModal.show("edit-message-thread");
    },

    updateBreadcrumbs(thread) {
      EventBus.emit("BREADCRUMBS_UPDATED", [
        {
          title: this.$t("application.messages"),
          link: this.url("/message_threads"),
        },
        {
          title: thread.title,
        },
      ]);
    },
  },
};
</script>
