<template>
  <be-modal
    id="price_variations"
    :title="$t('components.onboarding.price_variations')"
    ok-only
    ok-variant="light"
    :ok-title="$t('buttons.titles.close')"
  >
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              {{ $t("components.onboarding.feature") }}
            </th>

            <th class="text-right">
              {{ $t("components.onboarding.price") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(feature, index) in features" :key="index">
            <th>{{ feature.name }}</th>

            <td class="text-right">{{ feature.price }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </be-modal>
</template>

<script>
import { FEATURE_TABLE } from "@/config/pricing.js";
export default {
  data() {
    return {
      features: FEATURE_TABLE,
    };
  },
};
</script>
