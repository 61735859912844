<template>
  <div>
    <div v-if="shareholders.length > 0" class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>{{ $t("models.shareholder_transaction.owner") }}</th>

            <th class="d-none d-md-table-cell">
              {{ $t("activerecord.attributes.shareholder.email") }}
            </th>

            <th
              class="col-shrink"
              data="{default_order: 'desc', custom_sort: 'true'}"
            >
              {{ $t("activerecord.attributes.shareholder.share_count") }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="shareholder in shareholdersWithShares"
            :key="shareholder.id"
          >
            <td>
              <a
                v-if="platformAdmin"
                :href="
                  url(
                    `/shareholder_register/shareholders/${shareholder.id}/edit`
                  )
                "
                method="edit"
              >
                {{ ownerName(shareholder) }}
              </a>

              <template v-else>
                {{ ownerName(shareholder) }}
              </template>
            </td>

            <td class="d-none d-md-table-cell">{{ shareholder.email }}</td>

            <td class="text-right">{{ shareCount(shareholder) }}</td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td class="d-none d-md-table-cell"></td>

            <td></td>

            <td class="text-right">
              <span v-if="transactions.length > 0">
                {{ shareholderRegister.total_share_count }}
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>

    <be-alert v-else variant="info">
      {{
        $t(
          "components.shareholder_registers.shareholder_list.no_shareholders_present"
        )
      }}
    </be-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      shareholders: "shareholder_register/currentShareholders",
      transactions: "shareholder_register/transactions",
    }),

    shareholdersWithShares() {
      return this.shareholders.filter(
        (shareholder) => shareholder.share_count > 0
      );
    },
  },

  methods: {
    shareCount(shareholder) {
      return Math.round(shareholder.share_count).toString();
    },

    ownerName(shareholder) {
      return shareholder.company_name || shareholder.name;
    },
  },
};
</script>
