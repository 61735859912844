<script>
import { createNamespacedHelpers } from "vuex";
import { format } from "date-fns";

const {
  mapGetters: mapAnnualReportGetters,
  mapActions: mapAnnualReportActions,
  mapMutations: mapAnnualReportMutations,
} = createNamespacedHelpers("annual_reports");
const { mapGetters: mapCompanyGetters } = createNamespacedHelpers("company");

export default {
  inject: ["annualReportId"],

  computed: {
    ...mapAnnualReportGetters(["getAnnualReport"]),

    ...mapCompanyGetters({
      companyUsers: "users",
      hasActiveFeature: "hasActiveFeature",
    }),

    annualReport() {
      return this.getAnnualReport(this.annualReportId);
    },

    annualReportSortedSignatures() {
      if (this.annualReportSignatures) {
        return [...this.annualReportSignatures].sort((a, b) => {
          a.roleIndex = this.getRoleIndex(a);
          b.roleIndex = this.getRoleIndex(b);
          return `${a.roleIndex}-${this.userName(a)}` >
            `${b.roleIndex}-${this.userName(b)}`
            ? 1
            : -1;
        });
      }

      return [];
    },

    isPdfGenerating() {
      const pdfCallback = this.annualReport.job_callbacks.find(
        ({ key }) => key === "pdf_from_ixbrl"
      );
      return !pdfCallback || pdfCallback.status !== "completed";
    },

    companyCeos() {
      return this.$currentCompany.memberships.filter(
        (membership) => membership.role == "ceo"
      );
    },

    hasAuditorSignature() {
      return (
        this.annualReport.auditor_ids.length > 0 ||
        this.annualReport.ixbrl_signatures?.annual_report_ixbrl?.signatures?.some(
          ({ auditor }) => auditor
        )
      );
    },

    isFoundation() {
      return this.$currentCompany.type_of_organization == "foundation";
    },

    isDigitalDelivery() {
      return this.annualReport?.delivery_method == "digital";
    },

    currentUserIsAuditor() {
      return this.annualReport.auditor_ids.includes(this.$currentUser.id);
    },

    policy() {
      return this.annualReport.policy;
    },

    annualReportDocument() {
      return this.annualReport?.annual_report_document;
    },

    annualReportIxbrlDocument() {
      return this.annualReport?.annual_report_ixbrl_document;
    },

    annualReportSignedDocument() {
      return this.annualReport?.annual_report_signed_document;
    },

    boardStatementDocument() {
      return this.annualReport?.board_statement_document;
    },

    certificationDocument() {
      return this.annualReport?.certification_document;
    },

    auditReportDocument() {
      return this.annualReport?.audit_report_document;
    },

    auditReportIxbrlDocument() {
      if (this.annualReport?.shared_ixbrl_file) {
        return this.annualReportIxbrlDocument;
      }
      return this.annualReport?.audit_report_ixbrl_document;
    },

    auditReportSignedDocument() {
      return this.annualReport?.audit_report_signed_document;
    },

    annualReportWCertificationDocument() {
      return this.annualReport?.annual_report_w_certification_ixbrl_document;
    },

    meetingMinutesDocuments() {
      const minutesDocument = this.annualReport.annual_meeting_minutes_document;

      if (!minutesDocument) {
        return null;
      }

      return typeof minutesDocument == Array
        ? minutesDocument
        : [minutesDocument];
    },

    preparationDocuments() {
      return [
        this.annualReport.annual_report_document,
        this.annualReport.audit_report_document,
      ].filter((doc) => doc);
    },

    uploadedDocuments() {
      return [
        this.annualReport.annual_meeting_minutes_document,
        this.annualReport.annual_report_document,
        this.annualReport.annual_report_signed_document,
        this.annualReport.board_statement_document,
        this.annualReport.audit_report_document,
        this.annualReport.audit_report_signed_document,
        this.annualReport.certification_document,
        this.annualReport.signed_certification_document,
      ].filter((doc) => doc);
    },

    certificationUploaded() {
      const annualReport = this.annualReport;

      return (
        annualReport.no_separate_certificate ||
        !!annualReport.certification_document ||
        !!annualReport.annual_report_w_certification_ixbrl_document
      );
    },

    showNoAnnualReportChecksumWarning() {
      return (
        this.flipperFlag("digital_delivery") &&
        this.annualReportWCertificationDocument &&
        !this.annualReport.checksums.annual_report_ixbrl
      );
    },

    waitingForChecksum() {
      if (!this.annualReportWCertificationDocument) {
        return false;
      }
      const checksums = this.annualReport.checksums;
      return (
        checksums.annual_report_ixbrl &&
        !checksums.annual_report_w_certification_ixbrl
      );
    },

    checksumsMismatch() {
      if (!this.annualReportWCertificationDocument) {
        return false;
      }
      const checksums = this.annualReport.checksums;

      return (
        checksums.annual_report_ixbrl &&
        checksums.annual_report_w_certification_ixbrl !==
          checksums.annual_report_ixbrl
      );
    },

    // Get all non-nil ixbrl documents
    ixbrlDocuments() {
      return [
        this.annualReportWCertificationDocument ||
          this.annualReportIxbrlDocument,
        this.auditReportIxbrlDocument,
      ].filter((doc) => doc);
    },

    annualReportSignatures() {
      return this.annualReportDocument?.signatures || [];
    },

    auditReportSignatures() {
      return this.auditReportDocument?.signatures || [];
    },

    boardStatementSignatures() {
      return this.boardStatementDocument?.signatures || [];
    },

    stateTransitions() {
      return this.annualReport?.state_transitions;
    },

    auditorNeeded() {
      return this.annualReport?.auditor_needed;
    },

    // We use signature formats allowed for reports covering 2022 and later.
    ixbrlAllowed() {
      return !this.isFoundation && this.annualReport?.year >= 2022;
    },

    can() {
      return (event, namespace, policy) =>
        this.annualReport?.can[namespace][event] &&
        (policy ? this.policy[policy] : true);
    },

    canUploadDocument() {
      return (key) => {
        switch (key) {
          case "annual_meeting_minutes":
            return this.can("upload_minutes", "annual_meeting");
          case "certification":
            return this.can("upload_certification", "manual_delivery");
          case "signed_certification":
            return this.can("upload_signed_certification", "manual_delivery");
          case "annual_report_w_certification":
            return this.can("upload_certification", "digital_delivery");
          default:
            return this.can("upload", key);
        }
      };
    },

    canRemoveDocument() {
      return (document) => {
        if (!document) {
          return false;
        }
        if (!document.policy?.destroy) {
          return false;
        }
        switch (document.key) {
          case "annual_report":
          case "annual_report_ixbrl":
            return this.can("remove_upload", "annual_report");
          case "annual_report_signed":
            return this.can("remove_signed", "annual_report");
          case "board_statement":
            return this.can("remove_upload", "board_statement");
          case "audit_report":
          case "audit_report_ixbrl":
            return this.can("remove_upload", "audit_report");
          case "audit_report_signed":
            return this.can("remove_signed", "audit_report");
          case "annual_meeting_minutes":
            return this.can("remove_uploaded_minutes", "annual_meeting");
          case "certification":
            return this.can("remove_uploaded_certification", "manual_delivery");
          case "signed_certification":
            return this.can(
              "remove_uploaded_signed_certification",
              "manual_delivery"
            );
          case "annual_report_w_certification_ixbrl":
            return this.can(
              "remove_certification",
              this.isDigitalDelivery ? "digital_delivery" : "manual_delivery"
            );
          default:
            return false;
        }
      };
    },

    canCancelSignatures() {
      return (document) => {
        if (!document) {
          return false;
        }

        if (!document.policy?.destroy_signatures) {
          return false;
        }
        switch (document.key) {
          case "annual_report":
          case "annual_report_ixbrl":
            return this.can("reject_signature", "annual_report");
          case "board_statement":
            return this.can("reject_signature", "board_statement");
          case "audit_report":
          case "audit_report_ixbrl":
            return this.can("reject_signature", "audit_report");
          default:
            return false;
        }
      };
    },

    defaultTitles() {
      const entries = this.company.memberships.map((membership) => {
        const titles = [];
        // Not using translations, because this should always be in Swedish, and
        // use terms that are specific to Swedish conventions for annual reports.
        if (membership.role == "ceo") {
          titles.push("Verkställande Direktör");
        }
        if (membership.role == "auditor") {
          titles.push("Auktoriserad Revisor");
        }
        if (membership.function == "chairman") {
          titles.push("Styrelseordförande");
        }
        if (membership.function == "regular") {
          titles.push("Styrelseledamot");
        }
        if (membership.function == "alternate") {
          titles.push("Styrelsesuppleant");
        }
        return [membership.user.id, titles.join(", ")];
      });
      return Object.fromEntries(entries);
    },
  },

  methods: {
    ...mapAnnualReportActions([
      "addAnnualReport",
      "setAnnualReports",
      "fireAnnualReportEvent",
      "removeDocument",
      "signatureRequestReminder",
      "reloadAnnualReport",
      "updateAnnualReport",
    ]),

    ...mapAnnualReportMutations(["setAnnualReport"]),

    async confirmRemoveDocument(document) {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: document.filename,
        })
      );

      if (isConfirmed) {
        this.removeDocument({
          annualReport: this.annualReport,
          document,
        });
      }
    },

    async confirmTriggerEvent(event, namespace) {
      let confirmTextKey = event;

      if (
        event == "mark_as_received_by_authorities" &&
        namespace == "manual_delivery" &&
        !this.annualReport.signed_certification_document
      ) {
        confirmTextKey =
          "mark_as_received_by_authorities_without_certification";
      }

      if (
        event == "skip_audit" &&
        namespace == "phase" &&
        this.annualReport?.delivery_method == "digital" &&
        this.annualReport?.auditor_needed
      ) {
        confirmTextKey = "skip_audit_digital_delivery";
      }

      const isConfirmed = await this.promptConfirm({
        title: this.$t(
          `components.annual_reports.confirm_texts.${namespace}.${confirmTextKey}.title`
        ),

        description: this.$t(
          `components.annual_reports.confirm_texts.${namespace}.${confirmTextKey}.text`
        ),

        confirmButtonText: this.$t(
          `components.annual_reports.confirm_texts.${namespace}.${confirmTextKey}.button`
        ),
      });

      if (isConfirmed) {
        this.fireAnnualReportEvent({
          event,
          id: this.annualReport.id,
          namespace,
        });
      }
    },

    async afterSignaturesRequested() {
      this.signatureDocument = {};
      await this.reloadAnnualReport(this.annualReport.id);
    },

    transitionByEvent(event) {
      return this.stateTransitions.find((transition) => {
        return transition.event == event;
      });
    },

    findTransition(wantedTransitions) {
      const results = this.findTransitions(wantedTransitions);
      if (results.length) {
        return results[0];
      }
      return null;
    },

    findTransitions(wantedTransitions) {
      return this.stateTransitions.filter((candidate) =>
        wantedTransitions.some((wanted) =>
          Object.keys(wanted).every((key) => candidate[key] === wanted[key])
        )
      );
    },

    datesForTransitions(wantedTransitions) {
      return this.findTransitions(wantedTransitions)
        .map((t) => format(new Date(t.created_at), "yyyy-MM-dd"))
        .sort();
    },

    formatDate(date) {
      return format(new Date(date), "yyy-MM-dd");
    },

    getRoleIndex(signature) {
      let index = 0;

      if (this.annualReport.auditor_ids.includes(signature.user_id)) {
        index = 2;
      } else if (signature.user_id == this.annualReport.ceo_id) {
        index = 1;
      }

      return index;
    },

    // TODO: Add formatting and escaping
    userName(signature) {
      if (signature.user_name) {
        return signature.user_name;
      }

      const user = this.getUser(signature.user_id);
      if (user) {
        return user.name;
      }
      return "";
    },

    signedSignatures(signatures) {
      return signatures.filter((signature) => signature.signed_at);
    },

    signatureProgressText(signatures) {
      const signedSignatures = this.signedSignatures(signatures);
      return `(${signedSignatures.length}/${signatures.length})`;
    },

    filenameWithSize(document) {
      return `${document.filename} (${document.file_size})`;
    },
  },
};
</script>
