<template>
  <form
    ref="form"
    novalidate="novalidate"
    accept-charset="UTF-8"
    method="post"
    :action="action"
    @submit="loading = true"
  >
    <input name="utf8" type="hidden" value="✓" />

    <input type="hidden" name="authenticity_token" :value="$csrfToken" />

    <div class="row justify-content-center">
      <div class="col-12 col-lg-8">
        <header class="mb-4">
          <h1>{{ $t("views.companies.group.invitations.new.title") }}</h1>
        </header>

        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <company-form
                  :initial-invitation="initialInvitation"
                  :companies="companies"
                  :parent-company-title="parentCompany.title"
                  :in-client-manager="inClientManager"
                  @invitation-via-updated="invitationVia = $event"
                />

                <be-form-checkbox v-model="showOwnershipState" class="mt-3">
                  {{
                    $t(
                      "views.companies.group.invitation.include_ownership_distribution"
                    )
                  }}
                </be-form-checkbox>
              </div>
            </div>

            <template v-if="showOwnershipState">
              <hr />

              <h3 class="mb-3">
                {{
                  $t("views.companies.group.invitation.ownership_distribution")
                }}
              </h3>

              <ownership-distribution-form
                v-model="state"
                :errors="ownershipStateErrors"
                name-prefix="company_group_ownership[states_attributes][0]"
              />
            </template>
          </div>

          <div class="card-footer d-flex justify-content-end">
            <be-button
              variant="primary"
              type="submit"
              :disabled="companies.length == 0 && invitationVia !== 'email'"
              :loading="loading"
              :icon="invitationVia === 'email' ? 'fa-envelope' : null"
            >
              {{ $t("buttons.titles.send") }}
            </be-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import CompanyForm from "./CompanyForm.vue";
import OwnershipDistributionForm from "./parts/OwnershipDistributionForm.vue";

export default {
  components: { CompanyForm, OwnershipDistributionForm },

  props: {
    initialInvitation: {
      type: Object,
      required: true,
    },

    companies: {
      type: Array,
      required: true,
    },

    parentCompany: {
      type: Object,
      required: true,
    },

    inClientManager: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      showOwnershipState: false,
      ownershipStateErrors: {},
      invitationVia: this.initialInvitation.via || "membership",
      state: this.initialInvitation.states
        ? this.initialInvitation.states[0]
        : {
            start_at: null,
            end_at: null,
            shares: null,
            owned_shares: null,
            votes: null,
            owned_votes: null,
            ownership_type_mode: "auto",
            ownership_type: "daughter",
          },
      loading: false,
    };
  },

  computed: {
    action() {
      return this.inClientManager
        ? this.url("/companies/group/invitations")
        : this.url("/group/invitations");
    },
  },

  mounted() {
    let errors = this.initialInvitation.errors;

    if (Object.keys(errors).length > 0) {
      if (Object.keys(errors).some((key) => key.includes("states"))) {
        this.showOwnershipState = true;
      }

      this.ownershipStateErrors = Object.keys(errors).reduce((acc, key) => {
        if (key.includes("states.")) {
          acc[key.replace("states.", "")] = errors[key];
        }

        return acc;
      }, {});
    }
  },

  methods: {
    toggleOwnershipStateVisibility() {
      this.showOwnershipState = !this.showOwnershipState;
    },
  },
};
</script>
