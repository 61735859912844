<template>
  <nested-table :last-branch="lastBranch" :level="level">
    <template #header>
      <td>
        <template v-if="groupMember.actions && groupMember.actions.show">
          <be-link :href="groupMember.actions.show.url" @click.stop>
            {{ groupMember.actions.show.text }}
          </be-link>
        </template>

        <template v-else>{{ groupMember.title }}</template>
      </td>

      <td class="col-shrink d-none d-lg-table-cell">
        {{ groupMember.organization_number }}
      </td>

      <td class="col-shrink">
        <action-dropdown
          :group-member="groupMember"
          :actions="actions"
          :ownership="ownership"
          @click-remove-ownership="$emit('click-remove-ownership', $event)"
        />
      </td>
    </template>

    <template v-if="showShares" #body>
      <ownership-states-table
        :ownership="ownership"
        @click-add-state="onClickAddState"
        @click-edit-state="onClickEditState"
        @click-remove-state="onClickRemoveState"
      />
    </template>

    <template v-if="!hideChildren" #children>
      <tr
        v-for="child_ownership in groupMember.child_ownerships"
        :key="child_ownership.id"
      >
        <td v-if="!lastBranch" class="col-shrink p-0 branch" />

        <td colspan="4" class="p-0">
          <group-member
            :ownership="child_ownership"
            :group-member="child_ownership.child_company"
            :hide-children="treelevels === '1'"
            :level="level + 1"
            @click-remove-ownership="$emit('click-remove-ownership', $event)"
            @open-edit-state-modal="$emit('open-edit-state-modal', $event)"
            @confirm-remove-state="$emit('confirm-remove-state', $event)"
          />
        </td>
      </tr>

      <tr
        v-for="invitation in groupMember.child_invitations"
        :key="invitation.id"
      >
        <td v-if="!lastBranch" class="col-shrink p-0 branch" />

        <td colspan="4" class="p-0">
          <group-invitation
            :child="invitation.child_company"
            :invitation="invitation"
            :level="level + 1"
          />
        </td>
      </tr>
    </template>
  </nested-table>
</template>

<script>
import GroupInvitation from "./GroupInvitation.vue";
import NestedTable from "./NestedTable.vue";
import ActionDropdown from "./ActionDropdown.vue";
import RequestQueryMixin from "@/mixins/RequestQuery";
import OwnershipStatesTable from "./OwnershipStatesTable.vue";
import { isEmpty } from "lodash";

export default {
  name: "GroupMember",

  components: {
    OwnershipStatesTable,
    GroupInvitation,
    NestedTable,
    ActionDropdown,
  },

  mixins: [RequestQueryMixin],

  props: {
    groupMember: {
      type: Object,
      default: () => ({}),
      required: false,
    },

    ownership: {
      type: [Object, Boolean],
      default: false,
      required: false,
    },

    firstBranch: {
      type: Boolean,
      default: false,
      required: false,
    },

    hideChildren: {
      type: Boolean,
      default: false,
      required: false,
    },

    level: {
      type: Number,
      default: 0,
      required: false,
    },
  },

  emits: [
    "open-remove-ownership-modal",
    "open-edit-state-modal",
    "confirm-remove-state",
    "click-remove-ownership",
  ],

  data() {
    return {
      emptyState: {
        start_at: null,
        end_at: null,
        shares: null,
        owned_shares: null,
        owned_votes: null,
        votes: null,
        ownership_type: null,
        ownership_type_mode: "auto",
      },
    };
  },

  computed: {
    treelevels() {
      return this.getQueryParam("treelevels");
    },

    showShares() {
      return this.getQueryParam("show_shares") && this.level !== 0;
    },

    lastBranch() {
      if (this.hideChildren) {
        return true;
      }
      return (
        this.groupMember.child_ownerships?.length === 0 &&
        this.groupMember.child_invitations?.length === 0
      );
    },

    actions() {
      let value;

      if (
        !isEmpty(this.groupMember.actions) &&
        !isEmpty(this.ownership?.actions)
      ) {
        value = {
          ...this.groupMember.actions,
          divider: { type: "divider" },
          ...this.ownership?.actions,
        };
      } else if (!isEmpty(this.groupMember.actions)) {
        value = { ...this.groupMember.actions };
      } else if (!isEmpty(this.ownership?.actions)) {
        value = { ...this.ownership?.actions };
      } else {
        value = {};
      }

      delete value.show;
      return value;
    },

    computedStates() {
      const company_group_ownership = { ...this.ownership };
      delete company_group_ownership.states;

      return [...this.ownership.states]
        .map((state) => ({
          ...state,
          company_group_ownership,
        }))
        .sort((a, b) => {
          return new Date(b.start_at) - new Date(a.start_at);
        });
    },
  },

  methods: {
    onClickAddState() {
      this.$emit("open-edit-state-modal", {
        ...this.emptyState,
        company_group_ownership: this.ownership,
      });
    },

    onClickEditState(state) {
      this.$emit("open-edit-state-modal", {
        ...state,
        company_group_ownership: this.ownership,
      });
    },

    onClickRemoveState(state) {
      this.$emit("confirm-remove-state", {
        ...state,
        company_group_ownership: this.ownership,
      });
    },
  },
};
</script>
