<template>
  <div class="row">
    <div class="col-12 col-md-6 mb-4">
      <h3
        class="mb-3"
        v-text="$t('components.meetings.tabs.reviewed_minutes.title')"
      />

      <meeting-status
        v-if="meeting.process.active_state === 'approve'"
        :meeting="meeting"
      />

      <tab-activity
        :owner="minutes"
        :accepted-keys="['material.approved', 'material.declined']"
      />

      <be-button
        v-if="canShow"
        :href="`${meeting.paths.base}/minutes`"
        variant="outline-primary"
      >
        {{
          $t("components.meetings.tabs.reviewed_minutes.show_reviewed_minutes")
        }}
      </be-button>
    </div>

    <div class="col-12 col-md-6"></div>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";
import TabActivity from "./TabActivity.vue";

export default {
  components: {
    TabActivity,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canShow() {
      return this.minutes?.policy?.show;
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    minutesStatus() {
      return this.getMinutesStatus(this.meeting);
    },
  },

  mounted() {
    this.fetchMinutesForMeeting(this.meeting);
  },
};
</script>
