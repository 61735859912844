<template>
  <tr v-show="!possibleAnswer._destroy">
    <td>
      <be-form-group :error="getErrors(possibleAnswer, ['title'])" class="mb-0">
        <be-form-input
          :model-value="possibleAnswer.title"
          maxlength="255"
          :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][title]`"
          @input="update('title', $event)"
          @change="clearErrors(possibleAnswer, ['title'])"
        />
      </be-form-group>

      <input
        v-if="possibleAnswer.id"
        type="hidden"
        :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][id]`"
        :value="possibleAnswer.id"
      />

      <input
        v-if="question.created_at"
        type="hidden"
        :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][question_id]`"
        :value="question.id"
      />

      <input
        type="hidden"
        :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][position]`"
        :value="currentPosition"
      />

      <input
        type="hidden"
        :name="`evaluation_form[evaluation_sections_attributes][${componentIndexes[0]}][evaluation_questions_attributes][${componentIndexes[1]}][possible_answers_attributes][${componentIndexes[2]}][_destroy]`"
        :value="possibleAnswer._destroy"
      />
    </td>

    <td class="col-shrink">
      <be-button
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removePossibleAnswer"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    loadedPossibleAnswer: {
      type: Object,
      required: true,
    },

    question: {
      type: Object,
      required: true,
    },

    section: {
      type: Object,
      required: true,
    },

    possibleAnswers: {
      type: Array,
      required: true,
    },

    componentIndexes: {
      type: Array,
      required: true,
    },
  },

  emits: ["possible-answer-removed"],

  computed: {
    possibleAnswer() {
      return this.$store.getters["evaluation_forms/getPossibleAnswerById"]({
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,

        possibleAnswerId:
          this.loadedPossibleAnswer.id || this.loadedPossibleAnswer.uuid,
      });
    },

    currentPosition() {
      return (
        parseInt(
          this.possibleAnswers
            .filter((answer) => answer._destroy != 1)
            .findIndex((existingAnswer) => {
              return (
                (existingAnswer.id &&
                  existingAnswer.id == this.possibleAnswer.id) ||
                (existingAnswer.uuid &&
                  existingAnswer.uuid == this.possibleAnswer.uuid)
              );
            })
        ) + 1
      );
    },
  },

  methods: {
    removePossibleAnswer() {
      this.update("_destroy", 1);
      this.$emit("possible-answer-removed", this.possibleAnswer);
    },

    update(key, value) {
      this.$store.dispatch("evaluation_forms/updatePossibleAnswer", {
        sectionId: this.section.id || this.section.uuid,
        questionId: this.question.id || this.question.uuid,
        possibleAnswerId: this.possibleAnswer.id || this.possibleAnswer.uuid,
        data: { [key]: value },
      });
    },
  },
};
</script>
