<template>
  <div>
    <unlock-status
      v-if="
        meeting.process.minutes_unlocking_in_progress &&
        minutesStatus !== 'loading'
      "
      :meeting="meeting"
      :minutes="minutes"
      class="mb-4"
    />

    <be-skeleton-table
      v-else-if="
        meeting.process.minutes_unlocking_in_progress &&
        minutesStatus === 'loading'
      "
      :rows="3"
      :columns="2"
    />

    <div class="row">
      <div class="col-12 col-md-6 mb-4">
        <h3 class="mb-3">
          {{ $t("components.meetings.tabs.archived.title") }}
        </h3>

        <meeting-status
          v-if="meeting.process.active_state === 'archived'"
          :meeting="meeting"
        />

        <tab-activity
          :owner="minutes"
          :accepted-keys="['material.uploaded_signed_minutes']"
        />

        <div class="d-md-flex justify-content-start">
          <be-button
            :href="`${meeting.paths.base}/minutes`"
            variant="outline-primary"
            class="mb-2 mb-lg-4"
          >
            {{ $t("components.meetings.tabs.archived.reviewed_minutes") }}
          </be-button>
        </div>
      </div>

      <div
        v-if="folderStatus === 'loaded' && folder.id"
        class="col-12 col-md-6"
      >
        <h3>
          {{
            $t("components.meetings.tabs.components.meeting_documents.title")
          }}
        </h3>

        <meeting-documents
          :folder="folder"
          :meeting="meeting"
          initial-view="list"
          hide-header
        />
      </div>
    </div>
  </div>
</template>

<script>
import MeetingDocuments from "./components/MeetingDocuments.vue";
import TabActivity from "./TabActivity.vue";
import MaterialMixin from "@/mixins/meetings/material";

export default {
  components: {
    MeetingDocuments,
    TabActivity,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    standalone: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    minutes() {
      return this.getMinutes(this.meeting);
    },

    minutesStatus() {
      return this.getMinutesStatus(this.meeting);
    },

    folder() {
      return this.getFolder(this.meeting);
    },

    folderStatus() {
      return this.getFolderStatus(this.meeting);
    },
  },

  mounted() {
    this.fetchMinutesForMeeting(this.meeting);
    this.fetchFolderForMeeting(this.meeting);
  },
};
</script>
