<template>
  <div v-if="localContacts.length">
    <header class="mb-4">
      <h1>{{ $t("activerecord.models.contact.other") }}</h1>
    </header>

    <div class="card">
      <div v-if="allowNew" class="card-header d-md-flex justify-content-end">
        <be-button :href="url('/contacts/new')" variant="outline-primary">
          {{ $t("models.contact.new") }}
        </be-button>
      </div>

      <div class="card-body">
        <contacts-table
          :contacts="localContacts"
          @contact-removed="removeContact"
        />
      </div>
    </div>
  </div>

  <div v-else class="card">
    <div class="card-header">
      <h5 class="card-title">{{ $t("activerecord.models.contact.other") }}</h5>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6 order-2 order-md-1">
          <p>{{ $t("components.contacts.contacts_container.description") }}</p>

          <p class="mb-4">
            {{ $t("components.contacts.contacts_container.description2") }}
          </p>

          <be-button
            v-if="allowNew"
            :href="url('/contacts/new')"
            variant="primary"
          >
            {{ $t("models.contact.new") }}
          </be-button>

          <be-button
            v-if="allowNew"
            :href="url('/contacts/import')"
            variant="outline-secondary"
          >
            {{ $t("models.contact.import") }}
          </be-button>
        </div>

        <div class="col-12 col-md-6 order-1 order-md-2 mb-3 mb-md-0">
          <be-img :src="imageSrc(`onboarding/intro/contacts.png`)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import ContactsTable from "./ContactsTable.vue";

export default {
  components: {
    ContactsTable,
  },

  props: {
    contacts: {
      type: Array,
      required: false,
      default: () => [],
    },

    allowNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      localContacts: cloneDeep(this.contacts),
    };
  },

  methods: {
    async removeContact(contact) {
      try {
        await axios.delete(this.url(`/contacts/${contact.id}`));

        const idx = this.localContacts.findIndex(
          (existingContact) => existingContact.id == contact.id
        );

        if (idx > -1) {
          this.localContacts.splice(idx, 1);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
