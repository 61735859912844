<template>
  <div class="form-row">
    <be-form-group
      v-if="showYears"
      class="col-6 col-sm-4 col-md-auto order-1"
      :label="$t('components.corporate_group_tree.year_label')"
      label-for="year"
      label-class="mr-2"
    >
      <be-dropdown id="year" :text="selectedYear">
        <be-dropdown-item
          v-for="(title, _year) in year_options"
          :key="_year"
          @click="changeYear(_year)"
        >
          {{ title }}
        </be-dropdown-item>
      </be-dropdown>
    </be-form-group>

    <be-form-group
      v-if="showParent"
      class="col-6 col-sm-4 col-md-auto"
      :class="reverse ? 'order-0' : 'order-2'"
      :label="$t('components.corporate_group_tree.company_label')"
      label-for="parent"
      label-class="mr-2"
    >
      <be-form-select
        v-if="parentOptions.length > 5"
        id="parent"
        :model-value="selectedParent.nanoid"
        :options="parentOptions"
        option-label="title"
        option-value="nanoid"
        @update:model-value="changeParent($event)"
      >
        <template #option="{ option }">
          <div class="d-block text-nowrap">
            <strong v-if="option.top_parent">
              {{ option.title }}
            </strong>

            <template v-else>
              {{ option.title }}
            </template>
          </div>

          <div class="text-muted text-nowrap">
            <small v-if="option.top_parent">
              {{ $t("activerecord.attributes.company.parent") }}
            </small>

            <small v-else-if="option.has_children">
              {{ $t("activerecord.attributes.company.parent") }},
              {{ $t("activerecord.attributes.company.child") }}
            </small>

            <small v-else-if="!option.has_parent">
              {{ $t("activerecord.attributes.company.child") }}
            </small>

            <small v-else>
              {{ $t("activerecord.attributes.company.not_group") }}
            </small>
          </div>
        </template>
      </be-form-select>

      <be-dropdown v-else id="parent" :text="selectedParentTitle">
        <be-dropdown-item
          v-for="{ title, nanoid } in parentOptions"
          :key="nanoid"
          @click="changeParent(nanoid)"
        >
          {{ title }}
        </be-dropdown-item>
      </be-dropdown>
    </be-form-group>

    <be-form-group
      class="order-3 col-6 col-sm-4 col-md-auto"
      :label="$t('components.corporate_group_tree.levels')"
      label-for="levels"
    >
      <be-form-radio-group
        id="levels"
        v-model="levels"
        :options="levelOptions"
        name="levels"
        button-variant="outline-secondary"
        buttons
      />
    </be-form-group>

    <be-form-group
      class="order-4 col-6 col-sm-4 col-md-auto"
      :label="$t('components.corporate_group_tree.states')"
    >
      <be-button variant="outline-secondary" @click="toggleShares">
        {{ shares ? $t("buttons.titles.hide") : $t("buttons.titles.show") }}
      </be-button>
    </be-form-group>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import RequestQueryMixin from "@/mixins/RequestQuery";

const { mapState: mapGroupTreeState, mapActions } = createNamespacedHelpers(
  "corporate_group_tree"
);

export default {
  mixins: [RequestQueryMixin],

  props: {
    reverse: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      levelOptions: [
        {
          text: this.$t("components.corporate_group_tree.one_level"),
          value: "1",
        },
        {
          text: this.$t("components.corporate_group_tree.all_levels"),
          value: "0",
        },
      ],
    };
  },

  computed: {
    ...mapGroupTreeState(["year_options", "parent_options", "can_edit"]),

    parentOptions() {
      const options = [...this.parent_options];
      return options.sort((a, b) => {
        return (
          b.top_parent - a.top_parent ||
          b.has_children - a.has_children ||
          a.has_parent - b.has_parent
        );
      });
    },

    shares() {
      return this.getQueryParam("show_shares");
    },

    year() {
      return this.getQueryParam("year");
    },

    parent() {
      return this.getQueryParam("parent");
    },

    selectedYear() {
      return this.year_options[this.year];
    },

    selectedParent() {
      if (!this.parent) {
        return this.parent_options[0];
      }
      return this.parent_options.find(
        (option) => option.nanoid === this.parent
      );
    },

    showYears() {
      return Object.values(this.year_options).length > 1;
    },

    showParent() {
      return this.parent_options.length > 1;
    },

    selectedParentTitle() {
      return this.selectedParent.title;
    },

    levels: {
      get() {
        return this.getQueryParam("treelevels");
      },

      set(value) {
        this.changeLevels(value);
      },
    },
  },

  methods: {
    ...mapActions([
      "changeYear",
      "changeParent",
      "toggleShares",
      "changeLevels",
    ]),
  },
};
</script>
