<template>
  <tr>
    <td class="text-wrap">
      <document-link
        :document-id="initialDocument.id"
        :filename="initialDocument.filename"
        :icon="initialDocument.fontawesome_icon"
        :title="initialDocument.title"
      />
    </td>

    <td v-if="createdAtColumn" class="col-shrink">
      {{ createdAt }}
    </td>

    <td class="col-shrink">
      <be-button
        v-be-tooltip="$t('buttons.titles.share')"
        variant="outline-secondary"
        size="sm"
        icon="fa-share"
        inline
        @click="$emit('share-document', localDocument)"
      />
    </td>

    <td
      v-for="user in users"
      :key="`user-${user.id}`"
      class="col-shrink text-center"
    >
      <be-button
        v-if="user.id == $currentUser.id && !documentRead(user)"
        v-be-tooltip="$t('buttons.titles.mark_as_read')"
        variant="outline-success"
        size="sm"
        icon="fa-check"
        inline
        @click="readDocument"
      />

      <be-status
        v-else-if="documentRead(user)"
        size="xl"
        variant="success"
        class="justify-content-center"
        :tooltip="$t('activerecord.attributes.reading.read')"
      />
    </td>
  </tr>
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    initialDocument: {
      type: Object,
      required: true,
    },

    createdAtColumn: {
      type: Boolean,
      required: false,
      default: false,
    },

    sortedUsers: {
      type: Array,
      required: false,
      default: null,
    },
  },

  emits: ["share-document"],

  data() {
    return {
      localDocument: this.initialDocument,
    };
  },

  computed: {
    createdAt() {
      return format(new Date(this.localDocument.created_at), "yyyy-MM-dd");
    },

    users() {
      return this.sortedUsers ? this.sortedUsers : [this.$currentUser];
    },
  },

  methods: {
    documentRead(user) {
      const readings = this.localDocument.readings;

      if (readings.length > 0) {
        return !!readings.find((reading) => reading.user_id == user.id);
      }

      return false;
    },

    async readDocument() {
      try {
        const response = await axios.post(
          this.url(`/documents/${this.localDocument.id}/readings`)
        );

        this.localDocument.readings.push(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
