<template>
  <tr>
    <td>
      <user-avatar :user="membership.user" />
    </td>

    <td>
      <div>{{ membership.user.name }}</div>

      <membership-subtitle :membership="membership" class="small" />
    </td>

    <td>
      <be-link :href="`mailto:${membership.user.email}`">
        {{ membership.user.email }}
      </be-link>
    </td>

    <td>
      {{ $t(`models.membership.policy_levels.${membership.policy_level}`) }}
      <template v-if="membership.expires_at">
        <i
          v-be-tooltip="
            $t('models.membership.will_expire_at', {
              date: $d(new Date(membership.expires_at)),
            })
          "
          class="fal fa-clock text-muted ml-1"
        />
      </template>
    </td>

    <td>
      {{ dateIfPresent(membership.created_at) }}
    </td>

    <td>
      {{ dateIfPresent(membership.deleted_at) }}
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";
import MembershipSubtitle from "@/components/shared/MembershipSubtitle.vue";

export default {
  components: {
    MembershipSubtitle,
  },

  props: {
    membership: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "current_user/getUser",
    }),
  },

  methods: {
    dateIfPresent(date) {
      if (date) {
        return this.$d(new Date(date), { format: "mini" });
      }
      return "-";
    },
  },
};
</script>
