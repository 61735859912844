<template>
  <div>
    <template v-if="haveActiveSuggestions">
      <hr />

      <suggestions-table
        :suggestions="suggestions"
        :disabled="disabled"
        :minutes-step="minutesStep"
        @update:suggestions="(value) => (suggestions = value)"
      />
    </template>

    <div v-if="!disabled" class="row">
      <div class="col">
        <be-button
          variant="outline-secondary"
          size="sm"
          @click="onAddSuggestion"
        >
          {{ $t("components.meetings.suggestions.add_date_suggestions") }}
        </be-button>

        <be-button
          v-be-modal.generate-suggestions-modal
          :disabled="haveGeneratedSuggestions"
          variant="outline-secondary"
          size="sm"
        >
          {{
            $t(
              "components.meetings.suggestions.generate_suggestions.button_text"
            )
          }}
        </be-button>

        <be-button
          v-if="havePersistedNonSelected && haveActiveSuggestions"
          v-be-modal.availability-modal
          variant="outline-secondary"
          size="sm"
        >
          {{ $t("components.meetings.suggestions.show_availability") }}
        </be-button>
      </div>
    </div>

    <div v-if="haveDuplicates" class="row mt-3">
      <div class="col">
        <be-alert variant="warning">
          {{ $t("components.meetings.suggestions.duplicates_found") }}
        </be-alert>
      </div>
    </div>

    <div v-if="haveChangedSuggestions && !disabled" class="row mt-3">
      <div class="col">
        <be-alert variant="info">
          {{ $t("components.meetings.suggestions.message_information") }}
        </be-alert>
      </div>
    </div>

    <availability-modal
      v-if="havePersistedNonSelected"
      id="availability-modal"
      :meeting="meeting"
      @update:meeting="onMeetingUpdated"
      @mark-availability="$emit('mark-availability', $event)"
      @select-suggestion="$emit('select-suggestion', $event)"
    />

    <generate-suggestions-modal @generate-suggestions="onGenerateSuggestions" />
  </div>
</template>

<script>
import { requestSuggestions } from "../requests";
import { addMinutes, getMinutes, addBusinessDays } from "date-fns";
import uniqWith from "lodash/uniqWith";

import AvailabilityModal from "./AvailabilityModal.vue";
import GenerateSuggestionsModal from "./GenerateSuggestionsModal.vue";
import SuggestionsTable from "./SuggestionsTable.vue";

export const MINUTES_STEP = 5;
export const DEFAULT_MEETING_DURATION = 60; // minutes

export default {
  components: {
    AvailabilityModal,
    GenerateSuggestionsModal,
    SuggestionsTable,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },

    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  emits: [
    "update:meeting",
    "mark-availability",
    "select-suggestion",
    "is-valid",
  ],

  data() {
    return {
      minutesStep: MINUTES_STEP,
      haveChangedSuggestions: false,
    };
  },

  computed: {
    suggestions: {
      get() {
        return this.meeting?.suggestions || [];
      },

      set(newValue) {
        const localMeeting = this.cloneDeep(this.meeting);
        localMeeting.suggestions = newValue;
        this.onMeetingUpdated(localMeeting);
      },
    },

    maxDate: {
      get() {
        if (!this.activeSuggestions?.length) return null;
        const dates = this.activeSuggestions.map((suggestion) => {
          return new Date(suggestion.start_at);
        });
        return new Date(Math.max(...dates));
      },
    },

    haveActiveSuggestions() {
      return this.activeSuggestions.length > 0;
    },

    haveGeneratedSuggestions() {
      return (
        this.activeSuggestions.filter(
          (suggestion) =>
            suggestion.auto_generated || suggestion.auto_generated_at
        ).length > 0
      );
    },

    havePersistedNonSelected() {
      return (
        this.suggestions.filter(
          (suggestion) =>
            !suggestion.selected_at & (typeof suggestion.id == "number")
        ).length > 0
      );
    },

    activeSuggestions() {
      return this.suggestions.filter((suggestion) => !suggestion._destroy);
    },

    haveDuplicates() {
      const allTimes = this.activeSuggestions.map((suggestion) => [
        suggestion.start_at,
        suggestion.end_at,
      ]);
      const same = uniqWith(
        allTimes,
        (val, otherVal) => val[0] === otherVal[0] && val[1] === otherVal[1]
      );

      this.$emit("is-valid", same.length === allTimes.length);
      return same.length !== allTimes.length;
    },
  },

  watch: {
    activeSuggestions: {
      deep: true,

      handler() {
        if (this.activeSuggestions.length === 0) {
          this.haveChangedSuggestions = false;
        } else {
          this.haveChangedSuggestions = true;
        }
      },
    },
  },

  methods: {
    onAddSuggestion() {
      const [start_at, end_at] = this.getSuggestionTimeRange();
      this.suggestions.push({
        id: this.generateUuid(),
        _destroy: false,
        start_at,
        end_at,
      });

      this.haveChangedSuggestions = true;
    },

    async onGenerateSuggestions(range) {
      try {
        const { start_at, end_at } = this.meeting;

        const { data } = await requestSuggestions(
          start_at,
          end_at,
          range,
          this.url
        );

        data
          .filter((suggestion) => !suggestion.selected_at)
          .forEach((suggestion) => {
            this.suggestions.push({
              uuid: this.generateUuid(),
              ...suggestion,
            });
          });
      } catch (error) {
        this.handleError(error);
      }
      this.haveChangedSuggestions = true;
    },

    getSuggestionTimeRange() {
      let newTime = this.maxDate || new Date();
      newTime = addBusinessDays(newTime, 1);

      const minutes = getMinutes(newTime);

      if (minutes % MINUTES_STEP !== 0) {
        newTime.setMinutes(Math.round(minutes / MINUTES_STEP) * MINUTES_STEP);
      }

      newTime.setSeconds(0);
      newTime.setMilliseconds(0);

      return [
        newTime.toISOString(),
        addMinutes(newTime, DEFAULT_MEETING_DURATION).toISOString(),
      ];
    },

    onMeetingUpdated(value) {
      this.$emit("update:meeting", value);
    },
  },
};
</script>
