<template>
  <tr>
    <td class="pl-5">{{ subCategory.title }}</td>

    <td class="col-shrink">
      <be-button
        v-if="subCategory.policy.destroy && !subCategory.active"
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeSubCategory"
      />

      <be-button
        v-if="subCategory.policy.destroy && subCategory.active"
        v-be-tooltip="$t('buttons.titles.remove')"
        :href="editSubCategoryUrl"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    category: {
      type: Object,
      required: true,
    },

    subCategory: {
      type: Object,
      required: true,
    },
  },

  emits: ["removed-sub-category"],

  computed: {
    editSubCategoryUrl() {
      return this.url(`/sub_categories/${this.subCategory.id}/edit_categories`);
    },

    subCategoryUrl() {
      return this.url(`/sub_categories/${this.subCategory.id}`);
    },
  },

  methods: {
    async removeSubCategory() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("nav.confirm_delete_w_title", {
          title: this.subCategory.title,
        })
      );

      if (isConfirmed) {
        try {
          const response = await axios.delete(this.subCategoryUrl);

          this.$emit("removed-sub-category", response.data);
        } catch (error) {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
