<template>
  <tr v-show="!localShareholder.destroyed">
    <td>
      <be-form-select
        v-model="localShareholder.shareholder_type"
        :name="`shareholder_register[shareholders_attributes][${index}][shareholder_type]`"
        :options="shareholderTypeOptions"
        @change="changeShareholder('shareholder_type')"
      />
    </td>

    <td>
      <be-form-group :error="getErrors(localShareholder, 'name')">
        <be-form-input
          v-model="localShareholder.name"
          :name="`shareholder_register[shareholders_attributes][${index}][name]`"
          required
          @change="changeShareholder('name')"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group :error="getErrors(localShareholder, 'email')">
        <be-form-input
          v-model="localShareholder.email"
          type="email"
          :name="`shareholder_register[shareholders_attributes][${index}][email]`"
          required
          @change="changeShareholder('email')"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group
        :error="getErrors(localShareholder, 'identification_number')"
      >
        <be-form-input
          v-model="localShareholder.identification_number"
          :name="`shareholder_register[shareholders_attributes][${index}][identification_number]`"
          required
          @change="changeShareholder('identification_number')"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group :error="getErrors(localShareholder, 'company_name')">
        <be-form-input
          v-model="localShareholder.company_name"
          :disabled="localShareholder.shareholder_type !== 'legal_entity'"
          :name="`shareholder_register[shareholders_attributes][${index}][company_name]`"
          :required="localShareholder.shareholder_type === 'legal_entity'"
          @change="changeShareholder('company_name')"
        />
      </be-form-group>
    </td>

    <td>
      <be-form-group :error="getErrors(localShareholder, 'share_count')">
        <be-form-input
          v-model="localShareholder.share_count"
          type="number"
          :name="`shareholder_register[shareholders_attributes][${index}][share_count]`"
          required
          step="1"
          :min="min_share_count"
          :max="max_share_count"
          @change="changeShareholder('share_count')"
        />
      </be-form-group>
    </td>

    <td>
      <input
        type="hidden"
        :name="`shareholder_register[shareholders_attributes][${index}][shareholder_buyer_type]`"
      />

      <input
        v-model="localShareholder.row_id"
        type="hidden"
        :name="`shareholder_register[shareholders_attributes][${index}][row_id]`"
      />

      <input
        type="hidden"
        :name="`shareholder_register[shareholders_attributes][${index}][_destroy]`"
        :value="localShareholder.destroyed ? 1 : 0"
      />

      <be-button
        v-be-tooltip="$t('buttons.titles.remove')"
        variant="danger"
        size="sm"
        icon="fa-times"
        inline
        @click="removeShareholder()"
      />
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    shareholder: {
      type: Object,
      required: true,
    },
  },

  emits: ["shareholder-change", "shareholder-remove"],

  data() {
    return {
      min_share_count: this.shareholder.min_share_count,
      max_share_count: this.shareholder.max_share_count,
      localShareholder: this.cloneDeep(this.shareholder),
    };
  },

  computed: {
    shareholderTypeOptions() {
      return [
        {
          value: "physical_person",
          label: this.$t("models.shareholder.person"),
        },
        {
          value: "legal_entity",
          label: this.$t("models.shareholder.legal_person"),
        },
      ];
    },
  },

  watch: {
    shareholder(value) {
      this.localShareholder = this.cloneDeep(value);
    },
  },

  methods: {
    changeShareholder(attribute) {
      this.clearErrors(this.localShareholder, [attribute]);
      this.$emit("shareholder-change", this.localShareholder);
    },

    removeShareholder() {
      this.$emit("shareholder-remove", this.localShareholder);
    },
  },
};
</script>
