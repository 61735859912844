<template>
  <be-modal
    id="add-contact"
    :title="$t('models.contact.new')"
    :ok-title="$t('models.contact.create')"
    @ok="saveContact"
  >
    <be-form-group
      label-for="name"
      :label="$t('activerecord.attributes.contact.name')"
      :error="getErrors(contact, ['name'])"
    >
      <be-form-input
        id="contact-name"
        v-model="contact.name"
        required
        @change="clearErrors(contact, ['name'])"
      />
    </be-form-group>

    <be-form-group
      label-for="contact-email"
      :label="$t('activerecord.attributes.contact.email')"
    >
      <be-form-input id="contact-email" v-model="contact.email" type="email" />
    </be-form-group>

    <be-form-group
      label-for="contact-telephone"
      :label="$t('activerecord.attributes.contact.telephone')"
      :error="getErrors(contact, ['telephone'])"
    >
      <be-tel-input
        id="contact-telephone"
        v-model="contact.telephone"
        :state="validationState(contact, ['telephone'])"
        @change="clearErrors(contact, ['telephone'])"
      />
    </be-form-group>

    <be-form-group
      label-for="contact-company-name"
      :label="$t('activerecord.attributes.contact.company_name')"
    >
      <be-form-input id="contact-company-name" v-model="contact.company_name" />
    </be-form-group>

    <be-alert variant="info">
      {{ $t("components.shared.add_contact_modal.see_contacts_for_more") }}
    </be-alert>
  </be-modal>
</template>

<script>
export default {
  emits: ["contact-added"],

  data() {
    return {
      contact: this.newContact(),
    };
  },

  methods: {
    async saveContact(beModalEvt) {
      beModalEvt.preventDefault();

      try {
        const response = await axios.post(this.url("/contacts"), {
          contact: this.contact,
        });
        this.$emit("contact-added", response.data);
        this.contact = this.newContact();

        this.$beModal.hide("add-contact");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.contact = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },

    newContact() {
      return {
        name: null,
        email: null,
        telephone: null,
        company_name: null,
      };
    },
  },
};
</script>
