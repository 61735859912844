<template>
  <be-button
    v-be-tooltip="{
      title: tooltip,
      disabled: tooltipDisabled,
    }"
    variant="outline-primary"
    class="text-nowrap"
    :href="url(`/shareholder_register/actions/new`)"
    :disabled="disabled"
  >
    {{ $t("models.shareholder_register_action.new") }}
  </be-button>
</template>

<script>
import { mapGetters } from "vuex";
import { isMissingTransactions } from "@/utils/shareholder_register_helpers";

export default {
  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      lastAction: "shareholder_register/lastAction",
      transactions: "shareholder_register/transactions",
    }),

    disabled() {
      return !this.shareholderRegister.policy.create_action;
    },

    lastActionTransactions() {
      return this.transactions.filter(
        (transaction) =>
          transaction.shareholder_register_action_id === this.lastAction.id
      );
    },

    tooltip() {
      if (!this.lastAction) {
        return false;
      }

      const lastActionMissingTransactions = isMissingTransactions(
        this.lastAction,
        this.lastActionTransactions
      );

      if (this.lastActionLocked) {
        if (lastActionMissingTransactions) {
          return this.$t(
            "components.shareholder_registers.new_action_button.transactions_missing_for_locked_action"
          );
        }
      } else if (lastActionMissingTransactions) {
        return this.$t(
          "components.shareholder_registers.new_action_button.transactions_missing"
        );
      } else {
        return this.$t(
          "components.shareholder_registers.new_action_button.unlocked_actions_exists"
        );
      }

      return "";
    },

    tooltipDisabled() {
      return !this.disabled;
    },

    lastActionLocked() {
      return this.lastAction && !!this.lastAction.approved_at;
    },
  },
};
</script>
