<template>
  <div>
    <be-form-group
      :label="$t('activerecord.attributes.contract.creator')"
      required
    >
      <be-form-radio-group
        v-model="creatorType"
        :options="creatorTypeOptions"
        stacked
      />
    </be-form-group>

    <be-form-group>
      <template v-if="creatorType == 'user'">
        <be-form-input
          name="template_package[creators]"
          :model-value="$currentUser.name"
          disabled
        />

        <be-form-input
          name="template_package[creators]"
          :model-value="$currentUser.name"
          hidden
        />
      </template>

      <template v-if="creatorType == 'company'">
        <be-form-select v-model="companyNanoid" :options="options" />

        <be-form-input
          name="template_package[creators]"
          :model-value="companyTitle"
          hidden
        />

        <be-form-input
          name="template_package[selected_company]"
          :model-value="companyNanoid"
          hidden
        />
      </template>
    </be-form-group>
  </div>
</template>

<script>
export default {
  props: {
    templatePackage: {
      type: Object,
      required: true,
    },

    availableCompanies: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      creatorType: this.templatePackage.selected_company ? "company" : "user",

      creatorTypeOptions: [
        {
          value: "user",
          text: this.$t("models.template_package.creators.user"),
        },
        {
          value: "company",
          text: this.$t("models.template_package.creators.company"),
        },
      ],

      companyNanoid: this.templatePackage.selected_company || null,
    };
  },

  computed: {
    companyTitle() {
      return this.options.find((company) => {
        return company.value == this.companyNanoid;
      })?.text;
    },

    options() {
      return this.availableCompanies.map((company) => {
        return {
          value: company[0],
          text: company[1],
        };
      });
    },
  },

  mounted() {
    if (!this.companyNanoid) {
      this.companyNanoid = this.options[0].value;
    }
  },
};
</script>
