<template>
  <tr v-if="!destroyed">
    <!-- class=("status-" + (group.inactive? ? 'not_started' : 'done')) -->
    <td>
      <a :href="url(`/educations/groups/${group.id}`)">
        {{ group.title }}
      </a>

      <p>
        <small>{{ participants }}</small>

        <small v-if="joinedParticipants().length > 5">
          {{ $t("views.educations.groups.groups_table.and_more") }}
        </small>
      </p>
    </td>

    <td class="d-none d-md-table-cell">
      <user-avatar :user="creator" />
    </td>

    <td class="col-shrink d-none d-md-table-cell">
      {{ $d(new Date(group.created_at), "dateTime") }}
    </td>

    <td class="col-shrink">
      <be-dropdown v-if="actionsAvailable" size="sm" ellipsis>
        <be-dropdown-item
          v-if="groupJoinable"
          :href="url(`/educations/groups/${group.id}/join`)"
          data-method="POST"
        >
          {{ $t("views.educations.groups.groups_table.join") }}
        </be-dropdown-item>

        <template v-if="user.id == group.creator_id">
          <be-dropdown-item :href="url(`/educations/groups/${group.id}/edit`)">
            {{ $t("buttons.titles.edit") }}
          </be-dropdown-item>

          <be-dropdown-divider />

          <be-dropdown-item variant="danger" @click="removeRow">
            {{ $t("buttons.titles.remove") }}
          </be-dropdown-item>
        </template>
      </be-dropdown>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    group: {
      type: Object,
      required: true,
    },

    groupsToJoin: {
      type: Array,
      requried: false,
      default: () => [],
    },
  },

  emits: ["row-removed"],

  data() {
    return {
      destroyed: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "current_user/getUser",
    }),

    actionsAvailable() {
      return this.user.id == this.group.creator_id || this.groupJoinable();
    },

    creator() {
      return this.group.education_group_participants.find(
        (participant) => participant.user.id == this.group.creator_id
      ).user;
    },

    participants() {
      if (!this.joinedParticipants().length) {
        return this.creator.name;
      }
      return (
        this.creator.name +
        ", " +
        this.joinedParticipants()
          .slice(0, 4)
          .map((participant) => participant.user.name)
          .join(", ")
      );
    },
  },

  methods: {
    joinedParticipants() {
      return this.group.education_group_participants.filter(
        (participant) =>
          participant.joined_at != null &&
          this.group.creator_id != participant.user.id
      );
    },

    groupJoinable() {
      return this.groupsToJoin.find((group_id) => group_id == this.group.id);
    },

    async removeRow() {
      const isConfirmed = await this.promptRemovalConfirm(
        this.$t("views.educations.groups.edit.remove_group_confirm")
      );

      if (isConfirmed) {
        axios
          .delete(this.url(`/educations/groups/${this.group.id}`))
          .then((response) => {
            this.destroyed = true;
            this.$emit("row-removed", response.data);
          })
          .catch(() => {});
      }
    },
  },
};
</script>
