<template>
  <be-modal
    :id="`update-custom-field-${customField.id}`"
    :title="$t('components.custom_fields.edit_custom_field')"
    size="sm"
    :ok-title="$t('buttons.titles.save')"
    @cancel="resetDefaultValue"
    @ok="$emit('custom-field-updated', customField)"
  >
    <be-form-group
      :label="$t('components.custom_fields.title')"
      label-for="field-title"
    >
      <be-form-input
        id="field-title"
        :model-value="customField.title"
        readonly
      />
    </be-form-group>

    <be-form-group
      :label="$t('components.custom_fields.field_type')"
      label-for="field-type"
    >
      <be-form-select
        id="field-type"
        :model-value="selectedFieldType"
        :options="formattedFieldTypes"
        disabled
      />
    </be-form-group>

    <be-form-group
      :label="$t('components.custom_fields.default_value')"
      label-for="default-value"
    >
      <be-form-input
        id="default-value"
        v-model="customField.default_value"
        v-autofocus
      />
    </be-form-group>

    <be-form-checkbox
      id="marked_as_default"
      v-model="customField.marked_as_default"
    >
      {{ $t("components.custom_fields.marked_as_default") }}
    </be-form-checkbox>
  </be-modal>
</template>

<script>
export default {
  props: {
    initialCustomField: {
      type: Object,
      required: true,
    },
  },

  emits: ["custom-field-updated"],

  data() {
    return {
      customField: this.cloneDeep(this.initialCustomField),
      selectedFieldType: null,
      original_default_value: this.initialCustomField.default_value,
    };
  },

  computed: {
    formattedFieldTypes() {
      return this.$config.CONTRACT.FIELD_TYPES.map((key) => ({
        text: this.$i18n.t(`models.custom_field.field_types.${key}`),
        value: key,
      }));
    },
  },

  mounted() {
    this.selectedFieldType =
      this.customField.field_type || this.formattedFieldTypes[0].value;
  },

  methods: {
    resetDefaultValue() {
      this.customField.default_value = this.original_default_value;
    },
  },
};
</script>
