<template>
  <states-table
    :states="ownership_states"
    show-ownership-data
    @click-edit-state="onClickEditState"
    @click-remove-state="$emit('confirm-remove-state', $event)"
  />
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import StatesTable from "./StatesTable.vue";

const { mapState } = createNamespacedHelpers("corporate_group_tree");

export default {
  components: { StatesTable },
  emits: ["open-edit-state-modal", "confirm-remove-state"],

  computed: {
    ...mapState(["ownership_states"]),
  },

  methods: {
    onClickEditState(state) {
      const ownership = state.company_group_ownership;
      const states = this.ownership_states.filter(
        (otherState) => otherState.company_group_ownership.id === ownership.id
      );
      this.$emit("open-edit-state-modal", {
        ...state,
        company_group_ownership: { ...ownership, states },
      });
    },
  },
};
</script>
