<template>
  <signature-case>
    <template #title>
      {{ document.owner_info.title }}

      <div class="small">
        {{ document.owner_info.subtitle }}
      </div>
    </template>

    <template #header-buttons>
      <signature-case-buttons :document="localDocument" />
    </template>

    <template v-if="showMetadata" #metadata>
      <signature-case-metadata :document="localDocument" />
    </template>

    <template #table>
      <be-alert v-if="ownerDestroyed" variant="warning">
        {{
          $t(
            "views.companies.documents.signatures.sign_list.document_owner_destroyed"
          )
        }}
      </be-alert>

      <template v-else-if="notHandledInDocumentArchive">
        <div class="row mb-3">
          <div class="col-12 col-md">
            <be-alert variant="info" class="mb-md-0">
              {{
                $t(
                  "views.companies.documents.signatures.sign_list.handle_annual_report"
                )
              }}
            </be-alert>
          </div>

          <div
            class="col-12 col-md-auto d-flex justify-content-end align-items-center"
          >
            <be-button
              variant="outline-primary"
              :href="url(`/annual_reports/${document.owner_id}`)"
            >
              {{
                $t(
                  "views.companies.documents.signatures.sign_list.show_annual_report"
                )
              }}
            </be-button>
          </div>
        </div>
      </template>

      <signature-table
        v-if="showMetadata"
        :signatures="preparedSignatures"
        :allowed-to-remind="allowedToRemind"
        :read-only="notHandledInDocumentArchive"
        @remind="sendDocumentSignatureReminder"
      />
    </template>
  </signature-case>
</template>

<script>
import { EventBus } from "@/event-bus";

import SignatureCase from "@/components/shared/SignatureCase.vue";
import SignatureHelpers from "@/mixins/signatureHelpers";
import SignatureTable from "@/components/shared/SignatureTable.vue";

import SignatureCaseButtons from "./SignatureCaseButtons.vue";
import SignatureCaseMetadata from "./SignatureCaseMetadata.vue";

export default {
  components: {
    SignatureCase,
    SignatureCaseButtons,
    SignatureCaseMetadata,
    SignatureTable,
  },

  mixins: [SignatureHelpers],

  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localDocument: this.cloneDeep(this.document),
    };
  },

  computed: {
    allowedToRemind() {
      return this.localDocument.signatures.some(
        (signature) => signature.policy?.remind
      );
    },

    // Signatures for some annual report documents are read-only in the Document archive.
    notHandledInDocumentArchive() {
      return ["annual_report", "board_statement", "audit_report"].includes(
        this.localDocument.key
      );
    },

    showMetadata() {
      return !this.notHandledInDocumentArchive && !this.ownerDestroyed;
    },

    // Today we destroy documents when AnnualReports are removed, but we have not always done that.
    // This is a temporary solution to show a specific error for the end-user.
    ownerDestroyed() {
      return this.localDocument?.owner_info?.destroyed;
    },

    preparedSignatures() {
      return this.localDocument.signatures.map((signature) =>
        this.documentPrepareSignature(this.localDocument, signature)
      );
    },
  },

  mounted() {
    EventBus.on("signature-updated", this.documentUpdateSignature);
  },

  methods: {
    documentUpdateSignature(signature) {
      const index = this.localDocument.signatures.findIndex(
        (existing) => existing.id === signature.id
      );

      if (index !== -1) {
        this.localDocument.signatures[index] = signature;
      } else {
        console.warn("Could not find signature to update", signature);
      }
    },
  },
};
</script>
