<template>
  <div v-if="canReview || canComment" class="card-footer">
    <div v-if="canReview">
      <div class="w-100">
        <h3 v-if="!material.external_uploaded_at" class="mb-3">
          {{ $t("models.attendance.review_status") }}
        </h3>

        <be-form-group :error="getErrors(attendance, 'review_level')">
          <be-form-radio-group
            v-model="attendance.review_level"
            :options="reviewLevels"
            alternative
            :state="validationState(attendance, 'review_level')"
            @change="clearErrors(attendance, 'review_level')"
          />
        </be-form-group>

        <be-form-group
          label-for="review-comment"
          :label="$t('activerecord.models.comment.one')"
          :required="
            attendance.review_level !== 'approve' && !itemComments.length
          "
        >
          <be-form-textarea id="review-comment" v-model="comment" :rows="3" />
        </be-form-group>
      </div>

      <be-alert v-if="successfulSubmit" variant="info">
        {{
          $t("components.meetings.material.review.review_sent_w_status", {
            status: $t(
              `models.attendance.review_levels_present_tense.${attendance.review_level}`
            ),
          })
        }}
      </be-alert>

      <be-alert v-else-if="showReviewAlert" variant="warning">
        {{
          $t("components.meetings.material.review.invalid_w_status", {
            status: $t(
              `models.attendance.review_levels_present_tense.${attendance.review_level}`
            ),
          })
        }}
      </be-alert>

      <div class="d-md-flex align-items-center justify-content-end mt-4">
        <template v-if="!successfulSubmit && attendance.done_commenting_at">
          <div class="text-muted mr-auto mb-sm-3 mb-md-0">
            {{
              $t("models.material.minutes.review_status_w_level_w_date", {
                level: $t(`models.attendance.review_levels.${lastReviewLevel}`),
                date: $d(
                  parseDate(attendance.done_commenting_at),
                  "humanReadableWithTime"
                ),
              })
            }}.
          </div>
        </template>

        <div class="ml-auto d-sm-flex">
          <be-button
            v-be-tooltip="{
              disabled: canSendReview,
              title: $t('components.meetings.material.review.need_comment'),
            }"
            :variant="
              attendance.done_commenting_at ? 'outline-primary' : 'primary'
            "
            :disabled="!canSendReview"
            :loading="loading"
            class="ml-sm-auto"
            @click="sendReview"
          >
            {{ $t("models.attendance.send_review") }}
          </be-button>
        </div>
      </div>
    </div>

    <div v-if="canComment" class="d-flex flex-column">
      <be-form-group
        label-for="review-comment"
        :label="$t('activerecord.models.comment.one')"
      >
        <be-form-textarea id="review-comment" v-model="comment" :rows="3" />
      </be-form-group>

      <div class="d-flex flex-wrap align-items-center justify-content-end">
        <template v-if="attendance.done_commenting_at">
          <div class="text-muted mr-auto mb-3 mb-md-0">
            {{
              $t("models.material.minutes.marked_done_commenting_w_date", {
                date: $d(
                  parseDate(attendance.done_commenting_at),
                  "humanReadableWithTime"
                ),
              })
            }}.
          </div>
        </template>

        <be-button
          :variant="
            attendance.done_commenting_at ? 'outline-secondary' : 'primary'
          "
          :loading="loading"
          class="ml-auto"
          @click="sendComment"
        >
          {{ $t("models.attendance.mark_done_commenting") }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MaterialMixin from "@/mixins/meetings/material";

export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    material: {
      type: Object,
      required: true,
    },

    initialAttendance: {
      type: Object,
      required: true,
    },

    reviews: {
      type: Array,
      required: true,
    },
  },

  emits: ["review-added"],

  data() {
    return {
      attendance: this.cloneDeep(this.initialAttendance),
      loading: false,
      comment: "",
      successfulSubmit: false,

      lastReviewLevel:
        this.initialAttendance?.review_level || "request_changes",
    };
  },

  computed: {
    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
      comments: "comments/getComments",
      items: "material/items",
    }),

    canReview() {
      return this.inReview && this.attendance?.reviewer;
    },

    canComment() {
      return this.inCommenting && this.attendance?.commenter && !this.canReview;
    },

    inReview() {
      return this.meeting.process.active_state === "review";
    },

    inCommenting() {
      return ["minutes", "review"].includes(this.meeting.process.active_state);
    },

    previousReviewCommentIds() {
      return this.reviews
        .filter((review) => {
          return review.attendance_id === this.attendance.id;
        })
        .flatMap((review) => review.comments.map((comment) => comment.id));
    },

    itemComments() {
      const item_ids = new Set(
        this.items.map((item) => {
          return item.id;
        })
      );

      const secretaryDoneAt = new Date(this.material.secretary_done_at);

      // Finds comments by the current user on the current material
      // For review we also check that the comments were left since
      // the secretary marked minutes as done or if the comments were related to previous reviews
      return this.comments.filter(
        (comment) =>
          comment.deleted_at === null &&
          comment.user_id === this.$currentUser.id &&
          comment.commented_type === "Item" &&
          item_ids.has(comment.commented_id) &&
          new Date(comment.created_at) >= secretaryDoneAt &&
          !this.previousReviewCommentIds.includes(comment.id)
      );
    },

    reviewLevels() {
      return [
        {
          text: this.$t(
            "models.attendance.review_levels_present_tense.request_changes"
          ),

          value: "request_changes",
          variant: "danger",
          icon: "fas fa-times fa-lg",
        },
        {
          text: this.$t(
            "models.attendance.review_levels_present_tense.approve"
          ),

          value: "approve",
          variant: "success",
          icon: "fas fa-check fa-lg",
        },
      ];
    },

    canSendReview() {
      return (
        this.attendance.review_level === "approve" ||
        this.itemComments.length > 0 ||
        this.comment.length > 0
      );
    },

    showReviewAlert() {
      return !this.canSendReview && this.attendance.review_level !== "pending";
    },
  },

  watch: {
    "attendance.review_level": {
      handler() {
        this.successfulSubmit = false;
      },
    },

    comment: {
      handler(value) {
        if (value.length > 0) {
          this.successfulSubmit = false;
        }
      },
    },
  },

  methods: {
    async sendReview() {
      this.loading = true;

      await this.sendUnsavedComments();

      try {
        const { data } = await axios.post(
          `${this.meeting.paths.base}/minutes/review`,
          {
            review: {
              review_level: this.attendance.review_level,
              comment: this.comment,
              comment_ids: this.itemComments.map((comment) => comment.id),
            },
          }
        );

        const { attendance, review, status } = data;

        this.attendance = attendance;
        if (status === "approve") {
          // Reload to meeting overview if you were the final reviewer
          window.location = this.meeting.paths.base;
        }
        this.comment = "";
        this.lastReviewLevel = attendance.review_level;
        this.$emit("review-added", review);
        this.successfulSubmit = true;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async sendComment() {
      if (this.itemComments.length === 0 && this.comment.length === 0) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t("models.attendance.mark_done_commenting"),
          title: this.$t("models.attendance.confirm_title_done_commenting"),

          description: this.$t(
            "models.attendance.confirm_text_done_commenting"
          ),
        });

        if (!isConfirmed) {
          return;
        }
      }

      this.loading = true;

      await this.sendUnsavedComments();

      try {
        const { data } = await axios.post(
          `${this.meeting.paths.base}/minutes/done_commenter`,
          {
            review: {
              comment: this.comment,
              comment_ids: this.itemComments.map((comment) => comment.id),
            },
          }
        );

        this.comment = "";
        this.attendance = data.attendance;
        this.$emit("review-added", data.review);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async sendUnsavedComments() {
      // Get all comment lists
      const commentLists = document.querySelectorAll("#items .comments-list");

      // Click promises
      const clickPromises = [];

      // Loop over each comment list
      for (const commentList of commentLists) {
        // Get the textarea and save button
        const textarea = commentList.querySelector("textarea") || null;
        const saveButton = textarea?.parentElement.nextElementSibling;

        // Check if the textarea has content and the save button exists
        if (textarea && textarea.value.length > 0 && saveButton) {
          // Click the save button to save the unsaved comment
          clickPromises.push(
            new Promise((resolve) => {
              saveButton.click();
              resolve();
            })
          );
        }
      }

      // Wait for all clicks to complete
      await Promise.all(clickPromises);

      // Wait 500ms to ensure all comments are saved
      await new Promise((resolve) => setTimeout(resolve, 500));
    },
  },
};
</script>
