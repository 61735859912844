<template>
  <div class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="col-shrink" />

          <th>{{ $t("activerecord.attributes.membership.name") }}</th>

          <th>{{ $t("activerecord.attributes.membership.email") }}</th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.membership.policy_level") }}
          </th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.membership.created_at") }}
          </th>

          <th class="col-shrink">
            {{ $t("activerecord.attributes.membership.removed_at") }}
          </th>
        </tr>
      </thead>

      <tbody>
        <template
          v-for="membershipOrSupportManagement in membershipsOrSupportManagements"
        >
          <membership-row
            v-if="membershipOrSupportManagement.key === 'membership'"
            :key="`membership-${membershipOrSupportManagement.value.id}`"
            :membership="membershipOrSupportManagement.value"
          />

          <support-management-row
            v-else-if="
              membershipOrSupportManagement.key === 'support_management'
            "
            :key="`support-management-${membershipOrSupportManagement.value.id}`"
            :support-management-connection="membershipOrSupportManagement.value"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import MembershipRow from "./historic_memberships_and_support_managements_table/MembershipRow.vue";
import SupportManagementRow from "./historic_memberships_and_support_managements_table/SupportManagementRow.vue";

export default {
  components: {
    MembershipRow,
    SupportManagementRow,
  },

  props: {
    memberships: {
      type: Array,
      required: true,
    },

    supportManagementConnections: {
      type: Array,
      required: true,
    },
  },

  computed: {
    membershipsOrSupportManagements() {
      return [
        ...this.memberships.map((membership) => ({
          key: "membership",
          value: membership,
        })),
        ...this.supportManagementConnections.map(
          (supportManagementConnection) => ({
            key: "support_management",
            value: supportManagementConnection,
          })
        ),
      ].sort((a, b) => {
        const aDeletedOrExpiredAt =
          a.key === "membership" ? a.value.deleted_at : a.value.expires_at;

        const bDeletedOrExpiredAt =
          b.key === "membership" ? b.value.deleted_at : b.value.expires_at;
        return (
          new Date(bDeletedOrExpiredAt).getTime() -
          new Date(aDeletedOrExpiredAt).getTime()
        );
      });
    },
  },
};
</script>
