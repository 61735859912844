<template>
  <slot
    :selected-company="selectedCompany"
    :disabled="disabled"
    :set-selected-company="(event) => (selectedCompany = event.target.value)"
  />
</template>
<script>
export default {
  data() {
    return {
      selectedCompany: null,
    };
  },

  computed: {
    disabled() {
      return !this.selectedCompany;
    },
  },
};
</script>
