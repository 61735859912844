<template>
  <div>
    <h3 class="mb-3">{{ $t("components.meetings.tabs.review.title") }}</h3>

    <div class="row mb-3">
      <div class="col-12 col-md-6">
        <meeting-status
          v-if="meeting.process.active_state === 'review'"
          :meeting="meeting"
        />

        <tab-activity
          :owner="minutes"
          :accepted-keys="[
            'material.sealed',
            'material.declined',
            'material.review_started',
          ]"
        />
      </div>
    </div>

    <div class="mb-3 d-flex flex-wrap gap-2">
      <template v-if="canEdit">
        <be-button
          v-if="minutes.id"
          :href="`${meeting.paths.base}/minutes/edit`"
          variant="outline-primary"
        >
          {{ $t("models.material.minutes.edit") }}
        </be-button>
      </template>

      <be-button
        :variant="isSecretary ? 'outline-secondary' : 'outline-primary'"
        :href="`${meeting.paths.base}/minutes`"
      >
        {{ $t("models.material.minutes.show") }}
      </be-button>
    </div>

    <be-skeleton-wrapper :loading="loading">
      <template #loading>
        <be-skeleton-table :columns="2" :rows="3" />
      </template>

      <meeting-comments-table
        :meeting="meeting"
        :minutes="minutes"
        :attendances="attendances"
        :comments-requests="commentsRequests"
        mode="review-tab"
      />
    </be-skeleton-wrapper>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";
import MeetingCommentsTable from "./components/MeetingCommentsTable.vue";
import TabActivity from "./TabActivity.vue";

export default {
  components: {
    MeetingCommentsTable,
    TabActivity,
  },

  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },
  },

  computed: {
    attendances() {
      return this.getAttendances(this.meeting);
    },

    commentsRequests() {
      return this.getCommentsRequests(this.meeting);
    },

    commentsRequestStatus() {
      return this.getCommentsRequestStatus(this.meeting);
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    loading() {
      return (
        this.getAttendanceStatus(this.meeting) === "loading" ||
        this.getMinutesStatus(this.meeting) === "loading" ||
        this.getCommentsRequestStatus(this.meeting) === "loading"
      );
    },

    isSecretary() {
      for (const attendance of this.attendances) {
        if (attendance.user_id === this.$currentUser.id) {
          return attendance.secretary;
        }
      }

      return false;
    },

    shouldReview() {
      for (const attendance of this.attendances) {
        if (attendance.user_id === this.$currentUser.id) {
          return attendance.reviewer && attendance.done_commenting_at === null;
        }
      }

      return false;
    },

    minuteChangesRequested() {
      return this.attendances.some(
        (attendance) => attendance.review_level === "request_changes"
      );
    },

    canEdit() {
      return (
        (!this.minutes && this.meeting.policy.create) ||
        (this.minutes?.policy?.edit && !this.minutes?.sealed)
      );
    },
  },

  mounted() {
    this.fetchAttendancesForMeeting(this.meeting);
    this.fetchMinutesForMeeting(this.meeting);
    this.fetchCommentsRequestsForMeeting(this.meeting);
  },
};
</script>
