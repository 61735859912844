<template>
  <nested-table last-branch :level="level">
    <template #header>
      <td>
        <div v-if="company && company.path">
          <be-link :href="company.path">{{ company.title }}</be-link>
        </div>

        <div v-else-if="company">
          {{ company.title }}
        </div>

        <div v-else>
          {{ invitation.recipient_email }}
        </div>

        <div>
          <small>{{
            $t("components.corporate_group_tree.invitation_pending")
          }}</small>
        </div>
      </td>

      <td class="col-shrink d-none d-lg-table-cell" />

      <td class="col-shrink">
        <action-dropdown :actions="invitation.actions" />
      </td>
    </template>
  </nested-table>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ActionDropdown from "./ActionDropdown.vue";
import NestedTable from "./NestedTable.vue";

const { mapActions } = createNamespacedHelpers("corporate_group_tree");

export default {
  components: { ActionDropdown, NestedTable },

  props: {
    invitation: {
      type: Object,
      required: true,
    },

    level: {
      type: Number,
      required: true,
    },
  },

  computed: {
    company() {
      return this.invitation.child_company;
    },
  },

  methods: {
    ...mapActions(["loadTree"]),
  },
};
</script>
