<template>
  <div>
    <input id="" type="hidden" :name="`${namePrefix}[shareholder_buyer][id]`" />

    <input
      id=""
      type="hidden"
      :name="`${namePrefix}[shareholder_buyer][buyer]`"
      value="1"
    />

    <div class="row">
      <div class="col-12 mb-2">
        <be-form-group
          :label="$t('activerecord.attributes.company.organization_number')"
          :label-for="`identification_number_${index}`"
          :error="getErrors(localShareholder, ['identification_number'])"
        >
          <be-form-input
            :id="`identification_number_${index}`"
            v-model="localShareholder.identification_number"
            :name="`${namePrefix}[shareholder_buyer][identification_number]`"
            required
            @change="clearErrors(localShareholder, ['identification_number'])"
          />
        </be-form-group>

        <be-form-group
          :label="
            $t(
              'components.shareholder_registers.company_buyer_fields.company_name'
            )
          "
          :label-for="`company_name_${index}`"
          :error="getErrors(localShareholder, ['company_name'])"
        >
          <be-form-input
            :id="`company_name_${index}`"
            v-model="localShareholder.company_name"
            :name="`${namePrefix}[shareholder_buyer][company_name]`"
            required
            @change="clearErrors(localShareholder, ['company_name'])"
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <!--  .control-group class=(shareholder.present? && shareholder.errors[:address1].present? ? "error" : "") -->
        <h3>
          {{ $t("models.shareholder.legal_person") }}
        </h3>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.address1')"
          :label-for="`address1_${index}`"
        >
          <be-form-input
            :id="`address1_${index}`"
            v-model="localShareholder.address1"
            :name="`${namePrefix}[shareholder_buyer][address1]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.address2')"
          :label-for="`address2_${index}`"
        >
          <be-form-input
            :id="`address2_${index}`"
            v-model="localShareholder.address2"
            :name="`${namePrefix}[shareholder_buyer][address2]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.zipcode')"
          :label-for="`zipcode_${index}`"
        >
          <be-form-input
            :id="`zipcode_${index}`"
            v-model="localShareholder.zipcode"
            :name="`${namePrefix}[shareholder_buyer][zipcode]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.city')"
          :label-for="`city_${index}`"
        >
          <be-form-input
            :id="`city_${index}`"
            v-model="localShareholder.city"
            :name="`${namePrefix}[shareholder_buyer][city]`"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.country')"
          :label-for="`country_${index}`"
        >
          <be-form-select
            :id="`country_${index}`"
            v-model="localShareholder.country"
            :name="`${namePrefix}[shareholder_buyer][country]`"
            preset-options="countries"
            include-blank-option
          />
        </be-form-group>
      </div>

      <div class="col-12 col-lg-6">
        <h3>
          {{
            $t(
              "components.shareholder_registers.company_buyer_fields.contact_person"
            )
          }}
        </h3>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.name')"
          :label-for="`name_${index}`"
          :error="getErrors(shareholder, ['name'])"
        >
          <be-form-input
            :id="`name_${index}`"
            v-model="localShareholder.name"
            :name="`${namePrefix}[shareholder_buyer][name]`"
            required
            @change="clearErrors(localShareholder, ['name'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.email')"
          :label-for="`email_${index}`"
          :error="getErrors(localShareholder, ['email'])"
        >
          <be-form-input
            :id="`email_${index}`"
            v-model="localShareholder.email"
            type="email"
            :name="`${namePrefix}[shareholder_buyer][email]`"
            @change="clearErrors(localShareholder, ['email'])"
          />
        </be-form-group>

        <be-form-group
          :label="$t('activerecord.attributes.shareholder.phone')"
          :label-for="`phone_${index}`"
        >
          <be-tel-input
            :id="`phone_${index}`"
            v-model="localShareholder.phone"
            :name="`${namePrefix}[shareholder_buyer][phone]`"
          />
        </be-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number,
      required: false,
      default: 0,
    },

    namePrefix: {
      type: String,
      required: true,
    },

    shareholder: {
      type: Object,
      required: false,

      default: () => {
        return {
          type: "user",
          company_name: null,
          name: null,
          email: null,
          identification_number: null,
          phone: null,
          address1: null,
          address2: null,
          zipcode: null,
          city: null,
          country: null,
        };
      },
    },
  },

  emits: ["shareholder-changed"],

  data() {
    return {
      type: "user",
      localShareholder: this.shareholder,
    };
  },

  watch: {
    shareholder: {
      handler(value) {
        this.localShareholder = value;
      },

      deep: true,
    },

    localShareholder: {
      handler(value) {
        this.$emit("shareholder-changed", value);
      },

      deep: true,
    },
  },
};
</script>
