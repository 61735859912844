<template>
  <div>
    <div v-if="questions.length > 0" class="table-responsive">
      <table class="table table-hover vertical-align-middle">
        <thead>
          <tr>
            <th>
              {{ $t("views.educations.questions.table.question") }}
            </th>

            <th class="d-none d-md-table-cell col-shrink">
              {{ $t("attributes.company") }}
            </th>

            <th class="d-none d-md-table-cell col-shrink">
              {{ $t("views.educations.questions.table.created") }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <question-row
            v-for="question in questions"
            :key="question.id"
            :question="question"
            :published-questions="publishedQuestions"
            @row-removed="removeRowFromTable"
          />
        </tbody>
      </table>
    </div>

    <be-alert v-else variant="info">
      {{
        $t("components.educations.questions_table.no_questions_present_html")
      }}
    </be-alert>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import QuestionRow from "./questions/QuestionRow.vue";

export default {
  components: {
    QuestionRow,
  },

  props: {
    initialQuestions: {
      type: Array,
      required: true,
    },

    publishedQuestions: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      questions: this.cloneDeep(this.initialQuestions),
    };
  },

  methods: {
    removeRowFromTable(row) {
      let idx = this.questions.findIndex((question) => question.id == row.id);
      this.questions.splice(idx, 1);

      if (this.publishedQuestions) {
        EventBus.emit(
          "PUBLISHED_QUESTION_BADGE_UPDATED",
          this.questions.length
        );
      } else {
        EventBus.emit("QUESTION_BADGE_UPDATED", this.questions.length);
      }
    },
  },
};
</script>
