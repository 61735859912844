<template>
  <div class="row mb-2">
    <div class="col-12 col-lg-6">
      <div class="card mb-2">
        <div class="card-header">
          {{ $t("admin_panels.companies.companies.form.information") }}
        </div>

        <div class="card-body">
          <template v-if="showSuggestions && suggestionOptions.length > 0">
            <be-form-group
              :label="$t('admin_panels.companies.companies.form.fetch')"
              :error="getErrors(localCompany, 'title')"
              label-for="suggestion"
            >
              <be-form-select
                id="fetch"
                v-model="selectedSuggestion"
                :options="suggestionOptions"
                option-label="title"
                option-value="title"
              />
            </be-form-group>

            <hr />
          </template>

          <be-form-group
            :label="$t('activerecord.attributes.company.title')"
            :error="getErrors(localCompany, 'title')"
            label-for="title"
          >
            <be-form-input
              v-model="localCompany.title"
              name="title"
              @change="clearSuggestion() && clearErrors(localCompany, 'title')"
            />
          </be-form-group>

          <be-form-group
            :label="$t('activerecord.attributes.company.organization_number')"
            :error="getErrors(localCompany, 'organization_number')"
            label-for="organization_number"
          >
            <be-form-input
              v-model="localCompany.organization_number"
              name="organization_number"
              @change="
                clearSuggestion() &&
                  clearErrors(localCompany, 'organization_number')
              "
            />
          </be-form-group>

          <be-form-group
            :label="$t('activerecord.attributes.company.invoice_email')"
            :description="$t('simple_form.hints.company.invoice_email')"
            :error="getErrors(localCompany, 'invoice_email')"
            label-for="invoice_email"
          >
            <be-form-input
              v-model="localCompany.invoice_email"
              name="invoice_email"
              type="email"
              @change="clearErrors(localCompany, 'invoice_email')"
            />
          </be-form-group>

          <be-form-group
            :label="$t('activerecord.attributes.company.billing_address')"
            :error="getErrors(localCompany, 'billing_address')"
            label-for="billing_address"
          >
            <be-form-input
              v-model="localCompany.billing_address"
              name="billing_address"
              @change="clearErrors(localCompany, 'billing_address')"
            />
          </be-form-group>

          <be-form-group
            :label="$t('activerecord.attributes.company.billing_address_two')"
            :error="getErrors(localCompany, 'billing_address_two')"
            label-for="billing_address_two"
          >
            <be-form-input
              v-model="localCompany.billing_address_two"
              name="billing_address_two"
              @change="clearErrors(localCompany, 'billing_address_two')"
            />
          </be-form-group>

          <div class="row">
            <div class="col-4">
              <be-form-group
                :label="$t('activerecord.attributes.company.postcode')"
                :error="getErrors(localCompany, 'postcode')"
                label-for="postcode"
              >
                <be-form-input
                  v-model="localCompany.postcode"
                  name="postcode"
                  @change="clearErrors(localCompany, 'postcode')"
                />
              </be-form-group>
            </div>

            <div class="col-8">
              <be-form-group
                :label="$t('activerecord.attributes.company.city')"
                :error="getErrors(localCompany, 'city')"
                label-for="city"
              >
                <be-form-input
                  v-model="localCompany.city"
                  name="city"
                  @change="clearErrors(localCompany, 'city')"
                />
              </be-form-group>
            </div>
          </div>

          <be-form-group
            label-for="country"
            :label="$t('activerecord.attributes.company.country')"
            :error="getErrors(localCompany, 'country')"
          >
            <be-form-select
              id="country"
              v-model="localCompany.country"
              name="country"
              preset-options="countries"
              :state="validationState(localCompany, 'country')"
              @change="clearErrors(localCompany, 'country')"
            />
          </be-form-group>

          <be-form-group
            label-for="logo"
            :label="$t('components.companies.settings.choose_logo')"
          >
            <single-file-uploader
              v-model:remove="localCompany.remove_logo"
              :file="localCompany.logo || {}"
              :accepted-types="acceptedFileTypes"
              @file-updated="fileUpdated"
              @uploading="(value) => (uploadingFiles = value)"
            />
          </be-form-group>

          <div v-if="!persisted">
            <be-form-group
              :label="$t('simple_form.labels.company.promotional_code')"
              :error="getErrors(localCompany, 'promotional_code')"
              label-for="promotional_code"
            >
              <be-form-input
                v-model="localCompany.promotional_code"
                name="promotional_code"
                @change="clearErrors(localCompany, 'promotional_code')"
              />
            </be-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-6">
      <div class="card mb-2">
        <div class="card-header d-md-flex justify-content-between mb-2 mb-md-0">
          {{ $t("models.admin_company.administrator.other") }}
          <be-button
            v-if="persisted"
            :href="url(`/companies/${localCompany.nanoid}/administrators`)"
            variant="outline-secondary"
            size="sm"
          >
            {{ $t("models.admin_company.administrator_settings") }}
          </be-button>
        </div>

        <div class="card-body">
          <multi-select
            :items="adminPanel.users"
            :preselected-ids="userIds"
            :state="validationState(adminCompany, 'admin_panel_users')"
            value-selector="name"
            key-selector="id"
            @change="
              (userIds = $event) &&
                clearErrors(adminCompany, 'admin_panel_users')
            "
          />
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header">
          {{ $t("admin_panels.companies.companies.form.categories") }}
        </div>

        <div class="card-body">
          <multi-select
            :items="adminPanel.admin_company_categories"
            :preselected-ids="categories"
            :state="validationState(adminCompany, 'admin_company_categories')"
            value-selector="title"
            key-selector="id"
            @change="
              (categories = $event) &&
                clearErrors(adminCompany, 'admin_company_categories')
            "
          />
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <be-button variant="primary" :loading="sending" @click="submit">
          {{
            persisted ? $t("buttons.titles.save") : $t("models.company.create")
          }}
        </be-button>
      </div>
    </div>
  </div>
</template>

<script>
import SynaCompanySuggestions from "@/mixins/SynaCompanySuggestions";
import { flattenOptions } from "@/components/shared/be_form_select/utils";
import { IMAGE_TYPES } from "@/constants/file-types";

const USE_SYNA_FOR = [
  "title",
  "billing_address",
  "billing_address_two",
  "postcode",
  "city",
];

export default {
  mixins: [SynaCompanySuggestions],

  props: {
    adminCompany: {
      type: Object,
      required: true,
    },
  },

  emits: ["company-updated"],

  data() {
    return {
      sending: false,
      localCompany: this.cloneDeep(this.adminCompany.company),
      adminPanel: this.cloneDeep(this.adminCompany.admin_panel),
      categories: this.adminCompany.categories,

      userIds: this.adminCompany.memberships
        .filter((membership) => membership.directly_appointed)
        .map((membership) => membership.user.id),

      files: [],
      loading: false,
      uploadingFiles: false,
    };
  },

  computed: {
    acceptedFileTypes() {
      return IMAGE_TYPES.concat(["pdf"]);
    },

    persisted() {
      return !!this.localCompany.id;
    },

    showSuggestions() {
      return this.platformEnabled("syna_suggestions") && !this.persisted;
    },

    method() {
      return this.persisted ? "PATCH" : "POST";
    },

    formUrl() {
      return this.persisted
        ? this.url(`/companies/${this.localCompany.nanoid}`)
        : this.url("/companies");
    },
  },

  watch: {
    selectedSuggestion(title) {
      const { localCompany } = this;

      const value = flattenOptions(this.suggestionOptions).find(
        (option) => option.title === title
      );

      if (!value) {
        return;
      }

      // Change visible field if the user has set the
      // suggestion, not when unsetting.
      if (value.title) {
        USE_SYNA_FOR.forEach((field) => {
          localCompany[field] = value[field];
        });

        localCompany.organization_number = this.formatOrgNumber(value.id);
      }

      // Set hidden fields always, including on unset
      const yearStart = parseInt(value.financial_year_start) || 1;
      localCompany.financial_year_start = yearStart;
      localCompany.board_year_start = (yearStart + 4) % 12;
    },
  },

  methods: {
    async submit() {
      const data = {
        company: {
          ...this.localCompany,
          admin_company_category_ids: this.categories,
          users: this.userIds,
        },
      };
      if (
        this.localCompany.remove_logo ||
        this.localCompany.logo?.upload_state !== "added"
      ) {
        delete data.company.logo;
      }
      try {
        this.sending = true;

        const response = this.persisted
          ? await axios.patch(this.formUrl, data)
          : await axios.post(this.formUrl, data);

        this.$emit("company-updated", response.data);
        window.location.href = this.url("/companies");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.localCompany.errors = error.response.data.errors;
        } else {
          this.handleError(error);
        }
        this.sending = false;
      }
    },

    fileUpdated(file) {
      this.localCompany.logo = file;
    },
  },
};
</script>
