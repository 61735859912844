<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4">
        <be-form-group
          label-for="renewal-type"
          :label="$t('activerecord.attributes.contract.renewal_type')"
        >
          <be-form-select
            id="renewal-type"
            v-model="renewalType"
            name="contract[contract_type_attributes][renewal_type]"
            :options="formattedRenewalTypes"
          />
        </be-form-group>
      </div>
    </div>

    <no-renewal
      v-if="renewalType == 'no_renewal'"
      :end-date="endDate"
      :contract="contract"
      :reminders="formattedReminders"
      label="views.companies.contracts.metadata.notify_me"
    />

    <automatic-renewal
      v-else-if="renewalType == 'automatic'"
      :contract="contract"
      :reminders="formattedReminders"
    />

    <manual-renewal
      v-else-if="renewalType == 'manual'"
      :end-date="endDate"
      :contract="contract"
      :reminders="formattedReminders"
      label="views.companies.contracts.metadata.notify_me"
    />
  </div>
</template>

<script>
import NoRenewal from "./NoRenewal.vue";
import AutomaticRenewal from "./AutomaticRenewal.vue";
import ManualRenewal from "./ManualRenewal.vue";

export default {
  components: {
    NoRenewal,
    AutomaticRenewal,
    ManualRenewal,
  },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    endDate: {
      type: Date,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      renewalType: null,
    };
  },

  computed: {
    formattedRenewalTypes() {
      return this.$config.CONTRACT.RENEWAL_TYPES.map((renewalType) => {
        return {
          text: this.$i18n.t(
            `models.contract.types.renewal_type.${renewalType}`
          ),

          value: renewalType,
        };
      });
    },

    formattedReminders() {
      return this.contract.reminders.map((reminder) => ({
        text: reminder[0],
        value: reminder[1],
      }));
    },
  },

  mounted() {
    this.renewalType =
      this.contract.contract_type.renewal_type ||
      this.formattedRenewalTypes[0].value;
  },
};
</script>
