<template>
  <div v-if="!inEdit" class="row">
    <div class="col-12 col-lg-auto col-xl-3">
      <be-form-group
        :label="
          $t('components.shareholder_registers.standard_split.split_ratio')
        "
        label-for="split_ratio"
        :error="getErrors(action, ['split_ratio_first', 'split_ratio_last'])"
        :description="
          $t(
            'components.shareholder_registers.standard_split.split_quota_information'
          )
        "
        required
      >
        <be-input-group>
          <be-form-input
            id="split_ratio"
            v-model="splitRatioFirst"
            type="number"
            min="1"
            name="shareholder_register_action[split_ratio_first]"
            @change="clearErrors(action, ['split_ratio_first'])"
          />

          <be-input-group-separator is-text>:</be-input-group-separator>

          <be-form-input
            v-model="splitRatioLast"
            type="number"
            min="1"
            name="shareholder_register_action[split_ratio_last]"
            @change="clearErrors(action, ['split_ratio_last'])"
          />
        </be-input-group>
      </be-form-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },

    inEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      splitRatioFirst: isNaN(this.action.split_ratio_first)
        ? null
        : this.action.split_ratio_first,

      splitRatioLast: isNaN(this.action.split_ratio_last)
        ? null
        : this.action.split_ratio_last,
    };
  },
};
</script>
