<template>
  <be-table :fields="fields" :items="localAdminCompanies">
    <template #created_at="{ item }">
      <p v-if="item.created_at">
        {{ $d(new Date(item.created_at), "date") }}
      </p>
    </template>

    <template #actions="{ item }">
      <be-button
        v-if="item.id === null"
        v-be-tooltip="{
          disabled: canConnectToThisAdminPanel(item),
          title: $t(
            'components.admin_panel.companies.invitations_table.already_have_admin_panel'
          ),
        }"
        variant="primary"
        :disabled="connectedToOtherAdminPanel(item)"
        inline
        @click="addCompany(item)"
      >
        {{ $t("buttons.titles.add_more") }}
      </be-button>

      <be-button
        v-else
        :href="url(`/companies/${item.company.nanoid}`)"
        variant="outline-primary"
        inline
      >
        {{ $t("buttons.titles.show") }}
      </be-button>
    </template>
  </be-table>
</template>

<script>
export default {
  props: {
    adminCompanies: {
      type: Array,
      required: true,
    },

    adminPanel: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      localAdminCompanies: this.cloneDeep(this.adminCompanies),
    };
  },

  computed: {
    fields() {
      return [
        {
          key: "title",
          label: this.$t("activerecord.attributes.company.title"),
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) => item.company.title,
        },
        {
          key: "organization_number",
          label: this.$t("activerecord.attributes.company.organization_number"),
          class: "col-shrink",
          sortable: true,
          sortByFormatted: true,

          formatter: (value, key, item) => item.company.organization_number,
        },
        {
          key: "created_at",
          label: this.$t("activerecord.attributes.admin_company.created_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          class: "col-shrink text-center",
        },
      ];
    },
  },

  methods: {
    // admin_panel_id === null when connected to other admin panel
    // admin_panel_id === adminPanel.id when allowed to connect or already connected to this admin panel
    canConnectToThisAdminPanel(adminCompany) {
      return adminCompany.admin_panel_id === this.adminPanel.id;
    },

    connectedToOtherAdminPanel(adminCompany) {
      return adminCompany.admin_panel_id === null;
    },

    async addCompany(adminCompany) {
      try {
        const response = await axios.post(
          `/admin_panel/${this.adminPanel.id}/companies/invitations`,
          {
            admin_company: {
              company_id: adminCompany.company.id,
            },
          }
        );
        const index = this.localAdminCompanies.findIndex(
          (existing) => existing.company.id === adminCompany.company.id
        );
        if (index > -1) {
          this.localAdminCompanies[index] = response.data;
        } else {
          this.localAdminCompanies.push(response.data);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
