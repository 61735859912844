<template>
  <div>
    <div v-if="actionsPresent" class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              {{
                $t(
                  "activerecord.attributes.shareholder_register_action.action_type"
                )
              }}
            </th>

            <th class="col-shrink"></th>

            <th
              data='{default_order: "desc", custom_sort: "true"}'
              class="col-shrink"
            >
              {{
                $t(
                  "activerecord.attributes.shareholder_register_action.action_date"
                )
              }}
            </th>

            <th class="col-shrink"></th>
          </tr>
        </thead>

        <tbody>
          <action-row
            v-for="action in reversedActions"
            :key="action.id"
            :action="action"
          />
        </tbody>
      </table>
    </div>

    <div v-else>
      <h2>
        {{ $t("components.shareholder_registers.transaction_history.title") }}
      </h2>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="mb-3">
            {{
              $t(
                "components.shareholder_registers.transaction_history.description"
              )
            }}
          </div>

          <a
            v-if="allowedToCreateAction"
            :href="url('/shareholder_register/actions/new')"
            class="btn btn-outline-primary btn-block btn-md-inline-block"
          >
            {{ $t("models.shareholder_register_action.new") }}
          </a>
        </div>

        <div class="d-none d-lg-block col-lg-6">
          <be-img
            :src="
              imageSrc(
                'onboarding/intro/shareholder_register/transaction_history.png'
              )
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionRow from "../actions/ActionRow.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ActionRow,
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      reversedActions: "shareholder_register/reversedActions",
    }),

    allowedToCreateAction() {
      return this.shareholderRegister.policy.edit;
    },

    actionsPresent() {
      return (
        this.shareholderRegister.shareholder_register_actions &&
        this.shareholderRegister.shareholder_register_actions.length > 0
      );
    },
  },
};
</script>
