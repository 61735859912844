<template>
  <div
    :class="{
      'self-post': message.user == $currentUser,
    }"
  >
    <div v-show="message.show_edit_area">
      <be-form-group>
        <text-editor
          v-if="!message.deleted_at"
          v-model:content="localMessage.body"
          name="message[body]"
          class="mb-2"
        />
      </be-form-group>

      <be-button variant="outline-secondary" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button
        variant="primary"
        :disabled="localMessage.body.length == 0 || loading"
        @click="updateMessage"
      >
        {{ saveButtonTitle }}
      </be-button>
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <template v-if="!message.show_edit_area">
      <template v-if="!message.open">
        <div v-dompurify-html="previewMessage" class="mb-1" />

        <be-link
          v-if="showReadLinks"
          class="text-decoration-none"
          @click="togglePreview"
        >
          {{ $t("buttons.titles.read_more") }}
        </be-link>
      </template>

      <template v-else>
        <div
          v-dompurify-html="messageWithInnerHTML"
          class="ql-editor ql-viewer p-0"
        />

        <be-link
          v-if="showReadLinks"
          class="text-decoration-none"
          @click="togglePreview"
        >
          {{ $t("buttons.titles.read_less") }}
        </be-link>
      </template>
    </template>
    <!-- eslint-enable -->
  </div>
</template>

<script>
const MESSAGE_PREVIEW_LENGTH = 125;

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["update", "preview-toggled", "edit-cancelled"],

  data() {
    return {
      localMessage: this.cloneDeep(this.message),
      MESSAGE_PREVIEW_LENGTH: MESSAGE_PREVIEW_LENGTH,
    };
  },

  computed: {
    containsNewlines() {
      return (
        this.messageWithInnerHTML.includes("<br>") ||
        this.messageWithInnerHTML.includes("<p>")
      );
    },

    messageWithoutHTML() {
      // Removes all br and p tags and replaces them with a space
      let withoutNewlines = this.message.body.replace(
        /<(br|p|\/br|\/p)>/g,
        " "
      );

      // Removes all HTML tags
      return withoutNewlines.replace(/<(.*?)>/g, "");
    },

    messageWithInnerHTML() {
      // Removes outer wrapper <p> that gets added when
      // creating the message in the text editor
      return this.message.body.replace(/^<p>|<\/p>$/g, "");
    },

    previewMessage() {
      const shortMessage = this.messageWithoutHTML.slice(
        0,
        MESSAGE_PREVIEW_LENGTH
      );

      if (this.showReadLinks) {
        return shortMessage + "...";
      }

      return shortMessage;
    },

    saveButtonTitle() {
      if (this.loading) {
        return this.$i18n.t("buttons.states.saving");
      }

      return this.$i18n.t("buttons.titles.save");
    },

    showReadLinks() {
      return (
        this.message.body.length > MESSAGE_PREVIEW_LENGTH ||
        this.containsNewlines
      );
    },
  },

  methods: {
    cancel() {
      this.localMessage.body = this.message.body;
      this.$emit("edit-cancelled");
    },

    togglePreview() {
      this.$emit("preview-toggled");
    },

    updateMessage() {
      this.$emit("update", this.localMessage);
    },
  },
};
</script>
