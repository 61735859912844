<template>
  <div>
    <div class="card">
      <div class="card-header">
        {{ $t("components.meetings.minutes_review.events.title") }}
      </div>

      <div class="card-body">
        <ul>
          <li
            v-for="event in events"
            :key="`event-${event.key}-${event.timestamp}`"
          >
            {{ $d(event.timestamp, "humanReadableWithTime") }} -
            {{ event.body }}
          </li>
        </ul>
      </div>
    </div>

    <div class="accordion">
      <div class="card my-2 show">
        <div class="card-header p-3 px-md-4" @click="toggleComments">
          <div class="row align-items-center">
            <div class="col-12 col-md">
              <div class="row no-gutters">
                <div class="col-auto">
                  <div class="text-reset text-decoration-none mr-3">
                    <span class="text-primary">
                      <i
                        class="fas"
                        :class="{
                          'fa-chevron-down': commentsOpen,
                          'fa-chevron-right': !commentsOpen,
                        }"
                      />
                    </span>
                  </div>
                </div>

                <div class="col">
                  {{ $t("activerecord.models.comments_request.other") }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-show="commentsOpen">
          <div class="card-body">
            <meeting-comments-table
              :meeting="meeting"
              :minutes="material"
              :attendances="attendances"
              :comments-requests="preReviewCommentsRequests"
              mode="review-page-comments"
            />
          </div>

          <div
            v-if="commentsOpen"
            class="card-footer d-md-flex justify-content-end"
          >
            <be-button variant="outline-primary" @click="onCommentRequest">
              {{ $t("models.material.minutes.request_comments") }}
            </be-button>
          </div>
        </div>
      </div>

      <div class="card mb-2 show">
        <div class="card-header p-3 px-md-4" @click="toggleReviews">
          <div class="row align-items-center">
            <div class="col-12 col-md">
              <div class="row no-gutters">
                <div class="col-auto">
                  <div class="text-reset text-decoration-none mr-3">
                    <span class="text-primary">
                      <i
                        class="fas"
                        :class="{
                          'fa-chevron-down': reviewsOpen,
                          'fa-chevron-right': !reviewsOpen,
                        }"
                      />
                    </span>
                  </div>
                </div>

                <div class="col">
                  {{ $t("components.meetings.minutes_review.reviews") }}
                </div>
              </div>
            </div>

            <div class="col-auto">
              <template v-if="reviewCommentsRequests.length > 0">
                {{ reviewStatus["approve"] || 0 }}
                <i class="fal fa-check text-success mr-2" />
                {{ reviewStatus["request_changes"] || 0 }}
                <i class="fal fa-times text-danger mr-2" />
              </template>
            </div>
          </div>
        </div>

        <div v-show="reviewsOpen">
          <div class="card-body">
            <meeting-comments-table
              :meeting="meeting"
              :minutes="material"
              :attendances="attendances"
              :comments-requests="commentsRequests"
              mode="review-page-reviews"
            />
          </div>

          <div
            v-if="inReview"
            class="card-footer d-md-flex justify-content-end"
          >
            <be-button
              v-if="!material.secretary_done_at"
              variant="primary"
              :disabled="!hasSecretaryAndReviewers"
              @click="minutesDone"
            >
              {{ $t("components.meetings.material.editor.mark_minutes_done") }}
            </be-button>

            <be-button
              v-if="reviewCommentsRequests.length == 0"
              key="send-to-reviewer"
              v-be-tooltip="{
                title: $t(
                  'components.meetings.minutes_review.tooltips.minutes_not_marked_as_done'
                ),
                disabled: !!material.secretary_done_at,
              }"
              variant="primary"
              :disabled="
                !material.secretary_done_at || !hasSecretaryAndReviewers
              "
              @click="onReviewRequest"
            >
              {{ $t("models.material.minutes.send_to_reviewer") }}
            </be-button>

            <be-button
              v-else-if="material.secretary_done_at"
              variant="outline-primary"
              :disabled="!hasSecretaryAndReviewers"
              @click="onReviewRequest"
            >
              {{ $t("models.material.minutes.send_to_reviewer_again") }}
            </be-button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!material.sealed" class="card">
      <div class="card-body d-md-flex justify-content-end">
        <be-button
          v-be-tooltip="{
            title: markAsReviewedTooltip,
            disabled: material.secretary_done_at && hasSecretaryAndReviewers,
          }"
          variant="outline-secondary"
          :disabled="!material.secretary_done_at || !hasSecretaryAndReviewers"
          icon="fa-envelope"
          @click="$beModal.show('confirm-mark-as-reviewed')"
        >
          {{ $t("models.material.minutes.mark_as_reviewed") }}
        </be-button>
      </div>
    </div>

    <comments-request-modal
      :attendances="attendancesWithUsers"
      :comments-requests="commentsRequests"
      :send-review="false"
      :mode="requestMode"
    />

    <confirm-modal
      id="confirm-mark-as-reviewed"
      :title="$t('models.material.minutes.confirm_mark_as_reviewed')"
      :ok-title="
        $t('models.material.minutes.confirm_mark_as_reviewed_button_text')
      "
      :cancel-disabled="markingAsReviewed"
      stay-open-after-confirm
      @confirm="markAsReviewed"
      @show="
        informBoardMembers =
          meeting.inform_board_members_of_minutes_review_completion
      "
    >
      <p>
        {{ $t("models.material.minutes.confirm_mark_as_reviewed_description") }}
      </p>

      <be-form-group class="mb-0">
        <be-form-checkbox v-model="informBoardMembers">
          {{
            $t(
              "components.meetings.minutes_review.inform_board_members_review_is_complete"
            )
          }}
        </be-form-checkbox>
      </be-form-group>
    </confirm-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import sortBy from "lodash/sortBy";

import materialType from "./materialState";
import MaterialMixin from "@/mixins/meetings/material";

import CommentsRequestModal from "./CommentsRequestModal.vue";
import MeetingCommentsTable from "@/components/meetings/tabs/components/MeetingCommentsTable.vue";

export default {
  components: {
    CommentsRequestModal,
    MeetingCommentsTable,
  },

  mixins: [materialType, MaterialMixin],

  props: {
    initialMeeting: {
      type: Object,
      required: true,
    },

    initialMaterial: {
      type: Object,
      required: true,
    },

    initialAttendances: {
      type: Array,
      required: true,
    },

    initialCommentsRequests: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      requestMode: "comments",
      commentsOpen: true,
      reviewsOpen: true,
      markingAsReviewed: false,

      informBoardMembers:
        this.initialMeeting.inform_board_members_of_minutes_review_completion,
    };
  },

  computed: {
    ...mapGetters("material", ["material", "meeting", "lastItemUpdatedAt"]),

    ...mapGetters({
      attendances: "attendances/attendances",
      attendancesWithUsers: "attendances/attendancesWithUsers",
      hasLoadedAttendances: "attendances/hasLoaded",
      hasSecretaryAndReviewers: "attendances/hasSecretaryAndReviewers",
    }),

    reviewers() {
      return this.attendances.filter((attendance) => attendance.reviewer);
    },

    reviewStatus() {
      const results = {
        pending: 0,
        approve: 0,
        request_changes: 0,
      };

      this.reviewers.forEach((attendance) => {
        results[attendance.review_level] += 1;
      });

      return results;
    },

    secretaryDoneAt() {
      if (this.material.secretary_done_at) {
        return new Date(this.material.secretary_done_at);
      }

      return null;
    },

    minutesReviewStarted() {
      return this.meeting.process.active_state === "review";
    },

    commentsRequests() {
      return this.getCommentsRequests(this.meeting);
    },

    reviewCommentsRequests() {
      return this.commentsRequests.filter((request) => !request.pre_review);
    },

    preReviewCommentsRequests() {
      return this.commentsRequests.filter((request) => request.pre_review);
    },

    markAsReviewedTooltip() {
      if (!this.hasSecretaryAndReviewers) {
        return this.$t(
          "components.meetings.minutes_review.tooltips.no_secretary_or_reviewers"
        );
      }

      if (!this.meeting.secretary_done_at) {
        return this.$t(
          "components.meetings.minutes_review.tooltips.minutes_not_marked_as_done"
        );
      }

      return "";
    },

    events() {
      const data = [];

      if (this.material.created_at) {
        data.push({
          timestamp: this.parseDate(this.material.created_at),
          key: "minutes_created",

          body: this.$t(
            "components.meetings.minutes_review.events.minutes_created"
          ),
        });
      }

      if (this.parseDate(this.material.created_at) !== this.lastItemUpdatedAt) {
        data.push({
          timestamp: this.lastItemUpdatedAt,
          key: "minutes_updated",

          body: this.$t(
            "components.meetings.minutes_review.events.minutes_updated"
          ),
        });
      }

      if (this.material.secretary_done_at) {
        data.push({
          timestamp: this.parseDate(this.material.secretary_done_at),
          key: "minutes_done",

          body: this.$t(
            "components.meetings.minutes_review.events.minutes_done"
          ),
        });
      }
      if (this.material.approved_at) {
        data.push({
          timestamp: this.parseDate(this.material.approved_at),
          key: "approved_at",

          body: this.$t(
            "components.meetings.minutes_review.events.minutes_approved"
          ),
        });
      }

      this.commentsRequests.forEach((request) => {
        const requestedFrom = [];
        request.comments_request_receivers.forEach((receiver) => {
          const attendance = this.attendances.find(
            (attendance) => attendance.id === receiver.attendance_id
          );
          const user = this.$store.getters["company/userById"](
            attendance?.user_id
          );
          if (user) {
            requestedFrom.push(user.name);
          }
        });

        if (request.pre_review) {
          data.push({
            timestamp: this.parseDate(request.created_at),
            key: `comments_requested_${request.id}`,

            body: this.$t(
              "components.meetings.minutes_review.events.comments_requested_w_recipients",
              {
                recipients: requestedFrom.join(", "),
              }
            ),
          });
        } else {
          data.push({
            timestamp: this.parseDate(request.created_at),
            key: `review_requested_${request.id}`,

            body: this.$t(
              "components.meetings.minutes_review.events.review_requested_w_recipients",
              {
                recipients: requestedFrom.join(", "),
              }
            ),
          });
        }
      });

      this.attendances.forEach((attendance) => {
        // Eventually we want to avoid looking at `attendance.commented_at` and only use
        // `done_commenting_at`. Lets keep both until 2023-12-31.
        const markedDoneCommenting =
          attendance.done_commenting_at &&
          attendance.review_level === "pending";
        if (
          markedDoneCommenting ||
          (attendance.commented_at && !attendance.reviewer)
        ) {
          const user = this.$store.getters["company/userById"](
            attendance?.user_id
          );
          let key =
            "components.meetings.minutes_review.events.commented_w_name";
          if (markedDoneCommenting) {
            key =
              "components.meetings.minutes_review.events.done_commenting_w_name";
          }
          if (user) {
            data.push({
              key: `commented_${attendance.id}`,

              timestamp: this.parseDate(
                attendance.done_commenting_at || attendance.commented_at
              ),

              body: this.$t(key, {
                name: user.name,
              }),
            });
          }
        }

        if (
          attendance.reviewer &&
          attendance.done_commenting_at &&
          attendance.review_level !== "pending"
        ) {
          const user = this.$store.getters["company/userById"](
            attendance?.user_id
          );
          if (user) {
            data.push({
              timestamp: this.parseDate(attendance.done_commenting_at),
              key: `reviewed_${attendance.id}`,

              body: this.$t(
                "components.meetings.minutes_review.events.reviewed_w_name_w_review_level",
                {
                  name: user.name,

                  review_level: this.$t(
                    `models.attendance.review_levels.${attendance.review_level}`
                  ),
                }
              ),
            });
          }
        }
      });

      return sortBy(data, "timestamp");
    },

    inReview() {
      return ["minutes", "review"].includes(this.meeting.process.active_state);
    },
  },

  async created() {
    this.loadMeeting(this.initialMeeting);
    this.loadItemsAndMaterial(this.initialMaterial);

    this.loadAttendances(this.initialAttendances);
    this.loadCommentsRequestsForMeeting({
      meetingId: this.meeting.id,

      commentsRequestsData: {
        data: this.initialCommentsRequests,
        status: "loaded",
      },
    });
  },

  methods: {
    ...mapActions("material", ["markMinutesAsDone"]),

    ...mapMutations("material", ["loadItemsAndMaterial", "loadMeeting"]),
    ...mapMutations("meetings", ["loadCommentsRequestsForMeeting"]),
    ...mapMutations("attendances", ["loadAttendances"]),

    async minutesDone() {
      const response = await this.markMinutesAsDone();
      if (!response) {
        console.error("Could not save material");
      }
    },

    async onCommentRequest() {
      this.requestMode = "comments";
      this.$beModal.show("comments-request-modal");
    },

    async onReviewRequest() {
      this.requestMode = "review";
      this.$beModal.show("comments-request-modal");
    },

    async markAsReviewed() {
      this.markingAsReviewed = true;
      try {
        await axios.post(`${this.meeting.paths.base}/minutes/locking`, {
          meeting: {
            inform_board_members_of_minutes_review_completion:
              this.informBoardMembers,
          },
        });
        window.location.replace(`${this.meeting.paths.base}/minutes`);
      } catch (error) {
        // Check if it was already locked?
        if (error.response?.status === 409) {
          window.location.replace(`${this.meeting.paths.base}/minutes`);
        } else {
          this.markingAsReviewed = false;
          this.handleError(error);
        }
      }
    },

    toggleComments() {
      this.commentsOpen = !this.commentsOpen;
    },

    toggleReviews() {
      this.reviewsOpen = !this.reviewsOpen;
    },
  },
};
</script>
