<template>
  <div>
    <div
      v-for="(
        payment_method, payment_method_name
      ) in transaction_instructions_fields"
      :key="payment_method.id"
    >
      <div
        v-for="(payment_detail, payment_detail_name) in payment_method"
        :key="payment_detail.id"
      >
        <div
          v-if="
            payment_method_name ===
            form.payment_plan[0].inquiry_receiver.transaction_type
          "
        >
          <transaction-instructions-input
            :payment-method="payment_method_name"
            :payment-detail="payment_detail_name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import TransactionInstructionsInput from "@/components/inquiry_payments/components/transactions/TransactionInstructionsInput.vue";

export default {
  components: {
    TransactionInstructionsInput,
  },

  mixins: [CreateInquiryMixin],
};
</script>
