<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>{{ $t("activerecord.models.document.one") }}</th>

          <th class="col-shrink">
            {{ $t("components.documents.uploaded") }}
          </th>

          <th class="col-shrink" />

          <th class="col-shrink" />
        </tr>
      </thead>

      <tbody>
        <compliance-document-row
          v-for="initialDocument in localDocuments"
          :key="`document-${initialDocument.id}`"
          :initial-document="initialDocument"
          :created-at-column="true"
          @share-document="handleShare"
        />
      </tbody>
    </table>

    <inform-user-modal
      :document="shareDocument"
      :form-url="url('compliances/send_reminder')"
      exclude-current-user
      show-automatically
      @reset="() => (shareDocument = {})"
    />
  </div>
</template>

<script>
import ComplianceDocumentRow from "./ComplianceDocumentRow.vue";
import InformUserModal from "@/components/shared/InformUserModal.vue";

export default {
  components: { ComplianceDocumentRow, InformUserModal },

  props: {
    documents: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      localDocuments: this.cloneDeep(this.documents),
      shareDocument: {},
    };
  },

  methods: {
    handleShare(document) {
      this.shareDocument = document;
    },
  },
};
</script>
