<template>
  <div>
    <shareholder-register-tabs
      v-if="shareholderRegister"
      :shareholder-register="shareholderRegister"
      :processing-started="processingStarted"
      :initial-tab="initialTab"
      :transactions="transactions"
    />

    <div v-else class="card">
      <div class="card-header">
        <h5 class="card-title">
          {{ $t("activerecord.models.shareholder_register.one") }}
        </h5>
      </div>

      <div class="card-body">
        <main-introduction :processing-started="processingStarted" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MainIntroduction from "./MainIntroduction.vue";
import ShareholderRegisterTabs from "./ShareholderRegisterTabs.vue";

export default {
  components: {
    MainIntroduction,
    ShareholderRegisterTabs,
  },

  props: {
    initialShareholderRegister: {
      type: Object,
      required: false,
      default: null,
    },

    processingStarted: {
      type: Boolean,
      required: false,
      default: false,
    },

    initialTab: {
      type: String,
      required: false,
      default: "shareholder-list",
    },
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
      transactions: "shareholder_register/transactions",
    }),
  },

  mounted() {
    if (this.initialShareholderRegister) {
      this.$store.commit(
        "shareholder_register/loadRegister",
        this.initialShareholderRegister
      );
    }
  },
};
</script>
