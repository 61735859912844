<template>
  <be-table
    v-if="ownerships.length > 0"
    :items="formattedOwnerships"
    :fields="fields"
  >
    <template #parent_company="{ item }">
      <a v-if="item.parent_company.url" :href="item.parent_company.url">{{
        item.parent_company.title
      }}</a>

      <span v-else>{{ item.parent_company.title }}</span>
    </template>

    <template #child_company="{ item }">
      <a v-if="item.child_company.url" :href="item.child_company.url">{{
        item.child_company.title
      }}</a>

      <span v-else>{{ item.child_company.title }}</span>
    </template>

    <template #start_at="{ item }">
      <span v-if="item.start_at">{{ item.start_at }}</span>

      <span v-else class="text-muted">-</span>
    </template>

    <template #end_at="{ item }">
      <span v-if="item.end_at">{{ item.end_at }}</span>

      <span v-else class="text-muted">-</span>
    </template>

    <template #owned_shares="{ item }">
      <span v-if="item.owned_shares">{{ item.owned_shares }}</span>

      <span v-else class="text-muted">-</span>
    </template>

    <template #owned_votes="{ item }">
      <span v-if="item.owned_votes">{{ item.owned_votes }}</span>

      <span v-else class="text-muted">-</span>
    </template>

    <template #ownership_type="{ item }">
      <span v-if="item.ownership_type">{{
        $t(
          `models.company_group_ownership_state.ownership_type.${item.ownership_type}`
        )
      }}</span>

      <span v-else class="text-muted">-</span>
    </template>
  </be-table>
</template>

<script>
export default {
  props: {
    ownerships: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  computed: {
    fields() {
      return [
        {
          key: "parent_company",

          label: this.$t(
            "activerecord.attributes.company_group_ownership.parent_company"
          ),
        },

        {
          key: "child_company",

          label: this.$t(
            "activerecord.attributes.company_group_ownership.child_company"
          ),
        },

        {
          key: "start_at",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.start_at"
          ),
        },

        {
          key: "end_at",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.end_at"
          ),
        },

        {
          key: "owned_shares",

          label: this.$t(
            "models.company_group_ownership_state.owned_share_percentage"
          ),

          class: "text-right",
        },

        {
          key: "owned_votes",

          label: this.$t(
            "models.company_group_ownership_state.owned_voting_percentage"
          ),

          class: "text-right",
        },

        {
          key: "ownership_type",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.ownership_type"
          ),

          class: "col-shrink",
        },
      ];
    },

    formattedOwnerships() {
      return this.ownerships
        .map((ownership) =>
          ownership.states.length > 0
            ? this.formatStates(ownership)
            : this.formatOwnership(ownership)
        )
        .flat();
    },
  },

  methods: {
    formatStates(ownership) {
      return ownership.states.map((state) => ({
        ...this.formatOwnership(ownership),
        state_id: state.id,
        start_at: this.formatDate(state.start_at),
        end_at: this.formatDate(state.end_at),
        owned_shares: this.formatPercentage(state.shares, state.owned_shares),
        owned_votes: this.formatPercentage(state.votes, state.owned_votes),
        ownership_type: state.ownership_type,
      }));
    },

    formatOwnership: (ownership) => ({
      parent_company: {
        title: ownership.parent_company_title,
        url: ownership.parent_company_url,
      },

      child_company: {
        title: ownership.child_company_title,
        url: ownership.child_company_url,
      },

      ownership_id: ownership.id,
    }),

    formatPercentage(total, num) {
      if (!total || !num) return false;
      return BigNumber((num / total) * 100).decimalPlaces(2);
    },

    formatDate(value) {
      return value ? this.$i18n.d(new Date(value), "date") : false;
    },
  },
};
</script>
