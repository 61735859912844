<template>
  <div class="row">
    <div
      v-for="(codes, index) in slicedCodes"
      :key="`codes-${index}`"
      class="col-12 col-xl-6"
    >
      <ul class="text-monospace m-0 list-unstyled text-center">
        <li
          v-for="(code, i) in codes"
          :key="`code-${i}`"
          class="h5 font-weight-normal text-monospace py-2 px-3 border rounded border-light"
        >
          {{ code }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import chunk from "lodash/chunk";

export default {
  props: {
    recoveryCodes: {
      required: true,
      type: Array,
    },
  },

  computed: {
    slicedCodes() {
      return chunk(
        this.recoveryCodes,
        Math.round(this.recoveryCodes.length / 2.0)
      );
    },
  },
};
</script>
