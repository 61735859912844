<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-4">
        <contract-termination-date :contract="contract" />
      </div>
    </div>
  </div>
</template>

<script>
import ContractTerminationDate from "./dates/ContractTerminationDate.vue";

export default {
  components: { ContractTerminationDate },

  props: {
    contract: {
      type: Object,
      required: true,
    },
  },
};
</script>
