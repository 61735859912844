<template>
  <div>
    <be-modal
      :id="modalId"
      :title="modalTitle"
      :ok-title="okTitle"
      :ok-disabled="loading"
      @show="setDefault"
      @ok="saveTask"
      @cancel="resetTask"
    >
      <be-form-group
        :label="$t('simple_form.labels.tasks.description')"
        label-for="task-description"
        :error="getErrors(localTask, 'description')"
        required
      >
        <be-form-textarea
          id="task-description"
          v-model="localTask.description"
          :disabled="shouldDisable('description')"
          :state="validationState(localTask, 'description')"
          rows="3"
          autofocus
          @change="clearErrors(localTask, 'description')"
        />
      </be-form-group>

      <be-form-group
        label-for="task-datepicker"
        :label="$t('activerecord.attributes.task.due_at')"
        :disabled="shouldDisable('due_at')"
      >
        <be-form-datepicker
          v-model="localTask.due_at"
          :disabled="shouldDisable('due_at')"
          :min-date="new Date()"
        />
      </be-form-group>

      <be-form-group label-for="task-user" :label="$t('tasks.choose_user')">
        <be-form-select
          id="task-user"
          v-model="localTask.user_id"
          :options="selectableUsers"
          :disabled="shouldDisable('user_id')"
        />
      </be-form-group>

      <be-form-group
        v-if="
          platformEnabledAndSubscribed('projects') && selectedProject == null
        "
        label-for="task-project"
        :label="$t('activerecord.models.project.one')"
      >
        <be-form-select
          id="task-project"
          v-model="localTask.project_id"
          :options="projectOptions"
        />
      </be-form-group>

      <be-form-checkbox
        id="task-send-message"
        v-model="localTask.send_message"
        :description="$t('simple_form.hints.task.send_message')"
      >
        {{ $t("simple_form.labels.task.send_message") }}
      </be-form-checkbox>
    </be-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    modalId: {
      type: String,
      required: false,
      default: "new-task",
    },

    task: {
      type: Object,
      required: true,
    },

    projects: {
      type: Array,
      required: false,
      default: () => [],
    },

    selectedProject: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      localTask: this.cloneDeep(this.task),
    };
  },

  computed: {
    ...mapGetters("company", ["users"]),

    modalTitle() {
      return this.modalId == "new-task"
        ? this.$i18n.t("models.task.new")
        : this.$i18n.t("models.task.edit");
    },

    okTitle() {
      if (this.loading) {
        return this.$i18n.t("buttons.states.saving");
      }

      return this.modalId == "new-task"
        ? this.$i18n.t("models.task.create")
        : this.$i18n.t("buttons.titles.save");
    },

    selectableUsers() {
      if (!this.users) {
        return;
      }

      let users = this.users.map((user) => {
        return {
          text: user.name,
          value: user.id,
          disabled: this.shouldDisable("user_id"),
        };
      });

      if (!this.shouldDisable("user_id") && !this.localTask.user_id) {
        users.unshift({
          text: this.$t("tasks.choose_user"),
          value: null,
          disabled: true,
        });
      }

      return users;
    },

    projectOptions() {
      return [
        {
          value: null,
          text: "",
        },
        ...this.projects
          .map((project) => ({
            text: project.display_title,
            value: project.id,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
      ];
    },
  },

  watch: {
    task(newVal) {
      this.localTask = this.cloneDeep(newVal);
    },
  },

  mounted() {
    if (this.selectedProject) {
      this.localTask.project_id = this.selectedProject.id;
    }
  },

  methods: {
    setDefault() {
      if (!this.localTask.id) {
        this.localTask.user_id = this.selectableUsers.filter(
          (option) => !option.disabled
        )[0].value;
      }
    },

    async saveTask(beModalEvent) {
      beModalEvent.preventDefault();
      this.loading = true;

      try {
        if (this.localTask.id) {
          await this.$store.dispatch("tasks/updateTask", this.localTask);
        } else {
          await this.$store.dispatch("tasks/createTask", this.localTask);
        }
        this.localTask = this.cloneDeep(this.task);
        beModalEvent.vueTarget.hide();
      } catch (error) {
        if (error.response && error.response.status === 422) {
          this.localTask = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.loading = false;
      }
    },

    shouldDisable(attribute) {
      return !this.localTask.policy.permitted_attributes_for_update.includes(
        attribute
      );
    },

    resetTask() {
      this.localTask = this.cloneDeep(this.task);
    },
  },
};
</script>
