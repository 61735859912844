<template>
  <div class="card bg-light">
    <div class="card-body">
      <div class="row no-gutters">
        <h5 class="mb-2">
          <slot name="title"></slot>
        </h5>

        <slot name="text"></slot>

        <slot name="action-button"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
