<template>
  <be-modal
    id="add-custom-fields"
    :title="$t('models.custom_field.new')"
    :ok-title="$t('models.custom_field.create')"
    @ok="saveCustomField"
  >
    <be-form-group
      label-for="title"
      :label="$t('components.custom_fields.title')"
      :error="getErrors(customField, ['title'])"
      required
    >
      <be-form-input
        id="title"
        v-model="customField.title"
        name="custom_field[title]"
        required
        @change="clearErrors(customField, ['title'])"
      />
    </be-form-group>

    <be-form-group
      :label="$t('components.custom_fields.field_type')"
      label-for="field_type"
    >
      <be-form-select
        id="field_type"
        v-model="customField.field_type"
        name="custom_field[field_type]"
        :options="formattedFieldTypes"
      />
    </be-form-group>

    <be-form-group
      :label="$t('components.custom_fields.default_value')"
      label-for="default_value"
    >
      <be-form-input
        id="default_value"
        v-model="customField.default_value"
        name="custom_field[default_value]"
      />
    </be-form-group>

    <be-form-group>
      <be-form-checkbox v-model="customField.marked_as_default">
        {{ $t("components.custom_fields.marked_as_default") }}
      </be-form-checkbox>
    </be-form-group>

    <input :value="objectType" name="custom_field[owner_type]" type="hidden" />
  </be-modal>
</template>

<script>
export default {
  props: {
    object: {
      type: Object,
      required: true,
    },

    objectType: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["custom-field-added"],

  data() {
    return {
      customField: {
        title: null,
        default_value: null,
        field_type: "text",
        marked_as_default: false,
      },
    };
  },

  computed: {
    formattedFieldTypes() {
      return this.$config.CONTRACT.FIELD_TYPES.map((key) => ({
        text: this.$i18n.t(`models.custom_field.field_types.${key}`),
        value: key,
      }));
    },
  },

  methods: {
    async saveCustomField(beModalEvt) {
      beModalEvt.preventDefault();

      this.customField.owner_type = this.objectType;

      try {
        const response = await axios.post(this.url("/custom_fields"), {
          custom_field: this.customField,
        });

        if (Object.keys(response.data.errors).length == 0) {
          this.$emit("custom-field-added", response.data);

          // Reset new custom field
          this.customField.title = "";
          this.customField.default_value = null;
          this.customField.field_type = "text";
          this.customField.marked_as_default = false;
          this.$beModal.hide("add-custom-fields");
        } else {
          this.customField = response.data;
        }
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
