<template>
  <div>
    <be-button
      variant="outline-primary"
      :disabled="disabled"
      :loading="loading"
      icon="fa-cloud-download"
      @click="importTemplates"
    >
      <template v-if="disabled">
        {{ $t("buttons.states.imported") }}
      </template>

      <template v-else>
        {{ $t("buttons.titles.import") }}
      </template>
    </be-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      loading: false,
    };
  },

  methods: {
    async importTemplates() {
      try {
        this.loading = true;

        let response = await axios.post(this.url("/settings/defaults_imports"));

        if (response.status == 200) {
          this.disabled = true;
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
