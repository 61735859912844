<template>
  <sale-form :action="action" :in-edit="inEdit" />
</template>

<script>
import SaleForm from "./SaleForm.vue";

export default {
  components: { SaleForm },

  props: {
    action: {
      type: Object,
      required: true,
    },

    inEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
