<template>
  <div class="row">
    <div class="col-12 col-lg-6 order-2 order-lg-1">
      <h2>
        {{ $t("components.shareholder_registers.introduction.get_started") }}
      </h2>

      <h4>
        {{ $t("components.shareholder_registers.introduction.add_actions") }}
      </h4>

      <p>
        {{ $t("components.shareholder_registers.introduction.description") }}
      </p>

      <p>
        {{ $t("components.shareholder_registers.introduction.description_2") }}
      </p>

      <template v-if="platformAdmin">
        <div v-if="shareholderRegister" class="mb-4">
          <new-action-button />
        </div>

        <be-button
          v-else
          variant="outline-primary"
          class="mb-3"
          :href="url(`/shareholder_register/register_series/new`)"
        >
          {{
            $t(
              "components.shareholder_registers.introduction.create_share_register"
            )
          }}
        </be-button>

        <h4>
          {{
            $t(
              "components.shareholder_registers.introduction.upload_shareholders"
            )
          }}
        </h4>

        <i18n-t
          keypath="components.shareholder_registers.introduction.upload_description_html"
          tag="p"
          class="mt-3"
        >
          <template #link>
            <a :href="downloadTemplateLink">
              {{
                $t(
                  "components.shareholder_registers.introduction.download_example_file"
                )
              }}
            </a>
          </template>
        </i18n-t>

        <a
          v-if="shareholderRegister"
          class="btn btn-outline-secondary btn-block btn-md-inline-block mb-3"
          :href="url(`/shareholder_register/edit`)"
        >
          {{ $t("models.shareholder.new") }}
        </a>

        <a
          v-else
          data-method="POST"
          class="btn btn-outline-secondary btn-block btn-md-inline-block mb-3"
          :href="url(`/shareholder_register`)"
        >
          {{ $t("models.shareholder.new") }}
        </a>

        <be-alert v-if="processingStarted" variant="info" loading>
          {{
            $t(
              "components.shareholder_registers.introduction.processing_shareholders"
            )
          }}
        </be-alert>

        <form
          v-if="!processingStarted"
          :action="url('/shareholder_register/preview')"
          accept-charset="UTF-8"
          method="POST"
        >
          <input name="utf8" type="hidden" value="✓" />

          <input type="hidden" name="authenticity_token" :value="$csrfToken" />

          <input id="locale" type="hidden" name="locale" value="sv" />

          <input id="_method" type="hidden" name="_method" value="post" />

          <single-file-uploader
            name="shareholder_register"
            attachment-name="temp_owner_list"
            :accepted-types="['xls', 'xlsx']"
            :html-form="true"
            :file="{}"
            class="mb-2"
            @uploading="(value) => (uploadingFiles = value)"
          />

          <button
            type="submit"
            class="btn btn-primary justify-self-end btn-block btn-md-inline-block"
            :disabled="uploadingFiles"
          >
            {{ $t("buttons.titles.upload") }}
          </button>
        </form>
      </template>
    </div>

    <div class="col-lg-6 order-1 order-lg-2 mb-3 mb-lg-0">
      <be-img
        :src="
          imageSrc('onboarding/intro/shareholder_register/shareholder_list.png')
        "
        class="mb-3 mb-lg-0"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewActionButton from "./NewActionButton.vue";

export default {
  components: { NewActionButton },

  props: {
    processingStarted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      uploadingFiles: false,
    };
  },

  computed: {
    ...mapGetters({
      shareholderRegister: "shareholder_register/shareholderRegister",
    }),

    downloadTemplateLink() {
      return `/demo/shareholder_register_template_${this.$currentUser.locale}.xlsx`;
    },
  },

  mounted() {
    if (this.processingStarted) {
      this.startPolling();
    }
  },

  methods: {
    startPolling() {
      this.pollForExport();
      this.timer = setInterval(this.pollForExport, 1000);
    },

    pollForExport() {
      axios
        .get(this.url("/shareholder_register/parser/statuses"))
        .then((response) => {
          if (response.data.download_path) {
            // Start download automatically
            window.location.href = response.data.download_path;

            // Clear polling timer
            clearInterval(self.timer);

            // Hide the spinner
            window.message.complete();
          }
        })
        .catch(() => {});
    },
  },
};
</script>
