<template>
  <div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <div class="row">
          <div class="col-12 col-lg-auto col-xl-8">
            <be-form-group
              v-if="!inEdit"
              :label="
                $t('activerecord.attributes.shareholder_transaction.share_type')
              "
              label-for="share_type"
              required
            >
              <be-form-select
                id="share_type"
                v-model="shareType"
                name="shareholder_register_action[share_type]"
                :options="formattedShareTypes"
                required
              />
            </be-form-group>

            <be-form-group
              :label="
                $t(
                  'activerecord.attributes.shareholder_register_action.number_of_shares'
                )
              "
              label-for="number_of_shares"
              :error="getErrors(localAction, ['number_of_shares'])"
            >
              <be-form-input
                id="number_of_shares"
                v-model="numberOfShares"
                type="number"
                name="shareholder_register_action[number_of_shares]"
                @change="clearErrors(localAction, ['number_of_shares'])"
              />
            </be-form-group>

            <be-form-group
              v-if="!inEdit"
              label-for="share_price"
              :label="
                $t(
                  'activerecord.attributes.shareholder_transaction.share_price'
                )
              "
              :error="getErrors(localAction, ['price'])"
            >
              <be-form-input
                id="share_price"
                v-model="sharePrice"
                type="number"
                name="shareholder_register_action[price]"
                @input="updateValueAboveQuota()"
                @change="clearErrors(localAction, ['price'])"
              />
            </be-form-group>
          </div>
        </div>
      </div>

      <div v-if="!inEdit" class="col-12 col-lg-8">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-right">
                  {{
                    $t(
                      "models.shareholder_register_action.shares_left_to_assign"
                    )
                  }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register_action.shares_assigned") }}
                </th>

                <th class="text-right">
                  {{
                    `${$t("models.shareholder_register.quota")} (${
                      shareholderRegister.currency
                    })`
                  }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register.value_above_quota") }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="text-right">{{ sharesToDistribute }}</td>

                <td class="text-right">
                  {{ distributedShares > 0 ? distributedShares : "-" }}
                </td>

                <td class="text-right">
                  {{ quota > 0 ? formattedQuota : 0 }}
                </td>

                <td class="text-right">
                  {{ formattedValueAboveQuota }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-right">
                  {{
                    $t(
                      "models.shareholder_register_action.equity_issuance.share_count_before"
                    )
                  }}
                </th>

                <th class="text-right">
                  {{
                    $t(
                      "models.shareholder_register_action.equity_issuance.new_total_share_count"
                    )
                  }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register_action.old_capital") }}
                </th>

                <th class="text-right">
                  {{ $t("models.shareholder_register_action.new_capital") }}
                </th>

                <th class="text-right">
                  {{
                    `${$t(
                      "models.shareholder_register_action.equity_issuance.price_for_equity_issuance"
                    )} (${shareholderRegister.currency})`
                  }}
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="text-right">
                  {{ sharesBefore }}
                </td>

                <td class="text-right">
                  {{ sharesAfter }}
                </td>

                <td class="text-right">
                  {{ oldCapital }}
                </td>

                <td class="text-right">
                  {{ newCapital }}
                </td>

                <td class="text-right">
                  {{ totalPrice }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="!inEdit">
      <h3 class="mt-5">
        {{ $t("models.shareholder_register.share_distribution") }}
      </h3>

      <div class="table-responsive">
        <table class="table table-hover">
          <thead>
            <tr>
              <th>
                {{ $t("models.shareholder_register_action.owner") }}
              </th>

              <th class="text-right">
                {{ $t("models.shareholder_register_action.shares_before") }}
              </th>

              <th>
                {{ $t("models.shareholder_register_action.distribute") }}
                <span
                  v-be-tooltip="
                    $t(
                      'components.shareholder_registers.shareholder_register_action.equity_issuance.distribute_tooltip'
                    )
                  "
                  class="ml-1"
                >
                  <i class="fal fa-question-circle" />
                </span>
              </th>

              <th class="text-right">
                {{ $t("models.shareholder_register_action.shares_after") }}
              </th>

              <th class="text-right">
                {{ $t("models.shareholder_register_action.total_price") }}
              </th>

              <th class="d-none"></th>
            </tr>
          </thead>

          <tbody>
            <equity-issuance-owner-row
              v-for="(shareholder, index) in existingShareholders"
              :key="`persisted-shareholder-${shareholder.id}`"
              :action="localAction"
              :initial-shareholder="shareholder"
              :index="index"
              :show-share-type="false"
              :share-type="shareType"
              :share-price="sharePrice"
              @shareholder-updated="updateExistingShareholder"
            />
          </tbody>
        </table>
      </div>

      <new-shareholder-transaction
        v-for="(transaction, index) in newShareholderTransactions"
        :key="`new-shareholder-transaction-${transaction.id}`"
        :index="existingShareholders.length + index"
        :action="localAction"
        :share-price="sharePrice"
        :initial-transaction="transaction"
        name-prefix="shareholder_register_action[shareholder_transactions_attributes]"
        @new-shareholder-removed="removeNewShareholder"
        @transaction-changed="updateNewShareholder"
      />

      <div class="d-md-flex justify-content-end mt-2">
        <be-button
          variant="light"
          size="sm"
          icon="fa-plus"
          @click="addEmptyNewTransaction"
        >
          {{ $t("models.shareholder.add") }}
        </be-button>
      </div>

      <duplicate-shareholders-warning
        v-if="!localAction.id"
        :shareholders="newShareholders"
      />
    </div>
  </div>
</template>

<script>
import extend from "lodash/extend";
import isNumber from "lodash/isNumber";
import isFinite from "lodash/isFinite";

import EquityIssuanceOwnerRow from "./EquityIssuanceOwnerRow.vue";
import NewShareholderTransaction from "./NewShareholderTransaction.vue";
import shareholderRegisterMixins from "@/mixins/shareholderRegisters";
import DuplicateShareholdersWarning from "../../DuplicateShareholdersWarning.vue";

export default {
  components: {
    DuplicateShareholdersWarning,
    EquityIssuanceOwnerRow,
    NewShareholderTransaction,
  },

  mixins: [shareholderRegisterMixins],

  props: {
    shareholderRegister: {
      type: Object,
      required: true,
    },

    action: {
      type: Object,
      required: true,
    },

    currentQuota: {
      type: String,
      required: true,
    },

    newTransactions: {
      type: Array,
      required: false,
      default: null,
    },

    inEdit: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      newShareholderTransactions: [],

      numberOfShares: this.action.number_of_shares
        ? this.action.number_of_shares
        : null,

      roundDecimals: null,
      sharePrice: this.action.price ? this.action.price.toString() : null,
      shareType: this.action.share_type ? this.action.share_type : "a_shares",
      valueAboveQuota: null,
      quota: this.currentQuota,
      localAction: this.cloneDeep(this.action),
      localShareholderRegister: this.cloneDeep(this.shareholderRegister),
    };
  },

  computed: {
    existingShareholders() {
      return this.localShareholderRegister.shareholders.filter(
        (shareholder) => shareholder.id != null
      );
    },

    newShareholders() {
      const activeNewShareholderTransactions =
        this.newShareholderTransactions.filter(
          (transaction) => !transaction.destroyed
        );

      return activeNewShareholderTransactions.map(
        (shareholderTransaction) => shareholderTransaction.buyer || {}
      );
    },

    sharesToDistribute() {
      if (this.numberOfShares) {
        return parseInt(this.numberOfShares) - this.distributedShares;
      }

      return "-";
    },

    sharesBefore() {
      if (this.localShareholderRegister.total_share_count) {
        return this.localShareholderRegister.total_share_count;
      }

      return "-";
    },

    sharesAfter() {
      if (
        this.localShareholderRegister.total_share_count > 0 &&
        this.distributedShares > 0
      ) {
        return (
          parseInt(this.localShareholderRegister.total_share_count) +
          this.distributedShares
        );
      }

      return "-";
    },

    totalPrice() {
      if (this.sharePrice && this.distributedShares) {
        return BigNumber(this.sharePrice)
          .multipliedBy(this.distributedShares)
          .decimalPlaces(this.roundDecimals);
      }

      return "-";
    },

    oldCapital() {
      if (this.localShareholderRegister.capital) {
        return BigNumber(this.localShareholderRegister.capital).decimalPlaces(
          this.roundDecimals
        );
      }

      return "-";
    },

    newCapital() {
      if (
        this.localShareholderRegister.capital &&
        this.distributedShares &&
        this.quota
      ) {
        const oldCapital = BigNumber(this.localShareholderRegister.capital);
        const addedCapital = BigNumber(this.distributedShares).multipliedBy(
          BigNumber(this.quota)
        );

        return oldCapital.plus(addedCapital).decimalPlaces(this.roundDecimals);
      }

      return "-";
    },

    formattedQuota() {
      if (this.quota) {
        return BigNumber(this.quota).decimalPlaces(this.roundDecimals);
      }

      return null;
    },

    formattedValueAboveQuota() {
      if (this.valueAboveQuota) {
        return BigNumber(this.valueAboveQuota).decimalPlaces(
          this.roundDecimals
        );
      }

      return "-";
    },

    distributedShares() {
      let value = 0;

      // Existing shareholders
      this.existingShareholders.forEach((shareholder) => {
        if (
          this.valueNumeric(Number(shareholder.addedShares)) &&
          shareholder.addedShares > 0
        ) {
          value += parseInt(shareholder.addedShares);
        }
      });

      // Newly added shareholdes
      this.newShareholderTransactions.forEach((transaction) => {
        if (
          this.valueNumeric(Number(transaction.number_of_shares)) &&
          parseInt(transaction.number_of_shares) > 0
        ) {
          value += parseInt(transaction.number_of_shares);
        }
      });

      return value;
    },
  },

  watch: {
    numberOfShares(newValue) {
      if (newValue < 1) {
        this.localAction.errors.number_of_shares = [
          this.$t("errors.messages.greater_than", {
            count: 0,
          }),
        ];
      } else if (this.localAction.errors.number_of_shares) {
        delete this.localAction.errors.number_of_shares;
      }
    },

    sharePrice(newValue) {
      if (newValue < 1) {
        this.localAction.errors.price = [
          this.$t("errors.messages.greater_than", {
            count: 0,
          }),
        ];
      } else {
        delete this.localAction.errors.price;
      }
    },
  },

  created() {
    this.roundDecimals = this.$config.SHAREHOLDER_REGISTER.ROUND_DECIMALS;

    if (this.newTransactions) {
      this.newTransactions.forEach((transaction) => {
        let existingShareholder = this.existingShareholders.find(
          (existingShareholder) =>
            existingShareholder.id == transaction.buyer_id
        );

        if (existingShareholder) {
          existingShareholder.transaction = transaction;
          existingShareholder.addedShares = transaction.number_of_shares;
        } else {
          this.addNewShareholderTransaction(transaction);
        }
      });
    }
  },

  methods: {
    addNewShareholderTransaction(transaction) {
      this.newShareholderTransactions.push(
        extend(transaction, {
          id: this.generateUuid(),
          destroyed: false,
          existing: false,
          addedShares: transaction.number_of_shares,
        })
      );
    },

    updateValueAboveQuota() {
      if (this.sharePrice && this.quota) {
        this.valueAboveQuota = BigNumber(this.sharePrice).minus(
          BigNumber(this.quota)
        );
      } else {
        this.valueAboveQuota = null;
      }
    },

    addEmptyNewTransaction() {
      let transaction = {
        id: this.generateUuid(),
        destroyed: false,
        existing: false,
        addedShares: null,
        buyer: {},
      };

      this.newShareholderTransactions.push(transaction);
    },

    removeNewShareholder(transaction) {
      const idx = this.newShareholderTransactions.findIndex(
        (existingTransaction) => existingTransaction.id == transaction.id
      );

      if (idx > -1) {
        const transaction = this.newShareholderTransactions[idx];
        transaction.destroyed = 1;
        this.newShareholderTransactions.splice(idx, 1);
      }
    },

    updateNewShareholder(transaction) {
      const idx = this.newShareholderTransactions.findIndex(
        (existingTransaction) => existingTransaction.id == transaction.id
      );
      this.newShareholderTransactions[idx] = transaction;
    },

    updateExistingShareholder(shareholder) {
      const idx = this.localShareholderRegister.shareholders.findIndex(
        (existing) => existing.id === shareholder.id
      );
      this.localShareholderRegister.shareholders[idx] = shareholder;
    },

    valueNumeric(value) {
      return isNumber(parseFloat(value)) && isFinite(value);
    },
  },
};
</script>
