<template>
  <div class="badge badge-pill badge-light">
    {{ tasks.length }}
  </div>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },

  computed: {
    tasks() {
      let options = {
        projectId: this.project.id,
      };

      return this.$store.getters["tasks/filterTasks"](options);
    },
  },
};
</script>
