import $ from "jquery";
import { app } from "@/components/bootstrap-components";

import * as Sentry from "@sentry/browser";

import Config from "@/config.js";
import store from "@/store";
import i18n from "@/i18n";
import { scrubUrl } from "@/utils/scrub-url";

app.use(store);
app.use(i18n);

/**
 * Prepare App initialization
 */
document.addEventListener("DOMContentLoaded", () => {
  // eslint-disable-next-line
  const AmCharts_path = Config.AMCHARTS_PATH;

  /**
   * Generic Twitter-Bootstrap loaders and overrides
   */
  $(() => {
    // TODO: Use vue-tooltips instead?
    $('[data-toggle="tooltip"]').tooltip();

    // Add a show-class to the closest `card` when an accordion is opened.
    // Remove the class when the accordion is closed. This is used to modify
    // the behaviour of the default Bootstrap accordions.
    $(".collapse").on("show.bs.collapse", (e) => {
      $(e.target).closest(".card").addClass("show");
    });

    $(".collapse").on("hidden.bs.collapse", (e) => {
      $(e.target).closest(".card").removeClass("show");
    });
  });

  /**
   * Initialize Sentry
   */
  if (window.sentryConfig) {
    Sentry.init({
      dsn: window.sentryConfig.dsn,
      environment: window.sentryConfig.environment,
      release: window.sentryConfig.release,
      tracesSampleRate: window.sentryConfig.tracesSampleRate,

      integrations: [
        Sentry.browserTracingIntegration({
          beforeStartSpan: (context) => {
            return {
              ...context,

              name: scrubUrl(location.pathname),
            };
          },
        }),
      ],

      ignoreErrors: [
        "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
        "Network Error",
        "Request aborted",
      ],
    });
    window.Sentry = Sentry;
  }

  app.mount(`#${Config.ROOT_ELEMENT_ID}`);
});
