<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <reminder-period
        v-if="endDate"
        :contract="contract"
        :reminders="reminders"
        :label="label"
      />
    </div>
  </div>
</template>

<script>
import ReminderPeriod from "./ReminderPeriod.vue";

export default {
  components: { ReminderPeriod },

  props: {
    contract: {
      type: Object,
      required: true,
    },

    reminders: {
      type: Array,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },

    endDate: {
      type: Date,
      required: false,
      default: null,
    },
  },
};
</script>
