<template>
  <slot
    :should-esign="shouldEsign"
    :set-should-esign="(event) => (shouldEsign = event.target.checked)"
  />
</template>
<script>
export default {
  data() {
    return {
      shouldEsign: false,
    };
  },

  mounted() {},
};
</script>
