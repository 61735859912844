<template>
  <div class="card">
    <form
      novalidate="novalidate"
      :action="formUrl"
      accept-charset="UTF-8"
      method="post"
    >
      <input name="utf8" type="hidden" value="✓" />

      <input type="hidden" name="authenticity_token" :value="$csrfToken" />

      <input id="locale" type="hidden" name="locale" value="sv" />

      <input id="_method" type="hidden" name="_method" :value="formMethod" />

      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-auto col-xl-3">
            <be-form-group
              :label="
                $t(
                  'activerecord.attributes.shareholder_register_action.action_type'
                )
              "
              label-for="action_type"
              required
            >
              <be-form-select
                id="action_type"
                v-model="action.action_type"
                name="shareholder_register_action[action_type]"
                :disabled="inEdit"
                :options="formattedActionTypes()"
              />
            </be-form-group>
          </div>

          <div class="col-12 col-lg-auto">
            <be-form-group
              v-if="action.action_type != PREVIOUS_REGISTER"
              :label="$t('components.shareholder_registers.action_form.date')"
              label-for="action_date"
              required
            >
              <be-form-datepicker
                id="action_date"
                v-model="action.action_date"
                name="shareholder_register_action[action_date]"
                format="yyyy-MM-dd"
                :min-date="minDate"
                :max-date="maxDate"
              />
            </be-form-group>
          </div>
        </div>

        <hr />

        <component
          :is="action.action_type.replace(/_/g, '-')"
          :class="{ 'mb-4': !inEdit }"
          :action="action"
          :current-quota="quota"
          :in-edit="inEdit"
          :new-transactions="newTransactions"
          :existing-shareholders="existingShareholders"
          :shareholder-register="shareholderRegister"
        />

        <hr :class="{ 'd-none': inEdit }" />

        <be-form-group
          :label="$t('activerecord.models.document.other')"
          label-for="documents"
        >
          <document-uploader
            :documents="action.documents"
            name="shareholder_register_action[documents_attributes][_index_]"
            :html-form="true"
            @document-added="documentAdded"
            @document-removed="documentRemoved"
            @uploading="(value) => (uploadingFiles = value)"
          />
        </be-form-group>

        <be-form-group
          :label="$t('components.shareholder_registers.action_form.notes')"
          label-for="notes"
          :error="getErrors(action, 'notes')"
        >
          <be-form-textarea
            id="notes"
            v-model="action.notes"
            name="shareholder_register_action[notes]"
            rows="3"
            max-rows="20"
            :state="validationState(action, 'notes')"
            @change="clearErrors(action, 'notes')"
          />
        </be-form-group>

        <input
          name="shareholder_register_action[id]"
          type="hidden"
          :value="action.id"
        />
      </div>

      <div class="card-footer">
        <div class="d-flex justify-content-end">
          <be-button
            type="submit"
            variant="primary"
            :disabled="uploadingFiles"
            :data-confirm="confirmTitle"
            :data-description="confirmSaveDescription"
            :data-confirm-button-text="$t('buttons.titles.create')"
          >
            {{ formButtonText }}
          </be-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import PreviousRegister from "./types/PreviousRegister.vue";
import CompanyFormation from "./types/CompanyFormation.vue";
import Sale from "./types/SaleForm.vue";
import StandardSplit from "./types/StandardSplit.vue";
import BonusIssue from "./types/BonusIssue.vue";
import EquityIssuance from "./types/EquityIssuance.vue";
import ShareTransfer from "./types/ShareTransfer.vue";

const COMPANY_FORMATION = "company_formation";
const PREVIOUS_REGISTER = "previous_register";
const TODAY = new Date();

export default {
  components: {
    PreviousRegister,
    CompanyFormation,
    Sale,
    StandardSplit,
    BonusIssue,
    EquityIssuance,
    ShareTransfer,
  },

  props: {
    availableActionTypes: {
      type: Array,
      required: true,
    },

    firstPossibleDate: {
      type: String,
      required: false,
      default: null,
    },

    initialAction: {
      type: Object,
      required: true,
    },

    inEdit: {
      type: Boolean,
      default: false,
      required: true,
    },

    newTransactions: {
      type: Array,
      required: false,
      default: null,
    },

    existingShareholders: {
      type: Array,
      required: false,
      default: () => [],
    },

    quota: {
      type: String,
      required: true,
    },

    shareholderRegister: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      action: this.initialAction,
      minDate: null,
      maxDate: null,
      uploadingFiles: false,
      PREVIOUS_REGISTER: PREVIOUS_REGISTER,
      TODAY: TODAY,
    };
  },

  computed: {
    confirmTitle() {
      if (this.action.action_date == this.firstPossibleDate) {
        return this.$i18n.t(
          "components.shareholder_registers.action_form.confirm_date"
        );
      }

      return "";
    },

    confirmSaveDescription() {
      return this.$t(
        "components.shareholder_registers.action_form.confirm_date_description"
      );
    },

    formButtonText() {
      if (this.uploadingFiles) {
        return this.$i18n.t("buttons.states.loading");
      } else if (this.action.id) {
        return this.$i18n.t("buttons.titles.save");
      } else {
        return this.$i18n.t("models.shareholder_register_action.create");
      }
    },

    formMethod() {
      return this.inEdit ? "patch" : "post";
    },

    formUrl() {
      return this.inEdit
        ? this.url(`/shareholder_register/actions/${this.action.id}`)
        : this.url("/shareholder_register/actions");
    },
  },

  mounted() {
    this.$store.commit(
      "shareholder_register/loadRegister",
      this.shareholderRegister
    );

    if (this.initialAction.action_type != COMPANY_FORMATION) {
      this.minDate = new Date(this.firstPossibleDate);
      this.maxDate = TODAY;
    } else {
      this.minDate = null;
      this.maxDate = TODAY;
    }
  },

  // This needs to be created (not mounted), or else we cant access this.action yet
  created() {
    this.action.action_type = this.activeActionType();

    if (!this.action.action_date && this.firstPossibleDate) {
      this.action.action_date = this.firstPossibleDate;
    } else {
      this.action.action_date = new Date();
    }
  },

  methods: {
    activeActionType() {
      if (this.action.action_type) {
        return this.action.action_type;
      } else if (
        !this.shareholderRegister.shareholder_register_actions.length
      ) {
        return "company_formation";
      } else {
        return "sale";
      }
    },

    formattedActionTypes() {
      return this.availableActionTypes.map((actionType) => {
        return {
          value: actionType,

          text: this.$i18n.t(
            `models.shareholder_register_action.action_types.${actionType}`
          ),
        };
      });
    },

    documentAdded(file) {
      this.action.documents.push(file);
    },

    documentRemoved(file) {
      const index = this.action.documents.findIndex(
        (existingDocument) => existingDocument == file
      );

      if (index > -1) {
        this.action.documents.splice(index, 1);
      }
    },
  },
};
</script>
