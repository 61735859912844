<template>
  <div>
    <be-table
      v-if="states.length > 0"
      :items="states"
      :fields="fields"
      :table-class="tableClass"
      hide-filters
    >
      <template #parent_company="{ item }">
        <span>{{ item.company_group_ownership.parent_company_title }}</span>
      </template>

      <template #child_company="{ item }">
        <span>{{ item.company_group_ownership.child_company_title }}</span>
      </template>

      <template #end_at="{ item }">
        <span v-if="item.end_at">{{ item.end_at }}</span>

        <span v-else class="text-muted">-</span>
      </template>

      <template #owned_shares="{ item }">
        {{ calculatePercentage(item.shares, item.owned_shares) }}%
      </template>

      <template #owned_votes="{ item }">
        {{ calculatePercentage(item.votes, item.owned_votes) }}%
      </template>

      <template #ownership_type="{ item }">
        {{
          $t(
            `models.company_group_ownership_state.ownership_type.${item.ownership_type}`
          )
        }}

        ({{
          $t(
            `models.company_group_ownership_state.ownership_type_mode.${item.ownership_type_mode}`
          )
        }})
      </template>

      <template #options="{ item }">
        <be-dropdown
          v-if="item.company_group_ownership.can_edit"
          size="sm"
          ellipsis
        >
          <be-dropdown-item @click="$emit('click-edit-state', item)">
            {{ $t("buttons.titles.edit") }}
          </be-dropdown-item>

          <be-dropdown-divider />

          <be-dropdown-item
            variant="danger"
            @click="$emit('click-remove-state', item)"
          >
            {{ $t("buttons.titles.remove") }}
          </be-dropdown-item>
        </be-dropdown>
      </template>
    </be-table>
  </div>
</template>

<script>
export default {
  props: {
    states: {
      type: Array,
      default: () => [],
      required: false,
    },

    showOwnershipData: {
      type: Boolean,
      default: false,
      required: false,
    },

    tableClass: {
      type: String,
      default: "",
      required: false,
    },
  },

  emits: ["click-edit-state", "click-remove-state"],

  computed: {
    fields() {
      const value = [];

      if (this.showOwnershipData) {
        value.push(
          {
            key: "parent_company",

            label: this.$t(
              "activerecord.attributes.company_group_ownership.parent_company"
            ),
          },

          {
            key: "child_company",

            label: this.$t(
              "activerecord.attributes.company_group_ownership.child_company"
            ),
          }
        );
      }

      value.push(
        {
          key: "start_at",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.start_at"
          ),
        },
        {
          key: "end_at",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.end_at"
          ),
        },
        {
          key: "owned_shares",

          label: this.$t(
            "models.company_group_ownership_state.owned_share_percentage"
          ),

          class: "text-right",
        },
        {
          key: "owned_votes",

          label: this.$t(
            "models.company_group_ownership_state.owned_voting_percentage"
          ),

          class: "text-right",
        },
        {
          key: "ownership_type",

          label: this.$t(
            "activerecord.attributes.company_group_ownership_state.ownership_type"
          ),

          class: "col-shrink",
        }
      );

      if (this.showActions) {
        value.push({
          key: "options",
          label: "",
          class: "col-shrink text-center",
        });
      }

      return value;
    },

    showActions() {
      return this.states.some(
        (state) => state.company_group_ownership.can_edit
      );
    },
  },

  methods: {
    calculatePercentage(total, num) {
      if (!total || !num) return 100;
      return BigNumber((num / total) * 100).decimalPlaces(2);
    },
  },
};
</script>
