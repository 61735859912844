<template>
  <div class="row no-gutters">
    <div class="col-auto h5">
      <i
        v-be-tooltip="tooltipTitle"
        class="fas fa-check-circle mr-3"
        :class="checkboxClasses"
      />
    </div>

    <div class="col mb-1 mb-md-0">
      <h6>{{ title }}</h6>

      <slot name="text" />
    </div>

    <div v-if="!hideAction" class="col-12 col-md-auto">
      <div class="ml-md-3">
        <slot
          v-if="!completed || (completed && !hideButtonOnCompletion)"
          name="link"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actionKey: {
      type: String,
      required: true,
    },

    onboardingObject: {
      type: Object,
      required: true,
    },

    sectionType: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    hideButtonOnCompletion: {
      type: Boolean,
      required: false,
      default: false,
    },

    skipped: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideAction: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    completed() {
      if (
        this.onboardingObject.progress &&
        this.onboardingObject.progress[this.sectionType]
      ) {
        return (
          this.onboardingObject.progress[this.sectionType][this.actionKey] ||
          false
        );
      }

      return false;
    },

    checkboxClasses() {
      if (this.skipped && !this.completed) {
        return "text-warning";
      } else if (this.completed) {
        return "text-success";
      } else if (!this.completed) {
        return "invisible";
      }

      return "";
    },

    tooltipTitle() {
      if (this.skipped) {
        return this.$t("components.onboarding.skipped");
      }

      return "";
    },
  },
};
</script>
