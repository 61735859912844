<template>
  <span class="badge badge-light badge-pill ml-1">
    <template v-if="badgeValue != null">
      {{ badgeValue }}
    </template>

    <be-spinner v-else />
  </span>
</template>

<script>
import { EventBus } from "@/event-bus";

export default {
  props: {
    initialBadgeValue: {
      type: Number,
      required: false,
      default: null,
    },

    event: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      badgeValue: this.initialBadgeValue,
    };
  },

  created() {
    EventBus.on(this.event, (newBadgeValue) => {
      this.badgeValue = newBadgeValue;
    });
  },
};
</script>
