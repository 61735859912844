<template>
  <be-list-group flush>
    <be-list-group-item class="p-4">
      <task-container
        v-if="$currentUser.confirmed_at"
        action-key="email_confirmed"
        section-type="email_confirmation"
        :onboarding-object="localUser"
        :hide-button-on-completion="true"
        :required="true"
        :title="
          $t(
            'components.onboarding.introduction.email_confirmation.confirm_email'
          )
        "
      >
        <template #text>
          {{ localUser.email }}
        </template>

        <template #link> </template>
      </task-container>

      <task-container
        v-else
        action-key="email_confirmed"
        section-type="email_confirmation"
        :onboarding-object="localUser"
        :hide-button-on-completion="true"
        :hide-action="true"
        :title="
          $t(
            'components.onboarding.introduction.email_confirmation.confirm_email'
          )
        "
      >
        <template #text>
          <div class="d-flex flex-column">
            <div class="mb-3">
              {{
                $t(
                  "components.onboarding.introduction.email_confirmation.description"
                )
              }}
            </div>

            <be-alert v-if="emailAlertText" variant="info">
              {{ emailAlertText }}
            </be-alert>

            <be-form-group
              class="mb-3"
              :label="$t('activerecord.attributes.user.email')"
              label-for="email"
              :error="getErrors(localUser, ['email'])"
            >
              <be-form-input
                id="email"
                v-model="email"
                type="email"
                autocomplete="username"
                required
                @change="clearErrors(localUser, ['email'])"
              />
            </be-form-group>

            <div class="d-md-flex justify-content-between align-items-center">
              <div class="mb-3 mb-md-0">
                <template v-if="confirmationEmailSent">
                  <i class="fa fa-check-circle fa-fw text-success" />

                  <span class="text-muted">
                    {{
                      $t(
                        "components.onboarding.introduction.email_confirmation.email_sent_w_time",
                        {
                          time: $d(
                            new Date(localUser.confirmation_sent_at),
                            dateFormatIfSameDay(
                              new Date(localUser.confirmation_sent_at)
                            )
                          ),
                        }
                      )
                    }}
                  </span>
                </template>
              </div>

              <be-button
                v-if="confirmationEmailSent"
                variant="outline-secondary"
                icon="fa-envelope"
                @click="updateEmail"
              >
                {{
                  $t(
                    "components.onboarding.introduction.email_confirmation.request_confirmation_again"
                  )
                }}
              </be-button>

              <be-button
                v-else
                class="btn-block btn-md-inline-block"
                variant="primary"
                icon="fa-envelope"
                @click="updateEmail"
              >
                {{
                  $t(
                    "components.onboarding.introduction.email_confirmation.request_confirmation"
                  )
                }}
              </be-button>
            </div>
          </div>
        </template>

        <template #link>
          <div class="px-4"></div>
        </template>
      </task-container>
    </be-list-group-item>
  </be-list-group>
</template>

<script>
import TaskContainer from "@/components/onboarding/TaskContainer.vue";

export default {
  components: {
    TaskContainer,
  },

  data() {
    return {
      localUser: {},
      emailPending: false,
    };
  },

  computed: {
    email: {
      get() {
        return this.localUser.email || this.localUser.unconfirmed_email;
      },

      set(value) {
        this.localUser.email = value;
        this.localUser.unconfirmed_email = value;
      },
    },

    emailAlertText() {
      if (this.emailPending) {
        return this.$t(
          "components.onboarding.introduction.email_confirmation.email_pending"
        );
      } else {
        return this.$t(
          "components.onboarding.introduction.email_confirmation.no_unconfirmed_email"
        );
      }
    },

    confirmationEmailSent() {
      return (
        this.localUser.confirmation_sent_at &&
        this.localUser.confirmed_at == null &&
        this.emailPending
      );
    },
  },

  mounted() {
    this.localUser = this.cloneDeep(this.$currentUser);
    this.emailPending = this.email;
  },

  methods: {
    async updateUser(updatedUser) {
      this.localUser = this.cloneDeep(updatedUser);
      this.$store.commit("current_user/setUser", this.localUser);
    },

    async updateEmail() {
      if (!this.email) {
        this.localUser.errors = {
          ...this.localUser.errors,
          email: this.$t("errors.messages.blank"),
        };
        return;
      }
      try {
        const { data } = await axios.patch("/users/onboarding/email", {
          user: {
            email: this.email,
          },
        });

        this.updateUser(data);
        this.emailPending = this.email;
      } catch (error) {
        if (error.response?.status === 422) {
          this.localUser = error.response.data;
        } else {
          this.handleError(error);
        }
      }
    },
  },
};
</script>
