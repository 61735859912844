<template>
  <be-form-group
    :label="$t(`activerecord.attributes.inquiry.${paymentDetail}`)"
    :label-for="paymentDetail"
    :description="description"
    :error="paymentDetailError"
    :valid-feedback="feedback"
    :state="paymentDetailState"
  >
    <be-input-group prepend="#">
      <be-form-input
        :id="paymentDetail"
        v-model="form.payment_plan[0].inquiry_receiver[paymentDetail]"
        required
        :state="paymentDetailStatus"
        @input="debouncedValidation"
      />
    </be-input-group>
  </be-form-group>

  <p v-if="paymentDetail === 'bankgiro_number'">
    <be-link :href="bankgiro_website">Besök Bankgirots hemsida</be-link>
  </p>

  <p v-if="paymentDetail === 'plusgiro_number'">
    <be-link href="https://kontoutdrag.plusgirot.se/ku/html/sokkto.htm">
      Besök Plusgirots hemsida
    </be-link>
  </p>
</template>

<script>
import { mapActions } from "vuex";
import CreateInquiryMixin from "@/mixins/inquiries/createInquiry";
import debounce from "lodash/debounce";

export default {
  mixins: [CreateInquiryMixin],

  props: {
    paymentMethod: {
      type: String,
      required: true,
    },

    paymentDetail: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      paymentDetailStatus: null,
      bankName: null,
      bankClearingNumber: null,
      skipValidationField: null,
    };
  },

  computed: {
    paymentDetailState() {
      if (this.paymentDetailError) {
        return false;
      } else if (this.feedback) {
        return true;
      }
      return null;
    },

    feedback() {
      if (this.bankName && this.bankClearingNumber) {
        return this.$t(
          "views.companies.inquiries.create.bank_name_with_clearing_number",
          {
            bank_name: this.bankName,
            clearing_number: this.bankClearingNumber,
          }
        );
      }

      return null;
    },

    description() {
      if (this.bankName && this.bankClearingNumber) {
        return;
      }

      return this.$t(
        `activerecord.attributes.inquiry.${this.paymentDetail}_description`
      );
    },

    bankgiro_website() {
      const url = "https://www.bankgirot.se/sok-bankgironummer?bgnr=";
      const bankgiroNumber = encodeURI(this.form.inquiry.bankgiro_number);

      return url + bankgiroNumber;
    },

    paymentDetailError() {
      return this.getErrors(
        this.form.payment_plan[0],
        "inquiry_receiver." + this.paymentDetail
      );
    },
  },

  mounted() {
    this.bankName = this.form.payment_plan[0].inquiry_receiver.bank_name;
    this.bankClearingNumber =
      this.form.payment_plan[0].inquiry_receiver.bank_clearing_number;
  },

  methods: {
    ...mapActions("inquiries", ["validateInquiryPayment"]),

    async handleValidateInquiryPayment() {
      if (!this.form.inquiry.inquiry_id) {
        return;
      }
      this.clearStates();

      if (this.paymentMethod === "international_transfer") {
        if (this.paymentDetail === "iban") {
          this.skipValidationField = "bic";
        } else if (this.paymentDetail === "bic") {
          this.skipValidationField = "iban";
        }
      }

      const inquiryPayment = {
        inquiry_id: this.form.inquiry.inquiry_id,
        ...this.form.payment_plan[0],
        id: this.form.id,
        skip_validation_field: this.skipValidationField,
      };

      try {
        const response = await this.validateInquiryPayment(inquiryPayment);

        this.paymentDetailStatus = true;
        this.bankName = response.data.inquiry_receiver.bank_name;
        this.bankClearingNumber =
          response.data.inquiry_receiver.bank_clearing_number;
      } catch {
        this.paymentDetailStatus = false;
      }
    },

    clearStates() {
      this.clearErrors(
        this.form.payment_plan[0],
        "inquiry_receiver." + this.paymentDetail
      );

      this.paymentDetailStatus = null;
      this.bankName = null;
      this.bankClearingNumber = null;
    },

    debouncedValidation: debounce(function () {
      this.handleValidateInquiryPayment();
    }, 1000),
  },
};
</script>
