<template>
  <tr>
    <td>
      {{ version.title }}
      <i
        v-if="version.current"
        v-be-tooltip="$t('models.compliance.tooltips.current_version')"
        class="fal fa-star ml-2"
      />
    </td>

    <td class="col-shrink">
      {{ complianceDocuments.length }}
    </td>

    <td class="col-shrink">
      {{ $d(new Date(version.start_at), "date") }}
      <template v-if="version.end_at">
        - {{ $d(new Date(version.end_at), "date") }}
      </template>
    </td>

    <td class="col-shrink">
      <be-dropdown size="sm" ellipsis>
        <be-dropdown-item @click="$emit('edit', version)">
          {{ $t("buttons.titles.edit") }}
        </be-dropdown-item>

        <be-dropdown-item v-if="version.current" @click="$emit('archive')">
          {{ $t("buttons.titles.archive") }}
        </be-dropdown-item>

        <be-dropdown-divider />

        <be-dropdown-item variant="danger" @click="$emit('remove', version)">
          {{ $t("buttons.titles.remove") }}
        </be-dropdown-item>
      </be-dropdown>
    </td>
  </tr>
</template>

<script>
import RequestHandler from "@/mixins/RequestHandler";

export default {
  mixins: [RequestHandler],

  props: {
    compliance: {
      type: Object,
      required: true,
    },

    version: {
      type: Object,
      required: true,
    },

    complianceDocuments: {
      type: Array,
      required: true,
    },
  },

  emits: ["archive", "edit", "remove"],
};
</script>
