<template>
  <div class="card h-100">
    <div
      class="card-img-top feature-img-container"
      :style="{
        backgroundColor: featureBackgroundColor(feature.name),
      }"
    >
      <be-img
        :src="imageSrc(`icons/features/${feature.name}.svg`)"
        class="feature-img"
        crossorigin="anonymous"
      />
    </div>

    <div class="card-body">
      <div
        :class="{ 'd-md-flex justify-content-md-between': feature.beta_status }"
      >
        <h3>{{ $t(`models.feature.features.${feature.name}.title`) }}</h3>

        <div v-if="feature.beta_status">
          <span class="badge badge-secondary badge-pill">
            {{ $t("models.feature.beta_status") }}
          </span>
        </div>
      </div>

      <p>
        {{ $t(`models.feature.features.${feature.name}.description`) }}
      </p>

      <p
        v-if="$config.PRODUCTS_REQUIRING_FINANCIALS.includes(feature.name)"
        class="mt-3 text-muted"
      >
        {{ $t("models.feature.requires_financials") }}
      </p>
    </div>

    <div class="card-footer border-top-0 pt-0">
      <div v-if="!!feature.price">
        <h4
          v-if="!!feature.price.value"
          class="text-primary mb-0"
          :class="{ 'mb-1': showPriceVariationDescription || discount }"
        >
          <div class="mb-1">{{ $t("components.onboarding.free_30_days") }}</div>

          <s class="text-black-50 font-weight-normal mr-2">
            {{
              $t("models.feature.determined_price", {
                value: discountedPrice,
                currency: feature.price.currency,
              })
            }}
          </s>

          <be-badge
            v-if="discount"
            variant="notification"
            pill
            class="mb-1 mb-md-0"
          >
            -{{ discount }}%
          </be-badge>
        </h4>

        <div
          v-if="discount"
          class="small font-italic"
          :class="{ 'mb-1': showPriceVariationDescription }"
        >
          {{
            $t("models.feature.regular_price", {
              value: ordinaryPrice,
              currency: feature.price.currency,
            })
          }}
        </div>

        <i18n-t
          v-if="showPriceVariationDescription"
          keypath="components.onboarding.feature_shop.price_variation_description_short_html"
          tag="div"
          class="text-muted small font-italic"
        >
          <template #link>
            <be-link v-be-modal="'price_variations'">
              <span class="small font-italic">
                {{
                  $t("components.onboarding.quotation.price_link").toLowerCase()
                }}
              </span>
            </be-link>
          </template>
        </i18n-t>

        <small v-else class="d-none d-md-inline-block invisible">
          &nbsp;
        </small>
      </div>

      <p v-else class="text-primary mb-0">
        {{ $t("models.feature.contact_for_price") }}
      </p>
    </div>

    <div class="card-footer row no-gutters">
      <div class="col">
        <div v-if="selected">
          <be-button variant="primary" block @click="deselectFeature">
            {{ $t("buttons.titles.chosen") }}
          </be-button>
        </div>

        <div v-else>
          <be-button variant="outline-primary" block @click="selectFeature">
            {{ $t("buttons.titles.choose") }}
          </be-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { featureBackgroundColor } from "@/utils/color";

export default {
  props: {
    feature: {
      type: Object,
      required: true,
    },

    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["feature-selected", "feature-deselected"],

  data() {
    return {
      organizationTypesWithDiscounts: this.feature.price.discounts.map(
        (discount) => discount.kind
      ),

      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      organizationType: "company/getOrganizationType",
    }),

    showPriceVariationDescription() {
      return this.feature.has_price_variations;
    },

    ordinaryPrice() {
      return this.feature.price.value / 12;
    },

    discountedPrice() {
      return Math.floor(
        (this.feature.price.value -
          (this.feature.price.value / 100) * this.discount) /
          12
      );
    },

    discount() {
      if (
        this.organizationTypesWithDiscounts &&
        this.organizationTypesWithDiscounts.includes(this.organizationType)
      ) {
        return this.feature.price.discounts.find(
          (discount) => discount.kind == this.organizationType
        ).value;
      }

      return 0;
    },

    featureUrl() {
      let translatedUrl = this.$i18n.t(
        `models.feature.url.${this.feature.name}`
      );

      if (translatedUrl && translatedUrl.startsWith("https://")) {
        return translatedUrl;
      }

      return this.$config.WEBSITE_URLS.PRICE_PAGE_URL;
    },
  },

  methods: {
    featureBackgroundColor,

    selectFeature() {
      this.$emit("feature-selected", {
        ...this.feature,
        from_package: false,
      });
    },

    deselectFeature() {
      this.$emit("feature-deselected", this.feature);
    },
  },
};
</script>
