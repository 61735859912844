<template>
  <annual-report :annual-report-id="annualReport.id" />
</template>
<script>
import AnnualReport from "./AnnualReport.vue";
import { createNamespacedHelpers } from "vuex";

const { mapActions } = createNamespacedHelpers("annual_reports");

export default {
  components: {
    AnnualReport,
  },

  props: {
    annualReport: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.setAnnualReports([this.annualReport]);
  },

  methods: {
    ...mapActions(["setAnnualReports"]),
  },
};
</script>
