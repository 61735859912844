<template>
  <div class="card mb-2">
    <div
      v-if="liveUpdate && latestUpdate"
      class="card-footer d-flex justify-content-between align-items-center"
    >
      <save-status :loading="false" :last-saved-at="lastSavedAtText" />

      <h4>
        <be-badge
          v-be-tooltip="
            $t(
              'controllers.companies.meetings.minutes.show.live_update_description'
            )
          "
          variant="primary"
          pill
        >
          {{ $t("views.companies.meetings.minutes.show.live_update") }}
        </be-badge>
      </h4>
    </div>

    <external-material-show
      v-if="material.external_uploaded_at"
      :material="material"
      :item="items[0]"
      :attachments="attachments"
      :meeting="meeting"
    />

    <material-display
      v-else
      :with-comments="withComments"
      :comments-read-only="commentsReadOnly"
      auto-read
      :with-signature-lines="!hasActiveFeature('e-signature')"
    />

    <slot name="after-material" />

    <unlock-minutes-modal
      v-if="material && material.approved_at"
      :meeting="meeting"
      :minutes="material"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { parseISO } from "date-fns/parseISO";

import SaveStatus from "@/components/shared/SaveStatus.vue";

import MaterialStateMixin from "./materialState";
import MaterialMixin from "@/mixins/meetings/material";
import MaterialDisplay from "./MaterialDisplay.vue";
import ExternalMaterialShow from "./ExternalMaterialShow.vue";

export default {
  components: {
    MaterialDisplay,
    ExternalMaterialShow,
    SaveStatus,
  },

  mixins: [MaterialStateMixin, MaterialMixin],

  props: {
    initialMeeting: {
      type: Object,
      required: true,
    },

    initialMaterial: {
      type: Object,
      required: true,
    },

    liveUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    let maxDate = null;

    this.initialMaterial.items.forEach((item) => {
      if (item.updated_at) {
        const date = parseISO(item.updated_at);

        if (!maxDate || date > maxDate) {
          maxDate = date;
        }
      }
    });

    return {
      latestUpdate: maxDate,
      toggleComments: false,
    };
  },

  channels: {
    CompaniesMaterialsChannel: {
      connected() {
        console.log("connected");
      },

      rejected() {},

      received(response) {
        const { item } = response;

        if (item) {
          if (item.deleted_at) {
            this.removeItem(item);
          } else {
            this.loadItem(item);
          }
        }
        this.latestUpdate = new Date();
      },

      disconnected() {},
    },
  },

  computed: {
    ...mapGetters({
      meeting: "material/meeting",
      material: "material/material",
      attachments: "material/attachments",
      items: "material/items",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    withComments() {
      return ["minutes", "review", "approve", "sign", "archived"].includes(
        this.meeting.process.active_state
      );
    },

    commentsReadOnly() {
      return ["approve", "sign", "archived"].includes(
        this.meeting.process.active_state
      );
    },

    lastSavedAtText() {
      if (this.latestUpdate) {
        return this.$t(
          "components.meetings.material_show.last_updated_w_time",
          {
            time: this.$d(this.latestUpdate, "dateTime"),
          }
        );
      }
      return "";
    },
  },

  async created() {
    this.loadMeeting(this.initialMeeting);
    this.loadItemsAndMaterial(this.initialMaterial);

    const followUps = [
      "follow_up_reports",
      "follow_up_decisions",
      "follow_up_meetings",
      "follow_up_tasks",
    ];
    if (
      this.initialMaterial.items.some((item) =>
        followUps.includes(item.subtitle)
      )
    ) {
      this.fetchFollowUpReferences();
    }

    if (this.initialMaterial.material_type === "minutes") {
      this.fetchAttachments();

      if (this.liveUpdate) {
        this.$cable.subscribe(
          {
            channel: "Companies::MaterialsChannel",
            company_id: this.$currentCompany.id,
          },
          "CompaniesMaterialsChannel"
        );
      }
    }
  },

  methods: {
    ...mapActions("material", ["fetchAttachments", "fetchFollowUpReferences"]),

    ...mapMutations("material", [
      "loadItemsAndMaterial",
      "loadMeeting",
      "loadItem",
      "removeItem",
    ]),
  },
};
</script>
