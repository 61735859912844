<template>
  <div>
    <div class="card mb-2">
      <div
        class="card-header d-md-flex justify-content-between align-items-center"
      >
        <attendance-save-status class="mb-3 mb-md-0" text-class="small" />

        <be-button variant="primary" @click="$emit('update-page')">
          {{ $t("models.material.minutes.edit") }}
        </be-button>
      </div>

      <div class="card-body">
        <be-skeleton-table
          v-show="!hasLoaded"
          :rows="7"
          :columns="8"
          hide-footer
        />

        <div v-show="hasLoaded">
          <be-alert v-if="attendances.length === 0" variant="info">
            {{ $t("components.meetings.material.attendances.no_attendances") }}
          </be-alert>

          <template v-else>
            <be-alert v-if="hasWarnings" variant="warning">
              {{
                $t(
                  "components.meetings.material.attendances.requirements_warning"
                )
              }}
            </be-alert>

            <div class="table-responsive">
              <table class="table table-hover table-striped pt-3">
                <thead>
                  <tr>
                    <th
                      v-for="field in fields"
                      :key="field.key"
                      :class="field.class"
                    >
                      <div class="d-inline-block">
                        <span>
                          {{ field.label }}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <attendance-row
                    v-for="attendance in attendances"
                    :key="attendance.id"
                    :attendance="attendance"
                    @edit-attendance="handleEditAttendance"
                  />
                </tbody>
              </table>
            </div>
          </template>

          <be-dropdown v-if="pendingAttendances.length > 0">
            <template #button-content>
              {{ $t("components.meetings.material.attendances.add_more") }}
            </template>

            <template v-for="attendance in pendingAttendances">
              <be-dropdown-item
                v-if="attendance.name"
                :key="attendance.user_id"
                @click="handleAdd(attendance)"
              >
                {{ attendance.name }}
              </be-dropdown-item>
            </template>

            <be-dropdown-divider v-if="pendingAttendances.length > 0" />

            <be-dropdown-item
              variant="outline-secondary"
              size="sm"
              @click="showAddNewAttendance"
            >
              {{ $t("components.meetings.material.attendances.add_attendee") }}
            </be-dropdown-item>
          </be-dropdown>

          <be-button
            v-else
            variant="outline-secondary"
            @click="showAddNewAttendance"
          >
            {{ $t("components.meetings.material.attendances.add_more") }}
          </be-button>
        </div>
      </div>

      <material-editor-footer
        current-page="attendances"
        @update-page="$emit('update-page')"
      />
    </div>

    <external-attendance-form
      v-if="editAttendance"
      :attendance="editAttendance"
      @update="handleExternalAttendanceUpdate"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import AttendanceSaveStatus from "./AttendanceSaveStatus.vue";
import MaterialEditorFooter from "./MaterialEditorFooter.vue";
import AttendanceRow from "./AttendanceRow.vue";
import ExternalAttendanceForm from "./ExternalAttendanceForm.vue";

export default {
  components: {
    AttendanceSaveStatus,
    AttendanceRow,
    MaterialEditorFooter,
    ExternalAttendanceForm,
  },

  emits: ["update-page"],

  data() {
    return {
      editAttendance: null,
    };
  },

  computed: {
    ...mapGetters({
      attendances: "attendances/attendances",
      newAttendance: "attendances/newAttendance",
      pendingAttendances: "attendances/pendingAttendances",
      hasWarnings: "attendances/hasWarnings",
      hasLoaded: "attendances/hasLoaded",
      meeting: "material/meeting",
    }),

    fields() {
      return [
        { key: "avatar", label: "", class: "col-shrink text-center" },
        {
          key: "name",
          label: this.translateAttribute("user", "name"),
        },
        {
          key: "present",
          label: this.translateAttribute("attendance", "present"),
        },
        {
          key: "reported_absence",
          label: this.translateAttribute("attendance", "reported_absence"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "function",
          label: this.translateAttribute("membership", "function"),
        },
        {
          key: "secretary",
          label: this.translateAttribute("attendance", "secretary"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "reviewer",
          label: this.translateAttribute("attendance", "reviewer"),
          class: "col-shrink rotate-45deg",
        },
        {
          key: "signatory",
          label: this.translateAttribute("attendance", "signatory"),
          class: "col-shrink rotate-45deg",
        },
        { key: "action", label: "", class: "col-shrink text-center" },
      ];
    },
  },

  methods: {
    ...mapActions("attendances", [
      "addAttendance",
      "updateAttendance",
      "updateAttendanceUnhandled",
      "loadNewAttendance",
      "removeAttendance",
    ]),

    ...mapMutations("attendances", ["setNotLoading"]),

    async handleAdd(attendance) {
      this.addAttendance({ attendance, meeting: this.meeting });
    },

    async handleExternalAttendanceUpdate(attendance) {
      try {
        if (attendance.id) {
          await this.updateAttendanceUnhandled({
            attendance: attendance,
            meeting: this.meeting,
          });
        } else {
          await this.addAttendance({
            attendance: attendance,
            meeting: this.meeting,
          });
        }

        this.editAttendance = null;
      } catch (error) {
        if (error?.response?.status === 422) {
          this.editAttendance = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.setNotLoading();
      }
    },

    handleEditAttendance(attendance) {
      this.editAttendance = attendance;
    },

    async showAddNewAttendance() {
      await this.loadNewAttendance(this.meeting);
      this.editAttendance = this.newAttendance;
    },
  },
};
</script>
