<template>
  <div>
    <be-tabs v-model="activeTab" :options="tabsData" />

    <signature-case-list :signature-cases="activeCases" />
  </div>
</template>

<script>
import SignatureCaseList from "./SignatureCaseList.vue";

export default {
  components: {
    SignatureCaseList,
  },

  props: {
    documents: {
      type: Array,
      required: true,
    },

    meetings: {
      type: Array,
      required: false,
      default: () => [],
    },

    recentlySignedDocuments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeTab: "my-pending",
    };
  },

  computed: {
    activeCases() {
      if (this.activeTab === "my-pending") {
        return this.myPendingCases;
      } else if (this.activeTab === "other-pending") {
        return this.otherPendingCases;
      } else if (this.activeTab === "rejected") {
        return this.rejectedCases;
      } else if (this.activeTab === "recently-signed") {
        return this.recentlySignedCases;
      }

      return [];
    },

    myPendingCases() {
      const documents = this.documents.filter(
        (document) =>
          document.signature_status === "pending" &&
          document.signatures.some(
            (signature) =>
              signature.user_id === this.$currentUser.id && !signature.signed_at
          )
      );

      const meetings = this.meetings.filter(
        (meeting) =>
          meeting.signature_status === "pending" &&
          meeting.attendances.some(
            (attendance) =>
              attendance.user_id === this.$currentUser.id &&
              !attendance.signed_at
          )
      );

      return this.sortDocumentsMeetings(documents, meetings);
    },

    otherPendingCases() {
      const documents = this.documents.filter(
        (document) =>
          document.signature_status === "pending" &&
          !document.signatures.some(
            (signature) =>
              signature.user_id === this.$currentUser.id && !signature.signed_at
          )
      );

      const meetings = this.meetings.filter(
        (meeting) =>
          meeting.signature_status === "pending" &&
          !meeting.attendances.some(
            (attendance) =>
              attendance.user_id === this.$currentUser.id &&
              !attendance.signed_at
          )
      );

      return this.sortDocumentsMeetings(documents, meetings);
    },

    // Rejected signature cases for meetings are immediately removed
    rejectedCases() {
      const documents = this.documents.filter(
        (document) => document.signature_status === "rejected"
      );
      return this.sortDocumentsMeetings(documents, []);
    },

    // We could show meetings here, but maybe best not to.
    recentlySignedCases() {
      return this.sortDocumentsMeetings(this.recentlySignedDocuments, []);
    },

    tabsData() {
      return {
        myPending: {
          value: "my-pending",

          label: this.$t(
            "components.documents.signature_index.tabs.my_pending"
          ),

          count: this.myPendingCases.length,
        },

        otherPending: {
          value: "other-pending",

          label: this.$t(
            "components.documents.signature_index.tabs.other_pending"
          ),

          count: this.otherPendingCases.length,
        },

        rejected: {
          value: "rejected",

          label: this.$t("components.documents.signature_index.tabs.rejected"),

          count: this.rejectedCases.length,
        },

        recentlySigned: {
          value: "recently-signed",

          label: this.$t(
            "components.documents.signature_index.tabs.recently_signed"
          ),

          count: this.recentlySignedCases.length,
        },
      };
    },
  },

  methods: {
    sortDocumentsMeetings(documents, meetings) {
      const results = [];

      documents.forEach((document) => {
        results.push({
          type: this.isAnnualReport(document) ? "annual_report" : "document",

          requested_at:
            document.signatures[0]?.created_at || document.created_at,

          ...document,
        });
      });

      meetings.forEach((meeting) => {
        results.push({
          type: "meeting",

          requested_at: meeting.minutes.approved_at,

          ...meeting,
        });
      });

      // Newest first
      return results.sort((a, b) => {
        return new Date(b.requested_at) - new Date(a.requested_at);
      });
    },

    isAnnualReport(document) {
      return document.owner_type === "AnnualReport";
    },
  },
};
</script>
