<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <label>
        {{ $t("activerecord.attributes.shareholder_transaction.buyer") }}
      </label>

      <be-form-select
        v-if="
          localTransaction.id &&
          localTransaction.buyer &&
          localTransaction.buyer.id
        "
        v-model="localTransaction.buyer_id"
        required
        disabled
        :options="[formatShareholder(localTransaction.buyer)]"
      />

      <div
        v-if="
          ['previous_register', 'equity_issuance'].includes(actionType) &&
          !localTransaction.id
        "
      >
        <be-form-group>
          <be-form-radio-group
            id="buyer-type-group"
            v-model="selectedBuyerType"
            :options="selectedBuyerTypeOptions"
            name="shareholder_transaction[buyer_status]"
          />
        </be-form-group>
      </div>

      <div
        v-if="selectedBuyerType == 'new_shareholder' && !localTransaction.id"
      >
        <be-form-group>
          <be-form-radio-group
            id="buyer_type"
            v-model="shareholderBuyerType"
            :options="shareholderBuyerTypeOptions"
            name="shareholder_transaction[shareholder_buyer][shareholder_buyer_type]"
          />
        </be-form-group>

        <user-buyer-fields
          v-if="shareholderBuyerType == 'user'"
          name-prefix="shareholder_transaction"
          :shareholder="localTransaction.buyer || undefined"
        />

        <company-buyer-fields
          v-if="shareholderBuyerType == 'company'"
          name-prefix="shareholder_transaction"
          :shareholder="localTransaction.buyer || undefined"
        />
      </div>

      <div v-else-if="!localTransaction.id">
        <be-form-select
          v-model="localTransaction.buyer_id"
          name="shareholder_transaction[buyer_id]"
          required
          :options="formattedShareholders"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ShareholderRegisterMixins from "@/mixins/shareholderRegisters";

import CompanyBuyerFields from "../actions/types/CompanyBuyerFields.vue";
import UserBuyerFields from "../actions/types/UserBuyerFields.vue";

export default {
  components: {
    CompanyBuyerFields,
    UserBuyerFields,
  },

  mixins: [ShareholderRegisterMixins],

  props: {
    transaction: {
      type: Object,
      required: true,
    },

    actionType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      localTransaction: this.cloneDeep(this.transaction),
      selectedBuyerType: this.initialBuyerType(this.transaction),

      selectedBuyerTypeOptions: [
        { text: this.$t("models.shareholder.current"), value: "existing" },
        { text: this.$t("models.shareholder.new"), value: "new_shareholder" },
      ],

      shareholderBuyerType:
        this.transaction?.buyer?.shareholder_buyer_type || "user",

      shareholderBuyerTypeOptions: [
        { text: this.$t("models.shareholder.person"), value: "user" },
        { text: this.$t("models.shareholder.legal_person"), value: "company" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      shareholders: "shareholder_register/persistedShareholders",
    }),

    formattedShareholders() {
      return this.shareholders.map((shareholder) => {
        return this.formatShareholder(shareholder);
      });
    },
  },

  watch: {
    transaction(value) {
      this.localTransaction = this.cloneDeep(value);
    },

    selectedBuyerType(value) {
      if (value == "existing" && this.localTransaction.buyer_id == null) {
        this.localTransaction.buyer_id =
          this.formattedShareholders[0]?.value || null;
      }
    },
  },

  methods: {
    initialBuyerType(transaction) {
      if (this.actionType == "company_formation") {
        return "new_shareholder";
      } else if (transaction.buyer_status) {
        return transaction.buyer_status;
      } else {
        return "existing";
      }
    },
  },
};
</script>
