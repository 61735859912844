<template>
  <div class="card">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h3>
        {{ $t("components.meetings.material.minutes_approval_form.title") }}
      </h3>

      <be-button
        variant="danger"
        :icon="isSecretary ? '' : 'fa-envelope'"
        @click="decline"
      >
        {{ $t("models.material.minutes.decline") }}
      </be-button>
    </div>

    <div class="card-body">
      <div class="row">
        <div v-if="hasElectronicSignaturesFeature" class="col-12 col-md-6">
          <h4>
            {{
              $t(
                "components.meetings.material.minutes_approval_form.e_signature"
              )
            }}
          </h4>

          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-shrink"></th>

                  <th>{{ $t("attributes.name") }}</th>

                  <th>{{ $t("attributes.email") }}</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="attendance in signatoryAttendances"
                  :key="`attendance-${attendance.id}`"
                >
                  <td>
                    <user-avatar :user="attendance.name" />
                  </td>

                  <td>
                    <div>
                      {{ attendance.name }}
                    </div>

                    <small v-if="attendanceRole(attendance)">
                      {{ attendanceRole(attendance) }}
                    </small>
                  </td>

                  <td>{{ attendanceEmail(attendance) }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="d-flex justify-content-end">
            <be-button
              variant="primary"
              :icon="hasElectronicSignaturesFeature ? 'fa-envelope' : ''"
              @click="approve"
            >
              {{
                $t(
                  "components.meetings.material.minutes_approval_form.request_signatures"
                )
              }}
            </be-button>
          </div>
        </div>

        <div v-else-if="platformEnabled('shop')" class="col-12 col-md-6">
          <h4>
            {{
              $t(
                "components.meetings.material.minutes_approval_form.e_signature"
              )
            }}
          </h4>

          <be-alert variant="info" class="my-4">
            {{
              $t(
                "components.meetings.material.minutes_approval_form.activate_e_signature"
              )
            }}
          </be-alert>

          <be-button variant="outline-primary" :href="url('/shop')">
            {{ $t("application.shop") }}
          </be-button>
        </div>

        <div class="col-12 col-md-6">
          <h4>
            {{
              $t(
                "components.meetings.material.minutes_approval_form.upload_signed"
              )
            }}
          </h4>

          <p>
            {{
              $t(
                "components.meetings.material.minutes_approval_form.upload_signed_description"
              )
            }}
          </p>

          <be-form-group
            :label="
              $t(
                'components.meetings.tabs.sign.e_signature.upload.uploaded_title'
              )
            "
            label-for="document-uploader"
          >
            <document-uploader
              id="document-uploader"
              :max-files="1"
              :documents="documents"
              :manual-save="false"
              support-select-from-archive
              @document-added="documentAdded"
              @document-removed="documentRemoved"
              @uploading="(value) => (loading = value)"
            />
          </be-form-group>

          <div class="d-flex justify-content-end">
            <span
              v-be-tooltip="{
                title: $t(
                  'components.meetings.material.minutes_approval_form.mark_as_signed_tooltip'
                ),
                disabled: uploadReady,
              }"
            >
              <be-button
                :variant="
                  hasElectronicSignaturesFeature ? 'outline-primary' : 'primary'
                "
                :disabled="!uploadReady"
                icon="fa-envelope"
                @click="uploadSigned"
              >
                {{ $t("components.meetings.tabs.sign.mark_as_signed") }}
              </be-button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MaterialMixin from "@/mixins/meetings/material";

export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    minutes: {
      type: Object,
      required: true,
    },

    attendances: {
      type: Array,
      required: true,
    },
  },

  data() {
    const documents = [];
    if (this.minutes.external_document?.signed_at) {
      documents.push(this.minutes.external_document);
    }

    return {
      loading: false,
      documents: documents,
    };
  },

  computed: {
    ...mapGetters({
      hasActiveFeature: "company/hasActiveFeature",
    }),

    currentUserAttendance() {
      return this.attendances.find(
        (attendance) => attendance.user_id === this.$currentUser.id
      );
    },

    isSecretary() {
      return this.currentUserAttendance?.secretary;
    },

    signatoryAttendances() {
      return this.attendances.filter((attendance) => attendance.signatory);
    },

    hasElectronicSignaturesFeature() {
      return this.hasActiveFeature("e-signature");
    },

    uploadReady() {
      return this.documents[0]?.id;
    },
  },

  methods: {
    async approve() {
      let title = this.$t("models.material.minutes.confirm_approve");
      let text = "";

      if (this.hasElectronicSignaturesFeature) {
        title = this.$t("models.material.minutes.confirm_approve_esign");
        text = this.$t("models.material.minutes.confirm_approve_description");
      }

      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("models.material.minutes.approve"),
        title: title,
        description: text,
      });

      if (!isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await axios.post(`${this.meeting.paths.base}/minutes/approval`);
        window.location = `${this.meeting.paths.base}`;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async decline() {
      let text = this.$t("models.material.minutes.confirm_decline_description");

      if (!this.isSecretary) {
        text = this.$t(
          "models.material.minutes.confirm_decline_sends_email_description"
        );
      }

      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("models.material.minutes.decline"),
        title: this.$t("models.material.minutes.confirm_decline"),
        description: text,
      });

      if (!isConfirmed) {
        return;
      }

      this.loading = true;
      try {
        await axios.post(`${this.meeting.paths.base}/minutes/decline`);
        window.location.href = `${this.meeting.paths.base}/minutes`;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async uploadSigned() {
      const isConfirmed = await this.promptConfirm({
        confirmButtonText: this.$t("models.material.minutes.approve"),
        stayOpenAfterConfirm: true,

        title: this.$t(
          "components.meetings.material.minutes_approval_form.confirm_upload_signed"
        ),

        text: this.$t(
          "components.meetings.material.minutes_approval_form.confirm_upload_signed_text"
        ),
      });

      if (isConfirmed) {
        try {
          this.loading = true;

          await axios.post(
            `${this.meeting.paths.base}/minutes/manual_signature`,
            {
              document: this.documents[0],
            }
          );

          window.location.assign(`${this.meeting.paths.base}`);
        } catch (error) {
          this.handleError(error);
          this.loading = false;
        }
      }
    },

    async documentAdded(doc) {
      try {
        this.documents = [doc];
        this.loading = true;
        const { data } = await axios.post(
          `${this.meeting.paths.base}/minutes/signed_upload`,
          {
            document: doc,
          }
        );
        this.documents = [data.external_document].filter((doc) => doc);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async documentRemoved(doc) {
      this.loading = true;

      try {
        this.documents = [doc];
        await axios.delete(`${this.meeting.paths.base}/minutes/signed_upload`);

        this.documents = [];
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    attendanceEmail(attendance) {
      if (attendance.email) {
        return attendance.email;
      }

      return this.getUserEmail(attendance.user_id);
    },
  },
};
</script>
