import { handleError } from "@/utils/error-handling";
async function createMinutes(meeting) {
  try {
    await axios.post(`${meeting.paths.base}/minutes`);
    window.location = `${meeting.paths.base}/minutes/edit`;
  } catch (error) {
    handleError(error);
  }
}

async function createAgenda(meeting) {
  try {
    await axios.post(`${meeting.paths.base}/agenda`);
    window.location = `${meeting.paths.base}/agenda/edit`;
  } catch (error) {
    handleError(error);
  }
}

export default { createMinutes, createAgenda };
