<template>
  <be-modal
    id="confirm-membership-removal-modal"
    :title="title"
    centered
    @ok="handleOk"
    @hidden="handleHidden"
  >
    <template v-if="loading">
      {{ $t("components.memberships.confirm_removal.checking_status_of_user") }}
      <be-skeleton-table
        :rows="3"
        :columns="2"
        :table-props="{ striped: true }"
      />
    </template>

    <template v-else>
      <template v-if="nothingPending">
        <p
          :key="`${dompurifyKey}-title`"
          v-dompurify-html="
            $t('nav.confirm_delete_from_w_title_w_from', {
              title: membership.user.name,
              from: $t(
                'components.memberships.confirm_removal.the_organization'
              ),
            })
          "
        />
      </template>

      <template v-if="pending.length > 0">
        <span class="font-weight-bold">
          {{ $t("components.memberships.confirm_removal.pending_signatures") }}
        </span>

        <be-table-simple v-if="pending.length > 0">
          <thead>
            <tr>
              <th>
                {{ $t("attributes.title") }}
              </th>

              <th>
                {{ $t("attributes.type") }}
              </th>

              <th>
                {{ $t("attributes.date") }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="row in pending"
              :key="`pending-${rowType(row)}-${row.id}`"
            >
              <td>
                <be-link :href="rowLink(row)">
                  {{ rowTitle(row) }}
                </be-link>
              </td>

              <td>
                {{ rowType(row) }}
              </td>

              <td>{{ $d(new Date(rowDate(row)), "date") }}</td>
            </tr>
          </tbody>
        </be-table-simple>
      </template>

      <template v-if="pending.length > 0">
        <be-alert variant="warning">
          {{
            $t(
              "components.memberships.confirm_removal.cannot_sign_after_removal",
              {
                name: membership.user.name,
              }
            )
          }}
        </be-alert>

        <p
          :key="`${dompurifyKey}-confirm`"
          v-dompurify-html="
            $t(
              'components.memberships.confirm_removal.confirm_by_input_w_input_html',
              { input: expectedInput }
            )
          "
          for="confirm-input"
        />

        <be-form-group>
          <be-form-input
            id="confirm-input"
            v-model="confirmInput"
            v-autofocus
            :placeholder="expectedInput"
          />
        </be-form-group>
      </template>
    </template>

    <template #footer="{ ok, cancel }">
      <be-button variant="light" @click="cancel">
        {{ $t("buttons.titles.cancel") }}
      </be-button>

      <be-button variant="danger" :disabled="loading || !confirmed" @click="ok">
        {{ $t("buttons.titles.remove") }}
      </be-button>
    </template>
  </be-modal>
</template>

<script>
export default {
  props: {
    membership: {
      type: Object,
      required: true,
    },
  },

  emits: ["close", "confirmed"],

  data() {
    return {
      loading: true,
      pendingSignatures: [],
      pendingMeetings: [],
      confirmInput: "",
      dompurifyKey: this.generateUuid(),
    };
  },

  computed: {
    title() {
      return this.$t("components.memberships.confirm_removal.title_w_name", {
        name: this.membership.user.name,
      });
    },

    pending() {
      return [...this.pendingSignatures, ...this.pendingMeetings];
    },

    nothingPending() {
      return this.pending.length === 0;
    },

    confirmed() {
      if (this.nothingPending) {
        return true;
      }

      return [
        this.expectedInput,
        this.membership.user.name.toLowerCase(),
      ].includes(this.confirmInput.toLowerCase());
    },

    expectedInput() {
      return this.membership.user.name.toLowerCase().replace(" ", "-");
    },
  },

  watch: {
    membership() {
      this.loading = true;
      this.fetchMembership();
    },
  },

  mounted() {
    if (this.membership) {
      this.fetchMembership();
    }
  },

  methods: {
    async fetchMembership() {
      try {
        const { data } = await axios.get(
          this.url(`/memberships/${this.membership.id}/pending_signatures`)
        );

        this.pendingMeetings = data.meetings;
        this.pendingSignatures = data.documents;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    handleHidden() {
      this.$emit("close");
      this.confirmInput = "";
      this.pendingMeetings = [];
      this.pendingSignatures = [];
      this.loading = true;
      this.dompurifyKey = this.generateUuid();
    },

    handleOk() {
      this.$emit("confirmed", this.membership);
      this.confirmInput = "";
    },

    rowLink(row) {
      if (row.document) {
        return this.url(`/documents/${row.document.id}`);
      }

      return this.url(`/meetings/${row.id}`);
    },

    rowTitle(row) {
      if (row.document) {
        return row.document.filename;
      }

      return `#${row.number} - ${row.title}`;
    },

    rowDate(row) {
      if (row.document) {
        return row.document.created_at;
      }

      return row.start_at;
    },

    rowType(row) {
      if (row.document) {
        return this.$t("activerecord.models.document.one");
      }

      return this.$t("activerecord.models.meeting.one");
    },
  },
};
</script>
