<template>
  <div class="row mb-3">
    <div class="col-12 col-lg meeting start_at">
      <date-time-picker
        v-bind="pickerProps"
        v-model="startAt"
        :label="$t('activerecord.attributes.meeting.start_at')"
        label-class="d-block d-lg-none"
        :error-state="startAtErrors ? false : null"
        :error-message="startAtErrors"
        :disabled="disabled"
        @input="startAtUpdated"
      />
    </div>

    <div
      class="col col-lg-auto d-none d-xl-flex justify-content-center align-items-center align-self-center"
    >
      <i class="fas fa-dash" />
    </div>

    <div class="col-12 col-lg meeting end_at">
      <date-time-picker
        v-bind="pickerProps"
        v-model="endAt"
        :label="$t('activerecord.attributes.meeting.end_at')"
        label-class="d-block d-lg-none"
        :disabled="disabled"
        :error-state="endAtErrors ? false : null"
        :error-message="endAtErrors"
        :min="startAt"
        @input="endAtUpdated"
      />
    </div>
  </div>
</template>

<script>
import { isAfter, differenceInMinutes, addMinutes, isEqual } from "date-fns";
import DateTimePicker from "@/components/form/DateTimePicker.vue";

export default {
  components: { DateTimePicker },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["update"],

  data() {
    // Some meetings have end_at == null
    let startAt = null;
    let endAt = null;
    if (this.meeting.start_at) {
      startAt = new Date(this.meeting.start_at);
    } else {
      startAt = new Date();
    }
    if (this.meeting.end_at) {
      endAt = new Date(this.meeting.end_at);
    }
    if (!endAt) {
      endAt = addMinutes(startAt, 60);
    }
    return {
      startAt: startAt,
      endAt: endAt,
    };
  },

  computed: {
    pickerProps() {
      return {
        minutesStep: 5,
      };
    },

    startAtErrors() {
      return this.getErrors(this.meeting, "start_at");
    },

    endAtErrors() {
      let errors = "";

      if (this.meeting.errors?.end_at) {
        errors += this.meeting.errors.end_at;
      }

      if (this.endAt <= this.startAt) {
        const message = this.$t("errors.messages.later_than", {
          first: this.$t("activerecord.attributes.meeting.end_at"),
          second: this.$t("activerecord.attributes.meeting.start_at"),
        });
        if (!errors.includes(message)) {
          errors += " " + message;
        }
      }

      return errors.length > 0 ? errors : null;
    },
  },

  watch: {
    "meeting.end_at"(value) {
      this.endAt = new Date(value);
    },

    "meeting.start_at"(value) {
      this.startAt = new Date(value);
    },
  },

  methods: {
    startAtUpdated(value) {
      const startValue = new Date(this.startAt);
      let endValue = new Date(this.endAt);
      const newStartValue = new Date(value);

      // update endValue with startValue + old time range
      if (
        isAfter(newStartValue, endValue) ||
        isEqual(newStartValue, endValue)
      ) {
        const minutesDiff = differenceInMinutes(endValue, startValue);
        if (minutesDiff > 0) {
          endValue = addMinutes(newStartValue, minutesDiff);
        } else {
          endValue = addMinutes(newStartValue, 60);
        }
      }

      this.startAt = newStartValue;
      this.endAt = endValue;
      this.updated();
    },

    endAtUpdated(value) {
      this.endAt = value;
      this.updated();
    },

    updated() {
      this.$emit("update", {
        startAt: this.startAt,
        endAt: this.endAt,
      });
    },
  },
};
</script>
