<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'title')"
            label-for="meeting-title"
            :error="getErrors(localMeeting, ['title'])"
          >
            <be-form-input
              id="meeting-title"
              v-model="localMeeting.title"
              :placeholder="$t('agendas.enter_title')"
              max="64"
              :disabled="isSealed"
              required
              @change="clearErrors(localMeeting, ['title'])"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'number')"
            label-for="meeting-number"
            :error="
              meetingNumber.error_message || getErrors(localMeeting, ['number'])
            "
            required
          >
            <template #label-after>
              <i
                v-be-tooltip="
                  $t('components.meetings.editor.unique_number_notice')
                "
                class="fal fa-question-circle ml-2"
              />
            </template>

            <be-input-group>
              <be-form-select
                v-if="meetingSeriesEnabled"
                id="meeting-series-type"
                v-model="localMeeting.meeting_series_type"
                :options="meetingSeriesTypes"
                :disabled="isSealed"
                required
                :confirm-change="!!localMeeting.id"
                :confirm-change-title="
                  $t(
                    'components.meetings.editor.confirm_meeting_series_type_change_title'
                  )
                "
                :confirm-change-text="
                  $t(
                    'components.meetings.editor.confirm_meeting_series_type_change_body'
                  )
                "
              />

              <be-form-input
                id="meeting-number"
                v-model="localMeeting.number"
                :state="
                  meetingNumberState &&
                  validationState(localMeeting, ['number'])
                "
                :disabled="isSealed"
                min="1"
                type="number"
                number
                required
              />
            </be-input-group>
          </be-form-group>

          <template v-if="canOverrideMeetingNumber">
            <be-form-checkbox v-model="numberOverride" class="my-2">
              {{ $t("simple_form.hints.meeting.number_override") }}
            </be-form-checkbox>
          </template>
        </div>
      </div>

      <be-form-group
        :label="translateAttribute('meeting', 'time')"
        label-class="d-none d-lg-block"
      >
        <meeting-date-picker
          :meeting="localMeeting"
          :disabled="isSealed"
          @update="datesUpdated"
        />

        <meeting-suggestions
          :meeting="localMeeting"
          :disabled="isSealed"
          :errors="errorsOfType('suggestion')"
          @is-valid="setSuggestionsValid"
          @mark-availability="markAvailability"
          @select-suggestion="selectSuggestion"
          @update:meeting="(value) => (localMeeting = value)"
        />
      </be-form-group>

      <hr />

      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'location')"
            label-for="meeting-location"
            :error="getErrors(localMeeting, ['location'])"
          >
            <template #label-after>
              <i
                v-if="!hasActiveFeature({ name: 'video_meeting' })"
                v-be-tooltip="$t('components.meetings.editor.location_tooltip')"
                class="fal fa-fw fa-question-circle"
              />
            </template>

            <be-form-input
              id="meeting-location"
              v-model="localMeeting.location"
              max="64"
              :disabled="isSealed"
              @change="clearErrors(localMeeting, ['location'])"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'theme')"
            label-for="meeting-theme"
            :error="getErrors(localMeeting, ['theme'])"
          >
            <be-form-input
              id="meeting-theme"
              v-model="localMeeting.theme"
              :disabled="isSealed"
              @change="clearErrors(localMeeting, ['theme'])"
            />
          </be-form-group>
        </div>
      </div>

      <template v-if="hasActiveFeature({ name: 'video_meeting' })">
        <be-form-checkbox
          v-model="localMeeting.video_option"
          class="mt-2 mb-3"
          :description="$t('simple_form.hints.meeting.video_option')"
        >
          {{ translateAttribute("meeting", "video_option") }}
        </be-form-checkbox>
      </template>

      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'reason')"
            label-for="meeting-reason"
            :error="getErrors(localMeeting, ['reason'])"
          >
            <be-form-textarea
              id="meeting-reason"
              v-model="localMeeting.reason"
              :disabled="!canSave"
              name="reason"
              rows="2"
              max-rows="20"
              :state="validationState(localMeeting, ['reason'])"
              @change="clearErrors(localMeeting, ['reason'])"
            />
          </be-form-group>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label="translateAttribute('meeting', 'preparations')"
            label-for="meeting-preparations"
            :error="getErrors(localMeeting, ['preparations'])"
          >
            <be-form-textarea
              id="meeting-preparations"
              v-model="localMeeting.preparations"
              name="preparations"
              rows="2"
              max-rows="20"
              :disabled="!canSave"
              :state="validationState(localMeeting, ['preparations'])"
              @change="clearErrors(localMeeting, ['preparations'])"
            />
          </be-form-group>
        </div>
      </div>

      <hr />

      <template v-if="!isSealed">
        <be-form-group class="mb-0">
          <be-form-checkbox
            v-model="localMeeting.inform_users"
            name="inform_users"
            :description="$t('components.meetings.editor.inform_users')"
          >
            {{ $t("simple_form.labels.meeting.inform_users") }}
          </be-form-checkbox>
        </be-form-group>

        <invitation-batch
          v-if="localMeeting.inform_users"
          v-model:message="invitationBatch.message"
          v-model:selected-user-ids="selectedUserIds"
          class="mt-2 mb-4"
          preinvitation
          :invitations="invitations"
          :invitation-header="$t('components.meetings.invitations.sent_at')"
          @memberships-updated="membershipsUpdated"
        />
      </template>
    </div>

    <div v-if="canSave" class="card-footer text-right">
      <be-button
        v-if="localMeeting.inform_users"
        v-be-tooltip="{
          title: $t('components.meetings.invitations.invite_disabled'),
          disabled: !saveDisabled,
        }"
        type="submit"
        variant="primary"
        :disabled="isLoading || saveDisabled"
        @click="onSubmit('send_save')"
      >
        <i class="fal fa-envelope mr-1" />
        {{ $t("buttons.titles.save_and_continue") }}
      </be-button>

      <be-button
        v-if="!localMeeting.inform_users"
        type="submit"
        variant="outline-secondary"
        :disabled="isLoading"
        @click="onSubmit('save')"
      >
        {{ $t("buttons.titles.save") }}
      </be-button>

      <be-button
        v-if="!localMeeting.inform_users"
        type="submit"
        variant="primary"
        :disabled="isLoading"
        @click="onSubmit('save_continue')"
      >
        {{ $t("buttons.titles.save_and_continue") }}
      </be-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  saveMeeting,
  createMeeting,
  requestMeetingNumberValidation,
} from "./requests";
import MeetingSuggestions from "./components/MeetingSuggestions.vue";
import InvitationBatch from "@/components/meetings/InvitationBatch.vue";
import MeetingDatePicker from "@/components/meetings/components/DatePicker.vue";

export default {
  components: {
    InvitationBatch,
    MeetingDatePicker,
    MeetingSuggestions,
  },

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    loadedInvitations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      localMeeting: this.cloneDeep(this.meeting),

      invitationBatch: {
        message: "",
      },

      isLoading: false,
      isSending: false,
      validSuggestions: true,

      meetingNumber: {
        type: "pending",
        allowed: false,
        error_message: "",
      },

      numberOverride: false,

      selectedUserIds: [],
    };
  },

  computed: {
    ...mapGetters({
      $currentCompany: "company/getCompany",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    invitations() {
      return this.$store.getters["meetings/getInvitationsForMeeting"](
        this.localMeeting?.id
      );
    },

    meetingSeriesTypes() {
      return [
        {
          text: this.$t("models.meeting.meeting_series_type.board.one"),

          value: "board",
        },
        {
          text: this.$t("models.meeting.meeting_series_type.annual.one"),

          value: "annual",
        },
      ];
    },

    meetingSeriesEnabled() {
      return (
        this.$platform.features.meeting_series &&
        this.flipperFlag("meeting_series_flipper")
      );
    },

    haveSuggestions() {
      return this.localMeeting.suggestions.length > 0;
    },

    canOverrideMeetingNumber() {
      return this.meetingNumber.type === "cascade";
    },

    isSealed() {
      return this.localMeeting.is_sealed;
    },

    canSave() {
      return !this.localMeeting.is_sealed && this.localMeeting.policy.update;
    },

    saveDisabled() {
      return this.selectedUserIds.length === 0;
    },

    validForm() {
      return (
        this.localMeeting?.title?.length > 0 &&
        this.localMeeting.start_at &&
        this.localMeeting.end_at &&
        this.meetingNumber.allowed &&
        this.validSuggestions
      );
    },

    meetingNumberState() {
      if (this.localMeeting.errors?.numbers) {
        return false;
      } else if (this.meetingNumber.type !== "pending") {
        return this.meetingNumber.allowed;
      }
      return null;
    },
  },

  watch: {
    "localMeeting.meeting_series_type": async function () {
      if (!this.localMeeting.id) {
        await this.requestNextMeetingNumber();
      } else {
        const requestMeetingNumber = await requestMeetingNumberValidation(
          this.localMeeting,
          this.url
        );
        this.meetingNumber = requestMeetingNumber;
      }
    },

    "localMeeting.number": async function () {
      const requestMeetingNumber = await requestMeetingNumberValidation(
        this.localMeeting,
        this.url
      );
      this.meetingNumber = requestMeetingNumber;
    },
  },

  async created() {
    this.$store.commit("meetings/loadInvitationsForMeeting", {
      meeting: this.localMeeting,
      invitations: this.loadedInvitations,
    });
  },

  async mounted() {
    this.meetingNumber = await requestMeetingNumberValidation(
      this.localMeeting,
      this.url
    );
  },

  methods: {
    async requestNextMeetingNumber() {
      try {
        const response = await axios.get(this.url("/meetings/next_number"), {
          params: {
            meeting_series_type: this.localMeeting.meeting_series_type,
          },
        });
        this.localMeeting.number = response.data.next_number;
      } catch (error) {
        this.handleError(error);
      }
    },

    async markAvailability(suggestionData) {
      try {
        const response = await axios.patch(
          this.url(`/suggestions/${suggestionData.suggestion.id}/choices`),
          {
            choice: {
              availability: suggestionData.availability,
            },
          }
        );

        this.updateMeetingSuggestionChoice(
          suggestionData.suggestion.id,
          response.data
        );
      } catch (error) {
        this.handleError(error);
      }
    },

    updateMeetingSuggestionChoice(suggestionId, choice) {
      const suggestion = this.localMeeting.suggestions.find(
        (existing) => existing.id == suggestionId
      );

      if (suggestion == null) {
        return;
      }

      let index = suggestion.suggestion_choices.findIndex(
        (existing) => existing.id == choice.id
      );

      if (index === -1) {
        suggestion.suggestion_choices.push(choice);
      } else {
        suggestion.suggestion_choices[index] = choice;
      }
    },

    async selectSuggestion(suggestion) {
      try {
        await axios.post(`${this.localMeeting.paths.base}/pick_date`, {
          suggestion_id: suggestion.id,
        });

        const newSuggestions = this.cloneDeep(this.localMeeting.suggestions);

        newSuggestions.forEach((newSuggestion) => {
          newSuggestion.selected_at =
            newSuggestion.id === suggestion.id
              ? new Date().toISOString()
              : null;
        });

        this.localMeeting.start_at = suggestion.start_at;
        this.localMeeting.end_at = suggestion.end_at;
        this.localMeeting.suggestions = newSuggestions;
      } catch (error) {
        this.handleError(error);
      }
    },

    async setSuggestionsValid(state) {
      this.validSuggestions = state;
    },

    async onSubmit(submitType) {
      const sendInvites = this.localMeeting.inform_users;
      let meetingUpdated = false;
      if (this.localMeeting.created_at !== null && submitType === "save") {
        submitType = "save_and_stay";
      }

      try {
        this.isLoading = true;
        if (!this.localMeeting.id) {
          this.localMeeting = await createMeeting(
            this.localMeeting,
            this.numberOverride,
            this.url,
            ["save_continue", "send_save"].includes(submitType)
          );
          meetingUpdated = true;
        } else {
          this.localMeeting = await saveMeeting(
            this.localMeeting,
            this.numberOverride,
            ["save_continue", "send_save"].includes(submitType)
          );
          meetingUpdated = true;
        }

        if (meetingUpdated && sendInvites) {
          // keep the invitation component open after meeting update
          this.localMeeting.inform_users = true;
          await this.sendInvites();
        }

        if (meetingUpdated) {
          switch (submitType) {
            case "save_continue":
              this.navigateToNextStep();
              break;
            case "send_save":
              this.navigateToNextStep();
              break;
            case "save":
              this.navigateToEditView();
              break;
          }
        }
      } catch (error) {
        if (error?.response?.status === 422) {
          this.localMeeting = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.isLoading = false;
      }
    },

    errorsOfType(type) {
      const { errors } = this.localMeeting;

      if (errors) {
        const filteredErrors = [];
        Object.keys(errors).forEach((key) => {
          if (key.startsWith(type)) {
            filteredErrors.push(errors[key]);
          }
        });
        return filteredErrors;
      } else {
        return [];
      }
    },

    navigateToNextStep() {
      const url = `${this.localMeeting.paths.base}/agenda/edit`;
      window.location.href = url;
    },

    navigateToEditView() {
      window.location.href = this.localMeeting.paths.edit;
    },

    invitedUsers() {
      return this.invitations.filter(
        (invitation) =>
          invitation.deleted_at == null && invitation.created_at !== null
      );
    },

    membershipsUpdated() {
      this.$store.dispatch(
        "meetings/fetchInvitationsForMeeting",
        this.localMeeting
      );
    },

    async sendInvites() {
      this.isSending = true;

      try {
        const response = await this.$store.dispatch(
          "meetings/saveInvitationBatchUnhandled",
          {
            meeting: this.localMeeting,

            invitationBatch: {
              message: this.invitationBatch.message,
              include_agenda: false,
              preinvitation: true,
              user_ids: this.selectedUserIds,
            },
          }
        );
        response.data.invitations.forEach((invitation) => {
          this.$store.commit("meetings/updateInvitation", invitation);
        });
        this.invitationBatch.message = "";
      } catch (error) {
        if (error?.response?.status === 422) {
          this.invitationBatch = error.response.data;
        } else {
          this.handleError(error);
        }
      } finally {
        this.isSending = false;
      }
    },

    datesUpdated(value) {
      this.clearErrors(this.localMeeting, ["end_at", "start_at"]);
      this.localMeeting.start_at = value.startAt;
      this.localMeeting.end_at = value.endAt;
    },
  },
};
</script>
