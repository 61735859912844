<template>
  <be-modal
    id="notification-settings"
    :title="$t('components.user.notification_settings.title')"
    :ok-title="$t('buttons.titles.close')"
    ok-variant="light"
    ok-only
  >
    <p class="mb-3">
      {{ $t("components.user.notification_settings.checkbox_header") }}
    </p>

    <be-form-checkbox
      v-for="notificationType in notificationTypes"
      :key="notificationType"
      :checked="
        !$currentUser.disabled_email_notifications.includes(notificationType)
      "
      @change="(checked) => toggleNotification(notificationType, checked)"
    >
      {{ $t(`models.notification.descriptions.${notificationType}`) }}
    </be-form-checkbox>
  </be-modal>
</template>

<script>
import { handleError } from "@/utils/error-handling";
export default {
  props: {
    notificationTypes: {
      type: Array,
      required: true,
    },
  },

  methods: {
    async toggleNotification(notificationType, checked) {
      try {
        const { data } = await axios.patch("/users/notification_settings", {
          notification_setting: {
            type: notificationType,
            value: checked,
          },
        });

        const user = this.cloneDeep(this.$currentUser);
        user.disabled_email_notifications = data;
        this.$store.commit("current_user/setUser", user);
      } catch (e) {
        handleError(e);
      }
    },
  },
};
</script>
