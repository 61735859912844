<template>
  <section>
    <be-tabs v-model="activeTab" :options="tabsData">
      <template v-if="canExportDecisions" #content-right>
        <be-dropdown ellipsis>
          <be-dropdown-item v-be-modal.export-decisions>
            {{ exportModalTitle }}
          </be-dropdown-item>
        </be-dropdown>

        <export-modal-with-filters
          id="export-decisions"
          :export-types="['decisions_pdf', 'decisions_excel']"
          :filter-types="[
            'decisions',
            'decisions_my_active',
            'decisions_active',
            'decisions_archived',
          ]"
          :title="exportModalTitle"
          :preselected-options="exportPreselectedOptions"
        />
      </template>
    </be-tabs>

    <div v-if="filteredDecisions" class="card">
      <div class="card-body">
        <decisions-table
          :key="activeTab"
          :initial-decisions="filteredDecisions"
          @decision-status-updated="updateDecisions"
        />
      </div>
    </div>

    <be-alert v-else variant="warning">
      {{ $t("decisions.empty_decisions") }}
    </be-alert>
  </section>
</template>

<script>
export default {
  props: {
    initialDecisions: {
      type: Array,
      required: false,
      default: () => [],
    },

    selectedProject: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      activeTab: "my-active-decisions",
      decisions: this.initialDecisions,
    };
  },

  computed: {
    tabsData() {
      return {
        "my-active-decisions": {
          value: "my-active-decisions",
          label: this.$t("decisions.nav.my_decisions"),
          count: this.decisionCount("my-active-decisions"),
        },

        "all-active-decisions": {
          value: "all-active-decisions",
          label: this.$t("decisions.nav.active"),
          count: this.decisionCount("all-active-decisions"),
        },

        "archived-decisions": {
          value: "archived-decisions",
          label: this.$t("decisions.nav.inactive"),
          count: this.decisionCount("archived-decisions"),
        },

        "all-decisions": {
          value: "all-decisions",
          label: this.$t("decisions.nav.all"),
          count: this.decisionCount(),
        },
      };
    },

    filteredDecisions() {
      if (this.activeTab == "my-active-decisions") {
        return this.userActiveDecisions;
      } else if (this.activeTab == "all-active-decisions") {
        return this.allActiveDecisions;
      } else if (this.activeTab == "archived-decisions") {
        return this.inactiveDecisions;
      } else {
        return this.decisions;
      }
    },

    canExportDecisions() {
      return this.platformAdmin && this.selectedProject == null;
    },

    userActiveDecisions() {
      return this.decisions.filter(
        (decision) =>
          decision.active && decision.user_id === this.$currentUser.id
      );
    },

    allActiveDecisions() {
      return this.decisions.filter((decision) => decision.active);
    },

    inactiveDecisions() {
      return this.decisions.filter((decision) => !decision.active);
    },

    decisionCount() {
      return (tab) => {
        if (tab == "my-active-decisions") {
          return this.userActiveDecisions.length;
        } else if (tab == "all-active-decisions") {
          return this.allActiveDecisions.length;
        } else if (tab == "archived-decisions") {
          return this.inactiveDecisions.length;
        } else {
          return this.decisions.length;
        }
      };
    },

    exportModalTitle() {
      return this.$t("buttons.titles.export_w_title", {
        title: this.$t("activerecord.models.decision.other").toLowerCase(),
      });
    },

    exportPreselectedOptions() {
      return {
        exportType: "decisions_pdf",
        filterType: this.getFilterTypeFromTab(),
      };
    },
  },

  mounted() {
    this.decisions.forEach((decision) => (decision.loading = false));
  },

  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },

    updateDecisions(decision, updatedDecision) {
      let idx = this.decisions.findIndex((existingDecision) => {
        return existingDecision.id == decision.id;
      });

      if (idx != -1) {
        updatedDecision.loading = false;
        this.decisions[idx] = updatedDecision;
      }
    },

    getFilterTypeFromTab() {
      switch (this.activeTab) {
        case "my-active-decisions":
          return "decisions_my_active";
        case "all-active-decisions":
          return "decisions_active";
        case "archived-decisions":
          return "decisions_archived";
        default:
          return "decisions";
      }
    },
  },
};
</script>
