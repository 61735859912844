<template>
  <be-table-simple>
    <thead>
      <tr>
        <th>
          {{ $t("activerecord.attributes.company.title") }}
        </th>

        <th>
          {{ $t("activerecord.attributes.company.organization_number") }}
        </th>

        <th>
          {{ $t("attributes.created_at") }}
        </th>

        <th />
      </tr>
    </thead>

    <tbody>
      <tr v-for="company in companies" :key="key(company)">
        <td>{{ company.title }}</td>

        <td>
          {{ company.organization_number }}
        </td>

        <td>
          {{ $d(new Date(company.created_at), "dateTime") }}
        </td>

        <td class="text-right">
          <be-button
            size="sm"
            variant="outline-secondary"
            :href="`/admin_panel/${adminPanel.id}/companies/${company.nanoid}/edit`"
            inline
          >
            {{ $t("buttons.titles.edit") }}
          </be-button>
        </td>
      </tr>
    </tbody>
  </be-table-simple>
</template>

<script>
export default {
  props: {
    companies: {
      type: Array,
      required: true,
    },

    adminPanel: {
      type: Object,
      required: true,
    },
  },

  methods: {
    key(company) {
      return `companies-${company.id || "new"}`;
    },
  },
};
</script>
