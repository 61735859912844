<template>
  <div>
    <bank-id-collect
      v-if="returningFromBankId()"
      :redirect-on-success="redirectOnSuccess"
      collect-url="/bank_id/collect/activation"
      modal
      @bankid-collect-success="
        didActivate = true;
        $beModal.hide('bankid-activation-modal');
      "
    />

    <div v-if="$currentUser.identification_number_verified || didActivate">
      <be-alert variant="success">
        {{ $t("bankid.is_activated") }}
      </be-alert>

      <be-button variant="danger" @click="deactivate">
        {{ $t("buttons.titles.deactivate") }}
      </be-button>
    </div>

    <div v-else>
      <be-button @click="$beModal.show('bankid-activation-modal')">
        {{ $t("buttons.titles.activate") }}
      </be-button>

      <be-modal
        id="bankid-activation-modal"
        :title="$t('bankid.activate_bank_id')"
        no-footer
      >
        <bank-id-authentication
          begin-authentication-on-mount
          :redirect-on-success="redirectOnSuccess"
          collect-url="/bank_id/collect/activation"
          @cancel="$beModal.hide('bankid-activation-modal')"
          @bankid-collect-success="
            didActivate = true;
            $beModal.hide('bankid-activation-modal');
          "
        />
      </be-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    redirectOnSuccess: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      didActivate: false,
    };
  },

  methods: {
    returningFromBankId() {
      return /#bank-id-return/.test(window.location.hash);
    },

    async deactivate() {
      try {
        const { data } = await axios.post("/bank_id/deactivation");

        this.$store.dispatch("current_user/setUser", data);
        this.didActivate = false;
      } catch (error) {
        this.handleError(error);
      }
    },
  },
};
</script>
