<template>
  <!-- Adds border-bottom-1 to work with .accordion.card which removes bottom border -->
  <div
    class="card bg-light border-bottom-1 border-left-1"
    :class="{ 'mt-2': index !== 0 }"
  >
    <div
      class="card-header bg-light d-flex justify-content-between align-items-center"
    >
      <div>
        <template v-if="isAgenda">
          {{ $t("models.item.task_suggestion") }}
        </template>

        <template v-else>
          {{ $t("activerecord.models.task.one") }}
        </template>
      </div>

      <div>
        <be-button
          v-be-tooltip="$t('buttons.titles.remove')"
          variant="danger"
          size="sm"
          icon="fa-times"
          @click="handleRemove"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('description')"
            :label="translateAttribute('task', 'description')"
            :error="getErrors(task, 'description')"
            required
          >
            <be-form-textarea
              :id="inputId('description')"
              v-model="localTask.description"
              :rows="9"
              :max-rows="20"
              :autofocus="localTask.fresh"
              :state="validationState(task, ['description'])"
              @input="clearErrors(task, ['description'])"
              @blur="setAutosave(true)"
            />
          </be-form-group>

          <be-form-checkbox
            v-if="!hideIncludeInMaterial"
            :id="inputId('include_in_material')"
            v-model="localTask.include_in_material"
            :state="validationState(task, 'include_in_material')"
            @change="clearErrors(localTask, 'include_in_material')"
          >
            {{
              isAgenda
                ? translateAttribute("task", "include_in_agenda")
                : translateAttribute("task", "include_in_minutes")
            }}
          </be-form-checkbox>
        </div>

        <div class="col-12 col-md-6">
          <be-form-group
            :label-for="inputId('user_id')"
            :label="$t('components.meetings.material.tasks.assign_task')"
            :error="getErrors(task, 'user_id')"
          >
            <be-form-select
              :id="inputId('user_id')"
              v-model="localTask.user_id"
              :state="validationState(task, ['user_id'])"
              :options="userOptions"
              :include-blank-option="
                $t('components.meetings.material.decisions.unassigned')
              "
              @change="clearErrors(task, ['user_id'])"
            />
          </be-form-group>

          <be-form-group
            :label-for="inputId('due_at')"
            :label="translateAttribute('task', 'due_at')"
            :error="getErrors(task, 'due_at')"
          >
            <datepicker-future-buttons
              :id="inputId('due_at')"
              :model-value="localTask.due_at"
              :state="validationState(task, ['due_at'])"
              display-inline
              @change="clearErrors(task, ['due_at'])"
              @input="setDate"
            />
          </be-form-group>

          <be-form-group
            v-if="platformEnabledAndSubscribed('projects')"
            :label-for="inputId('project_id')"
            :label="translateAttribute('task', 'project_id')"
            :error="getErrors(task, 'project_id')"
          >
            <be-form-select
              :id="inputId('project_id')"
              v-model="localTask.project_id"
              :state="validationState(task, ['project_id'])"
              :options="projectOptions"
              :include-blank-option="$t('beta_shared.please_select_an_option')"
              @change="clearErrors(task, ['project_id'])"
            />
          </be-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from "vuex";

import materialType from "./materialState";
import autosave from "@/mixins/autosave";
import TextUtilities from "@/mixins/textUtilities";
import { EventBus } from "@/event-bus";

const materialHelpers = createNamespacedHelpers("material");

export default {
  mixins: [autosave, materialType, TextUtilities],

  props: {
    task: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },

    hideIncludeInMaterial: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localTask: this.cloneDeep(this.task),
    };
  },

  computed: {
    ...mapGetters({
      users: "company/users",
      projects: "company/projects",
      hasActiveFeature: "company/hasActiveFeature",
    }),

    ...materialHelpers.mapGetters({
      meeting: "meeting",
      hasErrors: "hasErrors",
    }),

    projectOptions() {
      return this.projects.map((project) => ({
        text: project.display_title,
        value: project.id,
      }));
    },

    hasProjects() {
      return this.hasActiveFeature({ name: "projects" });
    },

    userOptions() {
      return this.users.map((user) => {
        return { value: user.id, text: user.name };
      });
    },

    invalid() {
      return this.hasErrors(this.localTask);
    },

    descriptionErrors() {
      const value = this.getErrors(this.localTask, "description");
      if (Array.isArray(value)) {
        return value;
      } else if (value) {
        return [value];
      } else {
        return [];
      }
    },
  },

  watch: {
    task: {
      handler(newTask) {
        // Set updateDisabled = true to allow to skip one watch of localDecision
        this.updateDisabled = true;
        this.localTask = this.cloneDeep(newTask);
      },

      deep: true,
    },

    localTask: {
      handler(updatedTask) {
        if (!this.updateDisabled) {
          this.setDirty(`task-${updatedTask.id}`);
          this.tryAutoSave({
            item: this.item,
            task: updatedTask,
          });
        }

        this.updateDisabled = false;
      },

      deep: true,
    },
  },

  methods: {
    ...materialHelpers.mapActions(["updateTask", "removeTask"]),
    ...materialHelpers.mapMutations(["setDirty"]),

    inputId(field) {
      return `task-${this.task.id}-${field}`;
    },

    async handleRemove() {
      let title = "";

      const itemType = this.isAgenda
        ? this.$t("models.item.task_suggestion").toLowerCase()
        : this.$t("activerecord.models.task.one").toLowerCase();

      if (this.task.description) {
        title = this.$t("nav.confirm_delete_w_item_type_w_title", {
          item_type: itemType,
          title: this.truncateText(this.task.description, 64),
        });
      } else {
        if (this.isAgenda) {
          title = this.$t(
            "models.material.minutes.confirm_remove_task_suggestion"
          );
        } else {
          title = this.$i18n.t("nav.confirm_delete_w_item_type", {
            item_type: itemType,
          });
        }
      }

      const isConfirmed = await this.promptRemovalConfirm(title);

      if (isConfirmed) {
        this.removeTask({
          task: this.localTask,
          item: this.item,
        });
      }
    },

    setDate(date) {
      this.localTask.due_at =
        date.length > 0 ? new Date(date).toISOString() : "";
    },

    // Used by autosave
    notifyAutosaveFailure() {
      EventBus.emit("new-notification", {
        type: "danger",
        message: this.$t("models.task.update.failure"),
      });
    },

    // Used by autosave
    saveRequest(data) {
      return this.updateTask({
        task: data.task,
        item: data.item,
      });
    },
  },
};
</script>
