<template>
  <div class="flex flex-col">
    <div
      :class="[
        'border border-md border-top-1 border-left-1 border-right-1 border-bottom-1 bg-white',
        {
          'border-danger': mode === 'remove',
          'border-success': mode === 'keep',
        },
      ]"
    >
      <div class="py-3 px-4">
        <p class="mb-2 text-muted">
          {{ leadingText }}
        </p>

        <template v-if="!hideName">
          <p class="h3 mb-0">
            {{ user.name }}
          </p>
        </template>

        <p v-if="user.confirmed_email" class="my-0">
          {{ user.confirmed_email }}
        </p>

        <p v-if="displayCreatedAt" class="my-0">
          <small class="text-muted">
            {{
              $t(
                "components.user.merge_requests.merge_request_user.account_created_w_date",
                { date: $d(new Date(user.created_at)) }
              )
            }}
          </small>
        </p>

        <be-badge
          v-if="displayCreatedAt"
          class="mt-2"
          :variant="mode === 'remove' ? 'danger' : 'success'"
        >
          <span v-if="mode === 'remove'">
            <i class="fas fa-circle-minus"></i>
            {{
              $t(
                "components.user.merge_requests.merge_request_user.this_account_will_be_removed"
              )
            }}
          </span>

          <span v-if="mode === 'keep'">
            <i class="fas fa-circle-check"></i>
            {{
              $t(
                "components.user.merge_requests.merge_request_user.this_account_will_remain"
              )
            }}
          </span>
        </be-badge>
      </div>

      <div
        class="py-3 px-4 border border-left-0 border-right-0 border-bottom-0 border-top-1"
      >
        <h6>
          {{
            $t(
              "components.user.merge_requests.merge_request_user.email_and_password"
            )
          }}
        </h6>

        <p v-if="mode === 'remove' && willLosePassword">
          <i class="fas fa-circle-minus text-danger"></i>
          {{
            $t(
              "components.user.merge_requests.merge_request_user.email_and_password_from_this_user_will_not_be_usable"
            )
          }}
        </p>

        <p
          v-else-if="mode === 'keep' && !willLosePassword"
          v-dompurify-html="
            $t(
              'components.user.merge_requests.merge_request_user.will_use_current_user_email_and_password_w_email_html',
              { email: user.confirmed_email }
            )
          "
        />

        <template
          v-if="transferredIntegrations && transferredIntegrations.length > 0"
        >
          <h6>
            {{
              $t(
                "components.user.merge_requests.merge_request_user.sign_in_services"
              )
            }}
          </h6>

          {{
            $t(
              "components.user.merge_requests.merge_request_user.you_will_be_able_to_login_with_the_following_services"
            )
          }}

          <ul class="mt-2 fa-ul ml-4">
            <li
              v-for="integration in transferredIntegrations"
              :key="integration"
            >
              <span class="fa-li">
                <i
                  v-if="mode === 'remove'"
                  v-be-tooltip="{
                    title: $t(
                      'components.user.merge_requests.merge_request_user.will_be_transferred_to_target_account'
                    ),
                    placement: 'left',
                  }"
                  class="fa-duotone fa-circle-arrow-right text-warning"
                ></i>

                <i
                  v-else-if="mode === 'keep'"
                  v-be-tooltip="{
                    title: $t(
                      'components.user.merge_requests.merge_request_user.will_be_transferred_from_initiating_account'
                    ),
                    placement: 'left',
                  }"
                  class="fa-duotone fa-circle-plus text-warning"
                ></i>
              </span>

              <b>{{ integration }}</b>
            </li>
          </ul>
        </template>

        <template
          v-if="
            (mode == 'keep' && anyOrganizations) ||
            (mode == 'remove' && sourceOrganizations.length > 0)
          "
        >
          <h6>
            {{
              $t(
                "components.user.merge_requests.merge_request_user.organizations"
              )
            }}
          </h6>

          <transfer-lists
            :item-type="
              $t(
                'components.user.merge_requests.merge_request_user.organizations'
              )
            "
            :target-text="
              sendPreview
                ? $t(
                    'components.user.merge_requests.merge_request_user.current_organizations_for_w_for',
                    { for: user.confirmed_email }
                  )
                : ''
            "
            :source-items="sourceOrganizations"
            :target-items="targetOrganizations"
            :mode="mode == 'keep' ? 'target' : 'source'"
          />
        </template>

        <template
          v-if="
            (mode == 'keep' && anyClientManagers) ||
            (mode == 'remove' && sourceClientManagers.length > 0)
          "
        >
          <h6>
            {{
              $t(
                "components.user.merge_requests.merge_request_user.client_managers"
              )
            }}
          </h6>

          <transfer-lists
            :item-type="
              $t(
                'components.user.merge_requests.merge_request_user.client_managers'
              )
            "
            :target-text="
              sendPreview
                ? $t(
                    'components.user.merge_requests.merge_request_user.current_client_managers_for_w_for',
                    { for: user.confirmed_email }
                  )
                : ''
            "
            :source-items="sourceClientManagers"
            :target-items="targetClientManagers"
            :mode="mode == 'keep' ? 'target' : 'source'"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TransferLists from "./TransferLists.vue";

export default {
  components: {
    TransferLists,
  },

  props: {
    sendPreview: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideName: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideMode: {
      type: Boolean,
      required: false,
      default: false,
    },

    willLosePassword: {
      type: Boolean,
      required: true,
    },

    mode: {
      type: String,
      required: true,

      validator(value) {
        return ["remove", "keep"].includes(value);
      },
    },

    user: {
      type: Object,
      required: true,
    },

    displayCreatedAt: {
      type: Boolean,
      required: false,
      default: true,
    },

    transferredIntegrations: {
      type: Array,
      required: false,
      default: () => [],
    },

    sourceClientManagers: {
      type: Array,
      required: false,
      default: () => [],
    },

    targetClientManagers: {
      type: Array,
      required: false,
      default: () => [],
    },

    sourceOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },

    targetOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },

    leadingText: {
      type: String,
      required: true,
    },
  },

  computed: {
    anyOrganizations() {
      return (
        this.sourceOrganizations?.length > 0 ||
        this.targetOrganizations?.length > 0
      );
    },

    anyClientManagers() {
      return (
        this.sourceClientManagers?.length > 0 ||
        this.targetClientManagers?.length > 0
      );
    },
  },
};
</script>
