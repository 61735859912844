<template>
  <div class="container">
    <div class="card mb-2">
      <div class="card-body p-3 d-none d-md-block">
        <ul class="nav nav-wizard">
          <li
            class="nav-item"
            :class="currentSlide == 'introduction' ? 'active' : 'disabled'"
          >
            <span>
              {{ $t("components.onboarding.steps.welcome.title") }}
            </span>
          </li>

          <li
            class="nav-item"
            :class="currentSlide == 'feature-select' ? 'active' : 'disabled'"
          >
            <span>
              {{ $t("components.onboarding.steps.features.title") }}
            </span>
          </li>

          <li
            class="nav-item"
            :class="
              currentSlide == 'complete-onboarding' ? 'active' : 'disabled'
            "
          >
            <span>
              {{ $t("components.onboarding.steps.get_started.title") }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <onboarding-introduction
      v-show="currentSlide == 'introduction'"
      :aws-url="awsUrl"
      @set-slide="setSlide"
    />

    <feature-shop
      v-show="currentSlide == 'feature-select'"
      :all-features="features"
      :basic-plan="basicPlan"
      :packages="packages"
      @set-slide="setSlide"
    />

    <contact-information v-show="currentSlide == 'complete-onboarding'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OnboardingIntroduction from "./steps/OnboardingIntroduction.vue";
import FeatureShop from "./steps/FeatureShop.vue";
import ContactInformation from "./steps/ContactInformation.vue";

export default {
  components: {
    OnboardingIntroduction,
    FeatureShop,
    ContactInformation,
  },

  props: {
    awsUrl: {
      type: String,
      required: true,
    },

    features: {
      type: Array,
      required: true,
    },

    packages: {
      type: Array,
      required: true,
    },

    basicPlan: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      currentSlide: null,
    };
  },

  computed: {
    ...mapGetters({
      onboardingProgress: "company/getOnboardingProgress",
    }),
  },

  mounted() {
    this.setCurrentSlide();
  },

  methods: {
    setSlide(slide) {
      this.currentSlide = slide;
    },

    setCurrentSlide() {
      if (
        !this.$currentCompany.features.length &&
        !this.$currentCompany.onboarded_at &&
        (!this.onboardingProgress["introduction"]["video_visited"] ||
          !this.onboardingProgress["introduction"]["slide_show_visited"])
      ) {
        this.setSlide("introduction");
      } else {
        if (
          !this.$currentCompany.features.length &&
          !this.$currentCompany.onboarded_at
        ) {
          this.setSlide("feature-select");
        } else {
          this.setSlide("complete-onboarding");
        }
      }
    },
  },
};
</script>
