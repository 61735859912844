<template>
  <div v-if="showGraph" id="contract-gantt" class="wz-graph wz-graph-area" />

  <be-alert v-else variant="info">
    {{ $t("components.contracts.graph.no_data") }}
  </be-alert>
</template>

<script>
import { add } from "date-fns/add";
import { sub } from "date-fns/sub";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { mapGetters } from "vuex";

const TODAY = new Date();

export default {
  props: {
    initialLastPossibleDate: {
      type: String,
      default: "9999-12-24",
    },
  },

  data() {
    return {
      lastPossibleDate: this.initialLastPossibleDate,
      chartData: [],
      contractsWithoutEndDate: [],
      showGraph: true,
      titleLength: null,
    };
  },

  computed: {
    ...mapGetters({
      data: "contracts/getFilteredContracts",
    }),
  },

  watch: {
    data: {
      handler() {
        this.formatData();
        this._chart.data = this.chartData;
        this._chart.invalidate();
      },

      deep: true,
    },
  },

  created() {
    this._chart = null;
  },

  mounted() {
    this.titleLength = this.$config.CONTRACT.TITLE_LENGTH;
    this._chart = am4core.create(`contract-gantt`, am4charts.XYChart);
    this.createGantt();
  },

  beforeMount() {
    if (this._chart) {
      this._chart.dispose();
    }

    this._chart = null;
  },

  methods: {
    formatData() {
      const colors = this.$config.CONTRACT.GRAPH_COLORS;

      this.chartData = [];
      this.contractsWithoutEndDate = [];

      this.addContractsWithEndDate(colors);
      this.addContractsWithoutEndDate(colors);
    },

    addContractsWithEndDate(colors) {
      for (const contract of Object.values(this.data)) {
        let color = null;
        let terminationColor = null;
        let activeText = null;
        let terminatedText = null;
        let value = this.contractValue(contract);

        let formattedStartDate = contract.start_date
          ? this.$d(new Date(contract.start_date), "date")
          : null;
        let formattedEndDate = contract.end_date
          ? this.$d(new Date(contract.end_date), "date")
          : null;
        let formattedTerminationDate = contract.termination_date
          ? this.$d(new Date(contract.termination_date), "date")
          : null;

        if (contract.start_date && contract.end_date) {
          // Set color
          if (
            Date.parse(contract.start_date) < TODAY &&
            TODAY < Date.parse(contract.end_date)
          ) {
            color = colors.ACTIVE;
            terminationColor = colors.TERMINATED;
            activeText = this.$i18n.t("models.contract.statuses.active");
            terminatedText = this.$i18n.t(
              "models.contract.statuses.active_terminated"
            );
          } else {
            color = colors.INACTIVE;
            terminationColor = colors.INACTIVE_TERMINATED;
            activeText = this.$i18n.t("models.contract.statuses.inactive");
            terminatedText = this.$i18n.t(
              "models.contract.statuses.inactive_terminated"
            );
          }

          // Set last end date
          if (
            Date.parse(contract.end_date) > Date.parse(this.lastPossibleDate)
          ) {
            this.lastPossibleDate = contract.end_date;
          }

          if (contract.termination_date == null) {
            this.chartData.push({
              title: this.shortTitle(
                "#" + contract.contract_number + "-" + contract.title
              ),

              fromDate: this.$d(new Date(contract.start_date), "date"),
              toDate: this.$d(new Date(contract.end_date), "date"),
              color: color,

              task:
                this.shortTitle(contract.title) +
                "<b><br>" +
                value +
                activeText +
                "</b><br>" +
                formattedStartDate +
                " - " +
                formattedEndDate,
            });
          } else {
            this.chartData.push({
              title: this.shortTitle(
                "#" + contract.contract_number + "-" + contract.title
              ),

              fromDate: formattedStartDate,
              toDate: formattedEndDate,
              color: color,

              task:
                this.shortTitle(contract.title) +
                "<b><br>" +
                value +
                activeText +
                "</b><br>" +
                formattedStartDate +
                " - " +
                formattedTerminationDate,
            });
            this.chartData.push({
              title: this.shortTitle(
                "#" + contract.contract_number + "-" + contract.title
              ),

              fromDate: formattedTerminationDate,
              toDate: formattedEndDate,
              color: terminationColor,

              task:
                this.shortTitle(contract.title) +
                "..." +
                "<b><br>" +
                value +
                terminatedText +
                "<br>" +
                this.$i18n.t("models.contract.statuses.terminated") +
                " " +
                formattedTerminationDate +
                "</b><br>" +
                formattedTerminationDate +
                " - " +
                formattedEndDate,
            });
          }

          // If there is a running contract they should be handled separately
        } else if (contract.start_date && !contract.end_date) {
          this.contractsWithoutEndDate.push(contract);
        }
      }
    },

    addContractsWithoutEndDate(colors) {
      for (const contract of Object.values(this.contractsWithoutEndDate)) {
        let color = null;
        let terminatedText = null;
        let value = this.contractValue(contract);
        let formattedStartDate = this.$d(new Date(contract.start_date), "date");
        let formattedTerminationDate = contract.termination_date
          ? this.$d(new Date(contract.termination_date), "date")
          : null;

        if (
          Date.parse(contract.start_date) > Date.parse(this.lastPossibleDate)
        ) {
          this.lastPossibleDate = contract.start_date;
        }

        let endDate = this.$d(
          add(new Date(this.lastPossibleDate), { months: 3 }),
          "date"
        );

        if (contract.start_date != contract.termination_date) {
          if (Date.parse(contract.start_date) <= TODAY) {
            color = colors.RUNNING;

            if (contract.termination_date) {
              color = colors.TERMINATED;
              endDate = this.$d(new Date(contract.termination_date), "date");

              if (endDate < TODAY) {
                terminatedText =
                  this.$i18n.t("models.contract.statuses.inactive_terminated") +
                  "<br>" +
                  this.$i18n.t("models.contract.statuses.terminated") +
                  " " +
                  formattedTerminationDate;
              } else {
                terminatedText =
                  this.$i18n.t("models.contract.statuses.active_terminated") +
                  "<br>" +
                  this.$i18n.t("models.contract.statuses.terminated") +
                  " " +
                  formattedTerminationDate;
              }
            } else {
              terminatedText = this.$i18n.t("models.contract.statuses.active");
            }
          } else {
            color = colors.INACTIVE_RUNNING;

            if (contract.termination_date) {
              color = colors.INACTIVE_TERMINATED;
              endDate = this.$d(new Date(contract.termination_date), "date");
              terminatedText =
                this.$i18n.t("models.contract.statuses.inactive_terminated") +
                "-" +
                this.$i18n.t("models.contract.statuses.terminated") +
                " " +
                formattedTerminationDate;
            } else {
              terminatedText = this.$i18n.t(
                "models.contract.statuses.inactive"
              );
            }
          }

          this.chartData.push({
            title: this.shortTitle(
              "#" + contract.contract_number + "-" + contract.title
            ),

            fromDate: this.$d(new Date(contract.start_date), "date"),
            toDate: endDate,
            color: color,

            task:
              this.shortTitle(contract.title) +
              "<b><br>" +
              value +
              terminatedText +
              "</b><br>" +
              this.$i18n.t("models.contract.statuses.running_from") +
              " " +
              formattedStartDate,
          });
        }
      }
    },

    createGantt() {
      this._chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

      this._chart.paddingRight = 30;
      this._chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

      var colorSet = new am4core.ColorSet();
      colorSet.saturation = 0.4;

      this.formatData();
      if (this.chartData.length == 0) {
        this.showGraph = false;
        return false;
      }

      this._chart.data = this.chartData;

      var categoryAxis = this._chart.yAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "title";
      categoryAxis.renderer.grid.template.location = 0;

      var dateAxis = this._chart.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
      dateAxis.renderer.minGridDistance = 70;
      dateAxis.baseInterval = { timeUnit: "day" };
      dateAxis.min = "2020-01-01";
      dateAxis.max = "2020-03-30";
      dateAxis.strictMinMax = true;
      dateAxis.renderer.tooltipLocation = 0;

      var series1 = this._chart.series.push(new am4charts.ColumnSeries());
      series1.columns.template.width = am4core.percent(80);
      series1.columns.template.tooltipHTML = "{task}";

      series1.dataFields.openDateX = "fromDate";
      series1.dataFields.dateX = "toDate";
      series1.dataFields.categoryY = "title";
      series1.columns.template.propertyFields.fill = "color"; // get color from data
      series1.columns.template.propertyFields.stroke = "color";
      series1.columns.template.strokeOpacity = 1;

      // Add legend to chart
      let legend = new am4charts.Legend();
      legend.parent = this._chart.chartContainer;
      legend.marginTop = 20;
      legend.marginBottom = 20;
      legend.itemContainers.template.togglable = false;
      legend.itemContainers.template.cursorOverStyle =
        am4core.MouseCursorStyle.default;
      legend.data = [
        {
          name: this.$i18n.t("models.contract.statuses.active"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.ACTIVE,
        },
        {
          name: this.$i18n.t("models.contract.statuses.active_terminated"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.TERMINATED,
        },
        {
          name: this.$i18n.t("models.contract.statuses.inactive"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.INACTIVE,
        },
        {
          name: this.$i18n.t("models.contract.statuses.inactive_terminated"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.INACTIVE_TERMINATED,
        },
        {
          name: this.$i18n.t("models.contract.statuses.active_running"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.RUNNING,
        },
        {
          name: this.$i18n.t("models.contract.statuses.inactive_running"),
          fill: this.$config.CONTRACT.GRAPH_COLORS.INACTIVE_RUNNING,
        },
      ];

      this._chart.scrollbarX = new am4core.Scrollbar();

      this._chart.events.on("ready", () => {
        let startDate = sub(TODAY, { months: 1 });
        let endDate = add(TODAY, { months: 4 });

        if (
          this.lastPossibleDate >= startDate &&
          this.lastPossibleDate <= endDate
        ) {
          dateAxis.zoomToDates(startDate, endDate);
        }
      });
    },

    contractValue(contract) {
      if (contract.total_value_cents) {
        return (
          contract.total_value_cents / 100.0 + " " + contract.currency + "<br>"
        );
      } else if (contract.monthly_value_cents) {
        return (
          contract.monthly_value_cents / 100.0 +
          " " +
          contract.currency +
          "<br>"
        );
      } else {
        return "";
      }
    },

    shortTitle(title) {
      if (title.length > this.titleLength) {
        return title.substring(0, this.titleLength) + "...";
      } else {
        return title;
      }
    },
  },
};
</script>
