<template>
  <div class="card">
    <div class="card-body">
      <p>
        {{
          $t(
            "components.user.merge_requests.new_merge_request_form.description_1_w_translated_platform_name",
            {
              translated_platform_name:
                $platform.theme.translated_platform_name,
            }
          )
        }}
      </p>

      <p>
        {{
          $t(
            "components.user.merge_requests.new_merge_request_form.description_2"
          )
        }}
      </p>

      <p>
        {{
          $t(
            "components.user.merge_requests.new_merge_request_form.description_3"
          )
        }}
      </p>

      <p
        v-dompurify-html="
          $t(
            'components.user.merge_requests.new_merge_request_form.description_4_html'
          )
        "
      />

      <p class="h3 mt-4 mb-3">
        {{
          $t(
            "components.user.merge_requests.new_merge_request_form.receiving_account"
          )
        }}
      </p>

      <be-form-group
        label-for="email"
        :label="$t('activerecord.attributes.user.email')"
        required
        :state="validateEmail(email)"
        :error="errorMessage['email']"
      >
        <be-form-input
          id="email"
          v-model="email"
          type="email"
          name="email"
          trim
          debounce="500"
        />
      </be-form-group>

      <be-form-group
        label-for="email_confirmation"
        :label="
          $t(
            'components.user.merge_requests.new_merge_request_form.email_confirmation'
          )
        "
        debounce="500"
        :state="validateEmail(email_confirmation)"
        :error="errorMessage['email_confirmation']"
        required
      >
        <be-form-input
          id="email_confirmation"
          v-model="email_confirmation"
          type="email"
          name="email_confirmation"
          trim
        />
      </be-form-group>
    </div>

    <div class="card-footer d-flex flex-row justify-content-end">
      <be-button
        v-be-tooltip="errorMessage['tooltip']"
        v-be-modal="'confirm-send-user-merge'"
        variant="primary"
        :disabled="errorMessage['tooltip']"
      >
        {{ $t("buttons.titles.send") }}
      </be-button>

      <confirm-modal
        id="confirm-send-user-merge"
        :title="
          $t(
            'components.user.merge_requests.new_merge_request_form.confirm_send_merge_request_title'
          )
        "
        :ok-title="$t('buttons.titles.send')"
        :valid-input-text="confirmText"
        confirm-with-input
        @confirm="handleConfirm"
      >
        {{
          $t(
            "components.user.merge_requests.new_merge_request_form.confirm_send_merge_request_message_w_email",
            { email: email }
          )
        }}
        <merge-request-user
          class="my-4"
          send-preview
          :user="user"
          hide-mode
          :source-organizations="sourceOrganizations"
          :source-client-managers="sourceClientManagers"
          :transferred-integrations="transferredIntegrations"
          :display-created-at="false"
          hide-name
          mode="keep"
          :leading-text="
            $t(
              'components.user.merge_requests.merge_request_user.result_after_merge'
            )
          "
        />
      </confirm-modal>
    </div>
  </div>
</template>
<script>
import { validateEmail } from "@/utils/text-utils";

export default {
  props: {
    sourceOrganizations: {
      type: Array,
      required: false,
      default: () => [],
    },

    sourceClientManagers: {
      type: Array,
      required: false,
      default: () => [],
    },

    transferredIntegrations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      email: "",
      email_confirmation: "",
      validateEmail,
    };
  },

  computed: {
    emailsMatch() {
      return this.email === this.email_confirmation;
    },

    emailNotValidMessage() {
      return this.$t(
        "components.user.merge_requests.new_merge_request_form.email_not_valid"
      );
    },

    emailsDoNotMatchMessage() {
      return this.$t(
        "components.user.merge_requests.new_merge_request_form.emails_do_not_match"
      );
    },

    youMustFillInAllFieldsMessage() {
      return this.$t(
        "components.user.merge_requests.new_merge_request_form.you_must_fill_in_all_fields"
      );
    },

    cannotSendToYourselfMessage() {
      return this.$t(
        "components.user.merge_requests.new_merge_request_form.you_cannot_send_to_yourself"
      );
    },

    errorMessage() {
      return {
        email: [
          {
            message: this.emailNotValidMessage,
            condition: !this.validateEmail(this.email) && !!this.email,
          },
          {
            message: this.cannotSendToYourselfMessage,
            condition: this.email === this.$currentUser.email,
          },
          {
            message: this.emailsDoNotMatchMessage,
            condition: !this.emailsMatch && !!this.email,
          },
          {
            message: this.emailsDoNotMatchMessage,
            condition: !this.emailsMatch && !!this.email,
          },
        ].filter((error) => error.condition)[0]?.message,

        email_confirmation: [
          {
            message: this.emailNotValidMessage,

            condition:
              !this.validateEmail(this.email_confirmation) &&
              !!this.email_confirmation,
          },
          {
            message: this.emailsDoNotMatchMessage,

            condition: !this.emailsMatch && !!this.email_confirmation,
          },
        ].filter((error) => error.condition)[0]?.message,

        tooltip: [
          {
            message: this.youMustFillInAllFieldsMessage,
            condition: !(this.email && this.email_confirmation),
          },

          {
            message: this.cannotSendToYourselfMessage,
            condition: this.email === this.$currentUser.email,
          },
          {
            message: this.emailsDoNotMatchMessage,

            condition:
              !!this.email && !!this.email_confirmation && !this.emailsMatch,
          },
          {
            message: this.emailNotValidMessage,

            condition:
              (!this.validateEmail(this.email) ||
                !this.validateEmail(this.email_confirmation)) &&
              !!(this.email || this.email_confirmation),
          },
        ].filter((error) => error.condition)[0]?.message,
      };
    },

    confirmText() {
      return `remove-${this.$currentUser.email}`;
    },

    user() {
      return { ...this.$currentUser, confirmed_email: this.email };
    },
  },

  methods: {
    handleConfirm() {
      this.$el.closest("form").submit();
    },
  },
};
</script>
